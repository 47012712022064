define(["./threeModule"], function (threeModule) {
    "use strict";

    return threeModule.service("threePalletMeshFactory", ["threeBoxMeshFactory", "Object3DInfo", function (threeBoxMeshFactory, Object3DInfo) {
        var self = this;

        self.createMesh = function (object) {
            var palletBase = {
                dimensions: {
                    width: object.extraInfo[Object3DInfo.SUPPORT_WIDTH],
                    height: object.extraInfo[Object3DInfo.SUPPORT_HEIGHT],
                    length: object.extraInfo[Object3DInfo.SUPPORT_LENGTH]
                },
                extraInfo: {}
            };
            palletBase.extraInfo[Object3DInfo.COLOR_GROUP] = 0;

            var palletBaseMesh = threeBoxMeshFactory.createMesh(palletBase);
            // Garante que as rotações da base do pallet estejam zero, já que serão adicionadas na própria mesh da composição já rotacionada
            palletBaseMesh.rotation.y = 0;
            palletBaseMesh.material.color.set(0xa1561d);

            // Como todos os objetos são rotacionados 90 graus em Y, devemos trocar os offsets dos eixos X com Z
            var supportAxis = object.extraInfo[Object3DInfo.SUPPORT_AXIS];
            if(supportAxis === "y") {
                palletBaseMesh.position.y = (palletBase.dimensions.height - object.dimensions.height) / 2;
            } else if(supportAxis === "z"){
                palletBaseMesh.position.x = (palletBase.dimensions.width - object.dimensions.width) / 2;
            } else if(supportAxis === "-z"){
                // Reflete eixo Z devido à base do CL ser contrária à regra da mão esquerda
                palletBaseMesh.position.x = (object.dimensions.width - palletBase.dimensions.width) / 2;
            } else if(supportAxis === "x"){
                palletBaseMesh.position.z = (object.dimensions.length - palletBase.dimensions.length ) / 2;
            }

            return threeBoxMeshFactory.createMesh(object).add(palletBaseMesh);
        };

        return self;
    }]);
});