import orderTypeModule from "./orderTypeModule"

orderTypeModule.controller("orderTypeController",
    ["$scope",
        "sourceEntity",
        "entity",
        "orderTypeService",
        "entityEditionService",
        ($scope,
         sourceEntity,
         entity,
         orderTypeService,
         entityEditionService) => {
            const updateService = entityEditionService.crudHelper("order-type", sourceEntity)

            $scope.entity = entity

            orderTypeService.findIncompatibleCandidates(entity.id).then((orderTypes) => {
                $scope.incompatibleOrderTypes = orderTypes
            })


            orderTypeService.findCompatibleVehicles(entity).then((vehicles) => {
                $scope.compatibleVehicles = vehicles
            })

            $scope.save = updateService.save.bind(updateService, entity)

            $scope.saveAndContinue = updateService.save.bind(updateService, entity, true)
        }])