import notificationModule from "./notificationModule"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"
import notificationView from "./notificationView.html"

notificationModule.directive("nlgNotification", [
    "$window",
    function ($window) {
        return {
            restrict: "E",
            template: notificationView,
            scope: {},
            controller: ["$scope", "notificationService", "$localStorage", "messagesModal", "remoteExceptionHandler", function ($scope, notificationService, $localStorage, messagesModal, remoteExceptionHandler) {
                $scope.model = {
                    notifications: []
                }

                $scope.storage = $localStorage.$default({
                    notificationsCount: 0
                })

                $scope.removeNotification = function (notification) {
                    let index = $scope.model.notifications.indexOf(notification)
                    arrays.removeAt($scope.model.notifications, index)
                    $scope.storage.notificationsCount = $scope.storage.notificationsCount - 1
                    notificationService.markMessageAsConsumed(notification.id)
                }

                $scope.getNotificationLink = function (notification) {
                    switch (notification.notificationType) {
                        case "br.com.neolog.model.notification.Notification$NotificationType.INT_ERROR":
                            return "r2.spreadsheet-import"
                        default:
                            return "#"
                    }
                }

                $scope.loadNotifications = function () {
                    $scope.model.notifications = []
                    notificationService.loadNotifications()
                        .then(function (response) {
                            $scope.model.notifications = response.data
                        })
                }

                $scope.clearNotifications = function () {
                    messagesModal.cancellable("dialog.warning", ["notifications.clear.warning"])
                        .then(() => notificationService.clearNotifications()
                                .catch(remoteExceptionHandler())
                        )
                }

                notificationService.connect()
                notificationService.whenReceive()
                    .then(null, null, function (notificationsCount) {
                        $scope.storage.notificationsCount = notificationsCount
                    })

                $scope.$on("$destroy", notificationService.close)
                $window.addEventListener("beforeunload", notificationService.close)
            }]
        }
    }])