define(["../occurrenceCauseModule"],
    function (occurrenceCauseModule) {
        "use strict";
        return occurrenceCauseModule.controller("OccurrenceCauseEditionController", [
            "$scope",
            "$state",
            "occurrenceCause",
            "monitoringService",
            "messagesModal",
            "occurrenceCauseService",
            "OccurrenceCauseIcon",
            function ($scope, $state, occurrenceCause, monitoringService, messagesModal, occurrenceCauseService, OccurrenceCauseIcon) {
                $scope.entity = occurrenceCause;
                if (!$scope.entity.icon) {
                    occurrenceCause.icon = "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.WARNING";
                }
                $scope.entity.occurrenceCauseIcon = occurrenceCause.icon;

                $scope.status = function () {
                    return monitoringService.findInitialOccurrenceStatus();
                };

                $scope.occurrenceCategory = function () {
                    return monitoringService.findOccurrenceCategories();
                };

                $scope.getMonitorableTypes = function () {
                    return monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType")
                        .then(function (values) {
                            return values;
                        });
                };

                var occurrenceId;

                function save() {
                    if (!$scope.entity.occurrenceCauseIcon) {
                        occurrenceCause.icon = "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.WARNING";
                        $scope.entity.occurrenceCauseIcon = occurrenceCause.icon;
                        $scope.entity.icon = occurrenceCause.icon;
                        $scope.setIcon();
                    }
                    var occurrenceCategory = $scope.entity.occurrenceCategory || null;
                    var defaultStatus = $scope.entity.defaultStatus || null;

                    var occurrenceCauseJson = {
                        sourceId: $scope.entity.sourceId,
                        name: $scope.entity.name,
                        description: $scope.entity.description,
                        defaultImpact: $scope.entity.defaultImpact,
                        defaultStatus: defaultStatus,
                        causeOrder: $scope.entity.causeOrder,
                        occurrenceCategory: occurrenceCategory,
                        allowedMonitorableTypes: $scope.entity.allowedMonitorableTypes,
                        imageRequired: $scope.entity.imageRequired,
                        icon: $scope.entity.occurrenceCauseIcon
                    };

                    if ($scope.entity.id) {
                        occurrenceCauseJson.id = $scope.entity.id;
                    }

                    return occurrenceCauseService.saveOccurrenceCause(occurrenceCauseJson)
                        .then(function (id) {
                            occurrenceId = id;
                        });
                }

                $scope.save = function () {
                    save()
                        .then(function () {
                            return $state.go("monitoring.OccurrenceCauseEdition", {
                                occurrenceCauseId: occurrenceId
                            });
                        });
                };

                $scope.saveAndContinue = function () {
                    save()
                        .then(function () {
                            return $state.go($state.current, {reload: Date.now()});
                        });
                };

                $scope.setIcon = function () {
                    $scope.entity.pathImage = OccurrenceCauseIcon[$scope.entity.occurrenceCauseIcon];
                };

                $scope.setIcon();

                $scope.back = function () {
                    return $state.go("monitoring.OccurrenceCauseSearch", {
                        reload: true
                    });
                };

                $scope.getIcons = function () {
                    return occurrenceCauseService.getOccurrenceCauseIcons();
                };
            }
        ]);
    });