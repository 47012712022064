import homeModule from "./homeModule"
import homeView from "./homeView.html"

homeModule.config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state("r2.home", {
        data: {
            pageTitle: "{{'title.r2.home' | translate}}"
        },
        url: "/home",
        template: homeView
    })
}])