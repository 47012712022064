define(["../occurrencesModule", "moment"],
    function (occurrencesModule, moment) {
        "use strict";

        occurrencesModule.service("newOccurrenceService", [
            "$http",
            "$translate",
            "messagesModal",
            "loadingService",
            "mbrService",
            "remoteExceptionHandler",
            function ($http, $translate, messagesModal, loadingService, mbrService, remoteExceptionHandler) {
                return {
                    emptyOccurrence: emptyOccurrence,
                    ValueDelta: ValueDelta,
                    TimeDelta: TimeDelta,
                    formatOccurrenceStatus: formatOccurrenceStatus,
                    createOccurrence: createOccurrence
                };

                function emptyOccurrence() {
                    return {
                        "monitorableId": null,
                        "timestamp": new Date(),
                        "category": null,
                        "cause": null,
                        "impact": {
                            "timeDelta": null,
                            "valueDelta": null,
                            "quantityDelta": null
                        },
                        "status": null,
                        "comments": []
                    };
                }

                // valor em reais
                function ValueDelta(initial) {
                    if (!(this instanceof ValueDelta)) {
                        return new ValueDelta(initial);
                    }
                    var _value = initial || 0;
                    var _signal = (_value >= 0) ? 1 : -1;
                    return {
                        rawValue: _value,
                        signal: _signal,
                        getValue: function () {
                            if (!this.rawValue) {
                                return null;
                            }
                            return this.rawValue * this.signal;
                        }
                    };
                }

                // initial em millis
                function TimeDelta(initial) {
                    if (!(this instanceof TimeDelta)) {
                        return new TimeDelta(initial);
                    }
                    var _value = initial || 0;
                    var duration = moment.duration(_value);
                    var _signal = (_value >= 0) ? 1 : -1;
                    return {
                        minutes: duration.minutes(),
                        hours: duration.hours(),
                        days: duration.days(),
                        signal: _signal,

                        // getValue retorna em millis
                        getValue: function () {
                            if (!this.minutes && !this.hours && !this.days) {
                                return null;
                            }
                            var millis = moment.duration({
                                minutes: this.minutes || 0,
                                hours: this.hours || 0,
                                days: this.days || 0
                            }).asMilliseconds();
                            return millis * this.signal;
                        }
                    };
                }

                function formatOccurrenceStatus(status) {
                    if (!status) {
                        return "";
                    }
                    return $translate.instant(status);
                }

                function createOccurrence(occurrence, occurrenceMonitorableIdentifiers, files) {
                    return messagesModal.cancellable("dialog.warning", ["monitoring.monitorable.occurrence.addOccurrence"])
                        .then(function () {
                            return loadingService(mbrService.createOccurrence({
                                monitorableIdentifiers: occurrenceMonitorableIdentifiers,
                                occurrence: occurrence
                            }, files))
                                .catch(remoteExceptionHandler());
                        }).then(function (occurrenceId) {
                            return messagesModal("dialog.success", {
                                keyBundle: "monitoring.monitorable.occurrence.save.success"
                            }).then(function () {
                                return occurrenceId;
                            })
                        });
                }
            }]);
    });