define([
    "./nlgHealthCheckerModule",
    "text!./nlgHealthCheckerConfigurationModal.html",
    "lodash"
], function (nlgHealthCheckerModule, template, _) {
    "use strict";

    return nlgHealthCheckerModule.service("nlgHealthCheckerConfigurationModal", ["$modal", function ($modal) {
        return function (instance, checkerName) {
            return $modal.open({
                template: template,
                controller: ["$scope", "nlgHealthCheckerService", "loadingService", "remoteExceptionHandler", "messagesModal", function ($scope, nlgHealthCheckerService, loadingService, remoteExceptionHandler, messagesModal) {
                    $scope.model = {configurations: {}};
                    $scope.instance = instance;
                    $scope.checkerName = checkerName;

                    $scope.hasConfigurations = function () {
                        return !_.isEmpty($scope.model.configurations);
                    };

                    $scope.saveConfigurations = function () {
                        return loadingService(nlgHealthCheckerService.saveConfigurations(instance, $scope.model.configurations))
                            .catch(remoteExceptionHandler())
                            .then(function () {
                                return messagesModal("dialog.success", ["entity.validator.successMessage"]);
                            })
                            .then(function () {
                                return $scope.$close($scope.model.configurations);
                            });
                    };
                }]
            }).result;
        };
    }]);
});
