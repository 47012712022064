define([
        "angular",
        "lodash",
        "./nlgLGPDModule"],
    function (angular, _, nlgLGPDModule) {
        "use strict";

        return nlgLGPDModule.service("nlgLGPDService", [
            "$http",
            "remoteExceptionHandler",
            "loadingService",
            "filterServiceFactory",
            "nlgLGPD",
            "lgpdApiByArtifact",
            function ($http, remoteExceptionHandler, loadingService, filterServiceFactory, nlgLGPD, lgpdApiByArtifact) {
                var filterServicePath = nlgLGPD.createLGPDFilterServicePath();
                var availableServicePaths = nlgLGPD.getLGPDServiceNames();

                return {
                    getAllEntityOwners: function () {
                        return availableServicePaths.map(function (item) {
                            return lgpdApiByArtifact.getApiOfArtifact(item.artifactName).entityGetAllOwners()
                                .then(function (response) {
                                    return {
                                        key: item,
                                        value: response.data
                                    };
                                }).catch(function () {
                                    return {
                                        key: item,
                                        value: []
                                    };
                                });
                        });
                    },
                    getDefinitions: function () {
                        return availableServicePaths.map(function (item) {
                            return lgpdApiByArtifact.getApiOfArtifact(item.artifactName).entityGetDefinitions()
                                .then(function (response) {
                                    return {
                                        key: item,
                                        value: response.data
                                    };
                                }).catch(function () {
                                    return {
                                        key: item,
                                        value: []
                                    };
                                });
                        });
                    },
                    getEntitiesByOwner: function (owner) {
                        return lgpdApiByArtifact.getApiOfArtifact(owner.artifactName).entityGetByOwner(owner);
                    },
                    save: function (entity) {
                        return loadingService(lgpdApiByArtifact.getApiOfArtifact(entity.artifactName).entitySave(entity));
                    },
                    filterServiceName: function () {
                        return filterServicePath;
                    },
                    getAnonymizeFilterServiceName: function (definition) {
                        return loadingService(lgpdApiByArtifact.getApiOfArtifact(definition.artifactName).hasFilterTemplate(definition))
                            .then(function (response) {
                                if (response === true) {
                                    return lgpdApiByArtifact.getApiOfArtifact(definition.artifactName).getAnonymizeFilterServiceName(definition);
                                }
                            }
                        );
                    },
                    availableServiceNames: function () {
                        return availableServicePaths;
                    },
                    delete: function (entity) {
                        return loadingService(lgpdApiByArtifact.getApiOfArtifact(entity.artifactName).entityDelete(entity.id));
                    },
                    filter: function (template, path, firstResult, maxResults) {
                        var params = {params: {firstResult: firstResult, maxResults: maxResults}};
                        return loadingService($http.post(path + "/filter", template, params));
                    },
                    filterByArtifact: function (template, artifact, firstResult, maxResults, entityName) {
                        var params = {
                            params: {
                                firstResult: firstResult,
                                maxResults: maxResults,
                                entityName: entityName
                            }
                        };
                        return lgpdApiByArtifact.getApiOfArtifact(artifact).entityFilter(template, params);
                    },
                    findVersionsOf: function (id, artifactName) {
                        return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).entityLogVersionOf(id));
                    },
                    findVersion: function (id, version, artifactName) {
                        return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).entityFindVersion(id, version));
                    },
                    getAllDefinitionEntityNames: function () {
                        return availableServicePaths.map(function (item) {
                            return lgpdApiByArtifact.getApiOfArtifact(item.artifactName).anonymizeGetAllDefinitionsNames();
                        });
                    },
                    getEntitiesByServicePath: function (definition) {
                        return lgpdApiByArtifact.getApiOfArtifact(definition.artifactName).anonymizeGetEntitiesByName(definition.entityName, definition.servicePath);
                    },
                    getFieldTemplate: function (definition, field) {
                        return lgpdApiByArtifact.getApiOfArtifact(definition.artifactName).anonymizeGetFieldTemplate(field, definition.servicePath);
                    },
                    findFieldTemplate: function (data, firstResult, maxResults) {
                        var body = {
                            entityName: data.entityName,
                            entityField: data.entityField,
                            value: data.fieldTemplate
                        };
                        var params = {params: {firstResult: firstResult, maxResults: maxResults}};
                        return lgpdApiByArtifact.getApiOfArtifact(data.artifactName).anonymizeFindFieldTemplate(body, params, data.servicePath);
                    },
                    findFilterTemplate: function (data, firstResult, maxResults) {
                        var params = {
                            params: {
                                entityField: data.entityField,
                                firstResult: firstResult,
                                maxResults: maxResults
                            }
                        };
                        return lgpdApiByArtifact.getApiOfArtifact(data.artifactName).anonymizeFindFilterTemplate(data, params, data.servicePath);
                    },

                    anonymize: function (data, definition, id) {
                        var params = {params: {entityId: id}};
                        return lgpdApiByArtifact.getApiOfArtifact(data.artifactName).anonymize(definition, params, data.servicePath);
                    },
                    attachmentAnonymize: function (data, definition, id) {
                        var params = {params: {entityId: id}};
                        return lgpdApiByArtifact.getApiOfArtifact(data.artifactName).anonymizeAttachment(definition, params, data.servicePath);
                    },
                    getObfuscationEntitiesByServicePathAndTemplate: function (template) {
                        return lgpdApiByArtifact.getApiOfArtifact(template.artifactName).obfuscateEntitiesByName(template).then(function (response) {
                            return response.data;
                        });
                    },
                    getLogEntitiesByServicePathAndTemplate: function (template) {
                        return lgpdApiByArtifact.getApiOfArtifact(template.artifactName).logsEntitiesByName(template).then(function (response) {
                            return response.data;
                        });
                    },
                    getObfuscationEntities: function (data, firstResult, maxResults) {
                        return lgpdApiByArtifact.getApiOfArtifact(data.artifactName).obfuscateFind(data.findBy, {
                            params: {
                                firstResult: firstResult,
                                maxResults: maxResults
                            }
                        });
                    },
                    getAllObfuscationDefinitionEntityNames: function () {
                        return availableServicePaths.map(function (item) {
                            return lgpdApiByArtifact.getApiOfArtifact(item.artifactName).obfuscateAllNames()
                                .then(function (response) {
                                    return {
                                        key: item,
                                        value: response.data
                                    };
                                }).catch(function () {
                                    return {
                                        key: item,
                                        value: []
                                    };
                                });
                        });
                    },
                    saveObfuscationProfile: function (payload) {
                        return lgpdApiByArtifact.getApiOfArtifact(payload.artifactName).obfuscateSave(payload.data);
                    },

                    getLogTypes: function () {
                        return lgpdApiByArtifact.getApiOfArtifact(nlgLGPD.getMainArtifactName()).logsGetTypes().then(function (response) {
                            return response.data;
                        });
                    },
                    getlogEntities: function (data, firstResult, maxResults) {
                        return lgpdApiByArtifact.getApiOfArtifact(data.artifactName).logsFind(data.findBy, {
                            params: {
                                firstResult: firstResult,
                                maxResults: maxResults
                            }
                        });

                    },
                    getAlreadLogDefinitions: function () {
                        return availableServicePaths.map(function (item) {
                            return lgpdApiByArtifact.getApiOfArtifact(item.artifactName).logsAllNames()
                                .then(function (response) {
                                    return {
                                        key: item,
                                        value: response.data
                                    };
                                }).catch(function () {
                                    return {
                                        key: item,
                                        value: []
                                    };
                                });
                        });
                    },
                    getLgpdLogRows: function () {
                        return availableServicePaths.map(function (item) {
                            return lgpdApiByArtifact.getApiOfArtifact(item.artifactName).logExportDefinitions()
                                .then(function (response) {
                                    return {
                                        key: item,
                                        value: response.data
                                    };
                                }).catch(function () {
                                    return {
                                        key: item,
                                        value: []
                                    };
                                });
                        });
                    },
                };
            }]);
    });
