import carrierZoneModule from "./carrierZoneModule"

carrierZoneModule.service("carrierZoneBaseController", ["entityEditionService", "regionImportModal", (entityEditionService, regionImportModal) => {
    return (entityType, $scope, sourceEntity, entity) => {
        const updateService = entityEditionService.crudHelper(entityType, sourceEntity)
        $scope.entity = entity
        $scope.entityType = entityType

        $scope.map = {
            key: "key",
            zoom: 15,
            center: {
                latitude: -23.6099993,
                longitude: -46.6948204
            },
            control: {},
            refresh: true
        }

        let polygonsApi = null
        $scope.onRegisterApi = (api) => {
            polygonsApi = api
        }

        $scope.openImportModal = function () {
            regionImportModal().then(function (polygons) {
                if (polygons) {
                    polygonsApi.addPolygons(polygons, true)
                }
            })
        }

        $scope.save = () => {
            entity.polygons = polygonsApi.getPolygons()
            updateService.save(entity)
        }

        $scope.saveAndContinue = () => {
            entity.polygons = polygonsApi.getPolygons()
            updateService.save(entity, true)
        }
    }
}])