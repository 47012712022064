define([
    "angular",
    "angular-translate",
    "front-end-web-commons"
], function (angular) {
    "use strict";

    return angular.module("monitoringConfigurationModule", [
        "ui.router",
        'pascalprecht.translate'
    ]);
});