define([
    "./monitoringNodeCommandsModule"
], function (module) {
    "use strict";

    return module.service("nodeCommandMonitorableFinish", ["monitorableService", function (monitorableService) {
        return function (selection, context) {
            return monitorableService.finishMonitorables(selection.map(function (nodeIdentifier) {
                return nodeIdentifier.id;
            })).then(function () {
                return context.refilter();
            });
        };
    }]);
});