define(["./arrangementModule", "text!./nlgDeliveryUnitArrangement.html"], function (arrangementModule, nlgDeliveryUnitArrangementTemplate) {
    "use strict";

    return arrangementModule.directive("nlgDeliveryUnitArrangement", [function () {
        return {
            restrict: "E",
            scope: {
                arrangementDescription: "=",
                onRemoveOverload: "&?",
                viewOptions: "<?"
            },
            template: nlgDeliveryUnitArrangementTemplate,
            controller: "nlgArrangementController"
        };
    }]);
});