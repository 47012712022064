import cancellationModule from "./cancellationModule"
import server from "../server"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"

cancellationModule.service("r2CancellationService", [
    "$http",
    "OptimizationSource",
    "FlowType",
    "loadingService",
    "remoteExceptionHandler",
    "violationModal",
    "$q",
    "preValidationService",
    function ($http, OptimizationSource, FlowType, loadingService, remoteExceptionHandler, violationModal, $q, preValidationService) {

        var getCancelledTrips = function (result) {
            if (!result.liveTrips.length) {
                return result.tripsBefore
            }
            return arrays.filter(result.tripsBefore, function (trip) {
                return !arrays.contains(result.liveTrips, trip)
            })
        }

        return {
            cancelTrips: function (selection, context) {
                return loadingService($http.post(server.getServiceUrl("canCancel"), {
                        nodeIdentifiers: selection,
                        routingConfig: null
                    }))
                    .catch(remoteExceptionHandler())
                    .then(function (response) {
                        if (response.data) {
                            return $q.resolve()
                        }
                        return $q.reject()
                    }).then(function () {
                        var emptyDus = []
                        var preferredRoutingConfigSourceId = "Cancelamento"
                        return preValidationService.getViolationsForManualOperation(OptimizationSource.MANUAL_OPERATION, selection, emptyDus, preferredRoutingConfigSourceId, FlowType.ANALYSIS,true)
                            .then(function (configs) {
                                loadingService($http.post(server.getServiceUrl("cancelTrips"), {
                                    nodeIdentifiers: selection,
                                    routingConfig: configs.routingConfig.routingConfig
                                })) .catch(remoteExceptionHandler())
                                    .then(function (response) {
                                    return violationModal(response.data)
                                    .then(function() {
                                        var cancelledTrips = getCancelledTrips(response.data)
                                        if (cancelledTrips.length) {
                                            return loadingService($http.post(server.getServiceUrl("manualOperationSave"), response.data.token)
                                                .catch(remoteExceptionHandler()))
                                                .finally(function () {
                                                    context.refilter()
                                                })
                                        }
                                    })
                                })
                            })
                    })
            }
        }
    }])