define([
    "./clientViewConfigurationModule",
    "json!../tdnByEntity.json",
], function (module, tdnByEntity) {
    "use strict";

    return module.controller("clientViewConfigurationController", [
        "$scope",
        "$state",
        "clientViewConfigurationService",
        "configuration",
        "messagesModal",
        "tdnHelp",
        function ($scope, $state, clientViewConfigurationService, configuration, messagesModal, tdnHelp) {

        $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["client-view-configuration"];

            $scope.entity = configuration;
            $scope.hostName = getTAPUrl($scope.entity.publicUrl)
            $scope.save = function () {
                return clientViewConfigurationService.save($scope.entity).then((isSuccess) => {
                    if (isSuccess) {
                        $scope.hostName = getTAPUrl($scope.entity.publicUrl)
                    }
                });
            };

            function getTAPUrl(publicUrl) {
                let companyUrl = "/#/admin"
                return `${publicUrl}${companyUrl}`
            }

            $scope.isValid = function () {
                var entity = $scope.entity;
                return !!entity.key;
            };

            $scope.validateKey =  function () {
                if ($scope.entity.key){
                    return clientViewConfigurationService.validateKey($scope.entity.key)
                }
                 return messagesModal("dialog.warning", {
                    keyBundle: "monitoring.clientViewConfiguration.validate.empty.key"
                })
            }
        }
    ]);
});
