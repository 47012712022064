define([
    "./monitorModule"
], function (module) {
    "use strict";

    return module.service("monitorMappingParser", ["monitorMappingParserV1", "monitorMappingParserV2", function (monitorMappingParserV1, monitorMappingParserV2) {
        return function createMappings(springBootMappingFormat) {
            if (springBootMappingFormat.contexts) {
                return monitorMappingParserV2(springBootMappingFormat);
            }
            return monitorMappingParserV1(springBootMappingFormat);
        };
    }]);
});