define(["./addressModule"], function (addressModule) {
    "use strict";

    return addressModule.filter("addressFormat", ["entityFormatFilter", function (entityFormat) {
        return function (address, level) {
            if (!address) {
                return "";
            }
            var result = address.street;
            if (address.number) {
                result += ", " + address.number;
            }
            if (address.complement) {
                result += " (" + address.complement + ")";
            }
            if (level === "street") {
                return result;
            }
            if (address.zipCode) {
                result += " - " + address.zipCode.value;
            }
            if (address.city) {
                result += " - " + entityFormat(address.city);
            }
            if (address.state) {
                result += " - " + entityFormat(address.state);
            }
            if (address.country) {
                result += " - " + entityFormat(address.country);
            }
            return result;
        };
    }]);
});