define([
    "./logRecordModule",
    "text!./logRecordView.html",
], function (module,
             logRecordTemplate) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.logRecord", {
                url: "/logRecord",
                controller: "LogRecordController",
                template: logRecordTemplate,
                data: {
                    group: "group.monitoring.logRecord",
                    pageTitle: "{{'title.monitoring.logRecord' | translate:$stateParams}}"
                }
            })
    }]);
});
