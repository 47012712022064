import messageConfigParamsModule from "./messageConfigParamsModule"
import server from "../server"

messageConfigParamsModule.service("messageConfigParamsService", ["$http", function($http) {

    return {
        getMessageConfigParams: getMessageConfigParams,
        createOrUpdateConfig: createOrUpdateConfig,
        testConfigurations: testConfigurations
    }

    function getMessageConfigParams() {
        return $http.get(server.getServiceUrl("message-config-params") + "/find")
            .then((response) => response.data)
    }

    function createOrUpdateConfig (messageConfigParams)  {
        return $http.post(server.getServiceUrl("message-config-params") + "/update", messageConfigParams)
    }

    function testConfigurations (emailConfigParams, to) {
        return $http.post(server.getServiceUrl("message-config-params") + "/send-test-email?to=" + to, emailConfigParams)
    }
}])