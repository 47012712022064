define(["./nlgAuditModule"], function (mod) {
    "use strict";

    /**
     * @ngdoc directive
     *
     * @description
     * Diretiva para tabela de log em entidades não CRUD.
     * No serviço é necessário ter um método #findVersionsOf(id)#.
     *
     * @param {object} entidade
     * @param {string} nome do serviço ex: ('nlgABCService')
     * @param {string} state de acesso ex: ('access.logABC')
     *
     * @example
     * Exemplo de utilização com tab:
     *
     * <tab heading="{{'ABC.logs' | translate}}" active="false" name="logs" ng-if="model.id">
     *      <nlg-audit entity="model"
     *                  service-name="{{serviceName}}"
     *                  access-reference="{{accessReference}}"></nlg-audit>
     *</tab>
     *
     *
     * */
    return mod.directive("nlgAudit", [function () {
        return {
            templateUrl: require("link!./nlgAudit.html"),
            scope: {
                entity: "=",
                serviceName: "@",
                accessReference: "@",
            },
            controller: [
                "$scope",
                "remoteExceptionHandler",
                "$injector",
                function ($scope, remoteExceptionHandler, $injector) {

                    $scope.audTableOptions = {
                        records: [],
                        columns: [{
                            header: "edition.aud.operationTime",
                            cell: "{{ record.revisionInformation.revisionDate | date:'short' }}",
                            cellName: "revisionDate"
                        }, {
                            header: "edition.aud.user",
                            cell: "{{ record.revisionInformation.userId }}",
                            name: "userId"
                        }, {
                            header: "edition.aud.operation",
                            cell: "{{ record.revisionType }}",
                            cellName: "revisionType"
                        },],
                    };

                    var accessReference = $scope.accessReference;
                    $scope.audTableOptions.columns.push({
                        cell: {
                            template: "<div><a name='button' ui-sref ='" + accessReference + "({id: entity.id, version: record.revisionInformation.revisionNumber, artifactName: entity.artifactName})' target='_blank' class='btn btn-xs blue'><span class='fa fa-search'></span></a></div>"
                        },
                        width: "41px"
                    });

                    var service = $injector.get($scope.serviceName);
                    service.findVersionsOf($scope.entity.id, $scope.entity.artifactName).then(function (response) {
                        $scope.audTableOptions.records = response.data;
                    }).catch(remoteExceptionHandler());

                }]
        };
    }]);
});
