import module from "../monitoringDeliveryOverviewModule"
import template from "./modalSubtitles.html"


module.service("modalSubtitles", [
    "$modal",
    function ($modal){
        return function (){
            return $modal.open({
                template: template,
                size: "md",
                backdrop: "static",
            })
        }
    }
])
