define(["./mapsModule"], function (mapsModule) {
    "use strict";

    return mapsModule.service("FreeHandControlPanel", [function () {
        function FreeHandControlPanel(actionListener) {
            var controlUI = document.createElement("div");
            controlUI.style.backgroundColor = "#fff";
            controlUI.style.border = "2px solid #fff";
            controlUI.style.borderRadius = "3px";
            controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
            controlUI.style.cursor = "pointer";
            controlUI.style.marginTop = "5px";
            controlUI.style.textAlign = "center";
            var controlText = document.createElement("div");
            controlText.style.paddingLeft = "7px";
            controlText.style.paddingRight = "7px";
            controlText.style.paddingTop = "3px";
            controlText.style.paddingBottom = "3px";
            controlText.innerHTML = "<i class='fa fa-pencil'></i>";
            controlUI.appendChild(controlText);
            controlUI.addEventListener("click", actionListener);
            this.controlUI = controlUI;
        }

        FreeHandControlPanel.prototype.getElement = function () {
            var buttonDiv = document.createElement("div");
            buttonDiv.index = 1;
            buttonDiv.appendChild(this.controlUI);
            return buttonDiv;
        };

        return FreeHandControlPanel;
    }]);
});