define(["./timeModule", "lodash"], function (timeModule, _) {
    "use strict";

    return timeModule.service("dateService", [function () {
        var self = this;

        self.dateEquals = function (left, right) {
            return self.toMillis(left) === self.toMillis(right);
        };

        self.toMillis = function (date) {
            if (date instanceof Date) {
                return toMillisFromDate(date);
            }
            if (_.isNumber(date) && !_.isNaN(date)) {
                return date;
            }
            if (_.isString(date)) {
                return toMillisFromDate(new Date(date));
            }
            return null;
        };

        function toMillisFromDate(date) {
            if (!date) {
                return null;
            }
            var result = date.getTime();
            if (_.isNaN(result)) {
                return null;
            }
            return result;
        }

        return self;
    }]);
});