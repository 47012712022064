define(["./nlgNavigateModule"], function (nlgNavigateModule) {
    "use strict";

    return nlgNavigateModule.directive("nlgNavigate", [function () {
        return {
            restrict: "A",
            link: function ($scope, $element, $attrs) {
                $scope.$watch($attrs.nlgNavigate, function (href) {
                    if (!href) {
                        $element.removeAttr("href");
                        $element.attr("style", "cursor: default;");
                        $element.addClass("disabled");
                        $element.on("click", stopPropagation);
                    } else {
                        $element.attr("href", href);
                        $element.removeClass("disabled");
                    }
                });
            }
        };
    }]);

    function stopPropagation(ev) {
        ev.stopPropagation();
    }
});