define([
    "../nlgLGPDModule"
], function (module) {
    "use strict";

    return module.service("LGPDGenericParamService", [
        "$http",
        "loadingService",
        "lgpdApiByArtifact",
        "nlgLGPD",
        function ($http, loadingService, lgpdApiByArtifact, nlgLGPD) {
            var artifactName = nlgLGPD.getMainArtifactName();
            var servicePath = nlgLGPD.getGenericParamPath();

            function filterRecords(template, firstResult, maxResults) {
                var params = {params: {firstResult: firstResult, maxResults: maxResults}};
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).filterRecords(servicePath, template, params));
            }

            function save(lgpdDefinition) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).saveDefinition(servicePath, lgpdDefinition));
            }

            function getDefinitions() {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).getDefinitions(servicePath));
            }

            function getEntity(id) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).getDefinitionById(servicePath, id));
            }

            function copy(id) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).copyById(servicePath, id));
            }

            function deleteEntity(id) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).deleteById(servicePath, id));
            }

            function findVersionsOf(id) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).findVersionsOf(servicePath, id));
            }

            function findVersion(id, version) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).findVersion(servicePath, id, version));
            }

            return {
                getDefinitions: getDefinitions,
                getEntity: getEntity,
                filterRecords: filterRecords,
                save: save,
                copy: copy,
                deleteEntity: deleteEntity,
                findVersionsOf: findVersionsOf,
                findVersion: findVersion,
            };
        }]);
});
