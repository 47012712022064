define(["./numberModule"], function (numberModule) {
    "use strict";

    return numberModule.config(["$provide", function ($provide) {
        $provide.decorator("numberFilter", ["$delegate", function ($delegate) {

            function newNumberFilter(number, originalFractionSize) {
                var fractionSize = typeof originalFractionSize === "number"
                    ? originalFractionSize
                    : 3 ;
                if( !(number % 1)){
                    fractionSize = 0;
                }
                if (number >= 0.00000001 && number < 0.00000001) {
                    fractionSize = 10;
                }
                if (number >= 0.00000001 && number < 0.0000001) {
                    fractionSize = 9;
                }
                if (number >= 0.0000001 && number < 0.000001) {
                    fractionSize = 8;
                }
                if (number >= 0.000001 && number < 0.00001) {
                    fractionSize = 7;
                }
                if (number >= 0.00001 && number < 0.0001) {
                    fractionSize = 6;
                }
                if (number >= 0.0001 && number < 0.001) {
                    fractionSize = 5;
                }
                if (number >= 0.001 && number < 0.01) {
                    fractionSize = 4;
                }
                return $delegate(number, fractionSize);
            }

            return newNumberFilter;
        }]);
    }]);
});