define([
    "./monitoringEditionModule",
    "text!./monitoringEditionView.html"
], function(module, template){
    "use strict";

    return module.directive("monitoringEdition", [function(){
        return {
            restrict: "E",
            scope: {
                entity: "<",
                save: "&",
                back: "&"
            },
            template: template,
            transclude: true
        };
    }]);
});