define(["angular", "./commonsUserModule"],
    function (angular, commonsUserModule) {
        "use strict";

    commonsUserModule.provider("commonsUserService", [function () {
        var currentUserUrl = null;

        this.setCurrentUserUrl = function (url) {
            currentUserUrl = url;
        };

        this.$get = ["$http", function ($http) {
            return {
                getCurrentUser: function() {
                    return $http.get(currentUserUrl).then(function (response) {
                        return response.data;
                    });
                }
            };
        }];
    }]);
});