define(["../workHoursControlModule",
        "json!../config/workHoursControl.json",
        "front-end-web-commons/app/arrays/arrays",
        "lodash"],
    function (workHoursControlModule, workHoursControl, arrays, _) {
        "use strict";

        return workHoursControlModule.controller("WorkHoursControlSearchController", [
            "$scope",
            "loadingService",
            "remoteExceptionHandler",
            "workHoursControlService",
            "spreadsheetServiceInvoker",
            "$translate",
            "$q",
            function ($scope, loadingService, remoteExceptionHandler, workHoursControlService, spreadsheetServiceInvoker, $translate, $q) {
                var template, paginationApi;

                $scope.serviceName = workHoursControl.services.workRecord;

                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    paginationApi.seekPage(1);
                };

                $scope.tableOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(workHoursControlService.filterWorkRecords(template, firstResult, maxResults))
                                .then(function (result) {
                                    return result;
                                }).catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                    }
                };

                $scope.exportSpreedSheet = function () {
                    var exportDefer = $q.defer();
                    var maxResults = 10000;
                    var fileName = $translate.instant("workHoursControl.spreadsheet.file.name") || "workHoursControl";
                    exportFrom(0);
                    loadingService(exportDefer.promise)
                        .catch(remoteExceptionHandler());

                    function exportFrom(firstResult) {
                        var records = [];
                        return workHoursControlService.filterWorkRecords(template, firstResult, maxResults)
                            .then(function (filterResult) {
                                arrays.addAll(records, filterResult.result);
                                doExport(records);
                                if (records.length === maxResults) {
                                    var currentResult = firstResult + maxResults;
                                    return exportFrom(currentResult);
                                } else {
                                    exportDefer.resolve();
                                }
                            }).catch(exportDefer.reject);
                    }

                    function doExport(records) {
                        var translatePrefix = "workHoursControl.spreadsheet.header.";
                        var dataToExport = _.map(records, function (record) {
                            var formatted = {};
                            formatted[$translate.instant(translatePrefix + "driverName")] = record.user.name;
                            formatted[$translate.instant(translatePrefix + "driverNumber")] = record.driverNumber;
                            formatted[$translate.instant(translatePrefix + "type")] = $translate.instant(record.type);
                            formatted[$translate.instant(translatePrefix + "recordDate")] = new Date(record.recordDate).toLocaleString();
                            return formatted;
                        });
                        var spreadsheetStyle = {
                            headers: true,
                            column: {
                                style: {
                                    Font: {
                                        Bold: "1",
                                        Color: "#FFFFFF"
                                    },
                                    Alignment: {
                                        Horizontal: "Center"
                                    },
                                    Interior: {
                                        Color: "#1CBBB2",
                                        Pattern: "Solid"
                                    }
                                }
                            }
                        };
                        spreadsheetServiceInvoker.exportData(fileName, dataToExport, spreadsheetStyle);
                    }
                };

            }]);
    });