import sessionRecordsModule from "./sessionRecordsModule"
import server from "../server"

sessionRecordsModule.service("sessionRecordsModalService", ["$http", function searchService($http) {

    this.filter = (entityType, template, firstResult, maxResults, sessionId) => {
        return $http.post(server.getServiceUrl("search." + entityType) + "/" + sessionId + "/presentation-tree", template, {
            params: {
                firstResult,
                maxResults
            }
        }).then((response) => response.data)
    }
}])