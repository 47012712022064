define(["angular",
    "angular-google-maps",
    "angular-translate",
    "angular-aria",
    "angular-messages",
    "angular-material",
    "../colorpicker/colorpicker",
    "angular-bootstrap",
    "../violation/violation",
    "../routingConfig/routingConfig",
    "../directives/directives",
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name mapsModule
     * @description
     * Módulo com funcionalidades relacionados ao Google Maps.
     * */
    return angular.module("mapsModule", [
        "uiGmapgoogle-maps",
        "pascalprecht.translate",
        "colorpickerModule",
        "ui.bootstrap",
        "violationModule",
        "routingConfigModule",
        "directivesModule",
        "ngMaterial",
    ]);
});