define(["../../userManagementModule",
    "front-end-web-commons/app/objects/objects",
    "front-end-web-commons/app/arrays/arrays"
], function (userManagementModule, objects, arrays) {
    "use strict";

    return userManagementModule.controller("UserManagementProfileEditionController", [
        "$scope",
        "profile",
        "$state",
        "$translate",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        "userManagementService",
        function ($scope, profile, $state, $translate, loadingService, remoteExceptionHandler, messagesModal, userManagementService) {
            $scope.profile = profile;
            $scope.profile.permission = [];
            $scope.allProfilesSelected = false;
            loadAllProfiles($scope.profile);

            $scope.isNewInstance = function () {
                return profile.id === null;
            };

            (function orderPermissions() {
                var allGroups = profile.permissions;
                activateCheckBox(allGroups);
                $scope.generalGroups = allGroups.sort(groupComparator);
            })();

            function groupComparator(group1, group2) {
                return objects.compare($translate.instant(group1.permission.permission), $translate.instant(group2.permission.permission));
            }

            function activateCheckBox(groups) {
                arrays.each(groups, function (group) {
                    if (!group.active) {
                        $scope.profile.permission.selected = false;
                        return arrays.each.BREAK;
                    }
                    $scope.profile.permission.selected = true;
                });
            }

            function activateCheckBoxProfile(groups) {
                arrays.each(groups, function (group) {
                    if (!group.active) {
                        $scope.allProfilesSelected = false;
                        return arrays.each.BREAK;
                    }
                    $scope.allProfilesSelected = true;
                });
            }

            $scope.save = function () {
                $scope.profile.profiles = $scope.allProfiles;
                return loadingService(userManagementService.saveProfile($scope.profile)
                    .catch(remoteExceptionHandler())
                    .then(function (id) {
                        messagesModal("dialog.success", [{
                            keyBundle: "entity.validator.successMessage",
                            parameters: []
                        }]);
                        return $state.go("userManagement.profileEdit", {
                            profileId: id
                        });
                    })
                );
            };

            $scope.back = function () {
                return $state.go("userManagement.profile", {
                    reload: true
                });
            };

            $scope.selectUnselectAll = function () {
                if ($scope.profile.permission.selected) {
                    arrays.each(profile.permissions, function (permission) {
                        permission.active = true;
                    });
                }
                if (!$scope.profile.permission.selected) {
                    arrays.each(profile.permissions, function (permission) {
                        permission.active = false;
                    });
                }
            };

            $scope.changeSelection = function () {
                activateCheckBox(profile.permissions);
            };

            $scope.hideCheckBox = function () {
                return profile.permissions.length == 0;
            };

            $scope.selectUnselectAllProfiles = function (newValue) {
                $scope.allProfilesSelected = newValue;
                arrays.each($scope.allProfiles, function (profile) {
                    profile.active = $scope.allProfilesSelected;
                });
            };

            $scope.changeSelectionProfile = function () {
                activateCheckBoxProfile($scope.allProfiles);
            };

            function loadAllProfiles (profileLoaded) {
                loadingService(userManagementService.findAllProfiles()).then(function (result) {
                    $scope.allProfiles = result;

                    arrays.each(profileLoaded.profiles, function (loaded) {
                        var filtered = $scope.allProfiles.find(function (profile){
                            return profile.sourceId == loaded.sourceId;
                        });
                        if(filtered){
                            filtered.active = true;
                        }
                    });
                    $scope.changeSelectionProfile();
                }).catch(remoteExceptionHandler());
            }

        }]);
});