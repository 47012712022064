define([
    "./nlgScriptSqlQueryReportModule",
    "text!./nlgScriptSqlQueryReport.html",
], function (module, template) {
    "use strict";
    return module.directive("nlgScriptSqlQueryReport", [function () {
        return {
            restrict: "E",
            scope: {
                sqlBaseUrl: "@",
                sqlFilterServiceUrl: "@",
                domain: "@",
                filterService: "=",
                query: "<"
            },
            template: template,
            controller: ["$scope",
                function (
                    $scope
                ) {
                    if ($scope.query) {
                        $scope.filterService = $scope.sqlFilterServiceUrl + $scope.query.id;
                    } else {
                        $scope.filterService = null;
                    }
                }]
        };
    }]);
});
