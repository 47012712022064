import angular from "angular"
import "angular-route"
import "angular-ui-router"
import "angular-bootstrap"
import "ngstorage"
import "angular-animate"
import "../r2-translate/translate"

export default angular.module("authentication", [
    "ngRoute",
    "ui.router",
    "ui.bootstrap",
    "r2TranslateModule",
    "ngAnimate",
    "ngStorage"
])