define([
    "./tdnHelpModule"
], function (tdnHelpModule) {
    "use strict";
    tdnHelpModule.provider("tdnHelp", [function () {
        var context;

        this.setContext = function (contextName) {
            context = contextName;
        };

        this.$get = [function () {
            return {
                getContext: function() {
                    return context;
                }
            };
        }];
    }]);
});
