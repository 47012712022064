define([
    "./nlgTruckAllocationModule"
], function (nlgTruckAllocationModule) {
    "use strict";
    nlgTruckAllocationModule.provider("nlgTruckAllocation", [function () {

        var filter;
        var service;
        var find;

        this.$get = [function () {
            return {
                createFilter: function () {
                    return filter;
                },

                createService: function () {
                    return service;
                },

                createFind: function () {
                    return find;
                }
            };
        }];

        this.setFilterService = function (path) {
            filter = path.filter;
            service = path.service;
            find = path.find;
        };
    }]);
});