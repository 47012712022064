define([
    "../occurrencesModule",
    "text!./nlgTableDynamicManagerModal.html",
], function (module, template) {
    "use strict";

    return module.service("nlgTableDynamicManagerModal", ["$modal", "monitoringService", "loadingService", function ($modal, monitoringService, loadingService) {
        return function (tableDynamicDefinition) {
            return $modal.open({
                template: template,
                backdrop: "static",
                size: "lg",
                controller: ["$scope", function ($scope) {
                    $scope.tableDynamicDefinition = tableDynamicDefinition;
                    $scope.cancel = function () {
                        return $scope.$dismiss();
                    };
                    $scope.save = function () {
                        var hiddenColumnIdentifiers = $scope.tableDynamicDefinition.definitions.filter(function (definition) {
                            return !definition.show;
                        }).map(function (definition) {
                            return definition.cellName;
                        });
                        var tableDefinitions = {
                            identifier: $scope.tableDynamicDefinition.identifier,
                            hiddenColumnIdentifiers: hiddenColumnIdentifiers
                        };
                        return loadingService(monitoringService.saveDynamicTableDefinitions(tableDefinitions).then(function (response) {
                            return $scope.$close(response);
                        }));
                    };
                }]
            }).result;
        };
    }]);
});
