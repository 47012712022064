define(["./timeModule", "text!./datePicker.html"], function datePicker(timeModule, template) {
    "use strict";

    return timeModule.directive("datePicker", ["$document", function ($document) {
        return {
            template: template,
            scope: {
                "date": "=ngModel",
                "change": "=?ngChange",
                "ngDisabled": "=?"
            },
            require: "ngModel",
            controller: ["$scope", function ($scope) {
                $scope.popUp = {
                    open: false
                };

                $scope.openPopUp = function () {
                    $scope.popUp.open = true;
                };
            }],
            link: function ($scope, $element, $attrs, ngModelController) {
                if ($scope.date !== null) {
                    ngModelController.$setViewValue(instantiateDate());
                    ngModelController.$commitViewValue();
                    ngModelController.$validate();
                }

                function instantiateDate() {
                    var result = new Date($scope.date);
                    if (isValidDate(result)) {
                        return undefined;
                    }
                    return result;
                }

                function isValidDate(value) {
                    var time = value.getTime();
                    return time !== time;
                }

                document.addEventListener("scroll", closePopup, true);
                $document.on("click", closePopup);
                $scope.$on("$destroy", function () {
                    $scope.popUp.open = false;
                    $document.off("click", closePopup);
                    document.removeEventListener("scroll", closePopup, true);
                });

                function closePopup(ev) {
                    var isOpen = $scope.popUp.open;
                    var isTargetingDocument = ev.target === $document[0];
                    var isTargetOnElement = $element[0].contains(ev.target);
                    if (isOpen && !(isTargetingDocument || isTargetOnElement)) {
                        $scope.$apply(function () {
                            $scope.popUp.open = false;
                        });
                    }
                }
            }
        };
    }]);
});