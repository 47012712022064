import unblockTripMessagesModule from "./unblockTripMessagesModule"
import server from "../server"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"

unblockTripMessagesModule.controller("unblockTripMessagesController", [
    "$scope", "$q", "loadingService", "unblockTripMessagesService", "unblockTripMessageDialog", "messagesModal", "remoteExceptionHandler", ($scope, $q, loadingService, unblockTripMessagesService, unblockTripMessageDialog, messagesModal, remoteExceptionHandler) => {

        const paginationApi = $q.defer()
        let filterPatch = []

        $scope.filterServiceUrl = server.getRelativeUrl("search.unblockTripMessages")

        $scope.tableOptions = {
            records: [],
            columns: [{
                cell: {
                    template: "<a name='forceProcess' class='btn btn-xs red' style='margin-left: 5px; margin-top: 4px;' ng-click='forceProcessMessage(record)'><span class='fa fa-trash'></span></a>"
                },
                cellName: "forceProcessButton",
                width: "10px"
            },{
                header: "presentationnode.nodeproperty.unblocktripmessage.id",
                cell: "{{record.mappedProperties['presentationnode.unblocktripmessage.id'].value}}",
                cellName: "id"
            },{
                header: "presentationnode.nodeproperty.unblocktripmessage.tripCodes",
                cell: "{{record.mappedProperties['presentationnode.unblocktripmessage.tripCodes'].value}}",
                cellName: "tripCodes"
            },{
                header: "presentationnode.nodeproperty.unblocktripmessage.lastupdate",
                cell: "{{record.mappedProperties['presentationnode.unblocktripmessage.lastupdate'].value | date:'short'}}",
                cellName: "lastupdate"
            },{
                header: "presentationnode.nodeproperty.unblocktripmessage.status",
                cell: "{{record.mappedProperties['presentationnode.unblocktripmessage.status'].value}}",
                cellName: "status"
            },{
                cell: {
                    template: "<a name='inspect' class='btn btn-xs btn-primary' style='margin-left: 5px; margin-top: 4px;' ng-click='inspectMessage(record)'><span class='fa fa-search'></span></a>"
                },
                cellName: "inspectButton",
                width: "10px"
            }],
            pagination: {
                onPaginate: function (firstResult, maxResults) {
                    return loadingService(unblockTripMessagesService.filter("unblockTripMessages", filterPatch, firstResult, maxResults)
                        .then(function (response) {
                            response.result = response.result[0].children
                            mapChildrenProperties(response.result)
                            return response
                        }))
                }
            },
            onRegisterPagination: function (api) {
                paginationApi.resolve(api)
            }
        }

        $scope.forceProcessMessage = function(record){
            messagesModal.cancellable("dialog.proceed?", [{
                keyBundle: "interface.unblockTripMessage.forceProcessMessageWarning",
                parameters: [record.id]
            }]).then(function(){
                return unblockTripMessagesService.forceProcessMessage(record.id)
                    .then(function() {
                    return messagesModal("dialog.success", [{
                        keyBundle: "interface.unblockTripMessage.forceProcessMessageSuccess",
                        parameters: []
                    }])
                    .finally(function(){
                        $scope.search( filterPatch )
                    })
                }, remoteExceptionHandler())
            })
        }

        $scope.inspectMessage = (record) => {
            unblockTripMessagesService.find(record.id)
                .then((response) => {
                    unblockTripMessageDialog(response.entity)
                }).catch(remoteExceptionHandler())
        }

        function mapChildrenProperties(children) {
            arrays.each(children, function (child) {
                var map = {}
                arrays.each(child.properties, function (property) {
                    map[property.messageKey] = property
                })
                child.mappedProperties = map
            })
        }

        $scope.search = function (patch) {
            filterPatch = patch
            return paginationApi.promise.then((api) => api.seekPage(1))
        }
    }])
