import classificationModule from "./classificationModule"
import _ from "lodash"

classificationModule.controller("classificationController", ["$scope", "sourceEntity", "entity", "entityEditionService", "classificationService", "messagesModal", "orderByFilter", ($scope, sourceEntity, entity, entityEditionService, classificationService, messagesModal, orderByFilter) => {
    const updateService = entityEditionService.crudHelper("classification", sourceEntity)
    $scope.entity = entity

    $scope.entity.availableClassifications = (function () {
        if ($scope.entity.availableClassifications) {
            return orderByFilter($scope.entity.availableClassifications, "sourceId")
        }
        return []
    })()

    $scope.entity.defaultClassification = (() => {
        if (!$scope.entity.availableClassifications.length || !$scope.entity.defaultClassification) {
            return null
        }
        return _.find($scope.entity.availableClassifications, (classification) => {
            return classification.sourceId === $scope.entity.defaultClassification.sourceId
        })
    })()

    $scope.isEditable = (entity) => !entity.id

    $scope.getClassifiableTypes = classificationService.getClassifiableTypes

    $scope.isDefaultClassification = function (classification) {
        if ($scope.entity.defaultClassification.id) {
            return classification.id === $scope.entity.defaultClassification.id
        }
        return classification === $scope.entity.defaultClassification
    }

    $scope.addClassification = function () {
        let newClassification = {
            id: null
        }
        if (!$scope.entity.availableClassifications.length) {
            $scope.entity.defaultClassification = newClassification
        }
        $scope.entity.availableClassifications.push(newClassification)
    }

    $scope.removeClassification = function (index) {
        $scope.entity.availableClassifications.splice(index, 1)
    }

    $scope.save = () => {
        if (entity.id) {
            updateService.save(entity)
            return
        }
        cancellableModal().then(() => updateService.save(entity))
    }

    $scope.saveAndContinue = () => {
        cancellableModal().then(() => updateService.save(entity, true))
    }

    function cancellableModal() {
        return messagesModal.cancellable("dialog.proceed?", [{
            keyBundle: "classifications.edition.save",
            parameters: []
        }])
    }
}])