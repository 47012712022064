define([
    "./remoteModule",
    "text!./remoteExceptionHandler.html",
    "angular",
    "file-saver",
    "lodash"
], function (remoteModule, remoteExceptionHandlerTemplate, angular, fileSaver, _) {
    "use strict";

    var save = fileSaver.saveAs || fileSaver;

    var HTTP_STATUS = {
        NOT_MODIFIED: 304,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        PAYMENT_REQUIRED: 402,
        FORBIDDEN: 403,
        NOT_FOUND: 404,
        REQUEST_TIMEOUT: 408,
        UNPROCESSABLE_ENTITY: 422,
        INTERNAL_SERVER_ERROR: 500,
        GATEWAY_TIMEOUT: 504
    };

    /**
     * @ngdoc service
     * @name remoteModule.remoteExceptionhandler
     * @description
     * Serviço para tratar errors gerados à partir de uma conexão remota.
     *
     * Deve ser usado da seguinte forma:
     * ```
     *  $http
     *   .get("/path/to/server")
     *   .catch(remoteExceptionHandler(function (error) {
     *      // tratamento de outros erros que podem ocorrer, como bad requeste etc..
     *   }));
     * ```
     *
     * @param {function} nextErrorHandler Receberá um objeto de erro do servidor e será invocada quando
     * o erro não puder ser tratado por este serviço.
     * */
    return remoteModule.service("remoteExceptionHandler", ["$modal", "$translate", "messagesModal", "$q", "$log", "remoteLogger", function ($modal, $translate, messagesModal, $q, $log, remoteLogger) {
        return function (nextErrorHandler) {
            return function (error) {
                error = error || {};
                var handledError;
                var errorStatus = error.status;
                if (errorStatus === HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                    handledError = {type: "InternalServerError", message: error.data};
                } else if (errorStatus === HTTP_STATUS.FORBIDDEN || errorStatus === HTTP_STATUS.UNAUTHORIZED) {
                    return messagesModal("remoteExceptionHandler.AccessDenied", [{
                        keyBundle: "remoteExceptionHandler.AccessDenied.message"
                    }]).then(propagateRejection);
                } else if (errorStatus === HTTP_STATUS.BAD_REQUEST || errorStatus === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
                    return messagesModal("dialog.error", getMessages(error))
                        .then(propagateRejection);
                } else if (errorStatus === HTTP_STATUS.NOT_FOUND) {
                    handledError = {type: "NotFound", message: error.config.url};
                } else if ((errorStatus === 0 || errorStatus === HTTP_STATUS.REQUEST_TIMEOUT || errorStatus === HTTP_STATUS.GATEWAY_TIMEOUT) && !error.data) {
                    handledError = {
                        type: "Timeout",
                        message: $translate.instant("remoteExceptionHandler.Timeout.message")
                    };
                } else if (errorStatus === HTTP_STATUS.NOT_MODIFIED) {
                    return messagesModal("dialog.warning", [{
                        keyBundle: "remoteExceptionHandler.NotModified.message"
                    }]).then(propagateRejection);
                } else if (errorStatus === HTTP_STATUS.PAYMENT_REQUIRED) {
                    return messagesModal("dialog.warning", [{
                        keyBundle: "remoteExceptionHandler.payment.required"
                    }]).then(propagateRejection);
                } else if (angular.isUndefined(nextErrorHandler)) {
                    handledError = {type: "UnknownError", message: error.data || error};
                } else {
                    return (nextErrorHandler || $log.error)(error);
                }
                return $modal.open({
                    template: remoteExceptionHandlerTemplate,
                    controller: ["$scope", function ($scope) {
                        $scope.error = handledError;
                        if (!_.isString($scope.error.message)) {
                            $scope.error.message = formatToJson($scope.error.message);
                        }
                        var allInfo = {
                            error: error,
                            latestRequests: remoteLogger.get()
                        };
                        $scope.downloadReport = function () {
                            save(new Blob([formatToJson(allInfo)]), "error-report.json");
                        };
                    }]
                }).result;

                function propagateRejection() {
                    return $q.reject(error);
                }
            };
        };
    }]);

    function formatToJson(object) {
        var seen = new window.Set();

        return JSON.stringify(object, ignoreCyclic, 2);

        function ignoreCyclic(key, value) {
            if (typeof value !== "object") {
                return value;
            }
            if (value === null) {
                return value;
            }
            if (seen.has(value)) {
                return "[CYCLIC REFERENCE:" + value.toString() + "]";
            }
            seen.add(value);
            return value;
        }
    }

    function getMessages(error) {
        return error.data.messages
            || getExceptionMessage()
            || error.data;

        function getExceptionMessage() {
            if (!error.data.exception) {
                return null;
            }
            return error.data.exception + ": " + error.data.message;
        }
    }
});