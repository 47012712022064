define([
    "./monitorModule",
    "./monitorTracingConfiguration",
    "./monitorRouter",
    "./MonitorApplicationController",
    "./monitorMappingParser",
    "./monitorMappingParserV1",
    "./monitorMappingParserV2",
    "./MonitorOverviewController",
    "./monitorService",
    "./monitorUrlService",
    "./nlgMonitorHealth",
    "./nlgMonitorLogNavigator",
    "./nlgMonitorLogSearch",
    "./nlgMonitorLogSearchResult",
    "./nlgMonitorLogTail",
    "./nlgMonitorLogTree",
    "./nlgMonitorMapping",
    "./nlgMonitorMetricsChart",
    "./nlgMonitorMetricsV2",
    "./nlgMonitorRequestEnv",
    "./nlgMonitorRequestEnvV1Service",
    "./nlgMonitorRequestEnvV2Service",
    "./nlgMonitorRequestHistory"
], function () {
});