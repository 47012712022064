import gatewayServer from "./gatewayServer"
import server from "./server"

const userManagementBaseUrl = gatewayServer.gatewayBaseUrl + "/usermanagement"
const services = {
    "user.creation": "/user/create",
    "user.deletion": "/user/delete",
    "profile": "/profile/autocomplete",
    "permission-group": "/permission-group/",
    "permission-group.find": "/permission-group/find",
    "permission-group-calculate-code" : "/permission-group/calculate-code"
}

export const userManagementServer = {
    getBaseUrl() {
        return userManagementBaseUrl
    },
    getServiceUrl(service: keyof typeof services) {
        const relativeUrl = services[service]
        if (!relativeUrl) {
            return relativeUrl
        }
        return server.concatPaths(this.getBaseUrl(), relativeUrl)
    }
}

export default userManagementServer