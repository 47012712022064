define(["angular", "./transitionSpreadsheetModule", "text!./transitionSpreadsheetService.html", "lodash"], function (angular, transitionSpreadsheetModule, template) {
    "use strict";

    transitionSpreadsheetModule.service("transitionSpreadsheetService", ["$modal", "$translate", "mbrService", "loadingService", "remoteMessageModal", "remoteExceptionHandler", "$http", "$q", "messagesModal", "downloadBinary", function ($modal, $translate, mbrService, loadingService, remoteMessageModal, remoteExceptionHandler, $http, $q, messagesModal, downloadBinary) {

        return {
            openImportModal: openImportModal,
            multiplesExportTransitionSpreadsheet: multiplesExportTransitionSpreadsheet
        };

        function multiplesExportTransitionSpreadsheet(monitorableId, spreadsheetName) {
            return loadingService(downloadBinary({
                url: "/cockpit-gateway/monitoring/exportTransition/monitorable?id=" + monitorableId,
                method: "POST"
            }, spreadsheetName + ".xls"))
                .catch(remoteExceptionHandler());
        }

        function openImportModal() {
            return $modal.open({
                template: template,
                size: "md",
                controller: ["$scope", "$modalInstance", function ($scope) {
                    $scope.importSpreadsheet = function () {
                        var file = {
                            file: $scope.model.file
                        };
                        return loadingService(mbrService.importTransitionSpreadsheet(file)).then(function (remoteMessages) {
                            return remoteMessageModal(remoteMessages);
                        }).catch(function (errorResponse) {
                            if (angular.isUndefined(errorResponse) || angular.isUndefined(errorResponse.data)) {
                                return
                            }
                            return remoteExceptionHandler()(errorResponse);
                        }).then(function () {
                            $scope.$close();
                        })
                    }
                }
                ]
            }).result;
        }
    }
    ]);
});
