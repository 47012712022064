define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryTimeTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no `input` associado
     **/

    /**
     * @ngdoc function
     * @name templateFactoryTimeTemplate.templateFactoryTimeTemplate
     * @description
     * Template utilizado para criar um `input` do tipo `time`.
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     **/
    return templateFactoryModule.service("templateFactoryTimeTemplate", [function () {
        return function (config) {
            var element = angular.element("<nlg-timepicker>");
            element.attr("ng-model", config.model);
            element.attr("nlg-placeholder", config.placeholder);
            element.attr("nullable", config.nullable);
            return element;
        };
    }]);
});