define(["./nlgPaginationModule", "angular", "../objects/objects", "../arrays/arrays", "text!./nlgPagination.html"],
    function (nlgPaginationModule, angular, objects, arrays, paginationBarTemplate) {
        "use strict";

        /**
         * @ngdoc directive
         * @name directives.directive:nlgPagination
         * @restrict A
         * @description
         * Diretiva de paginação de propósito geral.
         * Cria um rodapé logo abaixo do componente em que for declarada para permitir a navegação entre as páginas.
         */
        return nlgPaginationModule.directive("nlgPagination", ["$compile", "$q", function ($compile, $q) {
            return {
                restrict: "A",
                require: ["nlgPagination"],
                priority: 50,
                controller: [function () {
                    var pagesArray = [],
                        selfController = this;

                    selfController.paginationOptions = {};

                    selfController.initialize = function (options) {
                        /**
                         * @ngdoc object
                         * @name directives.directive.nlgPagination:paginationOptions
                         * @description Objeto responsável por armazenar configurações para o componente de paginação
                         */
                        /**
                         * @ngdoc object
                         * @name selfController.paginationOptions
                         * @propertyOf directives.directive.nlgPagination:paginationOptions
                         * @description `Array` de objetos resultantes do serviço de busca atrelado à paginação
                         */
                        options = objects.enrich(options, {records: []});
                        options.pagination = options.pagination || {};
                        options.pagination = objects.enrich(options.pagination, {
                            /**
                             * @ngdoc number
                             * @name selfController.paginationOptions
                             * @propertyOf directives.directive.nlgPagination:paginationOptions
                             * @description Número máximo de resultados por página.
                             */
                            maxPageSize: 25,
                            /**
                             * @ngdoc Array
                             * @name selfController.paginationOptions
                             * @propertyOf directives.directive.nlgPagination:paginationOptions
                             * @description `Array` contendo os números máximos de resultados por página válidos.
                             */
                            maxPageSizes: [10, 25, 50, 100],
                            /**
                             * `Offset` da busca de paginação.
                             */
                            firstResult: 0,
                            /*
                             * Número total de registros. Utilizado pela diretiva de
                             * paginação para calcular as páginas para navegação.
                             */
                            count: 0,
                            /**
                             * Índice `one based` da página corrente.
                             */
                            currentPage: 1
                        });
                        selfController.paginationOptions = options;
                    };

                    selfController.setMaxPageSize = function (maxPageSize) {
                        if (arrays.contains(selfController.paginationOptions.pagination.maxPageSizes, maxPageSize)) {
                            selfController.paginationOptions.pagination.maxPageSize = maxPageSize;
                            selfController.goToPage(1);
                        }
                    };
                    selfController.getPages = function () {
                        return pagesArray;
                    };

                    selfController.goToPageIfNotCurrent = function (pageNumber) {
                        if (pageNumber !== selfController.paginationOptions.pagination.currentPage) {
                            selfController.goToPage(pageNumber);
                        }
                    };

                    selfController.goToPage = function (pageNumber) {
                        selfController.paginationOptions.pagination.currentPage = pageNumber;
                        var firstResult = convertPageIndexToOffset(pageNumber);
                        return paginate(firstResult, selfController.paginationOptions.pagination.maxPageSize);
                    };
                    selfController.getLowerResult = function () {
                        return (selfController.paginationOptions.pagination.currentPage - 1) * selfController.paginationOptions.pagination.maxPageSize + 1;
                    };
                    selfController.getHigherResult = function () {
                        return Math.min(selfController.paginationOptions.pagination.currentPage * selfController.paginationOptions.pagination.maxPageSize, selfController.paginationOptions.pagination.count);
                    };
                    selfController.recalculatePages = function () {
                        pagesArray = generatePagesArray(selfController.paginationOptions.pagination.currentPage, selfController.paginationOptions.pagination.count, selfController.paginationOptions.pagination.maxPageSize);
                    };

                    function paginate(firstResult, maxResults) {
                        /**
                         * @ngdoc function
                         * @name paginationOptions.pagination.onPaginate
                         * @propertyOf directives.directive.nlgPagination:paginationOptions
                         * @description `Callback` a ser disparada ao navegar na paginação. Será invocada
                         * recebendo `firstResult` e `maxResults`. Deve ser informada para agir como
                         * interface com o componente para busca dos registros da tabela.
                         */
                        return $q.when(selfController.paginationOptions.pagination.onPaginate(firstResult, maxResults)).then(function (dtos) {
                            selfController.paginationOptions.pagination.firstResult = dtos.firstResult;
                            selfController.paginationOptions.pagination.maxPageSize = dtos.maxResults;
                            selfController.paginationOptions.pagination.count = dtos.count;
                            selfController.paginationOptions.records = dtos.result;
                        }).then(selfController.recalculatePages);
                    }

                    function convertPageIndexToOffset(pageIndex) {
                        if (pageIndex === 1) {
                            return 0;
                        }
                        return (pageIndex - 1) * selfController.paginationOptions.pagination.maxPageSize;
                    }

                    function generatePagesArray(currentPage, totalItems, pageSize) {
                        var maxBlocks = 11,
                            maxPage,
                            maxPivotPages,
                            minPage,
                            numPages = Math.ceil(totalItems / pageSize),
                            pages = [];

                        if (numPages >= 1) {
                            pages.push({
                                type: "prev",
                                number: Math.max(1, currentPage - 1),
                                active: currentPage > 1
                            });
                            pages.push({
                                type: "first",
                                number: 1,
                                active: currentPage > 1,
                                current: currentPage === 1
                            });
                            maxPivotPages = Math.round((maxBlocks - 5) / 2);
                            minPage = Math.max(2, currentPage - maxPivotPages);
                            maxPage = Math.min(numPages - 1, currentPage + maxPivotPages * 2 - (currentPage - minPage));
                            minPage = Math.max(2, minPage - (maxPivotPages * 2 - (maxPage - minPage)));
                            var i = minPage;
                            while (i <= maxPage) {
                                if ((i === minPage && i !== 2) || (i === maxPage && i !== numPages - 1)) {
                                    pages.push({
                                        type: "more",
                                        active: false
                                    });
                                } else {
                                    pages.push({
                                        type: "page",
                                        number: i,
                                        active: currentPage !== i,
                                        current: currentPage === i
                                    });
                                }
                                i++;
                            }
                            if (numPages !== 1) {
                                pages.push({
                                    type: "last",
                                    number: numPages,
                                    active: currentPage !== numPages,
                                    current: currentPage === numPages
                                });
                            }
                            pages.push({
                                type: "next",
                                number: Math.min(numPages, currentPage + 1),
                                active: currentPage < numPages
                            });
                        }
                        return pages;
                    }
                }],
                link: {
                    pre: function (scope, element, attrs, controllers) {
                        // expõe um alias para o controller da diretiva nlgPagination
                        var paginationBarScope = scope.$new();
                        paginationBarScope.nlgPaginationController = controllers[0];

                        var placeholder = element.parent(".table-responsive");
                        if (placeholder.length === 0) {
                            placeholder = element;
                        }
                        placeholder.after($compile(paginationBarTemplate)(paginationBarScope));
                    },
                    post: function (scope, element, attrs, controllers) {
                        var nlgPaginationController = controllers[0];
                        var paginationOptions = scope.$eval(attrs.nlgPagination);
                        nlgPaginationController.initialize(paginationOptions);

                        if (paginationOptions.onRegisterPagination) {
                            var paginationApi = {
                                seekPage: function (pageNumber) {
                                    if (!angular.isNumber(pageNumber) || pageNumber <= 0) {
                                        throw new Error("Invalid pageNumber " + pageNumber);
                                    }
                                    return nlgPaginationController.goToPage(pageNumber);
                                },
                                clearResults: function () {
                                    paginationOptions.pagination.firstResult = 0;
                                    paginationOptions.pagination.count = 0;
                                    paginationOptions.records = [];
                                }
                            };
                            paginationOptions.onRegisterPagination(paginationApi);
                        }

                        scope.$watchCollection(function () {
                            return paginationOptions.pagination.count;
                        }, function () {
                            nlgPaginationController.recalculatePages();
                        });
                    }
                }
            };
        }]);
    });
