import analysisModule from "../analysisModule"
import server from "../../server"

analysisModule.service("stopFitService", [
    "$http",
    "loadingService",
    "remoteExceptionHandler",
    ($http, loadingService, remoteExceptionHandler) => {
        return {
            manualOperation: manualOperation,
            applyManualOperation: applyManualOperation
        }

        function manualOperation(tripId, {routingConfigId, orderId, destinationPosition}) {
            const url = server.getBaseUrl() + server.getRelativeUrl("fitOrderInTrip") + tripId
            const data = {
                routingConfigId: routingConfigId,
                orderId: orderId,
                destinationPosition: destinationPosition
            }
            return loadingService($http.post(url, data)
                .then((response) => response.data)
                .catch(remoteExceptionHandler()))
        }

        function applyManualOperation({token, orderId, stopPosition, monitorableVersion, tripId}) {
            const url = server.getServiceUrl("applyManualOperation")
            const data = {
                token: token,
                orderId: orderId,
                stopPosition: stopPosition,
                monitorableVersion: monitorableVersion
            }
            return loadingService($http.post(url, data)
                .then(function (response) {
                    if (response.data) {
                        $http.post(server.getServiceUrl("stopFitOrderPublish") + "/" + tripId + "/" + orderId)
                    }
                    return response.data
                })
                .catch(remoteExceptionHandler()))
        }
    }
])