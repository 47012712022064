define(["./threeModule", "three"], function (threeModule, THREE) {
    "use strict";

    var DEGREES_90 = Math.PI / 2;

    return threeModule.service("threeDrumMeshFactory", ["colorOfObject3D", function (colorOfObject3D) {
        var self = this;

        self.createMesh = function (object) {
            var radius = object.dimensions.width / 2;
            var depth = object.dimensions.length;

            var material = new THREE.MeshPhongMaterial({
                color: colorOfObject3D(object),
                specular: 0x999999,
                shininess: 7,
                side: THREE.DoubleSide
            });

            return rotateMesh(new THREE.Mesh(getGeometryFromCache(radius, depth), material), object.rotation);

            function rotateMesh(mesh, rotation) {
                // Crilindro do Three na CLA está Standing, mas no CPL é Straight
                if(rotation.name === "LWH" || rotation.name === "LHW"){
                    mesh.rotation.z += DEGREES_90;
                } else if(rotation.name === "WLH" || rotation.name === "HLW"){
                    mesh.rotation.x += DEGREES_90;
                }
                mesh.rotateX(object.rotation.x);
                mesh.rotateY(object.rotation.y);
                mesh.rotateZ(object.rotation.z);
                return mesh;
            }
        };

        var cache = {};
        function getGeometryFromCache(radius, depth) {
            var cacheKey = radius + "/" + depth;
            if (!cache[cacheKey]) {
                cache[cacheKey] = new THREE.CylinderGeometry(radius, radius, depth, 32, 20);
            }
            return cache[cacheKey];
        }

        return self;
    }]);
});