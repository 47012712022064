define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryTextTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no input.
     * */

    /**
     * @ngdoc function
     * @name templateFactoryTextTemplate.templateFactoryTextTemplate
     * @description
     * Template utilizado para exibir texto, utilizando um input.
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     * */
    return templateFactoryModule.service("templateFactoryTextTemplate", [function () {
        return function (config) {
            var element = angular.element("<input>");
            element.attr("type", "text").attr("class", "form-control").attr("autocomplete","off webauthn");

            if(config.model){
                element.attr("ng-model", config.model);
            }
            if(config.ngDisabled){
                element.attr("ng-disabled",config.ngDisabled );
            }
            if(config.maxLength){
                element.attr("maxlength", config.maxLength);
            }
            return element;
        };
    }]);
});