define([
    "./monitoringDriverModule",
    "text!./driverSearch.html",
    "text!./driverEdition.html"
], function (module,
             driverSearchTemplate,
             driverEditionTemplate) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.driverSearch", {
                url: "/driver",
                controller: "MonitoringDriverSearchController",
                template: driverSearchTemplate,
                data: {
                    group: "group.monitoring.driver",
                    pageTitle: "{{'title.trackingMobile.driverSearch' | translate:$stateParams}}"
                }
            })
            .state("monitoring.driverEdition", {
                url: "/driver/:driverId",
                controller: "MonitoringDriverEditionController",
                template: driverEditionTemplate,
                data: {
                    group: "group.monitoring.driver",
                    pageTitle: "{{'title.trackingMobile.driverEdition' | translate:$stateParams}}"
                },
                resolve: {
                    monitoringDriver: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return $stateParams.driverId ? monitoringService.findDriverById($stateParams.driverId) : null;
                    }]
                },
                params: {
                    reload: "true"
                }
            });
    }]);
});
