define([
    "./revisionModule"
], function (module) {
    "use strict";

    return module.provider("revisionService", [function () {
        var provider = this;
        var baseUrl = "/cockpit-gateway/service-revision";

        provider.setBaseUrl = function (url) {
            baseUrl = url;
        };

        provider.$get = ["$http", function ($http) {
            var service = {};

            service.getRevisions = function () {
                return $http.get(baseUrl).then(function (response) {
                    return response.data;
                });
            };

            return service;
        }];
    }]);
});