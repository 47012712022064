define(["./mapsModule"],
    function (mapsModule) {
        "use strict";

        mapsModule.service("markerService", [ function () {
            return {
                createMarker: createMarker
            };

            /**
             * Cria um marker para google maps.
             *
             * @param identifier {string=} identificador para permitir manipulação de markers relacionados.
             * @param markerKey {number=} uso interno do angularGoogleMapsAPI (http://angular-ui.github.io/angular-google-maps/#!/api/markers).
             * @param coordinates {object=} objeto contendo atributos "latitude" e "longitude".
             * @param iconUrl (string=) url do ícone que representará visualmente o marker.
             * @param popupConfig {object[]=} configuração de popup, contendo "template" (ou templateUrl) que é o conteúdo (ou link) do arquivo html, e "templateParameter" {object} parâmetros a serem usados no tamplate.
             * @returns {{coords: *, icon: *, key: *, options: {visible: boolean, zIndex: number}}}.
             */
            function createMarker(identifier, markerKey, coordinates, iconUrl,  popupConfig){
                var marker = {
                    identifier: identifier,
                    coords: coordinates,
                    icon: iconUrl,
                    key: markerKey,
                    options:  {
                        visible: true,
                        zIndex: 999
                    }
                };
                if(popupConfig){
                    marker.popupConfig = popupConfig;
                }
                return marker;
            }
        }

        ]);
    });