import analysisModule from "../analysisModule"
import arrays from "../../../bower_components/front-end-web-commons/app/arrays/arrays"

analysisModule.service("nodeCommandSelectNodes", ["$q", function($q) {
    return (select, context, nodes) => {
        const basketSelection = context.getSelection()
        arrays.each(nodes, function (node) {
            (function(){
                if (node.hasLazyChildren && !node.children.length ) {
                    return context.expandOrCollapse(node)
                }
                return $q.resolve()
            })().then(function(){
                if(node.type.name === "TRIP_BASKET"){
                    context.selectRootNode()
                }
                arrays.each(node.children, function (child) {
                    if (!arrays.contains(basketSelection[child.type.name] || [], child)) {
                        context.toggleSelection(child)
                    }
                })
            })
        })
    }
}])
