import helpModule from "./helpModule"
import nlgHelpView from "./nlgHelp.html"

helpModule.directive("nlgHelp", [()=>{
    return {
        restrict: "E",
        template: nlgHelpView,
        transclude: true,
        scope: {description: "@"}
    }
}])