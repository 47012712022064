define([
    "angular",
    "angular-ui-router",
    "angular-bootstrap",
    "front-end-web-commons"
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name workHoursControlModule
     * @description
     *
     * Módulo com funcionalidades de Controle de jornada
     * */
    return angular.module("workHoursControlModule", [
        "ui.router",
        "ui.bootstrap",
        "filterModule",
        "remoteModule",
        "entityDTOModule",
        "nlgTableModule",
        "nlgTableDynamicColumnsModule"
    ]);
});