define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckerPieReason.html",
    "lodash"
], function (module, template, _) {
    "use strict";

    return module.directive("nlgHealthCheckerPieReason", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                reason: "<"
            },
            controller: ["$scope", "typeFormatService", function ($scope, typeFormatService) {
                $scope.pieChartInput = {
                    configs: {
                        type: "pie",
                        properties: {
                            valueType: typeFormatService.convertTypeFormatToChartLocateName($scope.reason.pieTypeFormat),
                            threshold: $scope.reason.threshold,
                            rank: $scope.reason.rank
                        }
                    },
                    data: []
                };

                configurePieChart($scope.reason.datas);

                function configurePieChart(datas) {
                    _.forEach(datas, function (value, key) {
                        $scope.pieChartInput.data.push({label: key, value: value});
                    });
                }

                var valueFilter = typeFormatService.formatterByType($scope.reason.pieTypeFormat);
                $scope.format = function (value) {
                    return valueFilter(value);
                };
            }]
        };
    }]);
});
