define([
    "./transitionsModule",
    "text!./nlgTransitionTimeline.html",
    "lodash"
], function (module, template, _) {
    "use strict";

    return module.directive("nlgTransitionTimeline", ["transitionService", "TransitionStatus", "$translate", function (transitionService, TransitionStatus, $translate) {
        return {
            template: template,
            scope: {
                transitions: "=",
                onServiceClick: "&?"
            },
            controller: ["$scope", function ($scope) {
                var transitionGroups = $scope.transitions;
                $scope.services = createServices();
                function createServices() {
                    var services = [];
                    var lastLocality = null;
                    _.each(transitionGroups, function (transitionGroup) {
                        _.forEach(transitionGroup.transitions, function (transition) {
                            var finalized = transitionService.isFinalized(transition);
                            var location = !transition.finalizedLocation ? transitionGroup.location : transition.finalizedLocation;
                            if (lastLocality !== location.locality.id) {
                                lastLocality = location.locality.id;
                                services.push({
                                    locality: location.locality,
                                    transitions: [transition],
                                    estimatedTimestamp: transition.estimatedTimestamp,
                                    finalizedCount: finalized ? 1 : 0,
                                    finalized: finalized,
                                    lastFinalized: finalized
                                });
                            } else {
                                var service = services[services.length - 1];
                                service.transitions.push(transition);
                                service.finalizedCount += finalized ? 1 : 0;
                                service.finalized = service.finalized && finalized;
                                service.lastFinalized = finalized;
                            }
                        });
                    });
                    return services;
                }

                $scope.offsetByScreenSize = getOffsetByScreenSize();
                function getOffsetByScreenSize() {
                    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                    if(width < 450) return 0;
                    if(width < 950) return 1;
                    return 2;
                }

                $scope.servicesPage = currentPage();
                function currentPage() {
                    if($scope.services.length < 5) {
                        return $scope.services;
                    }
                    $scope.centralizedIndex = getCentralizedTransitionIndex($scope.offsetByScreenSize);
                    return getServicesPage();

                    function getCentralizedTransitionIndex() {
                        var currentTransitionIndex = 0;
                        _.forEachRight($scope.services, function(service, index) {
                            if(service.finalizedCount > 0) {
                                currentTransitionIndex = index;
                                if(transitionService.isFinalized(service.transitions[service.transitions.length - 1])) {
                                    currentTransitionIndex++;
                                }
                                return false;
                            }
                        });
                        return normalizeCurrentIndex(currentTransitionIndex);
                    }
                }

                $scope.next = function() {
                    $scope.centralizedIndex = $scope.centralizedIndex + 1;
                    $scope.servicesPage = getServicesPage();
                };

                $scope.nextPage = function() {
                    $scope.centralizedIndex = $scope.centralizedIndex + $scope.offsetByScreenSize * 2 + 1;
                    $scope.servicesPage = getServicesPage();
                };

                $scope.previous = function() {
                    $scope.centralizedIndex = $scope.centralizedIndex - 1;
                    $scope.servicesPage = getServicesPage();
                };

                $scope.previousPage = function() {
                    $scope.centralizedIndex = $scope.centralizedIndex - $scope.offsetByScreenSize * 2 - 1;
                    $scope.servicesPage = getServicesPage();
                };

                $scope.nextTransitionTitle = $translate.instant("transition.timeline.arrow.next.transition");
                $scope.nextTransitionPageTitle = $translate.instant("transition.timeline.arrow.next.transition.page", $scope.offsetByScreenSize * 2 + 1);
                $scope.previousTransitionTitle = $translate.instant("transition.timeline.arrow.previous.transition");
                $scope.previousTransitionPageTitle = $translate.instant("transition.timeline.arrow.previous.transition.page", $scope.offsetByScreenSize * 2 + 1);

                function getServicesPage() {
                    $scope.centralizedIndex = normalizeCurrentIndex($scope.centralizedIndex);
                    return $scope.services.slice(
                        $scope.centralizedIndex - $scope.offsetByScreenSize,
                        $scope.centralizedIndex + $scope.offsetByScreenSize + 1);
                }

                function normalizeCurrentIndex(currentTransitionIndex) {
                    if(currentTransitionIndex < $scope.offsetByScreenSize) {
                        return $scope.offsetByScreenSize;
                    }
                    if(currentTransitionIndex > $scope.services.length - $scope.offsetByScreenSize - 1) {
                        return $scope.services.length - $scope.offsetByScreenSize - 1;
                    }
                    return currentTransitionIndex;
                }

                $scope.transitionColorByStatus = function (transition) {
                    switch (transition.status) {
                        case TransitionStatus.FINALIZED:
                            return "#3c763d";
                        case TransitionStatus.NOT_ACCOMPLISHED:
                            return "indianred";
                        default:
                            return "inherit";
                    }
                };

                $scope.transitionClassByStatus = function (transition) {
                    switch (transition.status) {
                        case TransitionStatus.FINALIZED:
                            return "success";
                        case TransitionStatus.NOT_ACCOMPLISHED:
                            return "warning";
                        default:
                            return "secondary";
                    }
                };

                $scope.transitionIconByStatus = function (transition) {
                    switch (transition.status) {
                        case TransitionStatus.FINALIZED:
                            return "check-circle";
                        case TransitionStatus.CANCELLED:
                            return "cancel";
                        case TransitionStatus.NOT_ACCOMPLISHED:
                            return "times-circle";
                        default:
                            return "circle-o";
                    }
                };

                $scope.serviceClass = function (service) {
                    var i = 0, total = 0;
                    for (i = 0; i < service.transitions.length; i++) {
                        if (service.transitions[i].status === TransitionStatus.FINALIZED) {
                            total++;
                        }
                        if (service.transitions[i].status === TransitionStatus.NOT_ACCOMPLISHED) {
                            return "warning";
                        }
                    }
                    return total > 0 ? "success" : "info";
                };
            }]
        };
    }]);
});
