define([
    "./arrangementModule",
    "three",
    "../arrays/arrays",
    "angular"
], function (arrangementModule, THREE, arrays, angular) {
    "use strict";

    /**
     * @ngdoc service
     * @name arrangementModule.arrangementDrawerService
     * @description
     * Serviço para desenhar o arranjo.
     * */
    return arrangementModule.service("arrangementDrawerService", ["ThreeRenderer", "threeMeshFactory", "ArrangementColorGrouper", "threeEdgeFactory", "EntrySetMap", function (ThreeRenderer, threeMeshFactory, ArrangementColorGrouper, threeEdgeFactory, EntrySetMap) {
        var self = this;

        var arrangementColorGrouper = new ArrangementColorGrouper();

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDrawerService#createBaseScene
         * @methodOf arrangementModule.arrangementDrawerService
         * @description
         * Cria uma cena base para o desenho dos objetos.
         * @returns {THREE.Scene} cena base.
         * */
        self.createBaseScene = function () {
            var scene = new THREE.Scene();

            var sunlight = new THREE.DirectionalLight(0xefefff, 2);
            sunlight.position.set(1, 1, 1).normalize();
            scene.add(sunlight);

            var backlight = new THREE.DirectionalLight(0xefefff, 1);
            backlight.position.set(-1, -1, -1).normalize();
            scene.add(backlight);

            return scene;
        };

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDrawerService#createRenderer
         * @methodOf arrangementModule.arrangementDrawerService
         * @description
         * Cria o renderizador básico que será utilizado pela cena.
         * @param {number} width largura do container que este renderizador se encontra.
         * @param {number} height altura do container que este renderizador se encontra.
         * @returns {threeModule.ThreeRenderer} renderizador.
         * */
        self.createRenderer = function (width, height) {
            return new ThreeRenderer(width, height);
        };

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDrawerService#createDiagonalCamera
         * @methodOf arrangementModule.arrangementDrawerService
         * @description
         * Cria uma camera ortográfica que foca no objeto, exibindo-o na diagonal.
         * @param {Object3D} object Objeto que será focado.
         * @param {number} aspect AspectRatio da cena, ou seja, largura dividido pela altura.
         * @returns {THREE.OrthographicCamera} Camera ortográfica.
         * */
        self.createDiagonalCamera = function createDiagonalCamera(object, aspect) {
            var center = object.center;
            var maxDimension = Math.max(center.x, center.y, center.z);
            var orthographicSize = maxDimension / 1.2;
            var diagonalCamera = new THREE.OrthographicCamera(-orthographicSize * aspect, orthographicSize * aspect, orthographicSize, -orthographicSize, 90000000, -90000000);
            diagonalCamera.position.z = -1;
            diagonalCamera.lookAt(new THREE.Vector3(center.x, center.y, center.z));
            return diagonalCamera;
        };

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDrawerService#createLateralCamera
         * @methodOf arrangementModule.arrangementDrawerService
         * @description
         * Cria uma camera ortográfica que foca no objeto, exibindo-o na lateral.
         * @param {Object3D} object Objeto que será focado.
         * @param {number} aspect AspectRatio da cena, ou seja, largura dividido pela altura.
         * @returns {THREE.OrthographicCamera} Camera ortográfica.
         * */
        self.createLateralCamera = function createLateralCamera(object, aspect) {
            var center = object.center;
            var orthographicSize = Math.max(center.x, center.y, center.z);
            var lateralCamera = new THREE.OrthographicCamera(-orthographicSize * aspect, orthographicSize * aspect, orthographicSize, -orthographicSize, 90000000, -90000000);
            lateralCamera.position.x = center.x;
            lateralCamera.position.y = center.y;
            lateralCamera.position.z = -1;
            return lateralCamera;
        };

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDrawerService#resetColorScheme
         * @methodOf arrangementModule.arrangementDrawerService
         * @description
         * Instância ArrangementColorGrouper.
         */
        self.resetColorScheme = function () {
            arrangementColorGrouper = new ArrangementColorGrouper();
        };

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDrawerService#draw
         * @methodOf arrangementModule.arrangementDrawerService
         * @description
         * Desenha um container de um veículo na cena, com seus objetos internos.
         * @param {THREE.Scene} scene cena em que o objeto será desenhado.
         * @param {Object3D} object Objeto a ser desenhado.
         * @param {PropertyGetter} [viewType] tipo de agrupamento de objetos a ser usado para desenhar o contêiner.
         *  Caso não seja informado, `viewType.DELIVERY_UNIT` será utilizado.
         * */
        self.draw = function draw(scene, object, viewType) {
            var materials = [];
            var singleBoxGeometry = new THREE.Geometry();
            var singleEdgeGeometry = new THREE.Geometry();
            var singleOrientationLineGeometry = new THREE.Geometry();
            var materialIndex = 0;

            var drawnObjects = doDraw(object, viewType);
            drawnObjects.forEach(function (obj) {
                if (obj.object.children && obj.object.children.length) {
                    // Pallets são adicionados na cena diretamente devido à transparência do invólucro
                    scene.add(obj.mesh);
                } else if (!(obj.mesh.geometry instanceof THREE.BoxGeometry)) {
                    if (obj.edge) {
                        scene.add(obj.edge);
                    }
                    scene.add(obj.mesh);
                    return;
                } else {
                    materials.push(obj.mesh.material);
                    var oldLength = singleBoxGeometry.faces.length;
                    singleBoxGeometry.mergeMesh(obj.mesh);
                    var faces = singleBoxGeometry.faces.slice(oldLength, singleBoxGeometry.faces.length);
                    faces.forEach(function (face) {
                        face.materialIndex = materialIndex;
                    });
                    materialIndex++;
                }
                if (obj.edge) {
                    Array.prototype.push.apply(singleEdgeGeometry.vertices, obj.edge.geometry.vertices);
                }
                if (obj.orientationLine) {
                    Array.prototype.push.apply(singleOrientationLineGeometry.vertices, obj.orientationLine.geometry.vertices);
                }
            });

            var material = new THREE.MultiMaterial(materials);
            var mesh = new THREE.Mesh(singleBoxGeometry, material);
            scene.add(mesh);

            var edges = new THREE.LineSegments(singleEdgeGeometry, new THREE.LineBasicMaterial({
                color: 0x000000
            }));
            scene.add(edges);
            drawnObjects.meshMerged = mesh;

            if (singleOrientationLineGeometry.vertices.length) {
                var orientationLine = new THREE.LineSegments(singleOrientationLineGeometry, new THREE.LineBasicMaterial({
                    color: 0x333333
                }));
                drawnObjects.orientationLine = {mesh: orientationLine, object: {}};
                scene.add(orientationLine);
            }

            return drawnObjects;

            function doDraw(object, viewType) {
                var result = [];
                var mesh = threeMeshFactory(object.type).createMesh(object);
                mesh.position.set(object.center.x, object.center.y, object.center.z);
                mesh.updateMatrix();

                if (object.type.indexOf("VEHICLE") === -1) {
                    var edge = mesh.edge || threeEdgeFactory(mesh, object) || {};
                    result.push({
                        object: object,
                        mesh: mesh,
                        edge: edge.edge || null,
                        orientationLine: edge.orientationLine || null
                    });
                } else {
                    scene.add(mesh);
                    scene.add(new THREE.EdgesHelper(mesh, 0));
                }

                var resultObjects = result.concat(arrays.map(object.children || [], function (object) {
                    return doDraw(object, viewType);
                }));
                resultObjects.forEach(function (element) {
                    element.mesh.material.color.set(arrangementColorGrouper.getColor(element, viewType));
                });
                return resultObjects;
            }
        };

        self.setColor = function (color, meshAndObject) {
            meshAndObject.mesh.material.color.set(color);
        };

        self.setOpacity = function (opacity, meshAndObject) {
            var transparent = opacity !== 1;
            if (!transparent && isComposite(meshAndObject.object)) {
                meshAndObject.mesh.material.transparent = true;
                meshAndObject.mesh.material.opacity = 0.2;
            } else {
                meshAndObject.mesh.material.transparent = transparent;
                meshAndObject.mesh.material.opacity = opacity;
            }
            if (meshAndObject.edge) {
                meshAndObject.edge.material.transparent = transparent;
                meshAndObject.edge.material.opacity = opacity;
            }
        };

        self.getExtraInfo = function (object3dInfo, meshAndObject) {
            return meshAndObject.object.extraInfo[object3dInfo];
        };

        self.getColorScheme = function (elements, viewType) {
            var colorMap = new EntrySetMap();
            elements.forEach(function (element) {
                var color = arrangementColorGrouper.getColor(element, viewType);
                var extraInfo = ArrangementColorGrouper.getExtraInfo(element.value ? element.value[0] : element);
                var key = viewType.getValue(extraInfo);
                colorMap.put(key, color);
            });
            return colorMap;
        };

        self.getColor = function (element, viewType) {
            return arrangementColorGrouper.getColor(element, viewType);
        };

        function isComposite(object) {
            return angular.isDefined(object.children) && object.children.length > 0;
        }
    }]);
});