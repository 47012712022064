define([
    "./monitorModule",
    "text!./nlgMonitorLogTail.html"
], function (monitorModule, template) {
    "use strict";

    return monitorModule.directive("nlgMonitorLogTail", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                instance: "<",
                file: "<"
            },
            controller: ["$element", "$scope", "$q", "$interval", "$timeout", "monitorService", "loadingService", "remoteExceptionHandler", function ($element, $scope, $q, $interval, $timeout, monitorService, loadingService, remoteExceptionHandler) {
                $scope.tailResult = null;
                $scope.options = {
                    startByte: null,
                    bytesToRead: 50000,
                    autoRefresh: true,
                    autoScroll: true
                };

                $scope.loadFile = function () {
                    if (!$scope.file) {
                        return $q.resolve();
                    }
                    return loadingService(monitorService.tailLogFile($scope.instance, $scope.file, $scope.options.startByte, $scope.options.bytesToRead))
                        .catch(remoteExceptionHandler())
                        .then(function (tailResult) {
                            $scope.tailResult = tailResult;
                            scrollToBottom();
                        });
                };

                var autoLoadInterval = $interval(function () {
                    if (!$scope.options.autoRefresh || !$scope.tailResult) {
                        return $q.resolve();
                    }
                    return monitorService.tailLogFile($scope.instance, $scope.tailResult.file, $scope.tailResult.endByte, $scope.options.bytesToRead)
                        .catch(remoteExceptionHandler())
                        .then(function (tailResult) {
                            $scope.tailResult.endByte = tailResult.endByte;
                            tailResult.lines.forEach(function (line) {
                                $scope.tailResult.lines.push(line);
                            });

                            scrollToBottom();
                        });
                }, 10000);

                function scrollToBottom() {
                    if (!$scope.options.autoScroll) {
                        return;
                    }
                    $timeout(function () {
                        var resultList = $element.find(".nlgMonitorLogTailResults");
                        resultList[0].scrollBy(0, resultList[0].scrollHeight);
                    });
                }

                $scope.$watch("file", $scope.loadFile);
                $scope.$on("$destroy", function () {
                    $interval.cancel(autoLoadInterval);
                });
            }]
        };
    }]);
});