define([
    "../nlgHealthCheckerModule"
], function (nlgHealthCheckerModule) {
    "use strict";

    return nlgHealthCheckerModule.service("durationCheckerHistoryGraph", [
        "preciseDurationFilter",
        function (preciseDurationFilter) {
            return {
                canHandle: function (reasonReading) {
                    return reasonReading.type === "DurationReason";
                },
                create: function () {
                    return {
                        configureData: function (defaultConfiguration, datas) {
                            defaultConfiguration.json = datas;
                            return defaultConfiguration;
                        },
                        configureChart: function (defaultConfiguration) {
                            defaultConfiguration.axis.y = {
                                tick: {
                                    format: function (x) {
                                        return preciseDurationFilter(x);
                                    }
                                }
                            };
                            return defaultConfiguration;
                        }
                    };
                }
            };
        }]);
});
