define(["./arrangementModule"], function (arrangementModule) {
    "use strict";

    /**
     * @ngdoc service
     * @name arrangementModule.arrangementDescriptionService
     * @description
     * Serviço para obtenção de descrição de arranjo para cargas ou viagens.
     * */
    return arrangementModule.service("arrangementDescriptionService", ["$http", "arrangementUrls", function ($http, arrangementUrls) {
        var self = this;

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDescriptionService#forTrip
         * @methodOf arrangementModule.arrangementDescriptionService
         * @description
         * Busca a descrição de arranjo para uma viagem.
         * @param {number} tripId id da viagem.
         * @param {number=} routingConfigId id do routingConfig.
         * @param {number=} converterDecorator id do converterDecorator.
         * @return {HttpPromise} Promessa com os arranjos para cada carga e container.
         * */
        self.forTrip = function (tripId, routingConfigId, converterDecorator) {
            return $http.get(arrangementUrls.getArrangementDescriptionForTripUrl() + tripId, {
                params: {
                    routingConfigId: routingConfigId,
                    converterDecorator: converterDecorator
                }
            });
        };

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDescriptionService#forTrips
         * @methodOf arrangementModule.arrangementDescriptionService
         * @description
         * Busca a descrição de arranjo de viagens.
         * @param {number[]} tripIds ids das viagens.
         * @param {number=} routingConfigId id do routingConfig.
         * @param {number=} converterDecorator id do converterDecorator.
         * @return {HttpPromise} Promessa com os arranjos para cada carga e container.
         * */
        self.forTrips = function (tripIds, routingConfigId, converterDecorator) {
            return $http.get(arrangementUrls.getArrangementDescriptionForTripUrl(), {
                params: {
                    tripIds: tripIds,
                    routingConfigId: routingConfigId,
                    converterDecorator: converterDecorator
                }
            });
        };

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDescriptionService#forLoad
         * @methodOf arrangementModule.arrangementDescriptionService
         * @description
         * Busca a descrição de arranjo para uma carga.
         * @param {number} loadId id da carga.
         * @param {number} routingConfigId id do routingConfig.
         * @param {number=} converterDecorator id do converterDecorator.
         * @return {HttpPromise} Promessa com os arranjos para cada carga e container.
         * */
        self.forLoad = function (loadId, routingConfigId, converterDecorator, embedding) {
            return $http.get(arrangementUrls.getArrangementDescriptionForLoadUrl(), {
                params: {
                    loadId: loadId,
                    routingConfigId: routingConfigId,
                    converterDecorator: converterDecorator,
                    minimizingEmbedding: embedding
                }
            });
        };

        /**
         * @ngdoc service
         * @name arrangementModule.arrangementDescriptionService#forDeliveryUnit
         * @methodOf arrangementModule.arrangementDescriptionService
         * @description
         * Busca a descrição de arranjo para uma DeliveryUnit.
         * @param {number} deliveryUnitId id da du.
         * @param {number} routingConfigId id do routingConfig.
         * @param {number=} converterDecorator id do converterDecorator.
         * @return {HttpPromise} Promessa com o arranjo.
         * */
        self.forDeliveryUnit = function (deliveryUnitId, routingConfigId, converterDecorator) {
            return $http.get(arrangementUrls.getArrangementDescriptionForDUUrl(), {
                params: {
                    deliveryUnitId: deliveryUnitId,
                    routingConfigId: routingConfigId,
                    converterDecorator: converterDecorator
                }
            });
        };
    }]);
});