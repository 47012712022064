define([
    "./monitorModule",
    "text!./nlgMonitorRequestEnv.html",
    "lodash"
], function (module, template, _) {
    "use strict";

    return module.service("nlgMonitorRequestEnvV2Service", [
        "loadingService",
        "remoteExceptionHandler",
        "monitorService",
        "$q",
        "messagesModal",
        function (
            loadingService,
            remoteExceptionHandler,
            monitorService,
            $q,
            messagesModal) {

            function NlgMonitorRequestEnvV2Service(instance) {
                this.instance = instance;
            }

            NlgMonitorRequestEnvV2Service.prototype.createEnv = function (data) {
                return {
                    profiles: data.activeProfiles,
                    sources: convertEnvironment(data)
                };
            };

            function convertEnvironment(environment) {
                return _.chain(environment.propertySources)
                    .map(function (propertySource) {
                        return {
                            name: propertySource.name,
                            properties: _.map(propertySource.properties, function (property, key) {
                                return {
                                    name: key,
                                    value: property.value,
                                    origin: property.origin
                                };
                            })
                        };
                    })
                    .value();
            }

            NlgMonitorRequestEnvV2Service.prototype.computeValue = function (property) {
                var self = this;
                return loadingService(monitorService.findEndpoint(self.instance, "env/" + property.name)
                    .catch(remoteExceptionHandler()))
                    .then(function (data) {
                        property.computedValue = data.property.value;
                    });
            };

            NlgMonitorRequestEnvV2Service.prototype.updateValue = function () {
                return messagesModal("not implemented", "not implemented in spring boot 2.0");
            };

            NlgMonitorRequestEnvV2Service.prototype.createNewProperty = function () {
                return messagesModal("not implemented", "not implemented in spring boot 2.0");
            };

            return NlgMonitorRequestEnvV2Service;
        }]);
});