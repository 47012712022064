import analysisModule from "../analysisModule"
import server from "../../server"

analysisModule.service("nodeCommandStartMonitorableConfiguration", [
    "startMonitorableConfigurationView",
    "$http",
    "messagesModal",
    "remoteExceptionHandler",
    "loadingService",
    function (startMonitorableConfigurationView, $http, messagesModal, remoteExceptionHandler, loadingService) {
        return function (selection) {
            return loadingService($http.post(server.getServiceUrl("startMonitorableConfigurationByNodeIdentifiers"), selection)
                .then(function (response) {
                    return startMonitorableConfigurationView(response.data)
                })).catch(remoteExceptionHandler())
        }
    }])