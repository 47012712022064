import restrictionsModule from "./restrictionsModule"
import angular from "angular"
import jiff from "jiff"

restrictionsModule.controller("restrictionsController", ["$scope", "restrictionsService", "loadingService", "messagesModal", "remoteExceptionHandler",
    function ($scope, restrictionsService, loadingService, messagesModal, remoteExceptionHandler) {

        $scope.entity = {}
        $scope.originalEntity = {}

        $scope.orderSplitPolicies = [
            "br.com.neolog.r2.model.R2RestrictionsOrderSplitPolicy.NO_SPLIT",
            "br.com.neolog.r2.model.R2RestrictionsOrderSplitPolicy.SPLIT_BY_ORDER",
            "br.com.neolog.r2.model.R2RestrictionsOrderSplitPolicy.SPLIT_BY_ORDER_ITEM",
            "br.com.neolog.r2.model.R2RestrictionsOrderSplitPolicy.NO_SPLIT_HARD"
        ]

        function getEntity() {
            return loadingService(restrictionsService.findRestrictions()).then((response) => {
                $scope.entity = response
                $scope.originalEntity = angular.copy($scope.entity)
            })
        }

        getEntity()

        $scope.handleReturnDistanceInCostFuncion = function(){
            if(!$scope.entity.considerReturnDistance){
                $scope.entity.considerReturnDistanceInCostFuncion = false
            }
        }

        $scope.save = function () {
            var patches = jiff.diff($scope.originalEntity, $scope.entity, {invertible: false})
            loadingService(restrictionsService.updateRestrictions(patches, $scope.originalEntity.version))
                .catch(remoteExceptionHandler())
                .then(() => getEntity())
                .then(() => {
                    messagesModal("dialog.success", [{
                        keyBundle: "entity.validator.successMessage",
                        parameters: []
                    }])
                })
        }

    }])