define([
    "angular",
    "./commonsCancellationModule"
], function (angular, commonsCancellationModule) {
    "use strict";
    commonsCancellationModule.provider("cancellationServiceUrl", [function () {

        var cancelTripsUrl;

        this.$get = [function () {
            return {
                getCancelTripsUrl: getCancelTripsUrl
            };
        }];

        var getCancelTripsUrl = function () {
            return cancelTripsUrl;
        };

        this.configure = function (server) {
            cancelTripsUrl = server.cancelTripsUrl;
        };
    }]);
});

