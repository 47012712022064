define(["./remoteModule", "angular"], function (remoteModule, angular) {
    return remoteModule.factory("remoteLogger", ["$q", "CircularList", function ($q, CircularList) {
        var log = new CircularList();
        return {
            request: function (config) {
                log.add({timestamp: Date.now(), type: "request", result: angular.copy(config)});
                return config;
            },

            requestError: function (rejection) {
                log.add({timestamp: Date.now(), type: "requestError", result: angular.copy(rejection)});
                return $q.reject(rejection);
            },

            response: function (response) {
                log.add({timestamp: Date.now(), type: "response", result: angular.copy(response)});
                return response;
            },

            responseError: function (rejection) {
                log.add({timestamp: Date.now(), type: "responseError", result: angular.copy(rejection)});
                return $q.reject(rejection);
            },

            get: function () {
                return log.toArray();
            }
        };
    }]);
});