define(["./trackingMobileModule", "require", "text!./driver/DriverSearchView.html", "text!./driver/DriverEditionView.html", "text!./legacydriver/LegacyDriverEditionView.html", "text!./device/DeviceSearchView.html", "text!./device/DeviceEditionView.html", "text!./mobileSettings/MobileSettingsView.html"], function (trackingMobileModule, require, driverSearchTemplate, driverEditionTemplate, legacyDriverEditionTemplate, deviceSearchTemplate, deviceEditionTemplate, mobileSettingsTemplate) {
    "use strict";

    trackingMobileModule.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("trackingMobile.driverSearch", {
                url: "/driver",
                controller: "DriverSearchController",
                template: driverSearchTemplate,
                data: {
                    group: "group.monitoring.driver",
                    pageTitle: "{{'title.trackingMobile.driverSearch' | translate:$stateParams}}",
                    creationState: "trackingMobile.driverEdition"
                }
            })
            .state("trackingMobile.driverEdition", {
                url: "/driver/:driverId",
                controller: "DriverEditionController",
                template: driverEditionTemplate,
                data: {
                    group: "group.monitoring.driver",
                    pageTitle: "{{'title.trackingMobile.driverEdition' | translate:$stateParams}}"
                },
                resolve: {
                    driver: ["$stateParams", "trackingMobileService", function ($stateParams, trackingMobileService) {
                        return $stateParams.driverId ? trackingMobileService.findDriverById($stateParams.driverId) : null;
                    }]
                }
            })
            .state("trackingMobile.legacyDriverSearch", {
                url: "/legacy-driver",
                controller: "DriverSearchController",
                template: driverSearchTemplate,
                data: {
                    group: "group.monitoring.legacyDriver",
                    creationState: "trackingMobile.legacyDriverEdition"
                }
            })
            .state("trackingMobile.legacyDriverEdition", {
                url: "/legacy-driver/:driverId",
                controller: "LegacyDriverEditionController",
                template: legacyDriverEditionTemplate,
                data: {
                    group: "group.monitoring.legacyDriver"
                },
                resolve: {
                    driver: ["$stateParams", "trackingMobileService", function ($stateParams, trackingMobileService) {
                        return $stateParams.driverId ? trackingMobileService.findDriverById($stateParams.driverId) : null;
                    }],
                    device: ["driver", "trackingMobileService", function (driver, trackingMobileService) {
                        return driver  && driver.deviceId ? trackingMobileService.findDeviceById(driver.deviceId) : null;
                    }]
                }
            })
            .state("trackingMobile.deviceSearch", {
                url: "/device",
                controller: "DeviceSearchController",
                template: deviceSearchTemplate,
                data: {
                    group: "group.monitoring.driver",
                    pageTitle: "{{'title.trackingMobile.deviceSearch' | translate:$stateParams}}"
                }
            })
            .state("trackingMobile.deviceEdition", {
                url: "/device/:deviceId",
                controller: "DeviceEditionController",
                template: deviceEditionTemplate,
                data: {
                    group: "group.monitoring.driver",
                    pageTitle: "{{'title.trackingMobile.deviceEdition' | translate:$stateParams}}"
                },
                resolve: {
                    device: ["$stateParams", "trackingMobileService", function ($stateParams, trackingMobileService) {
                        return $stateParams.deviceId ? trackingMobileService.findDeviceById($stateParams.deviceId) : null;
                    }]
                }
            })
            .state("trackingMobile.mobileSettings", {
            url: "/mobileSettings",
            controller: "MobileSettingsController",
            template: mobileSettingsTemplate,
            data: {
                group: "group.monitoring.mobile.settings",
                pageTitle: "{{'title.trackingMobile.mobileSettingsApplication' | translate:$stateParams}}"
            },
            resolve: {
                mobileSettings: ["trackingMobileService", function (trackingMobileService) {
                    return trackingMobileService.getMobileApplicationSetting();
                }]
            }
        });
    }]);
});
