define([
    "../monitoringModule"
], function (monitoringModule) {
    "use strict";

    monitoringModule.provider("nlgTagInputIconMapMonitoring", [function () {
        this.$get = [function () {
            return {
                iconMap: iconMap,
            };
        }];

        var iconMap = function () {
            var enumToIcon = {
                "br.com.neolog.monitoring.monitorable.model.api.LocalityIcon.HUB": "images/locality_hub.png",
                "br.com.neolog.monitoring.monitorable.model.api.LocalityIcon.FACTORY": "images/locality_factory.png",
                "br.com.neolog.monitoring.monitorable.model.api.LocalityIcon.STORE": "images/locality_store.png",
                "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.WARNING": "images/WARNING_SMALL.png",
                "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.DELAY": "images/DELAY_SMALL.png",
                "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.FOOD": "images/FOOD_SMALL.png",
                "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.MECHANICAL": "images/MECHANICAL_SMALL.png",
                "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.OVERNIGHT_STAY": "images/OVERNIGHT_STAY_SMALL.png",
                "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.SPENT": "images/SPENT_SMALL.png",
                "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.CLOSED_ESTABLISHMENT": "images/CLOSED_ESTABLISHMENT_SMALL.png"
            };
            return enumToIcon;
        }

    }]);
});

