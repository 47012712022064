import analysisModule from "../analysisModule"
import server from "../../server"

analysisModule.service("nodeCommandReprogramTrip", [
    "$http",
    "messagesModal",
    "remoteExceptionHandler",
    "loadingService",
    function ($http, messagesModal, remoteExceptionHandler, loadingService) {
        return selection => {
            return loadingService($http.post(server.getServiceUrl("trip-reprogram"), selection))
                .catch(remoteExceptionHandler())
                .then((response) => {
                    return messagesModal("programming.unrelease.result", response.data)
                })
        }
    }])
