import userByOriginsModule from "./userByOriginsModule"
import tdnByEntity from "../search/tdnByEntity.json"

userByOriginsModule.controller("userByOriginsController", ["$scope", "userByOriginsService", "remoteExceptionHandler", "loadingService", "messagesModal", ($scope, userByOriginsService, remoteExceptionHandler, loadingService, messagesModal) => {

    $scope.tdn = tdnByEntity["users-by-origin"]

    $scope.availableOrigins = []
    $scope.userByOrigins = {
        user: {},
        origins: []
    }

    $scope.save = function () {
        loadingService(userByOriginsService.save($scope.userByOrigins))
            .catch(remoteExceptionHandler())
            .then(() => messagesModal("dialog.success", "entity.validator.successMessage"))
    }

    $scope.search = function (patch) {
        loadingService(userByOriginsService.userByOrigins(patch))
            .then((response) => {
                $scope.userByOrigins.origins = response.result[0].origins
                $scope.userByOrigins.user = response.result[0].user
            })
            .then(() => {
                userByOriginsService.findAllOrigins().then((origins) => {
                    $scope.availableOrigins = origins
                })
            })
    }
}])