import unblockTripMessagesModule from "./unblockTripMessagesModule"
import unblockTripMessagesView from "./unblockTripMessagesView.html"

unblockTripMessagesModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.unblockTripMessages", {
        data: {
            pageTitle: "{{'title.r2.unblockTripMessages' | translate}}"
        },
        template: unblockTripMessagesView,
        url: "/integration/unblock-trip",
        controller: "unblockTripMessagesController"
    })
}])