define(["./nlgTagInputModule", "angular"], function (nlgTagInputModule, angular) {
    "use strict";

    /**
     * @ngdoc service
     * @name services.fontUtils
     * @description
     * Serviço de com métodos utilitários para cálculos considerando a fonte.
     * */
    return nlgTagInputModule.factory("fontUtils", [function () {
        return {
            /**
             * @ngdoc method
             * @name services.fontUtils#stringWidth
             * @methodOf services.fontUtils
             * @description
             * Determina a largura em pixels de uma string, dado o container onde no qual ela ficaria.
             *
             * Para este cálculo, a `font` definida no container, bem como o `padding` e a `border` são considerados.
             *
             * @param {string} string String à ser medida.
             * @param {DOMNode} container Container onde a string está.
             * @returns {number} Largura da string.
             * */
            stringWidth: function (string, container) {
                var stringWidthElement = angular.element("#fontUtilsStringWidth");
                if (stringWidthElement.length === 0) {
                    stringWidthElement = angular.element("<div>")
                        .attr("id", "fontUtilsStringWidth")
                        .css({
                            "position": "absolute",
                            "white-space": "nowrap",
                            "visibility": "hidden"
                        }).appendTo(document.body);
                }

                var element = angular.element(container);
                var fontProperties = element.css("font");
                var padding = parseInt(element.css("padding-left")) + parseInt(element.css("padding-right"));
                var border = parseInt(element.css("border-left-width")) + parseInt(element.css("border-right-width"));

                return stringWidthElement
                        .html(string)
                        .css("font", fontProperties)
                        .width() + padding + border;
            }
        };
    }]);
});

