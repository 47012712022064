import genericParamDefinitionModule from "./genericParamDefinitionModule"

genericParamDefinitionModule.controller("genericParamDefinitionController",
    function ($scope: any, sourceEntity: any, entity: any, entityEditionService: any, genericParamDataDialog: any) {
        const hasOwnProperty = Object.hasOwnProperty
        const updateService = entityEditionService.crudHelper("genericParamDefinition", sourceEntity)
        $scope.entity = entity
        $scope.save = updateService.save.bind(updateService, entity)
        $scope.saveAndContinue = updateService.save.bind(updateService, entity, true)
        $scope.editData = function () {
            genericParamDataDialog($scope.entity, true);
        };
    });
