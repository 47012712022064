define(["./userPermissionsModule", "angular", "front-end-web-commons/app/arrays/arrays", "lodash", "json!../tdnByEntity.json"], function (module, angular, arrays, _, tdnByEntity) {
    "use strict";

    return module.controller("UserPermissionsController", [
        "$scope",
        "$q",
        "userPermissionsService",
        "userManagementService",
        "remoteExceptionHandler",
        "loadingService",
        "messagesModal",
        "tdnHelp",
        function ($scope, $q, permissionsService, userManagementService, remoteExceptionHandler, loadingService, messagesModal, tdnHelp) {

            $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["user-permission"];

            var previousProfile, previousCategory = null;

            $scope.selected = {
                profile: null,
                category: null,
                model: []
            };

            var originalConfigData = null;
            $scope.configData = null;
            var originalPermissionData = null;
            $scope.permissionsData = [];

            $scope.getProfiles = function () {
                return userManagementService.findAllProfiles();
            };

            $scope.getAllCategories = function () {
                return permissionsService.getAllCategories();
            };

            $scope.onChangeOption = function () {
                askBeforeChanging().then(function () {
                    previousProfile = $scope.selected.profile;
                    previousCategory = $scope.selected.category;
                    if (!previousProfile || !previousCategory) {
                        return;
                    }
                    loadingService(getPermissionData());
                }).catch(function () {
                    $scope.selected.category = previousCategory;
                    $scope.selected.profile = previousProfile;
                });

                function askBeforeChanging() {
                    if (!previousProfile || !previousCategory || !$scope.hasModifications()) {
                        return $q.resolve();
                    }
                    return messagesModal.cancellable("dialog.proceed?", "monitoring.permissions.confirm.change");
                }
            };

            $scope.hasModifications = function () {
                return !angular.equals(originalPermissionData, $scope.permissionsData) || !angular.equals(originalConfigData, $scope.configData);
            };

            $scope.isValidData = function () {
                return $scope.hasModifications() && checkOnlyIdOrSourceIdIsFilled();

                function checkOnlyIdOrSourceIdIsFilled() {
                    var isValid = true;
                    arrays.each($scope.permissionsData, function (data) {
                        if (data.entitySourceId && _.isNumber(data.entityId)) {
                            isValid = false;
                            return arrays.each.BREAK;
                        }
                    });
                    return isValid;
                }
            };

            $scope.save = function () {
                loadingService(
                    permissionsService.save($scope.selected.profile.id, $scope.selected.category.id, {
                        restrictions: $scope.permissionsData.filter(function (permission) {
                            return !!permission.entityId || !!permission.entitySourceId;
                        })
                    })
                        .then(permissionsService.saveConfiguration($scope.configData)))
                    .then(function () {
                        return messagesModal("dialog.success", [{
                            keyBundle: "monitoring.permissions.save.success",
                            parameters: []
                        }]);
                    }).then(function () {
                    loadingService(getPermissionData());
                }).catch(remoteExceptionHandler());
            };

            $scope.movedToLeft = function (selection) { // remove
                if ($scope.selected.category.type.indexOf("UserSensitive$Type.ID") !== -1) {
                    selection.forEach(function (entity) {
                        _.remove($scope.permissionsData, function (data) {
                            return data.entityId === entity.id;
                        })
                    });
                } else if ($scope.selected.category.type.indexOf("UserSensitive$Type.SOURCE_ID") !== -1) {
                    selection.forEach(function (entity) {
                        _.remove($scope.permissionsData, function (data) {
                            return data.entitySourceId === entity.sourceId;
                        })
                    });
                }
            };

            $scope.movedToRight = function (selection) { // add
                if ($scope.selected.category.type.indexOf("UserSensitive$Type.ID") !== -1) {
                    selection.forEach(function (entity) {
                        $scope.permissionsData.push({
                            "category": $scope.selected.category,
                            "profileId": $scope.selected.profile.id,
                            "entityId": entity.id,
                            "entitySourceId": null
                        });
                    });
                } else if ($scope.selected.category.type.indexOf("UserSensitive$Type.SOURCE_ID") !== -1) {
                    selection.forEach(function (entity) {
                        $scope.permissionsData.push({
                            "category": $scope.selected.category,
                            "profileId": $scope.selected.profile.id,
                            "entityId": null,
                            "entitySourceId": entity.sourceId
                        });
                    });
                }
            };

            $scope.formatCategory = function (tag) {
                if (!tag) {
                    return "";
                }
                return tag.qualifier;
            };

            function getPermissionData() {
                var possibleValuesPromise = permissionsService.dynamicFindAll($scope.selected.category.qualifier);
                var restrictionsPromise = permissionsService.getProfileRestrictionsByCategory({
                    profileId: $scope.selected.profile.id,
                    category: $scope.selected.category
                }).then(function (permissionData) {
                    $scope.permissionsData = permissionData;
                    originalPermissionData = angular.copy(permissionData);

                    if ($scope.selected.category.type.indexOf("UserSensitive$Type.ID") !== -1) {
                        return permissionsService.dynamicFindByIds($scope.selected.category.qualifier, getEntityIds(permissionData))
                    } else if ($scope.selected.category.type.indexOf("UserSensitive$Type.SOURCE_ID") !== -1) {
                        return permissionsService.dynamicFindBySourceIds($scope.selected.category.qualifier, getEntitySourceIds(permissionData))
                    } else {
                        return [];
                    }
                });
                var configurationPromise = permissionsService.findConfiguration($scope.selected.profile.id, $scope.selected.category);

                return $q.all([restrictionsPromise, configurationPromise, possibleValuesPromise])
                    .then(function (results) {
                        $scope.selected.model = results[0];
                        $scope.configData = results[1];
                        $scope.dualListEntities = results[2];
                        originalConfigData = angular.copy($scope.configData);
                    }).catch(remoteExceptionHandler());
            }

            function getEntityIds(objects) {
                return _.map(objects, function (obj) {
                    return obj.entityId;
                })
            }

            function getEntitySourceIds(objects) {
                return _.map(objects, function (obj) {
                    return obj.entitySourceId;
                })
            }
        }]);
});