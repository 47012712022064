import aboutModule from "./aboutModule.js"
import server from "../server"

aboutModule.service("aboutService", ["$http", function ($http) {
    this.getSystemVersionInfo = function() {
        return $http.get(server.getServiceUrl("system-version"))
            .then(function(response){
                return response.data
            })
    }
}])