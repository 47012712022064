define([
    "./violationModule",
    "./messagesModal",
	"./messagesModalOptions",
	"./nlgViolationsInnerBodyDirective",
    "./violationModal",
    "./violationModalDescription",
    "./violationModalLink",
    "./violationModalSimpleMessage",
    "./nlgViolationsTitle",
    "./innerArgumentsMessage",
    "./dtoLink",
    "./dtoLinkService"
], function () {
});