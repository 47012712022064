import analysisModule from "./analysisModule"
import server from "../server"

analysisModule.service("analysisService", ["$http", function($http) {
    this.filter = (entityType, template, firstResult, maxResults) => {
        if (entityType !== "basket" && entityType !== "trip") {
            throw new Error("Invalid entity.")
        }
        var url = server.getBaseUrl() + "/" + entityType + "/presentation-tree"
        return $http.post(url, template, {
            params: {
                firstResult,
                maxResults
            }
        }).then((response) => response.data)
    }
}])