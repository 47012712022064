define(["./logRecordModule"], function (logRecordModule) {
    "use strict";

    logRecordModule.controller("LogRecordController", [
        "$scope",
        "$q",
        "$http",
        "remoteExceptionHandler",
        "monitoringService",
        "loadingService",
        "mbrService",
        "remoteMessageModal",
        "messagesModal",
        function ($scope, $q, $http, remoteExceptionHandler, monitoringService, loadingService, mbrService, remoteMessageModal, messagesModal) {
            $scope.serviceName = monitoringService.getLogRecordFilterUrl();

            var template = [], paginationApi, paginationApiDefer = $q.defer();
            var filterApi, filterApiDefer = $q.defer();
            $scope.search = function (modifiedTemplate) {
                template = modifiedTemplate;
                return paginationApi.seekPage(1);
            };

            $scope.onRegisterFilterApi = function (api) {
                filterApi = api;
                filterApiDefer.resolve();
            };

            $scope.tableOptions = {
                records: [],
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        return loadingService(monitoringService.filterLogRecord(template, firstResult, maxResults))
                            .then(function (result) {
                                return result;
                            }).catch(remoteExceptionHandler());
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                    paginationApiDefer.resolve();
                }
            };

            $scope.republishing = function (record) {
                var publishingPromise = null;
                if(record.operation === "br.com.neolog.monitoring.logRecords.Operations.TRANSITION_PUBLISHING"){
                    publishingPromise = republishingTransitions(record.referenceId)
                } else {
                    publishingPromise = republishingOcurrence(record.referenceId)
                }
                return publishingPromise
                    .catch(remoteExceptionHandler())
                    .then(function (response) {
                        if (response.data.result) {
                            return messagesModal("dialog.success", ["log.record.republishing.success"]);
                        }
                        return messagesModal("dialog.error", ["log.record.republishing.error"]);
                    }).then(function () {
                        return $scope.search(template);
                    });
            };

            $scope.isPublishing = function (record) {
                return (record.operation === "br.com.neolog.monitoring.logRecords.Operations.OCCURRENCE_PUBLISHING" ||
                    record.operation === "br.com.neolog.monitoring.logRecords.Operations.TRANSITION_PUBLISHING") &&
                    record.success === false && record.processed === false
            };

            $scope.getResult = function (result){
                if(result){
                    return "log.record.status.success";
                }
                return "log.record.status.error";

            };

            function republishingTransitions(transitionId) {
                return loadingService(mbrService.publishTransition(transitionId));
            }

            function republishingOcurrence(occurenceId) {
                return loadingService(mbrService.publishOccurrence(occurenceId));
            }
        }

    ]);
});