define([
    "./userManagementModule",
    "require",
    "text!./userManagementProfile/search/UserManagementProfileSearchView.html",
    "text!./userManagementProfile/edition/UserManagementProfileEditionView.html",
    "text!./userManagementUser/search/UserManagementUserSearchView.html",
    "text!./userManagementUser/edition/UserManagementUserEditionView.html"
], function (
    app,
    require,
    profileSearchTemplate,
    profileEditionTemplate,
    userSearchTemplate,
    userEditionTemplate
) {
    "use strict";

    app.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("userManagement.profile", {
                url: "/profile?reload",
                controller: "UserManagementProfileSearchController",
                template: profileSearchTemplate,
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.userManagement.profile"
                }
            })
            .state("userManagement.profileEdit", {
                url: "/profile/edit/:profileId",
                controller: "UserManagementProfileEditionController",
                template: profileEditionTemplate,
                data: {
                    group: "group.userManagement.profile"
                },
                resolve: {
                    profile: ["$stateParams", "userManagementService", function ($stateParams, userManagementService) {
                        return userManagementService.findProfileById($stateParams.profileId);
                    }]
                }

            })
            .state("userManagement.profileCopy", {
                url: "/profile/copy/:profileId",
                controller: "UserManagementProfileEditionController",
                template: profileEditionTemplate,
                data: {
                    group: "group.userManagement.profile"
                },
                resolve: {
                    profile: ["$stateParams", "userManagementService", function ($stateParams, userManagementService) {
                        return userManagementService.copyProfile($stateParams.profileId);
                    }]
                },
                params: {
                    reload: "true"
                }

            })
            .state("userManagement.profileCreate", {
                url: "/profile/new",
                controller: "UserManagementProfileEditionController",
                template: profileEditionTemplate,
                data: {
                    group: "group.userManagement.profile"
                },
                resolve: {
                    profile: ["userManagementService", function ( userManagementService) {
                        return userManagementService.getNewProfileInstance();
                    }]
                }
            })
            .state("userManagement.user", {
                url: "/user?reload",
                controller: "UserManagementUserSearchController",
                template: userSearchTemplate,
                data: {
                    group: "group.userManagement.user",
                    pageTitle: "{{'title.userManagement.user' | translate}}"
                },
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                params: {
                    reload: "true"
                }

            })
            .state("userManagement.userEdit", {
                url: "/user/edit/:userId",
                controller: "UserManagementUserEditionController",
                template: userEditionTemplate,
                data: {
                    group: "group.userManagement.user",
                    pageTitle: "{{'title.userManagement.userEdit' | translate}}"
                },
                resolve: {
                    user: ["$stateParams", "userManagementService", function ($stateParams, userManagementService) {
                        return userManagementService.findUserById($stateParams.userId);
                    }]
                },
                params: {
                    reload: "true"
                }


            })
            .state("userManagement.userCopy", {
                url: "/user/copy/:userId",
                controller: "UserManagementUserEditionController",
                template: userEditionTemplate,
                data: {
                    group: "group.userManagement.user",
                    pageTitle: "{{'title.userManagement.userCopy' | translate}}"
                },
                resolve: {
                    user: ["$stateParams", "userManagementService", function ($stateParams, userManagementService) {
                        return userManagementService.copyUser($stateParams.userId);
                    }]
                },
                params: {
                    reload: "true"
                }

            })
            .state("userManagement.userCreate", {
                url: "/user/new",
                controller: "UserManagementUserEditionController",
                template: userEditionTemplate,
                data: {
                    group: "group.userManagement.user",
                    pageTitle: "{{'title.userManagement.userCreate' | translate}}"
                },
                resolve: {
                    user: ["userManagementService", function ( userManagementService) {
                        return userManagementService.getNewUserInstance();
                    }]
                },
                params: {
                    reload: "true"
                }
            });
    }]);
});
