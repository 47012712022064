import {LoadingService, MessagesModal, RemoteExceptionHandler} from "../../../bower_components/front-end-web-commons";
import {ConfigParamsService} from "../configParamsService";
import {configParamsModule} from "../configParamsModule";
import {server} from "../../server";
import {IHttpService, IPromise} from "angular";
import {ExceptionMessage, PatchInfo} from "../../../bower_components/front-end-web-commons";

const arrays = require("front-end-web-commons/app/arrays/arrays")

export interface InstanceTypeByRemoteServicesConfigParams {
    readonly orchestratorUrl: string;
    instanceTypeUpdateData: InstanceTypeUpdateData[];
}

export interface InstanceTypeWithRemoteServicesConfigParams {
    readonly orchestratorUrl: string;
    instanceTypeUpdateData: InstanceTypeUpdateData;
}

export interface InstanceTypeUpdateData {
    readonly id: number;
    readonly type: string;
    mappedMachineType: string;
    maxIdleTime: number;
}

export class RemoteRoutingService {
    constructor(private $http: IHttpService) {
    }

    getMappedMachineTypes(): IPromise<String[]> {
        return this.$http.get<String[]>(server.getServiceUrl("orchestrator.get-mapped-machine-types")).then(response => response.data);
    }

    getRemoteTypesConfigurations(): IPromise<InstanceTypeByRemoteServicesConfigParams[]> {
        return this.$http.get<InstanceTypeByRemoteServicesConfigParams[]>(server.getServiceUrl("orchestrator.remote-type-configuration")).then(response => response.data);
    }

    createRemoteTypesConfigurations():  IPromise<InstanceTypeByRemoteServicesConfigParams[]> {
        return this.$http.get<InstanceTypeByRemoteServicesConfigParams[]>(server.getServiceUrl("orchestrator.create-remote-type-configuration")).then(response => response.data);
    }

    updateRemoteTypeConfigurations(configurations: InstanceTypeByRemoteServicesConfigParams[]) {
        return this.$http.post<any[]>(server.getServiceUrl("orchestrator.update-type-configuration"), configurations)
            .then(response => response.data)
    }
}

configParamsModule.service("remoteRoutingService", RemoteRoutingService);

class NlgRemoteRoutingConfiguration {

    private remoteRoutingTypeConfigs: InstanceTypeByRemoteServicesConfigParams[];

    constructor(private loadingService: LoadingService,
                private remoteExceptionHandler: RemoteExceptionHandler,
                private messagesModal: MessagesModal,
                private configParamsService: ConfigParamsService,
                private remoteRoutingService: RemoteRoutingService) {
        this.loadRemoteTypesConfigurations();
    }

    loadRemoteTypesConfigurations() {
        this.loadingService(this.remoteRoutingService.getRemoteTypesConfigurations())
            .catch(this.remoteExceptionHandler())
            .then((configs: InstanceTypeByRemoteServicesConfigParams[]) => {
                let shouldCreateNewConfig = !configs || !configs.length || arrays.filter(configs, (config) => {
                    return config.instanceTypeUpdateData && config.instanceTypeUpdateData.length > 0;
                }).length === 0;

                if(shouldCreateNewConfig){
                    this.messagesModal.cancellable("dialog.proceed?",
                        "configParams.orchestratedRouting.remote.routing.create.config.type"
                    ).then( () => {
                        this.createRemoteTypesConfigurations();
                    });
                }else{
                    return this.remoteRoutingTypeConfigs = configs
                }
            });
    }

    createRemoteTypesConfigurations() {
        this.loadingService(this.remoteRoutingService.createRemoteTypesConfigurations())
            .catch(this.remoteExceptionHandler())
            .then((configs: InstanceTypeByRemoteServicesConfigParams[]) => {
                return this.remoteRoutingTypeConfigs = configs;
            });
    }

    getMappedMachineTypes(){
        return this.remoteRoutingService.getMappedMachineTypes();
    }

    testParallelism(instanceUpdateDataByUrl: string, instanceUpdateData: InstanceTypeUpdateData) {
        return this.loadingService(this.configParamsService.testRoutingParallelism({orchestratorUrl: instanceUpdateDataByUrl, instanceTypeUpdateData: instanceUpdateData}))
            .catch(this.remoteExceptionHandler())
            .then((messages) => this.messagesModal("dialog.result", messages))
    }

    save() {
        return this.loadingService(this.remoteRoutingService.updateRemoteTypeConfigurations(this.remoteRoutingTypeConfigs))
            .catch(this.remoteExceptionHandler())
            .then(() => this.messagesModal("dialog.success", "config.params.save.success"))
    }

    getRemoteTypesConfigurations() {
        return this.loadingService(this.remoteRoutingService.getRemoteTypesConfigurations())
            .catch(this.remoteExceptionHandler())
            .then((configs: InstanceTypeByRemoteServicesConfigParams[]) => {
                return configs;
            })
    }
}

export const directive = configParamsModule.directive("nlgRemoteRoutingConfiguration", [function () {
    return {
        restrict: "E",
        templateUrl: require("link!./nlgRemoteRoutingConfiguration.html"),
        scope: {},
        controllerAs: "ctrl",
        controller: NlgRemoteRoutingConfiguration
    }
}])