define(["./workHoursControlModule", "require"], function (module, require) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.workHoursControl", {
                url: "/workHoursControl/search",
                controller: "WorkHoursControlSearchController",
                templateUrl: require("link!./search/WorkHoursControlSearchView.html"),
                data: {
                    group: "group.workhourscontrol.search"
                }
            })
            .state("monitoring.workHoursControlSettings", {
                url: "/workHoursControl/settings",
                controller: "WorkHoursControlSettingsController",
                templateUrl: require("link!./settings/WorkHoursControlSettingsView.html"),
                data: {
                    group: "group.workhourscontrol.settings"
                }
            });
    }]);
});
