define([
    "./userManagementModule",
    "./userManagementRouter",
    "./userManagementService",
    "./userManagementProfile/search/UserManagementProfileSearchController",
    "./userManagementProfile/edition/UserManagementProfileEditionController",
    "./userManagementUser/search/UserManagementUserSearchController",
    "./userManagementUser/edition/UserManagementUserEditionController",
    "./lgpd/userManagementLGPD"
], function () {
});