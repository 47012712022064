define([
    "./trackingModule",
    "./trackingService",
    "./TrackingController",
    "./trackingRouter",
    "./truckMarkerPopupCreator",
    "./markerImage",
    "./trip/trackTripController",
    "./trip/tripAssignModalService",
    "./truckDevice/TruckDeviceSearchController",
    "./truckDevice/TruckDeviceEditionController",
    "./trackingMonitorableList"
], function () {
});