import angular from "angular"
import "angular-ui-router"
import "angular-route"
import "front-end-web-commons"
import "../../bower_components/front-end-web-commons/app/filter/filter"

export default angular.module("analysisModule", [
    "ngRoute",
    "ui.router",
    "nlgBasketModule",
    "numberModule",
    "filterModule",
    "commonsOperationModule",
    "entityDTOModule"])
