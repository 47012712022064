define(["../../userManagementModule",
        "json!../../userManagement.json",
        "json!../../tdn/tdnByEntity.json"],
    function (userManagementModule, userManagement, tdnByEntity) {
        "use strict";

        return userManagementModule.controller("UserManagementUserSearchController", [
            "$scope",
            "$q",
            "userManagementService",
            "reload",
            "loadingService",
            "messagesModal",
            "remoteExceptionHandler",
            "tdnHelp",
            function ($scope, $q, userManagementService, reload, loadingService, messagesModal, remoteExceptionHandler, tdnHelp) {

                $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["users"]

                $scope.serviceName = userManagement.services.user;
                var template, paginationApi, paginationApiDefer = $q.defer();
                var filterApi, filterApiDefer = $q.defer();

                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    paginationApi.seekPage(1);
                };

                $scope.onRegisterFilterApi = function (api) {
                    filterApi = api;
                    filterApiDefer.resolve();
                };

                $q.all([paginationApiDefer.promise, filterApiDefer.promise]).then(function () {
                    filterApi.onChangeService(function (service) {
                        if (service === $scope.serviceName) {
                            if (reload) {
                                filterApi.reload();
                            } else {
                                filterApi.reset();
                            }
                        }
                    });
                });

                $scope.tableOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(userManagementService.filterUser(template, firstResult, maxResults))
                                .then(function (result) {
                                    return result;
                                }).catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                        paginationApiDefer.resolve();
                    }
                };

                $scope.deleteUser = function (userId) {
                    return messagesModal.cancellable("dialog.proceed?", [{
                        keyBundle: "entityFilter.delete.confirm",
                        parameters: []
                    }]).then(function () {
                        return loadingService(userManagementService.deleteUser(userId)
                            .catch(remoteExceptionHandler())
                            .then(function () {
                                paginationApi.seekPage($scope.tableOptions.pagination.currentPage).then(function () {
                                    return messagesModal("dialog.success", [{
                                        keyBundle: "entityFilter.delete.ok",
                                        parameters: []
                                    }]);
                                });

                            }));
                    });
                };
            }]);
    });