import searchModule from "./searchModule"

searchModule.service("spreadsheetCustomActionsService", [function spreadsheetCustomActionsService() {

    this.customizedOptions = (entityType) => {
        var optionsByEntityType = new Map()
        optionsByEntityType.set("locality", populateLocalityCustomizedOptions())
        return optionsByEntityType.get(entityType)
    }

    var populateLocalityCustomizedOptions = () => {
        return [{
            //Locality operations
            entityType: "incompatibleVehicle",
            buttonName: "searchView.spreadsheet.incompatibleVehicleButton",
        }, {
            entityType: "allowedPlate",
            buttonName: "searchView.spreadsheet.allowedPlateButton",
        }, {
            entityType: "serviceTime",
            buttonName: "searchView.spreadsheet.serviceTimeButton"
        }]

    }
}])