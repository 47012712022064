define(["./backgroundTaskJobModule", "../arrays/arrays"], function (managerModule, arrays) {
    "use strict";

    managerModule.controller("BackgroundTaskJobController", [
        "$scope",
        "nlgBasketService",
        "$http",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        "automaticOptimizationViolationModal",
        "$modal",
        "downloadBinary",
        "backgroundTaskJobService",
        "backgroundTaskJobUrls",
        function ($scope, nlgBasketService, $http, loadingService, remoteExceptionHandler, messagesModal, automaticOptimizationViolationModal, $modal, downloadBinary, backgroundTaskJobService, backgroundTaskJobUrls) {

            var filterPatch = null;
            var paginationApi;

            $scope.filterServiceUrl = backgroundTaskJobUrls.filterService;
            $scope.tableOptions = {
                records: [],
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        return loadingService(nlgBasketService.doFilterPresentationTree($scope.filterServiceUrl, filterPatch, {
                            firstResult: firstResult,
                            maxResults: maxResults
                        }).then(function (resp) {
                            var result = resp.data;
                            result.result = result.result[0].children;
                            mapChildrenProperties(result.result);
                            return result;
                        }));
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                }
            };

            function mapChildrenProperties(children) {
                arrays.each(children, function (child) {
                    var map = {};
                    arrays.each(child.properties, function (property) {
                        map[property.messageKey] = property;
                    });
                    child.mappedProperties = map;
                });
            }

            $scope.search = function (patch) {
                filterPatch = patch;
                return paginationApi.seekPage(1);
            };

            $scope.cancel = function (record) {
                var bundle;
                if (record.mappedProperties["background.task.job.jobType"].value === "br.com.neolog.model.backgroundtask.BackgroundTask.GENERATE_REPORT") {
                    bundle = "background.task.job.confirm.report.abort";
                } else {
                    bundle = "background.task.job.confirm.task.abort";
                }
                messagesModal.cancellable("dialog.warning", [bundle]).then(function () {
                    return loadingService($http.post(backgroundTaskJobUrls.cancelJob + record.id))
                        .then(function () {
                            return messagesModal("dialog.success", [{
                                keyBundle: "background.task.job.confirmation.modal.message.success",
                                parameters: []
                            }]);
                        }).then(function () {
                            return paginationApi.seekPage($scope.tableOptions.pagination.currentPage);
                        }).catch(remoteExceptionHandler());
                });
            };

            $scope.violations = function (record) {
                loadingService(backgroundTaskJobService.violations(record.id))
                    .then(function (response) {
                        if (response.data.header) {
                            messagesModal(response.data.header, response.data.exceptionMessages);
                        } else {
                            automaticOptimizationViolationModal(response.data);
                        }
                    }).catch(remoteExceptionHandler());
            };

            $scope.downloadTask = function (record) {
                loadingService(backgroundTaskJobService.canDownloadTask(record.id)
                    .then(function (response) {
                        if (response.data.header) {
                            messagesModal(response.data.header, response.data.exceptionMessages);
                        }
                        return backgroundTaskJobService.getExportType(record.id);
                    })
                    .then(function (response) {
                        return downloadBinary.get(backgroundTaskJobUrls.downloadTask + record.id, record.id + response.data);
                    }))
                    .catch(function (error) {
                        if (error.data.header) {
                            messagesModal(error.data.header, error.data.exceptionMessages);
                        } else {
                            remoteExceptionHandler()(error);
                        }
                    });
            };
        }]);
});