define(["../tdnHelpModule", "text!./nlgTdnHelpTitleTemplate.html"], function (module, nlgTitleTdnHelpTemplate) {
    "use strict";

    return module.directive("nlgTdnHelpTitle", [function () {
        return {
            template: nlgTitleTdnHelpTemplate,
            transclude: true,
            scope: {
                pageTitle: "@",
                pageIcon: "@",
                tdn: "<?"
            },
            controller: ["$scope", "tdnHelp", function ($scope, tdnHelp) {
                $scope.context = tdnHelp.getContext();
            }]
        };
    }]);
});
