define([
    "./monitoringLocalityModule",
    "angular"
], function (module, angular) {
    "use strict";

    return module.controller("monitoringLocalityControllerEdition", [
        "$scope",
        "$state",
        "locality",
        "monitoringLocalityService",
        "loadingService",
        "nlgTagInputIconMap",
        "remoteExceptionHandler",
        function ($scope, $state, locality, monitoringLocalityService, loadingService, nlgTagInputIconMap, remoteExceptionHandler) {
            $scope.entity = locality;
            if (!$scope.entity.localityType) {
                $scope.entity.localityType = "br.com.neolog.monitoring.monitorable.model.api.LocalityType.UNDEFINED";
            }
            $scope.entityDto = {
                zipCode: locality.zipCode,
                polygonElements: locality.polygons
            };
            $scope.addressAdapter = {
                address: {
                    city: locality.city,
                    complement: locality.complement,
                    country: locality.country,
                    district: locality.district,
                    number: locality.number,
                    geographicInfo: {
                        latitude: locality.latitude,
                        longitude: locality.longitude
                    },
                    state: locality.state,
                    street: locality.street,
                    zipCode: locality.zipCode
                },
                phone: locality.phone
            };

            $scope.onRegisterApi = function (api) {
                $scope.polygonDrawerApi = api
            };

            function addressAdapterToEntity() {
                $scope.entity.city = getRealName($scope.addressAdapter.address.city);
                $scope.entity.country = getRealName($scope.addressAdapter.address.country);
                $scope.entity.state = getRealName($scope.addressAdapter.address.state);
                $scope.entity.district = $scope.addressAdapter.address.district;
                $scope.entity.street = $scope.addressAdapter.address.street;
                $scope.entity.zipCode = $scope.entityDto.zipCode;
                $scope.entity.latitude = $scope.addressAdapter.address.geographicInfo.latitude;
                $scope.entity.longitude = $scope.addressAdapter.address.geographicInfo.longitude;
                $scope.entity.complement = $scope.addressAdapter.address.complement;
                $scope.entity.number = $scope.addressAdapter.address.number;
                $scope.entity.phone = $scope.addressAdapter.phone;
            }

            function setPolygons() {
                if ($scope.polygonDrawerApi && $scope.polygonDrawerApi.getPolygons) {
                    $scope.entity.polygons = $scope.polygonDrawerApi.getPolygons();
                }
            }

            function getRealName(value) {
                if (!value || angular.isString(value)) {
                    return value;
                }
                return value.description;
            }

            $scope.back = function () {
                return $state.go("monitoring.locality", {
                    reload: true
                });
            };

            $scope.save = function () {
                addressAdapterToEntity();
                setPolygons();
                loadingService(monitoringLocalityService.save($scope.entity).catch(remoteExceptionHandler()).then(function (success) {
                    if (success[0].result) {
                        var id = success[0].parameters;
                        return $state.go("monitoring.editLocality", {
                            localityId: id,
                            reload: true
                        });
                    }

                }));
            };

            $scope.saveAndContinue = function () {
                addressAdapterToEntity();
                setPolygons();
                loadingService(monitoringLocalityService.save($scope.entity).catch(remoteExceptionHandler()).then(function (success) {
                    if (success[0].result) {
                        return $state.go($state.current, {reload: Date.now()});
                    }
                }));
            };

            $scope.getLocalityTypes = function () {
                return monitoringLocalityService.getLocalityTypes();
            };

            $scope.getLocalityIcons = function () {
                return monitoringLocalityService.getLocalityIcons();
            };

            $scope.getIconPreview = function () {
                return nlgTagInputIconMap.iconMapBig()[$scope.entity.localityIcon];
            };
        }
    ]);
});
