import searchModule from "./searchModule"
import server from "../server"

searchModule.service("searchService", ["$http", function searchService($http){

    this.validateType = (entityType) => {
        return !!getEntityUrl(entityType)
    }

    this.filter = (entityType, template, firstResult, maxResults) =>{
        if(!this.validateType(entityType)){
            throw new Error("Cannot determine serviceURL for type: " + entityType)
        }
        return $http.post(getEntityUrl(entityType) + "presentation-tree", template, {
            params: {
                firstResult,
                maxResults
            }
        }).then((response) => response.data)
    }
}])

function getEntityUrl(entityType){
    return server.getServiceUrl("search." + entityType)
}