define([
    "./userPermissionsModule",
    "text!./UserPermissionsView.html"
], function (module, userPermissionsTemplate) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.monitoringPermissions", {
                url: "/permissions",
                controller: "UserPermissionsController",
                template: userPermissionsTemplate,
                data: {
                    group: "group.monitoring.permissions",
                    pageTitle: "{{'title.monitoring.monitoringPermissions' | translate:$stateParams}}"
                }
            });
    }]);
});
