define([
    "./occurrencesModule",
    "./occurrenceRouter",
    "./edition/MonitoringOccurrenceEditionController",
    "./newOccurrence/newOccurrenceModal",
    "./newOccurrence/newOccurrenceService",
    "./newOccurrence/nlgNewOccurrence",
    "./search/MonitoringOccurrenceSearchController",
    "./search/monitorablesAndTransitionsDialog",
    "./nlgOccurrenceComments",
    "./nlgMonitorableOccurrences",
    "./occurrenceService",
    "./nlgOccurrenceHeader",
    "./tableDynamic/nlgTableDynamicService",
    "./tableDynamic/nlgTableDynamicManagerModal"
], function () {
});