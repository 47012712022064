import sessionRecordsModule from "./sessionRecordsModule"
import server from "../server"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"

sessionRecordsModule.controller("sessionRecordsController", ["$scope",
    "$q", "loadingService", "sessionRecordsService", "sessionRecordsModal", "remoteExceptionHandler", ($scope, $q, loadingService, sessionRecordsService, sessionRecordsModal, remoteExceptionHandler) => {

        const paginationApi = $q.defer()
        let filterPatch = []

        $scope.filterServiceUrl = server.getRelativeUrl("search.session-records")

        $scope.tableOptions = {
            records: [],
            columns: [{
                header: "presentationnode.nodeproperty.sessionrecord.interface",
                cell: "{{record.mappedProperties['presentation.sessionrecord.pointName'].value}}",
                cellName: "pointName"
            }, {
                header: "presentationnode.nodeproperty.sessionrecord.start",
                cell: "{{record.mappedProperties['presentationnode.sessionrecord.start'].value | date:'short'}}",
                cellName: "start"
            }, {
                header: "presentationnode.nodeproperty.sessionrecord.end",
                cell: "{{record.mappedProperties['presentationnode.sessionrecord.end'].value | date:'short'}}",
                cellName: "end"
            }, {
                header: "presentationnode.nodeproperty.sessionrecord.inserted",
                cell: "{{record.mappedProperties['presentationnode.sessionrecord.createdRecords'].value}}",
                cellName: "createdRecords"
            }, {
                header: "presentationnode.nodeproperty.sessionrecord.updated",
                cell: "{{record.mappedProperties['presentationnode.sessionrecord.updatedRecords'].value}}",
                cellName: "updatedRecords"
            }, {
                header: "presentationnode.nodeproperty.sessionrecord.deleted",
                cell: "{{record.mappedProperties['presentationnode.sessionrecord.deletedRecords'].value}}",
                cellName: "deletedRecords"
            }, {
                header: "presentationnode.nodeproperty.sessionrecord.denied",
                cell: "{{record.mappedProperties['presentationnode.sessionrecord.rejectedRecords'].value}}",
                cellName: "rejectedRecords"
            }, {
                header: "presentationnode.nodeproperty.sessionrecord.status",
                cell: "{{record.mappedProperties['presentationnode.sessionrecord.status'].value}}",
                cellName: "status"
            }, {
                cell: {
                    template: "<button name='inspect' class='btn btn-primary btn-xs' ng-disabled=\"!record.mappedProperties['presentationnode.sessionrecord.isViewLog'].value\" ng-click='inspectRecord(record)'><span class='fa fa-search'></span></button>"
                },
                cellName: "inspectButton",
                width: "10px"
            }],
            pagination: {
                onPaginate: function (firstResult, maxResults) {
                    return loadingService(sessionRecordsService.filter("session-records", filterPatch, firstResult, maxResults)
                        .then(function (response) {
                            response.result = response.result[0].children
                            mapChildrenProperties(response.result)
                            return response
                        }))
                }
            },
            onRegisterPagination: function (api) {
                paginationApi.resolve(api)
            }
        }

        function mapChildrenProperties(children) {
            arrays.each(children, function (child) {
                var map = {}
                arrays.each(child.properties, function (property) {
                    map[property.messageKey] = property
                })
                child.mappedProperties = map
            })
        }

        $scope.search = function (patch) {
            filterPatch = patch
            return paginationApi.promise.then((api) => api.seekPage(1))
        }

        $scope.inspectRecord = (record) => {
            sessionRecordsService.find(record.id)
                .then((response) => {
                    sessionRecordsModal(response.sessionId)
                }).catch(remoteExceptionHandler())
        }
    }])
