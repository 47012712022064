import module from "./r2BackgroundTaskJobModule"
import {IStateProvider} from "angular-ui-router";

export default module.config(function ($stateProvider: IStateProvider) {
    $stateProvider.state("r2.backgroundTaskJob", {
        data: {
            pageTitle: "{{'menu.configuration.backgroundTaskJob' | translate}}"
        },
        url: "/backgroundTaskJob",
        template: "<nlg-background-task-job-search></nlg-background-task-job-search>",
    })
});