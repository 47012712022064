import availabilityModule from "./availabilityModule"
import availabilityView from "./availabilityView.html"

availabilityModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.vehicle-availability", {
        data: {
            pageTitle: "{{'title.r2.vehicle-availability' | translate}}"
        },
        url: "/vehicle-availability",
        template: availabilityView,
        controller: "availabilityController"
    })
}])