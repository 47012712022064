import carrierZoneModule from "../carrierZoneModule"
import server from "../../server"

carrierZoneModule.service("carrierZoneBlockedVehicleService", ["$http", function ($http) {

    this.findVehicles = () => {
        return $http.get(server.getServiceUrl("edition.vehicle") + "find-all")
            .then((response) => response.data)
    }

    this.findTrucks = () => {
        return $http.get(server.getServiceUrl("edition.truck") + "find-all")
            .then((response) => response.data)
    }
}])