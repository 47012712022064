define(["./nlgSingleListModule", "../arrays/arrays", "text!./nlgSingleList.html"], function (nlgSingleListModule, arrays, template) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:nlgSingleList
     * @description
     * Lista ordenável com possibilidade de validação de movimentação de elementos da lista.
     *
     * @param {object[]=} ngModel Lista com os itens selecionado pelo usuário, exibidos.
     * @param {function()=} canMoveUp função que valida movimentação para cima do elemento
     * @param {function()=} canMoveDown função que valida movimentação para para baixo do elemento
     * @param {boolean()=} esconde botão de de ordenação
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div  nlg-single-list name="singleListExemplo" ng-model="selection" ></div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.selection = ['A', 'b', 'C'];
     *         });
     *     </file>
     * </example>
     * */

    return nlgSingleListModule.directive("nlgSingleList", ["entityFormatFilter", "entityFilterFilter", function (entityFormatFilter, entityFilter) {
        return {
            restrict: "A",
            template: template,
            scope: {
                ngModel: "=?ngModel",
                canMoveUp: "&?",
                canMoveDown: "&?",
                hideButtons: "&?",
                format: "&?",
                filterOption: "&?",
                multipleSelection: "<?"
            },
            controller: ["$scope", function ($scope) {
                $scope.ngModel = $scope.ngModel || [];
                $scope.hideButtons = $scope.hideButtons || false;

                $scope.canMoveUp = $scope.canMoveUp || function () {
                    return true;
                };

                $scope.canMoveDown = $scope.canMoveDown || function () {
                    return true;
                };

                $scope.availableItens = [];

                $scope.list = {
                    selection: []
                };

                $scope.formatOption = function (option) {
                    if ($scope.format) {
                        return $scope.format({option: option});
                    }
                    return entityFormatFilter(option);
                };

                $scope.filterModel = function (ngModel) {
                    if ($scope.filterOption) {
                        return $scope.filterOption({filterString: $scope.filter});
                    }
                    return entityFilter(ngModel, $scope.filter);
                };

                $scope.moveSelectionToUp = function () {
                    move($scope.list.selection, move.UP);
                };

                $scope.moveSelectionToDown = function () {
                    move(arrays.copy($scope.list.selection).reverse(), move.DOWN);
                };

                $scope.isUpAble = function () {
                    if ($scope.multipleSelection) {
                        return $scope.isMultipleSelectionUpAble();
                    }
                    var upIndex = $scope.ngModel.indexOf($scope.list.selection[0]) - 1;
                    if (upIndex === null || upIndex < 0 || $scope.list.selection.length > 1) {
                        return false;
                    }
                    return $scope.canMoveUp({
                        selected: $scope.list.selection,
                        up: $scope.ngModel[upIndex]
                    });
                };

                $scope.isMultipleSelectionUpAble = function () {
                    var upIndex = this.getMultipleSelectionUpIndex();
                    var selection = $scope.list.selection;
                    if (selection.length === 0) {
                        return false;
                    }
                    if (upIndex === null || upIndex < 0 || !$scope.validateSequenceByIndex()) {
                        return false;
                    }
                    return $scope.canMoveUp({
                        selected: selection,
                        up: $scope.ngModel[upIndex]
                    });
                };

                $scope.getMultipleSelectionUpIndex = function () {
                    return $scope.ngModel.indexOf($scope.list.selection[0]) - 1;
                };

                $scope.validateSequenceByIndex = function () {
                    var rangeSelected = $scope.list.selection.length - 1;
                    if (rangeSelected === 0) {
                        return true;
                    }
                    var firstSelectedIndex = $scope.ngModel.indexOf($scope.list.selection[0]);
                    var lastSelectedIndex = $scope.ngModel.indexOf($scope.list.selection[$scope.list.selection.length - 1]);
                    return firstSelectedIndex + rangeSelected === lastSelectedIndex;
                };

                $scope.isDownAble = function () {
                    if ($scope.multipleSelection) {
                        return $scope.isMultipleSelectionDownAble();
                    }
                    var downIndex = $scope.ngModel.indexOf($scope.list.selection[0]) + 1;
                    if (downIndex === null || downIndex >= $scope.ngModel.length || $scope.list.selection.length > 1) {
                        return false;
                    }
                    return $scope.canMoveDown({
                        selected: $scope.list.selection,
                        down: $scope.ngModel[downIndex]
                    });
                };

                $scope.isMultipleSelectionDownAble = function () {
                    var downIndex = this.getMultipleSelectionDownIndex();
                    var selection = $scope.list.selection;
                    if (selection.length === 0) {
                        return false;
                    }
                    if (downIndex === null || downIndex >= $scope.ngModel.length || !$scope.validateSequenceByIndex()) {
                        return false;
                    }
                    return $scope.canMoveDown({
                        selected: selection,
                        down: $scope.ngModel[downIndex]
                    });
                };

                $scope.getMultipleSelectionDownIndex = function () {
                    return $scope.ngModel.indexOf($scope.list.selection[$scope.list.selection.length - 1]) + 1;
                };

                move.UP = -1;
                move.DOWN = 1;

                function move(selection, direction) {
                    var i;
                    for (i = 0; i < selection.length; i++) {
                        var oldIndex = $scope.ngModel.indexOf(selection[i]);
                        var upperIndex = oldIndex + direction;
                        if (upperIndex < 0 || upperIndex >= $scope.ngModel.length) {
                            return;
                        }
                        arrays.swap($scope.ngModel, upperIndex, oldIndex);
                    }
                }
            }]
        };
    }]);
});