define(["./violationModule", "text!./violationModalDescription.html"], function (violationModule, violationModalDescriptionTemplate) {
    "use strict";

    /**
     * @ngdoc directive
     * @name violationModule.directive:violationModalDescription
     * @restrict E
     * @description
     * Diretiva que contém informações mais detalhadas de violações,
     * como o protótipo da viagem e as mensagens de violação.
     *
     * @param {expression} index Índice desta violação.
     * @param {expression} code Código da entidade com violação.
     * @param {expression} message Mensagems da violação.
     * */
    return violationModule.directive("violationModalDescription", [function () {
        return {
            restrict: "E",
            template: violationModalDescriptionTemplate,
            scope: {
                code: "@",
                message: "<",
                index: "@",
                description: "@?"
            },
            link: {
                pre: function preLink(scope, element, attrs) {
                    scope.description = attrs.description !== undefined
                        ? scope.description
                        : "violation";
                }
            }
        };
    }]);
});
