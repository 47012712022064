import panelModule from "./r2PanelModule"
import panelTitleView from "./panelTitleView.html"

panelModule.directive("nlgPanelTitle", [()=>{
    return {
        restrict: "E",
        require: "^nlgPanel",
        template: panelTitleView,
        transclude: true
    }
}])