define([
    "./monitoringNodeCommandsModule"
], function (module) {
    "use strict";

    return module.service("nodeCommandTransitionFinalize", ["transitionService", function (transitionService) {
        return function (selection, context) {
            return transitionService.accomplishTransitions(selection.map(function (nodeIdentifier) {
                return nodeIdentifier.id;
            })).then(function () {
                return context.refilter();
            });
        };
    }]);
});