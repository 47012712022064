define([
    "./monitoringNodeCommandsModule",
    "angular"
], function (module, angular) {
    "use strict";

    return module.service("nodeCommandMonitorableDetails", ["$state", "$window", function ($state, $window) {
        return {
            generalTab: function (selection) {
                return openMonitorableEdition(selection, "general");
            },
            transitionsTab: function (selection) {
                return openMonitorableEdition(selection, "transitions");
            },
            occurrencesTab: function (selection) {
                return openMonitorableEdition(selection, "occurrences");
            }
        };

        function openMonitorableEdition(selection, tab) {
            if (selection.length !== 1) {
                throw new Error("nodeCommandMonitorableDetails only available for single node identifier. Got " + angular.toJson(selection));
            }
            var href = $state.href("monitoring.monitorableEdition", {
                monitorableId: selection[0].id,
                tab: tab
            });
            $window.open(href, '_blank');
            return href;
        }
    }]);
});