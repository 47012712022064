define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactorySummaryTemplate", [function () {
        return function (config) {
            var element = angular.element("<div>");
            if (config.context) {
                element.attr("summary-chart", config.context);
            }
            return element;
        };
    }]);
});
