import restrictionsModule from "./restrictionsModule"
import restrictionsView from "./restrictionsView.html"

restrictionsModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.restrictions", {
        data: {
            pageTitle: "{{'title.r2.restrictions' | translate}}"
        },
        url: "/restrictions",
        template: restrictionsView,
        controller: "restrictionsController"
    })
}])