define([
    "./nlgSaveAndContinueModule",
    "text!./nlgSaveAndContinueView.html"
], function (module, template) {
    return module.directive("saveAndContinue", [function () {
        "use strict";

        return {
            restrict: "E",
            scope: {
                save: "&",
                back: "&",
                saveAndContinue: "&",
                disabledButtons:"<?"
            },
            template: template
        };
    }]);

});