define([
        "angular", "../occurrencesModule",],
    function (angular, module) {
        "use strict";

        return module.factory("tableDynamicService", [
            function () {
                return {
                    getDynamicColumns: function (hiddenColumnIdentifiers, tableOptions) {
                        tableOptions.columns.forEach(function (column) {
                            var isHide = hiddenColumnIdentifiers.includes(column.cellName);
                            if (!column.actions) {
                                column.hide = isHide;
                            }
                        });
                        return tableOptions.columns;
                    },
                    getColumnsDefinition: function (tableOptions) {
                        return tableOptions.columns.reduce(function (columns, column) {
                            if (!column.actions) {
                                var definition = {
                                    cellName: column.cellName,
                                    header: column.header,
                                    show: !!!column.hide
                                };
                                columns.push(definition);
                            }
                            return columns;
                        }, []);
                    }
                };

            }]);
    });
