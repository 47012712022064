define([
    "./nlgHealthCheckerModule",
    "text!./nlgHealthCheckerDetails.html"
], function (module, template) {
    "use strict";

    return module.directive("nlgHealthCheckerDetails", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                instance: "=",
                checkerName: "=",
            },
            controller: ["$scope", "loadingService", "remoteExceptionHandler", "nlgHealthCheckerService", function ($scope, loadingService, remoteExceptionHandler, nlgHealthCheckerService) {
                $scope.model = {
                    configurations: {}
                };
                refresh();

                $scope.refresh = refresh;

                function refresh() {
                    return loadingService(nlgHealthCheckerService.getHealthInfos($scope.instance, $scope.checkerName, $scope.model.configurations))
                        .catch(remoteExceptionHandler())
                        .then(function (healthInfos) {
                            $scope.healthInfos = healthInfos;
                        });
                }
            }]
        };
    }]);
});