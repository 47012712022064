define(["./monitoringCarrierTripOverviewModule"], function (module) {
    "use strict";

    return module.controller("monitoringCarrierTripOverviewController", [
        "$scope",
        function ($scope) {
            $scope.currentDashboard =   {
                dashboardSourceId: ""
            };
        }]);
});