define(["angular", "./monitoringCommonsModule"],
    function (angular, monitoringCommonsModule) {
        "use strict";

        monitoringCommonsModule.service("monitoringCommonsService", ["$http", "monitoringCommonsServiceUrls", function ($http, monitoringCommonsServiceUrls) {
            return {
                getFilterTemplateUrl: getFilterTemplateUrl,
                filter: filter
            };

            function getFilterTemplateUrl(){
                return monitoringCommonsServiceUrls.filterTemplateUrl;
            }

            function filter(template, firstResult, maxResults){
                return $http.post(monitoringCommonsServiceUrls.filterUrl, template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function getData(response){
                return response.data;
            }
        }
        ]);
    });