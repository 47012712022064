define([
    "./nlgAnalysisTreeModule",
    "text!./nlgAnalysisTreeProperties.html",
    "../arrays/arrays",
    "angular"
], function (nlgAnalysisTreeModule, template, arrays, angular) {
    "use strict";

    return nlgAnalysisTreeModule.directive("nlgAnalysisTreeProperties", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                node: "=",
                properties: "=",
                calculateStyle: "@?"
            },
            controller: ["$scope", function ($scope) {
                var totalWidth;

                $scope.propertyStyle = function computeStyle(property) {
                    if ($scope.calculateStyle !== "true") {
                        return "";
                    }
                    return "width:" + 100 * (property.relativeWidth / getTotalWidth()) + "%;";
                };

                function getTotalWidth() {
                    if (angular.isUndefined(totalWidth) && angular.isDefined($scope.properties) && $scope.properties.length > 0) {
                        var calculation = 0;
                        arrays.each($scope.properties, function (property) {
                            calculation += property.relativeWidth;
                        });
                        totalWidth = calculation;
                    }
                    return totalWidth;
                }
            }]
        };
    }]);
});