define([
    "../trackingMobileModule"
], function (trackingMobileModule) {
    "use strict";

    return trackingMobileModule.controller("DeviceEditionController", [
        "$scope",
        "device",
        "$state",
        "messagesModal",
        "loadingService",
        "remoteExceptionHandler",
        "trackingMobileService",
        "$translate",
        "countryLanguages",
        function ($scope, device, $state, messagesModal, loadingService, remoteExceptionHandler, trackingMobileService, $translate, countryLanguages) {
            $scope.entity = device || {
                phoneNumber: {
                    number: null,
                    country: null
                }
            };
            $scope.model = {
                countryData: null
            };
            $scope.isEditable;

            if(device){
                $scope.isEditable = true;
            }

            $scope.back = function(){
                return $state.go("trackingMobile.deviceSearch");
            };

            $scope.defaultCountry = countryLanguages.findByLanguage($translate.use()).iso2;

            $scope.save = function(){
                if(!$scope.entity.phoneNumber || ! $scope.entity.phoneNumber.number){
                    $scope.entity.phoneNumber = null;
                } else {
                    $scope.entity.phoneNumber.country = $scope.model.countryData.iso2;
                }
                loadingService(trackingMobileService.saveOrUpdateDevice($scope.entity))
                    .then(function(deviceId){
                        messagesModal("dialog.success", ["entity.validator.successMessage"])
                            .finally(function(){
                                $state.go("trackingMobile.deviceEdition", {
                                    deviceId: deviceId
                                });
                            });
                    }).catch(remoteExceptionHandler());
            };
        }
    ]);
});