define([
    "./monitoringDriverModule",
    "front-end-web-commons/app/arrays/arrays",
    "json!../monitoring.json",
    "json!../tdnByEntity.json",
    "lodash"
], function (module, arrays, monitoring, tdnByEntity, _) {
    "use strict";

    return module.controller("MonitoringDriverSearchController", [
        "$scope",
        "monitoringService",
        "loadingService",
        "remoteExceptionHandler",
        "monitoringDriverService",
        "tdnHelp",
        function ($scope, monitoringService, loadingService, remoteExceptionHandler, monitoringDriverService, tdnHelp) {
            var paginationApi;
            var template;

            $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["driver"]

            $scope.serviceName = _.trim(monitoring.services.driver, "/");
            $scope.search = function (modifiedTemplate) {
                template = modifiedTemplate;
                paginationApi.seekPage(1);
            };

            $scope.tableOptions = {
                records: [],
                columns: getColumnDefinitions(),
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        return loadingService(monitoringService.filterDrivers(template, firstResult, maxResults))
                            .then(function (result) {
                                return result;
                            }).catch(remoteExceptionHandler());
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                }
            };

            $scope.deleteDriver = function (driver) {
                return monitoringDriverService.deleteDriver(driver)
                    .finally(function () {
                        return paginationApi.seekPage(1);
                    });
            };

            function getColumnDefinitions() {
                var actionColumn = {
                    "width": "82px",
                    "cell": {
                        "template":
                            "<a name='editButton' tooltip=\"{{'searchView.edit' | translate }}\" class='btn mini btn-default' ui-sref='monitoring.driverEdition({ driverId: record.id})' style='margin-top: 3px; margin-right: 5px'><span class='fa fa-pencil-square-o'/></a>" +
                            "<button name='deleteButton' tooltip=\"{{'searchView.delete' | translate }}\" class='btn mini btn-default' style='margin-top:3px' ng-click='deleteDriver(record)'><span class='fa fa-trash-o'/></button>"
                    }
                };

                return [actionColumn].concat(arrays.map(["identifier", "name"], function (property) {
                    var cellValue = "record." + property;
                    var header = "trackingMobile.driver." + property;
                    var result = {
                        sortBy: property,
                        cellName: property,
                        header: header
                    };
                    result.cell = "{{" + cellValue + "}}";
                    return result;
                }));
            }
        }]);
});