import analysisModule from "../analysisModule"
import server from "../../server"

analysisModule.service("nodeCommandPublishTrip", [
    "$http",
    "messagesModal",
    "remoteExceptionHandler",
    "loadingService",
    function ($http, messagesModal, remoteExceptionHandler, loadingService) {
        return select => {
            return loadingService($http.post(server.getServiceUrl("trip-publish"), select))
                .catch(remoteExceptionHandler())
                .then((response) => {
                    return messagesModal("programming.synchronize.result", response.data)
                })
        }
    }])
