import genericAttributeModule from "./genericAttributeModule"

genericAttributeModule.controller("genericAttributeController", ["$scope", "sourceEntity", "entity", "entityEditionService", "genericAttributeService", "messagesModal", ($scope, sourceEntity, entity, entityEditionService, genericAttributeService, messagesModal) => {
    const updateService = entityEditionService.crudHelper("generic-attribute", sourceEntity)
    $scope.entity = entity

    $scope.isEditable = () => !entity.id

    $scope.getAttributableTypes = genericAttributeService.getAttributableTypes

    $scope.save = () => {
        if (entity.id) {
            updateService.save(entity)
            return
        }
        cancellableModal().then(() => updateService.save(entity))
    }

    $scope.saveAndContinue = () => {
        cancellableModal().then(() => updateService.save(entity, true))
    }

    function cancellableModal() {
        return messagesModal.cancellable("dialog.proceed?", [{
            keyBundle: "genericAttribute.edition.save",
            parameters: []
        }])
    }
}])