define([
    "./monitoringQueryModule"
], function (module) {
    "use strict";
    return module.controller("monitoringQueryController", [
        "$scope",
        "monitoringFilterService",
        "query",
        function ($scope, monitoringFilterService, query) {
            $scope.filterServiceUrl = "transactional/report/query/";
            $scope.baseUrl = "../cockpit-gateway/monitoring";
            $scope.filterService = monitoringFilterService;
            $scope.query = query
        }]);
});