define([
    "./ScriptLanguage",
    "./commonScriptModule",
    "./nlgScriptEditor",
    "./nlgScriptEntityEdition",
    "./nlgScriptEntityEditionController",
    "./nlgScriptEntityEditionGeneral",
    "./nlgScriptEntityEditionCollaborators",
    "./nlgScriptEntityEditionParams",
    "./scriptService",
    "./scriptUrls",
], function () {
});