define(["angular", "./entityDTOModule", "../arrays/arrays"], function (angular, entityDTOModule, arrays) {
    "use strict";

    /**
     * @ngdoc filter
     * @name filters.filter:entityFormat
     * @function
     * @param {*} entity Entidade ou Array de Entidades à serem representadas como string.
     * @return {string} representação da entidade.
     * @description
     * Filtro para retornar uma representação em string amigável para o usuário, dado uma entidade ou um array de entidades.
     *
     * O algorítimo básico é de tentar exibir a description, ou o name, ou o sourceId da entidade.
     * Se nenhum destes campos estiver disponível retorna o {@link #toString()} da entidade.
     * Caso seja recebido um array de entidade, concatena as representações das entidades com ', ' entre elas.
     *
     * @example
     *  <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <span>{{ {description: "description" } | entityFormat }}</span><br>
     *         <span>{{ {name: "name" } | entityFormat }}</span><br>
     *         <span>{{ {sourceId: "sourceId" } | entityFormat }}</span><br>
     *         <span>{{ "string simples" | entityFormat }}</span><br>
     *         <span>Também faz tradução do resultado: {{ "filtersView.export" | entityFormat }}</span><br>
     *         <span>Funciona corretamente com números: {{ 42 | entityFormat }}</span><br>
     *         <span>Undefined não retorna nada: "{{ undefined | entityFormat }}"</span><br>
     *         <span>Null não retorna nada: "{{ null | entityFormat }}"</span><br>
     *     </file>
     * </example>
     */
    return entityDTOModule.filter("entityFormat", ["$translate", function ($translate) {
        return function (entities) {
            if (angular.isArray(entities)) {
                return arrays.map(entities, formatSingleEntity).join(", ");
            }
            return formatSingleEntity(entities);
        };

        function formatSingleEntity(entity) {
            if (entity === null || angular.isUndefined(entity)) {
                return "";
            }

            var description = entity.name || entity.description || entity.sourceId || entity.code;
            if (!description && isEntity(entity)) {
                return "";
            }

            var translationId = description || entity;
            if (!angular.isString(translationId)) {
                translationId = entity.toString();
            }
            return $translate.instant(translationId);
        }

        function isEntity(entity) {
            return angular.isDefined(entity.name) ||
                angular.isDefined(entity.description) ||
                angular.isDefined(entity.sourceId) ||
                angular.isDefined(entity.code);
        }
    }])
        .filter("jodaOrEntityFormat", ["$translate", "entityFormatFilter", "$filter", function ($translate, entityFormatFilter, $filter) {
            return function (entities, type) {
                if (angular.isArray(entities)) {
                    return arrays.map(entities, doFormat.bind(null, type));
                }
                return doFormat(type, entities);
            };

            function doFormat(type, entity) {
                if (type === "org.joda.time.LocalTime") {
                    return $filter("date")(entity, "shortTime", "GMT");
                }
                if (type === "org.joda.time.DateTime") {
                    return $filter("date")(entity, "short", "GMT");
                }
                if (type === "org.joda.time.LocalDate") {
                    return $filter("date")(entity, "shortDate", "GMT");
                }
                if (type === "org.joda.time.Duration") {
                    return $filter("duration")(entity);
                }
                return entityFormatFilter(entity);
            }
        }]);
});