import userByOriginsModule from "./userByOriginsModule"
import userByOriginsView from "./userByOriginsView.html"

userByOriginsModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.userByOrigins", {
        data: {
            pageTitle: "{{'title.r2.userByOrigins' | translate}}"
        },
        url: "/userByOrigins",
        template: userByOriginsView,
        controller: "userByOriginsController"
    })
}])