define (["./pathModule"], function (pathModule) {
    "use strict";

    pathModule.provider("pathService", [function(){
        var paths = {};

        this.$get = [function(){
            return {
                getPath: function(serviceIdentifier){
                    var path = paths[serviceIdentifier];
                    if(!path) {
                        throw new Error("Path was not configured in the pathServiceProvider for service: " + serviceIdentifier);
                    }
                    return path;
                }
            };
        }];

        this.setPaths = function(servicesPaths) {
            paths = servicesPaths;
        };
    }]);
});