define([
    "angular",
    "./addressModule"
], function (angular, addressModule) {
    "use strict";
    addressModule.provider("addressServiceUrls", [function () {

        var cityUrl;
        var stateUrl;
        var countryUrl;
        var geocodingUrl;

        this.$get = [function () {
            return {
                cityUrl: cityUrl,
                stateUrl: stateUrl,
                countryUrl: countryUrl,
                geocodingUrl: geocodingUrl
            };
        }];

        function validateUrl(url) {
            if (!angular.isObject(url)) {
                throw new Error("url is not an object: " + url);
            }
            var urlHasOwnProperty = Object.prototype.hasOwnProperty.bind(url);
            if (!urlHasOwnProperty("cityUrl")) {
                throw new Error("city url must be defined");
            }
            if (!urlHasOwnProperty("stateUrl")) {
                throw new Error("state url must be defined");
            }
            if (!urlHasOwnProperty("countryUrl")) {
                throw new Error("country url must be defined");
            }
        }

        this.setUrl = function (url) {
            validateUrl(url);
            cityUrl = url.cityUrl;
            stateUrl = url.stateUrl;
            countryUrl = url.countryUrl;
            geocodingUrl = url.geocodingUrl;
        };
    }]);
});
