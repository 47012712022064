import integrationModule from "./integrationModule"
import integrationView from "./integrationView.html"

integrationModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.integration", {
        data: {
            pageTitle: "{{'title.r2.integration' | translate}}"
        },
        url: "/integration",
        template: integrationView,
        controller: "integrationController"
    })
}])