define(["./nodeCommandsModule"], function (nodeCommandsModule) {
    "use strict";

    return nodeCommandsModule.factory("NodeCommandExecutor", [
        "nodeIdentifiers",
        function (nodeIdentifiers) {
            /**
             * @ngdoc service
             * @name nodeCommandsModule.NodeCommandExecutor
             * @description
             * Responsável por saber executar um `nodeCommand` dado um qualificador.
             **/
            function NodeCommandExecutor(commandMap, fallbackExecutor) {
                this.commandMap = commandMap;
                this.fallbackExecutor = fallbackExecutor;
            }

            /**
             * @ngdoc service
             * @name nodeCommandsModule.NodeCommandExecutor#get
             * @methodOf nodeCommandsModule.NodeCommandExecutor
             * @description
             * Retorna o comando identificado pelo `qualifier` fornecido.
             * @param {string} qualifier identificação do comando.
             * @return {NodeCommand} Comando.
             * @throw Joga um erro quando não existe um comando definido para o `qualifier`.
             * */
            NodeCommandExecutor.prototype.get = function (qualifier) {
                var result = this.commandMap[qualifier] || (this.fallbackExecutor && this.fallbackExecutor.get(qualifier));
                if (!result) {
                    throw new Error("Command '" + qualifier + "' isn't defined.");
                }
                return result;
            };

            /**
             * @ngdoc service
             * @name nodeCommandsModule.NodeCommandExecutor#execute
             * @methodOf nodeCommandsModule.NodeCommandExecutor
             * @description
             * Executa um comando identificado por um `qualifier` sobre dado os `nodes` fornecidos.
             * @param {string} qualifier identificação do comando.
             * @param {Node[]} nodes nós envolvidos na operação.
             * @param {*} context contém o que for necessário para os nodeCommands
             * @throws Joga um erro quando não existe um comando definido para o `qualifier`.
             * */
            NodeCommandExecutor.prototype.execute = function (qualifier, nodes, context) {
                this.get(qualifier)(nodeIdentifiers.toNodeIdentifiers(nodes), context, nodes);
            };
            return NodeCommandExecutor;
        }]);
});