define(["./mapsModule", "../arrays/arrays", "../colors/colors", "lodash"], function (mapsModule, arrays, colors, _) {
    "use strict";

    var OPEN_STREET_MAPS_PROVIDER_TYPE = "br.com.neolog.model.parameters.routing.PathProvider$PathProviderType.OPEN_STREET_MAPS";

    /**
     * @ngdoc service
     * @name mapsModule.mapsDirectionsService
     * @description
     * Serviço que recebe um array de rotas e retorna uma promessa que será resolvida quando todas as rotas terminarem
     * de ser geocodificadas.
     * **Atenção:** Serão adicionadas propriedades nas rotas.
     * */
    return mapsModule.service("mapsDirectionsService", ["uiGmapGoogleMapApi", "$q", "mapsLatLngApi", "mapsConfigGetter", "$timeout", "mapsPathService", function (uiGmapGoogleMapApi, $q, mapsLatLngApi, mapsConfigGetter, $timeout, _mapsPathService) {

        return function (routes) {
            var mapsConfig = null;
            var mapsLatLng = null;
            var googleMaps = null;
            var directionsService = null;
            var mapsPathService = null;
            return mapsConfigGetter.then(function (_mapsConfig) {
                mapsConfig = _mapsConfig;
                return mapsLatLngApi;
            }).then(function (mapsLatLngApi) {
                mapsLatLng = mapsLatLngApi;
                return uiGmapGoogleMapApi;
            }).then(function (maps) {
                googleMaps = maps;
                directionsService = new googleMaps.DirectionsService();
                return _mapsPathService;
            }).then(function (_mapsPathService) {
                mapsPathService = _mapsPathService;
            }).then(function () {
                arrays.each(routes, function (route, routeIndex) {
                    route.color = route.color || getRouteColor(routeIndex);
                    route.visible = true;
                });

                return $q.all(arrays.map(routes, function (route) {
                    if (route.pathProviderType === OPEN_STREET_MAPS_PROVIDER_TYPE) {
                        return mapsPathService(route);
                    }
                    route.results = [];
                    // devido à limites de waypoints do serviço do google, as rotas devem ser quebradas em partes.
                    var routePartitions = arrays.continuousPartition(route.stops, mapsConfig.maxStops);

                    return $q.all(arrays.map(routePartitions, function doRoute(routeSegment) {
                        return routeAsPromise(routeSegment).then(function (results) {
                            results.forEach(function (result) {
                                route.results.push(result);
                            });
                        });
                    })).then(function () {
                        // Resolve as legs de retorno à origem ou início de logística reversa
                        return $q.all(arrays.map(routes, function routeStartingAndEndingLegs(route) {
                            var result = [];
                            if (route.startingLeg) {
                                result.push(populateLegDirections(route.startingLeg));
                            }
                            if (route.endingLeg) {
                                result.push(populateLegDirections(route.endingLeg));
                            }
                            return $q.all(result);
                        }));

                        function populateLegDirections(leg) {
                            return routeAsPromise([leg.origin, leg.end]).then(function (results) {
                                leg.directions = results[0];
                            });
                        }
                    });
                }));
            }).then(function () {
                return routes;
            });

            function routeAsPromise(locations) {
                var defer = $q.defer();
                var request = {
                    travelMode: googleMaps.TravelMode.DRIVING,
                    origin: toLatLng(locations[0]),
                    destination: toLatLng(locations[locations.length - 1]),
                    waypoints: arrays.map(locations.slice(1, -1), function (stop) {
                        return {
                            location: toLatLng(stop)
                        };
                    })
                };
                directionsService.route(request, function (result, status) {
                    if (status === googleMaps.DirectionsStatus.OVER_QUERY_LIMIT) {
                        return $timeout(_.random(10, 50))
                            .then(function () {
                                return routeAsPromise(locations);
                            }).then(defer.resolve);
                    }
                    if (status === googleMaps.DirectionsStatus.OK) {
                        return defer.resolve([result]);
                    }
                    if (locations.length === 2) {
                        return defer.resolve([createFakeResult(request, locations)]);
                    }
                    return defer.resolve(findUnrouteableLocation(locations));
                });
                return defer.promise;
            }

            function toLatLng(stop) {
                return mapsLatLng.toLatLng(stop.address.geographicInfo);
            }

            function findUnrouteableLocation(locations) {
                var partitionedLocations = arrays.continuousPartition(locations, getCurrentMaxStops(locations));
                return $q.all(arrays.map(partitionedLocations, routeAsPromise))
                    .then(function (results) {
                        return _.flatten(results);
                    });
            }

            function createFakeResult(request, locations) {
                var lastLocation = null;
                return {
                    "routes": [{
                        "legs": locations.map(function (location) {
                            var leg = null;
                            if (lastLocation === null) {
                                return leg;
                            }
                            leg = {
                                "end_location": localityToLatLong(location),
                                "start_location": localityToLatLong(lastLocation),
                                "steps": [{
                                    "end_location": localityToLatLong(location),
                                    "polyline": new googleMaps.Polyline({
                                        path: [localityToLatLong(lastLocation), localityToLatLong(location)]
                                    }),
                                    "start_location": localityToLatLong(lastLocation),
                                    "travel_mode": "DRIVING",
                                    "path": [localityToLatLong(lastLocation), localityToLatLong(location)],
                                    "start_point": localityToLatLong(lastLocation),
                                    "end_point": localityToLatLong(location)
                                }],
                            };
                            lastLocation = location;
                            return leg;
                        }).filter(function (leg) {
                            return leg !== null;
                        }),
                        "summary": "",
                        "warnings": [],
                        "waypoint_order": locations.map(function (location, idx) {
                            return idx;
                        }),
                        "overview_path": locations.map(localityToLatLong),
                        "routeIcons": [{
                            icon: {
                                path: "M 0,-.5 0,.5",
                                strokeOpacity: 1,
                                scale: 4
                            },
                            offset: "0",
                            repeat: "15px"
                        }]
                    }],
                    "status": "OK",
                    "request": request
                };

                function localityToLatLong(location) {
                    return new googleMaps.LatLng(
                        location.address.geographicInfo.latitude,
                        location.address.geographicInfo.longitude);
                }
            }

            function getCurrentMaxStops(locations) {
                if (locations.length <= 2) {
                    return locations.length;
                }
                return locations.length / 2 + 1;
            }
        };
    }]);

    function getRouteColor(routeIndex) {
        return colors.getColor(routeIndex);
    }
});