define([
    "./filterModule",
    "../arrays/arrays",
    "../objects/objects",
    "angular"
], function (filterModule, arrays, objects, angular) {
    "use strict";

    return filterModule.controller("nlgFilterEdit", ["$scope", "$modalInstance", "selectedFilters", "originalTemplate", "$translate", function ($scope, $modalInstance, selectedFilters, originalTemplate, $translate) {
        var filters = arrays.map(objects.values(originalTemplate), filterToDto);
        var extensionKeyField = ".extension.key";
        var extensionField = ".extension.";

        var filterNames = arrays.map(filters, filterDtoToName).sort(function (a, b) {
            // orderna por bundle traduzido
            var firstFilterField = formatFilterOption(a);
            var secondFilterField = formatFilterOption(b);
            if (firstFilterField > secondFilterField) {
                return 1;
            } else if (firstFilterField < secondFilterField) {
                return -1;
            }
            return 0;
        });

        $scope.selectedFilters = arrays.map(selectedFilters, filterToDto);

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };
        $scope.apply = function () {
            var result = arrays.filter($scope.selectedFilters, function (filter) {
                return filter.operation;
            });

            result = arrays.map(result, function (filter, idx) {
                var result = angular.copy(originalTemplate[filter.name]);
                if (typeof result === "undefined") {
                    var extraExtensionFilter = selectedFilters.filter(function (selectedFilter) {
                        return selectedFilter.fieldName === filter.name;
                    });
                    result = extraExtensionFilter[0];
                }
                result.operation = filter.operation;
                var oldFilter = selectedFilters[idx] || {};
                if (oldFilter.fieldName === result.fieldName && oldFilter.operation === result.operation) {
                    result.value = oldFilter.value;
                    result.multipleValue = oldFilter.multipleValue;
                }
                return result;
            });

            $modalInstance.close(result);
        };
        $scope.addFilter = function () {
            if (objects.values(originalTemplate).length > $scope.selectedFilters.length) {
                $scope.selectedFilters.push({});
            }
        };

        $scope.removeFilter = function (index) {
            if ($scope.selectedFilters === null || $scope.selectedFilters.length < index) {
                return;
            }
            var filter = $scope.selectedFilters[index];
            if (filter === null) {
                return;
            }

            var extensionOwner = filter.name.split(".")[0];
            if (filter.name.indexOf(extensionKeyField) !== -1) {
                var extraExtensionValueFilter = $scope.selectedFilters.filter(function (selectedFilter) {
                    return selectedFilter.name && selectedFilter.name.startsWith(extensionOwner + extensionField) && selectedFilter.name !== extensionOwner + extensionKeyField;
                });
                arrays.remove($scope.selectedFilters, extraExtensionValueFilter[0]);
            }

            arrays.removeAt($scope.selectedFilters, index);
            if ($scope.selectedFilters.length === 0) {
                $scope.addFilter();
            }
        };

        $scope.getAvailableFilterFields = function (selectedFilter) {
            var selectedFilterNames = arrays.map($scope.selectedFilters, filterDtoToName);
            return arrays.filter(filterNames, function (filter) {
                return filter === selectedFilter.name || !(arrays.contains(selectedFilterNames, filter));
            });
        };

        $scope.formatFilterOption = formatFilterOption;

        function formatFilterOption(filterField) {
            if (!filterField) {
                return "";
            }

            //Assume-se que ponto seguido de espaços em branco formam uma frase.
            var subTypes = /\.\S/.test(filterField) ? filterField.split(".") : [filterField];
            var last = subTypes.pop();
            return arrays.map(subTypes, function (subType) {
                return translate("entityCrudView.filter.inner." + subType);
            }).concat([translate("entityCrudView.filter.field." + last)]).join(" / ");
        }

        function translate(string) {
            return $translate.instant(string)
                .replace(/^entityCrudView\.filter\.field\./, "")
                .replace(/^entityCrudView\.filter\.inner\./, "")
                .replace(/_/g," ");
        }

        $scope.filterOperationSelected = function (filter) {
            if (!filter) {
                return;
            }
            filter.operation = filter.operation || $scope.getFilterAvailableOperations(filter)[0];
        };

        $scope.getFilterAvailableOperations = function (filter) {
            var filterEntry = originalTemplate[filter.name];

            if (typeof filterEntry === "undefined") {
                var extraExtensionFilter = selectedFilters.filter(function (selectedFilter) {
                    return selectedFilter.fieldName === filter.name;
                });
                filterEntry = extraExtensionFilter[0];
            }

            if (!filterEntry) {
                return [];
            }
            return filterEntry.availableOperations || filterEntry.filterType.defaultOperations;
        };

        if (selectedFilters.length === 0) {
            $scope.addFilter();
        }
    }]);

    function filterToDto(filter) {
        return {
            name: filter.fieldName,
            operation: filter.operation,
            required: filter.required
        };
    }

    function filterDtoToName(filter) {
        return filter.name;
    }
});