define([
    "./monitorableModule",
    "text!./monitorableSearch.html",
    "text!./monitorableEdition.html"
], function (
    monitorableModule,
    monitorableSearchTemplate,
    monitorableEditionTemplate
) {
    "use strict";

    monitorableModule.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.monitorableSearch", {
                url: "/monitorable?reload",
                controller: "MonitorableSearchController",
                template: monitorableSearchTemplate,
                params: {
                    preTemplate: null
                },
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.monitoring.trip",
                    pageTitle: "{{'title.monitoring.monitorableSearch' | translate:$stateParams}}"
                }
            })
            .state("monitoring.monitorableEdition", {
                url: "/monitorable/:monitorableId?tab",
                controller: "MonitorableEditionController",
                template: monitorableEditionTemplate,
                data: {
                    group: "group.monitoring.trip",
                    pageTitle: "{{'title.monitoring.monitorableEdition' | translate:$stateParams}}"
                },
                resolve: {
                    monitorable: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.findMonitorableByIdWithAllFetchers($stateParams.monitorableId);
                    }]
                }
            });
    }]);
});
