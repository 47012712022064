define(["../occurrencesModule"],
    function (occurrencesModule) {
        "use strict";

        return occurrencesModule.controller("MonitoringOccurrenceEditionController", [
            "$scope",
            "$state",
            "occurrence",
            "monitoringService",
            "occurrenceService",
            "remoteExceptionHandler",
            "$interval",
            "$window",
            "$timeout",
            function ($scope, $state, occurrence, monitoringService, occurrenceService, remoteExceptionHandler, $interval, $window, $timeout) {
                monitoringService.getContentExclusion().then(function (response) {
                    $scope.contentExclusion = response;
                });

                var printOnLoad = $state.current.data.printOnLoad;
                $scope.printOnLoad = printOnLoad;
                $scope.entity = occurrence || {};
                var checkComments;
                if (!printOnLoad) {
                    checkComments = $interval(loadComments, 30000);
                } else {
                    $timeout($window.print, 2000);
                }

                monitoringService.findMonitorableByOccurrenceId($scope.entity.id)
                    .then(function (monitorables) {
                        $scope.monitorableSourceId = monitorables.map(({ sourceId }) => sourceId).join('-');
                    });

                function loadComments() {
                    return occurrenceService.findById($scope.entity.id)
                        .catch(remoteExceptionHandler())
                        .then(function (occurrence) {
                            $scope.entity = occurrence;
                        });
                }

                $scope.$on("$destroy", function () {
                    $interval.cancel(checkComments);
                });

                $scope.back = function () {
                    return $state.go("monitoring.monitoringOccurrenceSearch", {
                        reload: true
                    });
                };
            }]);
    });