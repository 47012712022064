import analysisMapModule from "../analysisMapModule"
import organizeModal from "./organizeModal.html"
import _ from "lodash"
import angular from "angular"
import arrays from "../../../bower_components/front-end-web-commons/app/arrays/arrays"

analysisMapModule.service("organizeModal", ["$modal", function ($modal) {
    return function (organizeOperations, configs) {
        return $modal.open({
            template: organizeModal,
            size: "lg",
            controller: ["$scope", "autocompleteFactory", function ($scope, autocompleteFactory) {
                $scope.config = {
                    routingConfig: configs.routingConfig
                }

                $scope.organizeOperations = organizeOperations
                $scope.vehicles = autocompleteFactory.lazyLoader("vehicle")

                $scope.tableOptions = {
                    columns: [{
                        cellName: "organize.type",
                        header: "organize.type",
                        cell: "{{ record.node.identifier.type.messageKey }}"
                    },  {
                        cellName: "organize.order.item.codes",
                        header: "organize.order.item.codes",
                        cell: "{{ record.orderItemCodes.join(\", \") }}"
                    }, {
                        cellName: "organize.order.codes",
                        header: "organize.order.codes",
                        cell: "{{ record.orderCodes.join(\", \") }}"
                    }, {
                        cellName: "organize.product.codes",
                        header: "organize.product.codes",
                        cell: "{{ record.productCodes.join(\", \") }}"
                    }, {
                        width: "10%",
                        header: "quantity",
                        cell: {
                            "template": "<nlg-number-input name=\"organize.quantity\" ng-model=\"record.quantity\" nlg-decimals=\"0\" min='1' max='{{record.maxQuantity}}'></nlg-number-input>"
                        }
                    }, {
                        width: "10%",
                        cellName: "organize.maxQuantity",
                        header: "organize.maxQuantity",
                        cell: "{{record.maxQuantity}}"
                    }],
                    records: organizeOperations.froms
                }

                $scope.canMoveDown = function (selected, down) {
                    if (angular.isUndefined(selected) || selected.length === 0 || angular.isUndefined(down)) {
                        return false
                    }
                    let uniqueSelected = selected[0]
                    return arrays.disjoint(uniqueSelected.loadedDeliveryUnits, down.unloadedDeliveryUnits)
                }

                $scope.canMoveUp = function (selected, up) {
                    if (angular.isUndefined(selected) || selected.length === 0 || angular.isUndefined(up)) {
                        return false
                    }
                    let uniqueSelected = selected[0]
                    return arrays.disjoint(uniqueSelected.unloadedDeliveryUnits, up.loadedDeliveryUnits)
                }

                $scope.canProceed = function () {
                    return $scope.config.routingConfig
                }

                $scope.proceed = function () {
                    $scope.organizeOperations.froms = removeZeroQuantityFroms($scope.organizeOperations.froms)
                    $scope.$close(angular.merge({
                        operations: $scope.organizeOperations
                    }, $scope.config))
                }

                function removeZeroQuantityFroms(froms){
                    return _.filter(froms, function(from){
                        return from.quantity > 0
                    })
                }
            }]
        }).result
    }
}])