define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckerApplicationErrorCountReason.html"
], function (nlgHealthCheckerModule, template) {
    "use strict";

    return nlgHealthCheckerModule.directive("nlgHealthCheckerApplicationErrorCountReason", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                reason: "<"
            }
        };
    }]);
});
