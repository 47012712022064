import translateModule from "./r2TranslateModule"

export default translateModule.service("translateService", ["$translate", "tmhDynamicLocale", ($translate, tmhDynamicLocale)=>{
    return {
        /**
         * Retorna uma promessa que será resolvida quando o idioma for alterado.
         * @param language {string} novo idioma
         */
        changeLanguage(language){
            return $translate.use(language)
        },
        /**
         * Retorna o código do idioma atual com a variante.
         * @returns {string}
         */
        getCurrentLanguage(){
            return tmhDynamicLocale.get()
        }
    }
}])