define(["./nlgTableModule"], function (nlgTableModule) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:nlgTable
     * @element table
     * @restrict A
     * @description
     * Componente de tabela.
     *
     * @param {Object[]=} nlgTable objeto contendo configurações de comportamento do componente.
     * e.g.: Parâmetros de ordenação, paginação. A estrutura deste objeto é definida em
     * {@link directives.directive.nlgTable:tableOptions tableOptions}.
     */
    return nlgTableModule.directive("nlgTable", function () {
        return {
            restrict: "A",
            scope: {
                tableOptions: "=nlgTable"
            },
            priority: 100,
            controller: ["$scope", function nlgTableController($scope) {
                /**
                 * @ngdoc object
                 * @name directives.directive.nlgTable:tableOptions
                 * @description Objeto responsável por armazenar configurações de
                 * customizações do comportamento do componente de tabela
                 */
                this.tableOptions = $scope.tableOptions || {};
                this.tableApi = $scope.tableApi = {};
            }],
            link: {
                pre: function (scope, element) {
                    if (!element.is("table")) {
                        throw new Error("nlgTable must be used in a table element.");
                    }
                },
                post: function (scope, element) {
                    /**
                     * @ngdoc function
                     * @name tableOptions.onRegisterApi
                     * @propertyOf directives.directive.nlgTable:tableOptions
                     * @description `Callback` de registro da API da tabela. É invocada na
                     * compilação da diretiva, recebendo como parâmetro o objeto contendo a
                     * API pública da tabela.
                     */
                    if (scope.tableOptions.onRegisterApi) {
                        scope.tableOptions.onRegisterApi(scope.tableApi);
                    }

                    element.addClass("nlgTable");
                }
            }
        };
    });
});