/**
 * @ngdoc directive
 * @name directives.directive:nlgTagInput
 * @description
 * Componente para criação de tags.
 *
 * Quando o usuário pressiona `enter` ou `vírgula`, uma nova tag é adicionada ao modelo e exibida na tela.
 * É possível remover tags usando a tecla `backspace` ou clicando no "X" exibido em cada tag.
 * Quando o auto-complete é utilizado (atravéz dos parâmetros "source" ou "getTags"), apenas valores disponíveis no
 * auto-complete podem ser adicionados.
 *
 * Tags repetidas não podem ser inseridas.
 *
 * @param {Object[]=} ngModel Faz a ligação de uma variável de escopo com as tags exibidas.
 * @param {Object[]=} source Fonte de dados simples, utilizada pelo auto-complete.
 * @param {string=} placeholder Campo "placeholder" do campo de texto.
 * @param {Function(Object)=} formatTag Função que recebe uma tag e deve retornar uma representação em String da tag. Pode receber undefined ou null.
 * @param {Function(String):Object[]|Promisse=} getTags Função que recebe a String fornecida pelo usuário e retorna valores permitidos de tags.
 *      O campo source é ignorado quando esta função é fornecida. Esta função pode retornar uma promessa.
 *      É possível utilizar o parâmetro especial `viewData` na chamada da função, o qual contém o texto digitado pelo usuário.
 * @param {string=} delimiters String de delimitadores. Quando um deles for digitado, uma nova tag é criada.
 *      **Atenção:** Espaços vazios são removidos das extremidades da string. Portando, caso espaço vazio seja um delimitador, utilizar `\s`. *Padrão:* `,\n`.
 * @param {number=} typeAheadMinLength mínimo comprimento do texto digitado para início do typeahead. *Padrão:* 3.
 * @param {number=} typeAheadWaitMs atraso em milissegundos para início do typeahead. *Padrão:* 200ms.
 * @param {Function(object, object)=} comparator Função de comparação para ordenar os valores permitidos de tags.
 *
 * @example
 * Uso básico
 * ==========
 *
 * Irá exibir um campo de texto, que quando `enter` for pressionado, são criadas tags.
 * <example module="FrontEndWeb">
 *     <file name="index.html">
 *       <nlg-tag-input></nlg-tag-input>
 *     </file>
 * </example>
 *
 * Utilizando model.
 * ==========
 *
 * Inicializa o campo de texto com 3 tags, e ao adicionar novas tags, elas são automaticamente adicionadas no modelo.
 * <example module="FrontEndWeb">
 *     <file name="index.html">
 *        <div ng-init="tags = ['tag1', 'tag2', 'tag3']">
 *            <nlg-tag-input ng-model="tags"></nlg-tag-input>
 *            <ul>
 *                <li ng-repeat="tag in tags">{{tag}}</li>
 *            </ul>
 *        </div>
 *     </file>
 * </example>
 *
 * Utilizando auto-complete.
 * ==========
 *
 * Ao digitar, as tags disponíveis são exibidas ao usuário.
 * <example module="FrontEndWeb">
 *     <file name="index.html">
 *        <div ng-init="possibleValues = ['tag1', 'tag2', 'tag3']">
 *            <nlg-tag-input source="possibleValues"></nlg-tag-input>
 *        </div>
 *     </file>
 * </example>
 * */
define([
    "./TagInputDirectiveFactory",
    "./TagInputController"
], function nlgTagInput(directiveFactory, nlgTagInputController) {
    "use strict";

    // cria a tag html para o ie8
    document.createElement("nlg-tag-input");

    return directiveFactory.createDirective("nlgTagInput", nlgTagInputController.multiValueController);
});