define([
    "./../nlgLGPDModule",
], function (nlgLGPDModule) {
    "use strict";

    return nlgLGPDModule.service("nlgLGPDSQLService", [
        "$http",
        "loadingService",
        "lgpdApiByArtifact",
        "nlgLGPD",
        function ($http, loadingService, lgpdApiByArtifact, nlgLGPD) {
            var filterService = nlgLGPD.createLGPDSQLFilterServicePath();
            var availableServicePaths = nlgLGPD.getLGPDServiceNames();

            function filterRecords(template, firstResult, maxResults, artifactName, entityName) {
                var params = {params: {firstResult: firstResult, maxResults: maxResults, entityName: entityName}};
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).sqlFilterRecords(template, params));
            }

            function save(lgpdSQL) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(lgpdSQL.artifactName).sqlSave(lgpdSQL));
            }

            function deleteEntity(id, artifactName) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).sqlDeleteById(id));
            }

            function getEntities() {
                return availableServicePaths.map(function (item) {
                    if (typeof lgpdApiByArtifact.getApiOfArtifact(item.artifactName).sqlGetEntities === "function") {
                        return lgpdApiByArtifact.getApiOfArtifact(item.artifactName).sqlGetEntities()
                            .then(function (response) {
                                return {
                                    key: item,
                                    value: response.data
                                };
                            }).catch(function () {
                                return {
                                    key: item,
                                    value: []
                                };
                            });
                    }
                    return {
                        key: item,
                        value: []
                    };
                });
            }

            function getDefinitions() {
                return availableServicePaths.map(function (item) {
                    if (typeof lgpdApiByArtifact.getApiOfArtifact(item.artifactName).sqlGetDefinitions === "function") {
                        return lgpdApiByArtifact.getApiOfArtifact(item.artifactName).sqlGetDefinitions()
                            .then(function (response) {
                                return {
                                    key: item,
                                    value: response.data
                                };
                            }).catch(function () {
                                return {
                                    key: item,
                                    value: []
                                };
                            });
                    }
                    return {
                        key: item,
                        value: []
                    };
                });
            }

            function getScript(entityName, artifactName) {
                var param = {params: {sourceId: entityName}};
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).scriptSQLGetBySourceId(param));
            }

            function findVersionsOf(id, artifactName) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).sqlFindVersionOf(id));
            }

            function findVersion(id, version, artifactName) {
                return loadingService(lgpdApiByArtifact.getApiOfArtifact(artifactName).sqlFindVersion(id, version));
            }

            function filterServiceName() {
                return filterService;
            }

            return {
                filterRecords: filterRecords,
                save: save,
                deleteEntity: deleteEntity,
                getEntities: getEntities,
                getDefinitions: getDefinitions,
                getScript: getScript,
                findVersionsOf: findVersionsOf,
                findVersion: findVersion,
                filterServiceName: filterServiceName,
            };
        }]);
});
