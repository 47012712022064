define(["./commonScriptModule"], function (module) {
    "use strict";

    return module.service("scriptService", ["$http", "scriptUrls", function ($http, scriptUrls) {

        function validate(script) {
            return $http.post(scriptUrls.scriptValidator, script);
        }

        function getScriptTypes() {
            return $http.get(scriptUrls.scriptTypes);
        }

        function getRequiredGenericParams(scriptId) {
            return $http.get(scriptUrls.requiredGenericParams, {
                params: {
                    scriptId: scriptId
                }
            });
        }

        return {
            validate: validate,
            getScriptTypes: getScriptTypes,
            getRequiredGenericParams: getRequiredGenericParams
        };
    }]);
});