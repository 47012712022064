define([
    "./clientViewConfigurationModule",
    "text!./clientViewConfigurationTemplate.html",
], function (module, clientViewConfigurationTemplate) {
    "use strict";
    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.clientViewConfiguration", {
                url: "/clientViewConfiguration",
                controller: "clientViewConfigurationController",
                template: clientViewConfigurationTemplate,
                data: {
                    group: "group.monitoring.config.clientViewConfiguration",
                    pageTitle: "{{'title.monitoring.clientViewConfiguration' | translate:$stateParams}}"
                },
                resolve: {
                    configuration: ["$stateParams", "clientViewConfigurationService", function ($stateParams, clientViewConfigurationService) {
                        return clientViewConfigurationService.find();
                    }]
                },
                params: {
                    reload: "true"
                }
            })
    }]);
});
