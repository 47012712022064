/**
 * @ngdoc directive
 * @name directives.directive:nlgSingleTagInput
 * @description
 * Componente para seleção de uma única tag.
 * Possui as mesmas opções de nlgTagInput.
 * @see nlgTagInput
 */
define([
        "./TagInputDirectiveFactory",
        "./TagInputController"
    ],
    function nlgSingleTagInput(directiveFactory, nlgTagInputController) {
        "use strict";

        // cria a tag html para o ie8
        document.createElement("nlg-single-tag-input");

        return directiveFactory.createDirective("nlgSingleTagInput", nlgTagInputController.singleValueController);
    });