define([
    "./nlgTagInputModule",
    "angular"
], function (module, angular) {

    module.provider("nlgTagInputIconMap", [function () {

        this.$get = [function () {
            return {
                iconMap: iconMap,
                iconMapBig: iconMapBig,
                addIcons: addIcons
            };
        }];

        var enumToIcon = {};

        this.config = function(defaultIconPath) {
            enumToIcon["default"] = defaultIconPath;
        };

        var addIcons = function (icons) {
            enumToIcon = Object.assign({}, icons, iconMap());
        };

        var iconMap = function () {
            return enumToIcon;
        };

        var iconMapBig = function () {
            var enumToIconBig = angular.copy(enumToIcon);
            Object.keys(enumToIconBig).forEach(function(key) {
                enumToIconBig[key] = enumToIconBig[key].replace(".png","_big.png");
            });
            return enumToIconBig;
        };
    }]);
});

