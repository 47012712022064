define([
    "./trackingMobileModule",
    "./trackingMobileRouter",
    "./legacydriver/LegacyDriverEditionController",
    "./driver/trackingMobileService",
    "./device/deviceConfigurationService",
    "./mobileSettings/MobileSettingsController",
    "./device/DeviceSearchController",
    "./device/DeviceEditionController"
], function () {
});