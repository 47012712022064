import * as $ from "jquery";
import {integrationDocumentationModule} from "./integrationDocumentationModule";
import {element} from "angular";

interface WsdlNumericRestriction {
    type: "minLength" | "maxLength" | "minInclusive" | "minExclusive" | "maxInclusive" | "maxExclusive"
    value: number
}

interface WsdlChoiceRestriction {
    type: "choice"
    values: string[]
}

type WsdlRestriction = WsdlChoiceRestriction | WsdlNumericRestriction

export interface WsdlType {
    type: string
    elements: {
        name: string
        type: string
        minOccurs: number
        maxOccurs: number | "unbounded"
        nillable: boolean
    }[]
    base?: string,
    restrictions?: WsdlRestriction[]
}

export interface WsdlMessage {
    name: string
    parts: {
        type: string
    }[]
}

export interface WsdlOperation {
    name: string
    inputs: {
        type: string
    }[]
    outputs: {
        type: string
    }[]
}

export interface WsdlDefinition {
    types: WsdlType[]
    includeTypes: string[]
    messages: WsdlMessage[]
    operations: WsdlOperation[]
}

function getElementType(element: Element) {
    const defaultType = element.getAttribute("type") || element.getAttribute("name") || element.querySelector("[base]")?.getAttribute("base");
    if (!defaultType) {
        return getElementName(element);
    }
    if (defaultType.includes(":")) {
        return defaultType
    }
    return `${getElementNamespace(element)}:${defaultType}`
}

function getElementName(element: Element) {
    const name = element.getAttribute("name")!
    if (name.includes(":")) {
        return name
    }
    return `${getElementNamespace(element)}:${name}`
}

function getElementNamespace(element: Element) {
    const targetUri = $(element).parents("[targetNamespace]")?.attr("targetNamespace") || ""
    const ns = element.lookupPrefix(targetUri) || element.getRootNode().lookupPrefix(targetUri)
    if (ns) {
        return ns
    }
    throw new Error("undefined element namespace " + element.outerHTML)
}

export class IntegrationDocumentationParser {
    private domParser = new DOMParser();

    parseWsdl(content: string): WsdlDefinition {
        const result: WsdlDefinition = {
            types: [],
            includeTypes: [],
            messages: [],
            operations: []
        }

        const document = this.domParser.parseFromString(content, "application/xml");

        toArray(document.querySelectorAll("schema > *")).forEach(
            function arr(type: Element): string[] {
                if (type.localName === "choice") {
                    return toArray(type.children || []).flatMap(arr).sort();
                }
                const name = type.getAttribute("name");
                if (!name) return []
                const typdeDefinition: WsdlType = {
                    type: getElementType(type),
                    elements: toArray(type.querySelector("sequence")?.children || [])
                        .flatMap((element: Element) => element.localName === "choice" ? toArray(element.children) : [element])
                        .map((element: Element) => {
                            if (isComplex(element)) arr(element)
                            return {
                                name: element.getAttribute("name") || "",
                                type: getElementType(element),
                                minOccurs: mapOptional(element.getAttribute("minOccurs"), v => parseInt(v, 10)) ?? 1,
                                maxOccurs: mapOptional(element.getAttribute("maxOccurs"), v => parseInt(v, 10) || "unbounded") ?? 1,
                                nillable: mapOptional(element.getAttribute("nillable"), v => v !== "false") ?? false
                            }
                        })
                };
                addRestrictions(type, typdeDefinition)
                result.types.push(typdeDefinition)
                return [name]
            })

        toArray(document.querySelectorAll("definitions > message")).forEach((messageElement: Element) => {
            result.messages.push({
                name: getElementName(messageElement),
                parts: toArray(messageElement.querySelectorAll("[element]")).map((part: Element) => ({
                    type: part.getAttribute("element")!
                }))
            })
        })

        toArray(document.querySelectorAll("definitions > portType > operation")).forEach((messageElement: Element) => {
            result.operations.push({
                name: getElementName(messageElement),
                inputs: toArray(messageElement.querySelectorAll("input")).map((part: Element) => ({
                    type: part.getAttribute("message")!
                })),
                outputs: toArray(messageElement.querySelectorAll("output")).map((part: Element) => ({
                    type: part.getAttribute("message")!
                }))
            })
        })

        result.includeTypes = toArray(document.querySelectorAll("definitions > types > schema include")).map((element: Element) => {
            return element.getAttribute("schemaLocation")!
        }).filter(el => !!el)

        return result;
    }
}

integrationDocumentationModule.service("integrationDocumentationParser", IntegrationDocumentationParser)

function addRestrictions(element: Element, typeDefinition: WsdlType) {
    const choices: string[] = []
    const restrictions = toArray(element.querySelectorAll("restriction > *"));
    if (!restrictions.length) {
        return
    }
    typeDefinition.base = element.querySelector("restriction")?.getAttribute("base")!
    typeDefinition.restrictions = []
    restrictions.forEach((restriction) => {
        if (restriction.localName === "enumeration" && restriction.hasAttribute("value")) {
            choices.push(restriction.getAttribute("value")!)
        } else if (
            (restriction.localName === "minLength"
                || restriction.localName === "maxLength"
                || restriction.localName === "minExclusive"
                || restriction.localName === "minInclusive"
                || restriction.localName === "maxExclusive"
                || restriction.localName === "maxInclusive"
            ) && restriction.hasAttribute("value")) {

            typeDefinition.restrictions!.push({
                type: restriction.localName,
                value: parseInt(restriction.getAttribute("value")!, 10)
            })
        }
    })

    if (choices.length) typeDefinition.restrictions!.push({type: "choice", values: choices})
}

function mapOptional<T, R>(value: T | undefined | null, func: (v: T) => R) {
    if (value == null || undefined) return
    return func(value)
}

function toArray<T>(arr: ArrayLike<T>): T[] {
    return [].slice.call(arr)
}

function isComplex(type: Element) {
    return type.querySelector("element") != null
}