import {integrationDocumentationModule} from "./integrationDocumentationModule"
import {IStateParamsService, IStateProvider} from "angular-ui-router";
import {WsdlIdentifier} from "./integrationDocumentationService";

integrationDocumentationModule.config(($stateProvider: IStateProvider) => {
    $stateProvider
        .state("r2.integrationDocumentation", {
            data: {
                pageTitle: "{{'title.configuration.server' | translate}}",
                group: "group.r2.configuration.advanced"
            },
            url: "/integrationDocumentation",
            template: `<nlg-integration-documentation></nlg-integration-documentation>`,
        })
        .state("r2.integrationDocumentationDetails", {
            data: {
                pageTitle: "{{'title.configuration.server' | translate}}",
                group: "group.r2.configuration.advanced"
            },
            url: "/integrationDocumentation/{service}/{wsdlName}",
            template: `<nlg-integration-documentation-details wsdl-identifier="$resolve.identifier"></nlg-integration-documentation-details>`,
            resolve: {
                identifier($stateParams: IStateParamsService): WsdlIdentifier {
                    return {
                        wsdlName: $stateParams["wsdlName"],
                        service: $stateParams["service"]
                    }
                }
            }
        })
})