define([
    "angular",
    "./chartingModule",
    "c3",
    "d3",
], function (angular, chartingModule, c3, d3) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:barChart
     * @description
     * Diretiva que exibe uma gráfico de barras
     *
     * @param {object[]=} barChart dados a serem representados no gráfico.
     * @param {string@?} width largura do gráfico em px.
     * @param {string@?} height altura do gráfico em px.
     * @param {string=?} horizontal flag para alterar a orientção do gráfico, o valor default é false.
     * @param {string=?} translateLabels flag para traduzir o label(bundle) do chart
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div bar-chart="barChartInput" horizontal="true"></div>
     *            <div bar-chart="barChartInput" horizontal="false"></div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.barChartInput = (function () {
     *                  return {
     *                      configs: {
     *                          chartTitle: "The Chart Title",
     *                          type: "bar"
     *                      },
     *                      data: [
     *                          {
     *                              label: "data1",
     *                              value: 123
     *                          },
     *                          {
     *                              label: "data2",
     *                              value: 321
     *                          },
     *                          {
     *                              label: "data3",
     *                              value: 345
     *                          },
     *                          {
     *                              label: "data4",
     *                              value: 345
     *                          }
     *                      ]
     *                  }
     *              }());
     *         });
     *     </file>
     * </example>
     * */
    chartingModule.directive("barChart", ["$translate", "chartingService", "chartLocale", function ($translate, chartingService, chartLocale) {
        return {
            restrict: "A",
            scope: {
                context: "=barChart",
                width: "@?",
                height: "@?",
                horizontal: "=?",
                isAlreadySorted: "<"
            },
            template: "<div><h3 class='chart-title' translate></h3><div class='bar-chart'></div></div>",
            link: function ($scope, $element) {

                $element.find("h3.chart-title").text($scope.context.configs.chartTitle);

                var properties = $scope.context.configs.properties || $scope.context.configs.chartViewConfig.properties;

                var translate = properties.translateLabels === "true";
                var translateLabelFunction = translate ?
                    function (label) {
                        return $translate.instant(label);
                    }
                    : function (label) {
                        return label;
                    };

                var chartConfig = {
                    bindto: $element.find(".bar-chart")[0],
                    data: {
                        type: "bar",
                        x: "x",
                        columns: [],
                        labels: {
                            format: chartLocale.createFormatter(properties.valueType)
                        },
                        empty: {
                            label: {
                                text: $translate.instant("charting.notApplicable")
                            }
                        }
                    },
                    axis: {
                        rotated: $scope.horizontal ? $scope.horizontal : false,
                        x: {
                            type: "category",
                            tick: {
                                multiline: false
                            }
                        },
                        y: {
                            tick: {
                                format: function (x) {
                                    if (angular.isDefined(properties.showOnlyIntegerOnY) && properties.showOnlyIntegerOnY === "true") {
                                        if (x !== Math.floor(x)) {
                                            d3.selectAll(".c3-axis-y g.tick").filter(function () {
                                                var text = d3.select(this).select("text").text();
                                                return +text === x;
                                            }).style("opacity", 0);
                                            return "";
                                        }
                                    }
                                    return chartLocale.formatNumberData(properties.valueType, x);
                                }
                            }
                        }
                    },
                    legend: {
                        show: false
                    },
                    bar: {
                        width: {
                            ratio: 0.5
                        }
                    }
                };

                var size = getSize();
                if (size !== {}) {
                    chartConfig.size = size;
                }

                if (properties.globalColor) {
                    chartConfig.data.color = function () {
                        return properties.globalColor;
                    };
                }

                var chart = c3.generate(chartConfig);

                $scope.$watch(function () {
                    return $scope.context.data;
                }, function (newData) {
                    chart.load(processData(newData));
                });

                var subtitle = $translate.instant(properties.subtitle || "values");

                function processData(dataToProcess) {
                    var processedData = {
                        columns: []
                    };
                    if (dataToProcess.length === 0) {
                        processedData.unload = ["x", subtitle];
                    } else {
                        var categories = ["x"];
                        var values = [subtitle];
                        sortData(dataToProcess);
                        var ranked = chartingService.ranking(dataToProcess, properties.rank);
                        ranked.forEach(function (data) {
                            categories.push(translateLabelFunction(data.label));
                            values.push(data.value);
                        });
                        processedData.columns.push(categories, values);
                    }
                    return processedData;
                }

                function sortData(data) {
                    if (properties.orderBy) {
                        data.sort(function (a, b) {
                            return a[properties.orderBy] - b[properties.orderBy];
                        });
                    }else if (!$scope.isAlreadySorted) {
                        data.sort(function (a, b) {
                            return b.value - a.value;
                        });
                    }
                }

                function getSize() {
                    var width = parseInt($scope.width);
                    var height = parseInt($scope.height);
                    var size = {};
                    if (width) size.width = width;
                    if (height) size.height = height;
                    return size;
                }
            }
        };
    }]);
});