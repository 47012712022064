define(["./nlgPortletModule",
    "text!./portletBody.html"
], function (nlgPortletModule, portletBodyTemplate) {
    "use strict";

    return nlgPortletModule.directive("nlgPortletBody", function () {
        return {
            require: "^nlgPortlet",
            restrict: "A",
            scope: true,
            template: portletBodyTemplate,
            replace: true,
            transclude: true,
            link: function (scope, elem, attrs, parentController) {
                scope.isCollapsed = parentController.isCollapsed;
            }
        };
    });
});


