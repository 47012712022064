import sessionRecordsModule from "./sessionRecordsModule"
import sessionRecordsModalView from "./sessionRecordsModalView.html"
import server from "../server"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"

sessionRecordsModule.service("sessionRecordsModal", ["$modal", ($modal) => {
    return (sessionId) => {
        return $modal.open({
            template: sessionRecordsModalView,
            size: "xl",
            controller: ["$scope", "$q", "sessionRecordsModalService", ($scope, $q, sessionRecordsModalService) => {

                const paginationApi = $q.defer()
                let filterPatch = []

                $scope.filterServiceUrl = server.getRelativeUrl("search.payload-record")

                $scope.tableOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return sessionRecordsModalService.filter("payload-record", filterPatch, firstResult, maxResults, sessionId)
                                .then(function (response) {
                                    response.result = response.result[0].children
                                    mapChildrenProperties(response.result)
                                    return response
                                })
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi.resolve(api)
                    }
                }

                function mapChildrenProperties(children) {
                    arrays.each(children, function (child) {
                        var map = {}
                        arrays.each(child.properties, function (property) {
                            map[property.messageKey] = property
                        })
                        child.mappedProperties = map
                    })
                }

                $scope.search = (patch) => {
                    filterPatch = patch
                    return paginationApi.promise.then((api) => api.seekPage(1))
                }
            }]
        })
    }
}])
