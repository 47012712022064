define(["../temperatureRangeConfigModule",
        "json!../../monitoring.json",
        "json!../../tdnByEntity.json",],
    function (temperatureRangeConfigModule, monitoring, tdnByEntity) {
        "use strict";

        return temperatureRangeConfigModule.controller("TemperatureRangeConfigSearchController", [
            "$scope",
            "$q",
            "monitoringService",
            "reload",
            "loadingService",
            "remoteExceptionHandler",
            "messagesModal",
            "tdnHelp",
            function ($scope, $q, monitoringService, reload, loadingService, remoteExceptionHandler, messagesModal, tdnHelp ) {
                const DEFAULT_CONFIG = "Default";

                $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["temperature-range-config"];


                $scope.serviceName = monitoring.services.temperatureRangeConfigFilter;
                var template, paginationApi, paginationApiDefer = $q.defer();
                var filterApi, filterApiDefer = $q.defer();
                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    paginationApi.seekPage(1);
                };

                $scope.onRegisterFilterApi = function (api) {
                    filterApi = api;
                    filterApiDefer.resolve();
                };

                $q.all([paginationApiDefer.promise, filterApiDefer.promise]).then(function () {
                    filterApi.onChangeService(function (service) {
                        if (service === $scope.serviceName) {
                            if (reload) {
                                filterApi.reload();
                            } else {
                                filterApi.reset();
                            }
                        }
                    });
                });

                $scope.tableOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(monitoringService.filterTemperatureRangeConfig("/filter", template, firstResult, maxResults))
                                .then(function (result) {
                                    return result;
                                }).catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                        paginationApiDefer.resolve();
                    }
                };

                $scope.delete = function (configId) {
                    return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                        .then(function () {
                            return loadingService(monitoringService.deleteTemperatureRangeConfig(configId))
                                .catch(remoteExceptionHandler());
                        }).then(function () {
                            return messagesModal("dialog.success", {
                                keyBundle: "entity.validator.successDeleteMessage"
                            });
                        }).finally(function () {
                            filterApi.reload();
                        });
                };

                $scope.isDefault = function (sourceId) {
                    return sourceId === DEFAULT_CONFIG;
                }
            }]);
    });