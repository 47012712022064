define([
    "./clientViewConfigurationModule",
    "json!./config/clientViewConfiguration.json",
    "json!../mbr.json"
], function (clientViewConfigurationModule, clientViewConfiguration, mbr) {
    "use strict";

    clientViewConfigurationModule.service("clientViewConfigurationService", [
        "$http",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        function ($http, loadingService, remoteExceptionHandler, messagesModal) {
            var self = this;

            self.save = function (configuration) {
                return loadingService($http.post(servicePath("save"), configuration))
                    .catch(remoteExceptionHandler()).then(() => false)
                    .then(function () {
                        return messagesModal("dialog.success", {
                            keyBundle: "client.view.configuration.save.success"
                        }).then(() => true);
                    });
            };

            self.find = function () {
                return loadingService($http.get(servicePath("find")))
                    .then(getData);
            };

            function servicePath(serviceId) {
                return clientViewConfiguration.baseUrl + clientViewConfiguration.services[serviceId];
            }

            function getData(response) {
                return response.data;
            }


            self.validateKey = function  (key){
                return loadingService($http.get(
                         getServiceValidateTokenPath("existCompanyKey"), {headers: {token: key}}
                    ).then(function (response){
                    return messagesModal(getDialogMessageModalValidateKey(response.data), {
                            keyBundle: getBundleValidateKey(response.data)
                        }).then(() => true);
                }));
            }


            function getServiceValidateTokenPath(serviceId){
                return mbr.baseUrl + mbr.services[serviceId]
            }

            function getDialogMessageModalValidateKey(response) {
                if (response){
                    return "dialog.success";
                }
                return "dialog.error";
            }

            function getBundleValidateKey(response){
                if (response){
                    return "monitoring.clientViewConfiguration.validate.key.success"
                }
                return "monitoring.clientViewConfiguration.validate.key.error"
            }

            return self;
        }]);
});