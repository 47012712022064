import analysisMapModule from "../analysisMapModule"
import manualOperationToTripTemplate from "./manualOperationToTripModal.html"

analysisMapModule.service("manualOperationToTripModal", ["$modal", function ($modal) {
    return function (filteredTrips) {
        return $modal.open({
            template: manualOperationToTripTemplate,
            size: "lg",
            controller: ["$scope", "$modalInstance", function ($scope, $modalInstance) {
                $scope.filteredTrips = filteredTrips
                $scope.model = {
                    selectedTripNode: null
                }

                $scope.chooseTrip = function (tripNode) {
                    $scope.model.selectedTripNode = tripNode
                }

                $scope.isActive = function (tripNode) {
                    return $scope.model.selectedTripNode === tripNode
                }

                $scope.submitToTrip = function () {
                    $modalInstance.close($scope.model)
                }

            }]
        })
    }
}])