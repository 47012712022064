import spreadsheetModule from "./spreadsheetModule"
import spreadsheetDeletionModal from "./spreadsheetDeletionModal.html"

spreadsheetModule.service("spreadsheetDeletionModal", ["spreadsheetService", "$modal", "messagesModal", function (spreadsheetService, $modal, messagesModal) {
    this.show = (entityType) => {
        return $modal.open({
            template: spreadsheetDeletionModal,
            controller: ["$scope", ($scope) => {
                $scope.entityType = entityType.toUpperCase()
                $scope.deleteSpreadsheet = () => {
                    $scope.$close()
                    spreadsheetService.newSpreadsheetImport("DELETE_" + $scope.entityType, $scope.model.file)
                        .then($scope.$close)
                        .then(() => messagesModal("dialog.success", "entity.validator.successMessage"))
                        .catch((e) => {
                            const messages = e.messages
                            if (!messages || messages.length === 0) {
                                messagesModal("dialog.error", "import.exception.invalidSpreadsheet")
                                return
                            }
                            return messagesModal("dialog.error", messages)
                        })
                }
                $scope.exportTemplate = () => {
                    spreadsheetService.generateModel("DELETE_" + $scope.entityType)
                }
            }]
        }).result
    }
}])