import productCategoryModule from "./productCategoryModule"

productCategoryModule.controller("productCategoryController", ["$scope", "sourceEntity", "entity", "productCategoryService", "entityEditionService", ($scope, sourceEntity, entity, productCategoryService, entityEditionService)=> {
    const updateService = entityEditionService.crudHelper("product-category", sourceEntity)
    $scope.entity = entity

    productCategoryService.findIncompatibleCandidates(entity.id).then((productCategories) => {
        $scope.productCategories = productCategories
    })

    $scope.save = updateService.save.bind(updateService, entity)

    $scope.saveAndContinue = updateService.save.bind(updateService, entity, true)
}])