import jobModule from "./jobModule"
import server from "../server"

jobModule.service("jobService", ["$http", "downloadBinary", function searchService($http, downloadBinary) {

    this.filter = (entityType, template, firstResult, maxResults) => {
        return $http.post(server.getServiceUrl("search." + entityType) + "presentation-tree", template, {
            params: {
                firstResult,
                maxResults
            }
        }).then((response) => response.data)
    }

    this.showViolations = (jobId) => {
        return $http.get(server.getServiceUrl("job-violations") + jobId)
            .then((response) => response.data)
    }

    this.canDownloadJob = (jobId) => {
        return $http.get(server.getServiceUrl("can-download-job") + jobId)
            .then((response) => response.data)
    }

    this.download = (jobId) => {
        downloadBinary.get(server.getServiceUrl("download-job") + jobId, jobId + ".job")
    }

    this.cancelJob = (jobId) => {
        return $http.post(server.getServiceUrl("cancel-job") + jobId)
    }
}])
