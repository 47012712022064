define([
    "./temperatureRangeConfigModule",
    "text!./edition/TemperatureRangeConfigEditionView.html",
    "text!./search/TemperatureRangeConfigSearchView.html",
], function (module, temperatureRangeConfigEditionTemplate, temperatureRangeConfigSearchTemplate) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.temperatureRangeConfigEdition", {
                url: "/temperatureRangeConfig/:id",
                controller: "TemperatureRangeConfigEditionController",
                template: temperatureRangeConfigEditionTemplate,
                data: {
                    group: "group.monitoring.temperatureRangeConfig",
                    pageTitle: "{{'title.monitoring.temperatureRangeConfig' | translate:$stateParams}}"
                },
                resolve: {
                    temperatureRangeConfig: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return $stateParams.id
                            ? monitoringService.getTemperatureRangeConfiguration($stateParams.id)
                            : null;
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.temperatureRangeConfigCopy", {
                url: "/temperatureRangeConfig/copy/:id",
                controller: "TemperatureRangeConfigEditionController",
                template: temperatureRangeConfigEditionTemplate,
                data: {
                    group: "group.monitoring.temperatureRangeConfig",
                    pageTitle: "{{'title.monitoring.temperatureRangeConfig' | translate:$stateParams}}"
                },
                resolve: {
                    temperatureRangeConfig: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.copyTemperatureRangeConfig($stateParams.id);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.temperatureRangeConfigSearch", {
                url: "/temperatureRangeConfig?reload",
                controller: "TemperatureRangeConfigSearchController",
                template: temperatureRangeConfigSearchTemplate,
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.monitoring.temperatureRangeConfig",
                    pageTitle: "{{'title.monitoring.temperatureRangeConfigSearch' | translate:$stateParams}}"
                }
            })
    }]);
});
