define([
    "./occurrenceStatusModule"
], function (occurrenceStatusModule) {
    "use strict";

    occurrenceStatusModule.service("occurrenceStatusService", [
        "mbrService",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        function (mbrService, loadingService, remoteExceptionHandler, messagesModal) {
            var self = this;

            self.deleteOccurrenceStatus = function (id) {
                return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                    .then(function () {
                        return loadingService(mbrService.deleteOccurrenceStatus(id))
                            .catch(remoteExceptionHandler());
                    }).then(function () {
                        return messagesModal("dialog.success", {
                            keyBundle: "entityFilter.delete.ok"
                        });
                    });
            };

            self.saveOccurrenceStatus = function (entity) {
                return loadingService(mbrService.saveOccurrenceStatus(entity))
                    .catch(remoteExceptionHandler())
                    .then(function (id) {
                        return messagesModal("dialog.success", {
                            keyBundle: "monitoring.occurrence.status.save.success"
                        }).then(function () {
                            return id
                        });
                    });
            };

            return self;
        }])
});