define([
    "./nlgTagInputModule",
    "./nlgTagInputDirective",
    "./nlgSingleTagInputDirective",
    "./TagInputController",
    "./TagInputDirectiveFactory",
    "./nlgTagInputLinkServiceProvider",
    "./nlgTagInputGetIcon",
    "./nlgTagInputIconMap",
    "./fontUtils"
], function () {
});