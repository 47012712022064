define([
    "angular",
    "angular-translate",
    "angular-bootstrap",
    "../nlgNumberInput/nlgNumberInput"
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name timeModule
     * @description
     * Módulo que possui métodos utilitários para manipular datas, instantes, intervalos, etc...
     * */
    return angular.module("timeModule", [
        "ui.bootstrap",
        "pascalprecht.translate",
        "nlgNumberInputModule"
    ]);
});