define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryEntityFormatTemplate", [function () {
        return function (config) {
            return angular.element("<span>")
                .attr("translate", "")
                .text("{{" + config.model + " | entityFormat}}");
        };
    }]);
});