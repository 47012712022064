import angular from "angular"
import emailModule from "../emailModule"
import emailPreviewTemplate from "./emailPreviewTemplateViewModal.html"
import tdnByEntity from "json!../../tdnByEntity.json"

emailModule.controller("emailTemplateController", ["template", "emailService", "$scope", "loadingService", "remoteExceptionHandler", "messagesModal", "$modal", "$filter", "legendsEmailModal", "tdnHelp",
    (template, emailService, $scope, loadingService, remoteExceptionHandler, messagesModal, $modal, $filter, legendsEmailModal, tdnHelp) => {

        $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["email-template"];
        $scope.entity = template;

        $scope.filterMessageBody = function (template, groupingByDelayRange) {
            var selectedRange = getRangeTime(groupingByDelayRange);
            if (groupingByDelayRange === 0) {
                template = template
                    .replace("{FIM_FAIXA}", "")
            } else {
                template = template
                    .replace("{FIM_FAIXA}", "<b>" + filterHours(selectedRange.end) + "</b>")
            }
            return template
                .replace("{NOME_CLIENTE}", "<b>" + $filter("translate")("monitoring.email.templates.clientName") + "</b>")
                .replace("{INICIO_FAIXA}", "<b>" + filterHours(selectedRange.init) + "</b>")
        };

        $scope.viewSubtitles = function () {
            legendsEmailModal($scope.entity);
        };
        $scope.previewEmail = function () {
            $modal.open({
                template: emailPreviewTemplate,
                scope: $scope
            })
        };
        $scope.removeSignature = function () {
            $scope.entity.signatureTemplateImage = "";
            angular.forEach(
                angular.element("input[type='file']"),
                function (inputElem) {
                    angular.element(inputElem).val(null)
                })
        };
        $scope.save = function () {
            loadingService(emailService.saveTemplate($scope.entity))
                .then((result) => {
                    var messages = emailService.isValidResult(result);
                    if (messages.length < 1) {
                        messagesModal("dialog.success", [{
                            keyBundle: "entity.validator.successMessage",
                            parameters: []
                        }]);
                        return getTemplate()
                    }
                    messagesModal("dialog.error", messages)
                })
                .catch(remoteExceptionHandler())

        };
        $scope.setFile = function (element) {
            if (element.files[0].type !== "image/jpeg" && element.files[0].type !== "image/png") {
                $scope.removeSignature();
                return;
            }
            $scope.entity.signatureTemplateImage = element.files[0];
            var reader = new FileReader();

            reader.onload = function (event) {
                $scope.entity.signatureTemplateImage = event.target.result.replace(/data:(.*?);base64,/, "");
                $scope.$apply()
            };
            reader.readAsDataURL(element.files[0])
        };

        function getTemplate() {
            return loadingService(emailService.getMonitorableEmailTemplate()).then((template) => {
                    $scope.entity = template;
                    getRangeTime($scope.entity)
                }
            )
        }

        function getRangeTime(range) {
            var date = new Date();
            var hours = date.getHours();
            var minutes = new Date().getMinutes();
            if (Math.round(range) === 0) {
                var now = hours + ":" + minutes + "h";
                return {"init": now, "end": now}
            }

            var ranges = createRangeDay(Math.round(range));
            var selectedRange = {};
            for (let i = 0; i < ranges.length; i++) {
                if (hours === ranges[i].init) {
                    selectedRange = ranges[i];
                    break
                }

                if (hours > ranges[i].init && hours < ranges[i].end) {
                    selectedRange = ranges[i];
                    break
                }
            }
            return selectedRange
        }

        function createRangeDay(range) {
            var rangeTimes = 24 / range;
            var ranges = [];
            for (let i = 0; i < rangeTimes; i++) {
                var initRange = i * range;
                ranges.push({"init": initRange, "end": initRange + range});
                if (initRange + range > 24) {
                    ranges.push({"init": initRange, "end": 24})
                }
            }
            return ranges
        }

        function filterHours(hour) {
            if (hour.length === 5 || hour.length === 6) {
                return hour
            }
            if (hour === 24) {
                return "00:00h"
            }
            if (hour.length === 1) {
                return "0" + hour + ":00h"
            }
            return hour + ":00h"
        }
    }]);
