define([
    "./temperatureRangeConfigModule",
    "text!./nlgTemperatureRangeConfig.html",
], function (temperatureRangeConfigModule, template) {
    "use strict";

    return temperatureRangeConfigModule.directive("nlgTemperatureRangeConfig", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                config: "<",
                ngDisabled: "<?"
            },
            controller: ["$scope", "monitoringService", function ($scope, monitoringService) {
                $scope.causesTag = monitoringService.findOccurrenceCauses;

                $scope.updateTopCriticalTemperature = function() {
                    $scope.config.topCriticalRange.minTemperature = $scope.config.topWarningRange.maxTemperature;
                    $scope.config.topCriticalRange.maxTemperature = $scope.config.topWarningRange.maxTemperature;
                };

                $scope.updateTopWarningTemperature = function() {
                    $scope.config.topWarningRange.minTemperature = $scope.config.centerRange.maxTemperature;
                };

                $scope.updateLowerWarningTemperature = function() {
                    $scope.config.lowerWarningRange.maxTemperature = $scope.config.centerRange.minTemperature;
                };

                $scope.updateLowerCriticalTemperature = function() {
                    $scope.config.lowerCriticalRange.minTemperature = $scope.config.lowerWarningRange.minTemperature;
                    $scope.config.lowerCriticalRange.maxTemperature = $scope.config.lowerWarningRange.minTemperature;
                };
            }]
        }
    }]);
});