import productModule from "./productModule"

productModule.controller("productController", ["$scope", "sourceEntity", "entity", "entityEditionService", "autocompleteFactory", ($scope, sourceEntity, entity, entityEditionService, autocompleteFactory) => {
    const updateService = entityEditionService.crudHelper("product", sourceEntity)
    $scope.entity = entity
    $scope.getProductCategories = autocompleteFactory.lazyLoader("productCategory")

    $scope.save = updateService.save.bind(updateService, entity)

    $scope.saveAndContinue = updateService.save.bind(updateService, entity, true)
}])