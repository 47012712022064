define(["./nlgTableDynamicColumnsModule"], function (nlgTableDynamicColumnsModule) {
    "use strict";

    /**
     * @ngdoc directive
     * @name nlgTableDynamicSort.directive:nlgTableDynamicSort
     * @restrict A
     * @element tr
     * @requires directives.directive:nlgTableHeaders
     * @description
     * Adiciona funcionalidade de ordenação em colunas dinâmicas.
     * */
    return nlgTableDynamicColumnsModule.directive("nlgTableDynamicSort", [function () {
        return {
            require: "nlgTableHeaders",
            link: function ($scope, $element, $attrs, nlgTableHeaderController) {
                nlgTableHeaderController.addVisitor(function (element, columnConfig) {
                    if (columnConfig.sortBy) {
                        element.attr("nlg-table-sortable-column", columnConfig.sortBy);
                    }
                });
            }
        };
    }]);

    /**
     * @ngdoc object
     * @name nlgTableDynamicSort.class:ColumnDef
     * @description
     * Opções da tabela que são utilizadas pelas colunas dinâmicas em conjunto com a diretiva de ordenação.
     */

    /**
     * @ngdoc object
     * @name nlgTableDynamicSort.class:ColumnDef.sortBy
     * @propertyOf nlgTableDynamicSort.class:ColumnDef
     * @description
     * String que define qual propriedade será utilizada para fazer a ordenação dos elementos da tabela.
     * Campo opcional que quando não fornecido, desabilita a ordenação a coluna.
     */
});
