define([
    "angular",
    "lodash",
    "../arrays/arrays",
    "./nlgLGPDModule",
    "json!./tdn/tdnByEntity.json",
], function (angular, _, arrays, lgpdModule, tdnByEntity) {
    "use strict";

    return lgpdModule.controller("nlgLGPDAnonymizeController", [
        "$scope",
        "autocompleteFactory",
        "messagesModal",
        "remoteExceptionHandler",
        "$state",
        "$q",
        "loadingService",
        "nlgLGPDService",
        "$translate",
        "tdnHelp",
        function ($scope, autocompleteFactory, messagesModal, remoteExceptionHandler, $state, $q, loadingService, nlgLGPDService, $translate, tdnHelp) {
            var paginationApi;
            var filterServiceTemplate;

            if (tdnByEntity && tdnByEntity[tdnHelp.getContext()] && tdnByEntity[tdnHelp.getContext()]["anonymization"]) {
                $scope.tdn = tdnByEntity[tdnHelp.getContext()]["anonymization"];
            }
            $scope.context = tdnHelp.getContext();
            $scope.model = {
                entityDefinition: null,
                entityField: null,
                fieldTemplate: null,
                lgpdValue: null
            };

            $scope.entitiesNames = getAllDefinitionEntityNames();

            $scope.hideFilter = function (filter) {
                var firstCondition = !filter || !$scope.model || !$scope.model.entityField;
                if (firstCondition) {
                    return true;
                }
                var artifactName = $scope.model.entityDefinition.artifactName;
                return firstCondition && (artifactName !== "cpl" || artifactName !== "r2");
            };

            $scope.showFilterTemplate = function (filter, domain) {
                var firstCondition = !filter || !$scope.model || !$scope.model.entityField;
                if (firstCondition) {
                    return false;
                }
                var artifactName = $scope.model.entityDefinition.artifactName;

                if(artifactName === "monitoring" && domain  === "monitoring"){
                    return true;
                }
                return (artifactName === "userManagement" && domain === "userManagement");
            };

            $scope.search = function (template) {
                filterServiceTemplate = template;
                paginationApi.seekPage(1);
            };

            function getAllDefinitionEntityNames() {
                var resultNames = [];
                nlgLGPDService.getAllDefinitionEntityNames()
                    .forEach(function (allItems) {
                        $q.all(allItems).then(function (item) {
                            item.forEach(function (entry) {
                                entry.value.forEach(function (record) {
                                    var nameEntry = {
                                        entityName: record.entityName,
                                        presentationName: record.presentationName,
                                        servicePath: entry.key.path,
                                        artifactName: entry.key.artifactName,
                                        serviceName: entry.key.name,
                                        translatedServiceName: $translate.instant(entry.key.name)
                                    };
                                    resultNames.push(nameEntry);
                                });
                            });
                        });
                    });
                return resultNames;
            }

            $scope.findEntities = function () {
                return paginationApi.seekPage(1);
            };

            $scope.entityOwnerChanged = function () {
                $scope.filter = null;
                $scope.filterTemplate = null;
                filterServiceTemplate = null;
                paginationApi.clearResults();

                if (!$scope.model.entityDefinition) {
                    $scope.model.entityField = null;
                    $scope.model.fieldTemplate = null;
                    $scope.model.lgpdValue = null;
                } else {
                    nlgLGPDService.getAnonymizeFilterServiceName($scope.model.entityDefinition)
                        .then(function (value) {
                            if ($scope.model.entityDefinition.artifactName === "monitoring" || $scope.model.entityDefinition.artifactName === "userManagement") {
                                $scope.filterTemplate = value;
                            } else {
                                $scope.filter = value;
                            }
                        });
                }
            };

            $scope.onChangeServiceName = function (model) {
                if (!model.owner || !model.owner.serviceName) {
                    paginationApi.clearResults();
                    return;
                }
                $scope.serviceName = model.owner.serviceName;
            };

            $scope.canFill = function () {
                return $scope.model.entityDefinition
                    && $scope.model.entityField
                    && $scope.model.fieldTemplate;
            };

            $scope.getEntitiesByName = function (definiton) {
                return nlgLGPDService.getEntitiesByServicePath(definiton)
                    .then(function (response) {
                        return response.data;
                    });
            };

            $scope.getEntityFieldName = function (tag) {
                if (!tag) return "";
                if (!tag.presentationField) return tag.entityField;
                return tag.presentationField;
            };

            $scope.getEntityName = function (tag) {
                if (!tag) return "";
                if (tag.artifactName === "monitoring" || tag.artifactName === "userManagement") {
                    return "(" + _.capitalize($translate.instant(tag.artifactName)) + "/" +
                        _.capitalize(tag.translatedServiceName) + ") - " + tag.presentationName;
                }
                return "(" + _.capitalize(tag.translatedServiceName) + ") - " + tag.presentationName;
            };

            $scope.getFieldTemplate = function (definition, field) {
                if (definition && field) {
                    nlgLGPDService.getFieldTemplate(definition, field)
                        .then(function (response) {
                            $scope.model.fieldTemplate = response.data;
                        });
                } else {
                    $scope.model.fieldTemplate = null;
                    $scope.model.lgpdValue = null;
                }
            };


            $scope.anonymize = function (record) {
                return messagesModal.cancellable("dialog.warning", ["lgpd.confirm.anonymization"])
                    .then(function () {
                        var template = {
                            entityName: $scope.model.entityDefinition.entityName,
                            entityField: $scope.model.entityField.entityField,
                            value: $scope.model.lgpdValue
                        };
                        var data = {
                            findBy: template,
                            artifactName: $scope.model.entityDefinition.artifactName,
                            servicePath: $scope.model.entityDefinition.servicePath
                        };
                        return nlgLGPDService.anonymize(data, record.definition, record.id)
                            .then(function (response) {
                                var result = response.data;
                                if (result.success) {
                                    messagesModal("dialog.success", {
                                        keyBundle: "edition.lgpd.anonymization.success"
                                    }).then(function () {
                                        return paginationApi.seekPage(1);
                                    });
                                } else {
                                    messagesModal("dialog.warning", {
                                        keyBundle: "edition.lgpd.anonymization.failure",
                                        parameters: result.errorMessages
                                    }).then(function () {
                                        return paginationApi.seekPage(1);
                                    });
                                }
                            }).catch(remoteExceptionHandler());

                    });
            };

            $scope.attachmentAnonymize = function (record) {
                return messagesModal.cancellable("dialog.warning", ["lgpd.confirm.attachment.anonymization"])
                    .then(function () {
                        var template = {
                            entityName: $scope.model.entityDefinition.entityName,
                            entityField: $scope.model.entityField.entityField,
                            value: $scope.model.lgpdValue
                        };
                        var data = {
                            findBy: template,
                            artifactName: $scope.model.entityDefinition.artifactName,
                            servicePath: $scope.model.entityDefinition.servicePath
                        };
                        return nlgLGPDService.attachmentAnonymize(data, record.definition, record.id)
                            .then(function () {
                                messagesModal("dialog.success", {
                                    keyBundle: "edition.lgpd.anonymization.success",
                                }).then(function () {
                                    return paginationApi.seekPage(1);
                                });
                            }).catch(remoteExceptionHandler());
                    });
            };

            $scope.tableOptions = {
                records: [],
                onRegisterPagination: function (api) {
                    paginationApi = api;
                },
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        var data;
                        var filterPromise;

                        if (filterServiceTemplate) {
                            data = {
                                artifactName: $scope.model.entityDefinition.artifactName,
                                entityName: $scope.model.entityDefinition.entityName,
                                entityField: $scope.model.entityField.entityField,
                                servicePath: $scope.model.entityDefinition.servicePath,
                                serviceName: $scope.model.entityDefinition.serviceName,
                                template: filterServiceTemplate
                            };

                            filterPromise = loadingService(nlgLGPDService.findFilterTemplate(data, firstResult, maxResults));
                        } else {
                            data = {
                                entityName: $scope.model.entityDefinition.entityName,
                                entityField: $scope.model.entityField.entityField,
                                fieldTemplate: $scope.model.lgpdValue,
                                artifactName: $scope.model.entityDefinition.artifactName,
                                servicePath: $scope.model.entityDefinition.servicePath,
                                serviceName: $scope.model.entityDefinition.serviceName
                            };

                            filterPromise = loadingService(nlgLGPDService.findFieldTemplate(data, firstResult, maxResults));
                        }

                        return filterPromise.then(function (response) {
                            var definition = response.data.definition;
                            var entities = response.data.resultEntities;

                            if (!entities || entities.length === 0) {
                                messagesModal("dialog.success", {
                                    keyBundle: "edition.lgpd.anonymization.noResults",
                                });
                                response.data.count = 0;
                                return {
                                    result: [],
                                    count: response.data.count,
                                    firstResult: response.data.firstResult,
                                    maxResults: maxResults
                                };
                            }

                            arrays.each(entities, function (record) {
                                record.definition = definition;
                                record.servicePath = data.servicePath;
                                record.artifactName = data.artifactName;
                                record.serviceName = data.serviceName;
                                record.translatedServiceName = $translate.instant(data.serviceName);
                            });

                            return {
                                result: entities,
                                count: response.data.count,
                                firstResult: response.data.firstResult,
                                maxResults: maxResults
                            };
                        });
                    }
                }
            };

        }]);
});
