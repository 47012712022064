define([
    "./monitorModule",
    "c3",
    "angular",
    "lodash"
], function (module, c3, angular, _) {
    "use strict";

    return module.directive("nlgMonitorMetricsChart", [function () {
        return {
            scope: {
                instance: "<",
                metrics: "=",
                columnNames: "&"
            },
            template: "<div class=\"nlgMonitorMetricChart\"></div>",
            require: "nlgMonitorMetricsChart",
            controller: [
                "$scope",
                function ($scope) {
                    $scope.data = {};
                    var dataLoadedCallback = angular.noop;

                    $scope.columnNames().forEach(function (columnName) {
                        $scope.data[columnName] = [];
                    });

                    $scope.$watch("metrics", function (metrics) {
                        $scope.columnNames().forEach(function (columnName) {
                            var metric = metrics[columnName];
                            if (angular.isDefined(metric)) {
                                $scope.data[columnName].push(metric);
                            }
                        });
                        dataLoadedCallback($scope.data);
                    });

                    this.setOnDataLoadedCallback = function (_dataLoadedCallback) {
                        dataLoadedCallback = _dataLoadedCallback || angular.noop;
                    };
                }],
            link: function ($scope, $element, $attrs, nlgMonitorMetricsChart) {
                var chart = c3.generate({
                    bindto: $element.find(".nlgMonitorMetricChart")[0],
                    axis: {y: {min: 0}},
                    data: {columns: []}
                });
                nlgMonitorMetricsChart.setOnDataLoadedCallback(function (metrics) {
                    chart.load({
                        columns: _.map(metrics, function (values, columnName) {
                            return [columnName].concat(values);
                        })
                    });
                });
            }
        };
    }]);
});