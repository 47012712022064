import searchModule from "./searchModule"
import template from "./searchView.html"

searchModule.directive("nlgSearch", [() => {
    return {
        restrict: "E",
        template,
        scope: {
            entityType: "@",
            reload: "@",
            forbidDeletion: "<",
            enableDeletionCheckbox: "<",
            spreadsheetDeletionEnabled: "<"
        },
        controller: "searchController"
    }
}])