define(["../templateFactoryModule", "angular", "./templateFactoryLimitLength"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryDefaultTemplate.class:TemplateFactoryConfiguration
     * @property {string} name
     * Uma string que será colocada dentro do `<span>` do template.
     * */

    /**
     * @ngdoc function
     * @name templateFactoryDefaultTemplate.templateFactoryDefaultTemplate
     * @description
     * Template padrão, que é constituido apenas de um `<span>`.
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     * */
    return templateFactoryModule.service("templateFactoryDefaultTemplate", [function () {
        return function (config) {
            return angular.element("<template-factory-limit-length>")
                .attr("value", config.name);
        };
    }]);
});