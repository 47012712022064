define(["../temperatureRangeConfigModule", "lodash"],
    function (temperatureRangeConfigModule, _) {
        "use strict";

        return temperatureRangeConfigModule.controller("TemperatureRangeConfigEditionController", [
            "$scope",
            "$state",
            "temperatureRangeConfig",
            "monitoringService",
            "loadingService",
            "messagesModal",
            "remoteMessageModal",
            "remoteExceptionHandler",
            function ($scope, $state, temperatureRangeConfig, monitoringService, loadingService, messagesModal, remoteMessageModal, remoteExceptionHandler) {
                $scope.config = temperatureRangeConfig || newInstance();

                $scope.isConfigSelected = function () {
                    return $scope.config !== null;
                };

                $scope.save = function () {
                    loadingService(monitoringService.saveTemperatureConfigRange($scope.config)
                        .catch(remoteExceptionHandler())
                        .then(function (result) {
                            if (result[0].result) {
                                messagesModal("dialog.success", {
                                    keyBundle: "config.params.save.success"
                                }).finally(function () {
                                    reload();
                                })
                            } else {
                                remoteMessageModal(result);
                            }
                        }))
                };

                $scope.back = function () {
                    return $state.go("monitoring.temperatureRangeConfigSearch", {
                        reload: true
                    });
                };

                function reload() {
                    loadingService(monitoringService.findTemperatureRangeConfigBySourceId($scope.config.sourceId))
                        .then(function (config) {
                            $scope.config = config;
                        })
                }

                function newInstance() {
                    return {
                        topCriticalRange: {
                            temperatureRangeType: "br.com.neolog.monitoring.monitorable.model.api.TemperatureRangeType.TOP_CRITICAL_RANGE",
                            minTemperature: 0,
                            maxTemperature: 0,
                            tolerance: 0
                        },
                        topWarningRange: {
                            temperatureRangeType: "br.com.neolog.monitoring.monitorable.model.api.TemperatureRangeType.TOP_WARNING_RANGE",
                            minTemperature: 0,
                            maxTemperature: 0,
                            tolerance: 0
                        },
                        centerRange: {
                            temperatureRangeType: "br.com.neolog.monitoring.monitorable.model.api.TemperatureRangeType.CENTER_RANGE",
                            minTemperature: 0,
                            maxTemperature: 0,
                            tolerance: 0
                        },
                        lowerWarningRange: {
                            temperatureRangeType: "br.com.neolog.monitoring.monitorable.model.api.TemperatureRangeType.LOWER_WARNING_RANGE",
                            minTemperature: 0,
                            maxTemperature: 0,
                            tolerance: 0
                        },
                        lowerCriticalRange: {
                            temperatureRangeType: "br.com.neolog.monitoring.monitorable.model.api.TemperatureRangeType.LOWER_CRITICAL_RANGE",
                            minTemperature: 0,
                            maxTemperature: 0,
                            tolerance: 0
                        }
                    }
                }
            }]);
    });