/**
 * Arquivo para permitir corre��es na biblioteca C3.
 * Ao migrar a vers�o do C3, algumas corre��es podem n�o ser mais necess�rias.
 * Cada corre��o deve ter um teste para garantir que elas continuam funcionando ao trocar de vers�o.
 * */
define([
    "c3"
], function (c3) {
    "use strict";

    allowDotsOnObjectPropertyNames();

    return c3;

    function allowDotsOnObjectPropertyNames() {
        var hasOwnProperty = {}.hasOwnProperty;
        var c3Internal = c3.chart.internal.fn;
        var original = c3Internal.findValueInJson.bind(c3Internal);
        c3Internal.findValueInJson = function (object, path) {
            if (hasOwnProperty.call(object, path)) {
                return object[path];
            }
            return original.call(c3Internal, object, path);
        };
    }
});