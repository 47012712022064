define([
    "./transitionsModule"
], function (module) {
    "use strict";

    return module.constant("TransitionStatus", {
        FINALIZED: "br.com.neolog.monitoring.monitorable.model.api.transition.TransitionStatus.FINALIZED",
        IN_EXECUTION: "br.com.neolog.monitoring.monitorable.model.api.transition.TransitionStatus.IN_EXECUTION",
        NOT_ACCOMPLISHED: "br.com.neolog.monitoring.monitorable.model.api.transition.TransitionStatus.NOT_ACCOMPLISHED",
        CANCELLED: "br.com.neolog.monitoring.monitorable.model.api.transition.TransitionStatus.CANCELLED"
    });
});