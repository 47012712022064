define(["./commonScriptModule"], function (scriptModule) {
   "use strict";
    
    var prefix = "br.com.neolog.script.ScriptLanguage.";
    
    return scriptModule.constant("ScriptLanguage", {
        JAVASCRIPT: prefix + "JAVASCRIPT",
        JAVA: prefix + "JAVA",
        SQL: prefix + "SQL"
    });
});