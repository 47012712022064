import analysisMapModule from "../analysisMapModule"
import detailsModal from "./detailsModal.html"

analysisMapModule.service("detailsModal", ["$modal", function ($modal) {
    return function (selectedOrdersDetails) {
        return $modal.open({
            template: detailsModal,
            size: "xl",
            controller: ["$scope", function ($scope) {

                $scope.tableOptions = {
                    columns: [{
                        cellName: "analysis.map.details.orderCode",
                        header: "analysis.map.details.orderCode",
                        cell: {
                            "template": "<a href=\"\" target=\"_blank\" ui-sref=\"r2.edition({ entityType: record.type, entityId: record.orderId })\"> {{record.orderCode}} </a>"
                        }
                    },{
                        cellName: "analysis.map.details.originCode",
                        header: "analysis.map.details.originCode",
                        cell: "{{ record.originCode }}"
                    },{
                        cellName: "analysis.map.details.destinationCode",
                        header: "analysis.map.details.destinationCode",
                        cell: "{{ record.destinationCode }}"
                    },{
                        cellName: "analysis.map.details.destinationName",
                        header: "analysis.map.details.destinationName",
                        cell: "{{ record.destinationName }}"
                    },{
                        cellName: "analysis.map.details.destinationCityName",
                        header: "analysis.map.details.destinationCityName",
                        cell: "{{ record.destinationCityName }}"
                    },{
                        cellName: "analysis.map.details.destinationDistrict",
                        header: "analysis.map.details.destinationDistrict",
                        cell: "{{ record.destinationDistrict }}"
                    },{
                        cellName: "analysis.map.details.totalWeight",
                        header: "analysis.map.details.totalWeight",
                        cell: "{{record.totalWeight | number:3}}"
                    },{
                        cellName: "analysis.map.details.totalVolume",
                        header: "analysis.map.details.totalVolume",
                        cell:  "{{record.totalVolume | number:3}}"
                    },{
                        cellName: "analysis.map.details.totalValue",
                        header: "analysis.map.details.totalValue",
                        cell: "{{record.totalValue | number:2}}"
                    },{
                        cellName: "analysis.map.details.totalQuantityOfShipmentUnits",
                        header: "analysis.map.details.totalQuantityOfShipmentUnits",
                        cell: "{{ record.totalQuantityOfShipmentUnits }}"
                    },{
                        cellName: "analysis.map.details.distinctProducts",
                        header: "analysis.map.details.distinctProducts",
                        cell: "{{ record.distinctProducts }}"
                    }],
                    records: selectedOrdersDetails
                }
            }]
        }).result
    }
}])