define(["../genericParamsModule", "require"], function (crudModule, require) {
    "use strict";

    crudModule.directive("nlgGenericParamDefinitionInstructions", [function () {
        return {
            templateUrl: require("link!./nlgGenericParamDefinitionInstructions.html"),
            scope: {
                definition: "=?definition"
            }
        };
    }]);
});