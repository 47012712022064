define([
    "angular",
    "angular-bootstrap",
    "../path/path",
    "../remote/remote"
], function (angular) {
    "use strict";

    return angular.module("routingConfigModule", [
        "ui.bootstrap",
        "pathModule",
        "remoteModule"
    ]);
});