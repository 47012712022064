define([
    "angular",
    "../monitorable/monitorable"
], function (angular) {
    "use strict";

    return angular.module("monitoringNodeCommandsModule", [
        "monitorableModule",
        "monitoringModule",
    ]);
});