define(["./violationModule", "text!./messagesModalOptions.html", "lodash"], function (violationModule, template, _) {
    "use strict";

    /**
     * @ngdoc service
     * @name violationModule.messagesModalOptions
     * @description
     * Serviço que exibe um modal que recebe funções e são convertido em botões.
     *
     * @param {string} header Texto a ser exibido no cabeçalho do modal
     * @param {string|string[]} messageList `array` de mensagens a serem internacionalizadas e parametrizadas
     * @param {function} buttonFunctions são os objetos tendo um alias que é um bundle e qual ação ele irá disparar.
     *          objects = [{"buttonName":"bundle", "action":function(){}}]
     * @returns {promise} Promessa que é resolvida quando o modal é fechado.
     * */
    return violationModule.service("messagesModalOptions", ["$modal", function ($modal) {
        function showModal(header, messageList, functionArguments) {
            return $modal.open({
                template: template,
                controller: ["$scope", function ($scope) {
                    $scope.header = header;
                    $scope.messageList = _.isArray(messageList) ? messageList : [messageList];
                    $scope.functionArguments = functionArguments;
                    $scope.getBundle = function (message) {
                        return message.keyBundle || message;
                    };
                    $scope.executeAction = function (action) {
                        action();
                        $scope.$close();
                    };
                }]
            }).result;
        }

        return function (header, messageList, functionArguments) {
            return showModal(header, messageList, functionArguments)
                .catch(function () {
                });
        };
    }]);
});