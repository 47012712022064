import loadUnloadDurationModule from "./loadUnloadDurationModule"
import loadUnloadDurationView from "./loadUnloadDurationView.html"

loadUnloadDurationModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.loadUnloadDuration", {
        data: {
            pageTitle: "{{'title.r2.loadUnloadDuration' | translate}}"
        },
        url: "/load-unload-duration",
        template: loadUnloadDurationView,
        controller: "loadUnloadDurationController"
    })
}])