import optimizationModule from "./optimizationModule"
import server from "../server"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"

optimizationModule.service("r2OptimizationService", [
    "$http",
    "loadingService",
    "remoteExceptionHandler",
    "$q",
    function ($http, loadingService, remoteExceptionHandler, $q) {
        return function (trips, deliveryUnits, config) {
                
            var tripsIds = arrays.map(trips, function (nodeIdentifier) {
                return nodeIdentifier.id
            })
            var deliveryUnitsIds = arrays.map(deliveryUnits, function (nodeIdentifier) {
                return nodeIdentifier.id
            })

            return loadingService($http.post(server.getServiceUrl("doOptimize"), {
                deliveryUnitIds: deliveryUnitsIds,
                routingConfigId: config.routingConfig.id,
                tripIds: tripsIds
            })).catch(function (e) {
                remoteExceptionHandler()(e)
                return $q.reject()
            })
        }
    }])



         
