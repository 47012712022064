define([
    "./virtualFenceModule"
], function (virtualFenceModule) {
    "use strict";

    virtualFenceModule.service("virtualFenceService", [
        "monitoringService",
        function (monitoringService) {
            var self = this;

            self.getVirtualFenceConfigurations = function () {
                return monitoringService.getVirtualFenceConfigurations();
            };

            self.isValidResult = function (elements) {
                var messages = [];
                elements.forEach((function (element) {
                    if (element.result === false) {
                        messages.push(element.description)
                    }
                }));
                return messages
            };
            return self;
        }]);
});