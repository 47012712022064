define(["./panelModule",
    "text!./panel.html"
], function (panelModule, panelTemplate) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:panel
     * @description
     * Diretiva que consiste de um título e um corpo.
     *
     * @param {string=} panelTitle bundle de título do painel
     * @param {boolean=} checkBoxValue adiciona um checkBox no título, caso este atributo esteja definido.
     * Ademais, será feito binding bidirecional do valor deste checkBox com a propriedade booleana especificada.
     * @param {Function=} onValueChange Função opcional que será invocada quado o checkbox for clicado. Obrigatório uso de checkBoxValue.
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <span>Escolha um título:</span> <input type="text" ng-model="panelTitle">
     *            <div panel="{{panelTitle}}">
     *                Corpo
     *            </div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb")
     *         .controller("example", function ($scope) {
     *             $scope.panelTitle = "Título";
     *         });
     *     </file>
     * </example>
     *
     * Exemplo com checkBox:
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <span>O valor do checkbox interno é: {{model.value}}</span>
     *            <div panel="{{panelTitle}}" check-box-value="model.value">
     *                Corpo
     *            </div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb")
     *         .controller("example", function ($scope) {
     *             $scope.model = {value: false};
     *         });
     *     </file>
     * </example>
     *
     *
     * */
    panelModule.directive("panel", [function () {
        return {
            restrict: "A",
            template: panelTemplate,
            replace: true,
            transclude: true,
            scope: {
                ngDisabled: "&?ngDisabled",
                panelTitle: "@panel",
                checkBoxValue: "=?checkBoxValue",
                checkBox: "@?",
                onValueChange: "&?",
                titleTooltipText: "@?",
                titleTooltipStyle: "@?",
            },
            controller: ["$scope", "$attrs", function ($scope, $attrs) {
                $scope.useCheckBox = $scope.checkBox !== "false" && !!$attrs.checkBoxValue;
                if ($scope.useCheckBox) {
                    $scope.checkBoxName = $attrs.checkBoxValue;
                }
                $scope.model = {
                    value: $scope.checkBoxValue
                };
                $scope.onSelect = function () {
                    $scope.checkBoxValue = $scope.model.value;
                    if ($scope.onValueChange) {
                        $scope.onValueChange();
                    }
                };
                $scope.hasTooltip = function () {
                    return !!$scope.titleTooltipText;
                };
                $scope.getTooltipStyle = function () {
                    if ($scope.titleTooltipStyle){
                        return $scope.titleTooltipStyle;
                    }
                    return "fa-question-circle";
                };
                $scope.$watch("checkBoxValue", function (newValue) {
                    $scope.model.value = newValue;
                });
            }]
        };
    }]);
});
