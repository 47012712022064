define(["./timeModule", "angular", "../arrays/arrays", "text!./smallDateTimePicker.html"], function (timeModule, angular, arrays, template) {
    "use strict";

    return timeModule.directive("smallDateTimePicker", ["datetimepickerDirective", "timeService", function (datetimepickerDirectives, timeService) {
        var delegate = datetimepickerDirectives[0];
        return {
            template: template,
            scope: delegate.scope,
            require: delegate.require,
            link: function ($scope, $element, $attrs, ngModelController) {
                delegate.link.apply(delegate, arrays.copy(arguments));

                // visivel para testes do protractor
                $scope.updateDate = function (newDate) {
                    var date = new Date(newDate);
                    $scope.model.date = date;
                    $scope.model.time = timeService.toMillis(date.getHours(), date.getMinutes());
                    $scope.change();
                    $scope.$digest();
                    return $scope.model;
                };

                $scope.getDate = function () {
                    var date = ngModelController.$modelValue;
                    if (!date) {
                        return null;
                    }
                    return date.getTime();
                };
            },
            controller: ["$scope", "$attrs", function ($scope, $attrs) {
                $scope.placeholder = $scope.$parent.$eval($attrs.placeholder);
            }]
        };
    }]);
});