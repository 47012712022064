import gatewayServer from "./gatewayServer"

const dashboardBaseUrl = gatewayServer.gatewayBaseUrl + "/dashboard-server"
const services = {
    query: "/query",
    info: "/dashboard-info",
    export: "/export",
    countExport: "/countExport",
    discovery: "/discovery"
}

export default {
    getDashboardUrl() {
        return dashboardBaseUrl
    },
    getServiceUrl(service){
        if (!services[service]) {
            return void 0
        }
        return services[service]
    }
}


