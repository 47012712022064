define([
    "./dashboardModule",
    "lodash",
    "../arrays/arrays",
    "angular",
    "../objects/objects",
    "text!./dashboardFilters.html"
], function (dashboardModule, _, arrays, angular, objects, template) {
    "use strict";

    dashboardModule.directive("dashboardFilters", ["$translate", function ($translate) {
        return {
            "restrict": "E",
            "require": "^dashboard",
            "template": template,
            link: function (scope, element, attrs, dashboardController) {
                scope.filterBundle = "dashboard.filter.";
                scope.filters = [];
                scope.filterModel = {};
                scope.filterOptions = {};
                scope.hasRole = function hasRole(role, parameter) {
                    return role === parameter.parameterRole;
                };

                scope.applyFilter = function () {
                    var filtersToApply = [];
                    scope.filters.forEach(function (filterConfig) {
                        var filterConfigToApply = filterServices[filterConfig.filter.filterType].getFilterValue(filterConfig.filter);
                        filtersToApply.push(filterConfigToApply);
                    });
                    // estado atual do filtro, utilizado para compatibilizar critérios na exportação
                    scope.appliedFilter = filtersToApply;
                    scope.$broadcast("$filterRefresh");
                    dashboardController.filter(scope.config.dashboardSourceId, filtersToApply);
                };

                scope.createFilterTemplate = function (filterConfig) {
                    if (!filterConfig) {
                        throw new Error("Cannot create template without filterConfig.");
                    }
                    var templateFactory = dashboardController.getTemplateFactory();
                    var templateConfig = filterServices[filterConfig.filter.filterType].convertFilterConfig(filterConfig.filter);
                    var template = templateFactory(templateConfig);
                    return template[0].outerHTML;
                };

                scope.clearFilter = function () {
                    arrays.each(Object.keys(scope.filterModel), function (property) {
                        scope.filterModel[property] = null;
                    });
                };

                scope.areFiltersVisible = true;
                scope.toggleFiltersVisibility = function() {
                    scope.areFiltersVisible = !scope.areFiltersVisible;
                };

                scope.$on("$dashboardConfigUpdate", function (event, dashboardConfig) {
                    scope.filters = [];

                    dashboardConfig.defaultFilters.forEach(function (defaultFilter) {
                        var filterFactory = filterServices[defaultFilter.filterType];
                        if (!filterFactory) {
                            throw new Error("There is no filter factory for type: " + defaultFilter.filterType);
                        }

                        var allDomain = {
                            domain: []
                        };
                        dashboardConfig.chartInfoResult.forEach(function (chartInfo) {
                            allDomain.alias = defaultFilter.alias;
                            var domain = _.find(chartInfo.domains, function (domain) {
                                return domain.alias === defaultFilter.alias;
                            });
                            if (domain) {
                                concat(allDomain.domain, domain.domain);
                            }
                        });

                        var modelAndOptions = filterFactory.getModelAndOptions(defaultFilter, allDomain);
                        scope.filterModel[defaultFilter.alias] = modelAndOptions.model;
                        scope.filterOptions[defaultFilter.alias] = modelAndOptions.options;

                        scope.filters.push({
                            filter: defaultFilter,
                            domain: allDomain
                        });
                    });

                    /**
                     * @param {DomainElementsWrapper[]} allDomainElementsWrappers
                     * @param {DomainElementsWrapper[]} otherDomainElementsWrappers
                     * @return {DomainElementsWrapper[]}
                     */
                    function concat(allDomainElementsWrappers, otherDomainElementsWrappers) {
                        /** @type {String[]} */
                        var allValues = allDomainElementsWrappers.map(function (x) {
                            return x.elements[0].value;
                        });

                        otherDomainElementsWrappers.forEach(
                            /**
                             * @param {DomainElementsWrapper} element
                             */
                            function (element) {

                                if (!allValues.includes(element.elements[0].value)) {
                                    allDomainElementsWrappers.push(element);
                                    allValues.push(element.elements[0].value);
                                }
                            }
                        );
                    }
                });

                scope.filterComparator = function (value1, value2) {
                    var bundle1 = scope.filterBundle + (value1.value.filterLabel || value1.value.alias);
                    var bundle2 = scope.filterBundle + (value2.value.filterLabel || value2.value.alias);
                    return objects.compare($translate.instant(bundle1), $translate.instant(bundle2));
                };

                var filterServices = {
                    "br.com.neolog.dashboard.filter.FilterType.MULTIPLE_VALUES": {
                        convertFilterConfig: function (filter) {
                            return {
                                type: "multiSelect",
                                model: "filterModel[\"" + filter.alias + "\"]",
                                options: "filterOptions." + filter.alias
                            };
                        },
                        getModelAndOptions: function (filter, domainObject) {
                            var domainValues = [];
                            if (domainObject && domainObject.domain) {
                                domainValues = _.map(domainObject.domain, function (item, index) {
                                    return {
                                        id: index,
                                        label: item.elements[0].value,
                                        alias: item.elements[0].alias
                                    };
                                });
                            }
                            return {
                                model: null,
                                options: domainValues
                            };
                        },
                        getFilterValue: function (filter) {
                            var toApply = angular.copy(filter);
                            var valueToFilter = _.map(scope.filterModel[filter.alias], "label");
                            toApply.parameters.forEach(function (paramter) {
                                paramter.value = valueToFilter;
                            });
                            return toApply;
                        }
                    },
                    "br.com.neolog.dashboard.filter.FilterType.INTERVAL": {
                        convertFilterConfig: function (filterConfig) {
                            return {
                                type: "dateInterval",
                                model: "filterModel[\"" + filterConfig.alias + "\"]"
                            };
                        },
                        getModelAndOptions: function () {
                            return {
                                model: null,
                                options: null
                            };
                        },
                        getFilterValue: function (filterConfig) {
                            var toApply = angular.copy(filterConfig);
                            var filterValue = scope.filterModel[filterConfig.alias];
                            if (!filterValue) {
                                filterValue = {
                                    lowerBound: null,
                                    upperBound: null
                                };
                            }

                            var lowerParameters = arrays.filter(toApply.parameters, function (parameter) {
                                return scope.hasRole("br.com.neolog.dashboard.filter.ParameterRole.LOWER", parameter);
                            });
                            var upperParameters = arrays.filter(toApply.parameters, function (parameter) {
                                return scope.hasRole("br.com.neolog.dashboard.filter.ParameterRole.UPPER", parameter);
                            });

                            lowerParameters.forEach(function (parameter) {
                                parameter.value = filterValue.lowerBound;
                            });
                            upperParameters.forEach(function (parameter) {
                                parameter.value = filterValue.upperBound;
                            });

                            return toApply;
                        }
                    },
                    "br.com.neolog.dashboard.filter.FilterType.DATETIME_INTERVAL": {
                        convertFilterConfig: function (filterConfig) {
                            var toApply = angular.copy(filterConfig);
                            var lowerParameters = _.min(arrays.filter(toApply.parameters, function (parameter) {
                                return scope.hasRole("br.com.neolog.dashboard.filter.ParameterRole.LOWER", parameter);
                            }).map( function(p) { return p.value; }));

                            var upperParameters = _.max(arrays.filter(toApply.parameters, function (parameter) {
                                return scope.hasRole("br.com.neolog.dashboard.filter.ParameterRole.UPPER", parameter);
                            }).map(function(p) {
                                return p.value;
                            }));

                            var initialDate = new Date();
                            if(upperParameters){
                                initialDate.setTime(initialDate);
                            }else {
                                initialDate.setHours(0, 0, 0, 0);
                            }

                            var finalDate = new Date();
                            if(lowerParameters){
                                initialDate.setTime(lowerParameters);
                            }else{
                                finalDate.setHours(23, 59,59, 59);
                            }

                            return {
                                type: "dateTimeInterval",
                                model: "filterModel[\"" + filterConfig.alias + "\"]",
                                defaultLowerBound: initialDate,
                                defaultUpperBound: finalDate,
                                required: true
                            };
                        },
                        getModelAndOptions: function () {
                            return {
                                model: null,
                                options: null
                            };
                        },
                        getFilterValue: function (filterConfig) {
                            var toApply = angular.copy(filterConfig);
                            var filterValue = scope.filterModel[filterConfig.alias];
                            if (!filterValue) {
                                filterValue = {
                                    lowerBound: null,
                                    upperBound: null
                                };
                            }

                            var lowerParameters = arrays.filter(toApply.parameters, function (parameter) {
                                return scope.hasRole("br.com.neolog.dashboard.filter.ParameterRole.LOWER", parameter);
                            });
                            var upperParameters = arrays.filter(toApply.parameters, function (parameter) {
                                return scope.hasRole("br.com.neolog.dashboard.filter.ParameterRole.UPPER", parameter);
                            });

                            lowerParameters.forEach(function (parameter) {
                                parameter.value = filterValue.lowerBound;
                            });
                            upperParameters.forEach(function (parameter) {
                                parameter.value = filterValue.upperBound;
                            });

                            return toApply;
                        }
                    },
                    "br.com.neolog.dashboard.filter.FilterType.BOOLEAN": {
                        convertFilterConfig: function (filterConfig) {
                            return {
                                name: filterConfig.alias,
                                type: "checkbox",
                                model: "filterModel[\"" + filterConfig.alias + "\"]"
                            };
                        },
                        getModelAndOptions: function () {
                            return {
                                model: null,
                                options: null
                            };
                        },
                        getFilterValue: function (filterConfig) {
                            var filterValue = scope.filterModel[filterConfig.alias];
                            if (angular.isUndefined(filterValue) || filterValue === null) {
                                return filterValue;
                            }
                            var toApply = angular.copy(filterConfig);
                            var value = scope.filterModel[filterConfig.alias];
                            toApply.parameters.forEach(function (parameter) {
                                parameter.value = value;
                            });
                            return toApply;
                        }
                    }
                };
            }
        };
    }]);
    /**
     * Referências: http://usejsdoc.org/tags-typedef.html, https://eek.ro/json-to-jsdoc/
     *
     * @typedef {Object} DomainElement
     * @property {String} value
     * @property {String} alias
     * @property {String} javaType
     */
    /**
     * @typedef {Object} DomainElementsWrapper
     * @property {DomainElement[]} elements
     */
});
