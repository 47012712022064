define(["../occurrenceStatusModule", "lodash"],
    function (occurrenceStatusModule) {
        "use strict";

        return occurrenceStatusModule.controller("OccurrenceStatusEditionController", [
            "$scope",
            "$state",
            "occurrenceStatus",
            "monitoringService",
            "messagesModal",
            "occurrenceStatusService",
            function ($scope, $state, occurrenceStatus, monitoringService, messagesModal, occurrenceStatusService) {
                $scope.entity = occurrenceStatus;

                $scope.getStatusTypes = function () {
                    return monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.occurrence.OccurrenceStatusType")
                        .then(function (values) {
                            return values;
                        });
                };

                $scope.findAllIntermediateAndFinalStatuses = function () {
                    return monitoringService.findAllIntermediateAndFinalStatuses($scope.entity.id);
                };
                function save(){
                    var possibleNextStatuses = $scope.entity.possibleNextStatuses || [];

                    var occurrenceStatus = {
                        name: $scope.entity.name,
                        description: $scope.entity.description,
                        statusType: $scope.entity.statusType,
                        firstSla: $scope.entity.firstSla,
                        secondSla: $scope.entity.secondSla,
                        possibleNextStatuses: possibleNextStatuses,
                        presentationInfo: {
                            cssClass: "fa fa-exclamation fa-3x",
                            mapIconUrl: "images/warning.png"
                        }
                    };

                    if ($scope.entity.id) {
                        occurrenceStatus.id = $scope.entity.id;
                    }

                    return occurrenceStatusService.saveOccurrenceStatus(occurrenceStatus);
                }


                $scope.save = function () {
                    save().then(function (id) {
                        return $state.go("monitoring.OccurrenceStatusEdition", {
                            occurrenceStatusId: id
                        });
                    });
                };

                $scope.saveAndContinue = function () {
                    save().then(function () {
                        return $state.go($state.current, {reload: Date.now()});
                    });
                };

                $scope.onChangeType = function(){
                    if(!$scope.entity.statusType){
                        $scope.entity.possibleNextStatuses = null;
                    }
                };

                $scope.isNotInitialOrIntermediate = function () {
                    return !($scope.entity.statusType === 'br.com.neolog.monitoring.monitorable.model.api.occurrence.OccurrenceStatusType.INITIAL'
                        || $scope.entity.statusType === 'br.com.neolog.monitoring.monitorable.model.api.occurrence.OccurrenceStatusType.INTERMEDIATE')
                };
                $scope.back = function () {
                    return $state.go("monitoring.OccurrenceStatusSearch", {
                        reload: true
                    });
                };

            }
        ]);
    });