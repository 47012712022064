define([
    "./monitoringNodeCommandsModule"
], function (module) {
    "use strict";

    return module.service("nodeCommandTransitionNotAccomplish", ["transitionFailureReasonModal", function (transitionFailureReasonModal) {
        return function (selection, context, nodes) {
            return transitionFailureReasonModal([selection[0].id], null, null, null, nodes)
                .then(function () {
                    return context.refilter();
                });
        };
    }]);
});