define([
    "./commonScriptModule",
    "text!./nlgScriptEntityEditionCollaborators.html"
], function (controllers, template) {
    "use strict";

    controllers.directive("nlgScriptEntityEditionCollaborators", [function () {
        return {
            template: template,
            scope: {
                entity: "=",
                isEnabled: "&?"
            },
            controller: "nlgScriptEntityEditionController"
        };
    }]);
});