define(["./mapsModule", "../arrays/arrays", "angular", "text!./mapsRouteModal.html"], function (mapsModule, arrays, angular, mapsRouteModalTemplate) {
    "use strict";

    /**
     * @ngdoc service
     * @name mapsModule.mapsRouteModal
     * @description
     * Serviço que recebe um array de presentationNodes e exibe um modal com suas rotas.
     * */
    mapsModule.service("mapsRouteModal", ["$modal", function ($modal) {
        return {
            programmable: function (routes, context) {
                openRouteModal(routes, context, true);
            },
            presentation: function (routes, context) {
                openRouteModal(routes, context, false);
            }
        };

        function openRouteModal(routes, context, canProgram) {
            $modal.open({
                template: mapsRouteModalTemplate,
                size: "xl",
                controller: ["$scope", function ($scope) {
                    $scope.routes = routes;
                    $scope.canProgram = canProgram;

                    $scope.resume = function () {
                        $scope.$close();
                        context.refilter();
                    };
                }]
            });
        }
    }]);
});