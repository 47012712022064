import availabilityModule from "./availabilityModule"
import server from "../server"

availabilityModule.service("availabilityService", [
    "$http",
    "loadingService",
    "remoteExceptionHandler",
    ($http, loadingService, remoteExceptionHandler) => {

    const baseURL = server.getServiceUrl("vehicle-availability")

    const filterVehicles = ({patches, firstResult, maxResults}) => {
        const pageOptions = {
            firstResult: firstResult,
            maxResults: maxResults
        }
        return loadingService($http.post(baseURL, patches, {params: pageOptions}))
            .catch(remoteExceptionHandler())
            .then((response) => response.data)
    }

    const saveVehicleAvailability = ({vehicleId, availability}) => {
        const url = baseURL + "save"
        const vehicleIdentifier = {
            vehicleId: vehicleId
        }
        return loadingService($http.post(url, availability, {params: vehicleIdentifier}))
            .catch(remoteExceptionHandler())
            .then((response) => response.data)
    }

    return {
        filterVehicles: filterVehicles,
        saveVehicleAvailability: saveVehicleAvailability
    }
}])