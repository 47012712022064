define([
    "./genericParamsModule"
], function (crudModule) {
    "use strict";

    return crudModule.controller("TransferableGenericParamConfirmationController", ["$scope", "$q", "transferDto", "genericParamDataToDelete", "genericParamDataToCreate", function ($scope, $q, transferDto, genericParamDataToDelete, genericParamDataToCreate) {
            $scope.componentNames = [transferDto.transferableAxis.componentName];
            transferDto.fixedAxes.forEach(function (fixedAxis) {
                $scope.componentNames.push(fixedAxis.componentName);
            });

            $scope.createdQuantity = genericParamDataToCreate.length;
            $scope.datasToCreate = {
                show: false,
                paginationApi: null,
                allRecords: genericParamDataToCreate,
                records: [],
                pagination: {
                    maxPageSizes: [10, 25],
                    maxPageSize: 10,
                    onPaginate: function (firstResult, maxResults) {
                        return $q.when($scope.datasToCreate.allRecords.slice(firstResult, firstResult + maxResults))
                            .then(function (data) {
                                return {
                                    firstResult: firstResult,
                                    maxResults: maxResults,
                                    count: $scope.createdQuantity,
                                    result: data
                                };
                            });
                    }
                },
                onRegisterPagination: function (api) {
                    $scope.datasToCreate.paginationApi = api;
                }
            };
            $scope.datasToDelete = {
                show: false,
                paginationApi: null,
                allRecords: genericParamDataToDelete,
                records: [],
                pagination: {
                    maxPageSizes: [10, 25],
                    maxPageSize: 10,
                    onPaginate: function (firstResult, maxResults) {
                        return $q.when($scope.datasToDelete.allRecords.slice(firstResult, firstResult + maxResults))
                            .then(function (data) {
                                return {
                                    firstResult: firstResult,
                                    maxResults: maxResults,
                                    count: $scope.datasToDelete.allRecords.length,
                                    result: data
                                };
                            });
                    }
                },
                onRegisterPagination: function (api) {
                    $scope.datasToDelete.paginationApi = api;
                }
            };

            $scope.showOrHideData = function (context) {
                if (context === "create") {
                    if (!$scope.datasToCreate.show) {
                        $scope.datasToCreate.paginationApi.seekPage(1);
                    }
                    $scope.datasToCreate.show = !$scope.datasToCreate.show;
                } else if (context === "delete") {
                    if (!$scope.datasToDelete.show) {
                        $scope.datasToDelete.paginationApi.seekPage(1);
                    }
                    $scope.datasToDelete.show = !$scope.datasToDelete.show;
                }
            };
        }]
    );
});