define(["./kpiConfigurationsModule",
        "json!../tdnByEntity.json"],
    function (module, tdnByEntity) {
        "use strict";

        return module.controller("KPIConfigurationsController", [
            "$scope",
            "monitoringService",
            "kpiConfigurationService",
            "kpiConfigurations",
            "tdnHelp",
            function ($scope, monitoringService, kpiConfigurationService, kpiConfigurations, tdnHelp) {
                $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["kpi-configurations"];
                setConfigurations();

                (function () {
                    $scope.failureReasons = kpiConfigurationService.getAllFailureReason();

                    monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.kpiconfigurations.KPIConfigurationTripOrdenation")
                        .then(function (values) {
                            $scope.availableTripOrdenation = values;
                        });

                    monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.kpiconfigurations.KPIConfigurationIndicatorDefinition")
                        .then(function (values) {
                            $scope.availableIndicatorMeasurement = values;
                        });
                })();

                $scope.save = function () {
                    return kpiConfigurationService.save($scope.entity);
                };

                $scope.getAllFailureReason = function () {
                    return kpiConfigurationService.getAllFailureReason();
                };

                function setConfigurations() {
                    if (kpiConfigurations) {
                        $scope.entity = kpiConfigurations;
                        return;
                    }
                    $scope.entity = {};
                }
            }
        ]);
    });
