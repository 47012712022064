import unblockTripMessagesModule from "./unblockTripMessagesModule"
import unblockTripMessagesDialog from "./unblockTripMessageDialog.html"

unblockTripMessagesModule.service("unblockTripMessageDialog", [
        "$http",
        "$modal",
        "downloadBinary",
        "loadingService",
        function ($http, $modal, downloadBinary, loadingService) {
            return function (message) {
                return $modal.open({
                    template: unblockTripMessagesDialog,
                    size: "xl",
                    controller: ["$scope", "unblockTripMessagesService", "remoteExceptionHandler", "messagesModal", function ($scope, unblockTripMessagesService, remoteExceptionHandler, messagesModal) {

                        $scope.message = message

                        $scope.breakTable = {
                            records: $scope.message.unblockTripOrderBreaks
                        }

                        $scope.tripTable = {
                            records: $scope.message.unblockTrips
                        }

                        $scope.erpMessagesTable = {
                            records: $scope.message.erpMessages
                        }

                        $scope.resendMessage = function () {
                            loadingService(unblockTripMessagesService.resendMessage(message.id))
                                .then(function () {
                                    messagesModal("dialog.success", [{
                                        keyBundle: "interface.unblockTripMessage.modal.resendMessageSucceed",
                                        parameters: [message.id]
                                    }])
                                }).catch(remoteExceptionHandler())
                        }
                    }]
                })

            }
        }
    ])