import biModule from "./biModule"
import biView from "./biView.html"

biModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.bi", {
        url: "/bi",
        template: biView,
        controller: "biController",
        data: {
            pageTitle: "{{'title.report' | translate}}",
            group: "group.r2.kpi"
        },
        resolve: {
            isAllowed: ["$state", "configParamsService", ($state, configParamsService) => {
                return configParamsService.isBiAllowed().then(function (isAllowed) {
                    if (!isAllowed) {
                        $state.go("r2.home")
                    }
                })
            }]
        }
    })
}])
