define([
    "./occurrenceCauseModule",
    "text!./search/OccurrenceCauseSearchView.html",
    "text!./edition/OccurrenceCauseEditionView.html"
], function (module,
             occurrenceCauseSearchTemplate,
             occurrenceCauseEditionTemplate) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("monitoring.newOccurrenceCause", {
                url: "/newOcccurrenceCause",
                controller: "OccurrenceCauseEditionController",
                template: occurrenceCauseEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.cause",
                    pageTitle: "{{'title.monitoring.newOccurrenceCause' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceCause: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.getNewInstanceOccurrenceCause();
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.OccurrenceCauseSearch", {
                url: "/occurrenceCause?reload",
                controller: "OccurrenceCauseSearchController",
                template: occurrenceCauseSearchTemplate,
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.monitoring.occurrence.cause",
                    pageTitle: "{{'title.monitoringOccurrenceCauseSearch' | translate:$stateParams}}"
                }
            })
            .state("monitoring.OccurrenceCauseEdition", {
                url: "/occurrenceCause/:occurrenceCauseId",
                controller: "OccurrenceCauseEditionController",
                template: occurrenceCauseEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.cause",
                    pageTitle: "{{'title.monitoring.occurrenceCauseEdition' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceCause: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.findOccurrenceCauseById($stateParams.occurrenceCauseId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.OccurrenceCauseCopy", {
                url: "/occurrenceCause/copy/:occurrenceCauseId",
                controller: "OccurrenceCauseEditionController",
                template: occurrenceCauseEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.cause",
                    pageTitle: "{{'title.monitoring.occurrenceCauseCopy' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceCause: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.copyOccurrenceCause($stateParams.occurrenceCauseId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
    }]);
});
