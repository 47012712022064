define([
    "angular",
    "../routingConfig/routingConfig",
    "../translate/translate",
    "../collections/collections"
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name commonsOperationModule
     * @description
     * Módulo com funcionalidades referentes à operações manuais, otimizações
     * e outras operações.
     * */
    return angular.module("commonsOperationModule", [
        "routingConfigModule",
        "translateModule",
		"collectionsModule"
    ]);
});