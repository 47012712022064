define([
    "./moveToAnotherUserModule",
    "text!./moveToAnotherUserModal.html"
], function (moveToAnotherUserModule, template) {
    "use strict";
    return moveToAnotherUserModule.provider("moveToAnotherUserModal", [function () {
        var shouldLoadCurrentUser = false;

        this.shouldLoadCurrentUser = function (value) {
            shouldLoadCurrentUser = value;
        };

        this.$get = ["$http", "$modal", "autocompleteFactory", "loadingService", "commonsUserService", "$q", "remoteExceptionHandler", "pathService",
            function ($http, $modal, autocompleteFactory, loadingService, commonsUserService, $q, remoteExceptionHandler, pathService) {
                return function (selection, isFromSelectView) {
                    var defer = $q.defer();
                    $modal.open({
                        template: template,
                        controller: ["$scope", "messagesModal", function ($scope, messagesModal) {
                            $scope.users = autocompleteFactory.lazyLoader(pathService.getPath("users"), [], {
                                "ignoreUserRestrictions": false
                            });

                            $scope.model = {
                                user: null
                            };

                            $scope.openSuccessMessageModal = function (message) {
                                return messagesModal("dialog.success", [message]);
                            };

                            if (shouldLoadCurrentUser) {
                                commonsUserService.getCurrentUser()
                                    .then(function (userDto) {
                                        $scope.model = {
                                            user: userDto
                                        };
                                    });
                            }

                            function isFromSelectionView(isFromSelectView) {
                                if (isFromSelectView) {
                                    return loadingService($http.post(pathService.getPath("move-selected-deliveryunit-from-user-to-user") + $scope.model.user.id, selection));
                                }
                                return loadingService($http.post(pathService.getPath("move-deliveryunit-to-user") + $scope.model.user.id, selection));
                            }

                            $scope.okPressed = function () {
                                isFromSelectionView(isFromSelectView)
                                    .catch(remoteExceptionHandler())
                                    .then(function () {
                                        $scope.$close();
                                        defer.resolve();
                                    }).then(function () {
                                    return $scope.openSuccessMessageModal({
                                        keyBundle: "order.movimentation.successful"
                                    });
                                });
                            };
                        }]
                    });
                    return defer.promise;
                };
            }];
    }]);
});