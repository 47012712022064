define([
    "./transitionsModule",
    "text!../extension/AddNewExtension.html",
    "json!../monitoring.json"
], function (module, extensionModalTemplate, monitoring) {
    "use strict";

    return module.service("transitionService", [
        "monitoringService",
        "messagesModal",
        "loadingService",
        "mbrService",
        "remoteExceptionHandler",
        "remoteMessageModal",
        "TransitionStatus",
        "extensionService",
        "$modal",
        "$http",
        function (monitoringService, messagesModal, loadingService, mbrService, remoteExceptionHandler, remoteMessageModal, TransitionStatus, extensionService, $modal, $http) {
            var self = this;

            self.isFinalized = function (transition) {
                var transitionStatus = transition.status;
                return transitionStatus === TransitionStatus.FINALIZED ||
                    transitionStatus === TransitionStatus.NOT_ACCOMPLISHED ||
                    transitionStatus === TransitionStatus.CANCELLED;
            };

            self.accomplishTransitions = function (ids, timestamp) {
                return changeTransitionsStatus(
                    "monitoring.transition.header.finalize.confirmation",
                    ids,
                    TransitionStatus.FINALIZED,
                    timestamp || Date.now());
            };

            self.notAccomplishTransition = function (id, timestamp, comment, failureReasonId, isTotalSelected, notDeliveredDetails) {
                return loadingService(changeTransitionsStatus(
                    "monitoring.transition.header.notAccomplish.confirmation",
                    [id],
                    TransitionStatus.NOT_ACCOMPLISHED,
                    timestamp || Date.now(),
                    comment,
                    failureReasonId,
                    isTotalSelected,
                    notDeliveredDetails));
            };

            self.notAccomplishTransitions = function (ids, comment, failureReasonId, isTotalSelected, notDeliveredDetails) {
                return changeTransitionsStatus(
                    "monitoring.transition.header.notAccomplish.confirmation",
                    ids,
                    TransitionStatus.NOT_ACCOMPLISHED,
                    Date.now(),
                    comment,
                    failureReasonId,
                    isTotalSelected,
                    notDeliveredDetails);
            };

            self.getTimestampStatus = function (transitionid) {
                return getTimestampStatusService(transitionid);
            }

            function getTimestampStatusService(transitionid) {
                return $http.post(getTimestampStatusServicePath(), transitionid).then(function (response) {
                    return response.data;
                });
            }

            function getTimestampStatusServicePath() {
                return monitoring.baseUrl + monitoring.services.timestampStatus;
            }

            function changeTransitionsStatus(warningMessage, transitionIds, newStatus, timestamp, comment, failureReasonId, isTotalSelected, notDeliveredDetails) {
                return loadingService(monitoringService.findTransitionsById(transitionIds)
                    .catch(remoteExceptionHandler()))
                    .then(function (transitions) {
                        return messagesModal.cancellable("dialog.warning", [{
                            keyBundle: warningMessage,
                            parameters: transitions.map(function (transition) {
                                return transition.sourceId;
                            })
                        }]).then(function () {
                            return transitions;
                        });
                    }).then(function (transitions) {
                        let populatedDeliveryDetails = [];
                        if (notDeliveredDetails) {
                            populatedDeliveryDetails = convertDetailsToItemsDTO(isTotalSelected, notDeliveredDetails)
                            if (populatedDeliveryDetails.length === 0) {
                                return [{
                                    description: "monitoring.transition.failure.reason.not.delivered.quantity.not.found",
                                    result: false
                                }]
                            }
                        }
                        const finalizedTransition = transitions.map(function (transition) {
                            return {
                                "transitionId": transition.id,
                                "latitude": null,
                                "longitude": null,
                                "status": newStatus,
                                "finalizedTimestamp": timestamp,
                                "completionSource": "FrontEnd",
                                "comment": comment,
                                "failureReasonId": failureReasonId,
                                "notDeliveredItemsDTO": populatedDeliveryDetails
                            };
                        });
                        var data = new FormData();
                        data.append("transitions", new Blob([JSON.stringify(finalizedTransition)], {
                            type: "application/json"
                        }));
                        var files = comment ? comment.files : null;
                        if (files) {
                            for (var index in files) {
                                data.append("attachments", files[index]);
                            }
                        }
                        return loadingService(mbrService.transitionChangeStatus(data)
                            .catch(remoteExceptionHandler()));
                    }).then(function (remoteMessages) {
                        return remoteMessageModal(remoteMessages);
                    });
            }

            function convertDetailsToItemsDTO(isTotalSelected, notDeliveredDetails) {
                if (isTotalSelected) {
                    return notDeliveredDetails.map(function (detail) {
                        return {
                            id: detail.id,
                            quantity: detail.totalQuantity
                        }
                    })
                }
                const populatedNotDeliveredDetails = notDeliveredDetails.filter(function (details) {
                    return details.quantity !== 0
                })
                if (populatedNotDeliveredDetails.length === 0) {
                    return [];
                }
                return populatedNotDeliveredDetails.map(function (detail) {
                    return {
                        id: detail.id,
                        quantity: detail.quantity
                    }
                })
            }

            self.getTransitionsByMonitorableType = function (monitorableType) {
                return $http.get(monitoring.baseUrl + monitoring.services.transitionsByMonitorableType, {
                    params: {"monitorableType": monitorableType}
                }).then(function (response) {
                    return response.data;
                });
            }

            return self;
        }]);
});