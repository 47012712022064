define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactorySmallTimeTemplate", [function () {
        return function (config) {
            return angular.element("<small-date-time-picker>")
                .attr("ng-attr-title", config.title)
                .attr("placeholder", config.placeholder)
                .attr("ng-model", config.model);
        };
    }]);
});