import virtualFenceModule from "./virtualFenceModule"
import tdnByEntity from "json!../tdnByEntity.json"

virtualFenceModule.controller("virtualFenceController", ["template", "virtualFenceService", "$scope", "monitoringService", "loadingService", "messagesModal", "remoteExceptionHandler", "tdnHelp",
    (configurations, virtualFenceService, $scope, monitoringService, loadingService, messagesModal, remoteExceptionHandler, tdnHelp) => {

        $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["virtual-fence"];
        $scope.entity = configurations;

        $scope.startType = function () {
            return monitoringService.getEnum("br.com.neolog.monitoring.virtualfence.model.api.VirtualFenceStartType")
                .then(function (values) {
                    return values;
                });
        };


        $scope.save = function () {
            loadingService(monitoringService.saveVirtualFenceConfigurations($scope.entity))
                .then((result) => {
                    var messages = virtualFenceService.isValidResult(result);
                    if (messages.length < 1) {
                        messagesModal("dialog.success", [{
                            keyBundle: "entity.validator.successMessage",
                            parameters: []
                        }]);
                        return getConfig()
                    }
                    messagesModal("dialog.error", messages)
                })
                .catch(remoteExceptionHandler())
        };


        function getConfig() {
            return virtualFenceService.getVirtualFenceConfigurations()
                .then(function (configParams) {
                    $scope.entity = configParams
                })
        }

    }]);
