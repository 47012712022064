define([
    "angular",
    "./tripPickingListModule"
], function (angular, tripPickingListModule) {
    "use strict";
    tripPickingListModule.provider("tripPickingListServiceDependencies", [function () {

        var dependencies = {};

        this.$get = [function () {
            return {
                getDependencies: getDependencies
            };
        }];

        function getDependencies() {
            return dependencies;
        }

        this.configure = function (provider) {
            dependencies = provider.dependencies;
        };
    }]);
});

