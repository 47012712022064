define([
    "./monitoringLocalityModule",
    "text!./searchLocality.html",
    "text!./newLocality.html"
], function (module, templateSearch, templateCreate) {
    "use strict";
    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider.state("monitoring.locality", {
            url: "/locality?reload",
            controller: "monitoringLocalityControllerSearch",
            template: templateSearch,
            resolve: {
                reload: ["$stateParams", function ($stateParams) {
                    return $stateParams.reload === "true";
                }],
                locality: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                    return monitoringService.getNewInstanceLocality();
                }]
            },
            data: {
                group: "group.monitoring.locality",
                pageTitle: "{{'title.trackingMobile.locality' | translate:$stateParams}}"
            }

        })
            .state("monitoring.newLocality", {
                url: "/newLocality",
                controller: "monitoringLocalityControllerEdition",
                template: templateCreate,
                data: {
                    group: "group.monitoring.locality",
                    pageTitle: "{{'title.trackingMobile.locality' | translate:$stateParams}}"
                },
                resolve: {
                    locality: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.getNewInstanceLocality();
                    }]
                },
                params: {
                    reload: "true"
                }

            })
            .state("monitoring.editLocality", {
                url: "/editLocality/:localityId",
                controller: "monitoringLocalityControllerEdition",
                template: templateCreate,
                data: {
                    group: "group.monitoring.locality",
                    pageTitle: "{{'title.trackingMobile.locality' | translate:$stateParams}}"
                },

                resolve: {
                    locality: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.findLocalityById($stateParams.localityId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.copyLocality", {
                url: "/copyLocality/:localityId",
                controller: "monitoringLocalityControllerEdition",
                template: templateCreate,
                data: {
                    group: "group.monitoring.locality",
                    pageTitle: "{{'title.trackingMobile.locality' | translate:$stateParams}}"
                },

                resolve: {
                    locality: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.copyLocality($stateParams.localityId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })

    }]);
});
