define(["./nodeCommandsModule"], function (nodeCommandsModule) {
    "use strict";

    return nodeCommandsModule.service("nodeCommandCancelTrips", [
        "cancellationServiceInvoker",
        function (cancellationServiceInvoker) {
            return function (selection, context) {
                if (selection && selection.length > 0) {
                    return cancellationServiceInvoker.cancel(selection, context);
                }
            };
        }
    ]);
});