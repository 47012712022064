define([
    "../trackingMobileModule"
], function (trackingMobileModule) {
    "use strict";

    return trackingMobileModule.controller("MobileSettingsController", [
        "$scope",
        "mobileSettings",
        "trackingMobileService",
        "messagesModal",
        "remoteExceptionHandler",
        function ($scope, mobileSettings, trackingMobileService, messagesModal, remoteExceptionHandler) {
            $scope.model = mobileSettings;

            $scope.save = function () {
                trackingMobileService.saveMobileApplicationSettings($scope.model)
                    .then(function () {
                        messagesModal("dialog.success", ["mobile.settings.save.sucess"]);
                    }).catch(remoteExceptionHandler());
            };
        }
    ]);
});