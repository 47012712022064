import analysisModule from "./analysisModule"

analysisModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.analysis", {
        data: {
            pageTitle: "{{'title.r2.analysis' | translate}}"
        },
        url: "/programming/analysis",
        template: "<nlg-analysis-view></nlg-analysis-view>"
    })
}])
