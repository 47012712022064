define([
    "./driverMapModule",
    "text!./DriverMapView.html"
], function (driverMapModule, driverMapTemplate) {
    "use strict";

    return driverMapModule.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("trackingMobile.driverMap", {
                url: "/driver-map",
                controller: "DriverMapController",
                template: driverMapTemplate,
                data: {
                    group: "group.monitoring.driver",
                    pageTitle: "{{'title.trackingMobile.driverMap' | translate:$stateParams}}"
                }
            })
    }]);
});