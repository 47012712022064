define(["./remoteModule", "text!./loadingService.html"], function (remoteModule, template) {
    "use strict";

    /**
     * @ngdoc service
     * @name remoteModule.loadingService
     * @description
     * Serviço que exibe um modal de carregamento.
     *
     * Este modal espera uma promessa ser resolvida antes de ser fechado.
     * Os resultados da promessa fornecida são propagados adiante.
     *
     * @param {promise} promise Promessa que o modal espera a conclusão antes de fechar.
     * @return {promise} uma promessa que será resolvida com os resultados da promessa fornecida.
     * */
    return remoteModule.service("loadingService", ["$q", "$modal", function ($q, $modal) {
        return function (promise) {
            var defer = $q.defer();
            var loadingModalInstance = $modal.open({
                template: template,
                backdrop: "static",
                backdropClass: "loadingServiceBackdrop",
                keyboard: false
            });

            promise.then(function (arg) {
                defer.resolve(arg);
            }, function (arg) {
                defer.reject(arg);
            });

            $q.all([promise, loadingModalInstance.opened]).finally(function () {
                loadingModalInstance.dismiss();
            });

            return defer.promise;
        };
    }]);
});