define(["./timeModule", "text!./nlgDurationMinutes.html"], function (timeModule, template) {
    "use strict";
    return timeModule.directive("nlgDurationMinutes", [function () {
        return {
            restrict: "EA",
            template: template,
            scope: {
                model : "=ngModel"
            }
        };
    }]);
});