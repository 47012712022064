define([
    "./monitorModule",
    "text!./nlgMonitorRequestHistory.html"
], function (module, template) {
    "use strict";

    return module.directive("nlgMonitorRequestHistory", [function () {
        return {
            scope: {
                instance: "<"
            },
            template: template,
            controller: [
                "$scope",
                "monitorService",
                "loadingService",
                "remoteExceptionHandler",
                "$q",
                function ($scope, monitorService, loadingService, remoteExceptionHandler, $q) {
                    $scope.requests = [];

                    $scope.loadEndpoint = function () {
                        $scope.loading = true;

                        return loadingService(monitorService.findEndpoint($scope.instance, "trace")
                            .catch(function (err) {
                                // fallback para spring boot v2
                                if (err.status === 404) {
                                    return monitorService.findEndpoint($scope.instance, "httptrace")
                                        .then(function (data) {
                                            return data.traces.map(function (trace) {
                                                return {
                                                    timestamp: trace.timestamp.epochSecond,
                                                    info: {
                                                        method: trace.request.method,
                                                        path: trace.request.uri,
                                                        status: trace.response.status,
                                                        headers: {
                                                            request: trace.request.headers,
                                                            response: trace.response.headers
                                                        }
                                                    }
                                                };
                                            });
                                        });
                                }
                                return $q.reject(err);
                            }))
                            .catch(remoteExceptionHandler())
                            .then(function (data) {
                                $scope.requests = data;
                            })
                            .finally(function () {
                                $scope.loading = false;
                            });
                    };
                }]
        };
    }]);
});