import spreadsheetModule from "./spreadsheetModule"
import newSpreadsheetView from "./spreadsheetView.html"

spreadsheetModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.spreadsheet-import", {
        data: {
            pageTitle: "{{'title.r2.spreadsheet-import' | translate}}"
        },
        url: "/spreadsheet-import",
        template: newSpreadsheetView,
        controller: "newSpreadsheetController"
    })
}])