define([
    "../nlgScriptSqlQueryReportModule",
    "text!./nlgScriptSqlQueryReportList.html",
], function (module, template) {
    "use strict";
    return module.directive("nlgScriptSqlQueryReportList", [function () {
        return {
            restrict: "E",
            scope: {
                sqlBaseUrl: "@",
                sqlFilterServiceUrl: "@",
                domain: "@",
                filterService: "="
            },
            template: template,
            controller: ["$scope",
                "nlgScriptSqlServiceFactory",
                function (
                    $scope,
                    nlgScriptSqlServiceFactory) {
                    var nlgScriptSqlService = nlgScriptSqlServiceFactory($scope.sqlBaseUrl);
                    $scope.getQueries = nlgScriptSqlService.getQueries();
                    $scope.filterService = null;
                    $scope.model = {query: null};
                    $scope.updateQuery = function () {
                        if ($scope.model.query) {
                            $scope.filterService = $scope.sqlFilterServiceUrl + $scope.model.query.id;
                        } else {
                            $scope.filterService = null;
                        }
                    };
                    $scope.getFilterService = function () {
                        return $scope.filterService;
                    };
                }]
        };
    }]);

});
