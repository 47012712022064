import analysisModule from "./analysisModule"
import template from "./nlgAnalysisView.html"

analysisModule.directive("nlgAnalysisView", [() => {
    return {
        restrict: "E",
        template,
        scope: true,
        controller: "analysisController"
    }
}])