define([
    "../trackingMobileModule",
    "json!../trackingMobile.json",
    "front-end-web-commons/app/arrays/arrays",
    "require",
    "lodash"
],function (trackingMobileModule, trackingMobile, arrays, require, _) {
        "use strict";

        return trackingMobileModule.controller("DeviceSearchController", [
            "$scope",
            "trackingMobileService",
            "loadingService",
            "remoteExceptionHandler",
            "messagesModal",
            "deviceConfigurationService",
            function ($scope, trackingMobileService, loadingService, remoteExceptionHandler, messagesModal, deviceConfigurationService) {
                var paginationApi;
                var template;

                $scope.serviceName = _.trim(trackingMobile.services.device, "/");
                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    paginationApi.seekPage(1);
                };

                $scope.tableOptions = {
                    records: [],
                    columns: getColumnDefinitions(),
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(trackingMobileService.filterDevices(template, firstResult, maxResults))
                                .then(function (result) {
                                    return result;
                                }).catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                    }
                };

                $scope.sendConfigurationMessage = function (record) {
                    return deviceConfigurationService.sendConfigurationMessage(record);
                };

                $scope.openMobileQRCodeModal = function (record) {
                    return deviceConfigurationService.openMobileQRCodeModal(record);
                };

                $scope.deleteDevice = function(device){
                    loadingService(trackingMobileService.deleteDevice(device.identifier))
                        .then(function(){
                            messagesModal("dialog.success", ["entity.validator.successDeleteMessage"])
                                .finally(function(){
                                    paginationApi.seekPage(1);
                                });
                        }).catch(remoteExceptionHandler());
                };

                function getColumnDefinitions() {
                    var actionColumn = {
                        "width": "150px",
                        "cell": {
                            "template": "<a name='sendSMSButton' tooltip=\"{{'trackingMobile.sms.tooltip' | translate }}\" ng-disabled='!record.phoneNumber' class='btn mini btn-default' ng-click='sendConfigurationMessage(record)' style='margin-top: 3px; margin-right: 5px'><span class='fa fa-flip-horizontal fa-comment-o'/></a>" +
                            "<a name='openQrCode' tooltip=\"{{'trackingMobile.qr.tooltip' | translate }}\" ng-disabled='!record.phoneNumber' class='btn mini btn-default' ng-click='openMobileQRCodeModal(record)' style='margin-top: 3px; margin-right: 5px'><span class='fa fa-qrcode'/></a>" +
                            "<a name='editButton' tooltip=\"{{'searchView.edit' | translate }}\" class='btn mini btn-default' ui-sref='trackingMobile.deviceEdition({ deviceId: record.id })' style='margin-top: 3px; margin-right: 5px'> <span class='fa fa-pencil-square-o'></span> </a>" +
                            "<button name='deleteButton' tooltip=\"{{'searchView.delete' | translate }}\" class='btn mini btn-default' style='margin-top:3px' ng-click='deleteDevice(record)'> <span class='fa fa-trash-o'></span> </button>"
                        }
                    };

                    return [actionColumn].concat(arrays.map(["identifier", "phoneNumber.number"], function (property) {
                        var cellValue = "record." + property;
                        var header = "trackingMobile.device." + property;
                        var result = {
                            sortBy: property,
                            cellName: property,
                            header: header
                        };
                        result.cell = "{{" + cellValue + "}}";
                        return result;
                    }));
                }
            }]);
    });