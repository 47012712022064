define(["./monitorableFollowupDashboardOverviewModule"], function (module) {
    "use strict";

    return module.controller("monitorableFollowupDashboardOverviewController", [
        "$scope",
        "kpiConfigurations",
        function ($scope, kpiConfigurations) {
            $scope.currentDashboard = {
                dashboardSourceId: ""
            };
            $scope.presentationConfigurations = {
                refreshTime: kpiConfigurations.refreshTime,
                targetIdFullScreen: "monitoring-overview"
            }
        }]);
});
