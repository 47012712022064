import {queryModule} from "./queryModule";
import {IStateProvider} from "angular-ui-router"

queryModule.config(function ($stateProvider: IStateProvider) {
    $stateProvider.state("r2.query", {
        data: {
            pageTitle: "{{'title.r2.query' | translate}}"
        },
        url: "/query",
        template: require("./QueryReportView.html"),
        controller: "QueryReportController",
        controllerAs: "ctrl"
    })
});