import analysisMapModule from "../analysisMapModule"
import server from "../../server"

analysisMapModule.service("tripEditionService", ["$http", function ($http) {

    this.findTrip = (tripId) => {
        return $http.get(server.getServiceUrl("edition.trip") + tripId)
            .then((response) => response.data)
    }

    this.getStopsByTripId = (tripId) => {
        return $http.get(server.getServiceUrl("trip.stops") + tripId)
            .then((response) => response.data)
    }

    this.canEditTrip = (nodeIdentifier) => {
        return $http.post(server.getServiceUrl("can-edit-trip"), nodeIdentifier)
            .then((response) => response.data)
    }

    this.editTrip = (data) => {
        return $http.post(server.getServiceUrl("edit-trip"), data)
            .then((response) => response.data)
    }

    this.confirmEdition = (token) => {
        return $http.post(server.getServiceUrl("confirm-edition"), token)
            .then((response) => response.data)
    }

    this.getTripStatus = (tripId) => {
        return $http.get(server.getServiceUrl("status.trip") + tripId)
            .then((response) => response.data)
    }

}])
