import editionModule from "./editionModule"

const templateProvider = ["editionService", "$stateParams", (editionService, $stateParams) => {
    return editionService.getEditionTemplate($stateParams.entityType)
}]

const controllerProvider = ["editionService", "$stateParams", (editionService, $stateParams) => {
    return editionService.getEditionController($stateParams.entityType)
}]

editionModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.edition", {
        data: {
            pageTitle: "{{'title.r2.edition' | translate:$stateParams}}"
        },
        url: "/edition/{entityType}/{entityId}",
        templateProvider,
        controllerProvider,
        resolve: {
            entity: ["$stateParams", "editionService", "remoteExceptionHandler", ($stateParams, editionService, remoteExceptionHandler) => {
                return editionService.findEditionEntity($stateParams.entityType, $stateParams.entityId)
                    .catch(remoteExceptionHandler())
            }],
            sourceEntity: ["entity", (entity) => entity]
        }
    })
        .state("r2.create", {
            data: {
                pageTitle: "{{'title.r2.create' | translate:$stateParams}}"
            },
            url: "/new/{entityType}",
            templateProvider,
            controllerProvider,
            resolve: {
                entity: ["$stateParams", "editionService", "remoteExceptionHandler", ($stateParams, editionService, remoteExceptionHandler) => {
                    return editionService.getNewEntity($stateParams.entityType)
                        .catch(remoteExceptionHandler())
                }],
                sourceEntity: ["entity", (entity) => entity]
            }
        })
        .state("r2.copy", {
            data: {
                pageTitle: "{{'title.r2.copy' | translate:$stateParams}}"
            },
            url: "/copy/{entityType}/{entityId}",
            templateProvider,
            controllerProvider,
            resolve: {
                entity: ["$stateParams", "editionService", "remoteExceptionHandler", ($stateParams, editionService, remoteExceptionHandler) => {
                    return editionService.getCopy($stateParams.entityType, $stateParams.entityId)
                        .catch(remoteExceptionHandler())
                }],
                sourceEntity: ["$stateParams", "editionService", "remoteExceptionHandler", ($stateParams, editionService, remoteExceptionHandler) => {
                    return editionService.getNewEntity($stateParams.entityType)
                        .catch(remoteExceptionHandler())
                }]
            }
        })

}])