import orderModule from "./orderModule"
import template from "./orderItemClassificationAttributeEditionModal.html"
import angular from "angular"

orderModule.service("orderItemClassificationAttributeEditionModal", ["$modal", function ($modal) {
    this.show = (orderItem) => {
        return $modal.open({
            template: template,
            controller: ["$scope", ($scope) => {
                $scope.model = {
                    sourceId: orderItem.sourceId,
                    classificationByType: angular.copy(orderItem.classificationByType),
                    attributeByType: angular.copy(orderItem.attributeByType)
                }

                $scope.hasEditableData = () => {
                    return ($scope.model.classificationByType && $scope.model.classificationByType.length) ||
                        ($scope.model.attributeByType && $scope.model.attributeByType.length)
                }
            }]
        }).result
    }
}])