define([
    "./monitoringDriverModule",
], function (module) {
    "use strict";

    return module.controller("MonitoringDriverEditionController", [
        "$scope",
        "monitoringDriver",
        "$state",
        "userManagementService",
        "monitoringDriverService",
        function ($scope, monitoringDriver, $state,  userManagementService, monitoringDriverService) {
            $scope.entity = monitoringDriver || {user: null};

            $scope.users = function () {
                return userManagementService.findAllUsers();
            };

            $scope.back = function () {
                return $state.go("monitoring.driverSearch");
            };

            $scope.formatTag = function (tag) {
                if (tag) {
                    return tag.email;
                }
            };

            $scope.isValid = function () {
                return !!$scope.entity.user && $scope.entity.identifier && $scope.entity.name && $scope.entity.sourceId;
            };

            $scope.save = function () {
                return monitoringDriverService.saveDriver($scope.entity)
                    .then(function (driverId) {
                        $state.go("monitoring.driverEdition", {
                            driverId: driverId
                        });
                    });
            };

            $scope.saveAndContinue = function () {
                return monitoringDriverService.saveDriver($scope.entity)
                    .then(function () {
                         $state.go($state.current, {reload: Date.now()});
                    });
            };

        }
    ]);
});