define([
    "./simulationModule",
    "../arrays/arrays"
], function (module, arrays) {
    "use strict";

    return module.service("simulationService", ["$http", "$q", "$interval", "simulationUrls", function ($http, $q, $interval, simulationUrls) {
        var baseUrl = simulationUrls.baseUrl();
        var statusUrl = simulationUrls.statusUrl();
        var resultUrl = simulationUrls.resultUrl();
        var simulationActions = simulationUrls.simulationActions();

        return {
            "simulate": function (simulationContext) {
                var defer = $q.defer();
                $http.post(baseUrl, simulationContext)
                    .then(getData)
                    .then(function (tokens) {
                        var vehiclesToQuery = Object.keys(tokens);
                        var vehiclesNotDone = Object.keys(tokens);

                        var interval = $interval(function () {
                            arrays.each(vehiclesToQuery, function (vehicle) {
                                queryToken(tokens[vehicle], vehicle);
                            });
                        }, 300);

                        function queryToken(token, vehicle) {
                            $http.get(statusUrl + token)
                                .then(getData)
                                .then(function (isDone) {
                                    if (isDone !== true) {
                                        return;
                                    }

                                    var index = vehiclesToQuery.indexOf(vehicle);
                                    if (index === -1) {
                                        // essa chave ja foi processada
                                        return;
                                    }

                                    arrays.removeAt(vehiclesToQuery, index);
                                    $http.get(resultUrl + token)
                                        .then(getData)
                                        .then(function (data) {
                                            return defer.notify(data);
                                        })
                                        .then(function () {
                                            arrays.removeAt(vehiclesNotDone, vehiclesNotDone.indexOf(vehicle));
                                            if (vehiclesNotDone.length === 0) {
                                                defer.resolve();
                                            }
                                        })
                                        .catch(reject);

                                    // se todos os requests ja foram avaliados, resolve a promesa
                                    if (vehiclesToQuery.length === 0) {
                                        $interval.cancel(interval);
                                    }
                                })
                                .catch(reject);

                            function reject(errorReq) {
                                $interval.cancel(interval);
                                defer.reject(errorReq);
                            }
                        }
                    })
                    .catch(defer.reject);

                return defer.promise;
            },
            /**
             * Retorna uma promessa com um mapa das ações possíveis de simulação e os bundles
             * que dão semântica à quantidade de um produto a ser simulado.
             */
            "getSimulationActions": function getSimulationActions() {
                return $http.get(simulationActions).then(getData);
            }
        };
    }]);

    function getData(req) {
        return req.data;
    }
});