define(["./tdnHelpModule"], function (module) {
    "use strict";

   return module.directive("nlgTdnHelp", [function () {
       return {
           template: "<a target='_blank' class='nlgTdnHelp' ng-attr-href='https://tdn.totvs.com/pages/viewpage.action?pageId={{docId}}#{{subSection || \"\"}}' ng-attr-title='{{title | translate}}'><i class='fa fa-question-circle nlgTdnHelpIcon'></i></a>",
           scope: {
               docId: "@",
               title: "@",
               subSection: "@?",
           }
       };
   }]);
});