define([
        "../emailModule",
        "text!./legendsEmailViewModal.html"],
    function (emailModule, template) {
        "use strict";
        return emailModule.service("legendsEmailModal", [
            "$modal",
            function ( $modal) {
                return function (entity) {
                    return $modal.open({
                        template: template,
                        size: "md",
                        controller: ["$scope", function ($scope) {
                            $scope.entity = entity;
                        }]
                    });
                };
            }]);
    });