define([
    "../occurrencesModule",
    "text!./nlgNewOccurrence.html",
    "front-end-web-commons/app/arrays/arrays",
    "lodash"
], function (monitoringModule, template, arrays, _) {
    "use strict";

    return monitoringModule.directive("nlgNewOccurrence", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                monitorable: "<?",
                onSave: "&",
                onBack: "&"
            },
            controller: [
                "$scope",
                "loadingService",
                "monitoringService",
                "newOccurrenceService",
                "occurrenceService",
                function ($scope, loadingService, monitoringService, newOccurrenceService, occurrenceService) {
                    loadingService(monitoringService.hasPermission("occurrence-creation-status-permission"))
                        .then(function (permission) {
                            $scope.occurrenceCreationStatusPermission = permission;
                        });

                    $scope.occurrence = newOccurrenceService.emptyOccurrence();
                    $scope.occurrenceMonitorables = [];
                    if ($scope.monitorable !== undefined && $scope.monitorable !== null) {
                        $scope.occurrenceMonitorables.push(
                            {
                                monitorable: $scope.monitorable,
                                type: $scope.monitorable.type
                            }
                        );
                    }
                    $scope.valueDelta = newOccurrenceService.ValueDelta();
                    $scope.timeDelta = newOccurrenceService.TimeDelta();
                    $scope.categories = monitoringService.findOccurrenceCategories;
                    $scope.causes = monitoringService.findOccurrenceCauses;
                    $scope.occurrenceStatus = monitoringService.findOccurrenceStatus;
                    $scope.formatOccurrenceStatus = newOccurrenceService.formatOccurrenceStatus;
                    $scope.newComment = {};

                    $scope.causeSelected = function () {
                        $scope.valueDelta = newOccurrenceService.ValueDelta();
                        $scope.timeDelta = newOccurrenceService.TimeDelta();
                        $scope.occurrence.impact.quantityDelta = null;
                        if ($scope.occurrence.cause && !$scope.timeDelta.minutes && !$scope.timeDelta.hours && !$scope.timeDelta.days && !$scope.valueDelta.rawValue) {
                            var category = $scope.occurrence.category;
                            if (category && category.timeDelta) {
                                $scope.timeDelta = newOccurrenceService.TimeDelta($scope.occurrence.cause.defaultImpact.timeDelta);
                            }
                            if (category && category.valueDelta) {
                                $scope.valueDelta = newOccurrenceService.ValueDelta($scope.occurrence.cause.defaultImpact.valueDelta);
                            }
                            if (category && category.quantity) {
                                $scope.occurrence.impact.quantityDelta = $scope.occurrence.cause.defaultImpact.quantityDelta;
                            }
                            /*
                            * Uma vez que o usuário pode deletar as permissões para status de ocorrência via tela de Permissões de entidades,
                             * o status default pode não estar habilitado para este perfil, não podendo ser exibido na tela.
                             * $scope.occurrence.status = $scope.occurrence.cause.defaultStatus;
                             */
                        }
                    };

                    $scope.changeCategory = function () {
                        if (!$scope.occurrence.category) {
                            $scope.occurrence.cause = null;
                            $scope.causeSelected();
                        }
                    };

                    $scope.removeItem = function (index) {
                        arrays.removeAt($scope.occurrenceMonitorables, index);
                    };

                    $scope.addMonitorable = function () {
                        $scope.occurrenceMonitorables.push({
                            monitorable: null,
                            type: null
                        });
                    };

                    $scope.getMonitorableTypes = function () {
                        return monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType")
                            .then(function (values) {
                                return values;
                            });
                    };

                    $scope.getMonitorable = function (value, type) {
                        return monitoringService.findByLikeSourceIdAndTypeActive(value, type).then(function (monitorables) {
                                return arrays.filter(monitorables, function (monitorable) {
                                    var selected = _.map($scope.occurrenceMonitorables, "monitorable");
                                    return !(arrays.contains(selected, monitorable));
                                });
                            }
                        );
                    };

                    $scope.isNewOccurrenceValid = function () {
                        var categoryId = _.get($scope.occurrence, "category.id");
                        const CANCELLED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CANCELLED";
                        var causeCategoryId = _.get($scope.occurrence, "cause.occurrenceCategory.id");
                        var cancelledMonitorables = _.filter($scope.occurrenceMonitorables, function (m) {
                            return m.monitorable !== null && m.monitorable.completion.status === CANCELLED;
                        });
                        var selected = _.map($scope.occurrenceMonitorables, "monitorable");

                        return !(arrays.contains(selected, null)) && $scope.newComment.content && $scope.occurrenceMonitorables.length &&
                            _.isNumber(categoryId) && _.isNumber(causeCategoryId) &&
                            categoryId === causeCategoryId && cancelledMonitorables.length === 0;
                    };

                    function save() {
                        $scope.occurrence.impact.timeDelta = $scope.timeDelta.getValue();
                        $scope.occurrence.impact.valueDelta = $scope.valueDelta.getValue();

                        $scope.occurrenceMonitorableIdentifiers = [];
                        arrays.each($scope.occurrenceMonitorables, function (occurrenceMonitorable) {
                            $scope.occurrenceMonitorableIdentifiers.push(
                                {
                                    "sourceId": occurrenceMonitorable.monitorable.sourceId,
                                    "type": occurrenceMonitorable.monitorable.type
                                });
                        });

                        $scope.occurrence.comments = [];
                        $scope.occurrence.comments.push(
                            {
                                "content": $scope.newComment.content,
                                "timestamp": $scope.occurrence.timestamp,
                                "hasAttachment": !!$scope.newComment.content
                            });

                        $scope.occurrence.status = $scope.newComment.status;
                        $scope.occurrence.statusChangeTimestamp = $scope.occurrence.timestamp;
                        return newOccurrenceService.createOccurrence($scope.occurrence, $scope.occurrenceMonitorableIdentifiers, $scope.newComment.files);
                    }

                    $scope.save = function () {
                        save()
                            .then(function (mbrRestOccurrenceAndCommentIdentifiers) {
                                return $scope.onSave({occurrenceId: mbrRestOccurrenceAndCommentIdentifiers.occurrenceIdentifier.id});
                            });
                    };
                    $scope.saveAndContinue = function () {
                        save()
                            .then(function (mbrRestOccurrenceAndCommentIdentifiers) {
                                $scope.onSave({occurrenceId: mbrRestOccurrenceAndCommentIdentifiers.occurrenceIdentifier.id});
                                return occurrenceService.createNewOccurrence();
                            });
                    };

                    $scope.back = function () {
                        return $scope.onBack();
                    };
                }]
        };
    }]);
});