define(["../trackingModule", "text!./TripAssignView.html"], function (trackingModule, TripAssignView) {
    "use strict";

    return trackingModule.service("tripAssignModal", [
        "$q",
        "loadingService",
        "messagesModal",
        "$modal",
        "trackingMobileService",
        "trackingService",
        "remoteExceptionHandler",
        function ($q, loadingService, messagesModal, $modal, trackingMobileService, trackingService, remoteExceptionHandler) {

            return function (tripId) {
                var promise = $q.all([
                    trackingService.findByMonitorableId(tripId).catch(function () {
                        return null;
                    }),
                    trackingMobileService.tripHasDriver(tripId).catch(function () {
                        return false;
                    })]);

                return loadingService(promise).then(function (response) {
                    var trackableMonitorable = response[0];
                    var hasDriver = response[1];

                    if (trackableMonitorable && hasDriver) {
                        return messagesModal("dialog.warning", {keyBundle: "tracking.trip.modal.alreadyStarted"});
                    }

                    return $modal.open({
                        size: "md",
                        template: TripAssignView,
                        controller: ["$scope", function ($scope) {
                            $scope.alreadyTracked = !!trackableMonitorable;
                            $scope.alreadyHasDriver = !!hasDriver;

                            $scope.tracker = {};

                            $scope.drivers = function (viewValue) {
                                return trackingMobileService.findDrivers(viewValue);
                            };

                            $scope.assign = function () {
                                if (!$scope.alreadyTracked) {
                                    loadingService(trackingMobileService.findTruckDeviceByDriver($scope.tracker.driver))
                                        .catch(function (error) {
                                            if (error && error.status === 400) {
                                                return messagesModal("dialog.error", "trackingMobile.exception.tripDriverAppNotConfigured")
                                                    .then($q.reject);
                                            }
                                            return remoteExceptionHandler()();
                                        })
                                        .then(function (device) {
                                            $scope.tracker.plate = device;
                                            return $scope.$close($scope.tracker);
                                        });
                                } else {
                                    return $scope.$close($scope.tracker);
                                }
                            };

                        }]
                    }).result;
                });
            };
        }]);
});
