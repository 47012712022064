import reportModule from "./reportModule"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"
import xlsx from "../../node_modules/xlsx/xlsx"

reportModule.controller("queryReportController", [
    "$scope",
    "$http",
    "reportService",
    "messagesModal",
    "$q",
    "$parse",
    ($scope, $http, reportService, messagesModal, $q, $parse) => {

    let paginationApi
    let queryData

    $scope.filterService = null

    reportService.findQueryReport().then((reportId) => {
        $scope.filterService = "transactional/report/query/" + reportId
        $scope.scriptId = reportId
    })

    $scope.tableOptions = {
        records: [],
        columns: [],
        pagination: {
            onPaginate: function (firstResult, maxResults) {
                return reportService.executeQuery(firstResult, maxResults, $scope.scriptId, queryData)
                    .then((queryResult) => {
                        if (queryResult.tuples.length === 0) {
                            messagesModal("dialog.success", [{
                                keyBundle: "group.report.query.report.empty.result",
                                parameters: []
                            }])
                        }
                        $scope.tableOptions.columns = getTableDefinition(queryResult.headers)
                        return {
                            firstResult: firstResult,
                            maxResults: maxResults,
                            count: queryResult.count,
                            result: getArrayOfRowValues(queryResult.tuples)
                        }
                    })
            }
        },
        onRegisterPagination: function (api) {
            paginationApi = api
        }
    }

    function getTableDefinition(headers) {
        let columns = []
        arrays.each(headers, function (header, index) {
            columns.push({
                header: header,
                cell: "{{record[" + index + "]}}",
                width: "115px",
                sortBy: "{{" + index + "}}",
                cellName: header
            })
        })
        return columns
    }

    function getArrayOfRowValues(tuples) {
        let results = []
        if (!tuples) {
            return results
        }
        arrays.each(tuples, function(tuple){
            if(tuple){
                results.push(tuple.rowValues)
            }
        })
        return results
    }

    $scope.executeQuery = (selectedFilters) => {
        let selectedFiltersData = {}
        arrays.each(selectedFilters, function (filterField) {
            selectedFiltersData[filterField.fieldName] = filterField.value || filterField.multipleValue
        })
        queryData = selectedFiltersData
        return paginationApi.seekPage(1)
    }

    $scope.exportSpreadsheet = (scriptId) => {
        reportService.exportSpreadsheet(scriptId, queryData).then(
            function (req) {
                const data = req.data
                const fileName = getFileNameFromHeaders(req.headers)
                const sheetName = getSheetNameFromFileName(fileName)
                const translatedHeaders = getArrayOfTranslatedHeaders(data.headers)
                const workbook = xlsx.utils.book_new()
                const splittedSheets = arrays.split(data.tuples, 65535)
                for (let index = 0; index < splittedSheets.length; index++) {
                    generateSheetAndAppendToWorkbook(splittedSheets[index], translatedHeaders, data.projections, sheetName + index, workbook)
                }
                const writingOptions = {
                    type: "buffer",
                    bookType: "biff8",
                    compression: false
                }
                const defer = $q.defer()
                setTimeout(function(){
                    // Executa fora do ciclo de digest
                    xlsx.writeFile(workbook, fileName + ".xls", writingOptions)
                    defer.resolve()
                })
                return defer.promise
            }).catch(function () {
                messagesModal("dialog.warning", [{
                    keyBundle: "spreadsheetExportDialog.failed",
                    parameters: []
                }])
            })
    }

    function getFileNameFromHeaders(headers) {
        const result = headers("content-disposition").split(";")[1].trim().split("=")[1]
        return result.replace(/"/g, "")
    }

    function getSheetNameFromFileName(fileName) {
        if (fileName.length < 29) {
            return fileName
        }
        return fileName.substring(0, 28)
    }

    function getArrayOfTranslatedHeaders(headers) {
        let translatedHeaders = []
        arrays.each(headers, function (header) {
            const formatter = " | translate"
            translatedHeaders.push(format(header, formatter))
        })
        return translatedHeaders
    }

    function format(value, formatter){
        return $parse("'" + value + "'" + formatter)()
    }

    function generateSheetAndAppendToWorkbook(tuples, headers, projections, sheetName, workbook) {
        const sheet = xlsx.utils.aoa_to_sheet([headers])
        xlsx.utils.sheet_add_aoa(sheet, getArrayOfFormattedValues(tuples, headers, projections), {origin: "A2"})
        xlsx.utils.book_append_sheet(workbook, sheet, sheetName)
    }

    function getArrayOfFormattedValues(tuples, headers, projections) {
        let rowValues = getArrayOfRowValues(tuples)
        headers.forEach((header, index) => {
            let formatter = ""
            arrays.each(projections, function (projection) {
                if (projection.name === header) {
                    const rawFormatter = getProjectionFormatter(projection.formatter)
                    formatter = getPipeFormatter(rawFormatter)
                }
            })
            if (formatter !== "") {
                for (let i = 0; i < rowValues.length; i++) {
                    rowValues[i][index] = format(rowValues[i][index], formatter)
                }
            }
        })
        return rowValues
    }

    function getProjectionFormatter(rawFormatter) {
        const initialIndex = rawFormatter.lastIndexOf(".") + 1
        return rawFormatter.substring(initialIndex, rawFormatter.length)
    }

    function getPipeFormatter(formatter) {
        switch (formatter) {
            case "DATETIME":
                return " | date : 'short'"
            case "DATE":
                return " | date : 'dd/MM/yyyy'"
            case "TIME":
                return " | date : 'HH:mm'"
            case "THREE_DECIMALS":
                return " | number : 3"
            case "TWO_DECIMALS":
                return " | number : 2"
            case "TRANSLATE":
                return " | translate"
            case "GROUP_TRANSLATE":
                return " | groupTranslate"
            default:
                return ""
        }
    }
}])
