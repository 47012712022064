define([
    "angular",
    "./chartingModule",
    "c3",
    "d3",
], function (angular, chartingModule, c3, d3) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:pieChart
     * @description
     * Diretiva que exibe um gráfico de pizza.
     * É necessário que o backend mande as colunas com o valor rankeableValue e notRankeableValue para que o gráfico funcionar corretamente.
     * Lembrando que o rankeableValue será o unico valor que poderá ser rankeado.
     *
     *
     * @param {object[]=} pieChart dados a serem representados no gráfico
     *
     * */
    chartingModule.directive("multiBarChart", ["$translate", "chartingService", "chartLocale", function ($translate, chartingService, chartLocale) {
        return {
            restrict: "A",
            scope: {
                context: "=multiBarChart",
                width: "@?",
                height: "@?",
                horizontal: "=?",
                isAlreadyConverted: "<"
            },
            template: "<div><h3 class='chart-title col-xs-12 col-sm-12 col-md-12 col-lg-12' translate>{{context.configs.chartTitle}}</h3><div class='bar-chart'></div></div>",
            link: function ($scope, $element) {

                var properties = $scope.context.configs.properties || $scope.context.configs.chartViewConfig.properties;

                var chartConfig = {
                    bindto: $element.find(".bar-chart")[0],
                    data: {
                        type: "bar",
                        x: "x",
                        columns: [],
                        empty: {
                            label: {
                                text: $translate.instant("charting.notApplicable")
                            }
                        },
                        labels: {
                            format: function (x, y) {
                                if (y === "rankeableValue") {
                                    return chartLocale.createFormatter(properties.rankeableValueTypeFormat)(x);
                                }
                                if (y === "notRankeableValue") {
                                    return chartLocale.createFormatter(properties.notRankeableValueTypeFormat)(x);
                                }
                                return x;
                            },
                        },
                        names: {
                            rankeableValue: $translate.instant(properties.rankeableValueLegend),
                            notRankeableValue: $translate.instant(properties.notRankeableValueLegend)
                        },
                    },
                    legend: {
                        position: "right"
                    },
                    padding: {
                        bottom: 66
                    },
                    tooltip: {
                        position: function (data, width, height, element) {
                            return {
                                top: 0,
                                left: parseInt(element.getAttribute("x")) + parseInt(element.getAttribute("width"))
                            };
                        }
                    },
                    axis: {
                        rotated: $scope.horizontal ? $scope.horizontal : false,
                        x: {
                            type: "category",
                            tick: {
                                multiline: true
                            },
                        },
                        y: {
                            tick: {
                                format: function (x) {
                                    if (angular.isDefined(properties.showOnlyIntegerOnY) && properties.showOnlyIntegerOnY === "true") {
                                        if (x !== Math.floor(x)) {
                                            d3.selectAll(".c3-axis-y g.tick").filter(function () {
                                                var text = d3.select(this).select("text").text();
                                                return +text === x;
                                            }).style("opacity", 0);
                                            return "";
                                        }
                                    }
                                    return chartLocale.formatNumberData(properties.valueTypeA, x);
                                }
                            }
                        }
                    },
                    bar: {
                        width: {
                            ratio: 0.5
                        }
                    },
                };

                var chart = c3.generate(chartConfig);

                $scope.$watch(function () {
                    return $scope.context.data;
                }, function (newData) {
                    chart.load(processData(newData));
                });

                function processData(dataToProcess) {
                    var processedData = {
                        columns: []
                    };
                    if (dataToProcess.length === 0) {
                        processedData.unload = ["x", "values"];
                    }
                    processedData.columns = configureMultiBarChart(dataToProcess);
                    return processedData;
                }

                function configureMultiBarChart(dataToProcess) {
                    if (dataToProcess.length === 0) {
                        return [];
                    }
                    var result = {};
                    sortData(dataToProcess);
                    var ranked = chartingService.rankingMultiBarChart(dataToProcess, properties.rank);
                    Object.keys(ranked[0]).forEach(function (data) {
                        result[data] = [data];
                    });
                    ranked.forEach(function (data) {
                        Object.keys(result).forEach(function (key) {
                            var currentResult = result[key];
                            var currentData = data[key];
                            if (currentData) {
                                currentResult.push(currentData);
                            }
                        });
                    });
                    return convertData(result);

                }

                function convertData(data) {
                    return Object.keys(data).map(function (label) {
                        var slicedData = data[label].slice(1, data.length);
                        if (label !== "x") {
                            return [label].concat(slicedData);
                        }
                        return [label].concat(slicedData);
                    });
                }

                function sortData(dataToProcess) {
                    dataToProcess.sort(function (a, b) {
                        if (a.rankeableValue < b.rankeableValue) {
                            return 1;
                        }
                        if (a.rankeableValue === b.rankeableValue) {
                            return b.notRankeableValue - a.notRankeableValue;
                        }
                        return -1;
                    });
                }
            }
        };
    }]);
});
