define([
    "./dashboardModule",
    "text!./dashboardPresentation.html",
    "angular",
], function (dashboardModule, dashboardPresentationTemplate, angular) {
    "use strict";

    dashboardModule.directive("dashboardPresentation", ["$window", "$interval", function ($window, $interval) {
        return {
            "restrict": "E",
            "require": "^dashboard",
            "template": dashboardPresentationTemplate,
            link: function (scope) {
                if (!scope.presentationConfiguration) {
                    return;
                }
                scope.model = {};
                scope.presentationConfiguration.refreshTime = scope.presentationConfiguration.refreshTime ? scope.presentationConfiguration.refreshTime : 60;
                scope.model.isFullscreen = false;
                var refilterInterval = null;
                var parentId = "#" + scope.presentationConfiguration.targetIdFullScreen;

                var window = angular.element($window);
                window.bind("resize", function () {
                    scope.$apply();
                });

                scope.$watch(function () {
                    return $window.innerHeight;
                }, function (value) {
                    if (value === screen.height) {
                        scope.model.isFullscreen = true;
                        scope.initFullScreen();
                    } else {
                        scope.model.isFullscreen = false;
                        scope.initFullScreen();
                    }
                }, true);

                scope.initFullScreen = function () {
                    $interval.cancel(refilterInterval);
                    if (scope.model.isFullscreen) {
                        document.documentElement.requestFullscreen().catch(function () {
                        });
                        document.body.classList.add("fullscreen");
                        angular.element(parentId).addClass("fullscreenDashboard");
                        var refreshTime = scope.presentationConfiguration.refreshTime * 1000;
                        refilterInterval = $interval(autoRefresh, refreshTime);
                    } else {
                        document.exitFullscreen().catch(function () {
                        });
                        document.body.classList.remove("fullscreen");
                        angular.element(parentId).removeClass("fullscreenDashboard");
                    }
                };

                scope.$on("$destroy", function () {
                    $interval.cancel(refilterInterval);
                });

                function autoRefresh() {
                    scope.applyFilter();
                }

            }
        };
    }]);
});
