define(["./menuModule", "angular"], function (menuModule, angular) {
    menuModule.controller("menuController", ["$scope", "$state", "$stateParams", "authorizationService", "menuCommonsService", function ($scope, $state, $stateParams, authorizationService, menuCommonsService) {

        $scope.model = {searchText: ""};
        authorizationService.getAuthorizedMenus().then(function (menus) {
            $scope.userMenus = menus;
            $scope.$watch("userMenus", function (menus) {
                $scope.userMenus = menus;
                $scope.filteredMenu = menus;
            });
        });

        $scope.filterMenuItems = function () {
            if ($scope.model.searchText && $scope.model.searchText.length > 2) {
                $scope.filteredMenu = menuCommonsService.filterMenu(angular.copy($scope.userMenus), "bundle", $scope.model.searchText);
                return;
            }
            $scope.filteredMenu = angular.copy($scope.userMenus);
        };

        $scope.getFilteredMenu = function () {
            return $scope.filteredMenu;
        };

        $scope.isMenuActive = function (menu) {
            return menuCommonsService.isMenuActive(menu, $scope.isSubMenuActive);
        };

        $scope.isSubMenuActive = function (subMenu) {
            return $state.includes(subMenu.state, subMenu.stateParams);
        };

        $scope.hasMenuItems = function (menuItems) {
            return menuCommonsService.hasMenuItems(menuItems);
        };

        $scope.toggleSideMenu = function () {
            $scope.menuClosed = !$scope.menuClosed;
        };

        $scope.toggleTopLevelMenu = function (menu) {
            var newState = !menu.opened;
            closeAllMenus();
            menu.opened = newState;
        };

        function closeAllMenus() {
            $scope.filteredMenu.forEach(function (menuItem) {
                    menuItem.opened = false;
                }
            );
        }

        $scope.closeAllMenus = function closeAll() {
            cleanFilter();
            closeAllMenus();
        };

        function cleanFilter() {
            $scope.model.searchText = "";
            $scope.filterMenuItems();
        }

        /**
         * Retorna a url a ser direcionada pelo item de menu, considerando estados vazios.
         * https://github.com/angular-ui/ui-router/issues/371
         */
        $scope.getHref = function (subMenu) {
            if (!subMenu.state) {
                return "";
            }
            return $state.href(subMenu.state, subMenu.stateParams || {});
        };
    }]);
});