define(["./threeModule", "three", "./threeVehicleWithNeckMeshFactory", "./threeAxleMeshFactory"], function (threeModule, THREE) {
    "use strict";

    var DEGRES_90 = Math.PI / 2;

    return threeModule.service("threeVehicleMeshFactory", ["Object3DInfo", "threeVehicleWithNeckMeshFactory", "threeAxleMeshFactory", function (Object3DInfo, threeVehicleWithNeckMeshFactory, threeAxleMeshFactory) {
        var self = this;

        self.createMesh = function (object) {
            if(hasNeck(object, Object3DInfo)){
                return threeVehicleWithNeckMeshFactory.createMesh(object);
            }

            var width = object.dimensions.width;
            var height = object.dimensions.height;
            var depth = object.dimensions.length;

            var vehicle = new THREE.Object3D();
            vehicle.rotation.y = DEGRES_90;
            // para exibir os contornos
            vehicle.geometry = new THREE.BoxGeometry(width, height, depth);

            // contêiner sem pescoço
            var floor = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, depth), new THREE.MeshLambertMaterial({
                color: 0x603311,
                side: THREE.DoubleSide
            }));
            vehicle.add(floor);
            floor.rotation.x = DEGRES_90;
            floor.position.y = -height / 2;

            addSupport(vehicle, object);
            threeAxleMeshFactory.drawAxles(vehicle, object);

            return vehicle;
        };

        function addSupport(vehicle, object) {
            var width = object.dimensions.width,
                height = object.dimensions.height,
                depth = object.dimensions.length,
                rearSupportHeight = object.extraInfo[Object3DInfo.REAR_SUPPORT_HEIGHT],
                frontalSupportHeight = object.extraInfo[Object3DInfo.FRONT_SUPPORT_HEIGHT],
                sideSupportHeight = object.extraInfo[Object3DInfo.SIDE_SUPPORT_HEIGHT];

            var supportMaterial = new THREE.MeshBasicMaterial({
                color: 0x603311,
                opacity: 0.5,
                transparent: true,
                side: THREE.DoubleSide
            });

            var rearSupport = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, rearSupportHeight), supportMaterial);
            vehicle.add(rearSupport);
            rearSupport.position.z = depth / 2;
            rearSupport.position.y = (rearSupportHeight - height) / 2;

            var frontSupport = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, frontalSupportHeight), supportMaterial);
            vehicle.add(frontSupport);
            frontSupport.position.z = -depth / 2;
            frontSupport.position.y = (frontalSupportHeight - height) / 2;

            var leftSideSupport = new THREE.Mesh(new THREE.PlaneBufferGeometry(depth, sideSupportHeight), supportMaterial);
            vehicle.add(leftSideSupport);
            leftSideSupport.rotation.y = DEGRES_90;
            leftSideSupport.position.x = width / 2;
            leftSideSupport.position.y = (sideSupportHeight - height) / 2;

            var rightSideSupport = leftSideSupport.clone();
            vehicle.add(rightSideSupport);
            rightSideSupport.position.x = -width / 2;
        }

        function hasNeck(object) {
            return object.extraInfo[Object3DInfo.NECK_DIMENSIONS].length;
        }

        return self;
    }]);
});