define(["./nlgOperationOverviewModule"], function (nlgOperationOverviewModule) {
    "use strict";

    return nlgOperationOverviewModule.service("nlgOperationOverviewService", [
        "$http",
        "remoteExceptionHandler",
        "nlgHealthCheckerService",
        "pathService",
        function ($http, remoteExceptionHandler, nlgHealthCheckerService, pathService) {
            var servicePath = pathService.getPath("operation-overview");
            return {
                getHealthInfos: function (instance, checkerName, configurations) {
                    return nlgHealthCheckerService.getHealthInfos(instance, checkerName, configurations);
                },
                getChecker: function () {
                    return $http.get(servicePath).catch(remoteExceptionHandler());
                },
                getCheckerViews: function () {
                    return $http.get(servicePath + "/views").catch(remoteExceptionHandler());
                },
                updateChecker: function (newChecker) {
                    return $http.post(servicePath, newChecker).catch(remoteExceptionHandler());
                }
            };
        }]);
});

