define([
    "./occurrencesModule",
    "text!./search/MonitoringOccurrenceSearch.html",
    "text!./edition/MonitoringOccurrenceEditionView.html",
], function (module, monitoringOccurrenceSearchTemplate, monitoringOccurrenceEditionTemplate) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.monitoringOccurrenceSearch", {
                url: "/occurrence?reload",
                controller: "MonitoringOccurrenceSearchController",
                template: monitoringOccurrenceSearchTemplate,
                resolve: {
                    tableDynamicDefinition: ["monitoringService", function (monitoringService) {
                        return monitoringService.getTableDynamicDefinition("OCCURRENCE")
                    }],
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.monitoring.occurrence",
                    pageTitle: "{{'title.monitoring.monitoringOccurrenceSearch' | translate:$stateParams}}"
                }
            })
            .state("monitoring.monitoringOccurrenceEdition", {
                url: "/occurrence/:occurrenceId",
                controller: "MonitoringOccurrenceEditionController",
                template: monitoringOccurrenceEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence",
                    pageTitle: "{{'title.monitoring.monitoringOccurrenceEdition' | translate:$stateParams}}"
                },
                resolve: {
                    occurrence: ["$stateParams", "occurrenceService", function ($stateParams, occurrenceService) {
                        return occurrenceService.findById($stateParams.occurrenceId);
                    }]
                }
            })
            .state("monitoringViewOccurrence", {
                url: "/occurrence/print/:occurrenceId",
                controller: "MonitoringOccurrenceEditionController",
                template: monitoringOccurrenceEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence",
                    printOnLoad: true,
                    pageTitle: "{{'title.monitoringViewOccurrence' | translate:$stateParams}}"
                },
                resolve: {
                    occurrence: ["$stateParams", "occurrenceService", function ($stateParams, occurrenceService) {
                        return occurrenceService.findById($stateParams.occurrenceId);
                    }]
                }
            });
    }]);
});
