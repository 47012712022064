define([
    "angular",
    "angular-ui-router",
    "../remote/remote"
], function (angular) {
    "use strict";

    return angular.module("monitorModule", [
        "ui.router",
        "remoteModule"
    ]);
});