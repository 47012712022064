import orderModule from "./orderModule"

orderModule.controller("orderController", ["$scope", "sourceEntity", "entity", "entityEditionService", "autocompleteFactory", "messagesModal", "orderItemClassificationAttributeEditionModal", "orderService", function ($scope, sourceEntity, entity, entityEditionService, autocompleteFactory, messagesModal, orderItemClassificationAttributeEditionModal, orderService) {
    $scope.entity = entity
    const updateService = entityEditionService.crudHelper("order", sourceEntity)
    let save = updateService.save.bind(updateService, entity)
    let saveAndContinue = updateService.save.bind(updateService, entity, true)


    $scope.save = function () {
        let absentTimeWindowInfoMessages = getAbsentTimeWindowInfoMessages()
        let savePromise = save()
        showErrorMessages(absentTimeWindowInfoMessages, savePromise)
    }

    $scope.saveAndContinue = function () {
        let absentTimeWindowInfoMessages = getAbsentTimeWindowInfoMessages()
        let saveAndContinuePromise = saveAndContinue()
        showErrorMessages(absentTimeWindowInfoMessages, saveAndContinuePromise)
    }

    function getAbsentTimeWindowInfoMessages() {
        let absentTimeWindowInfoMessages = []
        if (!$scope.entity.pickUpWindowEnd) {
            absentTimeWindowInfoMessages.push("order.edition.nullPickUpWindowEnd")
        }
        if (!$scope.entity.deliveryWindowStart) {
            absentTimeWindowInfoMessages.push("order.edition.nullDeliveryWindowStart")
        }
        return absentTimeWindowInfoMessages
    }

    function showErrorMessages(absentTimeWindowInfoMessages, saveAndContinuePromise) {
        if (absentTimeWindowInfoMessages.length) {
            saveAndContinuePromise.then(() => messagesModal("order.edition.absentTimeWindowInfo", absentTimeWindowInfoMessages))
        }
    }

    if (!$scope.entity.code) {
        $scope.entity.code = $scope.entity.nextSourceId
    }
    // Se houver itens sem sourceId, é uma cópia
    if ($scope.entity.orderItems.length > 0 && !$scope.entity.orderItems[0].sourceId) {
        $scope.entity.orderItems.forEach((orderItem, i) => $scope.entity.orderItems[i].sourceId = generateOrderItemSourceId(i + 1))
    }

    $scope.getDestinations = autocompleteFactory.lazyLoader("locality", [], {
        logisticRoles: ["DESTINATION", "BOTH"]
    })
    $scope.getOrigins = autocompleteFactory.lazyLoader("locality", [], {
        logisticRoles: ["ORIGIN", "BOTH"]
    })

    $scope.getOrderTypes = autocompleteFactory.lazyLoader("orderType")

    $scope.getProducts = autocompleteFactory.lazyLoader("product")

    $scope.createOrderItem = function () {
        return orderService.newOrderItem()
            .then(orderItem => {
                orderItem.sourceId = generateOrderItemSourceId()
                return orderItem
            })
    }

    function generateOrderItemSourceId() {
        return $scope.entity.code + "/" + ($scope.entity.orderItems.length + 1)
    }

    $scope.openOrderItemModal = (orderItem) => {
        orderItemClassificationAttributeEditionModal.show(orderItem)
            .then(function (result) {
                orderItem.classificationByType = result.classificationByType
                orderItem.attributeByType = result.attributeByType
            })
    }

}])