import reportModule from "./reportModule"
import server from "../server"

reportModule.service("reportService", ["$http", "loadingService", function ($http, loadingService) {

    this.findQueryReport = () => {
        return $http.get(server.getServiceUrl("query-report") + "id").then((response) => response.data)
    }

    this.exportSpreadsheet = (scriptId, data) => {
        const url = server.getServiceUrl("query-report") + "spreadsheet/" + scriptId
        return loadingService($http.post(url, data))
    }

    this.executeQuery = (firstResult, maxResults, scriptId, data) => {
        return $http.post(server.getServiceUrl("query-report") + "execute/" + scriptId, data, {
            params: {
                "firstResult": firstResult,
                "maxResults": maxResults
            }
        }).then((response) => response.data)
    }
}])