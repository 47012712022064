define([
    "./genericParamsModule",
    "angular",
    "text!./transferableGenericParam.html",
    "text!./editTransferConflict.html",
    "text!./stopEditingConfirmModal.html",
    "lodash",
    "../objects/objects",
    "../jsonpatches/jsonpatches",
    "../arrays/arrays",
], function (crudModule, angular, template, template2, template3, _, objects, jsonpatches, arrays) {
    "use strict";

    var ESCAPE_FILTER = "%%%";

    return crudModule.directive("transferableGenericParam", [
        "filterServiceFactory",
        "filterDomain",
        "remoteExceptionHandler",
        "genericParamDataService",
        "loadingService",
        "messagesModal",
        "$modal",
        "$q",
        "EntrySetMap",
        "transferableGenericParamService",
        "genericParamsUrls",
        "genericParamAutocompleteService",
        function (filterServiceFactory, filterDomain, remoteExceptionHandler, genericParamDataService, loadingService, messagesModal, $modal, $q, EntrySetMap, transferableGenericParamService, genericParamsUrls, genericParamAutocompleteService) {
            return {
                restrict: "E",
                template: template,
                scope: {
                    definition: "<",
                    metaInf: "=",
                    onRegisterApi: "&?",
                    domain: "@?",
                    filterService: "=?"
                },
                controller: ["$scope", function ($scope) {
                    var hashComparator = transferableGenericParamService.hashComparator;
                    var hashTransferable = transferableGenericParamService.hashTransferable;
                    var isEditing = false;
                    var filterService = $scope.filterService || filterServiceFactory($scope.domain || filterDomain.getDefaultDomain());

                    var MAX_RESULTS = 1000;
                    var dataByTransferable = {
                        map: createEmptyObject(),
                        clear: function () {
                            this.map = createEmptyObject();
                        },
                        get: function (transferable) {
                            return this.map[hashTransferable(transferable)];
                        },
                        update: function (transferable, tuple) {
                            if (!this.map[hashTransferable(transferable)]) {
                                this.map[hashTransferable(transferable)] = [];
                            }
                            this.map[hashTransferable(transferable)].push(tuple);
                        }
                    };

                    var justTransfered = []; //transferables preenchidos ao filtrar

                    $scope.filtered = false;

                    $scope.allTransferables = [];
                    $scope.data = {
                        fixedByComponent: {},
                        transferedEntities: []
                    };
                    $scope.sync = {
                        apply: applySync,
                        syncing: false
                    };

                    $scope.isDisabled = function (componentName) {
                        var disableFunction = $scope.disableFunctions[componentName];
                        if (disableFunction) {
                            return disableFunction();
                        }
                        return false;
                    };
                    $scope.onChange = function (componentName, newComponentValue, oldComponentValue) {
                        var onChangeFunction = $scope.onChangeFunctions[componentName];
                        if (onChangeFunction) {
                            onChangeFunction();
                        }
                        if (angular.isArray(oldComponentValue)) {
                            if (oldComponentValue.length !== newComponentValue.length) {
                                handleStopEditing(componentName, oldComponentValue);
                            }
                        } else if (oldComponentValue && !newComponentValue) {
                            handleStopEditing(componentName, oldComponentValue);
                        }
                    };

                    function initFunctions() {
                        $scope.disableFunctions = {};
                        $scope.onChangeFunctions = {};
                        arrays.each($scope.metaInf.fixedComponents, function (fixedComponent) {
                            var componentName = fixedComponent.name;
                            getComponent(componentName)
                                .then(function (component) {
                                    var metadata = component.metadata;
                                    if (metadata && metadata.dependencyInfo) {
                                        $scope.disableFunctions[componentName] = function () {
                                            var dependencyInfoValue = $scope.data.fixedByComponent[metadata.dependencyInfo];
                                            return (dependencyInfoValue && dependencyInfoValue.length === 0);
                                        };
                                        $scope.onChangeFunctions[metadata.dependencyInfo] = function () {
                                            $scope.data.fixedByComponent[componentName] = [];
                                        };
                                    }
                                });
                        });
                    }

                    $scope.setTransferableComponent = function (transferableComponent) {
                        function doSetTransferableComponent(transferableComponent) {
                            $scope.filtered = false;
                            $scope.data.transferedEntities = [];
                            delete $scope.data.fixedByComponent[transferableComponent.name];
                            $scope.metaInf.possibleTransferableComponents = _.differenceWith($scope.metaInf.possibleTransferableComponents, [transferableComponent], angular.equals);
                            $scope.metaInf.fixedComponents = _.differenceWith($scope.metaInf.fixedComponents, [transferableComponent], angular.equals);
                            $scope.metaInf.possibleTransferableComponents.push($scope.metaInf.transferableComponent);
                            $scope.metaInf.fixedComponents.push($scope.metaInf.transferableComponent);
                            $scope.metaInf.transferableComponent = transferableComponent;
                            initFunctions();
                        }

                        if (isEditing) {
                            openStopEditingModal()
                                .then(function () {
                                    stopEdition();
                                    doSetTransferableComponent(transferableComponent);
                                });
                        } else {
                            doSetTransferableComponent(transferableComponent);
                        }
                    };

                    $scope.isSelectedDisabled = function (selectedOption) {
                        var notEditableData = getNotEditableDate(selectedOption);
                        return notEditableData ? notEditableData.editable : true;
                    };

                    $scope.trimUndescores = function (string) {
                        return string.replace(/_/g, " ");
                    };

                    $scope.save = function () {
                        var transferables = _.filter($scope.data.transferedEntities, function (transferable) {
                            return isEditable(transferable);
                        });

                        // cálculo de quais entidades foram para o painel de disponíveis
                        var transferedEntitiesIds = arrays.map(transferables, function (entity) {
                            return entity.id;
                        });
                        var deletedTransferableEntity = _.uniqBy(
                            _.filter(justTransfered, function (element) {
                                return !arrays.contains(transferedEntitiesIds, element.id);
                            }),
                            function (entity) {
                                return entity.id;
                            });

                        transferables = transferables.map(function (transferable) {
                            return {
                                "@type": $scope.metaInf.transferableComponent.type,
                                "value": transferable
                            };
                        });
                        var toDelete = [];
                        deletedTransferableEntity.forEach(function (entity) {
                            var genericParamData = dataByTransferable.get(entity);
                            if (genericParamData && genericParamData.length) {
                                genericParamData.forEach(function (data) {
                                    if (!arrays.contains(toDelete, data)) {
                                        toDelete.push(data);
                                    }
                                });
                            }
                        });
                        var transferDto = {
                            "fixedAxes": [],
                            "transferableAxis": {
                                "componentName": $scope.metaInf.transferableComponent.name,
                                "transferables": transferables
                            },
                            "toDelete": arrays.map(toDelete, function (dataToDelete) {
                                return dataToDelete.id;
                            })
                        };
                        $scope.metaInf.fixedComponents.forEach(function (component) {
                            var entities = $scope.data.fixedByComponent[component.name];
                            transferDto.fixedAxes.push({
                                componentName: component.name,
                                terms: entities.map(function (value) {
                                    return {
                                        "@type": component.type,
                                        "value": value
                                    };
                                })
                            });
                        });
                        var toCreate = transferableGenericParamService.generateCreatedData(transferables, transferDto, dataByTransferable);
                        if (toDelete.length === 0 && toCreate.length === 0) {
                            messagesModal("dialog.warning", [{
                                keyBundle: "entity.validator.noModification",
                                parameters: []
                            }]);
                        } else {
                            transferableGenericParamService.openConfirmationModal(transferDto, toDelete, toCreate)
                                .then(function () {
                                    loadingService(genericParamDataService.transfer($scope.definition, transferDto))
                                        .then(function () {
                                            messagesModal("dialog.success", [{
                                                keyBundle: "edition.genericParameters.success.save",
                                                parameters: [affectedRecordsLength(), $scope.definition.sourceId]
                                            }]).finally(function () {
                                                stopEdition();
                                                $scope.sync.apply();
                                            });
                                        }).catch(remoteExceptionHandler());
                                });
                        }

                        function affectedRecordsLength() {
                            return transferDto.toDelete.length + transferDto.transferableAxis.transferables.length;
                        }
                    };

                    function isEditable(transferable) {
                        var notEditableData = getNotEditableDate(transferable);
                        return notEditableData ? !notEditableData.editable : true;
                    }

                    function getNotEditableDate(transferable) {
                        var genericParamData = dataByTransferable.get(transferable);
                        if (genericParamData) {
                            return _.find(genericParamData, function (data) {
                                return !data.editable;
                            });
                        }
                    }

                    $scope.scheduleDeletion = function () {
                        $scope.recordEdition();
                    };

                    $scope.recordEdition = function () {
                        isEditing = true;
                    };

                    $scope.editing = function () {
                        return isEditing;
                    };

                    function stopEdition() {
                        isEditing = false;
                    }

                    $scope.getTags = function (componentName, searchValue) {
                        return filterComponentValues(componentName, searchValue);
                    };

                    $scope.$watch(function () {
                        return $scope.metaInf.transferableComponent;
                    }, function () {
                        initFunctions();
                        filterComponentValues($scope.metaInf.transferableComponent.name, ESCAPE_FILTER, MAX_RESULTS).then(function (allTransferables) {
                            $scope.allTransferables = allTransferables;
                        });
                    }, true);

                    function applySync() {
                        function doSync() {
                            $scope.sync.syncing = true;
                            var filterServiceUrl = genericParamsUrls.genericParamDataUrl + "/" + $scope.definition.id;
                            filterService.doFilter(filterServiceUrl, calculatePatches())
                                .then(function (response) {
                                    dataByTransferable.clear();

                                    $scope.filtered = true;

                                    var filterResult = response.data.result.filter(function (data) {
                                        return getTransferableComponent(data) !== null;
                                    });

                                    justTransfered = filterResult.map(function convert(tuple) {
                                        var transferable = getTransferableComponent(tuple);
                                        dataByTransferable.update(transferable, tuple);
                                        return transferable;
                                    });
                                    var transferedEntities = $scope.data.transferedEntities;
                                    $scope.data.transferedEntities = _.unionBy(justTransfered, transferedEntities, hashTransferable);

                                    var transferablesByFixedKey = new EntrySetMap();
                                    filterResult.forEach(function (tuple) {
                                        var fixedKey = {};
                                        $scope.metaInf.fixedComponents.forEach(function (fixedComponent) {
                                            fixedKey[fixedComponent.name] = hashTransferable(tuple.properties[fixedComponent.name]);
                                        });
                                        var transferables = transferablesByFixedKey.getOrPut(fixedKey, []);
                                        transferables.push(getTransferableComponent(tuple));
                                    });

                                    var commonlySelected = angular.copy($scope.data.transferedEntities);
                                    transferablesByFixedKey.entrySet.forEach(function (entry) {
                                        commonlySelected = _.intersectionWith(commonlySelected, entry.value, hashComparator);
                                    });

                                    var conflictedData = _.differenceWith($scope.data.transferedEntities, commonlySelected, hashComparator);
                                    if (conflictedData.length) {
                                        $modal.open({
                                            backdrop: "static",
                                            keyboard: false,
                                            template: template2,
                                            controller: ["$scope", function (modalScope) {
                                                modalScope.conflictedData = conflictedData;
                                                modalScope.bulkSelection = [];
                                            }]
                                        }).result.then(function (bulkSelection) {
                                            $scope.scheduleDeletion();
                                            $scope.data.transferedEntities = _.unionWith(commonlySelected, bulkSelection, hashComparator);
                                        }).catch(function () {
                                            $scope.data.transferedEntities = [];
                                        });
                                    }
                                    $scope.sync.syncing = false;
                                })
                                .catch(remoteExceptionHandler());
                        }

                        if (isEditing) {
                            openStopEditingModal()
                                .then(function () {
                                    doSync();
                                }).then(function () {
                                stopEdition();
                                $scope.data.transferedEntities = [];
                            });
                        } else {
                            doSync();
                        }
                    }

                    if ($scope.onRegisterApi) {
                        $scope.onRegisterApi({
                            api: {
                                isEditing: $scope.editing
                            }
                        });
                    }

                    function getTransferableComponent(tuple) {
                        return tuple.properties[$scope.metaInf.transferableComponent.name];
                    }

                    function handleStopEditing(componentName, oldValue) {
                        if (!isEditing) {
                            $scope.data.transferedEntities = [];
                            $scope.filtered = false;
                            return;
                        }
                        openStopEditingModal()
                            .then(function () {
                                $scope.data.transferedEntities = [];
                                stopEdition();
                                $scope.filtered = false;
                            }).catch(function () {
                            $scope.data.fixedByComponent[componentName] = oldValue;
                        });
                    }

                    function openStopEditingModal() {
                        return $modal.open({
                            template: template3
                        }).result;
                    }

                    function filterComponentValues(componentName, viewValue) {
                        return getComponent(componentName).then(function (component) {
                            return genericParamAutocompleteService(component.metadata, viewValue);
                        });
                    }

                    var componentsByNameDefer = $q.defer();
                    genericParamDataService.getComponents($scope.definition)
                        .then(function (response) {
                            var componentsByName = {};
                            $scope.components = response.components;
                            response.components.forEach(function (component) {
                                componentsByName[component.name] = component;
                            });
                            componentsByNameDefer.resolve(componentsByName);
                        });

                    function getComponent(componentName) {
                        return componentsByNameDefer.promise.then(function (componentsByName) {
                            return componentsByName[componentName];
                        });
                    }

                    function calculatePatches() {
                        var originalTemplate = {
                            template: {}
                        };
                        var modifiedTemplate = {
                            template: {}
                        };
                        $scope.metaInf.fixedComponents.forEach(function (fixedComponent) {
                            originalTemplate.template[fixedComponent.name] = {
                                value: null,
                                operation: null
                            };

                            var value = $scope.data.fixedByComponent[fixedComponent.name];
                            if (value.length) {
                                modifiedTemplate.template[fixedComponent.name] = {
                                    multipleValue: value,
                                    operation: "br.com.neolog.service.crud.filter.FilterOperation.IN"
                                };
                            } else {
                                modifiedTemplate.template[fixedComponent.name] = {
                                    value: null,
                                    operation: "br.com.neolog.service.crud.filter.FilterOperation.EQUAL"
                                };
                            }
                        });
                        return jsonpatches.compare(originalTemplate, modifiedTemplate);
                    }
                }]
            };
        }]);

    function createEmptyObject() {
        return Object.create(null);
    }
});