import carrierZoneModule from "./carrierZoneModule"
import carrierZoneSpeedView from "./speed/carrier-zone-speed.html"
import carrierZoneOperationView from "./operation/carrier-zone-operation.html"
import carrierZoneMaxValueView from "./max-value/carrier-zone-max-value-view.html"
import carrierZoneBlockedVehicleView from "./blocked-vehicles/carrier-zone-blocked-vehicles-view.html"

carrierZoneModule.config(["editionServiceProvider", (editionServiceProvider) =>{
    editionServiceProvider.registerEdition("carrier-zone-speed", carrierZoneSpeedView, "carrierZoneSpeedController")
    editionServiceProvider.registerEdition("carrier-zone-operation", carrierZoneOperationView, "carrierZoneOperationController")
    editionServiceProvider.registerEdition("carrier-zone-max-value", carrierZoneMaxValueView, "carrierZoneMaxValueController")
    editionServiceProvider.registerEdition("carrier-zone-blocked-vehicles", carrierZoneBlockedVehicleView, "carrierZoneBlockedVehicleController")
}])