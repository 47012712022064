define([
    "./chartingModule",
    "text!./stackedBarChart.html",
    "d3",
    "c3",
    "angular",
    "../arrays/arrays",
    "lodash",
], function (chartingModule, template, d3, c3, angular, arrays, _) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:stackedBarChart
     * @description
     * Diretiva que exibe um gráfico de barras empilhadas
     *
     * @param {object[]=} stackedBarChart dados a serem representados no gráfico
     * @param {number} [height] a altura do gráfico.
     * @param {number} [width] a largura do gráfico.
     * @param {boolean} [rotate] se o gráfico deve ser rotacionado. Valor padrão é false.
     * @param {boolean} [proportional] flag para definir o modo de exibição. Se true, as barras do gráfico vão ter o mesmo tamanho. Valor padrão é false.
     * @param {boolean} [absolute] flag para exibição dos valores. Se true mostrará os valores absolutos para determinado identifier, ou seja sem formatação. Valor padrão é false.
     * @param {string} [translate] define a estratégia de tradução de labels. Valores válidos são:
     *     <ul>
     *     <li><i>bundle</i>: utiliza os arquivos de bundle (formato do bundle: 'charting.nameLabel.stackedBar.<valor>');</li>
     *     <li><i>legend</i>: utiliza as propriedades 'legend' para tradução (formato da legenda: 'label.legend.<valor>');</li>
     *     </ul>
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div stacked-bar-chart="stackedBarChartInput"></div>
     *
     *            <div stacked-bar-chart="stackedBarChartInput" absolute="true"></div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     angular.module("FrontEndWeb").controller("example", function ($scope) {
                   $scope.stackedBarChartInput = (function () {
                       return {
                           configs: {
                               chartTitle: "The Chart Title",
                               type: "bar"
                           },
                           data: [
                               {
                                   identifier:"Identifier1",
                                   label:"label1",
                                   value:1234
                               },
                               {
                                   identifier:"Identifier1",
                                   label:"label2",
                                   value:858
                               },
                               {
                                   identifier:"Identifier2",
                                   label:"label1",
                                   value:854
                               },
                               {
                                   identifier:"Identifier2",
                                   label:"label2",
                                   value:291
                               },
                               {
                                   identifier:"Identifier3",
                                   label:"label1",
                                   value:573
                               },
                               {
                                   identifier:"Identifier3",
                                   label:"label2",
                                   value:793
                               }
                           ]
                       }
                   }());
              });
     </file>
     * </example>
     * */
    chartingModule.directive("stackedBarChart", ["$translate", "chartLocale", function ($translate, chartLocale) {
        return {
            restrict: "A",
            scope: {
                context: "=stackedBarChart",
                absolute: "=?"
            },
            template: template,
            link: function ($scope, $element, attrs) {
                $scope.absolute = $scope.absolute ? $scope.absolute : false;

                var properties = $scope.context.configs.properties || $scope.context.configs.chartViewConfig.properties;

                var translationBehaviour = {
                    bundle: function (label) {
                        return $translate.instant("charting.nameLabel.stackedBar." + label);
                    },
                    legend: function (label) {
                        return $scope.context.configs.chartViewConfig.legendTitles["label.legend." + label];
                    }
                };

                var translateLabelFunction = function (label) {
                    if (!properties.translate) {
                        return label;
                    }
                    return translationBehaviour[properties.translate](label);
                };

                $scope.typeIdentifier = properties.typeIdentifier ? properties.typeIdentifier : undefined;

                $scope.dataLabelFunction = $scope.absolute ? function (value) {
                    if (properties.valueType === "decimal") {
                        return chartLocale.formatDecimal(value);
                    }
                    if (properties.valueType === "currency") {
                        return chartLocale.formatCurrency(value);
                    }
                    return value;
                } : d3.format("0.2%");

                if (!angular.isDefined(attrs.hideTitle)) {
                    $element.find("h3.chart-title").text($scope.context.configs.chartTitle);
                }

                var identifierBehavior = {
                    timeseries: {
                        getIdentifierFormat: function () {
                            return function (date) {
                                if (date.getMonth() + 1 < 10) {
                                    return "0" + (date.getMonth() + 1) + "/" + date.getFullYear();
                                } else {
                                    return (date.getMonth() + 1) + "/" + date.getFullYear();
                                }
                            };
                        },
                        formatIdenfitierValue: function (data) {
                            var date;
                            // Fix para não exibir NaN no IE
                            if (isNaN(data.identifier)) {
                                var datePattern = /^\s*(\d{4})\/(\d+)/;
                                var month;
                                var parts = datePattern.exec(data.identifier);
                                date = new Date(NaN);

                                if (parts) {
                                    month = +parts[2];
                                    date.setFullYear(parts[1], month - 1);
                                    if (month !== date.getMonth() + 1) {
                                        date.setTime(NaN);
                                    }
                                }
                                return new Date(date.getFullYear(), date.getMonth());
                            } else {
                                date = new Date(data.identifier);
                                return new Date(date.getFullYear(), date.getMonth());
                            }
                        }
                    },
                    range: {
                        getIdentifierFormat: function () {
                            return function (value) {
                                return value;
                            };
                        },
                        formatIdenfitierValue: function (data) {
                            var unitOfMeasure = $scope.context.configs.properties.unitOfMeasure;

                            if (!data.upperBound) {
                                return $translate.instant("charting.from") + " " + data.lowerBound + unitOfMeasure;
                            }
                            return _.join([
                                $translate.instant("charting.of"),
                                data.lowerBound + unitOfMeasure,
                                $translate.instant("charting.until"),
                                data.upperBound + unitOfMeasure
                            ], " ");
                        }
                    },
                    translate: {
                        getIdentifierFormat: function () {
                            return function (value) {
                                return value;
                            };
                        },
                        formatIdenfitierValue: function (data) {
                            return $translate.instant("charting.identifier.stackedBar." +  data.identifier);
                        }
                    }
                };

                // labels do chart
                var currentIdentifiers = [];
                $scope.scale = null;
                var chartConfig = {
                    bindto: $element.find(".stacked-bar-chart-content")[0],
                    size: {
                        height: Number(properties.height) || undefined,
                        width: Number(properties.width) || undefined
                    },
                    padding: {
                        right: 100
                    },
                    data: {
                        x: "x",
                        columns: [],
                        type: "bar",
                        labels: {
                            format: function (value) {
                                if (value > 0) {
                                    return $scope.dataLabelFunction(value);
                                }
                            }
                        },
                        empty: {
                            label: {
                                text: $translate.instant("charting.notApplicable")
                            }
                        },
                        order: null
                    },
                    axis: {
                        rotated: properties.rotate === "true",
                        x: {
                            type: "category",
                            tick: {
                                rotate: getRotateByTypeIdentifier($scope.typeIdentifier),
                                multiline: false
                            },
                            height: 130
                        },
                        y: {
                            label: {
                                text: $translate.instant(properties.yAxisLabel || ""),
                                position: "outer-middle"
                            },
                            tick: {
                                format: function (x) {
                                    if (angular.isDefined(properties.showOnlyIntegerOnY) && properties.showOnlyIntegerOnY === "true") {
                                        if (x !== Math.floor(x)) {
                                            d3.selectAll(".c3-axis-y g.tick").filter(function () {
                                                var text = d3.select(this).select("text").text();
                                                return +text === x;
                                            }).style("opacity", 0);
                                            return "";
                                        }
                                    }
                                    return x;
                                }
                            }
                        }
                    },
                    tooltip: {
                        format: {
                            value: function (value) {
                                if (!$scope.absolute || properties.usePercentageOnTooltip) {
                                    var format = d3.format("0.2%");
                                    return format($scope.scale(value));
                                }
                                return value;
                            }
                        },
                        contents: function (data) {
                            var totalForColumn = 0;
                            data.forEach(function (datum) {
                                totalForColumn += datum.value;
                            });
                            var scaleFunction = d3.scale.linear();
                            scaleFunction.domain([0, totalForColumn]).range([0, 1]);
                            $scope.scale = scaleFunction;
                            return c3.chart.internal.fn.getTooltipContent.apply(this, arguments);
                        }
                    },
                    legend: {
                        show: false
                    }
                };

                if ($scope.context.configs.properties.color === "true") {
                    var colors = {};
                    angular.forEach($scope.context.configs.properties, function (value, prop) {
                        if (prop.substring(0, 6) === "color-") {
                            colors[translateLabelFunction(prop.substring(6))] = value;
                        }
                    });
                    chartConfig.data.colors = colors;
                } else {
                    var colorScale = d3.scale.category20();
                    var pattern = [];
                    for (var i = 0; i < 20; i++) {
                        pattern.push(colorScale(i));
                    }
                    chartConfig.color = {
                        pattern: pattern
                    };
                }

                function getRotateByTypeIdentifier(typeIdentifier) {
                    if (typeIdentifier === "range" || typeIdentifier === "timeseries") {
                        return 45;
                    }
                    return 0;
                }

                function getFormat(typeIdentifier) {
                    if (typeIdentifier !== undefined) {
                        return identifierBehavior[properties.typeIdentifier].getIdentifierFormat();
                    }
                    else {
                        return undefined;
                    }
                }

                var chart = c3.generate(chartConfig);

                $scope.$on("$filterRefresh", function () {
                    chart.show();
                });

                $scope.$watch(function () {
                    return $scope.context.data;
                }, function (newContextData) {
                    var dataToProcess = angular.copy(newContextData);
                    var processedData = processDataToUpdateChart(groupByIdentifier(convertDataToFormat(processRawData(dataToProcess))));
                    chart.load(processedData.data);
                    chart.groups([processedData.group]);
                    createLegends();
                }, true);

                function convertDataToFormat(rawData) {
                    rawData.forEach(function (data) {
                        //Formatação para o identifier
                        if ($scope.typeIdentifier === "range" && Number(data.identifier) === Number.NaN) {
                            throw new Error("Type range for identifier only aceppt number: ");
                        }
                        if ($scope.typeIdentifier) {
                            var format = getFormat($scope.typeIdentifier);
                            var newData = identifierBehavior[$scope.typeIdentifier].formatIdenfitierValue(data);
                            data.identifier = (format(newData));
                        }

                        //Formatação para a label
                        data.label = (translateLabelFunction(data.label));
                    });
                    return rawData;
                }

                function processRawData(rawData) {
                    var pivotData = rawData;
                    if (properties && angular.isDefined(properties.pivot)) {
                        pivotData = [];
                        rawData.forEach(function (datum) {
                            pivotData.push({
                                identifier: datum[properties.identifier] || properties.identifier,
                                label: datum[properties.label],
                                value: datum[properties.value]
                            });
                        });
                    }
                    return _.sortBy(pivotData, ["identifier", "label", "value"]);
                }

                function groupByIdentifier(newContextData) {
                    var groupByIdentifierData = [];
                    var allLabels = [];

                    newContextData.forEach(function (data) {
                        if (!arrays.contains(allLabels, data.label)) {
                            allLabels.push(data.label);
                        }
                    });

                    newContextData.forEach(function (data) {
                        if (angular.isUndefined(groupByIdentifierData[data.identifier])) {
                            var newData = {
                                identifier: data.identifier,
                                values: []
                            };
                            allLabels.forEach(function (label) {
                                newData.values.push({
                                    label: label,
                                    value: 0
                                });
                            });
                            groupByIdentifierData[data.identifier] = newData;
                        }
                        groupByIdentifierData[data.identifier].values.forEach(function (value) {
                            if (value.label === data.label) {
                                value.value += data.value;
                            }
                        });
                    });

                    var processedRawData = d3.values(groupByIdentifierData);
                    if ($scope.proportional) {
                        processedRawData = convertDataToRelativeByIdentifier(processedRawData);
                    }
                    return processedRawData;
                }

                function convertDataToRelativeByIdentifier(dataToConvert) {
                    dataToConvert.forEach(function (data) {
                        var totalForData = 0;
                        data.values.forEach(function (value) {
                            totalForData += value.value;
                        });
                        var scaleFunction = d3.scale.linear().domain([0, totalForData]).range([0, 1]);
                        data.values.forEach(function (value) {
                            value.value = scaleFunction(value.value);
                        });
                    });
                    return dataToConvert;
                }

                var rootNode = d3.select($element[0]).select(".stacked-bar-chart-legend");

                function createLegends() {
                    rootNode.selectAll(".legend-item").remove();

                    if (properties.legendTitle && currentIdentifiers.length) {
                        rootNode.append("p")
                            .attr("class", "legend-item legend-title")
                            .text($translate.instant(properties.legendTitle));
                    }

                    var divs = rootNode.selectAll("div.legend-item").data(currentIdentifiers);

                    var itens = divs.enter().append("div")
                        .attr("class", "legend-item text-left legend-container")
                        .attr("data-id", function (id) {
                            return id;
                        });

                    itens.append("div")
                        .attr("class", function (id) {
                            return "legend-" + replaceBlanckSpaces(id);
                        })
                        .classed("legend-item", true)
                        .classed("legend-color", true)
                        .style("background-color", function (id) {
                            return chart.color(id);
                        })
                        .on("mouseover", legendMouseover)
                        .on("mouseout", legendMouseout)
                        .on("click", legendClick);

                    itens.append("span")
                        .attr("class", function (id) {
                            return "legend-" + replaceBlanckSpaces(id);
                        })
                        .classed("legend-item", true)
                        .text(function (id) {
                            return id;
                        })
                        .on("mouseover", legendMouseover)
                        .on("mouseout", legendMouseout)
                        .on("click", legendClick);
                }

                var hiddenIdentifiers = [];

                function legendMouseover(id) {
                    chart.focus(id);
                    currentIdentifiers.forEach(function (identifier) {
                        if (id !== identifier) {
                            rootNode.selectAll(".legend-" + replaceBlanckSpaces(identifier))
                                .style("opacity", 0.3);
                        }
                    });
                }

                function legendMouseout() {
                    chart.revert();
                    currentIdentifiers.forEach(function (identifier) {
                        if (!arrays.contains(hiddenIdentifiers, identifier)) {
                            rootNode.selectAll(".legend-" + replaceBlanckSpaces(identifier))
                                .style("opacity", 1);
                        }
                    });
                }

                function legendClick(id) {
                    chart.toggle(id);
                    var opacity = rootNode.selectAll(".legend-" + replaceBlanckSpaces(id))
                        .style("opacity");
                    rootNode.selectAll(".legend-" + replaceBlanckSpaces(id))
                        .style("opacity", function () {
                            if (opacity === "1") {
                                hiddenIdentifiers.push(id);
                                return 0.3;
                            } else {
                                arrays.remove(hiddenIdentifiers, id);
                                return 1;
                            }
                        });
                }

                function replaceBlanckSpaces(str) {
                    return str.replace(/\s/g, "_").replace("/", "_");
                }

                function processDataToUpdateChart(newContextData) {
                    var processedData = {
                        data: {
                            x: "x",
                            columns: [],
                            type: "bar",
                            labels: {
                                format: function (value) {
                                    return (value);
                                }
                            },
                            unload: []
                        },
                        group: []
                    };

                    var mappingValues = {};
                    var x = ["x"];
                    var newLabels = [];
                    newContextData.forEach(function (data) {
                        var stringIdentifier = angular.isString(data.identifier) ? data.identifier : JSON.stringify(data.identifier);
                        x.push(stringIdentifier);
                        data.values.forEach(function (value) {
                            var stringLabel = angular.isString(value.label) ? value.label : JSON.stringify(value.label);
                            if (!arrays.contains(newLabels, stringLabel)) {
                                newLabels.push(stringLabel);
                            }
                            if (!mappingValues[stringLabel]) {
                                mappingValues[stringLabel] = [stringLabel];
                            }
                            mappingValues[stringLabel].push(value.value);
                        });
                    });
                    processedData.data.columns.push(x);
                    angular.forEach(mappingValues, function (value, key) {
                        processedData.data.columns.push(value);
                        processedData.group.push(key);
                    });

                    currentIdentifiers.forEach(function (label) {
                        if (!arrays.contains(newLabels, label)) {
                            processedData.data.unload.push(label);
                        }
                    });
                    currentIdentifiers = newLabels.sort();
                    return processedData;
                }
            }
        };
    }]);
});
