import angular from "angular"
import authorizationModule from "./authorizationModule"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"
import menuItems from "../menu/menuItems"

authorizationModule.service("authorizationService", function (permissionService, dynamicMenuItemsService) {

    this.getAuthorizedMenus = () => {
        return permissionService.getAuthorization()
            .then((authorization) => {
                let authorizedMenus = authorizeMenus(authorization, angular.copy(menuItems))
                dynamicMenuItemsService.setMenuItems(authorizedMenus)
                return authorizedMenus
            })
    }

    function authorizeMenus(authorization, menuItems) {
        if (!menuItems) {
            return []
        }
        return arrays.filter(menuItems, function (menu) {
            menu.subMenus = authorizeMenus(authorization, menu.subMenus)
            return authorization(menu.groupName).canRead
        })
    }
})
