define(["./transitionFailureReasonModule", "text!./transitionFailureReasonModal.html",], function (module, template) {
    "use strict";

    return module.service("transitionFailureReasonModal", ["$modal", "monitoringService", "messagesModal", "transitionService", "dataFetchers", function ($modal, monitoringService, messagesModal, transitionService, dataFetchers) {
        return function (transitionIds, timestamp, monitorable, invoiceNodes, transitionNodes) {
            if (monitorable) {
                //visão por hierarquia: clicou em engrenagem > transições > Realizado (falha), e era invoice
                if (monitorable.typeName !== 'INVOICE') {
                    return skipFailureReasonTransitionModal(transitionIds, timestamp);
                }
                //visão por hierarquia: clicou em engrenagem > transições > Realizado (falha), mas não era invoice
                return singleInvoiceFailureModal(transitionIds, timestamp, monitorable, null);
            }
            const invoiceNodesInParents = getInvoiceNodesInParents(transitionNodes);
            //visão por hierarquia: selecionou transitions com ao menos uma invoice > droppdown Transitions > Finalizar (falha)
            if (invoiceNodes && invoiceNodes.length > 0) {
                if (invoiceNodesInParents.length > 0 && invoiceNodesInParents.length === 1) {
                    // visão hierárquica: selecionou transitions com ao menos uma invoice e
                    // selecionou a transição filha do monitorável invoice e clicou no dropdown Transitions ou foi na engrenagem dessa transição
                    return openSingleInvoiceFailureModal(transitionIds, timestamp, invoiceNodesInParents[0].presentationId);
                }
                return massTransitionFailureWithAtLeastOneInvoiceModal(transitionIds, timestamp, invoiceNodes);
            }
            //visão por endereço, droppdown Transitions ou engrenagem -> Finalizar (falha)
            if (transitionNodes && transitionNodes.length > 0) {
                const invoiceTransitionNodes = getInvoiceTransitionNodes(transitionNodes);
                if (invoiceTransitionNodes.length > 0) {
                    //visão por endereço: clicou na engrenagem da transition que é de invoice, ou só selecionou a transition de invoice e clicou no dropdown Transitions
                    if (invoiceTransitionNodes.length === 1) {
                        const monitorableSourceId = getMonitorableCodeFromInvoiceTransitionNode(invoiceTransitionNodes[0]);
                        //visão hierárquica, mas, ao invés de clicar na engrenagem da própria invoice, clicou na engrenagem da transição filha da invoice
                        //visão por endereço, clicou na transition da invoice, mas não temos como acessar a invoice sem uma requisição
                        return openSingleInvoiceFailureModal(transitionIds, timestamp, monitorableSourceId);
                    }
                    //visão por endereço: selecionou mais de uma transition, com ao menos uma transition de invoice
                    return massTransitionFailureWithAtLeastOneInvoiceModal(transitionIds, timestamp, invoiceNodes);
                }
                if (invoiceNodesInParents.length > 0) {
                    //visão hierárquica: selecionou a transição filha do monitorável invoice  e clicou no dropdown Transitions ou foi na engrenagem dessa transição
                    if (invoiceNodesInParents.length === 1) {
                        return openSingleInvoiceFailureModal(transitionIds, timestamp, invoiceNodesInParents[0].presentationId);
                    }
                    return massTransitionFailureWithAtLeastOneInvoiceModal(transitionIds, timestamp, invoiceTransitionNodes);
                }
                //visão por endereço ou hierarquia: selecionou uma ou mais transitions mas sem nenhuma invoice, ou engrenagem na transition que não era invoice
                return skipFailureReasonTransitionModal(transitionIds, null);
            }
        }

        function openSingleInvoiceFailureModal(transitionIds, timestamp, monitorableSourceId) {
            return monitoringService.findMonitorableBySourceId(monitorableSourceId, dataFetchers.INVOICE_ITEMS)
                .then(function (data) {
                    return singleInvoiceFailureModal(transitionIds, timestamp, data);
                })
        }

        //visão hierárquica, engrenagem da invoice > transições
        function getMonitorableCodeFromInvoiceTransitionNode(invoiceTransitionNode) {
            return invoiceTransitionNode.headerProperties.find((property) => {
                return property.messageKey === 'monitorable.code';
            }).value;
        }

        function getInvoiceNodesInParents(transitionNodes) {
            let invoiceNodesInParents = [];
            for (let i = 0; i < transitionNodes.length; i++) {
                if (transitionNodes[i].$parent.type.name === 'Invoice') {
                    invoiceNodesInParents.push(transitionNodes[i].$parent);
                }
            }
            return invoiceNodesInParents;
        }

        function convertInvoiceToNotDeliveredDetails(monitorableInvoice) {
            if (monitorableInvoice) {
                return monitorableInvoice.items.map(function (item) {
                    return {
                        id: item.id,
                        description: item.orderCode + " - " + item.product.sourceId,
                        quantity: 0,
                        totalQuantity: item.quantity
                    }
                });
            }
        }

        function singleInvoiceFailureModal(transitionIds, timestamp, monitorableInvoice) {
            return $modal.open({
                size: "lg", template: template, controller: ["$scope", $scope => {
                    $scope.showInputForNotDeliveredQuantities = true;
                    $scope.newComment = {};
                    $scope.isTotalQuantitySelected = true;
                    $scope.notDeliveredDetails = convertInvoiceToNotDeliveredDetails(monitorableInvoice);
                    monitoringService.findTransitionFailureReasonsDefault().then((data) => {
                        $scope.failureReason = data;
                    });

                    $scope.availableTransitionFailureReasons = () => {
                        return monitoringService.findAllTransitionFailureReasons();
                    };

                    $scope.save = () => {
                        saveTransitions(transitionIds, timestamp, $scope.newComment, $scope.failureReason.id, $scope.isTotalQuantitySelected, $scope.notDeliveredDetails)
                            .then(() => {
                                return $scope.$close();
                            });
                    };

                    $scope.isTotalQuantityOptionSelected =  function() {
                        $scope.isTotalQuantitySelected = !$scope.isTotalQuantitySelected;
                    };
                }]
            }).result;
        }

        function massTransitionFailureWithAtLeastOneInvoiceModal(transitionIds, timestamp, invoiceTransitionNodes) {
            return $modal.open({
                size: "lg", template: template, controller: ["$scope", $scope => {
                    $scope.showInputForNotDeliveredQuantities = false;
                    $scope.isTotalQuantitySelected = true;
                    $scope.newComment = {};
                    monitoringService.findTransitionFailureReasonsDefault().then((data) => {
                        $scope.failureReason = data;
                    });
                    monitoringService.findMonitorableBySourceIds(getInvoiceTransitionNodes(invoiceTransitionNodes)).then((data) => {
                        $scope.notDeliveredDetails = convertInvoiceToNotDeliveredDetails(data);
                    })

                    $scope.availableTransitionFailureReasons = () => {
                        return monitoringService.findAllTransitionFailureReasons();
                    };

                    $scope.save = () => {
                        saveTransitions(transitionIds, timestamp, $scope.newComment, $scope.failureReason.id, $scope.isTotalQuantitySelected, $scope.notDeliveredDetails)
                            .then(() => {
                                return $scope.$close();
                            });
                    };

                    $scope.isTotalQuantityOptionSelected =  function() {
                        $scope.isTotalQuantitySelected = !$scope.isTotalQuantitySelected;
                    };
                }]
            }).result;
        }

        function skipFailureReasonTransitionModal(transitionIds, timestamp) {
            return saveTransitions(transitionIds, timestamp, null, null, null, null);
        }

        function saveTransitions(transitionIds, timestamp, comment, failureReasonId, isTotalSelected, notDeliveredDetails) {
            if (transitionIds.length === 1) {
                return transitionService.notAccomplishTransition(transitionIds[0], timestamp, comment, failureReasonId, isTotalSelected, notDeliveredDetails);
            }
            return transitionService.notAccomplishTransitions(transitionIds, comment, failureReasonId, isTotalSelected, notDeliveredDetails);
        }

        function getInvoiceTransitionNodes(nodes) {
            const invoiceTransitionNodes = [];
            for (let i = 0; i < nodes.length; i++) {
                const properties = nodes[i].headerProperties;
                for (let j = 0; j < properties.length; j++) {
                    if (properties[j].messageKey === "monitorable.type") {
                        if (properties[j].value === "br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType.INVOICE") {
                            invoiceTransitionNodes.push(nodes[i]);
                        } else {
                            break;
                        }
                    }
                }
            }
            return invoiceTransitionNodes;
        }
    }])
});