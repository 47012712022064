define(["./violationModule", "text!./nlgViolationsTitle.html"], function (violationModule, nlgViolationsTitleTemplate) {
    "use strict";

    /**
     * @ngdoc directive
     * @name violationModule.directive:nlgViolationsTitle
     * @restrict E
     * @description
     * Diretiva que cria título para listagem de violações.
     *
     * @param {Array} messages `Array` contendo as mensagens de violações para terem códigos exibidos em modal.
     * @param {string} title Texto a ser traduzido e utilizado como título
     * */
    return violationModule.directive("nlgViolationsTitle", [function () {
        return {
            restrict: "E",
            template: nlgViolationsTitleTemplate,
            scope: {
                codes: "<codes",
                type: "@?"
            },
            controller: ["$scope", "$attrs", "messagesModal", function ($scope, $attrs, messagesModal) {
                $scope.type = $attrs.type !== undefined
                    ? $attrs.type
                    : "violation";

                $scope.openModal = function () {
                    var codesMessage = $scope.codes.length ? $scope.codes.join(", ") : "";
                    messagesModal("summary.info." + $scope.type + ".title.modal", [{
                        keyBundle: codesMessage,
                        parameters: []
                    }]);
                };
            }]
        };
    }]);
});
