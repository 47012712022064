define(["../monitorableModule",
        "text!./secondaryDevicesModal.html",
        "front-end-web-commons/app/arrays/arrays",
        "angular"],
    function (module, template, arrays, angular) {
        "use strict";

        return module.service("secondaryDevicesModal", ["$modal", "monitoringService", "trackingService", function ($modal, monitoringService, trackingService) {
            return function (deviceByMonitorable) {
                return $modal.open({
                    size: "lg", template: template, backdrop: 'static', keyboard: false, controller: ["$scope", function ($scope) {

                        const secondaryDevices = !deviceByMonitorable.secondaryDevices ? [] : angular.copy(deviceByMonitorable.secondaryDevices);
                        $scope.records = secondaryDevices.map(function(device){
                            return {
                                device: device
                            };
                        })

                        $scope.truckDeviceNumbers = function (viewValue) {
                            return trackingService.findByDeviceIdLike(viewValue).then(function (truckDevices) {
                                return filterSecondaryDevicesMonitorables(truckDevices);
                            });
                        };

                        $scope.secondaryTruckDevices = function (viewValue) {
                            return trackingService.findByDescriptionLike(viewValue).then(function (truckDevices) {
                                return filterSecondaryDevicesMonitorables(truckDevices);
                            });
                        };

                        function filterSecondaryDevicesMonitorables(truckDevices) {
                            if( !!deviceByMonitorable.truckDevice ){
                                if( arrays.contains(truckDevices, deviceByMonitorable.truckDevice) ){
                                    arrays.remove(truckDevices, deviceByMonitorable.truckDevice)
                                }
                            }
                            return arrays.minus(truckDevices, $scope.records.map(record => record.device));
                        }

                        $scope.formatTag = function (tag) {
                            if (tag) {
                                return tag.deviceId;
                            }
                        };

                        $scope.assign = function () {
                            deviceByMonitorable.secondaryDevices = $scope.records.map(record => record.device);
                            $scope.$close();
                        };

                        $scope.disabledOKButton = function () {
                            return $scope.records.some( record => !record.device )
                        };
                    }]
                });
            };
        }]);
    });
