import messageConfigParamsModule from "./messageConfigParamsModule"
import server from "../server"

messageConfigParamsModule.controller("messageConfigParamsController", ["$scope", "messageConfigParamsService", "loadingService", "messagesModal", "remoteExceptionHandler","emailMessageTestConfigurationsModal",
    function ($scope, messageConfigParamsService, loadingService, messagesModal, remoteExceptionHandler, emailMessageTestConfigurationsModal) {

        $scope.entity = {}
        $scope.filterService = server.getRelativeUrl("message-config-params")

        function getConfig() {
            return loadingService(messageConfigParamsService.getMessageConfigParams()).then((config) => {
                $scope.entity = config
            })
        }

        getConfig()

        $scope.save = function () {
            loadingService(messageConfigParamsService.createOrUpdateConfig($scope.entity))
                .catch(remoteExceptionHandler())
                .then(() => getConfig())
                .then(() => {
                    messagesModal("dialog.success", [{
                        keyBundle: "entity.validator.successMessage",
                        parameters: []
                    }])
                })
        }


        $scope.testConfigurationsModal = function () {
           emailMessageTestConfigurationsModal($scope.entity)
        }

    }])