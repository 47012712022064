define(["angular",
    "./chartingModule"
], function (angular, chartingModule) {
    "use strict";

    chartingModule.service("chartingService", ["$translate", function ($translate) {
        return {
            ranking: ranking,
            rankingMultivaluedData: rankingMultivaluedData,
            rankingMultiBarChart: rankingMultiBarChart,
            sumOf: sumOf,
            formatChoosableLabel: formatChoosableLabel
        };

        function formatChoosableLabel(label, choosables, choosableInfo) {
            if (choosables && choosables[label]) {
                return label.concat(".", choosables[label]);
            }

            if (choosableInfo && choosableInfo.length) {
                var info = choosableInfo.filter(function (value) {
                    return value.alias === label;
                });

                if (info && info.length) {
                    return label.concat(".", info[0].defaultOptionAlias);
                }
            }
            return label;
        }

        function ranking(newContextData, rank) {
            var offset = rank || newContextData.length;

            var data = newContextData.slice();
            var top = data.slice(0, offset);
            var rest = data.splice(offset, data.length);

            var others = rest.reduce(function (a, b) {
                return {
                    "value": a.value + b.value,
                    "label": "charting.nameLabel.chart.Others"
                };
            }, {"value": 0, "label": "charting.nameLabel.chart.Others"});

            if (Array.isArray(top) && top.length && rest.length) top.push(others);
            return top;
        }

        function rankingMultivaluedData(newContextData, rank) {
            var offset = rank || newContextData.length;

            var data = newContextData.slice();
            var top = data.slice(0, offset);
            var rest = data.splice(offset, data.length);
            var emptyData = {};

            if (Array.isArray(top) && top.length) {
                var firstRecord = top[0];
                var fields = Object.keys(firstRecord);

                fields.forEach(function (field) {
                    if (isNumeric(firstRecord[field])) {
                        emptyData[field] = 0;
                    } else {
                        emptyData[field] = "charting.nameLabel.chart.Others";
                    }
                });
            }

            var others = rest.reduce(function (a, b) {
                var result = {};
                var keys = Object.keys(a);
                keys.forEach(function (key) {
                        if (isNumeric(a[key]) && isNumeric(b[key])) {
                            result[key] = a[key] + b[key];
                        } else {
                            result[key] = "charting.nameLabel.chart.Others";
                        }
                    }
                );
                return result;
            }, emptyData);

            if (Array.isArray(top) && top.length && rest.length) top.push(others);

            return top;
        }

        function sumOf(data, series) {
            var totalizer = [];
            series.forEach(function (serie) {
                if(!serie.summarized){
                    return;
                }
                var label = serie.label;
                if (Array.isArray(data) && data.length) {
                    var total = data.reduce(function (a, b) {
                        return a + b[label];
                    }, 0);
                }
                totalizer[label] = total;
            });
            return totalizer;
        }

        function isNumeric(str) {
            return !isNaN(str) && !isNaN(parseFloat(str));
        }

        function rankingMultiBarChart(newContextData, rank) {
            var offset = rank || newContextData.length;

            var data = newContextData.slice();
            var top = data.slice(0, offset);
            var rest = data.splice(offset, data.length);

            var others = rest.reduce(function (a, b) {
                return {
                    "rankeableValue": a.rankeableValue + b.rankeableValue,
                    "x": $translate.instant("charting.nameLabel.chart.Others")
                };
            }, {"rankeableValue": 0, "x": $translate.instant("charting.nameLabel.chart.Others")});

            if (Array.isArray(top) && top.length && rest.length) top.push(others);
            return top;
        }
    }]);
});
