/**
 * Métodos utilitários para manipular functions.
 * @module infra/functions
 */
define(["lodash"], function functions(_) {
    "use strict";

    return {
        memoize: memoizeWithExpiration,
        getter: getter
    };

    /**
     * Decora a function especificada que memoriza o resultado de sua chamada, considerando os parâmetros utilizados na
     * chamada.
     * O resultado estará disponível pelo período especificado no parâmetro timeout, ou seja, uma nova chamada a
     * function delegada (com os mesmos parâmetros de entrada) só será realizada após este intervalo.
     *
     * @param {Function} f function a ser decorada
     * @param {Number} timeout intervalo de validade do resultado
     */
    function memoizeWithExpiration(f, timeout) {
        var memoize = _.memoize(f);
        if (!timeout) {
            return memoize;
        }
        var start = 0;
        memoizedFunction.expire = expire;
        return memoizedFunction;

        function memoizedFunction() {
            if (Date.now() - start > timeout) {
                //Expired: reset All
                memoize = _.memoize(f);
                start = Date.now();
            }
            return memoize.apply(null, arguments);
        }

        function expire() {
            start = 0;
        }
    }

    /**
     * Retorna uma função que extrai uma propriedade do primeiro parâmetro especificado.
     *
     * @param field {String|Number} propriedade a ser extraída
     * @returns {Function} function cujo parâmetro terá a propriedade extraída
     */
    function getter(field) {
        return function (obj) {
            return obj[field];
        };
    }
});