import scriptModule from "./scriptModule"

scriptModule.controller("scriptController", [
    "$scope",
    "sourceEntity",
    "entity",
    "entityEditionService",
    function ($scope: any, sourceEntity: any, entity: any, entityEditionService: any) {
        const hasOwnProperty = Object.hasOwnProperty
        const updateService = entityEditionService.crudHelper("script", sourceEntity)
        $scope.entity = entity
        $scope.save = updateService.save.bind(updateService, entity)
        $scope.saveAndContinue = updateService.save.bind(updateService, entity, true)
    }])