import lgpdModule from "./lgpdModule"

lgpdModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("lgpd.lgpdEntity", {
            url: "/lgpd-entity",
            templateUrl: require("link!./lgpdView.html"),
            data: {
                group: "group.r2.lgpd.entity",
                pageTitle: "{{'title.lgpd.entity' | translate}}",
                dontRedirectOnForbidden: true
            },
    })
}])

lgpdModule.config(["lgpdApiByArtifactProvider", function (lgpdApiByArtifact) {
    lgpdApiByArtifact.addArtifactApi("r2", "lgpdApi")
}])