define([
    "angular",
    "qrcode-generator",
    "angular-ui-router",
    "angular-bootstrap",
    "angular-qrcode",
    "front-end-web-commons",
    "./driverMap/driverMap"
], function (angular, qrcode) {
    "use strict";

    /**
     * @ngdoc overview
     * @name trackingMobuleModule
     * @description
     *
     * Módulo destinado aos fluxos do TrackingMobile.
     * */
    return angular.module("trackingMobileModule", [
        "ui.router",
        "ui.bootstrap",
        "monospaced.qrcode",
        "filterModule",
        "remoteModule",
        "driverMapModule",
        "intlPhoneNumberModule"
    ]).run(["$window", function ($window) {
        $window.qrcode = qrcode;
    }]);
});