define(["./mapsModule"], function (mapsModule) {
    "use strict";

    return mapsModule.service("FreeHandDrawerContextMenu", ["uiGmapGoogleMapApi", "$translate", function (uiGmapGoogleMapApi, $translate) {
        function FreeHandDrawerContextMenu(map, position) {
            this.position = position;
            this.setMap(map);
            this.mapDiv = map.getDiv();
            this.menuItems = createMenuItems();
            this.canDraw = true;
        }

        uiGmapGoogleMapApi.then(function (mapApi) {
            FreeHandDrawerContextMenu.create = function (map, options, position) {
                var instance = new FreeHandDrawerContextMenu(map, options, position);
                instance.pixelOffset = options.pixelOffset || new mapApi.Point(10, -5);
                return instance;
            };

            FreeHandDrawerContextMenu.prototype = new mapApi.OverlayView();

            FreeHandDrawerContextMenu.prototype.onAdd = function () {
                var $this = this;
                this.canDraw = true;
                var menu = document.createElement("div");
                menu.addEventListener("click", function () {
                    $this.hide();
                });

                menu.style.cssText = "display:none; position:absolute";

                for (var i = 0; i < $this.menuItems.length; i++) {
                    if ($this.menuItems[i].label && $this.menuItems[i].eventName) {
                        menu.appendChild(createMenuItem($this, $this.menuItems[i]));
                    }
                }

                delete $this.menuItems;

                $this.isVisible = false;
                $this.menu = menu;

                this.getPanes().floatPane.appendChild(this.menu);
            };

            function createMenuItem(menu, values) {
                var menuItem = document.createElement("div");
                menuItem.innerHTML = values.label;

                if (values.id) {
                    menuItem.id = values.id;
                }
                menuItem.style.cssText = "cursor:pointer;";
                menuItem.onclick = function () {
                    mapApi.event.trigger(menu, "menuItemSelected", menu.position, values.eventName);
                };
                return menuItem;
            }

            FreeHandDrawerContextMenu.prototype.draw = function () {
                if (!this.isVisible && this.canDraw) {
                    this.menu.style.fontSize = "15px";
                    this.menu.style.backgroundColor = "#fff";
                    this.menu.style.display = "block";
                    this.isVisible = true;
                }

                if (this.isVisible && this.canDraw) {
                    var mapSize = new mapApi.Size(this.mapDiv.offsetWidth, this.mapDiv.offsetHeight);
                    var menuSize = new mapApi.Size(this.menu.offsetWidth, this.menu.offsetHeight);
                    var mousePosition = this.getProjection().fromLatLngToDivPixel(this.position);

                    var left = mousePosition.x;
                    var top = mousePosition.y;

                    if (mousePosition.x > mapSize.width - menuSize.width - this.pixelOffset.x) {
                        left = left - menuSize.width - this.pixelOffset.x;
                    } else {
                        left += this.pixelOffset.x;
                    }

                    if (mousePosition.y > mapSize.height - menuSize.height - this.pixelOffset.y) {
                        top = top - menuSize.height - this.pixelOffset.y;
                    } else {
                        top += this.pixelOffset.y;
                    }

                    this.menu.style.left = left + "px";
                    this.menu.style.top = top + "px";
                }
            };

            FreeHandDrawerContextMenu.prototype.hide = function () {
                if (this.isVisible) {
                    this.menu.style.display = "none";
                    this.isVisible = false;
                    this.canDraw = false;
                }
            };
        });

        function createMenuItems() {
            return [{
                eventName: "saveClick",
                label: $translate.instant("carrier.zone.polygon.context.menu.confirm")
            }, {
                eventName: "cancelClick",
                label: $translate.instant("carrier.zone.polygon.context.menu.cancel")
            }];
        }

        return FreeHandDrawerContextMenu;
    }]);
});