define(["./collectionsModule", "angular", "../arrays/arrays"], function (collectionsModule, angular, arrays) {
    "use strict";

    /**
     * @name Entry
     * @description Uma entrada num mapa.
     * @property {*} key Chave da entry
     * @property {*} value Valor da entry
     * */

    return collectionsModule.service("EntrySetMap", [function () {
        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap
         * @description
         * Implementação de Map, utilizando um array de {@link Entry}.
         * <p>
         * Esta implementação permite que qualquer objeto seja utilizado como
         * a chave. Porém, para realizar as operações é necessário percorrer
         * um array {@link Entry}, comparando chave à chave, utilizando
         * {@link angular#equals}, o que pode ser custoso se o conjunto for
         * muito grande, ou formado por chaves complexas.
         * */
        function EntrySetMap() {
            this.entrySet = [];
        }

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#put
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * Adiciona um valor ao mapa, definido pela chave.
         * @param {*} key Chave da entrada.
         * @param {*} value Valor à ser armazenado.
         * @returns {*} Último valor associado à esta chave.
         * */
        EntrySetMap.prototype.put = function (key, value) {
            var index = this.indexOf(key);
            if (index === -1) {
                this.entrySet.push({
                    key: key,
                    value: value
                });
                return;
            }

            var entry = this.entrySet[index];
            var oldValue = entry.value;
            entry.value = value;
            return oldValue;
        };

        /**
         * @private
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#indexOf
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * Encontra a posição de uma chave na lista de {@link Entry}
         * @param {*} key Chave da entrada.
         * @returns {number} posição da chave, ou -1 caso não exista.
         * */
        EntrySetMap.prototype.indexOf = function (key) {
            var result = -1;
            arrays.each(this.entrySet, function (entry, index) {
                if (angular.equals(entry.key, key)) {
                    result = index;
                    return arrays.each.BREAK;
                }
            });
            return result;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#containsKey
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * Verifica se uma dada chave está presente no mapa.
         * @param {*} key Chave da entrada.
         * @returns {boolean} Se a chave existe.
         * */
        EntrySetMap.prototype.containsKey = function (key) {
            return this.indexOf(key) !== -1;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#get
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * Retorna o valor dado uma chave.
         * @param {*} key Chave da entrada.
         * @returns {*} Valor.
         * */
        EntrySetMap.prototype.get = function (key) {
            var index = this.indexOf(key);
            if (index === -1) {
                return;
            }
            return this.entrySet[index].value;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#getOrPut
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * Retorna o valor dado uma chave.
         * <p>
         * Se a chave não existir no mapa, o valor fornecido será adicionado
         * e retornado.
         *
         * @param {*} key Chave da entrada.
         * @param {*=} defaultValue valor à ser adicionado caso não exista.
         * @returns {*} Valor.
         * */
        EntrySetMap.prototype.getOrPut = function (key, defaultValue) {
            var value = this.get(key);
            if (angular.isUndefined(value)) {
                this.put(key, defaultValue);
                return defaultValue;
            }
            return value;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#remove
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * Remove um valor do mapa usando a chave.
         * @param {*} key Chave da entrada.
         * @returns {*} Valor removido.
         * */
        EntrySetMap.prototype.remove = function (key) {
            var index = this.indexOf(key);
            if (index === -1) {
                return;
            }
            return arrays.removeAt(this.entrySet, index).value;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#clear
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * Remove todos os valores mapa.
         * */
        EntrySetMap.prototype.clear = function () {
            arrays.clear(this.entrySet);
        };

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#size
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * @returns {number} A quantidade de valores neste mapa.
         * */
        EntrySetMap.prototype.size = function () {
            return this.entrySet.length;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#isEmpty
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * @returns {boolean} Se o mapa não possui valores.
         * */
        EntrySetMap.prototype.isEmpty = function () {
            return this.size() === 0;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.EntrySetMap#each
         * @methodOf collectionsModule.EntrySetMap
         * @description
         * Executa uma callback para cada par valor/chave neste mapa.
         * @returns {collectionsModule.EntrySetMap} Este mapa.
         * */
        EntrySetMap.prototype.each = function (callback) {
            var self = this;
            arrays.each(self.entrySet, function (entry) {
                return callback.call(self, entry.value, entry.key, self);
            });
            return self;
        };

        return EntrySetMap;
    }]);
});