define(["angular", "./translateModule"], function (angular, translateModule) {
    "use strict";

    /**
     * @ngdoc filter
     * @name filters.filter:groupTranslate
     *
     * @function
     * @param {string} translationId chave de bundle cujo valor pode conter outros translationIds, ou texto que possua translationIds.
     * @param {string} interpolateParams parâmetros para a mensagem raiz.
     * @return {string} texto internacionalizado.
     *
     * @description resolve múltiplos bundles separados por '/'
     */
    return translateModule.filter("groupTranslate", ["$filter", function ($filter) {
        var translateFilter = $filter("translate");
        return function groupTranslate(translationId, interpolateParams) {
            var arrayOfGroups = translationId.toString().split("/");
            var translatedText = arrayOfGroups[0] + "/";
            for(var i = 1; i<arrayOfGroups.length; i++){
                translatedText = translatedText.concat(translateFilter(arrayOfGroups[i], interpolateParams),"/");
            }
            return translatedText.substring(0, translatedText.length - 1);
        };
    }]);
});