define([
    "angular",
    "../monitoringQueryModule",
    "front-end-web-commons/app/arrays/arrays"

], function (angular, controllers, arrays) {
    "use strict";

    controllers.controller("monitoringQueryEditionController", [
        "$scope",
        "$http",
        "$state",
        "loadingService",
        "scriptSQLService",
        "messagesModal",
        "monitoringService",
        "query",
        /**
         * @param $scope
         * @param {ScriptSQLEntity} $scope.entity
         * @param $http
         * @param $state
         * @param loadingService
         * @param scriptSQLService
         * @param messagesModal
         * @param monitoringService
         * @param query
         */
        function ($scope, $http, $state, loadingService, scriptSQLService, messagesModal, monitoringService, query) {
            $scope.entity = fill(query);

            $scope.typeWrapper = function () {
                return monitoringService.getParamTypes().then(function (response) {
                    return response;
                });
            };

            $scope.formatterWrapper = function () {
                return monitoringService.getFormatters().then(function (response) {
                    return response;
                });
            };

            $scope.typeChanged = function (record) {
                record.transformFunction = null;
            };

            $scope.formatterChanged = function (record) {
                record.formatter = null;
            };

            $scope.validateScript = function () {
                loadingService(monitoringService.validateScript($scope.entity).then(function (errors) {
                    if (!errors.length) {
                        $scope.scriptValidationResult = [];
                        return messagesModal("dialog.success", [{
                            "keyBundle": "script.success"
                        }]);
                    }
                    $scope.scriptValidationResult = errors;
                }));
            };

            $scope.addParameters = function (parameters) {
                var newParameters = {
                    name: "",
                    alias: "",
                    type: "",
                    optional: false,
                    multiValued: false
                };
                parameters.push(newParameters);
            };

            $scope.addProjections = function (projections) {
                var newProjection = {
                    name: "",
                    formatter: null
                };
                projections.push(newProjection);
            };

            $scope.removeItem = function (index, item) {
                arrays.removeAt(item, index);
            };

            $scope.saveEntity = function () {
                var id;
                loadingService(monitoringService.saveQuery($scope.entity)
                    .then(function (response) {
                    id = response;
                    })
                    .then(function (response) {
                        return messagesModal("dialog.success", {
                            keyBundle: "monitoring.query.save.success"
                        });
                    }).then(function () {
                        $state.go("monitoring.queryEdition", {
                            queryID: id
                        });
                    })
                    .catch(function (error) {
                        var messages = [];
                        angular.forEach(error.data, function (value) {
                            messages.push({
                                keyBundle: value.messageKey,
                                parameters: value.arguments
                            });
                        });
                        return messagesModal("dialog.error", messages);
                    })
                );
            };

            $scope.back = function () {
                return $state.go("monitoring.monitoringQuerySearch", {
                    reload: true
                });
            };


            $scope.moveParamUp = function (index) {
                var oldValue = $scope.entity.inputParameters[index - 1];
                $scope.entity.inputParameters[index - 1] = $scope.entity.inputParameters[index];
                $scope.entity.inputParameters[index] = oldValue;
            };

            $scope.moveParamDown = function (index) {
                var oldValue = $scope.entity.inputParameters[index + 1];
                $scope.entity.inputParameters[index + 1] = $scope.entity.inputParameters[index];
                $scope.entity.inputParameters[index] = oldValue;
            };

            $scope.moveProjectionUp = function (index) {
                var oldValue = $scope.entity.projections[index - 1];
                $scope.entity.projections[index - 1] = $scope.entity.projections[index];
                $scope.entity.projections[index] = oldValue;
            };

            $scope.moveProjectionDown = function (index) {
                var oldValue = $scope.entity.projections[index + 1];
                $scope.entity.projections[index + 1] = $scope.entity.projections[index];
                $scope.entity.projections[index] = oldValue;
            };

            $scope.isFirst = function (index) {
                return index === 0;
            };

            $scope.isLastParam = function (index) {
                return index === $scope.entity.inputParameters.length - 1;
            };

            $scope.isLastProjection = function (index) {
                return index === $scope.entity.projections.length - 1;
            };

            function fill(query) {
                if (query) {
                    return {
                        id: query.id,
                        sourceId: query.sourceId,
                        name: query.name,
                        description: query.description,
                        script: query.script,
                        inputParameters: query.inputParameters,
                        projections: query.projections,
                        permissionGroupId: query.permissionGroupId,
                        statementTimeout: query.statementTimeout
                    }
                }
                return {
                    id: null,
                    sourceId: "",
                    name: "",
                    description: "",
                    script: "",
                    inputParameters: [],
                    projections: [],
                    permissionGroupId: null,
                    statementTimeout: null
                };
            }

            /**
             * Referências: http://usejsdoc.org/tags-typedef.html, https://eek.ro/json-to-jsdoc/
             *
             * @typedef {Object} ScriptSQLEntity
             * @property {Number} id
             * @property {String} sourceId
             * @property {String} name
             * @property {String} description
             * @property {String} script
             * @property {Array} inputParameters
             * @property {Array} projections
             * @property {Number} permissionGroupId
             * @property {Number} statementTimeout
             */
        }]);
});
