define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckerMultiBarReason.html",
    "lodash",
    "moment"
], function (module, template) {
    "use strict";
    return module.directive("nlgHealthCheckerMultiBarReason", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                reason: "<"
            },
            controller: ["$scope", "typeFormatService", function ($scope, typeFormatService) {
                $scope.multiBarChartInput = {
                    configs: {
                        type: "multibar",
                        properties: {
                            rank: $scope.reason.rank,
                            rankeableValueTypeFormat: typeFormatService.convertTypeFormatToChartLocateName($scope.reason.multiBarTypeFormat.rankeableValueTypeFormat),
                            notRankeableValueTypeFormat: typeFormatService.convertTypeFormatToChartLocateName($scope.reason.multiBarTypeFormat.notRankeableValueTypeFormat),
                            rankeableValueLegend: $scope.reason.multiBarLegend.rankeableValueLegend,
                            notRankeableValueLegend: $scope.reason.multiBarLegend.notRankeableValueLegend
                        }
                    },
                    data: []
                };
                $scope.multiBarChartInput.data = configureMultiBarChart($scope.reason.datas);

                function configureMultiBarChart(datas) {
                    var result = [];
                    datas.forEach(function (data) {
                        var newObject = {};
                        data.columns.forEach(function (column) {
                            newObject["x"] = data.label;
                            newObject[column.label] = column.value;
                        });
                        result.push(newObject);
                    });
                    return result;
                }
            }]
        };
    }]);
});
