define([
    "./completionModule"
], function (completionModule) {
    "use strict";

    return completionModule.constant("CompletionStatus", {
        CREATED: "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CREATED",
        IN_EXECUTION: "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.IN_EXECUTION",
        FINISHED: "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.FINISHED",
        CANCELLED: "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CANCELLED"
    });
});