define([
    "./monitoringDriverModule"
], function (module) {
    "use strict";

    return module.service("alterDriverModal", [
        "monitoringService",
        "loadingService",
        "remoteExceptionHandler",
        "monitoringDriverSelectionModal",
        "mbrService",
        "remoteMessageModal",
        function (monitoringService, loadingService, remoteExceptionHandler, monitoringDriverSelectionModal, mbrService, remoteMessageModal) {
            return function (monitorable) {

                return monitoringDriverSelectionModal(monitorable.currentDriver, monitorable.drivers)
                    .then(function (selectedDriver) {
                        return loadingService(mbrService.updateCurrentDriver(monitorable.id, selectedDriver.id))
                            .catch(remoteExceptionHandler());
                    })
                    .then(function (remoteMessages) {
                        return remoteMessageModal(remoteMessages);
                    });
            };
        }]);
});