define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryDurationTemplate", [function () {
        return function (config) {
            var element = angular.element("<nlg-duration-input>");
            if (config.model) {
                element.attr("ng-model", config.model);
            }
            element.attr("hide-days", config.hideDays);
            element.attr("hide-milliseconds", config.hideMilliseconds);
            element.attr("hide-seconds", config.hideSeconds);
            element.attr("hide-minutes", config.hideMinutes);
            return element;
        };
    }]);
});