define(["./remoteModule", "angular"], function (remoteModule, angular) {
    "use strict";

    /**
     * @ngdoc directive
     * @name remoteModule.directive:upload
     * @element input
     * @restrict A
     * @description
     * Diretiva que permite o uso de `ngModel` com `input[type=file]`.
     * Selectiona apenas um arquivo para o modelo.
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *       <input upload ng-model="file">
     *       <pre>{{file.name}}</pre>
     *     </file>
     * </example>
     */
    return remoteModule.directive("upload", [function () {
        return {
            restrict: "A",
            element: "input",
            require: "ngModel",
            link: function ($scope, $element, $attrs, ngModelController) {
                if ($element.attr("type") !== "file") {
                    throw new Error("Input type has to be type=file");
                }

                $scope.$watch($attrs.ngModel, function (newValue) {
                    if (angular.isUndefined(newValue) ||
                        newValue === null ||
                        newValue.length === 0) {
                        $element.val(null);
                    }
                });

                $element.on("change", function () {
                    var self = this;

                    ngModelController.$setViewValue(getFiles());
                    ngModelController.$commitViewValue();

                    function getFiles() {
                        if (self.files.length === 0) {
                            return null;
                        }
                        if ($attrs.multiple) {
                            return self.files;
                        }
                        return self.files[0];
                    }
                });
            }
        };
    }]);
});