define(["./intlPhoneNumberModule"
], function (intlPhoneNumberModule) {
    "use strict";

    intlPhoneNumberModule.config(["ngIntlTelInputProvider", function (ngIntlTelInputProvider) {
        ngIntlTelInputProvider.set({
            onlyCountries: ["us", "br", "mx"],
            preferredCountries: [],
            autoPlaceholder: false,
        });
    }]);
});
