define(["./collectionsModule", "angular", "../arrays/arrays"], function (collectionsModule, angular, arrays) {
    "use strict";

    var hasOwnProperty = {}.hasOwnProperty;

    return collectionsModule.service("HashSet", ["Iterables", "Set", function (Iterables, Set) {
        /**
         * @ngdoc service
         * @name collectionsModule.HashSet
         * @description
         * Implementação de conjuntos.
         * <p>
         * Elementos são colocados numa tabela hash (usando a função hashFunction) e quando
         * ocorrem conflitos em hashs, os elementos são adicionados à um Set.
         *
         * @param {function} hashFunction Função de hash.
         * O resultado de uma hashFunction pode ser um number ou uma string.
         * */
        function HashSet(hashFunction) {
            if (!angular.isFunction(hashFunction)) {
                throw new Error("hashFunction must be a function: " + hashFunction);
            }

            this.hashTable = {};
            this.hashFunction = hashFunction;
        }

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#copyOf
         * @methodOf collectionsModule.HashSet
         * @description
         * Cria uma cópia de um iterable.
         * @param {object[]} iterable Iterable à ser copiado.
         * @param {function} hashFunction Função de hash.
         * @returns {collectionsModule.HashSet} conjunto copiado.
         * */
        HashSet.copyOf = function (iterable, hashFunction) {
            return new HashSet(hashFunction).addAll(iterable);
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#copyOfHashSet
         * @methodOf collectionsModule.HashSet
         * @description
         * Cria uma cópia de um HashSet, usando a mesma função de hash.
         * @param {collectionsModule.HashSet} hashSet Iterable à ser copiado.
         * @returns {collectionsModule.HashSet} conjunto copiado.
         * */
        HashSet.copyOfHashSet = function (hashSet) {
            return new HashSet(hashSet.hashFunction).addAll(hashSet);
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#of
         * @methodOf collectionsModule.HashSet
         * @description
         * Cria um conjunto com os elementos fornecidos.
         * @param {function} hashFunction Função de hash.
         * @param {object...} value varargs de elementos à serem adicionados no conjunto.
         * @returns {collectionsModule.HashSet} conjunto com os elementos fornecidos.
         * */
        HashSet.of = function (hashFunction) {
            return new HashSet(hashFunction).addAll([].slice.call(arguments, 1));
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#add
         * @methodOf collectionsModule.HashSet
         * @description
         * Adiciona um valor ao conjunto. Valores repetidos não são adicionados.
         * @param {*} value valor à ser adicionado.
         * @returns {collectionsModule.HashSet} Este conjunto.
         * */
        HashSet.prototype.add = function (value) {
            get(this.hashTable, this.hashFunction(value)).add(value);
            return this;
        };

        function get(hash, value) {
            if (hasOwnProperty.call(hash, value)) {
                return hash[value];
            }
            return (hash[value] = new Set());
        }

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#addAll
         * @methodOf collectionsModule.HashSet
         * @description
         * Adiciona todos os valores fornecidos ao conjunto.
         * @param {iterable} values valores à serem adicionados.
         * @returns {collectionsModule.HashSet} Este conjunto.
         * */
        HashSet.prototype.addAll = function (values) {
            var self = this;
            Iterables.iterate(values, function (value) {
                self.add(value);
            });
            return self;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#contains
         * @methodOf collectionsModule.HashSet
         * @description
         * Verifica se um valor foi adicionado ao conjunto.
         * @param {*} value Valor à ser verificado.
         * @returns {boolean} Se o elemento pertence ao conjunto.
         * */
        HashSet.prototype.contains = function (value) {
            var hashTable = this.hashTable;
            var hash = this.hashFunction(value);
            return hasOwnProperty.call(hashTable, hash) && hashTable[hash].contains(value);
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#containsAll
         * @methodOf collectionsModule.HashSet
         * @description
         * Verifica se todos os valores fornecidos estão contidos no conjunto.
         * <p>
         * Se nenhum valor for fornecido, é considerado que todos os elementos estão
         * contidos.
         * @param {iterable} values Valores à serem verificados.
         * @returns {boolean} Se os elementos pertencem ao conjunto.
         * */
        HashSet.prototype.containsAll = function (values) {
            var self = this, contains = true;
            Iterables.iterate(values, function (value) {
                contains = contains && self.contains(value);
            });
            return contains;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#remove
         * @methodOf collectionsModule.HashSet
         * @description
         * Remove um valor do conjunto.
         * <p>
         * Se o valor não está presente no conjunto, nada é feito.
         * @param {*} value Valor à ser removido.
         * @returns {collectionsModule.HashSet} Este conjunto.
         * */
        HashSet.prototype.remove = function (value) {
            var hash = this.hashFunction(value);
            var hashTable = this.hashTable;
            var set = get(hashTable, hash).remove(value);
            if (set.isEmpty()) {
                delete hashTable[hash];
            }
            return this;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#removeAll
         * @methodOf collectionsModule.HashSet
         * @description
         * Remove todos os valores fornecidos do conjunto.
         * @param {iterable} values Valores à serem removidos.
         * @returns {collectionsModule.HashSet} Este conjunto.
         * */
        HashSet.prototype.removeAll = function (values) {
            var self = this;
            Iterables.iterate(values, function (value) {
                self.remove(value);
            });
            return self;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#clear
         * @methodOf collectionsModule.HashSet
         * @description
         * Remove todos os valores conjunto.
         * @returns {collectionsModule.HashSet} Este conjunto.
         * */
        HashSet.prototype.clear = function () {
            this.hashTable = {};
            return this;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#size
         * @methodOf collectionsModule.HashSet
         * @description
         * @returns {number} A quantidade de valores neste conjunto.
         * */
        HashSet.prototype.size = function () {
            var count = 0;
            this.each(function () {
                count++;
            });
            return count;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#isEmpty
         * @methodOf collectionsModule.HashSet
         * @description
         * @returns {boolean} Se não tem nenhum elemento no conjunto.
         * */
        HashSet.prototype.isEmpty = function () {
            return Object.keys(this.hashTable).length === 0;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#each
         * @methodOf collectionsModule.HashSet
         * @description
         * Executa uma callback para cada elemento do conjunto.
         * @returns {collectionsModule.HashSet} Este conjunto.
         * */
        HashSet.prototype.each = function (callback) {
            var self = this;
            arrays.each(self.hashTable, function (value) {
                var result;
                value.each(function (value) {
                    return (result = callback(value, self));
                });
                return result;
            });
            return self;
        };

        /**
         * @ngdoc service
         * @name collectionsModule.HashSet#toArray
         * @methodOf collectionsModule.HashSet
         * @description
         * Retorna uma representação do set como um array.
         * @returns {*[]} Este conjunto convertido para array.
         * */
        HashSet.prototype.toArray = function () {
            var result = [];
            this.each(function (value) {
                result.push(value);
            });
            return result;
        };

        return HashSet;
    }]);
});