import analysisMapModule from "./analysisMapModule"
import analysisMapView from "./analysisMapView.html"

analysisMapModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.analysisMap", {
        data: {
            pageTitle: "{{'title.r2.analysisMap' | translate}}"
        },
        url: "/analysisMap",
        template: analysisMapView,
        controller: "analysisMapController"
    })
}])
