define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactorySingleTagInputTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no `singleTagInput` associado
     *
     * @property {expression} getTags
     * Expressão a ser avaliada para obter tags de auto-complete no `singleTagInput` associado
     *
     * @property {expression=} ngChange
     * Expressão a ser avaliada para executar uma função quando houver alteração no model do `singleTagInput` associado
     **/

    /**
     * @ngdoc function
     * @name templateFactorySingleTagInputTemplate.templateFactorySingleTagInputTemplate
     * @description
     * Template utilizado para criar um {@link directives.directive:nlgSingleTagInput singleTagInput}
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     **/
    return templateFactoryModule.service("templateFactorySingleTagInputTemplate", [function () {
        return function (config) {
            var element = angular.element("<nlg-single-tag-input>");

            setAttributeIfValid(element, "ng-model", config.model, "model");
            setAttributeIfValid(element, "get-tags", config.getTags, "getTags");

            if(config.ngChange){
                element.attr("ng-change", config.ngChange);
            }
            if(config.formatTag){
                element.attr("format-tag",config.formatTag );
            }
            if(config.ngDisabled){
                element.attr("ng-disabled", config.ngDisabled);
            }
            if(config.comparator){
                element.attr("comparator", config.comparator);
            }
            element.attr("typeahead-append-to-body", config.typeaheadAppendToBody);

            return element;

            function setAttributeIfValid(element, attr, value, configProperty) {
                if (!value) {
                    throw new Error(configProperty + " undefined for single tag input column config");
                }
                element.attr(attr, value);
                return element;
            }
        };
    }]);
});