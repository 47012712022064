define([
    "./monitoringModule",
    "text!./userPermissions/UserPermissionsView.html",
    "text!./deliveryOverview/monitoringDeliveryOverview.html",
    "text!./transitions/transitionOverview/MonitoringTransitionOverviewView.html",
    "text!./monitoringFollowupOverview/MonitoringFollowupOverviewView.html",
    "text!./document/monitoring/MonitoringPanel.html",
    "text!./occurrenceCategory/search/occurrenceCategorySearchView.html",
    "text!./occurrenceCategory/edition/OccurrenceCategoryEditionView.html",
    "text!./occurrenceCause/edition/OccurrenceCauseEditionView.html",
    "text!./occurrenceCause/search/OccurrenceCauseSearchView.html",
    "text!./occurrences/edition/MonitoringOccurrenceEditionView.html",
    "text!./occurrenceListener/MBROccurrenceListenerView.html",
    "text!./query/monitoringQuery.html",
    "text!./query/monitoringQueryList.html",
    "text!./tripOverview/monitoringTripOverview.html",
    "text!./tripStartOverview/monitoringTripStartOverview.html",
    "text!./monitorableTemperatureOverview/monitorableTemperatureOverview.html",
    "text!./monitorableFollowupDashboardOverview/monitorableFollowupDashboardOverview.html",
    "text!./occurrenceOverview/monitoringOccurrenceOverview.html",
    "text!./query/search/monitoringQuerySearchTemplate.html",
    "text!./query/edition/monitoringQueryEditionTemplate.html",
    "text!./carrierTripOverview/monitoringCarrierTripOverview.html",
    "text!./invoiceDeliveryEffectivenessOverview/monitoringInvoiceDeliveryEffectivenessOverview.html"
], function (monitoringModule,
             userPermissionsTemplate,
             monitoringDeliveryOverviewTemplate,
             monitoringTransitionOverviewTemplate,
             monitoringFollowupOverviewTemplate,
             monitoringPanelTemplate,
             monitoringOccurrenceCategorySearchTemplate,
             monitoringOccurrenceCategoryEditionTemplate,
             monitoringOccurrenceCauseEditionTemplate,
             monitoringOccurrenceCauseSearchTemplate,
             monitoringOccurrenceEditionTemplate,
             mbrOccurrenceListenerTemplate,
             monitoringQueryTemplate,
             monitoringQueryListTemplate,
             monitoringTripOverviewTemplate,
             monitoringTripStartOverviewTemplate,
             monitorableTemperatureOverviewTemplate,
             monitorableFollowupDashboardOverviewTemplate,
             monitoringOccurrenceOverviewTemplate,
             monitoringQuerySearchTemplate,
             monitoringNewQueryTemplate,
             monitoringCarrierTripOverviewTemplate,
             monitoringInvoiceDeliveryEffectivenessOverviewTemplate
) {
    "use strict";

    monitoringModule.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.monitoringPanel", {
                url: "/panel",
                controller: "MonitoringPanelController",
                template: monitoringPanelTemplate,
                data: {
                    group: "group.monitoring.driver.panel",
                    pageTitle: "{{'title.monitoring.monitoringPanel' | translate:$stateParams}}"
                }
            })
            .state("monitoring.deliveryOverview", {
                url: "/deliveryOverview",
                controller: "monitoringDeliveryOverviewController",
                template: monitoringDeliveryOverviewTemplate,
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.delivery",
                    pageTitle: "{{'title.deliveryOverview' | translate:$stateParams}}"
                }
            })
            .state("monitoring.expectedTransitionOverview", {
                url: "/expectedTransitionOverview?reload",
                controller: "MonitoringTransitionOverviewController",
                template: monitoringTransitionOverviewTemplate,
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.transition",
                    pageTitle: "{{'title.expectedTransitionOverview' | translate:$stateParams}}"
                }
            })
            .state("monitoring.followupOverview", {
                url: "/monitoringFollowupOverview?reload",
                controller: "MonitoringFollowupOverviewController",
                template: monitoringFollowupOverviewTemplate,
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }],
                    kpiConfigurations: ["monitoringService", function (monitoringService) {
                        return monitoringService.getKpiConfigurations();
                    }]
                },
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.transition",
                    pageTitle: "{{'title.followupOverview' | translate:$stateParams}}"
                }
            })
            .state("monitoring.tripOverview", {
                url: "/tripOverview",
                controller: "monitoringTripOverviewController",
                template: monitoringTripOverviewTemplate,
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.trip",
                    pageTitle: "{{'title.monitoring.tripOverView' | translate:$stateParams}}"
                }

            })
            .state("monitoring.tripStartOverview", {
                url: "/tripStartOverview",
                controller: "monitoringTripStartOverviewController",
                template: monitoringTripStartOverviewTemplate,
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.trip.start",
                    pageTitle: "{{'title.monitoring.tripStartOverView' | translate:$stateParams}}"
                }

            })
            .state("monitoring.temperatureOverview", {
                url: "/temperatureOverview",
                controller: "monitoringTemperatureOverviewController",
                template: monitorableTemperatureOverviewTemplate,
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.temperature.variation",
                    pageTitle: "{{'title.monitoring.temperatureOverview' | translate:$stateParams}}"
                }

            })
            .state("monitoring.followupDashboardOverview", {
                url: "/followupDashboardOverview",
                controller: "monitorableFollowupDashboardOverviewController",
                template: monitorableFollowupDashboardOverviewTemplate,
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.temperature.variation",
                    pageTitle: "{{'title.monitoring.followupDashboardOverview' | translate:$stateParams}}"
                },
                resolve: {
                    kpiConfigurations: ["monitoringService", function (monitoringService) {
                        return monitoringService.getKpiConfigurations();
                    }]
                },

            })
            .state("monitoring.occurrenceOverview", {
                url: "/occurrenceOverview",
                controller: "monitoringOccurrenceOverviewController",
                template: monitoringOccurrenceOverviewTemplate,
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.occurrence",
                    pageTitle: "{{'title.monitoring.occurrenceOverView' | translate:$stateParams}}"
                }

            })
            .state("monitoring.carrierTripOverview", {
                url: "/carrierTripOverview",
                controller: "monitoringCarrierTripOverviewController",
                template: monitoringCarrierTripOverviewTemplate,
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.trip.carrier",
                    pageTitle: "{{'title.monitoring.carrierTripOverView' | translate:$stateParams}}"
                }

            })
            .state("monitoring.invoiceDeliveryEffectivenessOverview", {
                url: "/invoiceDeliveryEffectivenessOverview",
                controller: "monitoringInvoiceDeliveryEffectivenessOverviewController",
                template: monitoringInvoiceDeliveryEffectivenessOverviewTemplate,
                data: {
                    group: "group.kpi.dashboard.management.view.monitoring.overview.delivery.effectiveness",
                    pageTitle: "{{'title.monitoring.invoiceDeliveryEffectivenessOverview' | translate:$stateParams}}"
                }

            })
            .state("monitoring.monitoringQuery", {
                url: "/query",
                controller: "monitoringQueryListController",
                template: monitoringQueryListTemplate,
                data: {
                    group: "group.report.query.report.monitoring",
                    pageTitle: "{{'title.monitoring.query' | translate:$stateParams}}"
                },
            })
            .state("monitoring.monitoringQueryIdentifier", {
                url: "/query-identifier/:queryID",
                controller: "monitoringQueryController",
                template: monitoringQueryTemplate,
                data: {
                    group: "group.report.query.report.monitoring",
                    pageTitle: "{{'title.monitoring.query' | translate:$stateParams}}"
                },
                resolve: {
                    query: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.findQueryById($stateParams.queryID).then((report) => {
                            return {id: report.id, sourceId: report.sourceId};
                        })
                    }]
                }
            })
            .state("monitoring.monitoringQuerySearch", {
                url: "/query/search",
                controller: "monitoringQuerySearchController",
                template: monitoringQuerySearchTemplate,
                data: {
                    group: "group.monitoring.query",
                    pageTitle: "{{'title.monitoring.query' | translate:$stateParams}}"
                }
            })
            .state("monitoring.newQuery", {
                url: "/query/edition",
                controller: "monitoringQueryEditionController",
                template: monitoringNewQueryTemplate,
                data: {
                    group: "group.monitoring.query",
                    pageTitle: "{{'title.monitoring.newQuery' | translate:$stateParams}}"
                },
                resolve: {
                    query: ["$stateParams", function () {
                    }]
                }

            })
            .state("monitoring.queryEdition", {
                url: "/query/edition/:queryID",
                controller: "monitoringQueryEditionController",
                template: monitoringNewQueryTemplate,
                data: {
                    group: "group.monitoring.query",
                    pageTitle: "{{'title.monitoring.queryEdition' | translate:$stateParams}}"
                },
                resolve: {
                    query: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.findQueryById($stateParams.queryID);
                    }]
                }
            })
            .state("monitoring.queryCopy", {
                url: "/query/copy/:queryID",
                controller: "monitoringQueryEditionController",
                template: monitoringNewQueryTemplate,
                data: {
                    group: "group.monitoring.query",
                    pageTitle: "{{'title.monitoring.queryCopy' | translate:$stateParams}}"
                },
                resolve: {
                    query: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.copyQuery($stateParams.queryID);
                    }]
                }
            });
    }]);
});
