define([
    "./genericParamsModule",
    "text!./nlgGenericParamData.html",
    "./GenericParamDataController"
], function (crudModule, template) {
    "use strict";

    return crudModule.directive("nlgGenericParamData", [function () {
        return {
            restrict: "E",
            template: template,
            controller: "GenericParamDataController",
            scope: {
                definition: "<",
                ngDisabled: "<?nlgDisabled",
                registerApi: "&?",
                hideButtonsColumn: "=?",
                filterService: "=?"
            }
        };
    }]);
});