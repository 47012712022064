define(["./occurrencesModule", "text!./nlgMonitorableOccurrences.html"], function (module, template) {
    "use strict";

    return module.directive("nlgMonitorableOccurrences", [function () {
        return {
            restrict: "E",
            scope: {
                monitorable: "="
            },
            template: template,
            controller: ["$scope", "monitoringService", "occurrenceService", "commentService", "loadingService", "remoteExceptionHandler", function ($scope, monitoringService, occurrenceService, commentService, loadingService, remoteExceptionHandler) {
                var paginationApi = null,
                    template = {};

                $scope.serviceName = occurrenceService.getFilterServiceUrl();

                $scope.tableOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(occurrenceService.filterForMonitorable($scope.monitorable.id, template, firstResult, maxResults))
                                .catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                    }
                };

                $scope.search = function (patches) {
                    template = patches;
                    return paginationApi.seekPage(1);
                };

                $scope.openComments = function (occurrence) {
                    occurrence.$isOpen = !occurrence.$isOpen;
                    if (occurrence.$isOpen) {
                        return loadOccurrenceComments(occurrence);
                    }
                };

                $scope.createNewOccurrence = function () {
                    return occurrenceService.createNewOccurrenceForMonitorable($scope.monitorable.id)
                        .then(function () {
                            return $scope.search(template)
                        });
                };

                $scope.getUrlDownloadAttachment = function (comment) {
                    return commentService.getAttachmentUrlForOccurrence(comment);
                };

                $scope.submitComment = function (occurrence, comment) {
                    return commentService.addOccurrenceComment(occurrence.id, comment)
                        .then(function () {
                            return loadOccurrenceComments(occurrence);
                        });
                };

                $scope.canCreateOccurrence = function (monitorable) {
                    return monitorable.completion.status !== 'br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CANCELLED'
                }
                function loadOccurrenceComments(occurrence) {
                    return loadingService(commentService.findOccurrenceComments(occurrence.id))
                        .catch(remoteExceptionHandler())
                        .then(function (comments) {
                            occurrence.comments = comments;
                        })
                }

                $scope.stopPropagation = function ($event) {
                    if ($event) {
                        $event.stopPropagation();
                    }
                };
            }]
        };
    }])
});