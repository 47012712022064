define(["./nlgTableModule", "../objects/objects"], function (nlgTableModule, objects) {
    "use strict";

    /**
     * @ngdoc filter
     * @name nlgTableModule.filter:tableSortFor
     * @param {Object} tableOptions objeto de configuração da tabela seguindo o formato
     * {@link directives.directive.nlgTable:tableOptions tableOptions}. Deve ser o mesmo `tableOptions` fornecido
     * ao componente de tabela a ter o filtro aplicado.
     * @return {Array} coleção recebida pelo `ngRepeat` ordenada conforme parâmetros de tableOptions.
     * @description
     * Filtro a ser usado para ordenar a saída do filtro {@link ngRepeat}.
     * Deve ser usado junto com o {@link directives.directive:nlgTable componente de tabela}.
     */
    return nlgTableModule.filter("tableSortFor", ["$filter", function ($filter) {
        return function (collection, tableOptions) {
            if (!tableOptions) {
                throw new Error("Table options must be passed.");
            }
            var sorting = tableOptions.sorting;
            if (objects.isUndefined(sorting) ||
                objects.isUndefined(sorting.predicate) ||
                objects.isUndefined(sorting.descending)) {
                return collection;
            }
            return $filter("orderBy")(collection, sorting.predicate, sorting.descending);
        };
    }]);
});