define([
    "./dashboardModule",
    "text!./dashboard.html",
    "lodash"
], function (dashboardModule, template, _) {
    "use strict";

    dashboardModule.directive("dashboard", [function () {
        return {
            "restrict": "E",
            "scope": {
                "config": "=",
                "domain": "@?",
                "presentationConfiguration":"=?",
            },
            "template": template,
            "require": "dashboard",
            link: function (scope, element, attrs, dashboardController) {
                scope.$watch("config", function (config) {
                    dashboardController.updateDashboardsInfo(config.dashboardSourceId);
                });
            },
            controller: ["$scope", "templateFactory", "loadingService", "remoteExceptionHandler", "dashboardServiceFactory", "dashboardDomain",
                function dashboardController($scope, templateFactory, loadingService, remoteExceptionHandler, dashboardServiceFactory, dashboardDomain) {
                    var dashboardService = dashboardServiceFactory($scope.domain || dashboardDomain.getDefaultDomain());
                    this.updateDashboardsInfo = function (dashboardSourceId) {

                        loadingService(dashboardService.info(dashboardSourceId)).then(function (dashboardConfig) {
                            $scope.$broadcast("$dashboardConfigUpdate", dashboardConfig);
                        }).catch(remoteExceptionHandler());
                    };

                    this.filter = function (dashboardSourceId, filterConfigs, choosables) {
                        loadingService(dashboardService.query(dashboardSourceId, filterConfigs, choosables)).then(function (queryResponse) {
                            var dataByChart = {};
                            queryResponse.results.forEach(function (chartResult) {
                                var newData = _.map(chartResult.results, function (eachResult) {
                                    var map = {};
                                    eachResult.elements.forEach(function (element) {
                                        map[element.alias] = element.value;
                                    });
                                    return map;
                                });
                                newData.choosables = choosables;
                                newData.choosableInfo = $scope.chartInfosBySourceId[chartResult.chartSourceId].choosablesInfo;

                                dataByChart[chartResult.chartSourceId] = newData;
                            });
                            $scope.$broadcast("$dashboardDataUpdate", dataByChart);
                        }).catch(remoteExceptionHandler());
                    };

                    this.export = function (dashboardSourceId, chartSourceId, maxResult, firstResult) {
                        return dashboardService.exportChart(dashboardSourceId, chartSourceId, $scope.appliedFilter, firstResult, maxResult);
                    };

                    this.countExport = function (dashboardSourceId, chartSourceId) {
                        return dashboardService.countExport(dashboardSourceId, chartSourceId, $scope.appliedFilter);
                    };

                    this.getTemplateFactory = function () {
                        return templateFactory;
                    };
                }]
        };
    }]);
});
