define([
    "./transitionFailureReasonModule"
], function (module) {
    "use strict";

    module.service("transitionFailureReasonService", [
        "monitoringService",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        function (monitoringService, loadingService, remoteExceptionHandler, messagesModal) {
            var self = this;

            self.save = function (transitionFailureReason) {
                return loadingService(monitoringService.saveTransitionFailureReason(transitionFailureReason))
                    .catch(remoteExceptionHandler())
                    .then(function (id) {
                        return messagesModal("dialog.success", {
                            keyBundle: "monitoring.transition.failure.reason.save.success"
                        }).then(function () {
                            return id;
                        });
                    });
            };

            self.delete = function (id) {
                return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                    .then(function () {
                        return loadingService(monitoringService.deleteTransitionFailureReason(id))
                            .catch(remoteExceptionHandler());
                    }).then(function () {
                        return messagesModal("dialog.success", {
                            keyBundle: "entityFilter.delete.ok"
                        });
                    });
            };

            return self;
        }]);
});