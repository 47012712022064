define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckerPercentageRangeReason.html",
], function (module, template) {
    "use strict";

    return module.directive("nlgHealthCheckerPercentageRangeReason", [function () {
        return {
            template: template,
            scope: {
                reason: "<",
                inverted: "&?"
            },
            controller: ["$scope", function ($scope) {
                if (!$scope.inverted) {
                    $scope.inverted = function () {
                        return false;
                    };
                }

                $scope.getOkLength = function () {
                    return !inverted() ? 100 - reason().warningValue : reason().errorValue;
                };
                $scope.getWarningLength = function () {
                    return !inverted() ? reason().errorValue - reason().warningValue : reason().warningValue - reason().errorValue;
                };
                $scope.getErrorLength = function () {
                    return !inverted() ? 100 - reason().errorValue : 100 - reason().warningValue;
                };

                $scope.getValuePosition = function () {
                    return reason().value;
                };
                $scope.getWarningPosition = function () {
                    return reason().warningValue;
                };
                $scope.getErrorPosition = function () {
                    return reason().errorValue;
                };

                function reason() {
                    return $scope.reason;
                }

                function inverted() {
                    return $scope.inverted();
                }
            }]
        };
    }]);
});
