define(["./occurrencesModule", "text!./nlgOccurrenceHeader.html"], function (module, template) {
    "use strict";

    return module.directive("nlgOccurrenceHeader", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                occurrence: "=",
                enabled: "&?"
            },
            controller: ["$scope", "monitoringService", "occurrenceService", "occurrenceCauseService", "OccurrenceCauseIcon",
                function ($scope, monitoringService, occurrenceService, occurrenceCauseService, OccurrenceCauseIcon) {

                monitoringService.findMonitorableByOccurrenceId($scope.occurrence.id)
                    .then(function (monitorables) {
                        $scope.monitorables = monitorables;
                    });

                occurrenceCauseService.getOccurrenceCause($scope.occurrence)
                    .then(function (cause) {
                        $scope.icon = OccurrenceCauseIcon[cause.icon] || OccurrenceCauseIcon["br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.WARNING"];
                    });

                $scope.getIndicator = function(occurrence) {
                    return occurrenceService.getIndicator(occurrence);
                };

                $scope.openTimeDuration = function(occurrence) {
                    return occurrenceService.openTimeDuration(occurrence);
                };

                $scope.durationInStatus = function (occurrence) {
                    return occurrenceService.durationInStatus(occurrence);
                };

                $scope.calculateDuration = function (timestamp) {
                    return occurrenceService.calculateDuration(timestamp);
                };
            }]
        };
    }]);
});