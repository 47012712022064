define([
        "./nlgLGPDModule",
        "require"],
    function (app, require) {
        "use strict";

        app.config(["$stateProvider", function ($stateProvider) {
            $stateProvider
                .state("lgpd.newLgpdEntity", {
                    url: "/entity/new",
                    templateUrl: require("link!./nlgLGPD.html"),
                    controller: "nlgLGPDEditionController",
                    data: {
                        group: "group.manager.lgpd.entity",
                        pageTitle: "{{'title.lgpd.entity.create' | translate}}",
                        dontRedirectOnForbidden: true
                    },
                    resolve: {
                        originalEntity: function () {
                            return null;
                        }
                    },
                    params: {
                        reload: "true"
                    }
                })
                .state("lgpd.editLgpdEntity", {
                    url: "/entity/edit/:lgpdEntityId/:artifactName",
                    templateUrl: require("link!./nlgLGPD.html"),
                    controller: "nlgLGPDEditionController",
                    data: {
                        group: "group.manager.lgpd.entity",
                        pageTitle: "{{'title.lgpd.entity.edition' | translate}}",
                        dontRedirectOnForbidden: true
                    },
                    resolve: {
                        originalEntity: ["$stateParams", "lgpdApiByArtifact", function ($stateParams, lgpdApiByArtifact) {
                            return lgpdApiByArtifact.getApiOfArtifact($stateParams.artifactName).entityGetDefinitionById($stateParams.lgpdEntityId)
                                .then(function (response) {
                                    response.data.artifactName = $stateParams.artifactName;
                                    return response.data;
                                });
                        }]
                    },
                    params: {
                        reload: "true"
                    }
                })
                .state("lgpd.copyLgpdEntity", {
                    url: "/entity/copy/:lgpdEntityId/:artifactName",
                    templateUrl: require("link!./nlgLGPD.html"),
                    controller: "nlgLGPDEditionController",
                    data: {
                        group: "group.manager.lgpd.entity",
                        pageTitle: "{{'title.lgpd.entity.copy' | translate}}",
                        dontRedirectOnForbidden: true
                    },
                    resolve: {
                        originalEntity: ["$stateParams", "lgpdApiByArtifact", function ($stateParams, lgpdApiByArtifact) {
                            return lgpdApiByArtifact.getApiOfArtifact($stateParams.artifactName).entityCopyGetDefinitionById($stateParams.lgpdEntityId)
                                .then(function (response) {
                                    response.data.artifactName = $stateParams.artifactName;
                                    return response.data;
                                });
                        }]
                    },
                    params: {
                        reload: "true"
                    }
                })
                .state("lgpd.logLgpdEntity", {
                    url: "/entity/log/:artifactName/:id/version/:version",
                    templateUrl: require("link!./nlgLGPD.html"),
                    controller: "nlgLGPDEditionController",
                    data: {
                        group: "group.manager.lgpd.entity",
                        pageTitle: "{{'title.lgpd.entity.log' | translate}}",
                    },
                    resolve: {
                        originalEntity: ["$stateParams", "nlgLGPDService", function ($stateParams, nlgLGPDService) {
                            return nlgLGPDService.findVersion($stateParams.id, $stateParams.version, $stateParams.artifactName)
                                .then(function (request) {
                                    request.data.artifactName = $stateParams.artifactName;
                                    return request.data;
                                });
                        }]
                    }
                })
                .state("lgpd.lgpdAnonymizeEntity", {
                    url: "/entity/anonymize",
                    templateUrl: require("link!./nlgLGPDAnonymize.html"),
                    controller: "nlgLGPDAnonymizeController",
                    data: {
                        group: "group.manager.lgpd.entity",
                        pageTitle: "{{'title.lgpd.entity.anonymize' | translate}}",
                        dontRedirectOnForbidden: true
                    }
                })
                .state("lgpd.lgpdObfuscationEntity", {
                    url: "/entity/obfuscation",
                    templateUrl: require("link!./obfuscationProfile/nlgLGPDObfuscation.html"),
                    controller: "nlgLGPDObfuscationController",
                    data: {
                        group: "group.manager.lgpd.obfuscation",
                        pageTitle: "{{'title.lgpd.entity.obfuscation' | translate}}",
                        dontRedirectOnForbidden: true
                    }
                })
                .state("lgpd.logsLgpd", {
                    url: "/logs",
                    templateUrl: require("link!./logs/nlgLGPDLogs.html"),
                    controller: "nlgLGPDLogsController",
                    data: {
                        group: "group.manager.lgpd.logs",
                        pageTitle: "{{'title.lgpd.logs' | translate}}",
                        dontRedirectOnForbidden: true
                    }
                })
                .state("lgpd.lgpdSQL", {
                    url: "/script-sql",
                    templateUrl: require("link!./sql/nlgLGPDSQLSearch.html"),
                    controller: "nlgLGPDSQLSearchController",
                    data: {
                        group: "group.manager.lgpd.sql",
                        pageTitle: "{{'title.lgpd.sql' | translate}}",
                        dontRedirectOnForbidden: true
                    },
                    params: {
                        reload: "true"
                    },
                })
                .state("lgpd.newLgpdSQL", {
                    url: "/script-sql/new",
                    templateUrl: require("link!./sql/nlgLGPDSQL.html"),
                    controller: "nlgLGPDSQLController",
                    data: {
                        group: "group.manager.lgpd.sql",
                        pageTitle: "{{'title.lgpd.sql.create' | translate}}",
                        dontRedirectOnForbidden: true
                    },
                    resolve: {
                        lgpdSQLDefinition: [emptyDefinition]
                    },
                    params: {
                        reload: "true"
                    }
                })
                .state("lgpd.editLgpdSQL", {
                    url: "/script-sql/edit/:lgpdDefinitionId/:artifactName",
                    templateUrl: require("link!./sql/nlgLGPDSQL.html"),
                    controller: "nlgLGPDSQLController",
                    data: {
                        group: "group.manager.lgpd.sql",
                        pageTitle: "{{'title.lgpd.sql.edition' | translate}}",
                        dontRedirectOnForbidden: true
                    },
                    resolve: {
                        lgpdSQLDefinition: ["$stateParams", "lgpdApiByArtifact", function ($stateParams, lgpdApiByArtifact) {
                            return lgpdApiByArtifact.getApiOfArtifact($stateParams.artifactName).sqlGetDefinitionById($stateParams.lgpdDefinitionId)
                                .then(function (response) {
                                    response.data.artifactName = $stateParams.artifactName;
                                    return response.data;
                                });
                        }]
                    },
                    params: {
                        reload: "true",
                    }
                })
                .state("lgpd.copyLgpdSQL", {
                    url: "/script-sql/copy/:lgpdDefinitionId/:artifactName",
                    templateUrl: require("link!./sql/nlgLGPDSQL.html"),
                    controller: "nlgLGPDSQLController",
                    data: {
                        group: "group.manager.lgpd.sql",
                        pageTitle: "{{'title.lgpd.sql.copy' | translate}}",
                        dontRedirectOnForbidden: true
                    },
                    resolve: {
                        lgpdSQLDefinition: ["$stateParams", "lgpdApiByArtifact", function ($stateParams, lgpdApiByArtifact) {
                            return lgpdApiByArtifact.getApiOfArtifact($stateParams.artifactName).sqlCopyById($stateParams.lgpdDefinitionId)
                                .then(function (response) {
                                    response.data.artifactName = $stateParams.artifactName;
                                    return response.data;
                                });
                        }]
                    },
                    params: {
                        reload: "true",
                    }
                })
                .state("lgpd.logLgpdSQL", {
                    url: "/script-sql/log/:id/version/:version/:artifactName",
                    controller: "nlgLGPDSQLController",
                    templateUrl: require("link!./sql/nlgLGPDSQL.html"),
                    data: {
                        group: "group.manager.lgpd.sql",
                        pageTitle: "{{'title.lgpd.sql.log' | translate}}",
                    },
                    resolve: {
                        lgpdSQLDefinition: ["$stateParams", "nlgLGPDSQLService", function ($stateParams, sqlService) {
                            return sqlService.findVersion($stateParams.id, $stateParams.version, $stateParams.artifactName)
                                .then(function (response) {
                                    response.data.artifactName = $stateParams.artifactName;
                                    return response.data;
                                });
                        }]
                    }
                })
                .state("lgpd.lgpdAttribute", {
                    url: "/attribute",
                    controller: "LGPDAttributableControllerSearch",
                    templateUrl: require("link!./attribute/searchLGPDAttributable.html"),
                    data: {
                        group: "group.manager.lgpd.attribute"
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.attribute",
                })
                .state("lgpd.newLgpdAttribute", {
                    url: "/attribute/new",
                    controller: "LGPDAttributableControllerEdition",
                    templateUrl: require("link!./attribute/newLGPDAttributable.html"),
                    data: {
                        group: "group.manager.lgpd.attribute"
                    },
                    resolve: {
                        lgpdAttributableDefinition: [emptyDefinition]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.attribute.create"
                })
                .state("lgpd.editLgpdAttribute", {
                    url: "/attribute/edit/:lgpdDefinitionId",
                    controller: "LGPDAttributableControllerEdition",
                    templateUrl: require("link!./attribute/newLGPDAttributable.html"),
                    data: {
                        group: "group.manager.lgpd.attribute"
                    },
                    resolve: {
                        lgpdAttributableDefinition: ["$stateParams", "LGPDAttributableService", function ($stateParams, LGPDAttributableService) {
                            return LGPDAttributableService.getEntity($stateParams.lgpdDefinitionId).then(function (request) {
                                return request.data;
                            });
                        }]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.attribute.edition"
                })
                .state("lgpd.copyLgpdAttribute", {
                    url: "/attribute/copy/:lgpdDefinitionId",
                    controller: "LGPDAttributableControllerEdition",
                    templateUrl: require("link!./attribute/newLGPDAttributable.html"),
                    data: {
                        group: "group.manager.lgpd.attribute"
                    },
                    resolve: {
                        lgpdAttributableDefinition: ["$stateParams", "LGPDAttributableService", function ($stateParams, attributableService) {
                            return attributableService.copy($stateParams.lgpdDefinitionId).then(function (request) {
                                return request.data;
                            });
                        }]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.attribute.copy"
                })
                .state("lgpd.logLgpdAttribute", {
                    url: "/attribute/log/:id/version/:version",
                    controller: "LGPDAttributableControllerEdition",
                    templateUrl: require("link!./attribute/newLGPDAttributable.html"),
                    data: {
                        group: "group.manager.lgpd.attribute"
                    },
                    resolve: {
                        lgpdAttributableDefinition: ["$stateParams", "LGPDAttributableService", function ($stateParams, attributableService) {
                            return attributableService.findVersion($stateParams.id, $stateParams.version)
                                .then(function (request) {
                                    return request.data;
                                });
                        }]
                    },
                    stateTitle: "title.lgpd.attribute.log"
                })
                .state("lgpd.lgpdClassification", {
                    url: "/classification",
                    controller: "LGPDClassifiableControllerSearch",
                    templateUrl: require("link!./classification/searchLGPDClassifiable.html"),
                    resolve: {
                        reload: ["$stateParams", function ($stateParams) {
                            return $stateParams.reload === "true";
                        }]
                    },
                    data: {
                        group: "group.manager.lgpd.classification"
                    },
                    stateTitle: "title.lgpd.classification"
                })
                .state("lgpd.newLgpdClassification", {
                    url: "/classification/new",
                    controller: "LGPDClassifiableControllerEdition",
                    templateUrl: require("link!./classification/newLGPDClassifiable.html"),
                    data: {
                        group: "group.manager.lgpd.classification"
                    },
                    resolve: {
                        lgpdClassifiableDefinition: [emptyDefinition]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.classification.create"
                })
                .state("lgpd.editLgpdClassification", {
                    url: "/classification/edit/:lgpdDefinitionId",
                    controller: "LGPDClassifiableControllerEdition",
                    templateUrl: require("link!./classification/newLGPDClassifiable.html"),
                    data: {
                        group: "group.manager.lgpd.classification"
                    },
                    resolve: {
                        lgpdClassifiableDefinition: ["$stateParams", "LGPDClassifiableService", function ($stateParams, classifiableService) {
                            return classifiableService.getEntity($stateParams.lgpdDefinitionId).then(function (request) {
                                return request.data;
                            });
                        }]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.classification.edition"
                })
                .state("lgpd.copyLgpdClassification", {
                    url: "/classification/copy/:lgpdDefinitionId",
                    controller: "LGPDClassifiableControllerEdition",
                    templateUrl: require("link!./classification/newLGPDClassifiable.html"),
                    data: {
                        group: "group.manager.lgpd.classification"
                    },
                    resolve: {
                        lgpdClassifiableDefinition: ["$stateParams", "LGPDClassifiableService", function ($stateParams, classifiableService) {
                            return classifiableService.copy($stateParams.lgpdDefinitionId).then(function (request) {
                                return request.data;
                            });
                        }]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.classification.copy"
                })
                .state("lgpd.logLgpdClassification", {
                    url: "/classification/log/:id/version/:version",
                    controller: "LGPDClassifiableControllerEdition",
                    templateUrl: require("link!./classification/newLGPDClassifiable.html"),
                    data: {
                        group: "group.manager.lgpd.classification"
                    },
                    resolve: {
                        lgpdClassifiableDefinition: ["$stateParams", "LGPDClassifiableService", function ($stateParams, classifiableService) {
                            return classifiableService.findVersion($stateParams.id, $stateParams.version)
                                .then(function (request) {
                                    return request.data;
                                });
                        }]
                    },
                    stateTitle: "title.lgpd.classification.log"
                })
                .state("lgpd.lgpdGenericParam", {
                    url: "/generic-param?reload",
                    controller: "LGPDGenericParamControllerSearch",
                    templateUrl: require("link!./genericParam/searchLGPDGenericParam.html"),
                    resolve: {
                        reload: ["$stateParams", function ($stateParams) {
                            return $stateParams.reload === "true";
                        }]
                    },
                    data: {
                        group: "group.manager.lgpd.genericparam"
                    },
                    stateTitle: "title.lgpd.generic.param"
                })
                .state("lgpd.newLgpdGenericParam", {
                    url: "/generic-param/new",
                    controller: "LGPDGenericParamControllerEdition",
                    templateUrl: require("link!./genericParam/newLGPDGenericParam.html"),
                    data: {
                        group: "group.manager.lgpd.genericparam"
                    },
                    resolve: {
                        lgpdGenericParamDefinition: [emptyDefinition]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.generic.param.create"
                })
                .state("lgpd.editLgpdGenericParam", {
                    url: "/generic-param/edit/:lgpdDefinitionId",
                    controller: "LGPDGenericParamControllerEdition",
                    templateUrl: require("link!./genericParam/newLGPDGenericParam.html"),
                    data: {
                        group: "group.manager.lgpd.genericparam"
                    },
                    resolve: {
                        lgpdGenericParamDefinition: ["$stateParams", "LGPDGenericParamService", function ($stateParams, genericParamService) {
                            return genericParamService.getEntity($stateParams.lgpdDefinitionId).then(function (request) {
                                return request.data;
                            });
                        }]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.generic.param.edition"
                })
                .state("lgpd.copyLgpdGenericParam", {
                    url: "/generic-param/copy/:lgpdDefinitionId",
                    controller: "LGPDGenericParamControllerEdition",
                    templateUrl: require("link!./genericParam/newLGPDGenericParam.html"),
                    data: {
                        group: "group.manager.lgpd.genericparam"
                    },
                    resolve: {
                        lgpdGenericParamDefinition: ["$stateParams", "LGPDGenericParamService", function ($stateParams, genericParamService) {
                            return genericParamService.copy($stateParams.lgpdDefinitionId).then(function (request) {
                                return request.data;
                            });
                        }]
                    },
                    params: {
                        reload: "true"
                    },
                    stateTitle: "title.lgpd.generic.param.copy"
                })
                .state("lgpd.logLgpdGenericParam", {
                    url: "/generic-param/log/:id/version/:version",
                    controller: "LGPDGenericParamControllerEdition",
                    templateUrl: require("link!./genericParam/newLGPDGenericParam.html"),
                    data: {
                        group: "group.manager.lgpd.genericparam"
                    },
                    resolve: {
                        lgpdGenericParamDefinition: ["$stateParams", "LGPDGenericParamService", function ($stateParams, genericParamService) {
                            return genericParamService.findVersion($stateParams.id, $stateParams.version)
                                .then(function (request) {
                                    return request.data;
                                });
                        }]
                    },
                    stateTitle: "title.lgpd.generic.param.log"
                });

            function emptyDefinition() {
                return {
                    entityName: "",
                    entityField: "",
                    presentationName: "",
                    presentationField: "",
                    personal: false,
                    sensitive: false,
                    anonymizable: false,
                    justification: null
                };
            }

        }]);

    });


