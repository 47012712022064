define(["./formModule"], function (formModule) {
    "use strict";

    /**
     * @ngdoc directive
     * @name formModule.directive:nlgIndeterminate
     * @element input
     * @restrict A
     * @description
     * Diretiva para adicionar a propriedade `indeterminate` em checkbox
     * apenas quando a expressão retornar `true`.
     * <br>
     * Esta diretiva não afeta o modelo que estiver atrelado ao checkbox, sendo
     * que o estado `indeterminate` é apenas para servir como uma dica visual para
     * o usuário.
     *
     * @param {expression} nlgIndeterminate
     * Expressão que será observada. Quando o valor booleano `true` for retornado,
     * a propriedade `indeterminate` do checkbox será definida. Caso contrário,
     * `indeterminate` não será definida.
     *
     * @example
     * <example module="FrontEndWebCommons">
     *     <file name="index.html">
     *       <input type="checkbox" nlg-indeterminate="selection" disabled>
     *
     *       <p>
     *           Use este checkbox para habilitar ou desabilitar o indeterminate:
     *           <input type="checkbox" ng-model="selection">
     *       </p>
     *     </file>
     * </example>
     */
    return formModule.directive("nlgIndeterminate", [function () {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                if (element.prop("tagName") !== "INPUT" || element.attr("type").toUpperCase() !== "CHECKBOX") {
                    throw new Error("element must be an input[type=checkbox]");
                }
                scope.$watch(attrs.nlgIndeterminate, function (indeterminate) {
                    element.prop("indeterminate", indeterminate === true);
                });
            }
        };
    }]);
});