import transitionsConfigModule from "./transitionsConfigModule"
import server from "../server"

transitionsConfigModule.service("transitionsConfigService", ["$http", function($http) {

    return {
        getTransitionsConfig: getTransitionsConfig,
        save: save
    }

    function getTransitionsConfig() {
        return $http.get(server.getServiceUrl("transitions-config"))
            .then((response) => response.data)
    }

    function save (transitionsConfig)  {
        return $http.post(server.getServiceUrl("transitions-config"), transitionsConfig)
    }
}])