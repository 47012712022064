define(["./routingConfigModule"], function (routingConfigModule) {
    "use strict";

    /**
     * @ngdoc object
     * @name routingConfigModule.FlowType
     */
    return routingConfigModule.constant("FlowType", {
        SELECTION: "SELECTION",
        ANALYSIS: "ANALYSIS",
		CANCEL: "CANCEL",
        DOCK_ALLOCATION: "DOCK_ALLOCATION",
        MONITORING: "MONITORING",
        KPI: "KPI",
        PROGRAMMING: "PROGRAMMING",
        ORGANIZE_WITH_RESTRICTION: "ORGANIZE_WITH_RESTRICTION",
        CUSTOM_OPERATIONS: "CUSTOM_OPERATIONS",
        INTEGRATION: "INTEGRATION"
    });
});