import spreadsheetModule from "./spreadsheetModule"
import * as xlsx from "xlsx-js-style"
import examples from "./examples.json"
import definitions from "./definitions.json"
import headers from "./headers.json"
import urlDefinitions from "./urlDefinitions.json"
import server from "../server"

spreadsheetModule.service("spreadsheetService", [
    "$translate",
    "$q",
    "loadingService",
    "$http",
    "remoteExceptionHandler",
    "newSpreadsheetConverter",
    "spreadsheetExportService",
    ($translate, $q, loadingService, $http, remoteExceptionHandler, newSpreadsheetConverter, spreadsheetExportService) => {
        const baseURL = server.getServiceUrl("spreadsheet-integration")

        function importRegisterErrors(id, entityName) {
            return loadingService($http.get(server.getServiceUrl("spreadsheet-integration-error") + "find-by-register-id/" + id))
                .catch(remoteExceptionHandler())
                .then(function (response) {
                    const rows = []
                    response.data.forEach(function (data) {
                        let {payload, payload: {classifications, attributes}} = data
                        let fullPayload = {...payload, ...classifications, ...attributes}
                        //Só é usado para Order(Pedido)
                        newSpreadsheetConverter.convertEntityToPayload(fullPayload, entityName)
                        const mappedErrors = {}
                        data.errors.forEach(function (error) {
                            mappedErrors[error.columnName] = error.error
                        })
                        const row = headers[entityName].map(function (headerName) {
                            return getCell(nullToEmpty(fullPayload[headerName]), headerName, mappedErrors)
                        })
                        rows.push(row)
                    })
                    return spreadsheetExportService.exportSpreadsheet(
                        entityName, $translate.instant("new.spreadsheet." + entityName + ".file.name.error"),
                        headers[entityName], rows, definitions[entityName])
                })
        }

        function getCell(value, columnName, errors) {
            let mappeableError = errors["internalError"]
            if (!mappeableError) {
                mappeableError = errors[columnName]
            }
            if (!mappeableError) {
                return {
                    v: value,
                    t: "s",
                    s: {alignment: {wrapText: true}}
                }
            }
            const c = []
            c.hidden = true
            c.push({a: "SheetJS", t: $translate.instant(mappeableError)})
            return {
                v: value,
                t: "s",
                c: c,
                s: {fill: {fgColor: {rgb: "ee4e4e"}}, alignment: {wrapText: true}}
            }
        }

        function nullToEmpty(value) {
            return !value ? "" : value
        }

        function newSpreadsheetImport(selectedEntity, file) {
            return loadingService($q((resolve, reject) => {
                const fileReader = new FileReader()
                fileReader.onload = () => {
                    const workbook = xlsx.read(fileReader.result, {
                        type: "binary", cellDates: true, sheetStubs: true
                    })
                    const result = {}
                    const sheetNames = workbook.SheetNames
                    if (!sheetNames.includes(selectedEntity)) {
                        reject({
                            bundle: "import.exception.invalid.sheetNames",
                            param: [sheetNames[0], selectedEntity],
                            message: "Invalid sheet length or name"
                        })
                        return
                    }
                    result[selectedEntity] = xlsx.utils.sheet_to_json(workbook.Sheets[selectedEntity]).map(function (payload) {
                        return newSpreadsheetConverter.convertPayloadToEntity(selectedEntity, payload)
                    })
                    if (result[selectedEntity] === undefined || result[selectedEntity].length === 0) {
                        reject({bundle: "import.exception.emptySheet", message: "Sheet with no rows"})
                        return
                    }
                    $http.post(server.getServiceUrl("new-spreadsheet-" + urlDefinitions[selectedEntity]) + "?entityName=" + selectedEntity, result[selectedEntity])
                        .then((response) => {
                                resolve(result)
                                response.data
                        })
                        .catch(function (e) {
                                return reject(e.data)
                            }
                        )
                }
                fileReader.readAsBinaryString(file)
            }))
        }

        function generateModel(selectedEntity) {
            generateEntityModel(examples[selectedEntity], definitions[selectedEntity], selectedEntity, $translate.instant("new.spreadsheet." + selectedEntity + ".file.name"))
        }

        const generateEntityModel = (example, definitions, sheetName, fileName) => {
            let headers = Object.keys(example[0])
            let rowValues = []
            example.forEach(function(dto){
                rowValues.push(Object.values(dto))
            })
            return spreadsheetExportService.exportSpreadsheet(sheetName, fileName, headers, rowValues, definitions)
        }

        const filterRegisters = ({patches, firstResult, maxResults}) => {
            const pageOptions = {
                firstResult: firstResult,
                maxResults: maxResults
            }
            return loadingService($http.post(baseURL, patches, {params: pageOptions}))
                .catch(remoteExceptionHandler())
                .then((response) => response.data)
        }

        function newSpreadsheetCancelImport(entityName, registerId) {
            return $http.post(server.getServiceUrl("new-spreadsheet-cancel-" + urlDefinitions[entityName]) + "?registerId=" + registerId)
        }

        return {
            newSpreadsheetImport: newSpreadsheetImport,
            generateModel: generateModel,
            filterRegisters: filterRegisters,
            importRegisterErrors: importRegisterErrors,
            newSpreadsheetCancelImport: newSpreadsheetCancelImport
        }
    }])
