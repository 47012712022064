define([
    "./restrictionConfigurationModule",
    "text!./restrictionConfigurationTemplate.html",
], function (module, restrictionConfigurationTemplate) {
    "use strict";
    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.restrictionConfiguration", {
                url: "/restrictionConfiguration",
                controller: "restrictionConfigurationController",
                template: restrictionConfigurationTemplate,
                data: {
                    group: "group.monitoring.restriction.configuration",
                    pageTitle: "{{'title.restrictionConfiguration' | translate:$stateParams}}"
                },
                resolve: {
                    restriction: ["$stateParams", "restrictionConfigurationService", function ($stateParams, restrictionConfigurationService) {
                        return restrictionConfigurationService.find();
                    }]
                },
                params: {
                    reload: "true"
                }
            })
    }]);
});
