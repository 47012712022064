define([
    "./monitorModule",
    "text!./nlgMonitorHealth.html",
    "angular"
], function (module, template, angular) {
    "use strict";

    return module.directive("nlgMonitorHealth", [function () {
        return {
            scope: {
                instance: "<"
            },
            template: template,
            controller: [
                "$scope",
                "monitorService",
                "$q",
                function ($scope, monitorService, $q) {
                    $scope.metrics = {};
                    $scope.health = {
                        value: null,
                        error: null
                    };

                    $scope.loadEndpoint = function () {
                        $scope.loading = true;

                        return $q.all([loadHealth(), loadMetrics()])
                            .finally(function () {
                                $scope.loading = false;
                            });
                    };

                    function loadHealth() {
                        $scope.health.value = null;
                        $scope.health.error = null;

                        return monitorService.findEndpoint($scope.instance, "health")
                            .then(function (data) {
                                $scope.health.value = data;
                            })
                            .catch(function (error) {
                                $scope.health.error = error;
                            });
                    }

                    function loadMetrics() {
                        return monitorService.findEndpoint($scope.instance, "metrics")
                            .then(function (data) {
                                if (data.names) {
                                    $scope.metricType = "V2";
                                    $scope.metrics = data.names;
                                    return;
                                }
                                $scope.metrics = angular.extend({
                                    "mem.used": data["mem"] - data["mem.free"]
                                }, data);
                            });
                    }

                    $scope.loadEndpoint();
                }]
        };
    }]);
});