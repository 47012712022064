import editionModule from "./editionModule"
import server from "../server"

editionModule.provider("editionService", [function () {

    const templatesByEntityType = {}
    const controllersByEntityType = {}

    this.registerEdition = (entityType, template, controller) => {
        if (!template || !controller) {
            throw new Error("Both template and controller must be defined")
        }
        if (templatesByEntityType[entityType]) {
            throw new Error("A template is already defined for " + entityType)
        }
        if (controllersByEntityType[entityType]) {
            throw new Error("A controller is already defined for " + entityType)
        }
        templatesByEntityType[entityType] = template
        controllersByEntityType[entityType] = controller
    }

    this.$get = ["$http", function ($http) {
        class EditionService {
            validateEntityType(entityType){
                return !!server.getServiceUrl("edition." + entityType)
            }

            findEditionEntity(entityType, id){
                return $http.get(server.getServiceUrl("edition." + entityType) + id)
                    .then((response)=> response.data)
            }

            getNewEntity(entityType) {
                return $http.get(server.getServiceUrl("edition." + entityType) + "new")
                    .then((response) => response.data)
            }

            getCopy(entityType, entityId) {
                return $http.get(server.getServiceUrl("edition." + entityType) + "copy/" + entityId)
                    .then((response) => response.data)
            }

            deleteEntity(entityType, entityId) {
                return $http.delete(server.getServiceUrl("edition." + entityType) + entityId)
            }

            deleteEntities(entityType, entityIds) {
                return $http.post(server.getServiceUrl("edition." + entityType) + "delete-multiple", entityIds)
            }

            deleteEntityIgnoringWarnings(entityType, entityId) {
                return $http.delete(server.getServiceUrl("edition." + entityType) + entityId + "/ignoring-warnings")
            }

            getEditionTemplate(entityType){
                const editionTemplate = templatesByEntityType[entityType]
                if (!editionTemplate) {
                    throw new Error("There is no edition template defined for type " + entityType)
                }
                return editionTemplate
            }

            getEditionController(entityType) {
                const controller = controllersByEntityType[entityType]
                if (!controller) {
                    throw new Error("There is no controller registered for type" + entityType)
                }
                return controller
            }
        }
        return new EditionService()
    }]
}])