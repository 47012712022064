define([
    "./nlgHealthCheckerModule",
    "text!./HealthCheckerView.html",
    "text!./HealthCheckerDetailsView.html",
    "text!./history/HealthCheckerHistoryView.html"
], function (app, healthCheckerView, healthCheckerDetailsView, healthCheckerHistoryView) {
    "use strict";

    app.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitor.healthChecker", {
                url: "/healthChecker",
                template: healthCheckerView,
                data: {
                    group: "group.manager.healthchecker",
                    pageTitle: "{{'title.manager.healthChecker' | translate:$stateParams}}"
                }
            })
            .state("monitor.healthCheckerDetails", {
                url: "/healthCheckerDetails?instance&checker",
                template: healthCheckerDetailsView,
                data: {
                    group: "group.manager.healthchecker",
                    pageTitle: "{{'title.manager.healthCheckerDetails' | translate:$stateParams}}"
                },
                resolveAs: "model",
                resolve: {
                    instance: ["$stateParams", function ($stateParams) {
                        return $stateParams.instance;
                    }],
                    checkerName: ["$stateParams", function ($stateParams) {
                        return $stateParams.checker;
                    }]
                },
            })
            .state("monitor.healthCheckerHistory", {
                url: "/healthCheckerHistory?instance&checker",
                template: healthCheckerHistoryView,
                controller: "HealthCheckerHistoryController",
                data: {
                    group: "group.manager.healthchecker",
                    pageTitle: "{{'title.manager.healthCheckerHistory' | translate:$stateParams}}"
                },
                resolveAs: "model",
                resolve: {
                    instance: ["$stateParams", function ($stateParams) {
                        return $stateParams.instance;
                    }],
                    checkerName: ["$stateParams", function ($stateParams) {
                        return $stateParams.checker;
                    }]
                },
            });
    }]);
});