define([
    "./userManagementLGPDModule",
    "json!../userManagement.json",
], function (monitoringLGPDModule, server) {
    "use strict";
    monitoringLGPDModule.service("userManagementLgpdApi", function ($http) {
        var self = this;

        self.entityGetDefinitions = function () {
            return $http.get(server.baseUrl + server.services.lgpd.entity.all)
                .then(function (response) {
                    return response;
                });
        };

        self.obfuscateAllNames = function () {
            return $http.get(server.baseUrl + server.services.lgpd.entity.obfuscate.allNames)
                .then(function (response) {
                    return response;
                });
        };

        self.obfuscateEntitiesByName = function (args) {
            return $http.post(server.baseUrl + server.services.lgpd.entity.obfuscate.entitiesByName, args)
                .then(function (response) {
                    return response;
                });
        };

        self.obfuscateFind = function (args, params) {
            return $http.post(server.baseUrl + server.services.lgpd.entity.obfuscate.find, args, params)
                .then(function (response) {
                    return response;
                });
        };

        self.obfuscateSave = function (args, params) {
            return $http.post(server.baseUrl + server.services.lgpd.entity.obfuscate.save, args, params)
                .then(function (response) {
                    return response;
                });
        };

        self.logsAllNames = function () {
            return $http.get(server.baseUrl + server.services.lgpd.entity.logs.allNames)
                .then(function (response) {
                    return response;
                });
        };

        self.logsEntitiesByName = function (args) {
            return $http.post(server.baseUrl + server.services.lgpd.entity.logs.entitiesByName, args)
                .then(function (response) {
                    return response;
                });
        };

        self.logsFind = function (args, params) {
            return $http.post(server.baseUrl + server.services.lgpd.entity.logs.find, args, params)
                .then(function (response) {
                    return response;
                });
        };

        self.logsGetTypes = function () {
            return $http.get(server.baseUrl + server.services.lgpd.entity.logs.types)
                .then(function (response) {
                    return response;
                });
        };

        self.logExportDefinitions = function () {
            return $http.get(server.baseUrl + server.services.lgpd.entity.logs.exportDefinitions)
                .then(function (response) {
                    return response;
                });
        };

        self.entityGetAllOwners = function () {
            return $http.get(server.baseUrl + server.services.lgpd.entity.edition)
                .then(function (response) {
                    return response;
                });
        };

        self.entitySave = function (definition) {
            return $http.post(server.baseUrl + server.services.lgpd.entity.edition, definition)
                .then(function (response) {
                    return response;
                });
        };

        self.entityGetByOwner = function (owner) {
            const queryParams = {params: {entityName: owner.entityName}};
            return $http.get(server.baseUrl + server.services.lgpd.entity.byOwner, queryParams)
                .then(function (response) {
                    return response;
                });
        };

        self.entityFilter = function (template, params) {
            return $http.post(server.baseUrl + server.services.lgpd.entity.filter, template, params)
                .then(function (response) {
                    return response;
                });
        };

        self.entityGetDefinitionById = function (id) {
            return $http.get(server.baseUrl + server.services.lgpd.entity.edition + "/" + id)
                .then(function (response) {
                    return response;
                });
        };

        self.entityDelete = function (id) {
            return $http.delete(server.baseUrl + server.services.lgpd.entity.edition + "/" + id)
                .then(function (response) {
                    return response;
                });
        };

        self.entityCopyGetDefinitionById = function (id) {
            return $http.get(server.baseUrl + server.services.lgpd.entity.copy + "/" + id)
                .then(function (response) {
                    return response;
                });
        };

        self.entityLogVersionOf = function (id) {
            return $http.get(server.baseUrl + server.services.lgpd.entity.versions + "/" + id)
                .then(function (response) {
                    return response;
                });
        };

        self.entityFindVersion = function (id, version) {
            return $http.get(server.baseUrl + server.services.lgpd.entity.edition + "/" + id + "/version/" + version)
                .then(function (response) {
                    return response;
                });
        };

        self.entityGetDefinitions = function () {
            return $http.get(server.baseUrl + server.services.lgpd.entity.all)
                .then(function (response) {
                    return response;
                });
        };

        self.filterRecords = function (servicePath, template, params) {
            return $http.post(server.baseUrl + servicePath + "/filter", template, params)
                .then(function (response) {
                    return response;
                });
        };

        self.saveDefinition = function (servicePath, definition) {
            return $http.post(server.baseUrl + servicePath, definition)
                .then(function (response) {
                    return response;
                });
        };

        self.deleteById = function (servicePath, id) {
            return $http.delete(server.baseUrl + servicePath + "/" + id)
                .then(function (response) {
                    return response;
                });
        };

        self.copyById = function (servicePath, id) {
            return $http.get(server.baseUrl + servicePath + "/copy/" + id)
                .then(function (response) {
                    return response;
                });
        };

        self.getDefinitionById = function (servicePath, id) {
            return $http.get(server.baseUrl + servicePath + "/" + id)
                .then(function (response) {
                    return response;
                });
        };

        self.getDefinitions = function (servicePath) {
            return $http.get(server.baseUrl + servicePath)
                .then(function (response) {
                    return response;
                });
        };

        self.findVersionsOf = function (servicePath, id) {
            return $http.get(server.baseUrl + servicePath + "/versions/" + id)
                .then(function (response) {
                    return response;
                });
        };

        self.findVersion = function (servicePath, id, version) {
            return $http.get(server.baseUrl + servicePath + "/" + id + "/version/" + version)
                .then(function (response) {
                    return response;
                });
        };

        self.anonymizeGetAllDefinitionsNames = function () {
            var artifactName = "userManagement";
            var entityAnonymizePath = server.baseUrl + server.services.lgpd.entity.anonymize;
            return [
                assemblyResponse($http.get(entityAnonymizePath + "/all-names"), artifactName, entityAnonymizePath, "entity"),
            ];
        };


        self.anonymizeGetEntitiesByName = function (entityName, servicePath) {
            return $http.post(servicePath + "/entities-by-name", entityName).then(function (response) {
                return response;
            });
        };

        self.anonymizeGetFieldTemplate = function (field, servicePath) {
            return $http.post(servicePath + "/find-template", field).then(function (response) {
                return response;
            });
        };

        self.anonymizeFindFieldTemplate = function (body, params, servicePath) {
            return $http.post(servicePath + "/find-field-template", body, params)
                .then(function (response) {
                    return response;
                });
        };

        self.anonymizeFindFilterTemplate = function (data, params) {
            return $http.post(server.baseUrl + server.preffix + "/lgpd/" + data.serviceName + "/anonymize/" + data.entityName + "/filter", data.template, params)
                .then(function (response) {
                    return response;
                });
        };

        self.getAnonymizeFilterServiceName = function (definition) {
            return server.services.lgpd.path + "/" + definition.serviceName + "/anonymize/" + definition.entityName;
        };

        self.hasFilterTemplate = function (definition) {
            return $http.get(server.baseUrl + server.preffix + "/" + server.services.lgpd.path + "/" + definition.serviceName + "/anonymize/" + definition.entityName + "/has-filter-template")
                .then(function (response) {
                    return response.data;
                });
        };

        self.anonymize = function (definition, params, servicePath) {
            return $http.post(servicePath + "/anonymize", definition, params)
                .then(function (response) {
                    return response;
                });
        };

        self.anonymizeAttachment = function (definition, params, servicePath) {
            return $http.post(servicePath + "/attachment-anonymize", definition, params)
                .then(function (response) {
                    return response;
                });
        };

        self.anonymizeGetAnonymizableEntities = function (template, params, servicePath) {
            return $http.post(servicePath + "/find", template, params)
                .then(function (response) {
                    return response;
                });
        };

        function assemblyResponse(request, artifactName, path, name) {
            return request.then(function (response) {
                return {
                    key: {
                        artifactName: artifactName,
                        path: path,
                        name: name
                    },
                    value: response.data
                };
            }).catch(function () {
                return {
                    key: {
                        artifactName: artifactName,
                        path: "",
                        name: name
                    },
                    value: []
                };
            });
        }

        return self;
    });
});