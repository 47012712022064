define([
    "../nlgLGPDModule"
], function (lgpdModule) {
    "use strict";

    return lgpdModule.controller("LGPDClassifiableControllerEdition", [
        "$scope",
        "LGPDClassifiableService",
        "lgpdClassifiableDefinition",
        "$state",
        "remoteExceptionHandler",
        "messagesModal",
        function ($scope, classifiableService, lgpdClassifiableDefinition,$state, remoteExceptionHandler, messagesModal) {

            $scope.entities = getEntities();
            $scope.entity = lgpdClassifiableDefinition;

            $scope.serviceName = "LGPDClassifiableService";
            $scope.accessReference = "lgpd.logLgpdClassification";

            function getEntities() {
                classifiableService.getDefinitions()
                    .then(function (result) {
                        $scope.entities = result.data;
                        $scope.createMap();
                    });
            }

            $scope.fieldsByEntity = new window.Map();
            $scope.createMap = function () {
                $scope.entities.forEach(function (entity) {
                    var info = { entityName: entity.entityName, fields: [] };
                    $scope.fieldsByEntity[entity.presentationName] = $scope.fieldsByEntity[entity.presentationName] || info;
                    $scope.fieldsByEntity[entity.presentationName].fields.push(entity.entityField);
                });
            };

            $scope.getEntitiesName = function () {
                return Object.keys($scope.fieldsByEntity);
            };

            $scope.getEntityFields = function (entity) {
                return $scope.fieldsByEntity[entity.presentationName].fields;
            };

            $scope.getTag = function (tag) {
                if (!tag) return "";
                return tag;
            };

            $scope.onChange = function (entity) {
                if (entity.presentationName) {
                    entity.entityName = $scope.fieldsByEntity[entity.presentationName].entityName;
                    $scope.entity = entity;
                } else {
                    $scope.entity.entityField = null;
                }
            };

            $scope.save = function () {
                classifiableService.save($scope.entity)
                    .then(function (result) {
                        $scope.entity = result.data;
                        messagesModal("dialog.success", [{
                            keyBundle: "lgpd.edition.save.success",
                            parameters: []
                        }]).then(function () {
                            $state.go("lgpd.editLgpdClassification", {
                                reload: true,
                                lgpdDefinitionId: $scope.entity.id
                            });
                        });
                    }).catch(remoteExceptionHandler());
            };

            $scope.back = function () {
                return $state.go("lgpd.lgpdClassification", {
                    reload: true
                });
            };

            $scope.saveAndContinue = function () {
                classifiableService.save($scope.entity)
                    .then(function (result) {
                        $scope.entity = result.data;
                        messagesModal("dialog.success", [{
                            keyBundle: "lgpd.edition.save.success",
                            parameters: []
                        }]).then(function () {
                            $state.go($state.current, {reload: Date.now()});
                        });
                    }).catch(remoteExceptionHandler());
            };

            $scope.canSave = function () {
                if ($state.params.version) {
                    return false;
                }
                return $scope.entity.entityName
                    && $scope.entity.entityField
                    && $scope.entity.personal
                    && $scope.entity.justification;
            };
        }]);
});
