import gatewayServer from "./gatewayServer"

const monitoringBaseUrl = gatewayServer.gatewayBaseUrl + "/monitoring"
const services = {
    "trip": "/monitorable/trip",
    "tripFilter": monitoringBaseUrl + "/monitorable/trip/filter",
    "getEnum" : monitoringBaseUrl  + "/static/enum/?enumType="
}

export default {
    getMonitoringUrl() {
        return monitoringBaseUrl
    },
    getServiceUrl(service){
        if (!services[service]) {
            return void 0
        }
        return services[service]
    }
}