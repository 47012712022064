define([
    "./restrictionConfigurationModule",
    "json!./config/restrictionConfiguration.json"
], function (restrictionConfigurationModule, restrictionConfiguration) {
    "use strict";

    restrictionConfigurationModule.service("restrictionConfigurationService", [
        "$http",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        "$state",
        function ($http, loadingService, remoteExceptionHandler, messagesModal, $state) {
            var self = this;

            self.save = function (restriction) {
                return loadingService($http.post(servicePath("save"), restriction))
                    .catch(remoteExceptionHandler())
                    .then(function () {
                        return messagesModal("dialog.success", {
                            keyBundle: "restrictionConfiguration.save.success"
                        })
                    .then(() => $state.go("monitoring.restrictionConfiguration"));
                    });
            };

            self.find = function () {
                return loadingService($http.get(servicePath("find")))
                    .then(getData);
            };

            function servicePath(serviceId) {
                return restrictionConfiguration.baseUrl + restrictionConfiguration.services[serviceId];
            }

            function getData(response) {
                return response.data;
            }

            return self;
        }]);
});