import analysisModule from "../analysisModule"
import server from "../../server"

analysisModule.service("orderBreakService", ["$http", function ($http) {

    this.findBreaks = (nodeIdentifier) => {
        return $http.post(server.getServiceUrl("find-breaks"), nodeIdentifier)
            .then((response) => response.data)
    }

    this.resolveOrderBreak = (payloads) => {
        return $http.post(server.getServiceUrl("resolve-order-break"), payloads)
            .then((response) => response.data)
    }
}])
