define([
    "./nlgHealthCheckerModule",
    "text!./nlgHealthChecker.html",
    "lodash"
], function (nlgHealthCheckerModule, template, _) {
    "use strict";

    return nlgHealthCheckerModule.directive("nlgHealthChecker", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {},
            controller: ["$scope", "nlgHealthCheckerService", "monitorService", "remoteExceptionHandler", function ($scope, nlgHealthCheckerService, monitorService, remoteExceptionHandler) {
                $scope.model = {};
                $scope.filter = {
                    instance: "cockpit-gateway"
                };
                initFiltersAndModel();
                getSummaryInfos();

                $scope.refresh = function () {
                    return getSummaryInfos();
                };

                function getSummaryInfos () {
                    return nlgHealthCheckerService.getSummaryInfos($scope.filter.instance, $scope.filter.checkers, $scope.filter.types, $scope.model.configurations)
                        .then(function (data) {
                            return ($scope.model.summaryInfos = data);
                        });
                }

                $scope.getCheckerNames = function(){
                    return nlgHealthCheckerService.getCheckerNames($scope.filter.instance)
                        .then(function (data) {
                            return ($scope.filter.checkerNames = data);
                        });
                };

                $scope.getStatus = function () {
                    return nlgHealthCheckerService.getStatus($scope.filter.instance)
                        .then(function (data) {
                            return ($scope.model.status = data);
                        });
                };

                $scope.instanceSelected = function () {
                    if ($scope.filter.instance) {
                        getSummaryInfos();
                    } else {
                        initFiltersAndModel();
                    }
                };

                $scope.filterStatus = function (checker) {
                    if ($scope.filter.status && $scope.filter.status.length > 0) {
                        return _.some($scope.filter.status, function (status) {
                            return status === checker.status;
                        });
                    }
                    return true;
                };

                $scope.getInstances = function () {
                    return monitorService.findApps()
                        .catch(remoteExceptionHandler())
                        .then(function (applications) {
                            return applications.application.map(function (app) {
                                return app.name.toLowerCase();
                            });
                        });
                };

                function initFiltersAndModel() {
                    $scope.filter.checkers = [];
                    $scope.filter.types = [];
                    $scope.filter.status = [];
                    $scope.model.summaryInfos = [];
                }

                $scope.getCheckerTypes = function () {
                    return nlgHealthCheckerService.getCheckerTypes($scope.filter.instance)
                        .then(function (data) {
                            return ($scope.filter.checkerTypes = data);
                        });
                };
            }]
        };
    }]);
});
