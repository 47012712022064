define([
    "./genericParamsModule",
    "text!./genericParamEditionConfirmation.html",
    "angular",
    "../arrays/arrays",
    "lodash"
], function (crudModule, template, angular, arrays, _) {
    "use strict";

    return crudModule.service("transferableGenericParamService", [
        "$modal",
        function ($modal) {
            var services = {};

            services.hashTransferable = function (transferable) {
                if (!transferable) {
                    return null;
                }
                if (transferable.id) {
                    // entity
                    return transferable.id;
                }
                // enum
                return transferable;
            };

            services.hashComparator = function (left, right) {
                return services.hashTransferable(left) === services.hashTransferable(right);
            };

            /**
             * Gera um visão dos dados que serão criados
             *
             * Obs: gera combinação de valores entres valores fixos e dos transferables,
             * e remove as combinações equivalantes aos registros que estão no dataByTransferable
             *
             * @param transferables
             * @param transferDto
             * @param dataByTransferable
             * @returns {Array} datasToCreate Visão de quais registros serão criados
             */
            services.generateCreatedData = function (transferables, transferDto, dataByTransferable) {
                var datasToCreate = [];
                transferables.forEach(function (transferable) {
                    var datasForTransferable = [];
                    var paramData = {};
                    paramData[transferDto.transferableAxis.componentName] = transferable.value;
                    join(transferDto.fixedAxes[0], paramData, 0, datasForTransferable);

                    var genericParamDatas = dataByTransferable.get(transferable.value);
                    if (genericParamDatas && genericParamDatas.length) {
                        datasForTransferable = removeGenericParamDatas(datasForTransferable, genericParamDatas);
                    }
                    datasToCreate = datasToCreate.concat(datasForTransferable);
                });

                return datasToCreate;

                function join(fixedAxis, paramData, index, datasForTransferable) {
                    if (index === transferDto.fixedAxes.length) {
                        datasForTransferable.push(paramData);
                        return;
                    }
                    if (fixedAxis.terms.length === 0) {
                        paramData[fixedAxis.componentName] = null;
                        join(transferDto.fixedAxes[index + 1], angular.copy(paramData), index + 1, datasForTransferable);
                    } else {
                        fixedAxis.terms.forEach(function (term) {
                            paramData[fixedAxis.componentName] = term.value;
                            join(transferDto.fixedAxes[index + 1], angular.copy(paramData), index + 1, datasForTransferable);
                        });
                    }
                }

                function removeGenericParamDatas(datasForTransferable, genericParamDatas) {
                    var paramProperties = arrays.map(genericParamDatas, function (data) {
                        return data.properties;
                    });

                    return _.filter(datasForTransferable, function (data) {
                        return !arrays.contains(paramProperties, data);
                    });
                }
            };

            services.openConfirmationModal = function (transferDto, genericParamDataToDelete, genericParamDataToCreate) {
                return $modal.open({
                    template: template,
                    size: "lg",
                    resolve: {
                        transferDto: function () {
                            return transferDto;
                        },
                        genericParamDataToDelete: function () {
                            return genericParamDataToDelete;
                        },
                        genericParamDataToCreate: function () {
                            return genericParamDataToCreate;
                        }
                    },
                    controller: "TransferableGenericParamConfirmationController"
                }).result;
            };

            return services;
        }]);
});