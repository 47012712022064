define(["./userPermissionsModule",
        "json!../monitoring.json"],
    function (module, monitoring) {
        "use strict";

        return module.service("userPermissionsService", ["$http",function ($http) {
            return {
                getProfileRestrictions: getProfileRestrictions,
                getProfileRestrictionsByCategory: getProfileRestrictionsByCategory,
                getAllCategories: getAllCategories,
                save: save,
                findConfiguration: findConfiguration,
                saveConfiguration: saveConfiguration,

                dynamicFindAll: dynamicFindAll,
                dynamicFindByIds: dynamicFindByIds,
                dynamicFindBySourceIds: dynamicFindBySourceIdsFilter
            };

            function getProfileRestrictions(profileId) {
                return $http.get(servicePath("profileRestrictions") + "/" + profileId);
            }

            function getProfileRestrictionsByCategory(profileAndCategory) {
                return $http.post(servicePath("profileRestrictionsByCategory"), profileAndCategory).then(getData);
            }

            function getAllCategories(){
                return $http.get(servicePath("categories")).then(getData);
            }

            function save(profileId, categoryId, data) {
                return $http.post(servicePath("profileRestrictionsSave"), data, {
                    params: {
                        profileId: profileId,
                        categoryId: categoryId
                    }
                });
            }

            function findConfiguration(profileId, category) {
                return $http.post(servicePath("profileRestrictionConfig"), {
                    profileId: profileId,
                    category: category
                }).then(getData);
            }

            function saveConfiguration(configDto) {
                return $http.post(servicePath("profileRestrictionConfigSave"), configDto).then(getData);
            }

            function dynamicFindAll(categoryQualifier) {
                return $http.get(servicePath(categoryQualifier)).then(getData);
            }

            function dynamicFindByIds(categoryQualifier, ids) {
                return $http.post(servicePath(categoryQualifier), ids).then(getData);
            }

            function dynamicFindBySourceIdsFilter(categoryQualifier, sourceIds) {
                return $http.post(servicePath(categoryQualifier), sourceIds).then(getData);
            }

            function servicePath(serviceId) {
                return monitoring.baseUrl + monitoring.services[serviceId];
            }

            function getData(response){
                return response.data;
            }
        }]);
    });