define([
    "./userPermissionsModule"
], function (userPermissionModule) {
    "use strict";

    return userPermissionModule.directive("nlgMonitoringPermission", [function () {
        return {
            restrict: "A",
            controller: ["$attrs", "$element", "monitoringService", function ($attrs, $element, monitoringService) {
                $element.hide();
                monitoringService.hasPermission($attrs.nlgMonitoringPermission).then(function (isEnabled) {
                    if (isEnabled) {
                        $element.show();
                    }
                });
            }]
        };
    }]);
});
