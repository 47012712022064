define(["./arrangementModule", "../arrays/arrays", "angular", "file-saver"], function (arrangementModule, arrays, angular, fileSaver) {
    "use strict";

    return arrangementModule.controller("nlgArrangementController", [
        "$scope",
        "arrangementViewType",
        "EntrySetMap",
        "HashSet",
        "loadingService",
        "remoteExceptionHandler",
        "arrangementUrls",
        "$http",
        "pathService",
        "violationModal",
        "messagesModal",
        "downloadBinary",
        "$translate",
        "arrangementDrawerService",
        "Object3DInfo",
        "arrangementLayerReport",
        "arrangementViewerModal",
        "entityFormatFilter",
        "ArrangementColorGrouper",
        "arrangementDescriptionService",
        function ($scope, arrangementViewType, EntrySetMap, HashSet, loadingService, remoteExceptionHandler, arrangementUrls, $http, pathService, violationModal, messagesModal, downloadBinary, $translate, arrangementDrawerService, Object3DInfo, arrangementLayerReport, arrangementViewerModal, entityFormat, ArrangementColorGrouper, arrangementDescriptionService) {
            var drawerApi = null;
            $scope.customColors = [];

            // must be consistent at inicialization
            $scope.viewType = arrangementViewType.DELIVERY_UNIT;
            var previousViewTypeName = $scope.viewType.name;

            $scope.viewTypes = arrangementViewType;
            $scope.Object3DInfo = Object3DInfo;
            $scope.containerArrangements = [];
            $scope.meshsByContainer = [];
            $scope.additionalObjects = [];
            $scope.tableRecords = [];
            $scope.tableOverloadRecords = [];
            $scope.tableAdditionalObjectsRecords = [];
            $scope.selectedObjects = new HashSet(function (renderedMesh) {
                return $scope.getExtraInfo(renderedMesh).deliveryUnitId;
            });
            $scope.selectViewType = true;
            $scope.showObjectDescription = true;

            $scope.viewTypeTableTemplate = function () {
                return "arrangement/viewTypeTable/" + $scope.viewType.name + ".html";
            };
            $scope.viewTypeTableHeadTemplate = function () {
                return "arrangement/viewTypeTable/" + $scope.viewType.name + "_HEADER.html";
            };
            $scope.viewTypeTableOverloadTemplate = function () {
                return "arrangement/viewTypeTableOverload/" + $scope.viewType.name + ".html";
            };
            $scope.viewTypeTableOverloadHeadTemplate = function () {
                return "arrangement/viewTypeTableOverload/" + $scope.viewType.name + "_HEADER.html";
            };

            $scope.onRegisterApi = function (_drawerApi) {
                drawerApi = _drawerApi;
            };

            var arrangementColorGrouper = new ArrangementColorGrouper();
            $scope.setSelectedContainerArrangement = function (selectedContainerArrangementIndex) {
                var selectedContainerArrangement = $scope.containerArrangements[selectedContainerArrangementIndex];
                $scope.selectedContainerArrangementIndex = selectedContainerArrangementIndex;
                $scope.selectedContainerArrangement = selectedContainerArrangement;
                $scope.selectedObjects.clear();
                $scope.$emit("nlgArrangement.containerSelectionChanged", $scope.selectedContainerArrangement);
                $scope.selectionChanged();
            };

            $scope.toggleGroup = function (meshs) {
                if ($scope.selectedObjects.containsAll(meshs)) {
                    $scope.selectedObjects.removeAll(meshs);
                } else {
                    $scope.selectedObjects.addAll(meshs);
                }
                $scope.selectionChanged();
                drawerApi.recalcObjectHighlight();
            };

            // visible for testing
            this.selectionChanged = $scope.selectionChanged;
            $scope.selectionChanged = function () {
                arrays.each($scope.tableRecords, function (record) {
                    record.selected = groupdSelected(record.value);
                });
                $scope.$broadcast("nlgArrangement.selectionChanged", $scope.selectedObjects);
            };

            function groupdSelected(meshs) {
                var selectionCount = 0;
                arrays.each(meshs, function (mesh) {
                    if ($scope.selectedObjects.contains(mesh)) {
                        selectionCount++;
                    }
                });
                if (selectionCount === 0) {
                    return "NONE";
                }
                if (selectionCount < meshs.length) {
                    return "SOME";
                }
                return "ALL";
            }

            $scope.getExtraInfo = function (meshAndObject) {
                var obj = meshAndObject.value ? meshAndObject.value[0] : meshAndObject;
                return ArrangementColorGrouper.getExtraInfo(obj);
            };

            $scope.removeOverload = function () {
                var description = $scope.arrangementDescription;
                loadingService($http({
                    method: "POST",
                    url: arrangementUrls.getRemoveOverloadUrl(),
                    params: {
                        loadId: description.load.id,
                        routingConfigId: description.routingConfigId,
                        converterDecorator: description.converterDecoratorId
                    }
                })).then(function (response) {
                    return violationModal(response.data);
                }, remoteExceptionHandler()).then(function (manualResult) {
                    return loadingService($http.post(pathService.getPath("manualOperation-save"), manualResult.token))
                        .catch(remoteExceptionHandler());
                }).then(function () {
                    return messagesModal("dialog.success", ["arrangement.removeOverload.success"]);
                }).then(function () {
                    loadingService(arrangementDescriptionService.forLoad(description.load.id, description.routingConfigId, description.converterDecoratorId).then(function (response) {
                        $scope.arrangementDescription = response.data;
                    }));
                });
            };

            $scope.getCLP = function () {
                var description = $scope.arrangementDescription;
                loadingService(downloadBinary.get(arrangementUrls.getClpUrl(), "clp.zip", {
                    loadId: description.load.id,
                    routingConfigId: description.routingConfigId,
                    converterDecorator: description.converterDecoratorId
                }));
            };

            $scope.printScreen = function () {
                drawerApi.toBlob(function (blob) {
                    fileSaver(blob, $translate.instant("arrangement.tabTitle", {
                        index: $scope.selectedContainerArrangementIndex
                    }) + ".png");
                });
            };

            $scope.generateVerticalReport = function () {
                var originalWidth = drawerApi.getWidth();
                var originalHeight = drawerApi.getHeight();
                try {
                    return arrangementLayerReport.vertical($scope.arrangementDescription, $scope.viewType, $scope.customColors);
                } finally {
                    // FIXME essa responsabilidade está vazando para o usuário (heee he he). Ele não deveria ter de
                    // saber que tem que restaurar o tamanho, para não afetar outras coisas renderizadas na
                    // tela
                    drawerApi.setSize(originalWidth, originalHeight);
                }
            };

            $scope.generateHorizontalReport = function () {
                var originalWidth = drawerApi.getWidth();
                var originalHeight = drawerApi.getHeight();
                try {
                    return arrangementLayerReport.horizontal($scope.arrangementDescription, $scope.viewType, $scope.customColors);
                } finally {
                    // FIXME essa responsabilidade está vazando para o usuário (heee he he). Ele não deveria ter de
                    // saber que tem que restaurar o tamanho, para não afetar outras coisas renderizadas na
                    // tela
                    drawerApi.setSize(originalWidth, originalHeight);
                }
            };

            $scope.viewDeliveryUnit = function (record) {
                arrangementViewerModal.forDeliveryUnit(
                    $scope.getExtraInfo(record.value[0]).deliveryUnit.id,
                    $scope.arrangementDescription.routingConfigId,
                    $scope.arrangementDescription.converterDecoratorId);
            };

            $scope.canViewDeliveryUnit = function (record) {
                return !!record.value[0].object.children;
            };

            $scope.isAutoRotate = function () {
                return $scope.autoRotate === "true";
            };

            $scope.$watch("arrangementDescription.containerArrangements", function (containerArrangements) {
                $scope.containerArrangements = containerArrangements || [];
                $scope.setSelectedContainerArrangement(0);
                if ($scope.isAutoRotate()) {
                    drawerApi.enableRotate();
                }
            });

            $scope.$watchGroup(["viewType", "meshsByContainer", "additionalObjects"], function (values) {
                var viewType = values[0];
                var elements = values[1];
                var additionalObjects = values[2];

                if (viewType && (viewType.name !== previousViewTypeName)) {
                    arrangementColorGrouper = new ArrangementColorGrouper();
                    arrays.clear($scope.customColors);
                    previousViewTypeName = viewType.name;
                }

                var elementsByGroup = new EntrySetMap();
                arrays.each(elements, function (element) {
                    var extraInfo = $scope.getExtraInfo(element);
                    var key = viewType.getValue(extraInfo);
                    var customColor = ArrangementColorGrouper.getCustomColor($scope.customColors, key);
                    arrangementDrawerService.setColor(customColor || arrangementColorGrouper.getColor(element, viewType), element);
                    var group = viewType.getValue(extraInfo);
                    elementsByGroup.getOrPut(group, []).push(element);
                });

                $scope.tableRecords = elementsByGroup.entrySet;
                $scope.displayCompositeDUColumn = $scope.selectedContainerArrangement &&
                    $scope.selectedContainerArrangement.container.type.indexOf("VEHICLE") !== -1 &&
                    hasComposite();


                if ($scope.arrangementDescription) {
                    var overloadByGroup = new EntrySetMap();
                    arrays.each($scope.arrangementDescription.remainingDU, function (element) {
                        var group = viewType.getValue(element.deliveryUnitData);
                        overloadByGroup.getOrPut(group, []).push(element);
                    });
                    $scope.tableOverloadRecords = overloadByGroup.entrySet;

                }

                var additionalObjectsByGroup = new EntrySetMap();
                arrays.each(additionalObjects, function (object) {
                    var extraInfo = $scope.getExtraInfo(object);
                    var key = extraInfo["id"];
                    var opacity = extraInfo["opacity"];
                    var customColor = ArrangementColorGrouper.getCustomColor($scope.customColors, key);
                    object.color = $scope.getIdColor(key);
                    arrangementDrawerService.setColor(customColor || object.color, object);
                    if (angular.isNumber(opacity)) arrangementDrawerService.setOpacity(opacity, object);
                    additionalObjectsByGroup.getOrPut(key, []).push(object);
                });
                $scope.tableAdditionalObjectsRecords = additionalObjectsByGroup.entrySet;

                $scope.selectedObjects.clear();
                $scope.selectionChanged();

                function hasComposite() {
                    var result = false;
                    arrays.each($scope.tableRecords, function (record) {
                        if ($scope.canViewDeliveryUnit(record)) {
                            result = true;
                            return arrays.each.BREAK;
                        }
                    });
                    return result;
                }
            });

            $scope.getIdColor = function (key) {
                var customColor = ArrangementColorGrouper.getCustomColor($scope.customColors, key);
                return customColor || arrangementColorGrouper.getIdColor(key);
            };

            $scope.setCustomColor = function (element) {
                arrays.each(element.value, function (meshAndObject) {
                    addOrReplaceColor(element.color, element.key);
                    arrangementDrawerService.setColor(element.color, meshAndObject);
                });
            };

            function addOrReplaceColor(color, key) {
                var id = ArrangementColorGrouper.getId(key);
                var customColorToId = arrays.filter($scope.customColors, function (customColor) {
                    return angular.equals(customColor.id, id);
                });

                if (customColorToId.length) {
                    arrays.remove($scope.customColors, customColorToId[0]);
                }

                $scope.customColors.push({
                    id: id,
                    color: color
                });
            }

            $scope.formatGroupName = function (key) {
                if (angular.isArray(key)) {
                    return arrays.map(key, $scope.formatGroupName);
                }
                var result = entityFormat(key);
                if (result === "[object Object]") {
                    return key.id;
                }
                return result;
            };

            $scope.getRemainingQuantity = function (record) {
                return record.value.reduce(function (old, value) {
                    return old + value.quantity;
                }, 0);
            };

            $scope.isTubesArrangement = function () {
                var result = false;
                arrays.each($scope.containerArrangements, function (containerArrangement) {
                    arrays.each(containerArrangement.container.children, function (child) {
                        if (child.type === "br.com.neolog.service.arrangement.viewer.model.Object3DType.TUBE") {
                            result = true;
                            return arrays.each.BREAK;
                        }
                    });
                    return arrays.each.BREAK;
                });
                return result;
            };

            $scope.minimize = true;

            $scope.maximizeEmbedding = function () {
                $scope.minimize = false;
                shouldEmbed();
            };

            $scope.minimizeEmbedding = function () {
                $scope.minimize = true;
                shouldEmbed();
            };

            function shouldEmbed() {
                var oldDesc = $scope.arrangementDescription;
                loadingService(arrangementDescriptionService.forLoad(oldDesc.load.id, oldDesc.routingConfigId, oldDesc.converterDecoratorId, $scope.minimize).then(function (response) {
                    $scope.arrangementDescription = response.data;
                }));
            }

            $scope.showHeader = function () {
                return $scope.hideHeader !== "true";
            };
            $scope.showActions = function () {
                return $scope.hideActions !== "true";
            };

            $scope.abs = function (value) {
                return Math.abs(value);
            };

            if ($scope.onRegisterNlgArrangementApi) {
                $scope.onRegisterNlgArrangementApi({
                    arrangementControlApi: {
                        /**
                         * @param {number} selectedContainerArrangementIndex
                         */
                        setSelectedContainerArrangement: function (selectedContainerArrangementIndex) {
                            $scope.setSelectedContainerArrangement(selectedContainerArrangementIndex);
                        }
                    }
                });
            }
        }]
    );
});