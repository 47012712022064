import searchModule from "./searchModule"
import forbidDeletion from "./forbidDeletion"
import enableDeletionCheckbox from "./enableDeletionCheckbox"
import spreadsheetDeletionEnabled from "./spreadsheetDeletionEnabled"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"

searchModule.config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state("r2.search", {
        data: {
            pageTitle: "{{'title.r2.search' | translate:$stateParams}}"
        },
        url: "/search/{entity}",
        template: "<nlg-search entity-type='{{$resolve.entityType}}' reload='$resolve.reload' forbid-deletion='$resolve.forbidDeletion' enable-deletion-checkbox='$resolve.enableDeletionCheckbox' spreadsheet-deletion-enabled='$resolve.spreadsheetDeletionEnabled'></nlg-search>",
        resolve: {
            entityType: ["$stateParams", ($stateParams) => {
                return $stateParams.entity
            }],
            reload: ["$stateParams", ($stateParams) => {
                return $stateParams.reload === "true"
            }],
            forbidDeletion: ["$stateParams", ($stateParams) => {
                return arrays.contains(forbidDeletion, $stateParams.entity)
            }],
            enableDeletionCheckbox: ["$stateParams", ($stateParams) => {
                return arrays.contains(enableDeletionCheckbox, $stateParams.entity)
            }],
            spreadsheetDeletionEnabled: ["$stateParams", ($stateParams) => {
                return arrays.contains(spreadsheetDeletionEnabled, $stateParams.entity)
            }]
        },
        onEnter: ["$stateParams", "$state", "searchService", ($stateParams, $state, searchService) => {
            if (!searchService.validateType($stateParams.entity)) {
                $state.go("r2")
            }
        }]
    })
}])