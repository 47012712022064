define([
    "./monitoringNodeCommandsModule",
], function (module) {
    "use strict";

    return module.service("nodeCommandSelectAllTransitions", ["monitorableService", function (monitorableService) {
        return function (selection, context, nodes) {
            function allTransitions(node) {
                if (node.children && node.children.length > 0) {
                    return node.children.flatMap(allTransitions)
                }
                if (node.type.name === "Transition") {
                    return node;
                }
                return [];
            }

            nodes.flatMap(allTransitions).forEach(function (node) {

                if (!context.isNodeSelected(node)) {
                    context.toggleSelection(node);
                }

            });
        };
    }]);
});