define([
    "angular",
    "angular-route",
    "angular-ui-router",
    "angular-bootstrap"
], function (angular) {
    "use strict";

    return angular.module("commonsUserModule", [
        "ngRoute",
        "ui.router",
        "ui.bootstrap"    
    ]);
});