define([
    "./trackingModule"
], function (trackingModule) {

    trackingModule.provider("markerImage", [function () {

        var icon = null;
        var violationIcon = null;
        var created = null;
        var in_execution = null;
        var delayed = null;
        var in_service = null;
        var pending_occurrence = null;
        var without_monitorable = null;
        var iconToFile = null;

        var signal_late = null;
        var created_late = null;
        var in_execution_late = null;
        var delayed_late = null;
        var in_service_late = null;
        var pending_occurrence_late = null;
        var without_monitorable_late = null;


        this.$get = [function () {
            return {
                getIcon: getIcon,
                getViolationIcon: getViolationIcon,
                enumIconToIconFile: enumIconToIconFile,
                labels: labels
            };
        }];

        this.setIcon = function (config) {
            icon = config.icon;
            violationIcon = config.violationIcon;

            created = config.created;
            in_execution = config.inExecution;
            delayed = config.delayed;
            in_service = config.inService;
            pending_occurrence = config.pendingOccurrence;
            without_monitorable = config.withoutMonitorable;

            signal_late = config.signal_late;
            created_late = config.created_late;
            in_execution_late = config.inExecution_late;
            delayed_late = config.delayed_late;
            in_service_late = config.inService_late;
            pending_occurrence_late = config.pendingOccurrence_late;
            without_monitorable_late = config.withoutMonitorable_late;

            iconToFile = {
                '': without_monitorable,
                'CREATED': created,
                'IN_EXECUTION': in_execution,
                'DELAYED': delayed,
                'IN_SERVICE': in_service,
                'PENDING_OCCURRENCE': pending_occurrence,

                'LATE': without_monitorable_late,
                'CREATED_LATE': created_late,
                'IN_EXECUTION_LATE': in_execution_late,
                'DELAYED_LATE': delayed_late,
                'IN_SERVICE_LATE': in_service_late,
                'PENDING_OCCURRENCE_LATE': pending_occurrence_late,
            };
        };

        var getIcon = function () {
            return icon;
        };

        var getViolationIcon = function () {
            return violationIcon
        };

        var enumIconToIconFile = function (positionStatus) {
            if (!positionStatus) {
                return iconToFile[''];
            }
            var enumSplited = positionStatus.split('.');
            var enumValue = enumSplited[enumSplited.length - 1];
            return iconToFile[enumValue];
        };

        var labels = function () {
            return {
                'tracking.image.without.monitorable': without_monitorable,
                'tracking.image.created': created,
                'tracking.image.in_execution': in_execution,
                'tracking.image.delayed': delayed,
                'tracking.image.in.service': in_service,
                'tracking.image.pending.occurrence': pending_occurrence,
                'tracking.image.late.signal': signal_late
            };
        }
    }]);
});

