define([
    "./filterModule"
], function (filterModule) {
    "use strict";

    filterModule.service("filterConfiguration", [function () {
        var filterGenericAttribute;

        this.filterGenericAttribute = function (viewValue, additionalArguments) {
            return filterGenericAttribute(viewValue, additionalArguments);
        };

        this.setFilterGenericAttribute = function (_filterGenericAttribute) {
            filterGenericAttribute = _filterGenericAttribute;
        };
    }]);
});