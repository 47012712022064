import {configParamsModule} from "../configParamsModule"
import {ConfigParamsService} from "../configParamsService";
import {LoadingService, MessagesModal, RemoteExceptionHandler} from "../../../bower_components/front-end-web-commons";

interface Identifiable {
    id: number
}


interface Model {
    userName: string,
    sourceId?: number,
    userPassword: string,
    repeatUserPassword: string,
    parentPermissionGroup?: Identifiable,
    profile?: Identifiable
}

class NlgPermissionGroupCreate {
    private hasToShow: boolean[];
    private firstIndex: number;
    private secondIndex: number;
    model: Model = {
        userName: "",
        userPassword: "",
        repeatUserPassword: ""
    }

    constructor(private loadingService: LoadingService,
                private remoteExceptionHandler: RemoteExceptionHandler,
                private messagesModal: MessagesModal,
                private configParamsService: ConfigParamsService,
                private userManagementService: any) {
        this.hasToShow = [false, false];
        this.firstIndex = 0;
        this.secondIndex = 1;
    }

    save() {
        return this.loadingService(this.configParamsService.createPermissionGroup({
            sourceId: this.model.sourceId,
            userName: this.model.userName,
            userPassword: this.model.userPassword,
            parentPermissionGroupId: this.model.parentPermissionGroup!.id,
            profileId: this.model.profile!.id,
        }))
            .catch(this.remoteExceptionHandler())
            .then(() => {
                this.messagesModal("dialog.success", "config.params.save.success")
                this.clear()
            })
    }

    profiles() {
        return this.userManagementService.findAllProfiles()
    }

    permissionGroups(viewValue: string) {
        return this.configParamsService.findPermissionGroupsBySourceId(viewValue)
    }

    isValidPassword() {
        const model = this.model;
        return model.userPassword === model.repeatUserPassword
    }

    hidePassword (index: number) {
       this.hasToShow[index] = ! this.hasToShow[index]
    }


    getInputType (index: number) {
        if (this.hasToShow[index]) {
            return "text"
        }
        return "password"
    }

    getEyeClass (index: number) {
        if (this.hasToShow[index]) {
            return "fa fa-eye"
        }
        return "fa fa-eye-slash"
    }


    getCodeValue(){
        if (!this.model.parentPermissionGroup){
            this.model.sourceId = undefined
            return
        }
        this.configParamsService.calculateCode(this.model.parentPermissionGroup)
            .then(value => this.model.sourceId = value);

    }

    clear(){
        this.model = {
            userName: "",
            sourceId: undefined,
            userPassword: "",
            repeatUserPassword: "",
            profile: undefined,
            parentPermissionGroup: undefined,
        }
    }
}

export const directive = configParamsModule.directive("nlgPermissionGroupCreate", [function () {
    return {
        restrict: "E",
        templateUrl: require("link!./nlgPermissionGroupCreate.html"),
        scope: {},
        controllerAs: "ctrl",
        controller: NlgPermissionGroupCreate,

    }
}])