define([
    "./macroModule"
], function (macroModule) {
    "use strict";

    macroModule.service("macroService", [
        "$http",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        "remoteMessageModal",
        "monitoringService",
        function ($http, loadingService, remoteExceptionHandler, messagesModal, remoteMessageModal, monitoringService) {
            var self = this;

            self.saveMacro = function (macro) {
                return loadingService(monitoringService.saveMacro(macro))
                    .catch(remoteExceptionHandler())
                    .then(function (id) {
                        return messagesModal("dialog.success", {
                            keyBundle: "monitoring.macro.save.success"
                        }).then(function () {
                            return id;
                        });
                    });
            };

            self.delete = function (id) {
                return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                    .then(function () {
                        return loadingService(monitoringService.deleteMacro(id))
                            .catch(remoteExceptionHandler());
                    }).then(function () {
                        return messagesModal("dialog.success", {
                            keyBundle: "entityFilter.delete.ok"
                        });
                    });
            };
            return self;
        }]);
});