import lgpdModule from "./lgpdModule"
import server from "../server"

lgpdModule.service("lgpdApi", function ($http) {
    var self = this

    self.entityGetAllOwners = function () {
        return $http.get(server.getServiceUrl("lgpd.entity.filter"))
            .then(function (response) {
                return response
            })
    }

    self.entitySave = function (definition) {
        return $http.post(server.getServiceUrl("lgpd.entity.edition"), definition)
            .then(function (response) {
                return response
            })
    }

    self.entityGetByOwner = function (owner) {
        const queryParams = {params: {entityName: owner.entityName}}
        return $http.get(server.getServiceUrl("lgpd.entity.by-owner"), queryParams)
            .then(function (response) {
                return response
            })
    }

    self.entityFilter = function (template, params) {
        return $http.post(server.getServiceUrl("lgpd.entity.custom-filter"), template, params)
            .then(function (response) {
                return response
            })
    }

    self.entityGetDefinitionById = function (id) {
        return $http.get(server.getServiceUrl("lgpd.entity.filter") + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.entityDelete = function (id) {
        return $http.delete(server.getServiceUrl("lgpd.entity.edition") + "/" + id)
            .then(function (response) {
                return response
            })
    }


    self.entityCopyGetDefinitionById = function (id) {
        return $http.get(server.getServiceUrl("lgpd.entity.copy") + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.entityLogVersionOf = function (id) {
        return $http.get(server.getServiceUrl("lgpd.entity.versions") + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.entityFindVersion = function (id, version) {
        return $http.get(server.getServiceUrl("lgpd.entity.edition") + "/" + id + "/version/" + version)
            .then(function (response) {
                return response
            })
    }

    self.entityGetDefinitions = function () {
        return $http.get(server.getServiceUrl("lgpd.entity.all"))
            .then(function (response) {
                return response
            })
    }

    self.filterRecords = function (servicePath, template, params) {
        return $http.post(server.getBaseUrl() + servicePath + "/filter", template, params)
            .then(function (response) {
                return response
            })
    }

    self.saveDefinition = function (servicePath, definition) {
        return $http.post(server.getBaseUrl() + servicePath, definition)
            .then(function (response) {
                return response
            })
    }

    self.deleteById = function (servicePath, id) {
        return $http.delete(server.getBaseUrl() + servicePath + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.copyById = function (servicePath, id) {
        return $http.get(server.getBaseUrl() + servicePath + "/copy/" + id)
            .then(function (response) {
                return response
            })
    }

    self.getDefinitionById = function (servicePath, id) {
        return $http.get(server.getBaseUrl() + servicePath + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.getDefinitions = function (servicePath) {
        return $http.get(server.getBaseUrl() + servicePath)
            .then(function (response) {
                return response
            })
    }

    self.findVersionsOf = function (servicePath, id) {
        return $http.get(server.getBaseUrl() + servicePath + "/versions/" + id)
            .then(function (response) {
                return response
            })
    }

    self.findVersion = function (servicePath, id, version) {
        return $http.get(server.getBaseUrl() + servicePath + "/" + id + "/version/" + version)
            .then(function (response) {
                return response
            })
    }


    self.obfuscateAllNames = function () {
        return $http.get(server.getServiceUrl("lgpd.obfuscate.allNames"))
            .then(function (response) {
                return response
            })
    }

    self.obfuscateEntitiesByName = function (args) {
        return $http.post(server.getServiceUrl("lgpd.obfuscate.entitiesByName"), args)
            .then(function (response) {
                return response
            })
    }

    self.obfuscateFind = function (args, params) {
        return $http.post(server.getServiceUrl("lgpd.obfuscate.find"), args, params)
            .then(function (response) {
                return response
            })
    }

    self.obfuscateSave = function (args, params) {
        return $http.post(server.getServiceUrl("lgpd.obfuscate.save"), args, params)
            .then(function (response) {
                return response
            })
    }

    self.logsAllNames = function () {
        return $http.get(server.getServiceUrl("lgpd.logs.allNames"))
            .then(function (response) {
                return response
            })
    }

    self.logsEntitiesByName = function (args) {
        return $http.post(server.getServiceUrl("lgpd.logs.entitiesByName"), args)
            .then(function (response) {
                return response
            })
    }

    self.logsFind = function (args, params) {
        return $http.post(server.getServiceUrl("lgpd.logs.find"), args, params)
            .then(function (response) {
                return response
            })
    }

    self.logsGetTypes = function () {
        return $http.get(server.getServiceUrl("lgpd.logs.types"))
            .then(function (response) {
                return response
            })
    }

    self.logExportDefinitions = function () {
        return $http.get(server.getServiceUrl("lgpd.logs.exportDefinitions"))
            .then(function (response) {
                return response
            })
    }

    self.sqlGetDefinitionById = function (id) {
        return $http.get(server.getServiceUrl("lgpd.sql.edition") + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.scriptSQLGetBySourceId = function (param) {

        return $http.get(server.getServiceUrl("lgpd.sql.r2-default"), param)
            .then(function (response) {
                return response
            })
    }

    self.sqlCopyById = function (id) {
        return $http.get(server.getServiceUrl("lgpd.sql.copy") + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.sqlGetEntities = function () {
        return $http.get(server.getServiceUrl("lgpd.sql.edition"))
            .then(function (response) {
                return response
            })
    }

    self.sqlGetDefinitions = function () {
        return $http.get(server.getServiceUrl("lgpd.sql.all"))
            .then(function (response) {
                return response
            })
    }

    self.sqlFilterRecords = function (template, params) {
        return $http.post(server.getServiceUrl("lgpd.sql.edition") + "/custom-filter", template, params)
            .then(function (response) {
                return response
            })
    }

    self.sqlSave = function (definition) {
        return $http.post(server.getServiceUrl("lgpd.sql.edition"), definition)
            .then(function (response) {
                return response
            })
    }

    self.sqlDeleteById = function (id) {
        return $http.delete(server.getServiceUrl("lgpd.sql.edition") + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.sqlFindVersionOf = function (id) {
        return $http.get(server.getServiceUrl("lgpd.sql.versions") + "/" + id)
            .then(function (response) {
                return response
            })
    }

    self.sqlFindVersion = function (id, version) {
        return $http.get(server.getServiceUrl("lgpd.sql.edition") + "/" + id + "/version/" + version)
            .then(function (response) {
                return response
            })
    }

    self.anonymizeGetAllDefinitionsNames = function () {
        var artifactName = "r2"
        var attributableAnonymizePath = server.getServiceUrl("lgpd.attributable.anonymize")
        var classifiableAnonymizePath = server.getServiceUrl("lgpd.classifiable.anonymize")
        var entityAnonymizePath = server.getServiceUrl("lgpd.entity.anonymize")
        var genericParamAnonymizePath = server.getServiceUrl("lgpd.genericParam.anonymize")
        return [
            assemblyResponse($http.get(attributableAnonymizePath + "/all-names"), artifactName, attributableAnonymizePath, "attributable"),
            assemblyResponse($http.get(classifiableAnonymizePath + "/all-names"), artifactName, classifiableAnonymizePath, "classifiable"),
            assemblyResponse($http.get(entityAnonymizePath + "/all-names"), artifactName, entityAnonymizePath, "entity"),
            assemblyResponse($http.get(genericParamAnonymizePath + "/all-names"), artifactName, genericParamAnonymizePath, "generic-param")
        ]
    }

    self.anonymizeGetEntitiesByName = function (entityName, servicePath) {
        return $http.post( servicePath + "/entities-by-name", entityName).then(function (response) {
            return response
        })
    }

    self.anonymizeGetFieldTemplate = function (field, servicePath) {
        return $http.post( servicePath + "/find-template", field).then(function (response) {
            return response
        })
    }

    self.anonymizeFindFieldTemplate = function (body, params, servicePath) {
        return $http.post( servicePath + "/find-field-template", body, params)
            .then(function (response) {
                return response
            })
    }

    self.anonymizeFindFilterTemplate = function (data, params) {
        return $http.post(server.getBaseUrl() + "/lgpd/" + data.serviceName + "/anonymize/" + data.entityName + "/filter", data.template, params)
            .then(function (response) {
                return response
            })
    }

    self.getAnonymizeFilterServiceName = function (definition) {
        return "/lgpd/" + definition.serviceName + "/anonymize/" + definition.entityName
    }

    self.hasFilterTemplate = function (definition) {
        return $http.get(server.getBaseUrl() + "/lgpd/" + definition.serviceName + "/anonymize/" + definition.entityName + "/has-filter-template")
            .then(function (response) {
                return response.data
            })
    }

    self.anonymize = function (definition, params, servicePath) {
        return $http.post( servicePath + "/anonymize", definition, params)
            .then(function (response) {
                return response
            })
    }

    self.anonymizeAttachment = function (definition, params, servicePath) {
        return $http.post( servicePath + "/attachment-anonymize", definition, params)
            .then(function (response) {
                return response
            })
    }

    self.anonymizeGetAnonymizableEntities = function (template, params, servicePath) {
        return $http.post( servicePath + "/find", template, params)
            .then(function (response) {
                return response
            })
    }

    function assemblyResponse(request, artifactName, path, name) {
        return request.then(function (response) {
            return {
                key: {
                    artifactName: artifactName,
                    path: path,
                    name: name
                },
                value: response.data
            }
        }).catch(function () {
            return {
                key: {
                    artifactName: artifactName,
                    path: "",
                    name: name
                },
                value: []
            }
        })
    }


})