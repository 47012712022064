define([
    "./monitoringNodeCommandsModule",
    "angular"
], function (module, angular) {
    "use strict";

    return module.service("nodeCommandTemperatureGraph", ["$state", "$window", function ($state, $window) {
        return function openTemperatureGraph(selection) {

            if (selection.length !== 1) {
                throw new Error("nodeCommandTemperatureGraph only available for single node identifier. Got " + angular.toJson(selection));
            }

            var href = $state.href("monitoring.monitoringTemperature", {
                monitorableId: selection[0].id
            });

            $window.open(href, '_blank');
            return href;
        }
    }]);
});