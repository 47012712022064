define(["./remoteModule"], function (remoteModule) {
    return remoteModule.factory("remoteUrlNormalizer", ["$q", function ($q) {
        return {
            request: function (config) {
                var original = config.url;
                if(!/^https?\:\/\//.test(original)){
                    config.url = original.replace(/\/+/g, "/");
                }
                if (original !== config.url) {
                    // eslint-disable-next-line no-console
                    console.warn("Request is malformed [" + original + "], normalizing");
                }
                return config;
            },

            requestError: function (rejection) {
                return $q.reject(rejection);
            },

            response: function (response) {
                return response;
            },

            responseError: function (rejection) {
                return $q.reject(rejection);
            }
        };
    }]);
});