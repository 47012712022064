define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryPasswordTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no input.
     * */

    /**
     * @ngdoc function
     * @name templateFactoryPasswordTemplate.templateFactoryPasswordTemplate
     * @description
     * Template utilizado para mascarar a exibição de senha, utilizando um input.
     *
     * @param {TemplatePasswordConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     * */
    return templateFactoryModule.service("templateFactoryPasswordTemplate", [function () {
        return function (config) {
            var element = angular.element("<input>");
            element.attr("type", "password").attr("class", "form-control").attr("autocomplete", "off webauthn");

            if(config.model){
                element.attr("ng-model", config.model);
            }
            if(config.ngDisabled){
                element.attr("ng-disabled",config.ngDisabled );
            }
            return element;
        };
    }]);
});