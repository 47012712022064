define([
    "./nodeCommandsModule"
], function (nodeCommandsModule) {
    "use strict";

    nodeCommandsModule.service("rootNodeCommandExecutorSupplier", ["commonNodeCommandExecutor", function (commonNodeCommandExecutor) {
        var rootNodeCommandExecutor = null;

        return {
            getRoot: function () {
                return rootNodeCommandExecutor || commonNodeCommandExecutor;
            },
            setRoot: function (nodeCommandExecutor) {
                rootNodeCommandExecutor = nodeCommandExecutor;
            }
        };
    }]);
});