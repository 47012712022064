import translateModule from "./r2TranslateModule"
import translateView from "./translateView.html"
import locales from "./languages"

export default translateModule.directive("nlgTranslate", [()=>{
    return {
        restrict: "E",
        template: translateView,
        scope:{
            "dropdownClass": "@?dropdownClass"
        },
        controller: ["$scope", "translateService", ($scope, translateService) => {
            $scope.changeLanguage = function (language) {
                translateService.changeLanguage(language).then(()=>{
                    $scope.currentLanguage = translateService.getCurrentLanguage()
                    $scope.currentLanguageImage = $scope.getImageUrl($scope.currentLanguage)
                })
            }

            $scope.getImageUrl = function (language) {
                return locales.get(language).img
            }

            $scope.getLanguageBundle = function (language) {
                return "translate.language." + locales.get(language).getLanguage().getLanguageCode()
            }

            $scope.currentLanguage = translateService.getCurrentLanguage()
            $scope.currentLanguageImage = $scope.getImageUrl($scope.currentLanguage)
            $scope.supportedLanguages = locales.getAllLocaleCodes()
        }]
    }
}])