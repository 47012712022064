define([
    "../trackingModule",
    "angular",
    "lodash"
], function (trackingModule, angular, _) {
    "use strict";

    return trackingModule.controller("TruckDeviceEditionController", [
        "$scope",
        "truckDevice",
        "$state",
        "messagesModal",
        "loadingService",
        "remoteExceptionHandler",
        "trackingService",
        "$translate",
        "countryLanguages",
        function ($scope, truckDevice, $state, messagesModal, loadingService, remoteExceptionHandler, trackingService, $translate, countryLanguages) {
            $scope.entity = angular.copy(truckDevice) || {};
            $scope.defaultCountry = countryLanguages.findByLanguage($translate.use()).iso2;

            $scope.providers = function () {
                return trackingService.listProviders()
                    .catch(remoteExceptionHandler())
                    .then(function (providers) {
                        return _.map(providers, "id");
                    });
            };

            $scope.back = function () {
                return $state.go("tracking.truckDeviceSearch");
            };

            $scope.isValid = function () {
                return $scope.entity.deviceId && $scope.entity.providerId && $scope.entity.description;
            };

            function save(){
                var entityToSave = {
                    id: $scope.entity.id,
                    deviceId: $scope.entity.deviceId,
                    plate: $scope.entity.plate,
                    providerId: $scope.entity.providerId ? $scope.entity.providerId : null,
                    description: $scope.entity.description
                };
                return loadingService(trackingService.saveOrUpdateTruckDevice(entityToSave));
            }

            $scope.save = function () {
               save()
                    .then(function () {
                        messagesModal("dialog.success", ["entity.validator.successMessage"])
                            .finally(function () {
                                $state.go("tracking.truckDeviceSearch");
                            });
                    }).catch(remoteExceptionHandler());
            };

            $scope.saveAndContinue = function () {
                save()
                    .then(function () {
                        messagesModal("dialog.success", ["entity.validator.successMessage"])
                            .finally(function () {
                                return $state.go($state.current, {reload: Date.now()});
                            });
                    }).catch(remoteExceptionHandler());
            };
        }
    ]);
});