define([
    "./threeModule",
    "angular",
    "three"
], function (threeModule, angular, THREE) {
    "use strict";

    // renderer tem que ser singleton para a aplicação.
    var threeJsRenderer = null;

    var div = document.createElement("div");

    function lazyLoadThreeJsRenderer() {
        if (threeJsRenderer === null) {
            loadThreeJsRenderer();
        }
    }

    function loadThreeJsRenderer() {
        threeJsRenderer = new THREE.WebGLRenderer({
            antialias: true,
            preserveDrawingBuffer: true
        });
        threeJsRenderer.setClearColor(0xffffff);
        div.innerHTML = "";
        div.appendChild(threeJsRenderer.domElement);
    }

    function instantiateFakeRendererAndAbortRendering() {
        // bodeia
        div.innerHTML = "OutOfMemory";
        threeJsRenderer = {
            setSize: angular.noop,
            render: angular.noop,
            domElement: {
                toDataUrl: angular.noop,
                toBlob: angular.noop
            },
            context: {
                getError: angular.noop(),
                NO_ERROR: 1
            }
        };
    }

    return threeModule.service("ThreeRenderer", [function () {
        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderer
         * @description
         * Renderizador de cenas.
         * @param {number=} width Largura do renderer, ou 400, caso não definido.
         * @param {number=} height Altura do renderer, ou 320, caso não definido.
         * */
        function ThreeRenderer(width, height) {
            lazyLoadThreeJsRenderer();
            this.width = firstNumber(width, 400);
            this.height = firstNumber(height, 320);

            function firstNumber(value, defaultValue) {
                if (angular.isNumber(value)) {
                    return value;
                }
                return defaultValue;
            }
        }

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderer#getDomObject
         * @methodOf threeModule.ThreeRenderer
         * @description
         * Retorna o objeto DOM onde este renderer irá desenhar os resultados.
         * @returns {DOM} domObject
         * */
        ThreeRenderer.prototype.getDomObject = function () {
            return div;
        };

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderer#setSize
         * @methodOf threeModule.ThreeRenderer
         * @description
         * Define o tamanho deste renderer.
         * @param {number} width Largura.
         * @param {number} height Altura.
         * @returns {threeModule.ThreeRenderer} Renderizador
         * */
        ThreeRenderer.prototype.setSize = function (width, height) {
            this.width = width;
            this.height = height;
            threeJsRenderer.setSize(this.width, this.height);
            return this;
        };

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderer#render
         * @methodOf threeModule.ThreeRenderer
         * @description
         * Renderiza uma scene vista por uma camera.
         * @param {THREE.Scene} scene Cena a ser renderizada.
         * @param {THREE.Camera} camera Camera a ser utilizada.
         * */
        ThreeRenderer.prototype.render = function (scene, camera) {
            var gl = threeJsRenderer.context;
            threeJsRenderer.setSize(this.width, this.height);
            threeJsRenderer.render(scene, camera);
            var error = gl.getError();
            if (error === gl.OUT_OF_MEMORY) {
                // eslint-disable-next-line no-console
                console.error("WEBGL [" + formatError(error) + "], aborting rendering.");
                instantiateFakeRendererAndAbortRendering();
                return;
            }
            if (error !== gl.NO_ERROR) {
                // eslint-disable-next-line no-console
                console.error("WEBGL rendered with error code [" + formatError(error) + "], reloading context and retrying");
                loadThreeJsRenderer();
                threeJsRenderer.setSize(this.width, this.height);
                threeJsRenderer.render(scene, camera);
            }

            function formatError(error) {
                switch (error) {
                    case gl.NO_ERROR:
                        return "NO_ERROR";
                    case gl.INVALID_ENUM:
                        return "INVALID_ENUM";
                    case gl.INVALID_VALUE:
                        return "INVALID_VALUE";
                    case gl.INVALID_OPERATION:
                        return "INVALID_OPERATION";
                    case gl.INVALID_FRAMEBUFFER_OPERATION:
                        return "INVALID_FRAMEBUFFER_OPERATION";
                    case gl.OUT_OF_MEMORY:
                        return "OUT_OF_MEMORY";
                    case gl.CONTEXT_LOST_WEBGL:
                        return "CONTEXT_LOST_WEBGL";
                    default:
                        return error;
                }
            }
        };

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderer#renderAsDataUrl
         * @methodOf threeModule.ThreeRenderer
         * @description
         * Renderiza uma scene, vista por uma camera, e o resultado é retornado
         * como uma string base64.
         * @param {THREE.Scene} scene Cena a ser renderizada.
         * @param {THREE.Camera} camera Camera a ser utilizada.
         * @returns {string} data url
         * */
        ThreeRenderer.prototype.renderAsDataUrl = function (scene, camera) {
            this.render(scene, camera);
            return threeJsRenderer.domElement.toDataURL();
        };

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderer#renderAsBlob
         * @methodOf threeModule.ThreeRenderer
         * @description
         * Renderiza uma cena para um BLOB que pode ser salvo como uma
         * imagem.
         * @param {THREE.Scene} scene Cena a ser renderizada.
         * @param {THREE.Camera} camera Camera a ser utilizada.
         * @param {function} callback Callback que recebe o BLOB gerado.
         * */
        ThreeRenderer.prototype.renderAsBlob = function (scene, camera, callback) {
            threeJsRenderer.domElement.toBlob(callback);
        };

        return ThreeRenderer;
    }]);
});