define([
    "./transitionsModule",
    "text!./nlgTransitions.html",
    "angular"
], function (module, template, angular) {
    "use strict";

    return module.directive("nlgTransitions", [function () {
        return {
            restrict: "E",
            scope: {
                monitorable: "=",
                reload: "&"
            },
            template: template,
            controller: [
                "$scope",
                "xlsService",
                "transitionSpreadsheetService",
                function ($scope, xlsService, transitionSpreadsheetService) {
                    $scope.transitionGroups = [];

                    $scope.exportTransitionSpreadsheet = function () {
                        return transitionSpreadsheetService.multiplesExportTransitionSpreadsheet($scope.monitorable.id, "Extensions Monitorable " + $scope.monitorable.sourceId);
                    };

                    $scope.openImportModal = function () {
                        return transitionSpreadsheetService.openImportModal()
                            .then($scope.reload);
                    };

                }]
        }
    }]);
});