import analysisModule from "../analysisModule"
import orderBreakModal from "./orderBreakModal.html"

analysisModule.service("orderBreakModal",
    ($q,
     $modal,
     orderBreakService,
     loadingService,
     messagesModal,
     remoteExceptionHandler) => {
        return (nodeIdentifiers) => {
            return loadingService(orderBreakService.findBreaks(nodeIdentifiers)).then((result) => {
                return $modal.open({
                    template: orderBreakModal,
                    size: "lg",
                    controller: ["$scope", ($scope) => {

                        $scope.orderBreakPayloads = result.orderBreaks

                        $scope.concatQuantity = (partialQuantity, totalQuantity) => {
                            return partialQuantity + "/" + totalQuantity
                        }

                        $scope.save = () => {
                            loadingService(orderBreakService.resolveOrderBreak({
                                tripNodeIdentifiers: result.tripNodeIdentifiers,
                                orderBreaks: result.orderBreaks
                            })).then(() => {
                                $scope.$close()
                                messagesModal("dialog.success", [{
                                    keyBundle: "resolveOrderBreak.success",
                                    parameters: []
                                }])
                            }).catch(remoteExceptionHandler())
                        }
                    }]
                }).result
            }).catch(error => {
                if (error.data.messages) {
                    let messages = []
                    error.data.messages.forEach(function (warning) {
                        messages.push({
                            keyBundle: warning.messageKey,
                            parameters: warning.arguments
                        })
                    })
                    return messagesModal("dialog.warning", messages)
                } else {
                    return remoteExceptionHandler()(error)
                }
            })
        }
    })
