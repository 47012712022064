import orderTypeModule from "./orderTypeModule"
import server from "../server"

orderTypeModule.service("orderTypeService", ["$http", function ($http) {
    this.findCompatibleCandidates = (id) => {
        return $http.get(server.getServiceUrl("edition.order-type") + "compatible/" + (id || ""))
            .then((response) => response.data)
    }

    this.findIncompatibleCandidates = (id) => {
        return $http.get(server.getServiceUrl("edition.order-type") + "incompatible/" + (id || ""))
            .then((response) => response.data)
    }

    this.findCompatibleVehicles = (orderType) => {
        return $http.post(server.getServiceUrl("edition.order-type") + "compatible-vehicles/", orderType)
            .then((response) => response.data)
    }
}])

