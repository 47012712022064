import orderModule from "./orderModule"
import server from "../server"

orderModule.service("orderService", ["$http", "remoteExceptionHandler", function ($http, remoteExceptionHandler) {
    this.newOrderItem = () => {
        return $http.get(server.getServiceUrl("edition.order") + "order-item/new")
            .then((response) => response.data)
            .catch(remoteExceptionHandler())
    }

    this.getOrder = (id) => {
        return $http.get(server.getServiceUrl("search.order") + id)
            .then((response) => response.data)
            .catch(remoteExceptionHandler())
    }
}])