import analysisModule from "../analysisModule"
import server from "../../server"

analysisModule.service("nodeCommandServeTrip", ["$http", "messagesModal", "remoteExceptionHandler", "loadingService", function ($http, messagesModal, remoteExceptionHandler, loadingService) {
    return nodeIdentifiers => {
        return loadingService($http.post(server.getServiceUrl("trip-serve"), nodeIdentifiers))
            .then((response) => {
                let messages = []
                let warnings = response.data
                if (warnings === null || warnings.length === 0) {
                    return messagesModal("dialog.success", [{
                        keyBundle: "serveTrip.success",
                        parameters: []
                    }])
                }
                warnings.forEach(function (warning) {
                    messages.push({
                        keyBundle: warning.messageKey,
                        parameters: warning.arguments
                    })
                })
                return messagesModal("dialog.warning", messages)
                    .catch(() => {
                        // ignore backdrop click
                    })
            }).catch(remoteExceptionHandler())
    }
}])