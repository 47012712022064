import translateModule from "./r2TranslateModule"

translateModule.filter("translateFilter", ["$translate", "filterFilter", ($translate, filterFilter)=>{
    return function(input, searchText){
        input = input || []
        searchText = searchText || ""
        if(typeof searchText !== "string"){
            throw new Error("Invalid searchText type. Only string is supported")
        }
        return filterFilter(input, searchText, (obj) => {
            return $translate.instant(obj).toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        } )
    }
}])