define(["./workHoursControlModule",
        "json!./config/workHoursControl.json"
    ],
    function (workHoursControlModule, workHoursControl) {
        "use strict";

        workHoursControlModule.service("workHoursControlService", ["$http", function ($http) {

            return {
                filterWorkRecords: filterWorkRecords,
                findWorkHoursControlSettings: findWorkHoursControlSettings,
                updateSettings: updateSettings
            };

            function filterWorkRecords(template, firstResult, maxResults) {
                return $http.post(servicePath("filterWorkRecord"), template, {
                    params: {
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                }).then(getData);
            }

            function findWorkHoursControlSettings() {
                return $http.get(servicePath("settings"))
                    .then(getData);
            }

            function updateSettings(settings) {
                return $http.post(servicePath("updateSettings"), settings);
            }

            function servicePath(serviceId) {
                return workHoursControl.baseUrl + workHoursControl.services[serviceId];
            }

            function getData(response){
                return response.data;
            }

        }]);
    });