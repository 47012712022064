define(["./mapsModule"], function (mapsModule) {
    "use strict";

    /**
     * @ngdoc service
     * @name mapsModule.pointReductionService
     * @description
     * Serviço com algoritmo para redução de pontos.
     *
     * Calcula dada uma curva composta por vários pontos, uma curva semelhante com uma quantidade menor de pontos, removendo os pontos que estão dentro da tolerância.
     * Tolerância é baseada no zoom que está no mapa em um intervalo [0:20]
     **/

    return mapsModule.service("pointReductionService", [function () {
        return douglasPeuckerPointReduction;

        function douglasPeuckerPointReduction(points, zoomLevel) {
            var tolerance = getToleranceInMeters(zoomLevel);
            var quantityPoints, iterator, indexDestination, start, end;
            var distance, toleranceConverted;
            var initialLngDistance, initialLatDistance, initialLatLngDistance, lngDistanceToStart, latDistanceToStart, latLgnDistanceToStart, lngDistanceToEnd, latDistanceToEnd, latLgnDistanceToEnd;
            var F = Math.PI / 360;
            var indexPoints = [];
            var indexToStartCompare = [];
            var indexToEndCompare = [];

            if (points.length < 3) {
                return (points);
            }
            quantityPoints = points.length;
            toleranceConverted = tolerance * 360.0 / (2.0 * Math.PI * 6378137.0);
            toleranceConverted *= toleranceConverted;
            indexDestination = 0;
            indexToStartCompare[0] = 0;
            indexToEndCompare[0] = quantityPoints - 1;
            iterator = 1;

            while (iterator > 0) {
                start = indexToStartCompare[iterator - 1];
                end = indexToEndCompare[iterator - 1];
                iterator--;

                if (end - start <= 1) {
                    indexPoints[indexDestination] = start;
                    indexDestination++;
                    continue;
                }

                initialLngDistance = normalizeDistance(points[end].lng() - points[start].lng());
                initialLngDistance *= Math.cos(F * (points[end].lat() + points[start].lat()));

                initialLatDistance = (points[end].lat() - points[start].lat());
                initialLatLngDistance = (initialLngDistance * initialLngDistance) + (initialLatDistance * initialLatDistance);

                for (var i = start + 1, indexMaxDistance = start, maxDistance = -1.0; i < end; i++) {
                    lngDistanceToStart = normalizeDistance(points[i].lng() - points[start].lng());
                    lngDistanceToStart *= Math.cos(F * (points[i].lat() + points[start].lat()));

                    latDistanceToStart = (points[i].lat() - points[start].lat());
                    latLgnDistanceToStart = (lngDistanceToStart * lngDistanceToStart) + (latDistanceToStart * latDistanceToStart);

                    lngDistanceToEnd = normalizeDistance(points[i].lng() - points[end].lng());
                    lngDistanceToEnd *= Math.cos(F * (points[i].lat() + points[end].lat()));

                    latDistanceToEnd = (points[i].lat() - points[end].lat());
                    latLgnDistanceToEnd = (lngDistanceToEnd * lngDistanceToEnd) + (latDistanceToEnd * latDistanceToEnd);

                    if (latLgnDistanceToStart >= ( initialLatLngDistance + latLgnDistanceToEnd )) {
                        distance = latLgnDistanceToEnd;
                    } else if (latLgnDistanceToEnd >= ( initialLatLngDistance + latLgnDistanceToStart )) {
                        distance = latLgnDistanceToStart;
                    } else {
                        distance = Math.pow(lngDistanceToStart * initialLatDistance - latDistanceToStart * initialLngDistance, 2) / initialLatLngDistance;
                    }

                    if (distance > maxDistance) {
                        indexMaxDistance = i;
                        maxDistance = distance;
                    }
                }

                if (maxDistance < toleranceConverted) {
                    indexPoints[indexDestination] = start;
                    indexDestination++;
                } else {
                    iterator++;
                    indexToStartCompare[iterator - 1] = indexMaxDistance;
                    indexToEndCompare[iterator - 1] = end;
                    iterator++;
                    indexToStartCompare[iterator - 1] = start;
                    indexToEndCompare[iterator - 1] = indexMaxDistance;
                }
            }

            indexPoints[indexDestination] = quantityPoints - 1;
            indexDestination++;

            var result = [];
            for (var j = 0; j < indexDestination; j++) {
                result.push(points[indexPoints[j]]);
            }
            return result;
        }

        function normalizeDistance(distance){
            if (Math.abs(distance) > 180.0) {
                return 360.0 - Math.abs(distance);
            }
            return distance;
        }

        function getToleranceInMeters(zoom) {
            var toleranceByZoom = [30000, 19000, 12000, 8000, 4500, 2000, 600, 400, 220, 110, 50, 30, 15, 7.5, 4.6, 3, 1.5, 0.5, 0.33, 0.2, 0.2];
            return toleranceByZoom[zoom];
        }
    }]);
});