define([
    "angular",
    "./ganttChartModule",
    "text!./ganttChart.html",
    "text!./ganttChartTaskTooltip.html",
], function (angular, ganttChartModule, ganttChartTemplate, ganttChartTaskTooltip) {
    "use strict";

    var viewScales = [
        {viewScale: "15 minutes", headers: ["day", "hour"]},
        {viewScale: "30 minutes", headers: ["day", "hour"]},
        {viewScale: "1 hour", headers: ["day", "hour"]},
        {viewScale: "2 hours", headers: ["day"]},
        {viewScale: "3 hours", headers: ["day"]}
    ];

    return ganttChartModule.directive("nlgGanttChart", ["$filter", function ($filter) {
        var dateFilter = $filter("date");
        return {
            restrict: "E",
            template: ganttChartTemplate,
            scope: {
                rows: "<",
                scale: "<",
                movable: "<",
                tooltips: "<",
                rowTemplate: "<",
                overlap: "<",
                onRegisterApi: "<"
            },
            controller: ["$scope", "$translate", "$rootScope", function ($scope, $translate, $rootScope) {
                $scope.options = {
                    columnWidth: 50,
                    headersFormats: {
                        day: function (column) {
                            return dateFilter(column.date.toDate(), "shortDate") + " (" + dateFilter(column.date.toDate(), "EEEE") + ")";
                        },
                        hour: "HH:mm"
                    },
                    allowSideResizing: false,
                    expandToFit: false,
                    taskOutOfRange: "expand",
                    columnLimitThreshold: 500,
                    maxHeight: 650
                };

                if (!$scope.onRegisterApi) {
                    $scope.onRegisterApi = angular.noop();
                }

                $scope.ganttTooltipTemplate = ganttChartTaskTooltip;
                $scope.ganttTreeConfiguration = {
                    header: $translate.instant("timeConfiguration.treeHeader"),
                    content: $scope.rowTemplate
                };

                $scope.allowRowSwitching = function (task, targetRow) {
                    var row = task.row;

                    if (row.model.name === targetRow.model.parent) {
                        return true;
                    }

                    if (row.model.parent !== null && row.model.parent === targetRow.model.parent) {
                        return true;
                    }

                    if (row.model.parent === targetRow.model.name) {
                        return true;
                    }

                    return false;
                };

                $scope.zoomOut = $rootScope.$on("zoomOut", function () {
                    return zoomOut();
                });

                function zoomOut() {
                    var currentIndex = viewScales.indexOf(getViewScale());
                    if (currentIndex < viewScales.length - 1) {
                        setViewScale(viewScales[currentIndex + 1]);
                    }
                }

                $scope.zoomIn = $rootScope.$on("zoomIn", function () {
                    return zoomIn();
                });

                function zoomIn() {
                    var currentIndex = viewScales.indexOf(getViewScale());
                    if (currentIndex > 0) {
                        setViewScale(viewScales[currentIndex - 1]);
                    }
                }

                function getViewScale() {
                    return $scope.scale;
                }

                function setViewScale(viewScale) {
                    $scope.scale = viewScale;
                }

                setViewScale($scope.scale);
            }]
        };
    }]);
});