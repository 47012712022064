define([
    "./driverMapModule",
    "text!./DriverMapPopupView.html",
    "img!../../tracking/truck.png",
    "lodash"
], function (driverMapModule, driverMapPopupTemplate, truckMarkerIcon, _) {
    "use strict";

    return driverMapModule.controller("DriverMapController", [
        "$scope",
        "$q",
        "trackingMobileService",
        "loadingService",
        "remoteExceptionHandler",
        "markerService",
        "$interval",
        "trackingService",
        function ($scope, $q, trackingMobileService, loadingService, remoteExceptionHandler, markerService, $interval, trackingService) {
            var template = {}, paginationApi;
            var first = {};
            var max = {};
            $scope.serviceName = trackingMobileService.driversMapService();

            clearMap();
            $scope.paginationOptions = {
                onRegisterPagination: function (api) {
                    paginationApi = api;
                },
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        var filterResult;
                        clearMap();
                        return loadingService(trackingMobileService.filterDriversMap(template, firstResult, maxResults))
                            .catch(remoteExceptionHandler())
                            .then(function (_filterResult) {
                                first = firstResult;
                                max = maxResults;
                                filterResult = _filterResult;
                                var driverMaps = _filterResult.result;
                                $scope.driverMaps = driverMaps;
                                return driverMaps.map(function (driverMap) {
                                    $scope.model.realizedRoutes.push({path: driverMap.signals});
                                    return (driverMap.marker = createMarkers(driverMap.driver, driverMap.device, $scope.lastSignal(driverMap)));
                                });
                            })
                            .then(function (markers) {
                                $scope.model.markers = markers.filter(function (marker) {
                                    return !!marker;
                                });
                            })
                            .then(function () {
                                return filterResult;
                            });
                    }
                }
            };

            var intervalKey = $interval(function () {
                //Para evitar problemas, só começamos o fluxo de atualização automatica após o usuário
                // clicar uma vez no botão pesquisar
                if ($scope.driverMaps) {
                    // Filtra novamente, para atualizar os motoristas que estão ativos, pois no back-end,
                    // só trazemos motoristas que possuam sinal. Caso contrario só atualizaria os motoristas
                    // que vieram na priemra pesquisa realizada
                    trackingMobileService.filterDriversMap(template, first, max).then(function (filterResult) {
                        $scope.driverMaps = filterResult.result;
                    });
                    $scope.driverMaps.forEach(function (driverMap) {
                        var lastTimestamp = driverMap.signals[0].signalTime + 1;
                        trackingService.getLastSignalsForDeviceSince(driverMap.driver.id, lastTimestamp)
                            .then(function (newSignals) {
                                if (newSignals.length !== 0) {
                                    configDrivePosition(driverMap, newSignals);
                                }
                            });
                    });
                }
            }, 30000);
            $scope.$on("$destroy", function () {
                $interval.cancel(intervalKey);
            });

            function configDrivePosition(driverMap, signals) {
                var newSignals = signals.concat(driverMap.signals);
                driverMap.signals = newSignals;
                $scope.model.realizedRoutes.push({path: newSignals});

                $scope.model.markers = _.remove($scope.model.markers, function (marker) {
                    return marker.identifier === driverMap.driver.id;
                });
                $scope.model.markers.push(createMarkers(driverMap.driver, driverMap.device, $scope.lastSignal(driverMap)));
            }

            function clearMap() {
                $scope.model = {
                    realizedRoutes: [],
                    routes: [],
                    markers: []
                };
            }

            $scope.registerApi = function (api) {
                $scope.center = function (driverMap) {
                    api.centerMapOnCoords(driverMap.signals[0]);
                };
            };

            $scope.lastSignal = function (driverMap) {
                if (!driverMap.signals || driverMap.signals.length === 0) {
                    return null;
                }
                return driverMap.signals[0];
            };

            $scope.search = function (modifiedTemplate) {
                template = modifiedTemplate;
                return paginationApi.seekPage(1);
            };

            function createMarkers(driver, device, signal) {
                if (!signal) {
                    return null;
                }
                return markerService.createMarker(driver.id, driver.id, signal, truckMarkerIcon, {
                    template: driverMapPopupTemplate,
                    templateParameter: {
                        driver: driver,
                        device: device,
                        lastUpdate: signal.signalTime
                    }
                });
            }
        }]);
});