import searchModule from "./searchModule"
import actionColumnButtons from "./actionColumnButtons.html"
import deletionCheckbox from "./deletionCheckbox.html"
import headerDeletionCheckbox from "./headerDeletionCheckbox.html"
import ignoringWarningsDeletionModal from "./ignoringWarningsDeletionModal.html"
import server from "../server"
import tdnByEntity from "./tdnByEntity.json"

searchModule.controller("searchController", function ($scope, $q, $modal, searchService, editionService, spreadsheetModal, spreadsheetDeletionModal, messagesModal, loadingService, spreadsheetCustomActionsService) {

    const entityType = $scope.entityType
    const paginationApi = $q.defer()
    let filterApi
    const filterApiDefer = $q.defer()
    let filterPatch = []

    $scope.tdn = tdnByEntity[$scope.entityType]
    $scope.filterServiceUrl = server.getRelativeUrl("search." + entityType)

    $scope.tableOptions = {
        records: [],
        columns: [],
        pagination: {
            onPaginate: (firstResult, maxResults) => {
                return searchService.filter(entityType, filterPatch, firstResult, maxResults)
                    .then((response) => {
                        var firstResult = response.result[0]
                        $scope.tableOptions.columns = getColumnDefinitions(firstResult.children[0])
                        return {
                            result: firstResult.children,
                            firstResult: response.firstResult,
                            maxResults: response.maxResults,
                            count: response.count
                        }
                    })
            }
        },
        onRegisterPagination: function (api) {
            paginationApi.resolve(api)
        }
    }

    $scope.onRegisterFilterApi = function (api) {
        filterApi = api
        filterApiDefer.resolve()
    }

    $q.all([paginationApi.promise, filterApiDefer.promise]).then(function () {
        filterApi.onChangeService(function (service) {
            if (service === $scope.filterServiceUrl) {
                if ($scope.reload) {
                    filterApi.reload()
                } else {
                    filterApi.reset()
                }
            }
        })
    })

    $scope.search = function (patch) {
        filterPatch = patch
        $scope.model = {}
        $scope.model.check = {}
        return paginationApi.promise.then((api) => api.seekPage(1))
    }

    $scope.deleteEntity = function (record) {
        messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"]).then(function () {
            loadingService(editionService.deleteEntity(entityType, record.id))
                .then(() => {
                    $scope.search(filterPatch)
                        .then(function () {
                            openDeletionSuccessModal()
                        })
                })
                .catch(function (deleteErrorResponse) {
                    if (deleteErrorResponse.data.hasOnlyWarnings) {
                        $modal.open({
                            template: ignoringWarningsDeletionModal,
                            controller: ["$scope", "$modalInstance", function ($scope, $modalInstance) {
                                $scope.messageList = deleteErrorResponse.data.messages
                                $scope.proceedDeletion = function () {
                                    $modalInstance.close()
                                    loadingService(editionService.deleteEntityIgnoringWarnings(entityType, record.id))
                                        .then(openDeletionSuccessModal)
                                        .catch(openDeletionFailureModal)
                                }
                            }]
                        })
                    } else {
                        openDeletionFailureModal(deleteErrorResponse)
                    }
                })
        })

        function openDeletionSuccessModal() {
            $scope.search(filterPatch)
                .then(function () {
                    messagesModal("entityFilter.delete.ok.header", [{
                        keyBundle: "entityFilter.delete.ok",
                        parameters: []
                    }])
                })
        }

        function openDeletionFailureModal(errorResponse) {
            messagesModal("entityFilter.delete.error.header", errorResponse.data.messages)
        }
    }

    $scope.deleteEntities = function () {
        var checkedForDeletion = $scope.tableOptions.records.filter(function (record) {
            return $scope.model.check[record.id]
        })
        var deletionIds = checkedForDeletion.map(function (record) {
            return record.id
        })
        messagesModal.cancellable("dialog.proceed?", [{
            keyBundle: "entityFilter.delete.multiple",
            parameters: [checkedForDeletion.map(function (record) {
                return record.properties[0].value
            })]
        }]).then(function () {
            return loadingService(editionService.deleteEntities(entityType, deletionIds))
                .then(() => {
                    return $scope.search(filterPatch)
                        .then(function () {
                            return messagesModal("entityFilter.delete.ok.header", [{
                                keyBundle: "entityFilter.delete.ok",
                                parameters: []
                            }])
                        })
                })
                .catch(function (deleteErrorResponse) {
                    return $scope.search(filterPatch)
                        .then(function () {
                            return messagesModal("entityFilter.delete.error.header", deleteErrorResponse.data.messages)
                        })
                })
        })
    }

    $scope.toggleAll = function () {
        for (var record of $scope.tableOptions.records) {
            $scope.model.check[record.id] = $scope.model.headerCheck
        }
    }

    $scope.allChildrenChecked = function () {
        for (var record of $scope.tableOptions.records) {
            if (!$scope.model.check[record.id]) {
                return false
            }
        }
        return true
    }

    $scope.hasCheckedBox = function () {
        return $scope.tableOptions.records.some(function (record) {
            return $scope.model.check[record.id]
        })
    }

    $scope.spreadsheetModal = (entityType) => {
        spreadsheetModal.show(entityType)
    }

    $scope.spreadsheetDeletionModal = () => {
        spreadsheetDeletionModal.show(entityType)
    }

    $scope.model = {}
    $scope.model.check = {}
    $scope.customizedOptions = spreadsheetCustomActionsService.customizedOptions($scope.entityType)

    function getColumnDefinitions(firstRow) {
        if (!firstRow) {
            return []
        }

        var actionColumn = {
            "width": "120px",
            "cell": {
                template: actionColumnButtons
            }
        }

        var checkbox = {
            "width": "30px",
            "header": {
                template: headerDeletionCheckbox,
            },
            "cell": {
                template: deletionCheckbox,
            }
        }
        var columns
        if ($scope.enableDeletionCheckbox) {
            columns = [checkbox].concat([actionColumn])
        } else {
            columns = [actionColumn]
        }

        return columns.concat(firstRow.properties.map(function (property, index) {
            var propertyPath = "properties[" + index + "].value"
            var cellValue = "record." + propertyPath
            return {
                sortBy: propertyPath,
                cellName: property.messageKey.replace(/.*\.(\w+)$/, "$1"),
                header: property.messageKey,
                cell: getCellTemplateString(cellValue, property.type)
            }

            function getCellTemplateString(cellValue, propertyType) {
                switch (propertyType) {
                    case "STRING":
                        return "{{" + cellValue + "}}"
                    case "ENTITY":
                    case "IDENTIFIABLE":
                        return "{{" + cellValue + " | entityFormat}}"
                    case "DATETIME":
                        return "{{" + cellValue + " | date:'short'}}"
                    case "NUMBER":
                        return "{{" + cellValue + " | number}}"
                }
                throw new Error("type not found: " + propertyType)
            }
        }))
    }
})