import jobModule from "./jobModule"
import jobsView from "./jobsView.html"

jobModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.optimization", {
        data: {
            pageTitle: "{{'title.r2.optimization' | translate}}"
        },
        url: "/programming/optimization",
        template: jobsView,
        controller: "jobController"
    })
}])
