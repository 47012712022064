define(["./nlgBasketModule"], function (nlgBasketModule) {
    "use strict";

    return nlgBasketModule.factory("nlgBasketServiceFactory", ["$http", function ($http) {
        return function (treePath, basePath) {
            return {
                /**
                 * @ngdoc method
                 * @name nlgBasketModule.nlgBasketService#doFilterPresentationTree
                 * @methodOf nlgBasketModule.nlgBasketService
                 * @description
                 * Retorna as entidades de um serviço que respeitam o filtro fornecido.
                 * A diferença deste método para o {@link nlgBasketModule.nlgBasketService#doFilterPresentationTree doFilterPresentationTree}
                 * é que este deve ser usado para filtrar pelo serviço de PresentationTree.
                 *
                 * @param {string} service Serviço à ser buscado.
                 * @param {jsonpatch} patch Patch do filtro retornado por {@link nlgBasketModule.nlgBasketService#getFilter getFilter}.
                 * @param {object} [params] Objeto com parâmetros adicionais para busca.
                 * @returns {HttpPromise<object[]>} Array com as entidades válidas.
                 */
                doFilterPresentationTree: function (service, patch, params) {
                    return $http({
                        url: buildUrl(service),
                        method: "POST",
                        data: patch,
                        params: params
                    });
                },
                /**
                 * @ngdoc method
                 * @name nlgBasketModule.nlgBasketService#getEmptyPresentationTree
                 * @methodOf nlgBasketModule.nlgBasketService
                 * @description
                 * Retorna uma PresentationTree vazia.
                 *
                 * @param {string} service Serviço à ser buscado.
                 * @returns {HttpPromise<PresentationNode>} PresentationNode.
                 */
                getEmptyPresentationTree: function (service) {
                    return $http.get(buildUrl(service)).then(function (response) {
                        return response.data;
                    });
                },
                /**
                 * @ngdoc method
                 * @name nlgBasketModule.nlgBasketService#doLoadLazyChildren
                 * @methodOf filterModule.filterService
                 * @description
                 * Retorna uma promessa cujo resultado é a sub-árvore a partir do parent de id informado.
                 * @param {String} service Serviço da entidade pai.
                 * @param {integer} parentNodeId Identificador da entidade pai, cuja sub-árvore será carregada.
                 * @returns {HttpPromise<FromToNode[]>} Snapshot.
                 */
                doLoadLazyChildren: function (service, parentNodeId, lazyChildrenParams) {
                    return $http({
                        url: buildUrl(service, parentNodeId),
                        method: "GET",
                        params: lazyChildrenParams
                    });
                }
            };

            function buildUrl(service, parentNodeId) {
                var urlParts = [basePath, service, treePath];
                if (!parentNodeId) {
                    return urlParts.join("/");
                }
                urlParts.push(parentNodeId);
                return urlParts.join("/");
            }
        };
    }]);
});