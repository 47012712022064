import biModule from "./biModule"
import {
    DELEGATE_SESSION_URL, LIBRARY_URL
} from "./microStrategyConstants"
import server from "../server"

biModule.directive("nlgMicroStrategy", [() => {
    return {
        restrict: "E",
        template: `<div id="LibraryHomePageContainer" style="width: 100%; min-height: 85vh"></div>`,
        scope: true,
        controller: function (loadingService, $http, remoteExceptionHandler) {

            const microstrategy = window.microstrategy
            const config = {
                serverUrl: LIBRARY_URL,
                placeholder: document.getElementById("LibraryHomePageContainer")
            }
            loadingService(loginInternal()).then(() => microstrategy.embeddingContexts.embedLibraryPage(config))

            //TODO FAZER IR PRA HOME
            function loginInternal() {
                return $http.get(server.getServiceUrl("microstrategy-login"))
                    .catch(remoteExceptionHandler())
                    .then((response) => {
                        return authenticate(response.data.token)
                    })
            }

            function authenticate(token) {
                const options = {
                    method: "POST",
                    credentials: "include",
                    mode: "cors",
                    headers: {"content-type": "application/json"},
                    body: JSON.stringify({
                        "loginMode": -1,
                        "identityToken": token
                    })
                }
                return fetch(DELEGATE_SESSION_URL, options)
            }
        }
    }
}
])