define(["angular", "./nlgTemplateModule"], function (angular, module) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:nlgTemplate
     * @restrict EA
     * @description
     * Compila um template HTML e o adiciona como filho do elemento DOM que contém esta diretiva.
     *
     * @param {string} nlgTemplate
     * Campo string que será compilado como HTML e adicionado como filho do elemento na DOM.
     *
     * @param {scope=} nlgTemplateScope
     * Escopo que será utilizado durante a compilação do template. Por padrão é o escopo do elemento DOM que
     * está com esta diretiva.
     *
     * @param {boolean=} nlgTemplateReplace
     * Boolean que indica se o template deverá subistitiuir o elemento que contém a diretiva, ou não.
     * Por padrão é true.
     *
     * @example
     * Caso de uso simples
     * ===
     * <example module="FrontEndWeb">
          <file name="index.html">
             <div ng-controller="test">
                 <div nlg-template="template"></div>
             </div>
          </file>
          <file name="index.js">
             angular
                .module("FrontEndWeb")
                .controller("test", function ($scope) {
                   $scope.template = "<div style='border: 1px solid red;'>{{ message }}</div>";
                   $scope.message = "Olá mundo";
                })
          </file>
      </example>
     *
     *
     * Sem substituição do elemento
     * ===
     * <example module="FrontEndWeb">
          <file name="index.html">
             <div ng-controller="test">
                 <div style="border: 1px solid blue; padding: 10px;"
                      nlg-template="template"
                      nlg-template-replace="false">

                     Olha que coisa boa.
                 </div>
             </div>
          </file>
          <file name="index.js">
             angular
                .module("FrontEndWeb")
                .controller("test", function ($scope) {
                   $scope.template = "<div style='border: 1px solid red;'>{{ message }}</div>";
                   $scope.message = "Olá mundo";
                })
          </file>
      </example>
     */
    return module.directive("nlgTemplate", ["$compile", function ($compile) {
        return {
            restrict: "EA",
            scope: {
                template: "&nlgTemplate",
                scope: "<?nlgTemplateScope",
                replace: "@?nlgTemplateReplace"
            },
            link: function (scope, element) {
                var template = angular.element(scope.template());
                element.append(template);
                var compiledTemplate = $compile(template)(scope.scope || scope.$parent);

                if (scope.replace !== "false") {
                    element.replaceWith(compiledTemplate);
                }
            }
        };
    }]);
});
