define([
    "./attributableModule",
    "text!./attributableEdition.html"
], function (attributableModule, template) {
    "use strict";

    attributableModule.directive("attributableEdition", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                attributeByType: "=",
                ngDisabled: "=?",
                maxValueLength: "=?"
            },

            controller: ["$scope", function ($scope) {
                $scope.attributableTableOptions = {
                    records: $scope.attributeByType,
                    columns: [{
                        header: "edition.attributable.type",
                        cell: "{{ record.genericAttributeType | entityFormat }}",
                        width: "30%"
                    }, {
                        header: "edition.attributable.value",
                        cell: {
                            model: "record.value",
                            type: "text",
                            disabled: "ngDisabled ||! record.editable",
                            maxLength: $scope.maxValueLength
                        },
                        sortBy: "value",
                        cellName: "attribute"
                    }]
                };
            }]
        };
    }]);
});