define(["./nlgTableDynamicColumnsModule", "text!./nlgTableRows.html"], function (nlgTableDynamicColumnsModule, tableRowsTemplate) {
    "use strict";

    /**
     * @ngdoc directive
     * @name nlgTableDynamicColumns.directive:nlgTableRows
     * @restrict A
     * @element tr
     * @requires directives.directive:nlgTable
     * @description
     * Exibe as colunas definidas na tableOptions.columns da diretiva {@link directives.directive:nlgTable nlgTable}
     *
     * Esta diretiva costuma ser utilizada em conjunto com a {@link nlgTableDynamicColumns.directive:nlgTableHeaders nlgTableHeaders}.
     *
     * @example
     * <example module="FrontEndWeb">
          <file name="index.html">
             <div ng-controller="test">
                 <table nlg-table="tableOptions">
                     <tbody>
                         <tr ng-repeat="record in tableOptions.records"
                             nlg-table-rows></tr>
                     </tbody>
                 </table>
             </div>
          </file>
          <file name="index.js">
             angular
                .module("FrontEndWeb")
                .controller("test", function ($scope) {
                   $scope.tableOptions = {
                       records: [
                           {id: 10, desc: '10'},
                           {id: 20, desc: '20'},
                           {id: 30, desc: '30'}
                       ],
                       columns: [
                           {cell: "{{ record.id }}"},
                           {cell: {
                              type: "text",
                              model: "record.desc"
                           }}
                       ]
                   };
                })
          </file>
      </example>
     */
    return nlgTableDynamicColumnsModule.directive("nlgTableRows", ["templateFactory", function (templateFactory) {
        return {
            restrict: "A",
            require: "^nlgTable",
            template: tableRowsTemplate,
            scope: true,
            controller: ["$scope", function ($scope) {
                $scope.getRowScope = function () {
                    return $scope.$parent;
                };

                $scope.createTemplate = function (column) {
                    return templateFactory(column.cell)[0].outerHTML;
                };
            }],
            link: function (scope, element, attrs, nlgTableController) {
                scope.tableOptions = nlgTableController.tableOptions;
            }
        };
    }]);
});