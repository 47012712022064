define(["./violationModule", "text!./violationModalLink.html"], function (violationModule, violationModalLinkTemplate) {
    "use strict";

    /**
     * @ngdoc directive
     * @name violationModule.directive:violationModalLink
     * @restrict E
     * @description
     * Diretiva que cria links para as violações mais detalhadas geradas com
     * {@link violationModule.directive:violationModalDescription violationModalDescription}.
     *
     * @param {expression} index Índice desta violação.
     * @param {expression} code Código da entidade com violação.
     * @param {string} type Tipo da violação: `trip` ou `delivery.unit`.
     * */
    return violationModule.directive("violationModalLink", [function () {
        return {
            restrict: "E",
            template: violationModalLinkTemplate,
            controller: ["$scope", "$attrs", "$location", "$anchorScroll", function ($scope, $attrs, $location, $anchorScroll) {
                $scope.index = $scope.$eval($attrs.index);
                $scope.code = $scope.$eval($attrs.code);
                $scope.type = $attrs.type;

                $scope.description = $attrs.description !== undefined
                    ? $attrs.description
                    : "violation";

                $scope.scroll = function (name) {
                    $location.hash(name);
                    $anchorScroll();
                };
            }]
        };
    }]);
});
