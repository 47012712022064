import restrictionsModule from "./restrictionsModule"
import server from "../server"

restrictionsModule.service("restrictionsService", ["$http", function($http) {

    return {
        findRestrictions: findRestrictions,
        updateRestrictions: updateRestrictions
    }

    function findRestrictions() {
        return $http.get(server.getServiceUrl("restrictions"))
            .then((response) => response.data)
    }

    function updateRestrictions (patches, version)  {
        return $http.post(server.getServiceUrl("restrictions") + "version/" + version, patches)
    }
}])