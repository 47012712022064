import authenticationModule from "./authenticationModule"
import gatewayServer from "../gatewayServer"

authenticationModule.service("authenticationService", ["$localStorage", "$http", "$httpParamSerializer", function ($localStorage, $http, $httpParamSerializer) {

    let authenticationCallbacks = []
    let targetState = null
    let targetStateParams = null

    /**
     * @returns {boolean} true se o usuário atual estiver autenticado
     */
    this.isAuthenticated = function isAuthenticated() {
        return !!this.getUserToken()
    }

    /**
     * Armazena a url especificada para uso posterior. Tipicamente, esta url reflete a intenção de um usuário, ainda
     * não autenticado, acessar um recusro. Pode ser obtida pós login para redirecionamento através de #getTargetUrl()
     */
    this.setTargetState = (state, params) => {
        targetState = state
        targetStateParams = params
    }

    /**
     * Retorna a url configurada anteriormente.
     */
    this.getTargetState = function getTargetState() {
        return targetState
    }

    this.getTargetStateParams = () => {
        return targetStateParams
    }

    this.addAuthenticationCallback = Array.prototype.push.bind(authenticationCallbacks)

    /**
     * Autentica o usuário a partir das credenciais especificadas.
     * @param login nome do usuário
     * @param password senha
     * @returns {Promise}
     */
    this.authenticate = function authenticate(login, password) {
        return $http({
            url: gatewayServer.getServiceUrl("authentication"),
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded", "applicationClient": "default"},
            data: $httpParamSerializer({
                username: login,
                password: password
            })
        }).then((response) =>{
            $localStorage["user.token"] = response.data.token
            $localStorage["current.user.name"] = login
            $localStorage.$apply()
            authenticationCallbacks.forEach((callback) => {
                if(callback.onLogIn){
                    callback.onLogIn()
                }
            })
        })
    }

    /**
     * Finaliza a sessão do usuário.
     */
    this.logOut = function logOut() {
        delete $localStorage["user.token"]
        authenticationCallbacks.forEach((callback) => {
            if(callback.onLogOut) {
                callback.onLogOut()
            }
        })
    }

    /**
     * Retorna o token do usuário atualmente logado.
     */
    this.getUserToken = function(){
        return $localStorage["user.token"]
    }

    this.validateToken = ()=>{
        return $http.get(gatewayServer.getServiceUrl("token") + "/" + this.getUserToken())
            .then(()=>{
                authenticationCallbacks.forEach((callback) => {
                    if(callback.onLogIn){
                        callback.onLogIn()
                    }
                })
            })
    }

    this.getCurrentUserName = () => {
        return $localStorage["current.user.name"]
    }
}])