define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryCheckBoxTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no `checkbox` associado
     **/

    /**
     * @ngdoc function
     * @name templateFactoryCheckBoxTemplate.templateFactoryCheckBoxTemplate
     * @description
     * Template utilizado para criar um `input` do tipo `checkbox`.
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     **/
    return templateFactoryModule.service("templateFactoryCheckBoxTemplate", [function () {
        return function (config) {
            var element = angular.element("<input>").attr("type", "checkbox");
            /**
             * Forca inicialização do model.
             * Não força inicialização para indeterminate, pois `undefined` é um valor aceitável neste caso.
             */
            if (!config.indeterminate) {
                element.attr("ng-init", config.model + "=!!" + config.model);
            }
            element.attr("ng-model", config.model);
            element.attr("ng-checked", config.checked);
            element.attr("nlg-indeterminate", config.indeterminate);
            element.attr("ng-disabled", config.disabled);
            element.attr("ng-click", config.click);
            element.attr("ng-change", config.onChange);
            return element;
        };
    }]);
});