define([
    "../nlgHealthCheckerModule"
], function (nlgHealthCheckerModule) {
    "use strict";

    return nlgHealthCheckerModule.service("pieCheckerHistoryGraph", [
        "typeFormatService",
        function (typeFormatService) {
            return {
                canHandle: function (reasonReading) {
                    return reasonReading.type === "PieReason";
                },
                create: function () {
                    var obj = {};
                    var formatter;
                    return {
                        configureData: function (defaultConfiguration, datas) {
                            defaultConfiguration.json = datas.map(function (x) {
                                formatter = typeFormatService.formatterByType(x.value.pieTypeFormat);
                                Object.keys(x.value.datas).forEach(function (key) {
                                    obj[key] = 1;
                                });
                                return Object.assign({
                                    timestamp: x.timestamp
                                }, x.value.datas);
                            }).reduce(function (a, b) {
                                return a.concat(b);
                            }, []);
                            defaultConfiguration.keys.value = Object.keys(obj);
                            return defaultConfiguration;
                        },
                        configureChart: function (defaultConfiguration) {
                            defaultConfiguration.axis.y = {
                                tick: {
                                    format: function (x) {
                                        return formatter(x);
                                    }
                                }
                            };
                            return defaultConfiguration;
                        }
                    };
                }
            };
        }]);
});
