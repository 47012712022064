import analysisModule from "../analysisModule"
import server from "../../server"

analysisModule.service("nodeCommandInvoiceView", [
    "invoiceViewModal",
    "$http",
    "messagesModal",
    "remoteExceptionHandler",
    "loadingService",
    function (invoiceViewModal, $http, messagesModal, remoteExceptionHandler, loadingService) {
        return function (selection) {
            return loadingService($http.post(server.getServiceUrl("invoicesByNodeIdentifiers"), selection)
                .then(function (response) {
                    if (response.data.invoices.length === 0) {
                        return messagesModal("dialog.warning", ["invoice.modal.is.empty"])
                    }
                    return invoiceViewModal(response.data.invoices)
                })).catch(remoteExceptionHandler())
        }
    }])