define([
    "angular",
    "./genericParamsModule",
    "text!./genericParamDataEditionModal.html",
    "../objects/objects"
], function (angular, programmingModule, template, objects) {
    "use strict";

    return programmingModule.service("GenericParamWithEntityFilterService", [
        "$http",
        "filterService",
        "genericParamsUrls",
        function ($http, filterService, genericParamsUrls) {
            var basePath = genericParamsUrls.basePath;

            function GenericParamWithEntityFilterService(currentDTO) {
                if (!(this instanceof GenericParamWithEntityFilterService)) {
                    return new GenericParamWithEntityFilterService(currentDTO);
                }
                this.currentDTO = currentDTO;
            }

            GenericParamWithEntityFilterService.prototype.getFilter = function getFilter(service) {
                return $http.post(basePath + service + "/filter-template-with-entity", {
                    "currentEntity": this.currentDTO
                });
            };

            GenericParamWithEntityFilterService.prototype.doFilter = function doFilter(service, patch, params) {
                return $http({
                    url: basePath + service + "/filter-with-entity",
                    method: "POST",
                    data: {
                        "patches": patch,
                        "currentEntity": this.currentDTO
                    },
                    params: params || {}
                });
            };

            objects.enrich(GenericParamWithEntityFilterService.prototype, filterService);
            return GenericParamWithEntityFilterService;
        }]);
});
