define([
    "./revisionModule",
    "text!./nlgRevisionTable.html"
], function (module, template) {
    "use strict";

    return module.directive("nlgRevisionTable", [function () {
        return {
            template: template,
            controllerAs: "ctrl",
            controller: ["revisionService", function (revisionService) {
                var controller = this;

                controller.revisions = [];

                revisionService.getRevisions().then(function (revisions) {
                    controller.revisions = revisions;
                });
            }]
        };
    }]);
});