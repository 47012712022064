import spreadsheetModule from "./spreadsheetModule"
import server from "../server"
import newSpreadsheetConstants from "./spreadsheetConstants.json"
import confirmationModalView from "../edition/confirmationModalView.html"

spreadsheetModule.controller("newSpreadsheetController", ["$scope", "$q", "spreadsheetService", "messagesModal", "$filter", "loadingService", "$modal",
    function ($scope, $q, spreadsheetService, messagesModal, $filter, loadingService, $modal) {
        $scope.model = {file: null, selectedEntity: null}

        $scope.selectableEntities = ["PRODUCT_CATEGORY",
            "VEHICLE_AVAILABILITY",
            "LOCALITY",
            "ORDER",
            "PERMITTED_PLATES",
            "PRODUCT",
            "SERVICE_TIME",
            "VEHICLE",
            "FORBIDDEN_VEHICLES"
        ]

        $scope.isAnyEntitySelected = () => {
            return $scope.model.selectedEntity === undefined || $scope.model.selectedEntity === null
        }

        $scope.isEnabledImportButton = () => {
            let file = $scope.model.file
            return $scope.isAnyEntitySelected() || (file === undefined || file === null)
        }

        $scope.generateModel = () => {
            spreadsheetService.generateModel($scope.model.selectedEntity)
        }

        $scope.import = () => {
            spreadsheetService.newSpreadsheetImport($scope.model.selectedEntity, $scope.model.file)
                .then(function () {
                    return messagesModal("dialog.success", "newSpreadsheet.import.success")
                })
                .then(() => {
                    $scope.model = {file: null, selectedEntity: $scope.model.selectedEntity}
                })
                .catch((e) => {
                    const messages = e.messages
                    if (!messages || messages.length === 0) {
                        messagesModal("dialog.error", "import.exception.invalidSpreadsheet")
                        return
                    }
                    return messagesModal("dialog.error", messages)
                })
        }

        $scope.filterService = server.getRelativeUrl("spreadsheet-integration")

        $scope.isPossibleToSeeViolations = (status) => {
            return status === newSpreadsheetConstants.PROCESSING_STATUS || status === newSpreadsheetConstants.SUCCESS_STATUS ||
                status === newSpreadsheetConstants.WAITING || status === newSpreadsheetConstants.INTERNAL_ERROR ||
                status === newSpreadsheetConstants.CANCELLED
        }

        const paginationApi = $q.defer()
        let filterPatch = []
        $scope.patchValue = undefined

        $scope.tableOptions = {
            records: [],
            pagination: {
                onPaginate: function (firstResult, maxResults) {
                    const filterOptions = {
                        patches: filterPatch,
                        firstResult: firstResult,
                        maxResults: maxResults
                    }
                    return spreadsheetService.filterRegisters(filterOptions)
                }
            },
            onRegisterPagination: function (api) {
                paginationApi.resolve(api)
            }
        }

        $scope.search = (patch) => {
            filterPatch = patch
            return paginationApi.promise.then((api) => api.seekPage(1))
        }

        $scope.getDuration = (duration) => {
            return duration < 1000 ? duration + "ms" : $filter("duration")(duration)
        }

        $scope.getViolations = (violationsId, entityName) => {
            spreadsheetService.importRegisterErrors(violationsId, entityName.replace("br.com.neolog.model.spreadsheetregister.R2SpreadsheetIntegrationEntityName.", ""))
        }

        $scope.isPossibleToSeeInternalError = (status) => {
            return status === newSpreadsheetConstants.INTERNAL_ERROR
        }

        $scope.getInternalErrorMessageModal = () => {
            return messagesModal("dialog.error", ["spreadsheet.internal.error.importing.spreadsheet"])
        }

        $scope.formatEntityName = (entityName) => {
            if (entityName === newSpreadsheetConstants.FORBIDDEN_VEHICLES) {
                return "FORBIDDEN_VEHICLES"
            }
            if (entityName === newSpreadsheetConstants.LOCALITY) {
                return "LOCALITY"
            }
            if (entityName === newSpreadsheetConstants.ORDER) {
                return "ORDER"
            }
            if (entityName === newSpreadsheetConstants.PERMITTED_PLATES) {
                return "PERMITTED_PLATES"
            }
            if (entityName === newSpreadsheetConstants.PRODUCT) {
                return "PRODUCT"
            }
            if (entityName === newSpreadsheetConstants.PRODUCT_CATEGORY) {
                return "PRODUCT_CATEGORY"
            }
            if (entityName === newSpreadsheetConstants.SERVICE_TIME) {
                return "SERVICE_TIME"
            }
            if (entityName === newSpreadsheetConstants.VEHICLE) {
                return "VEHICLE"
            }
            if (entityName === newSpreadsheetConstants.VEHICLE_AVAILABILITY) {
                return "VEHICLE_AVAILABILITY"
            }
        }

        $scope.cancelImport = (entityName, registerId) => {
            $scope.openWarningModal(registerId).then(() => {
                loadingService(spreadsheetService.newSpreadsheetCancelImport($scope.formatEntityName(entityName), registerId)).then(() => {
                    messagesModal("dialog.success", "spreadsheet.cancel.success")
                        .finally(() => {
                            paginationApi.promise.then((api) => api.seekPage(1))
                        })
                })
            })
        }

        $scope.openWarningModal = (registerId) => {
            return $modal.open({
                controller: ["$scope", ($scope) => {
                    $scope.header = "dialog.warning"
                    $scope.messageList = [{
                        keyBundle: "spreadsheet.cancel.ask",
                        parameters: [registerId]
                    }]
                }],
                template: confirmationModalView
            }).result
        }

        $scope.shouldDisableCancelImport = (status) => {
            return status !== newSpreadsheetConstants.PROCESSING_STATUS
        }
    }])