define(["./nodeCommandsModule"], function (nodeCommandsModule) {
    "use strict";

    return nodeCommandsModule.service("nodeCommandTripPickingList", [
        "tripPickingListService",
        "remoteExceptionHandler",
        function (tripPickingListService, remoteExceptionHandler) {
            return function (selection, context) {
                if (selection && selection.length > 0) {
                    return tripPickingListService.tripPickingListFromNodeIdentifiers(selection)
                        .catch(remoteExceptionHandler())
                        .catch(function () {
                            context.refilter();
                        });
                }
            };
        }
    ]);
});