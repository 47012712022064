define([
    "./chartingModule",
    "lodash",
    "angular"
], function (chartingModule, _, angular) {
    "use strict";

    /**
     * @ngdoc service
     * @name chartingModule.chartTemplateFactories
     * @description
     * Agrupa o mapeamento de tipo de gráfico -> configurações necessárias para a criação de um gráfico
     * por meio da infra de {@code nlgTemplate}.
     **/
    return chartingModule.factory("chartTemplateFactories", [function () {
        var axisOrientationMap = {
            "br.com.neolog.dashboard.chart.serie.AxisOrientation.LEFT": "left",
            "br.com.neolog.dashboard.chart.serie.AxisOrientation.RIGHT": "right"
        };

        var seriesTypeMap = {
            "br.com.neolog.dashboard.chart.serie.SerieType.BAR": "bar",
            "br.com.neolog.dashboard.chart.serie.SerieType.LINE": "line"
        };

        return {
            "br.com.neolog.dashboard.chart.ChartType.BAR": {
                getTemplateConfig: function (chartInfo) {
                    return {
                        type: "bar",
                        horizontal: chartInfo.chartViewConfig.properties.horizontal
                    };
                },
                getOptions: function (chartInfo) {
                    return {
                        configs: {
                            chartTitle: chartInfo.chartViewConfig.chartTitle,
                            isExportable: chartInfo.chartViewConfig.isExportable,
                            properties: chartInfo.chartViewConfig.properties || {},
                            translateLabels: chartInfo.chartViewConfig.properties.translateLabels,
                            orderBy: chartInfo.chartViewConfig.properties.orderBy,
                            type: "bar"
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.MAP": {
                getTemplateConfig: function () {
                    return {
                        type: "geo"
                    };
                },
                getOptions: function (chartInfoResult) {
                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            legendTitle: chartInfoResult.chartViewConfig.legendTitles.label,
                            type: "geo"
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.RANGE": {
                getTemplateConfig: function () {
                    return {
                        type: "rangeChart"
                    };
                },
                getOptions: function (chartInfoResult) {
                    var series = [], rangeAxis = chartInfoResult.chartViewConfig.groupingAxes[0];
                    series.push({
                        name: rangeAxis.alias,
                        role: "category"
                    });
                    chartInfoResult.chartViewConfig.series.forEach(function (eachSeries) {
                        series.push({
                            name: eachSeries.axis.alias,
                            role: "column",
                            type: seriesTypeMap[eachSeries.type],
                            yAxis: axisOrientationMap[eachSeries.axis.orientation]
                        });
                    });
                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            type: "range",
                            properties: {
                                unitOfMeasure: chartInfoResult.chartViewConfig.properties.unitOfMeasure,
                                rangeValue: parseInt(chartInfoResult.chartViewConfig.properties.rangeValue),
                                translateLabels: chartInfoResult.chartViewConfig.properties.translateLabels,
                                groupedChartYAxisLabel : chartInfoResult.chartViewConfig.properties.groupedChartYAxisLabel,
                                groupedChartY2AxisLabel : chartInfoResult.chartViewConfig.properties.groupedChartY2AxisLabel,
                                showOnlyIntegerOnY: chartInfoResult.chartViewConfig.properties.showOnlyIntegerOnY,
                                series: series
                            }
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.TIMESERIES": {
                getTemplateConfig: function () {
                    return {
                        type: "timeseriesChart"
                    };
                },
                getOptions: function (chartInfoResult) {
                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            translateLabels: chartInfoResult.chartViewConfig.properties.translateLabels,
                            type: "timeseries"
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.GROUP": {
                getTemplateConfig: function () {
                    return {
                        type: "chartGroup"
                    };
                },
                getOptions: function (chartInfoResult) {
                    var groupingAxes = _.filter(chartInfoResult.chartViewConfig.groupingAxes, {
                        axisType: "br.com.neolog.dashboard.chart.serie.AxisType.GROUPER"
                    });
                    var timeseriesAxis = _.find(chartInfoResult.chartViewConfig.groupingAxes, {
                        axisType: "br.com.neolog.dashboard.chart.serie.AxisType.TIMESERIES"
                    });

                    var rangeAxis = _.find(chartInfoResult.chartViewConfig.groupingAxes, {
                        axisType: "br.com.neolog.dashboard.chart.serie.AxisType.RANGE"
                    });


                    var series = [], rows = [];
                    groupingAxes.forEach(function (groupingAxis) {
                        rows.push(groupingAxis.alias);
                        series.push({
                            name: groupingAxis.alias,
                            role: "category"
                        });
                    });

                    if (timeseriesAxis) {
                        series.push({
                            name: timeseriesAxis.alias,
                            role: "timeseries"
                        });
                    }
                    if (rangeAxis) {
                        series.push({
                            name: rangeAxis.alias,
                            role: "range"
                        });
                    }
                    chartInfoResult.chartViewConfig.series.forEach(function (eachSeries) {
                        series.push({
                            name: eachSeries.axis.alias,
                            role: "column",
                            type: seriesTypeMap[eachSeries.type],
                            yAxis: axisOrientationMap[eachSeries.axis.orientation]
                        });
                    });

                    return {
                        groupings: {
                            rows: rows
                        },
                        chart: {
                            configs: {
                                chartViewConfig: chartInfoResult.chartViewConfig,
                                chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                                isExportable: chartInfoResult.chartViewConfig.isExportable,
                                chartType: chartInfoResult.chartViewConfig.properties.groupedChartType,
                                chartSourceId: chartInfoResult.chartViewConfig.chartSourceId,
                                properties: angular.extend({}, chartInfoResult.chartViewConfig.properties, {series: series}),
                                translateLabels: chartInfoResult.chartViewConfig.properties.translateLabels
                            },
                            data: []
                        }
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.PIE": {
                getTemplateConfig: function () {
                    return {
                        type: "pie"
                    };
                },
                getOptions: function (chartInfoResult) {
                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            type: "pie",
                            properties: chartInfoResult.chartViewConfig.properties || {},
                            colors: chartInfoResult.chartViewConfig.colors || {},
                            translateLabels: chartInfoResult.chartViewConfig.properties.translateLabels
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.STACKED_BAR": {
                getTemplateConfig: function (chartInfo) {
                    return {
                        type: "stackedBar",
                        absolute: chartInfo.chartViewConfig.properties.absolute
                    };
                },
                getOptions: function (chartInfoResult) {
                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            type: "stackedBar",
                            chartViewConfig: chartInfoResult.chartViewConfig,
                            properties: chartInfoResult.chartViewConfig.properties || {},
                            translateLabels: chartInfoResult.chartViewConfig.properties.translateLabels
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.HORIZONTAL_STACKED_BAR": {
                getTemplateConfig: function (chartInfo) {
                    return {
                        type: "horizontalStackedBar",
                        absolute: chartInfo.chartViewConfig.properties.absolute
                    };
                },
                getOptions: function (chartInfoResult) {
                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            type: "horizontalStackedBar",
                            chartViewConfig: chartInfoResult.chartViewConfig,
                            properties: chartInfoResult.chartViewConfig.properties || {},
                            translateLabels: chartInfoResult.chartViewConfig.properties.translateLabels
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.MULTI_BAR": {
                getTemplateConfig: function (chartInfo) {
                    return {
                        type: "multiBar",
                        absolute: chartInfo.chartViewConfig.properties.absolute
                    };
                },
                getOptions: function (chartInfoResult) {
                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            type: "multiBar",
                            chartViewConfig: chartInfoResult.chartViewConfig,
                            properties: chartInfoResult.chartViewConfig.properties || {},
                            translateLabels: chartInfoResult.chartViewConfig.properties.translateLabels
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.TABLE": {
                getTemplateConfig: function () {
                    return {
                        type: "table"
                    };
                },
                getOptions: function (chartInfoResult) {
                    var definitions = [];

                    angular.forEach(chartInfoResult.chartViewConfig.legendTitles, function(value, key) {
                        var newDefinition = {
                            header: value,
                            model: key,
                            format: getDefinitionFormat(key)
                        };
                        definitions.push(newDefinition);
                    });

                    function getDefinitionFormat(model){
                        var format = chartInfoResult.chartViewConfig.dataFormats[model];
                        if (!format) {
                            return "STRING";
                        }
                        return format;
                    }

                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            type: "table",
                            orientation: chartInfoResult.chartViewConfig.orientation,
                            definitions: definitions,
                            links: chartInfoResult.chartViewConfig.links
                        },
                        data: []

                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.SERIES": {
                getTemplateConfig: function () {
                    return {
                        type: "series"
                    };
                },
                getOptions: function (chartInfoResult) {
                    var definitions = [];

                    angular.forEach(chartInfoResult.chartViewConfig.legendTitles, function(value, key) {
                        var newDefinition = {
                            header: value,
                            model: key
                        };
                        definitions.push(newDefinition);
                    });

                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            type: "series",
                            definitions: definitions,
                            links: chartInfoResult.chartViewConfig.links,
                            properties: chartInfoResult.chartViewConfig.properties,
                            dataFormats: chartInfoResult.chartViewConfig.dataFormats,
                            chartIcon: chartInfoResult.chartViewConfig.chartIcon,
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.TREE_MAP": {
                getTemplateConfig: function () {
                    return {
                        type: "treemap"
                    };
                },
                getOptions: function (chartInfoResult) {
                    return {
                        configs: {
                            chartTitle: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            type: "treemap",
                            properties: chartInfoResult.chartViewConfig.properties
                        },
                        data: []
                    };
                }
            },
            "br.com.neolog.dashboard.chart.ChartType.SUMMARY": {
                getTemplateConfig: function () {
                    return {
                        type: "summary"
                    };
                },
                getOptions: function (chartInfoResult) {
                    return {
                        configs: {
                            chartTile: chartInfoResult.chartViewConfig.chartTitle,
                            isExportable: chartInfoResult.chartViewConfig.isExportable,
                            legendTitle: chartInfoResult.chartViewConfig.legendTitles,
                            type: "summary"
                        },
                        data: []
                    };
                }
            }
        };
    }]);
});
