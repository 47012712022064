define(["../nodeCommandsModule", "text!./nodeCommandAction.html"], function (nodeCommandsModule, nodeCommandAction) {
    "use strict";

    nodeCommandsModule.directive("nodeCommandAction", [function () {
        return {
            restrict: "E",
            template: nodeCommandAction,
            scope: {
                node: "=",
                property: "="
            },
            controller: ["$scope", "rootNodeCommandExecutorSupplier", "nodeIdentifiers", function ($scope, rootNodeCommandExecutorSupplier, nodeIdentifiers) {
                var rootNodeCommandExecutor = rootNodeCommandExecutorSupplier.getRoot();
                $scope.execute = function ($event) {
                    $event.stopPropagation();
                    var nodeCommand = rootNodeCommandExecutor.get($scope.property.command);
                    nodeCommand(nodeIdentifiers.toNodeIdentifiers([$scope.node]));
                };
            }]
        };
    }]);
});