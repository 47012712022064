define([
    "angular",
    "./chartingModule",
    "c3",
    "d3",
], function (angular, chartingModule, c3, d3) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:temperatureChart
     * @description
     * Diretiva que exibe um gráfico de temperaturas/data com ranges (areaLines)
     *
     * @param {object[]=} temperatureChart dados a serem representados no gráfico.
     * @param {string@?} width largura do gráfico em px.
     * @param {string@?} height altura do gráfico em px.
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div temperature-chart="temperatureChartData"></div>
     *         </div>
     *     </file>
     *     temperatureChart.data.temperatures = [temperature, date, door]
     *     temperatureChart.data.ranges = [lowerValue, upperValue]
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *
     *              $scope.barChartInput = (function () {
     *                  return {
     *                      configs: {
     *                          chartTitle: "The Chart Title"
     *                      },
     *                      data: {temperatures: [[10, new Date(2018, 10, 10), false],[20, new Date(2018, 10, 11), true], [30, new Date(2018, 10, 11), null]],
     *                      ranges: [[-50, -40, 'red'], [-40, -30, 'yellow'], [-30, -20, 'green'], [-20, -10, 'yellow'], [-10, 0, 'red']]}
     *                  }
     *              }());
     *         });
     *     </file>
     * </example>
     * */
    chartingModule.directive("temperatureChart", ["$translate", function ($translate) {
        return {
            restrict: "A",
            scope: {
                context: "=temperatureChart",
                width: "@?",
                height: "@?",
                reload: "=refreshChartControl"
            },
            template: "<div><h3 class='chart-title' translate></h3><div class='temperature-chart'></div></div>",
            link: function ($scope, $element) {
                $element.find("h3.chart-title").text($scope.context.configs.chartTitle);
                $scope.height = parseInt($scope.height) || 720;

                var ticks = 8;
                var marginXFactor = 1.02;
                var initialDomain = 0;
                var doorToColor = {null: "black", false: "blue", true: "red"};

                var chartConfig = {
                    zoom: {
                        enabled: true,
                        rescale: true,
                        onzoom: adjustTicks
                    },
                    bindto: $element.find(".temperature-chart")[0],
                    size: {
                        height: $scope.height
                    },
                    point: {
                        r: function (data) {
                            if (data.id.indexOf("range") > -1) {
                                return 0;
                            }
                            return 3;
                        }
                    },
                    data: {
                        empty: {
                            label: {
                                text: $translate.instant("charting.notApplicable")
                            }
                        },
                        order: onlyOrderTemperatureNotRanges,
                        x: "date",
                        columns: [],
                        colors: {
                            temperature: "black",
                            temperatureOpen: "red",
                            temperatureClosed: "blue"
                        },
                        types: {
                            temperature: "line",
                            temperatureOpen: "line",
                            temperatureClosed: "line",
                            range1: "area",
                            range2: "area",
                            range3: "area",
                            range4: "area",
                            range5: "area",
                            range6: "area"
                        },
                        names: {
                            temperature: $translate.instant("monitoring.monitorable.charting.line.temperature"),
                            temperatureOpen: $translate.instant("monitoring.monitorable.charting.line.temperature.open.door"),
                            temperatureClosed: $translate.instant("monitoring.monitorable.charting.line.temperature.close.door")
                        },
                        groups: [
                            ["range1", "range2", "range3", "range4", "range5", "range6"]
                        ]
                    },
                    axis: {
                        x: {
                            tick: {
                                culling: false,
                                multiline: false,
                                count: defineTickQuantity,
                                format: formatXAxis
                            }
                        },
                        y: {
                            tick: {
                                format: formatYAxis
                            }
                        }
                    },
                    legend: {
                        show: true,
                    },
                    tooltip: {
                        format: {
                            title: formatTooltip,
                            value: onlyShowForTemperature
                        }
                    }
                };

                function defineTickQuantity() {
                    return ticks;
                }

                function formatXAxis(timestamp) {
                    var date = new Date(timestamp);
                    return d3.time.format("%dd %Hh")(date);
                }

                function formatYAxis(temperature) {
                    return temperature + "º";
                }

                function formatTooltip(timestamp) {
                    var date = new Date(timestamp);
                    return "Date " + d3.time.format("%Hh %Mm")(date);
                }

                function adjustTicks(domain) {
                    var zoomDomain = (domain[1] - domain[0]) / marginXFactor;
                    var zoomRatio = initialDomain / zoomDomain;
                    ticks = 8 * zoomRatio;
                }

                function onlyOrderTemperatureNotRanges(data1, data2) {
                    if (data1.id.indexOf("range") > -1) {
                        return false;
                    }
                    return data1 < data2;
                }

                function onlyShowForTemperature(value, ratio, id) {
                    if (id.indexOf("range") > -1) {
                        return;
                    }
                    return value + "º";
                }

                function havePositiveAndNegativeSide(range) {
                    var result = 0;
                    [0, 1].forEach(function (value) {
                        if (range[value] < 0) {
                            result--;
                        }
                        if (range[value] > 0) {
                            result++;
                        }
                    });
                    return result === 0;
                }

                function generateRangePoints(name, range, quantity) {
                    var rangePoints = [name];
                    var size = range[1] - range[0];
                    if (range[0] < 0) {
                        size = range[0] - range[1];
                    }
                    for (var index = 0; index < quantity; index++) {
                        rangePoints.push(size);
                    }
                    return rangePoints;
                }

                // from 0 to negative then from 0 to positive
                function orderAreaToPlot(data1, data2) {
                    if (data1[0] < 0 && data2[0] < 0) {
                        if (data1[0] >= data2[0]) {
                            return -1;
                        }
                        return 1;
                    }
                    if (data1[0] <= data2[0]) {
                        return -1;
                    }
                    return 1;
                }

                function adjustRedRangeBoundaries(ranges, maxSignalValue, minSignalValue) {
                    var redRanges = ranges.filter(function (range) {
                        return range[2] === "red";
                    });

                    var inferiorRedRange = redRanges[0];
                    var superiorRedRange = redRanges[1];

                    if (redRanges[0] < redRanges[1] ) {
                        inferiorRedRange = redRanges[0];
                        superiorRedRange = redRanges[1];
                    }


                    var lineRangeHeigth = 15;
                    inferiorRedRange[0] = Math.min(inferiorRedRange[1] - lineRangeHeigth, minSignalValue);
                    superiorRedRange[1] = Math.max(superiorRedRange[0] + lineRangeHeigth, maxSignalValue);
                }

                function addRanges(ranges, signalQuantity, data) {
                    adjustRedRangeBoundaries(ranges, data.max, data.min);

                    var indexOfRangeToBeDuplicated = ranges.findIndex(function (range) {
                        return havePositiveAndNegativeSide(range);
                    });

                    if (indexOfRangeToBeDuplicated >= 0) {
                        var findedRange = ranges[indexOfRangeToBeDuplicated];
                        ranges.splice(indexOfRangeToBeDuplicated, 1, [findedRange[0], 0, findedRange[2]], [0, findedRange[1], findedRange[2]]);
                    }

                    var orderedRanges = ranges.sort(orderAreaToPlot);
                    orderedRanges.forEach(function (range, index) {
                        var name = "range" + (index + 1);
                        data.colors[name] = range[2];
                        data.columns.push(generateRangePoints(name, range, signalQuantity));
                    });
                }

                function processTemperatures(data) {
                    var preparedData = {
                        columns: [],
                        colors: chartConfig.data.colors,
                        max: null,
                        min: null
                    };

                    var temperatures = ["temperature"];
                    var temperaturesOpen = ["temperatureOpen"];
                    var temperaturesClosed = ["temperatureClosed"];
                    var temperatureArrays = [temperatures, temperaturesOpen, temperaturesClosed];
                    var dates = [];
                    if (data && data.temperatures) {
                        var temperatureDatas = data.temperatures.reverse();
                        var lastDoor = null;

                        temperatureDatas.forEach(function (temperatureData) {
                            if (isValidLinePoint(temperatureData[0])) {
                                if (temperatureData[2] !== lastDoor) {
                                    putSegment(temperatureData, lastDoor, temperatureArrays, dates);
                                }
                                putSegment(temperatureData, temperatureData[2], temperatureArrays, dates);
                                lastDoor = temperatureData[2];
                            }
                        });
                    }

                    preparedData.range = [d3.min(dates), d3.max(dates)];
                    dates.splice(0, 0, "date");
                    preparedData.columns.push(dates);

                    temperatureArrays.forEach(function (temperatureArray) {
                        preparedData.columns.push(temperatureArray);
                    });

                    var temperatureValues = data.temperatures.map(function (temperature) {
                        return temperature[0];
                    });

                    preparedData.max = Math.max.apply(null, temperatureValues);
                    preparedData.min = Math.min.apply(null, temperatureValues);

                    return preparedData;
                }

                function isValidLinePoint(lineSegment) {
                    return lineSegment !== null;
                }

                function putSegment(temperatureDate, door, temperatureArrays, dates) {
                    var color = doorToColor[door];

                    temperatureArrays.forEach(function (temperatureArray) {
                        if (chartConfig.data.colors[temperatureArray[0]] === color) {
                            temperatureArray.push(temperatureDate[0]);
                        } else {
                            temperatureArray.push(null);
                        }
                    });
                    dates.push(temperatureDate[1]);
                }

                function hideRangeLegends(chart) {
                    for (var i = 1; i < 7; i++) {
                        chart.legend.hide("range" + i);
                    }
                }

                var preparedData = null;
                $scope.$watch(function () {
                    return $scope.reload;
                }, function () {
                    var rawData = $scope.context.data;
                    preparedData = processTemperatures(rawData);
                    var signalQuantity = preparedData.columns[0].length;

                    addRanges(rawData.ranges || [], signalQuantity, preparedData);
                    var chart = c3.generate(chartConfig);
                    chart.load(preparedData);

                    initialDomain = (preparedData.range[1] - preparedData.range[0]) * marginXFactor;
                    chart.zoom(preparedData.range);
                    hideRangeLegends(chart);
                });

            }
        };
    }]);
});
