/**
 * @ngdoc service
 * @name objects.objects
 * @description
 *
 * Métodos utilitários para manipular objects.
 */
define([], function () {
    "use strict";

    return {
        lazyGet: lazyGet,
        enrich: enrich,
        compare: compare,
        values: values,
        getEnumName: getEnumName,
        isUndefined: isUndefined
    };

    /**
     * @ngdoc method
     * @name objects.objects#lazyGet
     * @methodOf objects.objects
     * @description
     *
     * Retorna uma {Function} que, quando invocada, retorna a propriedade especificada do objeto fornecido.
     * Tipicamente, o uso desta função está relacionado com a obtenção de uma referência para uma propriedade de um
     * objeto, isto é, a invocação da função retornada produz o valor atual da propriedade.
     *
     * @param {object} object objeto cuja propriedade será extraída
     * @param {string} property propriedade do objeto a ser retornada
     * @returns {function} Função que retorna o valor da propriedade do objeto
     * */
    function lazyGet(object, property) {
        return function () {
            return object[property];
        };
    }

    /**
     * @ngdoc method
     * @name objects.objects#enrich
     * @methodOf objects.objects
     * @description
     *
     * Atribui propriedades ao `object` especificado cujas chaves inexistem ou estejam explicitamente
     * mapeadas ao valor `undefined`. Se `object` for `null` ou se não for `instanceof Object` um erro será lançado.
     *
     * @param {Object} objeto a ser enriquecido
     * @param {Object} additionalProperties objeto a ser usado como mapa de propriedades a serem atribuídas
     * @returns {object} o próprio `object` com as propriedades adicionais que não estavam definidas
     * */
    function enrich(object, additionalProperties) {
        if (!isObject(object)) {
            throw new Error(object + " is null or it is not an instance of Object.");
        }
        var hasOwnProperty = Object.prototype.hasOwnProperty;
        for (var additionalProperty in additionalProperties) {
            if (hasOwnProperty.call(additionalProperties, additionalProperty) && isUndefined(object[additionalProperty])) {
                object[additionalProperty] = additionalProperties[additionalProperty];
            }
        }
        return object;
    }

    /**
     * @ngdoc method
     * @name objects.objects#compare
     * @methodOf objects.objects
     * @description
     *
     * Realiza a comparação natural de dois valores especificados, retornando valor negativo caso o primeiro elemento seja menor,
     * valor positivo caso o primeiro elemento seja maior e `zero` caso contrário.
     *
     * @param {number|string} o1 primeiro objeto
     * @param {number|string} o2 segundo object
     * @returns {number} Número positivo, caso o1 seja maior que o2,
     * negativo caso o1 seja menor que o2 e 0 se ambos forem iguais.
     */
    function compare(o1, o2) {
        if (o1 < o2) {
            return -1;
        }
        if (o1 > o2) {
            return 1;
        }
        return 0;
    }

    /**
     * @ngdoc method
     * @name objects.objects#values
     * @methodOf objects.objects
     * @description
     *
     * Retorna um array com os valores de todas as propriedades do objeto.
     * A ordem do array não é garantida.
     *
     * @param {object} object Objeto cujos valores serão extraídos.
     * @returns {object[]} Array com os valores do objeto.
     * */
    function values(object) {
        var values = [];
        Object.keys(object).forEach(function(key){
            values.push(object[key]);
        });
        return values;
    }

    /**
     * Extrai o name de um enum dado seu nome completamente qualificado.
     *
     * @param {string} fullyQualifiedEnumName nome completamente qualificado de uma instância de enum
     * @returns {string} name da representação em string da instância do enum passada
     */
    function getEnumName(fullyQualifiedEnumName) {
        return fullyQualifiedEnumName.replace(/^.*\.(\w+)$/, "$1");
    }

    function isObject(object){
        return object !== null && typeof object === "object";
    }

    function isUndefined(object){
        return object === void 0;
    }
});