define(["./threeModule", "three", "./threeAxleMeshFactory"], function (threeModule, THREE) {
    "use strict";

    var DEGRES_90 = Math.PI / 2;

    return threeModule.service("threeVehicleWithNeckMeshFactory", ["Object3DInfo", "threeAxleMeshFactory", "$window", function (Object3DInfo, threeAxleMeshFactory, $window) {
        var self = this;

        self.createMesh = function (object) {
            var neckDimensions = object.extraInfo[Object3DInfo.NECK_DIMENSIONS];
            if(!neckDimensions.length){
                throw new Error("Expected a container with neck.");
            }

            var width = object.dimensions.width;
            var height = object.dimensions.height;
            var depth = object.dimensions.length;

            var vehicle = createContainer3DWithNeck();
            addFloor(vehicle);
            addSupport(vehicle, object);
            threeAxleMeshFactory.drawAxles(vehicle, object);

            return vehicle;

            function createContainer3DWithNeck(){
                var vehicle = new THREE.Object3D();
                vehicle.rotation.y = DEGRES_90;
                // para exibir os contornos
                vehicle.geometry =  new THREE.BufferGeometry();

                var neckLength = neckDimensions.length;
                var neckHeight = neckDimensions.height;
                var halfDepth = depth/2;
                var halfWidth = width/2;
                var halfHeight = height/2;

                // Vide NEO-28556
                var A = [-halfWidth, -halfHeight + neckHeight , -halfDepth],
                    B = [-halfWidth, -halfHeight + neckHeight , neckLength - halfDepth],
                    C = [-halfWidth, -halfHeight              , neckLength - halfDepth],
                    D = [-halfWidth, -halfHeight              , halfDepth],
                    E = [-halfWidth,  halfHeight              , halfDepth],
                    F = [-halfWidth,  halfHeight              , -halfDepth],
                    G = [ halfWidth, -halfHeight + neckHeight , -halfDepth],
                    H = [ halfWidth, -halfHeight + neckHeight , neckLength - halfDepth],
                    I = [ halfWidth, -halfHeight              , neckLength - halfDepth],
                    J = [ halfWidth, -halfHeight              , halfDepth],
                    K = [ halfWidth,  halfHeight              , halfDepth],
                    L = [ halfWidth,  halfHeight              , -halfDepth];

                var vertices = new $window.Float32Array( Array.prototype.concat.call(
                    // Face 1
                    A,B,H,H,G,A,
                    // Face 2
                    B,C,I,I,H,B,
                    // Face 3
                    C,D,J,J,I,C,
                    // Face 4
                    D,J,K,K,E,D,
                    // Face 5
                    E,K,L,L,F,E,
                    // Face 6
                    A,G,L,L,F,A
                 ) );
                vehicle.geometry.addAttribute( "position", new THREE.BufferAttribute( vertices, 3 ) );
                return vehicle;
            }

            function addFloor(vehicle) {
                var neckLength = neckDimensions.length;

                var material = new THREE.MeshLambertMaterial({
                    color: 0x603311,
                    side: THREE.DoubleSide
                });
                material.transparent = true;
                material.depthTest = true;
                material.depthWrite = false;
                material.polygonOffset = true;
                material.polygonOffsetFactor= -4;

                var mainFloor = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, depth-neckLength), material);
                vehicle.add(mainFloor);
                mainFloor.position.y = -height/2;
                mainFloor.rotation.x = DEGRES_90;
                mainFloor.position.z += neckLength/2;

                var neckFloor = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, neckLength), material);
                var neckHeight = neckDimensions.height;
                vehicle.add(neckFloor);
                neckFloor.position.y = neckHeight - height/2;
                neckFloor.position.z = neckLength/2 - depth/2;
                neckFloor.rotation.x = DEGRES_90;

                var neckHeightFloor = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, neckHeight), material);
                vehicle.add(neckHeightFloor);
                neckHeightFloor.position.z = neckLength - depth/2;
                neckHeightFloor.position.y = neckHeight/2 -height/2;
            }

        };

        function addSupport(vehicle, object) {
            var width = object.dimensions.width;
            var height = object.dimensions.height;
            var depth = object.dimensions.length;
            var rearSupportHeight = object.extraInfo[Object3DInfo.REAR_SUPPORT_HEIGHT];
            var frontalSupportHeight = object.extraInfo[Object3DInfo.FRONT_SUPPORT_HEIGHT];
            var sideSupportHeight = object.extraInfo[Object3DInfo.SIDE_SUPPORT_HEIGHT];

            var supportMaterial = new THREE.MeshBasicMaterial({
                color: 0x603311,
                opacity: 0.5,
                transparent: true,
                side: THREE.DoubleSide
            });

            var neckDimensions = object.extraInfo[Object3DInfo.NECK_DIMENSIONS];
            var neckHeight = neckDimensions.height;

            var rearSupport = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, rearSupportHeight), supportMaterial);
            vehicle.add(rearSupport);
            rearSupport.position.z = depth / 2;
            rearSupport.position.y = (rearSupportHeight - height) / 2;

            var neckFrontSupportHeight = frontalSupportHeight - neckHeight;
            if(neckFrontSupportHeight > 0) {
                var frontSupport = new THREE.Mesh(new THREE.PlaneBufferGeometry(width, neckFrontSupportHeight), supportMaterial);
                vehicle.add(frontSupport);
                frontSupport.position.z = -depth / 2;
                frontSupport.position.y = (neckFrontSupportHeight - height) / 2 + neckHeight;
            }

            var neckLength = neckDimensions.length;
            var leftSideSupport = new THREE.Mesh(new THREE.PlaneBufferGeometry(depth - neckLength, sideSupportHeight), supportMaterial);
            vehicle.add(leftSideSupport);
            leftSideSupport.rotation.y = DEGRES_90;
            leftSideSupport.position.x = width / 2;
            leftSideSupport.position.z += neckLength/2;
            leftSideSupport.position.y = (sideSupportHeight - height) / 2;

            var rightSideSupport = leftSideSupport.clone();
            vehicle.add(rightSideSupport);
            rightSideSupport.position.x = -width / 2;

            var neckLateralSupportHeight = sideSupportHeight - neckHeight;
            if(neckLateralSupportHeight > 0){
                var neckLeftSideSupport = new THREE.Mesh(new THREE.PlaneBufferGeometry(neckLength, neckLateralSupportHeight), supportMaterial);
                vehicle.add(neckLeftSideSupport);
                neckLeftSideSupport.rotation.y = DEGRES_90;
                neckLeftSideSupport.position.x = width / 2;
                neckLeftSideSupport.position.z = (neckLength - depth)/2;
                neckLeftSideSupport.position.y = (neckLateralSupportHeight - height) / 2 + neckHeight;

                var neckRightSideSupport = neckLeftSideSupport.clone();
                vehicle.add(neckRightSideSupport);
                neckRightSideSupport.position.x = -width / 2;
            }
        }

        return self;
    }]);
});