define(["../nodeCommandsModule", "text!./nodePinAction.html"], function (nodeCommandsModule, nodePinActionTemplate) {
    "use strict";

    nodeCommandsModule.directive("nodePinAction", [function () {
        return {
            restrict: "E",
            template: nodePinActionTemplate,
            scope: {
                node: "=",
                property: "="
            },
            controller: ["$scope", "rootNodeCommandExecutorSupplier", function ($scope, rootNodeCommandExecutorSupplier) {
                var rootNodeCommandExecutor = rootNodeCommandExecutorSupplier.getRoot();
                $scope.execute = function ($event) {
                    $event.stopPropagation();
                    var nodeCommand = rootNodeCommandExecutor.get($scope.property.command);
                    var context = {
                        lock: !$scope.property.value
                    };
                    nodeCommand($scope.node, context)
                        .then(function () {
                            $scope.property.value = !$scope.property.value;
                        });
                };
            }]
        };
    }]);
});