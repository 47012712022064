import sessionRecordsModule from "./sessionRecordsModule"
import sessionRecordsView from "./sessionRecordsView.html"

sessionRecordsModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.log", {
        data: {
            pageTitle: "{{'title.r2.log' | translate}}"
        },
        url: "/integration/session-records",
        template: sessionRecordsView,
        controller: "sessionRecordsController"
    })
}])

