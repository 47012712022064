define(["require", "../nlgOperationOverviewModule"], function (require, nlgOperationOverviewModule) {
    "use strict";

    return nlgOperationOverviewModule.directive("nlgOperationOverviewInfo", [function () {
        return {
            templateUrl: require("link!./nlgOperationOverviewInfo.html"),
            scope: {
                instance: "<?",
                info: "<"
            },
            controller: ["$scope", "nlgHealthCheckerDetailsModal", "nlgHealthCheckerConfigurationModal",
                function ($scope, nlgHealthCheckerDetailsModal, nlgHealthCheckerConfigurationModal) {
                    $scope.getStatusClass = function (status) {
                        if (status === "br.com.neolog.healthcheck.api.HealthCheckerStatus.OK") {
                            return "bg-success";
                        } else if (status === "br.com.neolog.healthcheck.api.HealthCheckerStatus.WARNING") {
                            return "bg-warning";
                        } else {
                            return "bg-danger";
                        }
                    };

                    $scope.openModal = function (checker) {
                        return nlgHealthCheckerDetailsModal($scope.instance, checker);
                    };

                    $scope.openConfigurationModal = function ($ev, checker) {
                        $ev.stopPropagation();
                        return nlgHealthCheckerConfigurationModal($scope.instance, checker);
                    };
                }]
        };
    }]);
});
