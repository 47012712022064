define([
    "./kpiConfigurationsModule",
    "text!./kpiConfigurations.html"
], function (module, kpiConfigurationsTemplate) {
    "use strict";
    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.kpiConfigurations", {
                url: "/kpi-configurations",
                controller: "KPIConfigurationsController",
                template: kpiConfigurationsTemplate,
                data: {
                    group: "group.r2.configuration.kpi.configuration",
                    pageTitle: "{{'title.monitoring.kpiConfigurations' | translate:$stateParams}}"
                },
                resolve: {
                    kpiConfigurations: ["monitoringService", function (monitoringService) {
                        return monitoringService.getKpiConfigurations();
                    }]
                }
            })
    }]);
});
