import messageConfigParamsModule from "./messageConfigParamsModule"
import messageConfigParamsView from "./messageConfigParamsView.html"

messageConfigParamsModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.message-config-params", {
        data: {
            pageTitle: "{{'title.r2.emailConfig' | translate}}"
        },
        url: "/message-config-params",
        template: messageConfigParamsView,
        controller: "messageConfigParamsController"
    })
}])