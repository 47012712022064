define([
    "./macroModule"
], function (module) {
    "use strict";

    return module.controller("macroControllerEdition", [
        "$scope",
        "$state",
        "macroService",
        "loadingService",
        "nlgTagInputIconMap",
        "remoteExceptionHandler",
        "monitoringService",
        "trackingService",
        "macro",
        function ($scope, $state, macroService, loadingService, nlgTagInputIconMap, remoteExceptionHandler, monitoringService, trackingService, macro) {

            $scope.entity = macro;

            $scope.back = function () {
                return $state.go("monitoring.macroSearch", {
                    reload: true
                });
            };

            $scope.save = function () {
                return macroService.saveMacro($scope.entity)
                    .then(function (id) {
                        return $state.go("monitoring.macroEdition", {
                            macroId: id
                        });
                    });
            };

            $scope.saveAndContinue = function () {
                return macroService.saveMacro($scope.entity)
                    .then(function () {
                        return $state.go($state.current, {reload: Date.now()});
                    });
            };

            $scope.getMacroFunctions = function () {
                return monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.MacroFunction")
                    .then(function (values) {
                        return values;
                    });
            };

            $scope.providers = function () {
                return trackingService.listProviders()
                    .catch(remoteExceptionHandler())
                    .then(function (providers) {
                        return _.map(providers, "id");
                    });
            };
        }
    ]);
});
