define(["../trackingMobileModule",
        "json!../trackingMobile.json"],
    function (trackingMobileModule, trackingMobile) {
        "use strict";

        trackingMobileModule.service("trackingMobileService", [
            "$http",
            "$location",
            "$translate",
            function ($http, $location, $translate) {
                return {
                    sendConfigurationMessage: sendConfigurationMessage,
                    sendConfigurationMessageByDriver: sendConfigurationMessageByDriver,
                    getQRCodeConfigurationUrl: getQRCodeConfigurationUrl,
                    filterDrivers: filterDrivers,
                    filterDevices: filterDevices,
                    driversMapService: driversMapService,
                    filterDriversMap: filterDriversMap,
                    saveOrUpdateDriver: saveOrUpdateDriver,
                    saveOrUpdateLegacyDriver: saveOrUpdateLegacyDriver,
                    saveOrUpdateDevice: saveOrUpdateDevice,
                    findDriverById: findDriverById,
                    findDeviceById: findDeviceById,
                    findDeviceByPhoneNumber: findDeviceByPhoneNumber,
                    findDriverByIdentifier: findDriverByIdentifier,
                    findNotInTripDrivers: findNotInTripDrivers,
                    findDrivers: findDrivers,
                    countNotInTripDrivers: countNotInTripDrivers,
                    deleteDriver: deleteDriver,
                    deleteLegacyDriver: deleteLegacyDriver,
                    deleteDevice: deleteDevice,
                    assignDriverToTrip: assignDriverToTrip,
                    assignTripToAccept: assignTripToAccept,
                    assignDriverIdentifierToTrip: assignDriverIdentifierToTrip,
                    validateDriverDevice: validateDriverDevice,
                    assignDriverToInvoice: assignDriverToInvoice,
                    getDriverOfTrip: getDriverOfTrip,
                    tripHasDriver: tripHasDriver,
                    invoiceHasDriver: invoiceHasDriver,
                    sendTripCancelNotification: sendTripCancelNotification,
                    sendInvoiceCancelNotification: sendInvoiceCancelNotification,
                    findDeviceByTripId: findDeviceByTripId,
                    isTripOnQueue: isTripOnQueue,
                    findTripDriverAssociationTripCodeByDriverId: findTripDriverAssociationTripCodeByDriverId,
                    assignDriverToDocument: assignDriverToDocument,
                    driverByDocument: driverByDocument,
                    sendDocumentCancelNotification: sendDocumentCancelNotification,
                    findTruckDeviceByDriver: findTruckDeviceByDriver,
                    isDriverMandatory: isDriverMandatory,
                    getMobileApplicationSetting: getMobileApplicationSetting,
                    saveMobileApplicationSettings: saveMobileApplicationSettings
                };

                function sendConfigurationMessage(device) {
                    var deviceId = device.deviceId;
                    var messageBody = $translate.instant("tracking.mobile.config.sms.message");
                    return $http.post(servicePath("configurationSms") + deviceId, messageBody).then(getData);
                }

                function sendConfigurationMessageByDriver(driver, language) {
                    var deviceId = device.id;
                    var number = phoneNumber.number;
                    var currentSystemLanguage = $translate.use();
                    $translate.use(language);
                    var messageBody = $translate.instant("tracking.mobile.config.sms.message");
                    $translate.use(currentSystemLanguage);
                    return $http.post(servicePath("config"), {
                        phoneNumber: number,
                        messageBody: messageBody
                    }, {
                        params: {
                            driver: driver
                        }
                    }).then(getData);
                }

                function getQRCodeConfigurationUrl(device) {
                    return $http({
                        url: servicePath("configurationQRCode") + device.deviceId,
                        method: "GET"
                    })
                        .then(getData)
                        .then(function (result) {
                            return result.externalUri;
                        });
                }

                function filterDrivers(template, firstResult, maxResults) {
                    return $http.post(servicePath("driver") + "filter", template, {
                        params: {
                            firstResult: firstResult,
                            maxResults: maxResults
                        }
                    }).then(getData);
                }

                function filterDevices(template, firstResult, maxResults) {
                    return $http.post(servicePath("device") + "filter", template, {
                        params: {
                            firstResult: firstResult,
                            maxResults: maxResults
                        }
                    }).then(getData);
                }

                function driversMapService() {
                    return trackingMobile.services.driverMap;
                }

                function filterDriversMap(template, firstResult, maxResults) {
                    return $http.post(servicePath("driverMap") + "filter", template, {
                        params: {
                            firstResult: firstResult,
                            maxResults: maxResults
                        }
                    }).then(getData);
                }

                function findDriverById(driverId) {
                    return $http.get(servicePath("driver") + driverId).then(getData);
                }

                function findDeviceById(deviceId) {
                    return $http.get(servicePath("device") + deviceId).then(getData);
                }

                function findDeviceByPhoneNumber(phoneNumber) {
                    return $http.get(servicePath("device"), {params: {phoneNumber: phoneNumber}}).then(getData);
                }

                function findDriverByIdentifier(identifier) {
                    return $http.get(servicePath("driverByIdentifier"), {params: {identifier: identifier}}).then(getData);
                }

                function findNotInTripDrivers(name) {
                    return $http.post(servicePath("availableDrivers"), name || "%%%").then(getData);
                }

                function findDrivers(name) {
                    return $http.post(servicePath("findDrivers"), name || "%%%").then(getData);
                }

                function countNotInTripDrivers() {
                    return $http.get(servicePath("countAvailableDrivers")).then(getData);
                }

                function deleteDriver(identifier) {
                    return $http.delete(servicePath("driver") + "/" + identifier).then(getData);
                }

                function deleteLegacyDriver(identifier) {
                    return $http.delete(servicePath("legacyDriver"), {
                        params: {
                            identifier: identifier
                        }
                    }).then(getData);
                }

                function deleteDevice(identifier) {
                    return $http.delete(servicePath("device"), {
                        params: {
                            identifier: identifier
                        }
                    }).then(getData);
                }

                function saveOrUpdateDriver(driver) {
                    return $http.post(servicePath("driver"), driver).then(getData);
                }

                function saveOrUpdateLegacyDriver(driver) {
                    return $http.post(servicePath("legacyDriver"), driver).then(getData);
                }

                function saveOrUpdateDevice(device) {
                    return $http.post(servicePath("device"), device).then(getData);
                }

                function findDeviceByTripId(tripId) {
                    return $http.get(servicePath("deviceId") + tripId).then(getData);
                }

                function servicePath(serviceId) {
                    return trackingMobile.baseUrl + trackingMobile.services[serviceId];
                }

                function assignDriverToTrip(tripId, driver, params) {
                    return $http({
                        url: servicePath("assignDriverToTrip") + tripId,
                        method: "POST",
                        data: driver,
                        params: params || {}
                    }).then(getData);
                }

                function assignTripToAccept(tripId, driver) {
                    return $http({
                        url: servicePath("assignTripToAccept") + tripId,
                        method: "POST",
                        data: driver
                    }).then(getData);
                }

                function assignDriverIdentifierToTrip(tripId, identifier) {
                    return $http.post(servicePath("assignDriverIdentifierToTrip") + tripId, identifier).then(getData);
                }

                function validateDriverDevice(driver) {
                    return $http.post(servicePath("validateDriverDevice"), driver).then(getData);
                }

                function assignDriverToInvoice(invoiceDriverAssociation) {
                    return $http.post(servicePath("assignDriverToInvoice"), invoiceDriverAssociation).then(getData);
                }

                function tripHasDriver(tripId) {
                    return $http.get(servicePath("tripHasDriver") + tripId).then(getData);
                }

                function getDriverOfTrip(tripId) {
                    return $http.get(servicePath("getDriverOfTrip") + tripId).then(getData);
                }

                function invoiceHasDriver(invoiceId) {
                    return $http.get(servicePath("invoiceHasDriver") + invoiceId).then(getData);
                }

                function sendTripCancelNotification(tripId) {
                    return $http.post(servicePath("tripCancelNotification") + tripId, {});
                }

                function isTripOnQueue(tripId) {
                    return $http.get(servicePath("isTripOnQueue") + tripId)
                        .then(getData);
                }

                function sendInvoiceCancelNotification(invoiceId) {
                    return $http.post(servicePath("invoiceCancelNotification") + invoiceId, {});
                }

                function findTripDriverAssociationTripCodeByDriverId(driverId) {
                    return $http.get(servicePath("tripDriverAssociationTripCode") + "/" + driverId).then(getData);
                }

                function assignDriverToDocument(documentId, driverId) {
                    return $http.post(servicePath("assignDriverToDocument") + documentId, driverId).then(getData);
                }

                function driverByDocument(documentId) {
                    return $http.get(servicePath("driverByDocument") + documentId).then(getData);
                }

                function getMobileApplicationSetting() {
                    return $http.get(servicePath("mobileApplicationSettings")).then(getData);
                }

                function isDriverMandatory() {
                    return $http.get(servicePath("isDriverMandatory")).then(getData);
                }

                function sendDocumentCancelNotification(documentid) {
                    return $http.post(servicePath("documentCancelNotification") + documentid, {});
                }

                function findTruckDeviceByDriver(driver) {
                    return $http.post(servicePath("findTruckDeviceByDriver"), driver).then(getData);
                }

                function getData(response) {
                    return response.data;
                }

                function saveMobileApplicationSettings(mobileSettings) {
                    return $http.post(servicePath("mobileApplicationSettings"), mobileSettings);
                }
            }
        ]);
    });