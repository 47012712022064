define([
    "./nlgAnalysisTreeDirective",
    "text!./nlgAnalysisTreeSelectedSummaryProperty.html"
], function (nlgAnalysisTree, template) {
    "use strict";

    return nlgAnalysisTree.directive("nlgAnalysisTreeSelectedSummaryProperty", [function () {
        return {
            restrict: "A",
            template: template,
            scope: {
                node: "=",
                property: "="
            },
            controller: ["$scope", "numberFilter", function ($scope, numberFilter) {
                $scope.propertyValue = numberFilter($scope.property.value);
            }]
        };
    }]);
});