define(["./numberModule"], function (numberModule) {
    "use strict";

    return numberModule.filter("decimal", ["$filter", function ($filter) {
        var numberFilter = $filter("number");
        return function (value, decimals) {
            if (!value || isNaN(value)) {
                return "0";
            }
            return numberFilter(value, decimals);
        };
    }]);
});