define([
    "./monitoringLGPDModule",
    "json!../monitoring.json",
], function (monitoringLGPDModule, monitoringServer) {
    "use strict";
    monitoringLGPDModule.service("monitoringLgpdApi", function ($http) {
            var self = this;

            self.obfuscateAllNames = function () {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.obfuscate.allNames)
                    .then(function (response) {
                        return response;
                    })
            };

            self.obfuscateEntitiesByName = function (args) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.obfuscate.entitiesByName, args)
                    .then(function (response) {
                        return response;
                    })
            };

            self.obfuscateFind = function (args, params) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.obfuscate.find, args, params)
                    .then(function (response) {
                        return response;
                    })
            };

            self.obfuscateSave = function (args) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.obfuscate.save, args)
                    .then(function (response) {
                        return response;
                    })
            };

            self.logsAllNames = function () {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.logs.allNames)
                    .then(function (response) {
                        return response;
                    });
            };

            self.logsEntitiesByName = function (args) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.logs.entitiesByName, args)
                    .then(function (response) {
                        return response;
                    });
            };

            self.logsFind = function (args, params) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.logs.find, args, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.logExportDefinitions = function () {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.logs.exportDefinitions)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlGetDefinitionById = function (id, serviceName) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.edition + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.scriptSQLGetBySourceId = function (param) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.scriptSQL, param)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlCopyById = function (id) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.copy + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlGetEntities = function () {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.edition)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlGetDefinitions = function () {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.all)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlFilterRecords = function (template, params) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.filter, template, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlSave = function (definition) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.edition, definition)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlDeleteById = function (id) {
                return $http.delete(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.edition + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlFindVersionOf = function (id) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.versions + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.sqlFindVersion = function (id, version) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.sql.edition + "/" + id + "/version/" + version)
                    .then(function (response) {
                        return response;
                    });
            };


            self.entityGetAllOwners = function () {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.edition)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entitySave = function (definition) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.edition, definition)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityGetByOwner = function (owner) {
                const params = {params: {entityName: owner.entityName}};
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.byOwner, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityFilter = function (template, params) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.filter, template, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityGetDefinitionById = function (id) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.edition + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityDelete = function (id) {
                return $http.delete(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.edition + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityCopyGetDefinitionById = function (id) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.copy + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityLogVersionOf = function (id) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.versions + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityFindVersion = function (id, version) {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.edition + "/" + id + "/version/" + version)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityGetDefinitions = function () {
                return $http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.all)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeGetAllDefinitionsNames = function () {
                return [$http.get(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.allNames).then(function (response) {
                    return {
                        key: {
                            artifactName: "monitoring",
                            path: "",
                            name: "entity"
                        },
                        value: response.data
                    };
                }).catch(function () {
                    return {
                        key: {
                            artifactName: "",
                            path: "",
                            name: ""
                        },
                        value: []
                    }
                })];
            };

            self.getAnonymizeFilterServiceName = function (definition) {
                return  monitoringServer.services.lgpd.path + "/" + definition.serviceName + "/anonymize/" + definition.entityName;
            };

            self.hasFilterTemplate = function (definition) {
                return $http.get(monitoringServer.baseUrl  + monitoringServer.services.lgpd.entity.anonymize + "/" + definition.entityName + "/has-filter-template")
                    .then(function (response) {
                        return response.data;
                    });
            };

            self.anonymizeFindFilterTemplate = function (data, params) {
                return $http.post(monitoringServer.baseUrl  + monitoringServer.services.lgpd.entity.anonymize + "/" + data.entityName + "/filter", data.template, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeGetEntitiesByName = function (entityName) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.anonymize + "/entities-by-name", entityName)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeGetFieldTemplate = function (field) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.anonymize + "/find-template", field)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeFindFieldTemplate = function (body, params) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.anonymize + "/find-field-template", body, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymize = function (definition, params) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.anonymize + "/anonymize", definition, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeAttachment = function (definition, params) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.anonymize + "/attachment-anonymize", definition, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeGetAnonymizableEntities = function (template, params) {
                return $http.post(monitoringServer.baseUrl + monitoringServer.services.lgpd.entity.anonymize + "/find", template, params)
                    .then(function (response) {
                        return response;
                    });
            };
            
            return self;
        }
    );
});
