import routeStopPopupModule from "./routeStopPopupModule"

routeStopPopupModule.service("routeStopPopupCreationService", ["translateFilter", "dateFilter", "durationFilter", function (translateFilter, dateFilter, durationFilter) {
    return function (route, stop) {
        var additionalInfo = ""
        for(var info in stop.additionalInfo){
            additionalInfo += "<tr> <td class=\"blue_title\">" + translateFilter("presentationnode.nodetype." + info.toLowerCase())
                + ":</td><td name=\"tripCode\">" + stop.additionalInfo[info] + "</td></tr>"
        }
        var loadUnloadDuration = ""
        if(stop.unloadedDuration){
            loadUnloadDuration += "        <tr> <td class=\"blue_title\">" + translateFilter("routeView.unloadingDuration") + ":</td><td name=\"unloadingDuration\">"
                + durationFilter(stop.unloadedDuration) + "</td></tr>"
        }
        if(stop.loadedDuration){
            loadUnloadDuration += "        <tr> <td class=\"blue_title\">" + translateFilter("routeView.loadingDuration") + ":</td><td name=\"loadingDuration\">"
                + durationFilter(stop.loadedDuration) + "</td></tr>"
        }

        function getTruckPlate() {
            let truckPlate = route.truckPlate
            if ( !truckPlate ) {
                return translateFilter("presentationnode.nodeproperty.NA")
            }
            return truckPlate
        }

        return " <div class=\"stopInfoTooltip\"> " +
            "    <div class=\"stopInfoTooltipHeader\">" +
            "        <div class=\"stopInfoTooltipNumber\" name=\"sequence\">" + (stop.sequenceOnTrip + 1) + "</div> " +
            "        <div class=\"stopInfoTooltipTitle\" name=\"stopName\">" + stop.stopName + "</div> " +
            "    </div>" +
            "    <table class=\"stopInfoTooltipContent\" name=\"stopInfoTooltip\">" +
            "        <tr> <td class=\"blue_title\">" + translateFilter("presentationnode.nodetype.trip") + ":</td><td name=\"tripCode\">" + route.tripCode + "</td></tr>" +
            "        <tr> <td class=\"blue_title\">" + translateFilter("edition.truck.plate") + ":</td><td name=\"truckPlate\">" + getTruckPlate() + "</td></tr>" +
            "        <tr> <td class=\"blue_title\">" + translateFilter("routeView.eta") + ":</td><td name=\"eta\">" + dateFilter(stop.arrivalTime, "short") + "</td></tr>" +
            loadUnloadDuration +
            additionalInfo +
            "        <tr> <td class=\"blue_title\" colspan=\"2\">" + translateFilter("presentationnode.nodeproperty.locality") + ":</td></tr>" +
            "        <tr> <td colspan=\"2\" name=\"street-number\">" + stop.address.street + ", " + (stop.address.number || "") + "</td></tr>" +
            "        <tr> <td colspan=\"2\" name=\"city-state-country\">" + stop.address.city.name + " - " + stop.address.state.name + ", " + stop.address.country.name + "</td></tr>" +
            "        <tr> <td colspan=\"2\" name=\"zipCode\">" + stop.address.zipCode.value + "</td></tr>" +
            "    </table>" +
            " </div>"
    }
}])