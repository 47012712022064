define([
    "./intlPhoneNumberModule",
    "lodash"
], function (intlPhoneNumberModule, _) {
    "use strict";

    return intlPhoneNumberModule.service("countryLanguages", [function () {
        var languages = [{
            language: "pt",
            iso2: "br"
        }, {
            language: "pt_BR",
            iso2: "br"
        }, {
            language: "en",
            iso2: "us"
        }, {
            language: "es",
            iso2: "mx"
        }];

        return {
            findByIso2Code: function (code) {
                return _.find(languages, {"iso2": code});
            },
            findByLanguage: function (language) {
                return _.find(languages, {"language": language});
            }
        };
    }]);
});