define([
    "./occurrenceListenerModule",
    "require",
    "text!./MBROccurrenceListenerParamModal.html"
], function (module, require, template) {
    "use strict";

    return module.service("MBROccurrenceListenerParamModalService", [
        "$modal",
        "mbrService",
        "loadingService",
        "messagesModal",
        function ($modal, mbrService, loadingService,messagesModal) {
            return function (listener) {
                return $modal.open({
                    template:  template,
                    size: "lg",
                    controller: [ "$scope", function($scope){
                        $scope.params = Object.entries(listener.properties);
                        $scope.save = function ($dismiss){
                            updateValues(listener.properties,  $scope.params);
                            loadingService(mbrService.updateOccurrenceListenersParam(listener.name, listener.properties)).then(function () {
                                messagesModal("dialog.success", [{
                                    keyBundle: "MBROccurrenceListenerParamModalService.sucess",
                                    parameters: []
                                }]);
                                $dismiss();
                            }).catch(function(){
                                messagesModal("dialog.error", [{
                                    keyBundle: "MBROccurrenceListenerParamModalService.failed",
                                    parameters: []
                                }]);
                            });

                        };

                        function updateValues(properties, entries){
                            var variableNameIndex = 0;
                            var variableValueIndex = 1;
                            for(var i = 0; i <  entries.length; i++){
                                properties[entries[i][variableNameIndex]] = entries[i][variableValueIndex];
                            }
                        }
                    }]
                });
            };
        }]);
});