define(["./nodeCommandsModule"],
    function (nodeCommandsModule) {
        "use strict";

        return nodeCommandsModule.service("nodeCommandMoveTripsToUser", ["moveTripsToUserModal", function (moveTripsToUserModal) {
            return function (selection,context) {
                return moveTripsToUserModal(selection, false)
                    .then(function(){
                        context.refilter();
                    });
            };
        }]);
    });