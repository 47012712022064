import {configParamsModule} from "../configParamsModule";
import {IntegrationService} from "../../integration/integrationService";
import {ConfigParamsService} from "../configParamsService";
import {LoadingService, MessagesModal, RemoteExceptionHandler} from "../../../bower_components/front-end-web-commons";
import {ExceptionModal} from "./exceptionModal";

export class NlgIntegrationValidatorController {
    private integrationDataSource: string = "";
    private integrationInterfaceName: string = "";

    constructor(private integrationService: IntegrationService,
                private configParamsService: ConfigParamsService,
                private loadingService: LoadingService,
                private remoteExceptionHandler: RemoteExceptionHandler,
                private messagesModal: MessagesModal,
                private exceptionModal: ExceptionModal) {
        this.getIntegrationDataSources().then(dataSources => this.integrationDataSource = dataSources[0])
    }

    getIntegrationDataSources() {
        return this.configParamsService.getIntegrationDataSources()
    }

    getAvailableInterfaces() {
        return this.configParamsService.getAvailableInterfaces()
    }

    testPublish() {
        return this.loadingService(this.configParamsService.testPublish(this.integrationInterfaceName, this.integrationDataSource))
            .catch(this.remoteExceptionHandler())
            .then(result => {
                if (result.successful) {
                    return this.messagesModal("dialog.success", "integration.connectivity.ok")
                }
                return this.exceptionModal.open(result.throwable)
            })
    }
}

configParamsModule.directive("nlgIntegrationValidator", function () {
    return {
        controller: NlgIntegrationValidatorController,
        controllerAs: "ctrl",
        // @ts-ignore
        templateUrl: require("link!./nlgIntegrationValidator.html")
    }
})