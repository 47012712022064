define([
    "angular",
    "./commonsOptimizationModule"
], function (angular, commonsOptimizationModule) {
    "use strict";
    commonsOptimizationModule.provider("optimizationServiceUrl", [function () {

        var optimizeUrl;

        this.$get = [function () {
            return {
                getOptimizeUrl: getOptimizeUrl
            };
        }];

        var getOptimizeUrl = function () {
            return optimizeUrl;
        };

        this.configure = function (server) {
            optimizeUrl = server.optimizeUrl;
        };
    }]);
});

