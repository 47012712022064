import integrationModule from "./integrationModule"
import server from "../server"
import {IHttpService, IPromise} from "angular";
import {R2IntegrationSysParams} from "./R2IntegrationSysParams";

export class IntegrationService {
    constructor(private $http: IHttpService) {
    }

    getIntegrationConfig() {
        return this.$http.get<R2IntegrationSysParams>(server.getServiceUrl("integration-config"))
            .then((response) => response.data)
    }

    createOrUpdateConfig(integrationConfig: R2IntegrationSysParams) {
        return this.$http.post<void>(server.getServiceUrl("integration-config"), integrationConfig)
    }

    testConnectivity(urlToTest: string) {
        return this.$http.post<boolean>(server.getServiceUrl("integration-connectivity"), urlToTest)
            .then((response) => response.data)
    }
}

integrationModule.service("integrationService", IntegrationService)