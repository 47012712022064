import localityModule from "./localityModule"
import server from "../server"
import monitoringServer from "../monitoringServer"

localityModule.service("localityService", ["$http", function ($http) {

    this.findPossibleIncompatibleVehicles = () => {
        return $http.get(server.getServiceUrl("edition.locality") + "vehicles")
            .then((response) => response.data)
    }

    this.findPossibleAllowedTrucks = () => {
        return $http.get(server.getServiceUrl("search.truck") + "find-all")
            .then((response) => response.data)
    }

    this.findPreDefinedWorkingHours = () => {
        return $http.get(server.getServiceUrl("edition.locality") + "working-hours")
            .then((response) => response.data)
    }

    this.getLocalityTypes = () => {
        var enumType = "br.com.neolog.monitoring.monitorable.model.api.LocalityType"
        return $http.get(monitoringServer.getServiceUrl("getEnum") + enumType)
            .then(getData)
    }

    this.getLocalityIcons = () => {
        var enumIcon = "br.com.neolog.monitoring.monitorable.model.api.LocalityIcon"
        return $http.get(monitoringServer.getServiceUrl("getEnum") + enumIcon)
            .then(getData)
    }

    function getData(response) {
        return response.data
    }
}])