define(["./timeModule", "angular", "text!./dateInterval.html"], function (timeModule, angular, template) {
    "use strict";

    return timeModule.directive("dateInterval", function () {
        return {
            restrict: "E",
            require: "ngModel",
            scope: {
                outerModel: "<ngModel"
            },
            template: template,
            link: function ($scope, $element, $attrs, ngModelController) {
                $scope.model = {
                    lowerBound: null,
                    upperBound: null
                };

                function modelFormatter(modelValue) {
                    return modelValue || {
                            lowerBound: null,
                            upperBound: null
                        };
                }

                ngModelController.$formatters.push(modelFormatter);

                ngModelController.$validators.notParcialInput = function (modelValue, viewValue) {
                    return (viewValue.lowerBound === null) === (viewValue.upperBound === null);
                };

                ngModelController.$parsers.push(function (viewValue) {
                    if (viewValue.lowerBound === null || viewValue.upperBound === null) {
                        return null;
                    }
                    return viewValue;
                });

                ngModelController.$validators.notEmptyWhenRequired = function (modelValue) {
                    var required = $attrs.required;
                    return !!(!required || (required && modelValue));
                };

                ngModelController.$render = function () {
                    $scope.model = angular.copy(ngModelController.$viewValue);
                };

                $scope.change = function () {
                    if (modelChanged()) {
                        ngModelController.$setViewValue(angular.copy($scope.model));
                    }
                };

                function modelChanged() {
                    return !angular.equals($scope.model, ngModelController.$viewValue);
                }

                ngModelController.$setViewValue(modelFormatter($scope.outerModel));
                ngModelController.$render();

                $element.closest("form,ng-form").on("reset", function () {
                    ngModelController.$setViewValue({lowerBound: null, upperBound: null});
                    ngModelController.$render();
                });
            }
        };
    });
});