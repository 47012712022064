define(["./arrangementModule", "../arrays/arrays", "three", "angular"], function (arrangementModule, arrays, THREE, angular) {
    "use strict";

    return arrangementModule.service("arrangementLayerReport", [
        "arrangementDrawerService",
        "arrangementViewType",
        "EntrySetMap",
        "Object3DInfo",
        "loadingService",
        "downloadBinary",
        "remoteExceptionHandler",
        "ArrangementColorGrouper",
        "arrangementUrls",
        function (arrangementDrawerService, arrangementViewType, EntrySetMap, Object3DInfo, loadingService, downloadBinary, remoteExceptionHandler, ArrangementColorGrouper, arrangementUrls) {
            var width = 800;
            var height = 640;
            var aspect = width / height;
            var self = {};

            self.vertical = function (arrangementDescription, viewType, customColors) {
                return generateReport(arrangementDescription, Object3DInfo.VERTICAL_LAYER_INDEX, viewType, customColors, function (center) {
                    var orthographicSize = diagonal(center.x, center.y, center.z) / (Math.min((center.x / 2000), 1.7));
                    var camera = new THREE.OrthographicCamera(-orthographicSize * aspect, orthographicSize * aspect, orthographicSize, -orthographicSize, 90000000, -90000000);
                    camera.position.z = -1;
                    camera.lookAt(new THREE.Vector3(center.x, center.y, center.z));
                    return camera;
                });
            };

            self.horizontal = function (arrangementDescription, viewType, customColors) {
                return generateReport(arrangementDescription, Object3DInfo.HORIZONTAL_LAYER_INDEX, viewType, customColors, function (center) {
                    var orthographicSize = diagonal(center.x, center.y, center.z);
                    var c = new THREE.OrthographicCamera(-orthographicSize * aspect, orthographicSize * aspect, orthographicSize, -orthographicSize, 90000000, -90000000);
                    c.position.x = center.x * 0.9;
                    c.position.z = -1;
                    c.rotation.x = 120 * Math.PI / 180;
                    c.rotation.y = -20 * Math.PI / 180;
                    c.rotation.z = 150 * Math.PI / 180;
                    return c;
                });
            };

            function diagonal(x, y, z) {
                return Math.sqrt((x * x) + (y * y) + (z * z));
            }

            function generateReport(arrangementDescription, object3dInfo, viewType, customColors, createCamera) {
                var renderer = arrangementDrawerService.createRenderer(width, height);
                return loadingService(downloadBinary.post(arrangementUrls.getLayerReportUrl(), {
                    tripId: getId(arrangementDescription.trip),
                    loadId: getId(arrangementDescription.load),
                    containerArrangements: arrays.map(arrangementDescription.containerArrangements, function (result) {
                        var center = result.container.center;
                        var camera = createCamera(center);
                        var scene = arrangementDrawerService.createBaseScene();
                        var drawnObjects = arrangementDrawerService.draw(scene, result.container);
                        var layers = new EntrySetMap();
                        arrays.each(drawnObjects, function (meshByContainer) {
                            var key = viewType.getValue(ArrangementColorGrouper.getExtraInfo(meshByContainer));
                            var customColor = ArrangementColorGrouper.getCustomColor(customColors, key);
                            meshByContainer.mesh.material.color.set(customColor || arrangementDrawerService.getColor(meshByContainer, viewType));
                            var extraInfo = arrangementDrawerService.getExtraInfo(object3dInfo, meshByContainer);
                            layers.getOrPut(extraInfo, []).push(meshByContainer);
                        });
                        return {
                            layers: arrays.map(layers.entrySet, function (entry) {
                                arrays.each(drawnObjects, arrangementDrawerService.setOpacity.bind(null, 0.05));
                                arrays.each(entry.value, arrangementDrawerService.setOpacity.bind(null, 1));
                                return {
                                    deliveryUnits: getDeliveryUnits(entry.value, viewType, customColors),
                                    image: renderer.renderAsDataUrl(scene, camera)
                                };
                            })
                        };
                    })
                }, arrangementDescription.load.code + ".pdf")).catch(remoteExceptionHandler());
            }

            function getId(obj) {
                if (angular.isArray(obj)) {
                    return arrays.map(obj, getId);
                }
                return obj.id || obj;
            }

            function getDeliveryUnits(meshsAndObjects, viewType, customColors) {
                var colorAndQuantityByDu = {};
                arrays.each(meshsAndObjects, function (meshAndObject) {
                    var key = viewType.getValue(ArrangementColorGrouper.getExtraInfo(meshAndObject));
                    var customColor = ArrangementColorGrouper.getCustomColor(customColors, key);
                    var duId = getId(arrangementDrawerService.getExtraInfo(Object3DInfo.DELIVERY_UNIT_DATA, meshAndObject).deliveryUnit);
                    colorAndQuantityByDu[duId] = colorAndQuantityByDu[duId] || {
                        color: new THREE.Color(customColor || arrangementDrawerService.getColor(meshAndObject, viewType)).getHex(),
                        quantity: 0
                    };
                    colorAndQuantityByDu[duId].quantity++;
                });
                return arrays.map(colorAndQuantityByDu, function (colorAndQuantity, duId) {
                    return {
                        deliveryUnitId: duId,
                        color: colorAndQuantity.color,
                        quantity: colorAndQuantity.quantity
                    };
                });
            }

            return self;
        }]);
});