define(["./routingConfigModule"], function (routingConfigModule) {
    "use strict";

    /**
     * @ngdoc object
     * @name routingConfigModule.OptimizationSource
     */
    return routingConfigModule.constant("OptimizationSource", {
        MANUAL_OPERATION: "MANUAL_OPERATION",
        UPDATE_OPERATION: "UPDATE_OPERATION",
        OPTIMIZATION: "OPTIMIZATION",
        VIEW_OPERATION: "VIEW_OPERATION",
        SCHEDULING: "SCHEDULING",
        SUGGEST_SCHEDULING : "SUGGEST_SCHEDULING"
    });
});