import analysisModule from "../analysisModule"
import server from "../../server"

analysisModule.service("nodeCommandExportAllTripPickingList", [
    "tripPickingListService",
    "$http",
    "remoteExceptionHandler",
    "loadingService",
    function (tripPickingListService, $http, remoteExceptionHandler, loadingService) {
        return function (selection) {
            let tripIds = selection.map(selection => selection.id)
            return loadingService($http.post(server.getServiceUrl("tripPickingListExportAll"), tripIds)
                .then(function (response) {
                    return tripPickingListService.exportTripPickingListsInZip(response.data)
                })).catch(remoteExceptionHandler())
        }
    }])