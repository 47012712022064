define(["./extensionModule", "text!./nlgExtension.html"], function (module, template) {
    "use strict";

    return module.directive("nlgExtension", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                extension: "<"
            }
        };
    }]);
});