define([
    "./occurrencesModule",
    "json!../monitoring.json",
    "moment"
], function (occurrencesModule, monitoring, moment) {
    "use strict";

    occurrencesModule.service("occurrenceService", [
        "monitoringService",
        "newOccurrenceModal",
        "loadingService",
        "remoteExceptionHandler",
        function (monitoringService, newOccurrenceModal, loadingService, remoteExceptionHandler) {
            var self = this;

            self.getFilterServiceUrl = function () {
                return monitoring.services.occurrences;
            };

            self.getFilterServiceUrlWithMonitorable = function () {
                return monitoring.services.occurrencesWithMonitorable;
            };

            self.filter = function (template, firstResult, maxResults) {
                return monitoringService.filterOccurrences(
                    "/filter",
                    template,
                    firstResult,
                    maxResults);
            };

            self.filterWithMonitorable = function (template, firstResult, maxResults) {
                return monitoringService.filterOccurrencesWithMonitorable(
                    "/filter",
                    template,
                    firstResult,
                    maxResults);
            };

            self.filterForMonitorable = function (monitorableId, template, firstResult, maxResults) {
                return monitoringService.filterOccurrences(
                    monitorableId + "/monitorable/filter",
                    template,
                    firstResult,
                    maxResults);
            };

            self.findById = function (occurrenceId) {
                return  monitoringService.findOccurrenceById(occurrenceId);
            };

            self.createNewOccurrenceForMonitorable = function (monitorableId) {
                return loadingService(monitoringService.findMonitorableById(monitorableId))
                    .catch(remoteExceptionHandler())
                    .then(function (monitorable) {
                        return newOccurrenceModal(monitorable);
                    });
            };

            self.createNewOccurrence = function () {
                return newOccurrenceModal();
            };

            self.getMonitorables = function(occurrenceId) {
                return monitoringService.findMonitorableByOccurrenceId(occurrenceId);
            };

            self.openTimeDuration = function(occurrence){
                if(occurrence.finalizedTimestamp){
                    return calculateDurationWithFinalizedTimestamp(moment.utc(occurrence.finalizedTimestamp), moment.utc(new Date(occurrence.timestamp)) );
                }
                return self.calculateDuration(occurrence.timestamp)
            };

            self.durationInStatus = function (occurrence) {
                if(occurrence.finalizedTimestamp){
                    return calculateDurationWithFinalizedTimestamp(moment.utc(occurrence.finalizedTimestamp), moment.utc(new Date(occurrence.statusChangeTimestamp)));
                }
                return self.calculateDuration(occurrence.statusChangeTimestamp);
            };

            self.getIndicator = function(occurrence) {
                var openedStatusTime = Date.now() - occurrence.statusChangeTimestamp;
                if(occurrence.status.statusType === "br.com.neolog.monitoring.monitorable.model.api.occurrence.OccurrenceStatusType.FINAL"){
                    return;
                }

                if(openedStatusTime > occurrence.status.secondSla){
                    return "images/red.png"
                }
                if(openedStatusTime > occurrence.status.firstSla){
                    return "images/yellow.png"
                }
                return "images/green.png"
            };

            self.calculateDuration = function (timestamp) {
                var momentNow = moment.utc(Date.now());
                var momentTimestamp = moment.utc(new Date(timestamp));

                var ms = moment(momentNow,"DD/MM/YYYY HH:mm:ss").diff(moment(momentTimestamp,"DD/MM/YYYY HH:mm:ss"));
                var duration = moment.duration(ms);

                var formattedDuration = duration.days() + "d " + duration.hours() + "h " + duration.minutes() + "m";
                return formattedDuration;
            };

            function calculateDurationWithFinalizedTimestamp(firstMoment, lastMoment) {
                var ms = moment(firstMoment,"DD/MM/YYYY HH:mm:ss").diff(moment(lastMoment,"DD/MM/YYYY HH:mm:ss"));
                var duration = moment.duration(ms);

                var formattedDuration = duration.days() + "d " + duration.hours() + "h " + duration.minutes() + "m";
                return formattedDuration;
            }

            return self;
        }]);
});