define([
    "./nodeCommandsModule",
    "./nodeCommandViewRoute",
    "./nodeCommandCalculateToll",
    "./nodeCommandCancelTrips",
    "./nodeCommandOptimize",
    "./nodeCommandFinalizeTrip",
    "./nodeCommandMoveTripsToUser",
    "./nodeCommandMoveDeliveryUnitToAnotherUser",
    "./monitoringNodeCommandDocumentView",
    "./nodeCommandViewArrangement",
    "./nodeCommandViewTripArrangement",
    "./nodeCommandTripPickingList"
], function (nodeCommandsModule) {
    "use strict";

    /**
     * @ngdoc service
     * @name nodeCommandsModule.commonNodeCommandExecutor
     * @description
     * Estrutura com comandos para execução pela tela de análise.
     *
     * Comandos são serviços que recebem como parâmetro um array com os nós envolvidos na operação.
     * */
    return nodeCommandsModule.service("commonNodeCommandExecutor", [
        "NodeCommandExecutor",
        "nodeCommandViewRoute",
        "nodeCommandCalculateToll",
        "nodeCommandCancelTrips",
        "nodeCommandOptimize",
        "nodeCommandFinalizeTrip",
        "nodeCommandMoveTripsToUser",
        "nodeCommandMoveDeliveryUnitToAnotherUser",
        "monitoringNodeCommandDocumentView",
        "nodeCommandViewArrangement",
        "nodeCommandViewTripArrangement",
        "nodeCommandTripPickingList",
        function (NodeCommandExecutor, nodeCommandViewRoute, nodeCommandCalculateToll, nodeCommandCancelTrips, nodeCommandOptimize, nodeCommandFinalizeTrip, nodeCommandMoveTripsToUser, nodeCommandMoveDeliveryUnitToAnotherUser, monitoringNodeCommandDocumentView, nodeCommandViewArrangement, nodeCommandViewTripArrangement, nodeCommandTripPickingList) {
            var commandMap = {
                "viewRouteCommand": nodeCommandViewRoute,
                "calculateTollCommand": nodeCommandCalculateToll,
                "cancelTripsCommand": nodeCommandCancelTrips,
                "optimizeCommand": nodeCommandOptimize,
                "nodeCommandFinalizeTrip": nodeCommandFinalizeTrip,
                "moveTripsToUser": nodeCommandMoveTripsToUser,
                "nodeCommandMoveDeliveryUnitToAnotherUser": nodeCommandMoveDeliveryUnitToAnotherUser,
                "monitoringNodeCommandDocumentView": monitoringNodeCommandDocumentView,
                "viewArrangement": nodeCommandViewArrangement,
                "viewTripArrangement": nodeCommandViewTripArrangement,
                "tripPickingListCommand": nodeCommandTripPickingList
            };
            return new NodeCommandExecutor(commandMap);
        }]);
});