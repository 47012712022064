define(["./mapsModule", "../arrays/arrays", "angular"], function (mapsModule, arrays, angular) {
    "use strict";

    /**
     * @ngdoc service
     * @name mapsModule.alterStopsService
     * @description
     * Serviço de confirmação de troca de paradas.
     **/
    return mapsModule.provider("alterStopsService", [function () {
        var config = {};
        this.configure = function (configValue) {
            config = configValue;
        };

        this.$get = ["routingSelectionDialog",
            "OptimizationSource",
            "FlowType",
            "$http",
            "$httpParamSerializer",
            "$modal",
            "loadingService",
            "messagesModal",
            "remoteExceptionHandler",
            "violationModal",
            function (routingSelectionDialog, OptimizationSource, FlowType, $http, $httpParamSerializer, $modal, loadingService, messagesModal, remoteExceptionHandler, violationModal) {
                return {
                    confirmAlteration: confirmAlteration,
                    moveStop: moveStop
                };

                function moveStop(moveStopParameter) {
                    return loadingService($http.post(config.moveStopUrl, moveStopParameter))
                        .catch(remoteExceptionHandler())
                        .then(function (response) {
                            return violationModal(response.data);
                        })
                        .then(function (manualOperationResult) {
                            return loadingService($http.post(config.manualOperationSaveUrl, manualOperationResult.token))
                                .catch(remoteExceptionHandler());
                        })
                        .then(function () {
                            return messagesModal("dialog.success", [{
                                keyBundle: "alterStopsModal.success",
                                parameters: []
                            }]);
                        });
                }


                function confirmAlteration(stops, initSequence, tripsId, context) {
                    var sequenceParam = {};
                    var hasModifications = false;

                    angular.copy(tripsId).forEach(function (tripId) {
                        sequenceParam[tripId.id] = [];
                        angular.forEach(stops[tripId.id], function (stop) {
                            sequenceParam[tripId.id].push(stop.sequenceOnTrip);
                        });
                        if (!angular.equals(sequenceParam[tripId.id], initSequence[tripId.id])) {
                            hasModifications = true;
                        } else {
                            delete stops[tripId.id];
                            delete sequenceParam[tripId.id];
                            delete initSequence[tripId.id];
                            arrays.remove(tripsId, tripId);
                        }
                    });

                    if (!hasModifications) {
                        return messagesModal("dialog.warning", [{
                            keyBundle: "alterStopsModal.violation.noAlteration",
                            parameters: []
                        }]);
                    }

                    var tripsIds = arrays.map(tripsId, function (nodeIdentifier) {
                        return nodeIdentifier.id;
                    });


                    return routingSelectionDialog({
                        source: OptimizationSource.MANUAL_OPERATION,
                        flow: FlowType.ANALYSIS,
                        trips: tripsIds,
                        dus: [],
                        single: true
                    }).then(function (configs) {
                        return doAlter(tripsId, sequenceParam, configs)
                            .catch(remoteExceptionHandler())
                            .then(function (response) {
                                return violationModal(response.data);
                            }).then(function (manualOperationResult) {
                                return saveOperation(manualOperationResult);
                            }).then(function () {
                                return messagesModal("dialog.success", [{
                                    keyBundle: "alterStopsModal.success",
                                    parameters: []
                                }]);
                            }).then(function () {
                                if (context) {
                                    context.refilter();
                                }
                            });
                    });
                }

                function saveOperation(manualOperationResult) {
                    return loadingService($http.post(config.manualOperationSaveUrl, manualOperationResult.token));
                }

                function doAlter(tripsId, sequenceParam, configs) {
                    if (tripsId.length === 1) {
                        return loadingService($http({
                            url: config.doAlterStopsUrl,
                            method: "POST",
                            headers: {"Content-Type": "application/x-www-form-urlencoded"},
                            data: $httpParamSerializer({
                                sequence: sequenceParam[tripsId[0].id],
                                routingConfigId: configs.routingConfig.id,
                                tripId: tripsId[0].id
                            })
                        }));
                    }

                    var alterations = [];
                    tripsId.forEach(function (trip) {
                        alterations.push({
                                tripNodeIdentifier: trip,
                                sequence: sequenceParam[trip.id]
                            }
                        );
                    });

                    return loadingService($http.post(config.doAlterStopsForTripsUrl, {
                        alterations: alterations,
                        routingConfigId: configs.routingConfig.id
                    }));
                }
            }];


    }]);
});