define([
    "angular",
    "./tripPickingListModule"
], function (angular, tripPickingListModule) {
    "use strict";
    tripPickingListModule.provider("tripPickingListServiceImages", [function () {

        var images = {};

        this.$get = [function () {
            return {
                getImages: getImages
            };
        }];

        function getImages() {
            return images;
        }

        this.configure = function (provider) {
            images = provider.images;
        };
    }]);
});

