define([
    "./genericParamsModule",
    "text!./genericParamDataDialog.html",
    "./nlgGenericParamData",
    "./nlgGenericParamDefinitionInstructions/nlgGenericParamDefinitionInstructions",
], function (crudModule, template) {
    "use strict";

    return crudModule.service("genericParamDataDialog", ["$modal", function ($modal) {
        return function (entity, isEnabled) {
            return $modal.open({
                template: template,
                controller: ["$scope", function ($scope) {
                    $scope.entity = entity;
                    $scope.isEnabled = isEnabled;

                    $scope.model = {
                        viewMode: "table"
                    };
                }],
                size: "xl"
            }).result;
        };
    }]);
});