import userByOriginsModule from "./userByOriginsModule"
import server from "../server"

userByOriginsModule.service("userByOriginsService", ["$http", ($http) => {
    return {
        save: save,
        findAllOrigins: findAllOrigins,
        userByOrigins: userByOrigins
    }

    function save(userByOriginsSave) {
        return $http.post(server.getServiceUrl("userByOrigins"), userByOriginsSave).then((response) => response.data)
    }

    function userByOrigins(patch){
        return $http.post(server.getServiceUrl("userByOrigins") + "filter", patch).then((response) => response.data)
    }

    function findAllOrigins() {
        return $http.get(server.getServiceUrl("userByOrigins"))
            .then((response) => response.data)
    }
}])