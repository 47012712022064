define(["./directivesModule"], function (directivesModule) {
    "use strict";

    var safetyLimit = 20;

    /**
     * @ngdoc directive
     * @name nlgHeightFiller
     * @restrict A
     *
     * @description
     *
     * @param {string} nlgHeightFiller altura a ser aplicada ao elemento ou `auto` para que a altura seja ajustada
     * automaticamente de acordo com a altura  do `browser window viewport`.
     *
     * @example
     <example>
     <file name="nlg-height-filler.html">
     <div nlg-height-filler="auto"></div>
     </file>
     </example>
     */
    return directivesModule.directive("nlgHeightFiller", ["$window", function ($window) {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                var heightValue = attrs.nlgHeightFiller;

                function setNewHeight(newHeight) {
                    element.height(newHeight);
                }

                if (heightValue === "auto") {
                    scope.$watch(function () {
                        return $window.innerHeight - element.offset().top - safetyLimit;
                    }, setNewHeight);
                } else if (heightValue) {
                    scope.$watch(function () {
                        return attrs.nlgHeightFiller;
                    }, setNewHeight);
                }
            }
        };
    }]);
});