define(["./genericParamsModule",
    "../objects/objects"
], function (services, objects) {
    "use strict";

    /**
     * @ngdoc service
     * @name services.genericParamMetadataService
     * @description
     * Serviço que provê métodos para tratar metadados de componentes de parâmetros genéricos.
     * */
    return services.service("genericParamMetadataService", [function () {
        var metadataTypeMapping = {
            "string": "text",
            "boolean": "checkbox",
            "number": "number",
            "color": "color",
            "datetime": "datetime",
            "date": "date",
            "time": "time",
            "entity": "singleTagInput",
            "enum": "singleTagInput",
            "duration": "duration",
            "entityFormat": "entityFormat"
        };
        return {
            createCellConfig: createCellConfig,
            createPivotTableCellConfig: createPivotTableCellConfig,
            getEscapedComponentName: getEscapedComponentName,
            escape: escape
        };

        function createCellConfig(component) {
            var escapedComponentName = getEscapedComponentName(component);
            var cellConfig = {
                model: "record.properties['" + escapedComponentName + "']",
                name: "{{ record.properties['" + escapedComponentName + "'] }}",
                type: getTemplateType(component.type, component.editable),
                disabled: createDisabledExpressionForComponent(),
                // Responsabilidade dos validadores de parâmetro no serviço validar
                nullable: ""
            };
            if (component.editable) {
                cellConfig.change = "updateDependencies(record, '" + escapedComponentName + "')";
            }
            var metadata = component.metadata;
            if (component.type === "enum") {
                cellConfig.getTags = "getEnums('" + metadata.enumClass + "')";
            }
            if (component.type === "entity") {
                cellConfig.getTags = "getEntities(record, viewValue, '" + escapedComponentName + "')";
            }
            return objects.enrich(cellConfig, metadata);

            function createDisabledExpressionForComponent() {
                if (component.metadata.dependencyInfo) {
                    // deve ter um watcher para dependencyInfo
                    return "!record.editable || ngDisabled || " + (!component.editable) + " || !record.properties['" + escape(component.metadata.dependencyInfo) + "']";
                }
                return "::(!record.editable || ngDisabled || " + (!component.editable) + ")";
            }
        }

        function createPivotTableCellConfig(component) {
            var escapedComponentName = getEscapedComponentName(component);
            var cellConfig = {
                model: "row.entity.properties['" + escapedComponentName + "']",
                type: getTemplateType(component.type, component.editable),
                disabled: !component.editable + "|| !row.entity.editable"
            };
            var metadata = component.metadata;
            if (component.type === "enum") {
                cellConfig.getTags = "getEnums('" + metadata.enumClass + "')";
            }
            if (component.type === "entity") {
                cellConfig.getTags = "grid.appScope.getEntities(row, viewValue, '" + escapedComponentName + "')";
            }
            return objects.enrich(cellConfig, metadata);
        }

        function getEscapedComponentName(component) {
            return escape(component.name);
        }

        function escape(string) {
            return string.replace(/(['"])/g, "\\$1");
        }

        function getTemplateType(dataType, editable) {
            var presentationType = metadataTypeMapping[dataType];
            if (!presentationType || (dataType === "string" && !editable)) {
                return "default";
            }
            return presentationType;
        }
    }]);
});