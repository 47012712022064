define([
    "../nlgLGPDModule",
], function (lgpdModule) {
    "use strict";

    return lgpdModule.controller("LGPDAttributableControllerSearch", [
        "$scope",
        "LGPDAttributableService",
        "remoteExceptionHandler",
        "messagesModal",
        "$state",
        "nlgLGPD",
        function ($scope, attributableService, remoteExceptionHandler, messagesModal, $state, nlgLGPD) {
            var filterPatch, paginationApi;

            $scope.serviceName = nlgLGPD.getAttributePath();

            $scope.search = function (patch) {
                filterPatch = patch;
                paginationApi.seekPage(1);
            };

            $scope.tableOptions = {
                records: [],
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        return attributableService.filterRecords(filterPatch, firstResult, maxResults)
                            .then(function(response) {
                                var result = response.data;
                                result.result = result.result[0].lgpdDefinitions;
                                return result;
                            })
                            .catch(remoteExceptionHandler());
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                }
            };

            $scope.delete = function (id) {
                return messagesModal.cancellable("dialog.warning", ["edition.lgpd.delete.entity"])
                    .then(function () {
                        return attributableService.deleteEntity(id).then(function () {
                            return messagesModal("dialog.success", {
                                keyBundle: "lgpd.edition.delete.success"
                            }).then(function () {
                                paginationApi.seekPage(1);
                            });
                        }).catch(remoteExceptionHandler());
                    });
            };
        }]);

});
