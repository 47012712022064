define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    return templateFactoryModule.service("templateFactoryMultiSelectTemplate", [function () {
        return function (config) {
            var element = angular.element("<nlg-multi-select>");
            if (config.model) {
                element.attr("ng-model", config.model);
            }
            if (config.options) {
                element.attr("options", config.options);
            }
            return element;
        };
    }]);
});