define([
    "./virtualFenceModule",
    "text!./virtualFenceView.html",
], function (module, virtualFenceView) {
    "use strict";
    module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("monitoring.virtualFence", {
            url: "/virtualFence?reload",
            controller: "virtualFenceController",
            template: virtualFenceView,
            resolve: {
                reload: ["$stateParams", function ($stateParams) {
                    return $stateParams.reload === "true";
                }],
                template: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                    return monitoringService.getVirtualFenceConfigurations();
                }]
            },
            data: {
                group: "group.monitoring.virtual.fence.properties"
            }
        })
    }]);
});
