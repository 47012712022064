define([
    "angular",
    "monitoring",
    "front-end-web-commons",
    "front-end-web-commons/app/nlgSaveAndContinue/nlgSaveAndContinue",
    "./lgpd/trackingLGPD",
    "front-end-web-commons/app/nlgLGPD/nlgLGPD"
], function (angular) {
    "use strict";

    return angular.module("trackingModule", [
        "monitoringModule",
        "mapsModule",
        "nlgSaveAndContinueModule",
        "trackingLGPDModule"
    ]);
});