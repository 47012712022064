define([
    "./chartingModule",
    "text!./tableChart.html",
    "d3",
    "lodash",
    "../arrays/arrays",
    "json!./routingChart.json",
    "moment"
], function (chartingModule, template, d3, _, arrays, routingChart, moment) {
    "use strict";

    /**
     * @ngdoc directive
     * @name chartingModule.directive:tableChart
     * @description
     * Diretiva que exibe um gráfico de tabela.
     * As configurações consumidas pelo gráfico são:
     *  - orientação da tabela (vertical ou horizontal);
     *  - formatações opcionais associados à cada dado (porcentagem, horas, etc.);
     *  - formatos associados à colunas indicando como será a ordenação da mesma;
     *  - links associados à colunas, de forma que todos os dados da coluna serão
     *  associados com links relativos à si mesmo e a coluna.
     *
     * @example
     * <example module="FrontEndWeb">
     *     <file name="index.html">
     *         <div ng-controller="example">
     *            <div table-chart="tableChartInput"></div>
     *         </div>
     *     </file>
     *     <file name="index.js">
     *         angular.module("FrontEndWeb").controller("example", function ($scope) {
     *              $scope.tableChartInput = (function () {
     *                  return {
     *                      configs: {
     *                          chartTitle: "The Chart Title",
     *                          type: "table",
     *                          orientation: "br.com.neolog.dashboard.chart.view.TableChartViewConfig.Orientation.HORIZONTAL",
     *                          legendTitles: {
     *                              "label1": "header1",
     *                              "label2": "header2"
     *                          },
     *                          dataFormats: {
     *                              "header2": "PERCENTAGE"
     *                          },
     *                          links: {
     *                              "header1": "br.com.neolog.dashboard.chart.linkable.LinkableField.OCCURRENCE_BY_SOURCEID"
     *                          }
     *                      },
     *                      data: [
     *                      {
     *                          elements:[
     *                          {
     *                              value: 58.12,
     *                              alias: "label1",
     *                              javaType: "java.lang.Integer"
     *                          },
     *                          {
     *                              value: "end",
     *                              alias: "label2",
     *                              javaType: "java.lang.Double"
     *                          }]
     *                      },
     *                      {
     *                          elements:[
     *                          {
     *                              value: 15298,
     *                              alias: "label1",
     *                              javaType: "java.lang.Integer"
     *                          },
     *                          {
     *                              value: 123.2,
     *                              alias: "label2",
     *                              javaType: "java.lang.Double"
     *                          }]
     *                      }]
     *                  }
     *              }());
     *         });
     *     </file>
     * </example>
     */
    chartingModule.directive("tableChart", ["$translate", "$state", "chartLocale", function ($translate, $state, chartLocale) {
        return {
            restrict: "A",
            scope: {
                context: "=tableChart"
            },
            template: template,
            controller: ["$scope", "$window", "$element", "$filter", "entityFormatFilter", function ($scope, $window, $element, $filter, entityFormat) {
                var rootNode = d3.select($element[0]);
                var thead = rootNode.select("thead");
                var tbody = rootNode.select("tbody");

                $scope.$watch(function () {
                    return $scope.context;
                }, function (newContext) {
                    var processedData = processData(newContext);
                    createTableAndSetData(processedData, newContext);
                }, true);

                var mouseover = function () {
                    d3.select(this).style("background-color", "#ECF0F1");
                };

                var mouseout = function () {
                    d3.select(this).style("background-color", "white");
                };

                var sort = {
                    predicate: "",
                    ascending: false,
                    headerElement: null
                };

                function createTableAndSetData(processedData, context) {
                    if (processedData.headers.length > 0) {
                        setHeaders(processedData.headers);
                    }

                    tbody.selectAll("tr").remove();

                    var tbodytrs = tbody.selectAll("tr")
                        .data(processedData.elements);
                    tbodytrs.exit().remove();
                    tbodytrs.enter().append("tr");
                    tbodytrs.on("mouseover", mouseover);
                    tbodytrs.on("mouseout", mouseout);

                    /*
                    * Indexes criados a partir do header para ter a posição de qual elemento é linkável ou não.
                    * */
                    var indexes = [];
                    _.forEach(processedData.notTranslatedHeaders, function (value) {
                        indexes.push(getLink(value));
                    });

                    var tbodytds = tbodytrs.selectAll("td")
                        .data(identity());
                    tbodytds.exit().remove();
                    tbodytds.enter().append("td")
                        .attr("class", setTDAsHeaderElement($scope.context.configs.orientation));

                    var mapIdSourceId = mapIdForSourceId(context.data);
                    setElement(tbodytds);


                    /*
                    * Adapta o uso do index para casos de tabelas horizontais ou verticais.
                    * */
                    function setElement(tableData) {
                        if (normalizeEnum($scope.context.configs.orientation) === "HORIZONTAL") {
                            tableData.each(function (value, verticalIndex, horizontalIndex) {
                                if (verticalIndex === 0) {
                                    d3.select(this)
                                        .text(identity());
                                } else {
                                    setTableData(value, horizontalIndex, this);
                                }
                            });
                        } else if (normalizeEnum($scope.context.configs.orientation) === "VERTICAL") {
                            tableData.each(function (value, verticalIndex) {
                                setTableData(value, verticalIndex, this);
                            });
                        } else {
                            throw "Orientation undefined";
                        }
                    }

                    /*
                    * Escolhe se vai setar no elemento um anchor ou apenas um texto, com base no index do header.
                    * */
                    function setTableData(value, index, reference) {
                        if (indexes[index].linkable) {
                            var linkValue = getLinkByValue(value);
                            d3.select(reference).append("a")
                                .attr("href", indexes[index].url + linkValue)
                                .attr("target", "_blank")
                                .text(identity());
                        } else {
                            d3.select(reference)
                                .text(identity());
                        }
                    }

                    function getLinkByValue(value) {
                        var linkValue = value;
                        mapIdSourceId.forEach(function (element) {
                            if (element.sourceId === value && element.identifier) {
                                linkValue = element.identifier;
                                return linkValue;
                            }
                        });
                        return linkValue;
                    }

                    function setHeaders(headers) {
                        var theadtds = thead.select("tr.header")
                            .selectAll("th")
                            .data(headers);
                        theadtds.exit().remove();
                        theadtds.text(identity());
                        theadtds.enter()
                            .append("th")
                            .attr("class", function () {
                                return "header-element";
                            })
                            .text(identity())
                            .on("click", headerOnClickSort());
                    }

                    function getLink(index) {
                        if (context.configs.links[index] !== undefined) {
                            var routeName = normalizeEnum(context.configs.links[index] + "");
                            return {
                                linkable: true,
                                url: $state.href(routingChart[routeName], {absolute: true})
                            };
                        }
                        return {
                            linkable: false
                        };
                    }

                    function setTDAsHeaderElement(orientation) {
                        return function (data, col) {
                            if (normalizeEnum(orientation) === "HORIZONTAL" && col === 0) {
                                return "header-element";
                            }
                            return "class";
                        };
                    }

                    function identity() {

                        return function (element) {
                            return (element);
                        };
                    }

                    function headerOnClickSort() {
                        return function (header) {
                            var index = processedData.headers.indexOf(header);
                            if (sort.predicate !== header) {
                                sort.predicate = header;
                                sort.ascending = true;
                                if (sort.headerElement !== null) {
                                    sort.headerElement.selectAll("i.nlgSortIcon").remove();
                                }
                                sort.headerElement = d3.select(this);
                                sort.headerElement.append("i")
                                    .attr("class", "nlgSortIcon nlgAscSort")
                                    .style("margin-left", "5px");
                            } else {

                                sort.ascending = sort.ascending !== true;
                                var sortClassToAdd = sort.ascending ? "nlgAscSort" : "nlgDescSort";
                                sort.headerElement.selectAll("i.nlgSortIcon").remove();
                                sort.headerElement.append("i")
                                    .attr("class", "nlgSortIcon " + sortClassToAdd)
                                    .style("margin-left", "5px");
                            }
                            rootNode.selectAll("tbody tr")
                                .sort(getFunctionSort(header, index));
                        };
                    }

                    function getFunctionSort(predicate, index) {
                        var predicateDefinition = _.find($scope.context.configs.definitions, function (definition) {
                            return definition.header === predicate;
                        });
                        if (!predicateDefinition || !arrays.contains(["PERCENTAGE", "REAL", "DATETIME"], predicateDefinition.format)) {
                            return function (a, b) {
                                return sort.ascending === true ? d3.ascending(a[index], b[index]) : d3.descending(a[index], b[index]);
                            };
                        }
                        if (predicateDefinition.format === "PERCENTAGE") {
                            return function (a, b) {
                                return sort.ascending === true ? d3.ascending(parseFloat(a[index]), parseFloat(b[index])) : d3.descending(parseFloat(a[index]), parseFloat(b[index]));
                            };
                        }
                        if (predicateDefinition.format === "REAL") {
                            return function (a, b) {
                                var valueIndexA = Number(a[index].replace(/[^0-9.-]+/g, ""));
                                var valueIndexB = Number(b[index].replace(/[^0-9.-]+/g, ""));
                                return sort.ascending === true ? d3.ascending(valueIndexA, valueIndexB) : d3.descending(valueIndexA, valueIndexB);
                            };
                        }
                        if (predicateDefinition.format === "DATETIME" || predicateDefinition.format === "DATE") {
                            return function (a, b) {
                                var dateA = new Date(a[index]);
                                var dateB = new Date(b[index]);
                                return sort.ascending === true ? d3.ascending(dateA.getTime(), dateB.getTime()) : d3.descending(dateA.getTime(), dateB.getTime());
                            };
                        }
                    }

                    function mapIdForSourceId(data) {
                        return _.map(data, function (data) {
                            return {
                                sourceId: data.sourceId,
                                identifier: data.identifier
                            };
                        });
                    }
                }

                function applyFormat(targetType, element) {
                    if (isOfType("ENTITY") || isOfType("IDENTIFIABLE")) {
                        return entityFormat;
                    }
                    if (isOfType("DATE")) {
                        return $filter("date")(element, "dd/MM/yyyy");
                    }
                    if (isOfType("DATETIME")) {
                        return $filter("date")(element, "dd/MM/yyyy H:mm");
                    }
                    if (isOfType("HOURS")) {
                        return ($filter("date")(element, "hh/mm/")) + "h";
                    }
                    if (isOfType("DURATION")) {
                        var duration = moment.duration(Math.abs(element) * 1000);
                        return Math.floor(duration.asDays()) + "d " + duration.hours() + "h " + duration.minutes() + "m";
                    }
                    if (isOfType("PERCENTAGE")) {
                        return d3.format(".2%")(element);
                    }
                    if (isOfType("NUMBER")) {
                        return chartLocale.formatDecimal(element);
                    }
                    if (isOfType("REAL")) {
                        return chartLocale.formatCurrency(element);
                    }
                    if (isOfType("FREIGHT_BY_WEIGHT")) {
                        return "R$/ton " + chartLocale.formatDecimal(element);
                    }
                    if (isOfType("FREIGHT_BY_VOLUME")) {
                        return "R$/m³ " + chartLocale.formatDecimal(element);
                    }
                    if (isOfType("FREIGHT_BY_DISTANCE")) {
                        return "R$/km " + chartLocale.formatDecimal(element);
                    }
                    return element;

                    function isOfType(type) {
                        return targetType === type;
                    }
                }

                function processData(context) {
                    var normalizedOrientation = normalizeEnum(context.configs.orientation);
                    if (normalizedOrientation === "HORIZONTAL") {
                        return processHorizontalData(context);
                    }
                    if (normalizedOrientation === "VERTICAL") {
                        return processVerticalData(context);
                    }
                    throw "Orientation is invalid. It must be HORIZONTAL or VERTICAL.";
                }

                function processHorizontalData(context) {
                    var data = createDataStructure();
                    context.configs.definitions.forEach(function (definition) {
                        var values = [];
                        values.push($translate.instant(definition.header));
                        context.data.forEach(function (element) {
                            values.push(adaptData(definition, element));
                        });
                        data.elements.push(values);
                        data.notTranslatedHeaders.push(normalizeEnum(definition.header));
                    });
                    return data;
                }

                function processVerticalData(context) {
                    var data = createDataStructure();
                    context.configs.definitions.forEach(function (definition) {
                        data.headers.push($translate.instant(definition.header));

                        data.notTranslatedHeaders.push(normalizeEnum(definition.header));
                    });
                    context.data.forEach(function (element) {
                        var values = [];
                        context.configs.definitions.forEach(function (definition) {
                            values.push(adaptData(definition, element));
                        });
                        data.elements.push(values);
                    });
                    return data;
                }


                function normalizeEnum(enumerator) {
                    var lastDot = enumerator.lastIndexOf(".");
                    if (lastDot === -1) {
                        return enumerator;
                    }
                    return enumerator.substr(lastDot + 1);
                }

                function adaptData(definition, element) {
                    var model = definition.model;
                    var format = definition.format;
                    return applyFormat(format, element[model]);
                }

                function createDataStructure() {
                    return {

                        notTranslatedHeaders: [],
                        headers: [],
                        elements: []
                    };
                }
            }]
        };
    }]);
});
