define(["../trackingModule", "text!./TrackTripView.html"], function (trackingModule, trackTripView) {
    "use strict";

    return trackingModule.service("trackTripModal", ["$modal", "$q", "messagesModal", "loadingService", "trackingService", "trackingMobileService", "remoteExceptionHandler", function ($modal, $q, messagesModal, loadingService, trackingService, trackingMobileService, remoteExceptionHandler) {
        return function (tripId) {
        	var promise = $q.all([
        		trackingService.findByMonitorableId(tripId).catch(function() { return null; }),
        		trackingMobileService.tripHasDriver(tripId).catch(function(){ return false; }),
        		trackingService.listProviders().catch(function() { return []; }),
                trackingMobileService.isDriverMandatory().catch(function() { return false; })
			]);
        	
            return loadingService(promise).then( function (info) {
				var trackableMonitorable = info[0];
				var driver = info[1];
				var providers = info[2];
				var isDriverMandatory = info[3];
				if ( trackableMonitorable && driver ) {
					return messagesModal("dialog.warning", {keyBundle: "tracking.trip.modal.alreadyStarted"});
				}
                if (!providers || !providers.length) {
                    return messagesModal("dialog.warning", {keyBundle: "tracking.trip.modal.noTrackersAvailable"});
                }
                return $modal.open({
                    size: "md",
                    template: trackTripView,
                    controller: ["$scope", "trackingService", function ($scope) {
                    	$scope.alreadyTracked = !!trackableMonitorable;
        				$scope.alreadyHasDriver = !!driver;
        				$scope.isDriverMandatory = !!isDriverMandatory;

        				$scope.tracker = {};
    					// Carrega os provedores
    					$scope.thirdPartyProviders = providers.filter(function (provider) {
    						return !provider.mobile;
    					});
    					providers.forEach(function (provider) {
    						// Se houver o mobile usa ele como padrão
    						if (provider.mobile) {
    							$scope.mobile = true;
    							$scope.mobileTracker = provider;
    							if ( ! $scope.alreadyTracked ) {
    								$scope.tracker.provider = provider;
    								$scope.tracker.mobileSignal = true;
    							}
    							return false;
    						}
    					});
    					if (!$scope.mobile) {
    						if ( ! $scope.alreadyTracked ) {
    							$scope.tracker.provider = $scope.thirdPartyProviders[0];
    						}
    						$scope.mobile = false;
    					}

                        $scope.toggleMobile = function (mobile) {
                            $scope.mobile = mobile;
                            if (mobile) {
                                $scope.tracker.thirdPartyProvider = $scope.tracker.provider;
                                $scope.tracker.provider = $scope.mobileTracker;
                            } else {
                                $scope.tracker.provider = $scope.tracker.thirdPartyProvider;
                            }
                        };

                        $scope.getProviders = function () {
                            if ($scope.tracker.plate) {
                                return $scope.thirdPartyProviders.filter(function (provider) {
                                    return provider.id === $scope.tracker.plate.providerId;
                                });
                            }
                            return $scope.thirdPartyProviders;
                        };

                        $scope.getPlates = function (viewValue) {
                            return trackingService.findByDescriptionLike(viewValue, $scope.tracker.provider ?
                                [$scope.tracker.provider.id] : $scope.thirdPartyProviders.map(function (provider) {
                                return provider.id;
                            }));
                        };

                        $scope.getDrivers = function (viewValue) {
                            return trackingMobileService.findDrivers(viewValue);
                        };

                        $scope.matchPlateAndProvider = function () {
                            if ($scope.tracker.plate && $scope.tracker.provider) {
                                if ($scope.tracker.plate.providerId !== $scope.tracker.provider.id) {
                                    $scope.tracker.plate = null;
                                }
                            }
                        };

                        $scope.selected = function () {
                        	if ( $scope.alreadyTracked ) {
                        		return !!$scope.tracker.driver;
                        	}
                        	if ( $scope.alreadyHasDriver ) {
                        		return !!$scope.tracker.provider && ( ! $scope.tracker.provider.mobile || $scope.tracker.mobileSignal );
                        	}
                            return $scope.tracker.provider && ( ( $scope.tracker.provider.mobile && $scope.tracker.driver ) ||
                                ( !$scope.tracker.provider.mobile && $scope.tracker.plate && ($scope.tracker.driver || !$scope.isDriverMandatory) ) );
                        };
                        
                        $scope.ok = function() {
                        	if( $scope.alreadyTracked ) {
                        		$scope.tracker.provider = null;
                        	}
                        	var driver = $scope.tracker.driver;
                    		if( $scope.alreadyHasDriver ) {
                        		$scope.tracker.driver = null;
                        	}
                        	if ( $scope.mobile && ! $scope.alreadyTracked ) {
                        		loadingService(trackingMobileService.findTruckDeviceByDriver( driver ))
									.catch(function (error) {
										if (error && error.status === 400) {
											return messagesModal("dialog.error", "trackingMobile.exception.tripDriverAppNotConfigured")
												.then($q.reject);
										}
										return remoteExceptionHandler()();
                                    })
                        			.then(function(device) {
                        				$scope.tracker.plate = device;
                        				return $scope.$close($scope.tracker);
                        			});
                        	} else {
                        		return $scope.$close($scope.tracker);
                        	}
                        };
                    }]
                }).result;
            });
        };
    }]);
});