define([
    "./fetcherModule"
], function (module) {
    "use strict";

    return module.constant("DataFetcherType", {
        TRANSITION: "TRANSITION",
        DRIVER: "DRIVER",
        OCCURRENCE: "OCCURRENCE",
        EXTENSION: "EXTENSION",
        COMMENTS: "COMMENTS",
        CHILDREN: "CHILDREN",
        INVOICE_ITEMS: "INVOICE_ITEMS",
        ROOT: "ROOT",
        TRACKING_DETAILS: "TRACKING_DETAILS"
    });
});