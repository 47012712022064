define(["angular",
    "angular-simple-logger",
    "../maps/mapsModule",
    "../toll/toll",
    "../cancellation/cancellation",
    "../optimization/optimization",
    "../moveTripsToUser/moveTripsToUser",
    "../moveToAnotherUser/moveToAnotherUser",
    "../tripPickingList/tripPickingList"
], function (angular) {
    "use strict";

    return angular.module("nodeCommandsModule", [
        "mapsModule",
        "tollModule",
        "commonsCancellationModule",
        "commonsOptimizationModule",
        "moveTripsToUserModule",
        "moveToAnotherUserModule",
        "arrangementModule",
        "tripPickingListModule"
    ]);
});