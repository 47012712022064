import transitionsConfigModule from "./transitionsConfigModule"
import transitionsConfigView from "./transitionsConfigView.html"

transitionsConfigModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.transitionsConfig", {
        data: {
            pageTitle: "{{'title.r2.transitionsConfig' | translate}}"
        },
        url: "/transitions-config",
        template: transitionsConfigView,
        controller: "transitionsConfigController"
    })
}])