import panelModule from "./r2PanelModule"
import viewsPanel from "./viewsPanel.html"
import angular from "angular"

panelModule.directive("nlgViewsPanel", function () {
    return {
        restrict: "E",
        template: viewsPanel,
        scope: {
            firstViewTitle: "@",
            secondViewTitle: "@",
        },
        transclude: {
            firstView: "firstView",
            secondView: "secondView"
        },
        controller($scope, $element, $window) {

            $scope.gridView = false
            $scope.halfWidthBasket = false
            $scope.halfWidthTripList = false

            $scope.showBasket = {
                "order": true,
                "trip": true
            }

            $scope.$watchCollection("showBasket", function () {
                $scope.selectedBaskets = []
                angular.forEach($scope.showBasket, function (value, key) {
                    if (value) {
                        $scope.selectedBaskets.push(key)
                    }
                })
                updateHalfWidthState()
            })

            $scope.shouldDisabled = (basket) => {
                return $scope.selectedBaskets.length === 1 && $scope.selectedBaskets.indexOf(basket) !== -1
            }

            $scope.getPanelMaxHeight = function () {
                let windowHeight = $window.innerHeight
                let firstPanelTop = $element.find(".viewPanelContents").offset().top
                let buttonsBarHeight = $element.siblings().outerHeight(true)
                let buttonsBarMargin = 10
                let marginInsidePanel = 63
                let marginOutsidePanel = 10
                let heightSections = getNumberOfHeightSections()
                let blankSpace = windowHeight - firstPanelTop - buttonsBarHeight - buttonsBarMargin - (marginInsidePanel + marginOutsidePanel) * heightSections
                let basketContainerHeight = blankSpace / heightSections + "px"
                angular.element("nlg-basket")
                    .parent()
                    .css({
                        "max-height": basketContainerHeight,
                        height: basketContainerHeight
                    })
            }
            angular.element($window).resize($scope.getPanelMaxHeight)

            $scope.useListView = function () {
                $scope.gridView = false
                updateHalfWidthState()
            }

            $scope.useGridView = function () {
                $scope.gridView = true
                updateHalfWidthState()
            }

            function getNumberOfHeightSections() {
                return $scope.gridView ? Math.max($scope.selectedBaskets.length - 1, 1) : $scope.selectedBaskets.length
            }

            function updateHalfWidthState() {
                $scope.halfWidthBasket = $scope.gridView && $scope.selectedBaskets.length === 2
                $scope.halfWidthTripList = $scope.gridView && $scope.showBasket.trip && $scope.selectedBaskets.length === 2
            }
        }
    }
})