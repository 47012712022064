define([
    "./transitionFailureReasonModule",
    "text!./search/transitionFailureReasonSearchView.html",
    "text!./edition/transitionFailureReasonEditionView.html"
], function (module, transitionFailureReasonSearchTemplate, transitionFailureReasonEditionTemplate) {
    "use strict";
    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.newTransitionFailureReason", {
                url: "/new-transition-failure-reason",
                controller: "TransitionFailureReasonControllerEdition",
                template: transitionFailureReasonEditionTemplate,
                data: {
                    group: "group.monitoring.transition.failure.reason",
                    pageTitle: "{{'title.monitoring.transitionFailureReason' | translate:$stateParams}}"
                },
                resolve: {
                    transitionFailureReason: ["monitoringService", function (monitoringService) {
                        return monitoringService.getNewInstanceTransitionFailureReason();
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.transitionFailureReasonSearch", {
                url: "/transition-failure-reason?reload",
                controller: "TransitionFailureReasonControllerSearch",
                template: transitionFailureReasonSearchTemplate,
                data: {
                    group: "group.monitoring.transition.failure.reason",
                    pageTitle: "{{'title.monitoring.transitionFailureReason' | translate:$stateParams}}"
                },
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
            })
            .state("monitoring.transitionFailureReasonEdition", {
                url: "/transition-failure-reason/:transitionFailureReasonId",
                controller: "TransitionFailureReasonControllerEdition",
                template: transitionFailureReasonEditionTemplate,
                data: {
                    group: "group.monitoring.transition.failure.reason",
                    pageTitle: "{{'title.monitoring.transitionFailureReason' | translate:$stateParams}}"
                },
                resolve: {
                    transitionFailureReason: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.findTransitionFailureReasonById($stateParams.transitionFailureReasonId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.transitionFailureReasonCopy", {
                url: "/transition-failure-reason/copy/:transitionFailureReasonId",
                controller: "TransitionFailureReasonControllerEdition",
                template: transitionFailureReasonEditionTemplate,
                data: {
                    group: "group.monitoring.transition.failure.reason",
                    pageTitle: "{{'title.monitoring.transitionFailureReason' | translate:$stateParams}}"
                },
                resolve: {
                    transitionFailureReason: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.copyTransitionFailureReason($stateParams.transitionFailureReasonId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
    }]);
});
