import r2Module from "./r2Module"

r2Module.config(($stateProvider) => {
    $stateProvider.state("r2", {
        url: "/r2",
        template: "<nlg-r2></nlg-r2>",
        abstract: true
    }).state("userManagement", {
        parent: "r2",
        url: "/usermanagement",
        abstract: true,
        template: "<ui-view></ui-view>"
    }).state("monitoring", {
        parent: "r2",
        url: "/monitoring",
        template: "<ui-view></ui-view>",
        abstract: true
    }).state("tracking", {
        parent: "r2",
        url: "/tracking",
        abstract: true,
        template: "<ui-view></ui-view>"
    }).state("trackingMobile", {
        parent: "r2",
        url: "/trackingmobile",
        abstract: true,
        template: "<ui-view></ui-view>"
    }).state("monitor", {
        parent: "r2",
        url: "/monitor",
        template: "<ui-view></ui-view>",
        abstract: true
    }).state("lgpd", {
        parent: "r2",
        url: "/lgpd",
        template: "<ui-view></ui-view>",
        abstract: true
    })
})