define([
    "./monitorableModule",
     "text!./monitorableItemExtensionDialog.html"],
    function (monitorableModule, template) {
        "use strict";

        return monitorableModule.service("monitorableItemExtensionDialog", [
            "$http",
            "$modal",
            "$state",
            function ($http, $modal, $state) {
                return function (item) {

                    return $modal.open({
                        template: template,
                        size: "md",
                        controller: ["$scope", function ($scope) {

                            $scope.item = item;
                            $scope.goToMonitorable = function (id) {
                                $scope.$close();
                                return $state.go("monitoring.monitorableEdition", {
                                    monitorableId: id
                                });
                            };
                        }]
                    });
                };
            }]);
    });