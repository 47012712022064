define([
    "./monitoringModule",
    "./monitoringConfiguration",
    "./monitoringRouter",
    "./monitoringService",
    "./mbrService",
    "./filter/monitoringFilterService",
    "./dashboard/monitoringDashboardService",
    "./phonenumber",
    "./iconConfiguration/nlgTagInputIconMapMonitoring",
    "./document/monitoring/MonitoringPanelController",
    "./query/monitoringQueryController",
    "./tripOverview/monitoringTripOverviewController",
    "./tripStartOverview/monitoringTripStartOverviewController",
    "./monitorableTemperatureOverview/monitoringTemperatureOverviewController",
    "./monitorableFollowupDashboardOverview/monitorableFollowupDashboardOverviewController",
    "./occurrenceOverview/monitoringOccurrenceOverviewController",
    "./carrierTripOverview/monitoringCarrierTripOverviewController",
    "./invoiceDeliveryEffectivenessOverview/monitoringInvoiceDeliveryEffectivenessOverviewController",
    "./query/search/monitoringQuerySearchController",
    "./query/edition/monitoringQueryEditionController",
    "front-end-web-commons",
    "./yardControl/monitoringYardControlController",
    "./email/template/emailTemplateController",
    "./virtualFence/virtualFenceController"
], function() {
});
