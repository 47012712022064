define(["./nlgDualListModule", "text!./nlgDualList.html", "../arrays/arrays", "lodash"], function (nlgDualListModule, nlgDualListTemplate, arrays, _) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:nlgDualList
     * @description
     * Diretiva que exibe duas listas, lado a lado, onde a lista esquerda contém todas
     * as possibilidades de seleção, e a lista a direita contém os itens selecionados
     * pelo usuário.
     *
     * @param {object[]=} nlgDualList Lista com valores disponíveis, exibidos à esquerda.
     * @param {object[]=} ignoredData remove dados da lista de esquerda.
     * @param {object[]=} ngModel Lista com os itens selecionado pelo usuário, exibidos à direita.
     * @param {string=} leftTitle Título acima da lista de disponíveis.
     * @param {string=} rightTitle Título acima da lista de itens selecionados.
     * @param {boolean=} showReorder Exibe na lista de selecionados botões para fazer reordenação dos itens.
     * @param {expression=} canMoveLeft Expressão que retorna true caso seja possível mover para a esquerda.
     * @param {expression=} canMoveRight Expressão que retorna true caso seja possível mover para a direita.
     * @param {function=} whenMoveToRight Expressão que é chamada quando botões de movimentações para à direita são clicados, passando, os elementos que foram movidos.
     * @param {function=} whenMoveToLeft Expressão que é chamada quando botões de movimentações para à esquerda são clicados, passando, os elementos que foram movidos.
     * @param {function=} disableRight Expressão booleana executada para cada ´option´ responsável por indicar se este pode ser movimentado. Aplicado sobre o modelo selecionado.
     *
     * @example
     * <example module="FrontEndWeb">
         <file name="index.html">
              <div ng-controller="example">
                 <div nlg-dual-list="entities" ng-model="selection" left-title="Disponíveis" right-title="Selecionados" show-reorder="true"></div>
                 <ul>
                     <li ng-repeat="item in selection">{{ item | entityFormat }}</li>
                 </ul>
              </div>
          </file>
          <file name="index.js">
              angular.module("FrontEndWeb").controller("example", function ($scope) {
                   $scope.entities = (function () {
                       var result = [], i;
                       for (i = 0; i < 20; i++) {
                           result.push({ sourceId: "Código " + i });
                       }
                       return result;
                   }());
              });
          </file>
      </example>
     * */
    return nlgDualListModule.directive("nlgDualList", [function () {
        return {
            restrict: "A",
            template: nlgDualListTemplate,
            scope: {
                dataSource: "=?nlgDualList",
                ngModel: "=?ngModel",
                ngDisabled: "=?ngDisabled",
                ignoredData: "=?ignoredData",
                leftTitle: "@?leftTitle",
                rightTitle: "@?rightTitle",
                showReorder: "@?showReorder",
                canMoveLeft: "&?canMoveLeft",
                canMoveRight: "&?canMoveRight",
                whenMoveToRight: "&?whenMoveToRight",
                whenMoveToLeft: "&?whenMoveToLeft",
                disableRight: "&?disableRight"
            },
            controller: ["$scope", "$q", "entityFormatFilter", "entityFilterFilter", "orderByFilter", function ($scope, $q, entityFormat, entityFilter, orderBy) {
                $scope.ngModel = $scope.ngModel || [];
                $scope.ngDisabled = $scope.ngDisabled || false;
                $scope.ignoredData = $scope.ignoredData || [];
                $scope.reorder = {show: $scope.$eval($scope.showReorder) || false };

                $scope.originalData = [];
                $scope.availableItens = [];
                $scope.disableRight = $scope.disableRight || function () {
                        return false;
                    };

                $scope.formatOption = function (option) {
                    return entityFormat(option);
                };

                $scope.filterAndSort = function (ngModel) {
                    var filter = entityFilter(ngModel, $scope.filterRight);
                    if (!$scope.showReorder) {
                        return orderBy(filter, $scope.formatOption);
                    }
                    return filter;
                };

                updateDataSource();
                $scope.$watch("dataSource", updateDataSource);
                $scope.$watch("ignoredData", updateDataSource);

                function updateDataSource() {
                    if ($scope.dataSource && $scope.dataSource.length) {
                        $scope.originalData = arrays.minus($scope.dataSource, $scope.ignoredData);
                    } else {
                        $scope.originalData = arrays.copy($scope.ngModel || []);
                    }
                    $scope.availableItens = filterLeftItens();
                }

                var disabledMap = {};
                $scope.$watchCollection("ngModel", function (selectedOptions) {
                    disabledMap = {};
                    selectedOptions.forEach(function (option) {
                        if (_.isObject(option) && $scope.disableRight) {
                            disabledMap[entityFormat(option)] = !isOptionEditable(option);
                        }
                    });
                    $scope.availableItens = filterLeftItens();
                });

                function isOptionEditable(option) {
                    return !$scope.disableRight({option: option});
                }

                $scope.isOptionDisabled = function (option) {
                    return disabledMap[entityFormat(option)];
                };

                function filterLeftItens() {
                    return arrays.minus($scope.originalData, $scope.ngModel);
                }

                $scope.left = {
                    selection: []
                };
                $scope.right = {
                    selection: []
                };

                $scope.moveSelectionToRight = function () {
                    var selection = $scope.left.selection;
                    doMoveRight(selection);
                };

                $scope.moveAllToRight = function () {
                    var selection = $scope.availableItens;
                    doMoveRight(selection);
                };

                function doMoveRight(selection) {
                    canMoveRight(selection).then(function () {
                        appendSelectionToModel(selection);
                        updateAvailableList(selection, $scope.left, $scope.right);
                        whenMoveToRight(selection);
                    });
                }

                function appendSelectionToModel(selection) {
                    arrays.addAll($scope.ngModel, selection);
                }

                $scope.moveSelectionToLeft = function () {
                    var selection = $scope.right.selection;
                    canMoveLeft(selection).then(function () {
                        arrays.each(selection, function (object) {
                            arrays.remove($scope.ngModel, object);
                        });
                        updateAvailableList(selection, $scope.right, $scope.left);
                        whenMoveToLeft(selection);
                    });
                };

                $scope.moveAllToLeft = function () {
                    canMoveLeft($scope.ngModel).then(function () {
                        var editableModel = _.filter($scope.ngModel, isOptionEditable);
                        whenMoveToLeft(editableModel);
                        $scope.ngModel = _.differenceWith($scope.ngModel, editableModel, _.isEqual);
                        updateAvailableList(editableModel, $scope.right, $scope.left);
                    });
                };

                function canMoveLeft(selection) {
                    if (!$scope.canMoveLeft) {
                        return $q.resolve();
                    }
                    return $q.when($scope.canMoveLeft({selection: selection})).then(failOnFalse);
                }

                function canMoveRight(selection) {
                    if (!$scope.canMoveRight) {
                        return $q.resolve();
                    }
                    return $q.when($scope.canMoveRight({selection: selection})).then(failOnFalse);
                }

                function failOnFalse(result) {
                    if (!result) {
                        return $q.reject();
                    }
                }

                function updateAvailableList(selection, fromList, toList) {
                    toList.selection = selection;
                    fromList.selection = [];
                    $scope.availableItens = filterLeftItens();
                }

                function whenMoveToRight(selection) {
                    if ($scope.whenMoveToRight) {
                        $scope.whenMoveToRight({
                            selection: arrays.copy(selection)
                        });
                    }
                }

                function whenMoveToLeft(selection) {
                    if ($scope.whenMoveToLeft) {
                        $scope.whenMoveToLeft({
                            selection: arrays.copy(selection)
                        });
                    }
                }

                $scope.moveSelectionToUp = function () {
                    move($scope.right.selection, move.UP);
                };

                $scope.moveSelectionToDown = function () {
                    move(arrays.copy($scope.right.selection).reverse(), move.DOWN);
                };

                move.UP = -1;
                move.DOWN = 1;
                function move(selection, direction) {
                    var i;
                    for (i = 0; i < selection.length; i++) {
                        var oldIndex = $scope.ngModel.indexOf(selection[i]);
                        var upperIndex = oldIndex + direction;
                        if (upperIndex < 0 || upperIndex >= $scope.ngModel.length) {
                            return;
                        }
                        arrays.swap($scope.ngModel, upperIndex, oldIndex);
                    }
                }
            }]
        };
    }]);
});