define(["../occurrenceCategoryModule"],
    function (occurrenceCategoryModule) {
        "use strict";

        return occurrenceCategoryModule.controller("OccurrenceCategoryEditionController", [
            "$scope",
            "$state",
            "occurrenceCategory",
            "$q",
            "monitoringService",
            "occurrenceCategoryService",
            function ($scope, $state, occurrenceCategory, $q, monitoringService, occurrenceCategoryService) {
                $scope.entity = occurrenceCategory;

                monitoringService.getContentExclusion()
                    .then(function (response) {
                        $scope.contentExclusion = response;
                    });

                $scope.save = function () {
                    return occurrenceCategoryService.saveOccurrenceCategory($scope.entity)
                        .then(function (id) {
                            return $state.go("monitoring.OccurrenceCategoryEdition", {
                                occurrenceCategoryId: id
                            });
                        });
                };

                $scope.saveAndContinue = function () {
                    return occurrenceCategoryService.saveOccurrenceCategory($scope.entity)
                        .then(function () {
                            return $state.go($state.current, {reload: Date.now()});
                        });
                };

                $scope.back = function () {
                    return $state.go("monitoring.OccurrenceCategorySearch", {
                        reload: true
                    });
                };
            }
        ]);
    });