define([
    "../nlgHealthCheckerModule",
    "text!./nlgHealthCheckerBarReason.html",
    "lodash",
    "moment"
], function (module, template, _, moment) {
    "use strict";
    return module.directive("nlgHealthCheckerBarReason", ["$translate", function ($translate) {
        return {
            restrict: "E",
            template: template,
            scope: {
                reason: "<"
            },
            controller: ["$scope", "typeFormatService", "dateFilter", function ($scope, typeFormatService, dateFilter) {
                $scope.barChartInput = {
                    configs: {
                        type: "bar",
                        properties: {
                            valueType: typeFormatService.convertTypeFormatToChartLocateName($scope.reason.typeFormat),
                            rank: $scope.reason.rank,
                            translateLabels: "true"
                        }
                    },
                    data: []
                };
                $scope.barChartInput.data = configureBarChart($scope.reason.datas);

                function configureBarChart(datas) {
                    datas.sort(function (a, b) {
                        return a.label.localeCompare(b.label);
                    });
                    datas.sort().reverse();
                    var result = {};
                    _.forEach(datas, function (barData) {
                        var label = formatLabel(barData, $scope.reason.labelFormat);
                        var value = barData.value;
                        result[label] = (result[label] || 0) + value;
                    });
                    return _.map(result, function (value, key) {
                        return {
                            label: key,
                            value: value
                        };
                    });

                    function formatLabel(barData, labelFormat) {
                        switch (labelFormat) {
                            case "br.com.neolog.healthcheck.api.reason.bar.LabelFormat.WEEK":
                                var label = barData.label;
                                var header = $translate.instant("charting.nameLabel.chart.week");
                                return header + " " + getWeekOfMonth(label) + " " + moment(label).format("MMM/YY");
                            case "br.com.neolog.healthcheck.api.reason.bar.LabelFormat.DATE":
                                return dateFilter(barData.label, "shortDate");
                            case "br.com.neolog.healthcheck.api.reason.bar.LabelFormat.DATE_TIME":
                                return dateFilter(barData.label, "short");
                            case "br.com.neolog.healthcheck.api.reason.bar.LabelFormat.TIME":
                                return dateFilter(barData.label, "shortTime");
                            default:
                                return barData.label;
                        }
                    }

                    function getFirstDateOfMonth(date) {
                        return new Date(date.getFullYear(), date.getMonth(), 1);
                    }

                    function getWeekOfMonth(data) {
                        var date = new Date(data);
                        var firstDayOfMonth = getFirstDateOfMonth(date);
                        // O incremento serve pra que as semanas não comecem com 0
                        return Math.floor((firstDayOfMonth.getDay() + date.getDate() - 1) / 7) + 1;
                    }
                }
            }]
        };
    }]);
});