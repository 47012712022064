define([
    "./remoteMessageModule",
    "text!./remoteMessageModal.html",
    "angular",
    "lodash"
], function (module, template, angular, _) {
    "use strict";

    return module.service("remoteMessageModal", ["$modal", function ($modal) {

        function showModal(header, successMessageList, errorMessageList) {
            return $modal.open({
                template: template,
                controller: ["$scope", function ($scope) {
                    $scope.header = header;
                    $scope.successMessageList = _.isArray(successMessageList) ? successMessageList : [successMessageList];
                    $scope.errorMessageList = _.isArray(errorMessageList) ? errorMessageList : [errorMessageList];
                    $scope.successAndErrorsMessages = $scope.successMessageList.length && $scope.errorMessageList.length;
                    $scope.getBundle = function (message) {
                        return message.keyBundle || message;
                    };
                }]
            }).result;
        }

        return function (remoteMessages) {
            var resultGroup = _.groupBy(
                remoteMessages
                    .map(function (remoteMessage) {
                        if (isEmptyMessage(remoteMessage)) {
                            return;
                        }
                        return {
                            keyBundle: remoteMessage.description,
                            parameters: [remoteMessage.sourceId],
                            result: remoteMessage.result
                        }
                    }), 'result');

            if(isEmptyMessages(remoteMessages)){
                return showModal("dialog.result", {  keyBundle: "entity.validator.noModification"}, []);
            }

            var successMessages = resultGroup.true || [];
            var failureMessages = resultGroup.false || [];
            var isSuccess = !failureMessages.length;
            var header = isSuccess ? "dialog.success" : "dialog.error";
            if (successMessages.length && failureMessages.length) {
                header = "dialog.result";
            }

            return showModal(header, successMessages, failureMessages).catch(angular.noop)
                .then(function () {
                    if (isSuccess) {
                        return successMessages;
                    }
                    return failureMessages;
                });
        }
    }]);

    function isEmptyMessages(remoteMessages) {
        var isEmptyMessages = true;
        _.forEach(remoteMessages, function (remoteMessage) {
            if (!isEmptyMessage(remoteMessage)) {
                isEmptyMessages = false;
            }
        });
        return isEmptyMessages;
    }

    function isEmptyMessage(remoteMessage) {
        return isNullOrEmpty(remoteMessage.description) && isNullOrEmpty(remoteMessage.sourceId && isNullOrEmpty(remoteMessage.code));
    }

    function isNullOrEmpty(string) {
        return string === null || string === "";
    }
});