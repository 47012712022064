define(["./occurrenceListenerModule", "json!../tdnByEntity.json",], function (module, tdnByEntity) {
    "use strict";

    return module.controller("MBROccurrenceListenerController", [
        "$scope",
        "MBROccurrenceListenerParamModalService",
        "mbrService",
        "tdnHelp",
        function ($scope, modal, mbrService, tdnHelp) {
            $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["mbr-occurrence"];
            $scope.openModal = function (index) {
                modal($scope.occurrenceListeners[index]);
            };

            $scope.occurrenceListeners = [];
            mbrService.getOccurrenceListeners()
                .then(function (response) {
                    $scope.occurrenceListeners = response.data;
                });
        }]);
});