define([
    "./monitorModule",
    "angular",
    "text!./nlgMonitorLogTree.html"
], function (monitorModule, angular, template) {
    "use strict";

    return monitorModule.directive("nlgMonitorLogTree", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                instance: "=",
                onSelectFile: "&?",
                folder: "<?"
            },
            controller: [
                "$scope",
                "monitorService",
                "loadingService",
                "remoteExceptionHandler",
                "$q",
                function ($scope, monitorService, loadingService, remoteExceptionHandler, $q) {
                    $scope.subDirectories = [];
                    $scope.directory = !$scope.folder || $scope.folder.directory;

                    $scope.folderClicked = function () {
                        if ($scope.directory) {
                            return expand();
                        }
                        fileClicked();
                        return $q.resolve(null);
                    };

                    function fileClicked() {
                        ($scope.onSelectFile || angular.noop)({
                            file: getFileName($scope.folder)
                        });
                    }

                    function expand() {
                        if ($scope.subDirectories.length !== 0) {
                            $scope.subDirectories = [];
                            return $q.resolve([]);
                        }
                        return loadingService(monitorService.findLogFiles($scope.instance, getFileName($scope.folder)))
                            .catch(remoteExceptionHandler())
                            .then(function (subDirectories) {
                                $scope.subDirectories = subDirectories.map(function (sub) {
                                    sub.$parent = $scope.folder;
                                    return sub;
                                });
                                return $scope.subDirectories;
                            });
                    }

                    $scope.download = function () {
                        return loadingService(monitorService.downloadLogFile($scope.instance, getFileName($scope.folder)))
                            .catch(remoteExceptionHandler());
                    };

                    function getFileName(folder) {
                        if (!folder) {
                            return ".";
                        }
                        return getFileName(folder.$parent) + "/" + folder.fileName;
                    }
                }]
        };
    }]);
});