define([
    "../nlgLGPDModule",
], function (lgpdModule) {
    "use strict";

    return lgpdModule.controller("LGPDGenericParamControllerEdition", [
        "$scope",
        "LGPDGenericParamService",
        "lgpdGenericParamDefinition",
        "$state",
        "remoteExceptionHandler",
        "messagesModal",
        function ($scope, genericParamService, genericParamDefinition,$state, remoteExceptionHandler, messagesModal) {

            $scope.entities = getEntities();
            $scope.entity = genericParamDefinition;

            $scope.serviceName = "LGPDGenericParamService";
            $scope.accessReference = "lgpd.logLgpdGenericParam";

            function getEntities() {
                genericParamService.getDefinitions()
                    .then(function (result) {
                        $scope.entities = result.data;
                        $scope.createMap();
                    });
            }

            $scope.fieldsByEntity = new window.Map();
            $scope.createMap = function () {
                $scope.entities.forEach(function (entity) {
                    $scope.fieldsByEntity[entity.entityName] = $scope.fieldsByEntity[entity.entityName] || [];
                    $scope.fieldsByEntity[entity.entityName].push(entity.entityField);
                });
            };

            $scope.getEntitiesName = function () {
                return Object.keys($scope.fieldsByEntity);
            };

            $scope.getEntityFields = function (entity) {
                return $scope.fieldsByEntity[entity.entityName];
            };

            $scope.getTag = function (tag) {
                if (!tag) return "";
                return tag;
            };

            $scope.onChange = function (entity) {
                if (entity.entityName) {
                    $scope.entity = entity;
                }else{
                    $scope.entity.entityField = null;
                }
            };

            $scope.save = function () {
                genericParamService.save($scope.entity)
                    .then(function (result) {
                        $scope.entity = result.data;
                        messagesModal("dialog.success", [{
                            keyBundle: "lgpd.edition.save.success",
                            parameters: []
                        }]).then(function () {
                            $state.go("lgpd.editLgpdGenericParam", {
                                reload: true,
                                lgpdDefinitionId: $scope.entity.id
                            });
                        });
                    }).catch(remoteExceptionHandler());
            };

            $scope.back = function () {
                return $state.go("lgpd.lgpdGenericParam", {
                    reload: true
                });
            };

            $scope.saveAndContinue = function () {
                genericParamService.save($scope.entity)
                    .then(function (result) {
                        $scope.entity = result.data;
                        messagesModal("dialog.success", [{
                            keyBundle: "lgpd.edition.save.success",
                            parameters: []
                        }]).then(function () {
                            $state.go($state.current, {reload: Date.now()});
                        });
                    }).catch(remoteExceptionHandler());
            };

            $scope.canSave = function () {
                if ($state.params.version) {
                    return false;
                }
                return $scope.entity.entityName
                    && $scope.entity.entityField
                    && $scope.entity.personal
                    && $scope.entity.justification;
            };
        }]);
});
