import allowedOriginsModule from "./allowedOriginsModule"
import allowedOriginsView from "./allowedOriginsView.html"

allowedOriginsModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.allowedOrigins", {
        data: {
            pageTitle: "{{'title.r2.allowedOrigins' | translate}}"
        },
        url: "/allowedOrigins",
        template: allowedOriginsView,
        controller: "allowedOriginsController"
    })
}])