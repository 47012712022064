define([
    "./monitoringDriverModule"
], function (driverModule) {
    "use strict";

    driverModule.service("monitoringDriverService", [
        "monitoringService",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        function (monitoringService, loadingService, remoteExceptionHandler, messagesModal) {
            var self = this;

            self.deleteDriver = function (driver) {
                return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                    .then(function () {
                        return loadingService(monitoringService.deleteDriver(driver.id))
                            .catch(remoteExceptionHandler());
                    }).then(function () {
                        return messagesModal("dialog.success", ["trackingMobile.driver.delete.success"])
                    });

            };

            self.saveDriver = function (driver) {
                return loadingService(monitoringService.saveOrUpdateDriver(driver))
                    .catch(remoteExceptionHandler())
                    .then(function (driverId) {
                        return messagesModal("dialog.success", ["entity.validator.successMessage"])
                            .then(function () {
                                return driverId;
                            })
                    });
            };
            return self;
        }]);
});
