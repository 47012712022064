define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryRadioTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no `radio` associado
     **/

    /**
     * @ngdoc function
     * @name templateFactoryRadioTemplate.templateFactoryRadioTemplate
     * @description
     * Template utilizado para criar um `input` do tipo `radio`.
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     **/
    return templateFactoryModule.service("templateFactoryRadioTemplate", [function () {
        return function (config) {
            var element = angular.element("<input>").attr("type", "radio");
            if (config.model) {
                element.attr("ng-model", config.model);
            }
            if (config.checked) {
                element.attr("ng-checked", config.checked);
            }
            if (config.indeterminate) {
                element.attr("nlg-indeterminate", config.indeterminate);
            }
            if(config.disabled) {
                element.attr("ng-disabled", config.disabled);
            }
            if(config.click){
                element.attr("ng-click", config.click);
            }
            if(config.onChange){
                element.attr("ng-change", config.onChange);
            }
            return element;
        };
    }]);
});