define([
    "./occurrenceCategoryModule"
], function (occurrenceCategoryModule) {
    "use strict";

    occurrenceCategoryModule.service("occurrenceCategoryService", [
        "monitoringService",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        function (monitoringService, loadingService, remoteExceptionHandler, messagesModal) {
            var self = this;

            self.deleteOccurrenceCategory = function (id) {
                return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                    .then(function () {
                        return loadingService(monitoringService.deleteOccurrenceCategory(id))
                            .catch(remoteExceptionHandler());
                    }).then(function () {
                        return messagesModal("dialog.success", {
                            keyBundle: "entityFilter.delete.ok"
                        });
                    });
            };

            self.saveOccurrenceCategory = function (occurrenceCategory) {
                return loadingService(monitoringService.saveOccurrenceCategory(occurrenceCategory))
                    .catch(remoteExceptionHandler())
                    .then(function (id) {
                        return messagesModal("dialog.success", {
                            keyBundle: "monitoring.occurrence.category.save.success"
                        }).then(function () {
                            return id;
                        });
                    });
            };
            return self;
        }]);
});