import autocompleteModule from "./autocompleteModule"
import angular from "angular"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"
import server from "../server"

autocompleteModule.service("autocompleteFactory", ["$http", function ($http) {

    this.lazyLoader = (service, dependencies, additionalInfo) => {
        if (!service) {
            throw new Error("service must be defined. Got: " + service)
        }

        additionalInfo = additionalInfo || {}
        dependencies = dependencies || []
        if (!arrays.isArray(dependencies)) {
            dependencies = [dependencies]
        }

        return function (viewValue, dinamicDependencies, dinamicAdditionalInfo) {
            if (angular.isDefined(dinamicAdditionalInfo)) {
                additionalInfo = dinamicAdditionalInfo
            }

            if (angular.isDefined(dinamicDependencies)) {
                dependencies = dinamicDependencies

                if (!arrays.isArray(dependencies)) {
                    dependencies = [dependencies]
                }
            }

            return $http.post(server.getAutocompleteUrl(service) + "find", {
                additionalInfo: additionalInfo,
                dependencies: dependencies.map((value) => {
                    if (!angular.isDefined(value)) {
                        return null
                    }
                    if (angular.isFunction(value)) {
                        return value()
                    }
                    return value
                }).filter((value) => {
                    return angular.isDefined(value) && value !== null
                })
            }, {params: {value: viewValue}})
                .then((req) =>req.data)
        }
    }

}])