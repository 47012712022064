define([
    "./macroModule",
    "text!./searchMacro.html",
    "text!./newMacro.html"
], function (module, macroSearchTemplate, macroEditionTemplate) {
    "use strict";
    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider
            .state("monitoring.newMacro", {
                url: "/newMacro",
                controller: "macroControllerEdition",
                template: macroEditionTemplate,
                data: {
                    group: "group.monitoring.macro",
                    pageTitle: "{{'title.monitoring.newMacro' | translate:$stateParams}}"
                },
                resolve: {
                    macro: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.getNewInstanceMacro();
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.macroSearch", {
                url: "/macro?reload",
                controller: "macroControllerSearch",
                template: macroSearchTemplate,
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.monitoring.macro",
                    pageTitle: "{{'title.monitoringMacroSearch' | translate:$stateParams}}"
                }
            })

            .state("monitoring.macroEdition", {
                url: "/macro/:macroId",
                controller: "macroControllerEdition",
                template: macroEditionTemplate,
                data: {
                    group: "group.monitoring.macro",
                    pageTitle: "{{'title.monitoring.macroEdition' | translate:$stateParams}}"
                },
                resolve: {
                    macro: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return $stateParams.macroId ? monitoringService.findMacroById($stateParams.macroId) : null;
                    }]
                },
                params: {
                    reload: "true"
                }
            })

            .state("monitoring.macroCopy", {
                url: "/macro/copy/:macroId",
                controller: "macroControllerEdition",
                template: macroEditionTemplate,
                data: {
                    group: "group.monitoring.macro",
                    pageTitle: "{{'title.monitoring.macroCopy' | translate:$stateParams}}"
                },
                resolve: {
                    macro: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.copyMacro($stateParams.macroId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
    }]);
});
