import angular from "angular"
import "../edition/edition"
import "front-end-web-commons"
import "angular-simple-logger"
import "angular-google-maps"
import "../autocomplete/autocomplete"

export default angular.module("localityModule", [
    "editionModule",
    "addressModule",
    "nlgPortletModule",
    "nlgTagInputModule",
    "nlgDualListModule",
    "functioningEditionModule",
    "timeModule",
    "panelModule",
    "nemLogging",
    "uiGmapgoogle-maps",
    "autocompleteModule"
])