define(["./numberModule"], function (numberModule) {
    "use strict";

    return numberModule.filter("bytes", ["$filter", function ($filter) {
        var numberFilter = $filter("number");
        return function (bytes) {
            if (!bytes || isNaN(bytes)) return "0 B";

            var k = 1024;
            var sizes = ["B", "KB", "MB", "GB", "TB"];

            var i = Math.floor(Math.log(bytes) / Math.log(k));
            var value = numberFilter(Math.floor(bytes / Math.pow(k, i))) + " " + sizes[i];
            if (i > 0) {
                value += " " + numberFilter(Math.floor((bytes % Math.pow(k, i)) / Math.pow(k, i - 1))) + " " + sizes[i - 1];
            }
            return value;
        };
    }]);
});