define(["./nlgTableModule", "../objects/objects"], function (nlgTableModule, objects) {
    "use strict";

    /**
     * @ngdoc directive
     * @name directives.directive:nlgTableSortableColumn
     * @element table
     * @restrict A
     * @description
     * Diretiva de ordenação a ser plugada no {@link directives.directive:nlgTable componente de tabela}.
     * Atrela o evento de `click` nos cabeçalhos da tabela para `toggle` de ordenação crescente e decrescente.
     *
     * @requires directives.directive:nlgTable
     */
    return nlgTableModule.directive("nlgTableSortableColumn", function () {
        return {
            restrict: "A",
            require: "^nlgTable",
            link: function (scope, element, attrs, nlgTableController) {
                var tableOptions = nlgTableController.tableOptions;
                tableOptions.sorting = tableOptions.sorting || {};
                tableOptions.sorting = objects.enrich(tableOptions.sorting, {
                    /**
                     * `flag` que indica se a ordenação deve ser decrescente ou não.
                     * Default é crescente, ou seja `false`.
                     */
                    descending: false
                });

                element.on("click", function () {
                    scope.$apply(function () {
                        sort(element, attrs.nlgTableSortableColumn);
                    });
                });

                function sort(headerElement, field) {
                    var descending = tableOptions.sorting.descending;
                    var predicate = tableOptions.sorting.predicate;

                    if (field === predicate) {
                        if (descending === false) {
                            // depois descendente
                            descending = true;
                        } else {
                            // depois limpa ordenação
                            descending = undefined;
                            predicate = undefined;
                        }
                    } else {
                        descending = false;
                        predicate = field;
                    }
                    headerElement.closest("[nlg-table]").find("i.nlgSortIcon").remove();

                    if (!objects.isUndefined(descending)) {
                        var sortClassToAdd = descending ? "nlgDescSort" : "nlgAscSort";
                        headerElement.prepend("<i class='nlgSortIcon " + sortClassToAdd + "'></i>");
                    }

                    tableOptions.sorting.descending = descending;
                    tableOptions.sorting.predicate = predicate;
                }
            }
        };
    });
});