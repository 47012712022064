define([
    "./chartingModule",
    "d3",
    "lodash",
    "datamaps"
], function (chartingModule, d3, _, Datamaps) {
    "use strict";

    chartingModule.directive("geoChart", [function () {
        return {
            restrict: "A",
            scope: {
                context: "=geoChart",
                size: "@?"
            },
            template: "<div><h3 class='chart-title' translate></h3></div>",
            link: function (scope, element) {
                var colorScale = d3.scale.category10(),
                    size = scope.size || 600;
                element.find("h3.chart-title").text(scope.context.configs.chartTitle);
                var datamap = new Datamaps({
                    scope: "bra",
                    element: element[0],
                    width: size,
                    height: size,
                    fills: calculateFills(),
                    setProjection: function () {
                        var projection = d3.geo.equirectangular()
                            .center([-53.1933905, -15.1043038])
                            .scale(size)
                            .translate([size / 2, size / 2]);
                        var path = d3.geo.path().projection(projection);
                        return {
                            path: path,
                            projection: projection
                        };
                    },
                    done: function (datamap) {
                        datamap.svg.call(d3.behavior.zoom().on("zoom", zoomIt));
                        function zoomIt() {
                            datamap.svg.selectAll("g")
                                .attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
                        }
                    }
                });

                displayMarkers(scope.context.data);

                scope.$watch(function () {
                    return scope.context.data;
                }, function (newContextData) {
                    datamap.options.fills = calculateFills();
                    datamap.draw();
                    displayMarkers(newContextData);
                    element.find(".datamaps-legend").remove();
                    if (newContextData.length) {
                        datamap.legend({legendTitle: scope.context.configs.legendTitle});
                    }
                }, true);

                function calculateFills() {
                    var fills = {};
                    _.uniq(_.map(scope.context.data, "label")).forEach(function (label) {
                        fills[label] = colorScale(label);
                    });
                    fills.defaultFill = "#abdda4";
                    return fills;
                }

                function displayMarkers(newContextData) {
                    var markers = [];
                    newContextData.forEach(function (item) {
                        markers.push({
                            fillKey: item.label,
                            name: item.label,
                            latitude: item.latitude,
                            longitude: item.longitude,
                            radius: 4
                        });
                    });
                    datamap.bubbles(markers, {
                        popupTemplate: function (geo, data) {
                            return "<div class='hoverinfo'><strong>" + data.name + "</strong>";
                        }
                    });
                }
            }
        };
    }]);
});