define([
    "./monitoringYardControlModule",
    "text!./monitoringYardControl.html"
], function (module, template) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {

        $stateProvider.state("monitoring.yardControl", {
            url: "/yardControl?reload",
            controller: "monitoringYardControlController",
            template: template,
            resolve: {
                reload: ["$stateParams", function ($stateParams) {
                    return $stateParams.reload === "true";
                }]
            },
            data: {
                group: "group.monitoring.yardControl",
                pageTitle: "{{'title.trackingMobile.yardControl' | translate:$stateParams}}"
            }
        });
    }]);
});