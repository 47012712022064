import {configParamsModule} from "./configParamsModule"
import {IStateProvider} from "angular-ui-router";

configParamsModule.config(($stateProvider: IStateProvider) => {
    $stateProvider.state("r2.configParams", {
        data: {
            pageTitle: "{{'title.configuration.server' | translate}}",
            group: "group.r2.configuration.advanced"
        },
        url: "/configParams",
        template: require("./configParamsView.html"),
        controller: "configParamsController",
        controllerAs: "ctrl",
    })
})