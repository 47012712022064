define(["./mapsModule"], function (mapsModule) {
    "use strict";

    return mapsModule.service("routeService", ["$http", "routeServiceUrl", function ($http, routeServiceUrl) {
        return {
            getRouteFromNodeIdentifiers: getRouteFromNodeIdentifiers,
            getRouteFromTripIds: getRouteFromTripIds
        };

        function getRouteFromNodeIdentifiers(nodeIdentifiers) {
            return $http.post(routeServiceUrl.getRouteUrlForNodeIdentifier(), nodeIdentifiers)
                .then(getData);
        }

        function getRouteFromTripIds(tripIds) {
            return $http.post(routeServiceUrl.getRouteUrlForTripIds(), tripIds).then(getData);
        }

        function getData(response) {
            return response.data;
        }
    }]);
});