define(["./nlgFilterFieldInputModule", "../arrays/arrays", "angular", "text!./nlgFilterFieldInputTemplate.html"],
    function (nlgLGPDModule, arrays, angular, template) {
        "use strict";

        return nlgLGPDModule.directive("nlgFilterFieldInput", [function () {
            // cria a tag html para o ie8
            document.createElement("nlg-single-tag-input");

            return {
                restrict: "E",
                template: template,
                scope: {
                    initialValue: "=?ngModel",
                    ngDisabled: "&",
                    fieldTemplate: "&?"
                },
                require: "ngModel",
                link: function ($scope, $element, $attrs, ngModelController) {
                    $scope.change = function () {
                        if (modelChanged()) {
                            ngModelController.$setViewValue(angular.copy($scope.model));
                        }
                    };

                    function modelChanged() {
                        return !angular.equals($scope.model, ngModelController.$viewValue);
                    }

                    ngModelController.$parsers.push(function(viewValue) {
                        return viewValue;
                    });

                    ngModelController.$formatters.push(function(modelValue) {
                        $scope.model.value = modelValue;
                        return modelValue;
                    });
                },
                controller: ["$scope",
                    function ($scope) {
                        $scope.ngDisabled = $scope.ngDisabled || false;
                        $scope.model = {
                            fieldTemplate : $scope.fieldTemplate,
                            value : $scope.initialValue
                        };
                    }]
            };
        }]);
    });

