define([
    "./mapsModule",
    "./mapsSetup",
    "./mapsConfigGetter",
    "./mapsModalFix",
    "./mapsDirectionsService",
    "./mapsRendererService",
    "./mapsLatLngApi",
    "./mapsRouteModal",
    "./mapControl",
    "./routeService",
    "./nlgPolygonDrawingManager",
    "./carrierZoneGrouperService",
    "./carrierZonesPlot",
    "./nlgRoute",
    "./nlgRouteController",
    "./nlgRouteOverlayPath",
    "./nlgMapStyle",
    "./pointReductionService",
    "./FreeHandDrawerContextMenu",
    "./FreeHandControlPanel",
    "./MoveStopAction",
    "./routeServiceUrl",
    "./alterStopsService",
    "./routeStopPopupService",
    "./markerService",
    "./mapsPathService",
    "./mapsPathServiceUrl",
    "./nlgMap",
    "./nlgRouteMapMarkerService",
    "./nlgRouteStopMarkerService"
], function () {
});