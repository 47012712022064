import allowedOriginsModule from "./allowedOriginsModule"
import server from "../server"

allowedOriginsModule.service("allowedOriginsService", ["$http", ($http) => {
    return {
        save: save,
        findAllAllowedOrigins: findAllAllowedOrigins,
        findUserOriginRestriction: findUserOriginRestriction
    }

    function save(userOriginRestriction) {
        return $http.post(server.getServiceUrl("allowedOrigins"), userOriginRestriction).then((response) => response.data)
    }

    function findUserOriginRestriction(patch){
        return $http.post(server.getServiceUrl("allowedOrigins") + "filter", patch).then((response) => response.data)
    }

    function findAllAllowedOrigins() {
        return $http.get(server.getServiceUrl("allowedOrigins"))
            .then((response) => response.data)
    }
}])