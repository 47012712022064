define(["angular"], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name pathModule
     * @description
     * Módulo que agrupa paths utilizados por serviços no front-end-web-comomon.
     * */
    return angular.module("pathModule", []);
});