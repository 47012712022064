define(["./formModule", "angular"],
    function (formModule, angular) {
        "use strict";

        /**
         * @ngdoc directive
         * @name formModule.directive:isolateForm
         * @element input
         * @restrict A
         * @description
         * Diretiva utilizada para isolar validações de formulários quando existirem formulários aninhados.
         * <br>
         * Esta diretiva não surtirá efeito com validações nativas do HTML, sendo utilizada
         * somente para validações de formulários do AngularJs.
         *
         * @example
         * <example module="FrontEndWeb">
         *   <file name="index.html">
         *    <div data-ng-app="demoApp" data-ng-controller="nestedFormIsolation" class="main">
         *       <div class="bg-info wrapper">
         *          <h4>
         *             Parent Form
         *             <span data-ng-if="parentForm.$invalid" class="label label-danger">NG-INVALID</span>
         *             <span data-ng-if="parentForm.$valid" class="label label-success">NG-VALID</span>
         *             <span data-ng-if="parentForm.$pristine" class="label label-info">NG-PRISTINE</span>
         *             <span data-ng-if="parentForm.$dirty" class="label label-warning">NG-DIRTY</span>
         *          </h4>
         *          <ng-form name="parentForm">
         *             <br>
         *             <input type="text" required data-ng-model="input1" name="input1" />
         *             <span data-ng-if="parentForm.input1.$invalid" class="label label-danger">NG-INVALID</span>
         *             <span data-ng-if="parentForm.input1.$valid" class="label label-success">NG-VALID</span>
         *             <span data-ng-if="parentForm.input1.$pristine" class="label label-info">NG-PRISTINE</span>
         *             <span data-ng-if="parentForm.input1.$dirty" class="label label-warning">NG-DIRTY</span>
         *             <br><br>
         *             <a class="btn btn-default btn-xs" data-ng-click="setPristine(parentForm)">Set pristine</a>
         *             <div class="indent-block">
         *                <div class="bg-success wrapper">
         *                   <h4>
         *                      Isolated subform
         *                      <span data-ng-if="isolatedForm.$invalid" class="label label-danger">NG-INVALID</span>
         *                      <span data-ng-if="isolatedForm.$valid" class="label label-success">NG-VALID</span>
         *                      <span data-ng-if="isolatedForm.$pristine" class="label label-info">NG-PRISTINE</span>
         *                      <span data-ng-if="isolatedForm.$dirty" class="label label-warning">NG-DIRTY</span>
         *                   </h4>
         *                   <ng-form name="isolatedForm" data-isolate-form>
         *                      <br>
         *                      <input type="text" required data-ng-model="input2" name="input2" />
         *                      <span data-ng-if="isolatedForm.input2.$invalid" class="label label-danger">NG-INVALID</span>
         *                      <span data-ng-if="isolatedForm.input2.$valid" class="label label-success">NG-VALID</span>
         *                      <span data-ng-if="isolatedForm.input2.$pristine" class="label label-info">NG-PRISTINE</span>
         *                      <span data-ng-if="isolatedForm.input2.$dirty" class="label label-warning">NG-DIRTY</span>
         *                      <br><br>
         *                      <a class="btn btn-default btn-xs" data-ng-click="setPristine(isolatedForm)">Set pristine</a>
         *                   </ng-form>
         *                </div>
         *                <div class="bg-warning wrapper">
         *                   <h4>
         *                      Classic subform
         *                      <button type="button" class="btn btn-xs btn-default" ng-click="setPristine(classicNested)">Set pristine</button>
         *                      <span data-ng-if="classicNested.$invalid" class="label label-danger">NG-INVALID</span>
         *                      <span data-ng-if="classicNested.$valid" class="label label-success">NG-VALID</span>
         *                      <span data-ng-if="classicNested.$pristine" class="label label-info">NG-PRISTINE</span>
         *                      <span data-ng-if="classicNested.$dirty" class="label label-warning">NG-DIRTY</span>
         *                   </h4>
         *                   <ng-form name="classicNested">
         *                      <br>
         *                      <input type="text" required data-ng-model="input4" name="input4" />
         *                      <span data-ng-if="classicNested.input4.$invalid" class="label label-danger">NG-INVALID</span>
         *                      <span data-ng-if="classicNested.input4.$valid" class="label label-success">NG-VALID</span>
         *                      <span data-ng-if="classicNested.input4.$pristine" class="label label-info">NG-PRISTINE</span>
         *                      <span data-ng-if="classicNested.input4.$dirty" class="label label-warning">NG-DIRTY</span>
         *                      <br><br>
         *                      <a class="btn btn-default btn-xs" data-ng-click="setPristine(classicNested)">Set pristine</a>
         *                   </ng-form>
         *                </div>
         *             </div>
         *          </ng-form>
         *       </div>
         *    </div>
         *   </file>
         * </example>
         */
        return formModule.directive("isolateForm", [function () {
            return {
                restrict: "A",
                require: "?form",
                link: function (scope, elm, attrs, ctrl) {
                    if (!ctrl) {
                        return;
                    }

                    // Do a copy of the controller
                    var ctrlCopy = {};
                    angular.copy(ctrl, ctrlCopy);

                    // Get the parent of the form
                    var parent = elm.parent().controller("form");

                    if (parent) {
                        // Remove parent link to the controller
                        parent.$removeControl(ctrl);

                        // Replace form controller with a "isolated form"
                        var isolatedFormCtrl = {
                            $setValidity: function (validationToken, isValid, control) {
                                ctrlCopy.$setValidity(validationToken, isValid, control);
                                parent.$setValidity(validationToken, true, ctrl);
                            },
                            $setDirty: function () {
                                elm.removeClass("ng-pristine").addClass("ng-dirty");
                                ctrl.$dirty = true;
                                ctrl.$pristine = false;
                            }
                        };
                        angular.extend(ctrl, isolatedFormCtrl);
                    }
                }
            };
        }]);
    });