define(["angular",
    "angular-translate",
    "angular-bootstrap",
    "../violation/violation"
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name optimizationModule
     * @description
     * Módulo com funcionalidades relacionados a otimização de pedidos e viagens.
     * */
    return angular.module("commonsOptimizationModule", [
        "pascalprecht.translate",
        "ui.bootstrap",
        "violationModule"
    ]);
});