define(["./commentModule", "text!./nlgComments.html"], function (module, template) {
    "use strict";

    return module.directive("nlgComments", ["monitoringService", function (monitoringService) {
        return {
            restrict: "E",
            template: template,
            scope: {
                comments: "=",
                parentId: "<?",
                editable: "&?",
                saveComment: "&?",
                urlDownloadAttachments: "&?",
                hideStatusChange: "<?",
                monitorableSourceId: "="
            },
            controller: ["$scope", function ($scope) {
                $scope.model = {
                    newComment: {}
                };
                $scope.findPossibleOccurrenceStatus = function () {
                    return monitoringService.findPossibleOccurrenceStatus($scope.parentId);

                }
            }]
        };
    }]);
});