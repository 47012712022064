define(["angular", "angular-translate"], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name chartingModule
     * @description
     * Módulo para agrupamento de gráficos.
     * */
    return angular.module("chartingModule", [
        "pascalprecht.translate"
    ]);
});