define([
    "./kpiConfigurationsModule"
], function (module) {
    "use strict";

    module.service("kpiConfigurationService", [
        "monitoringService",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        function (monitoringService, loadingService, remoteExceptionHandler, messagesModal) {
            var self = this;

            self.getAllFailureReason = function () {
                return loadingService(monitoringService.findAllTransitionFailureReasons())
                    .catch(remoteExceptionHandler())
                    .then(function (failureReasons) {
                        return failureReasons;
                    });
            }

            self.save = function (kpiConfigurations) {
                return loadingService(monitoringService.saveKpiConfigurations(kpiConfigurations))
                    .catch(remoteExceptionHandler())
                    .then(function (restRemoteMessage) {
                        let bundles = restRemoteMessage.map(function (remoteMessage) {
                            return remoteMessage.description;
                        });
                        if (restRemoteMessage.some(remoteMessage => !remoteMessage.result)) {
                            return messagesModal("dialog.error", bundles);
                        }
                        return messagesModal("dialog.success", bundles);
                    });
            };

            return self;
        }]);
});
