define(["../../monitoringModule",
        "text!./MonitoringPanelSubtitles.html"],
    function (monitoringModule, monitoringPanelSubtitlesTemplate) {
        "use strict";

        return monitoringModule.controller("MonitoringPanelController", [
            "$scope",
            "$q",
            "$http",
            "$modal",
            function ($scope, $q, $http, $modal) {
                var paginationApi,
                    template,
                    paginationApiDefer = $q.defer(),
                    serviceDefinitionDefer = $q.defer(),
                    basketApi = {};

                $scope.root = {};
                $scope.service = "driverDocument/basket";

                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    return paginationApi.seekPage(1);
                };

                $scope.paginationOptions = {
                    pagination: {
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            $scope.root = {};
                            var params = {
                                firstResult: firstResult,
                                maxResults: maxResults
                            };
                            return doFilterPresentationTree($scope.service, template, params)
                                .then(function (result) {
                                    var response = result.data;
                                    $scope.root = response.entity.result[0];
                                    return response.entity;
                                }).then(function (root) {
                                    if ($scope.afterPaginate) {
                                        $scope.afterPaginate($scope.root);
                                    }
                                    return root;
                                });
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                    }
                };

                var getEmptyPresentationTree = function (service) {
                    return $http.get(buildUrl(service)).then(function (response) {
                        return response.data;
                    });
                };

                var doFilterPresentationTree = function (service, patch, params) {
                    return $http({
                        url: buildUrl(service),
                        method: "POST",
                        data: patch,
                        params: params
                    });
                };


                function buildUrl(service) {
                    var urlParts = ["/cockpit-gateway/monitoring", service, "presentation/tree"];
                    return urlParts.join("/");
                }

                $scope.viewSubtitle = function () {
                    return $modal.open({
                        template: monitoringPanelSubtitlesTemplate
                    });
                };

                $q.all([
                    paginationApiDefer.promise,
                    serviceDefinitionDefer.promise
                ]).then(function () {
                    return getEmptyPresentationTree($scope.service);
                }).then(function (emptyRoot) {
                    basketApi.setData(emptyRoot);
                }).finally(function () {
                    if ($scope.registerBasketApi) {
                        $scope.registerBasketApi({api: basketApi});
                    }
                });
            }]);
    });