define([
    "./occurrenceCauseModule"
], function (occurrenceCauseModule) {
    "use strict";

    occurrenceCauseModule.service("occurrenceCauseService", [
        "monitoringService",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        function (monitoringService, loadingService, remoteExceptionHandler, messagesModal) {
            var self = this;

            self.deleteOccurrenceCause = function (id) {
                return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                    .then(function () {
                        return loadingService(monitoringService.deleteOccurrenceCause(id))
                            .catch(remoteExceptionHandler());
                    }).then(function () {
                        return messagesModal("dialog.success", {
                            keyBundle: "entityFilter.delete.ok"
                        });
                    });
            };

            self.saveOccurrenceCause = function (entity) {
                return loadingService(monitoringService.saveOccurrenceCause(entity))
                    .catch(remoteExceptionHandler())
                    .then(function (id) {
                        return messagesModal("dialog.success", {
                            keyBundle: "monitoring.occurrence.Cause.save.success"
                        }).then(function () {
                            return id;
                        });
                    });
            };

            self.getOccurrenceCauseIcons = function () {
                return monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon")
                    .then(function (values) {
                        return values;
                    });
            };

            self.getOccurrenceCause = function (occurrence) {
                return monitoringService.findOccurrenceCauseById(occurrence.cause.id)
                    .then(function (values) {
                        return values;
                    });
            };

            return self;
        }]);
});