define([
    "./monitoringYardControlModule",
    "front-end-web-commons/app/arrays/arrays",
    "json!../monitoring.json",
    "angular"
], function (module, arrays, monitoring) {
    "use strict";

    return module.controller("monitoringYardControlController", [
        "$scope",
        "$interval",
        "loadingService",
        "monitoringService",
        "remoteExceptionHandler",
        function ($scope, $interval, loadingService, monitoringService, remoteExceptionHandler) {
            $scope.filterService = null;
            $scope.tableOptions = null;
            $scope.serviceName = _.trim(monitoring.services.yardControl.baseUrl);
            var template = undefined;
            var paginationApi;
            var filterApi;
            var page = 1;
            var autoRefreshFlow = false;

            $scope.search = function (modifiedTemplate) {
                template = modifiedTemplate || null;
                paginationApi.seekPage(page);
            };

            $scope.$on("$destroy", function () {
                $interval.cancel(refilterInterval);
            });

            $scope.onRegisterFilterApi = function (api) {
                filterApi = api;
            };

            $scope.getStatusImage = function (status) {
                if (!status) {
                    return "";
                }
                return "./images/" + monitoringService.getEnumName(status).toLowerCase() + ".png";
            };

            $scope.tableOptions = {
                records: [],
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        if(autoRefreshFlow){
                            return monitoringService.filterYardControl("/filter", template, firstResult, maxResults)
                                .catch(remoteExceptionHandler());
                        }
                        return loadingService(monitoringService.filterYardControl("/filter", template, firstResult, maxResults))
                            .catch(remoteExceptionHandler());
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                }
            };
            var refilterInterval, timeToPaginate, executedRefreshTimes = 0;
            monitoringService.getYardControlConfiguration().then(function (response) {
                timeToPaginate = response.millisToPaginate / response.millisToRefreshPage;
                refilterInterval = $interval(refreshPaginateFilter, response.millisToRefreshPage);
            });

            /*A função refreshPaginateFilter e paginate volta para a primeira página caso der conflito de clique simultâneo na hora de paginar.*/
            function refreshPaginateFilter() {
                if (template === undefined) {
                    return;
                }
                executedRefreshTimes++;
                if (executedRefreshTimes < timeToPaginate) {
                    autoRefresh();
                    if(!$scope.tableOptions.records.length){
                        page = 1;
                        autoRefresh();
                    }
                    return;
                }
                paginate();
            }

            function paginate() {
                if (!$scope.tableOptions.records.length) {
                    return;
                }
                var pageOptions = $scope.tableOptions.pagination.onPaginate.length;
                var maxPageSize = $scope.tableOptions.pagination.maxPageSize;
                var countRecords = $scope.tableOptions.pagination.count;
                if (countRecords < maxPageSize || pageOptions <= page ) {
                    page = 1;
                    autoRefresh();
                    executedRefreshTimes = 0;
                } else {
                    page++;
                    autoRefresh();
                    if(!$scope.tableOptions.records.length){
                        page = 1;
                        autoRefresh();
                    }
                    executedRefreshTimes = 0;
                }
            }

            function autoRefresh(){
                autoRefreshFlow = true;
                $scope.search(template);
                autoRefreshFlow = false;
            }
        }]);
});
