define(["../monitoringQueryModule",
        "json!../../monitoring.json"],
    function (monitoringModule, monitoring) {
        "use strict";

        return monitoringModule.controller("monitoringQuerySearchController", [
            "$scope",
            "$q",
            "monitoringService",
            "loadingService",
            "remoteExceptionHandler",
            "messagesModal",
            function ($scope, $q, monitoringService, loadingService, remoteExceptionHandler, messagesModal) {
                $scope.serviceName = monitoring.services.query;
                var template, paginationApi, paginationApiDefer = $q.defer();
                var filterApi, filterApiDefer = $q.defer();
                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    paginationApi.seekPage(1);
                };

                $scope.onRegisterFilterApi = function (api) {
                    filterApi = api;
                    filterApiDefer.resolve();
                };

                monitoringService.getContentExclusion().then(function (response) {
                    $scope.contentExclusion = response;
                });

                $scope.tableOptions = {
                    records: [],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(monitoringService.filterQuery("/filter", template, firstResult, maxResults))
                                .then(function (result) {
                                    return result;
                                }).catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                        paginationApiDefer.resolve();
                    }
                };

                $scope.delete = function (id) {
                    messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"]).then(function () {
                        loadingService(monitoringService.deleteQuery(id))
                            .catch(remoteExceptionHandler())
                            .then(function () {
                                return messagesModal("dialog.success", {
                                    keyBundle: "monitoring.query.delete.success"
                                });
                            })
                            .finally(function () {
                                filterApi.reload();
                            });
                    });
                };
            }]);
    });