define([
        "./monitoringFollowupOverviewModule",
        "lodash",
        "img!../images/no_data.png",
        "img!../images/intime.png",
        "img!../images/warning.png",
        "img!../images/delayed.png"],
    function (module, _, no_data, intime, warning, delayed) {
        "use strict";

        return module.controller("MonitoringFollowupOverviewController", [
            "$scope",
            "$q",
            "monitoringService",
            "reload",
            "loadingService",
            "remoteExceptionHandler",
            "kpiConfigurations",
            "$interval",
            "$filter",
            "$window",
            function ($scope, $q, monitoringService, reload, loadingService, remoteExceptionHandler, kpiConfigurations, $interval, $filter, $window) {
                $scope.serviceName = monitoringService.getMonitoringFollowupFilterUrl();
                $scope.configurations = kpiConfigurations;
                $scope.summary = {};
                $scope.model = {};
                $scope.model.isFullscreen = false;
                getAvailableIndicatorDefinition();
                $scope.indicatorDefinition = getLastWordEnumLower($scope.configurations.indicatorDefinition);
                var refilterInterval = null;
                var page = 1;
                var template = [], paginationApi, paginationApiDefer = $q.defer();
                var filterApi, filterApiDefer = $q.defer();

                $scope.search = function (modifiedTemplate) {
                    monitoringService.getKpiConfigurations().then(function (configurations) {
                        $scope.configurations.transitionFailureReason = configurations.transitionFailureReason;
                        template = modifiedTemplate;
                        if (!$scope.model.isFullscreen) {
                            page = 1;
                        }
                        paginationApi.seekPage(page);
                    });
                };

                $scope.onRegisterFilterApi = function (api) {
                    filterApi = api;
                    filterApiDefer.resolve();
                };

                /*Solução criada para quando sair do full screen com o F11, parar de paginar*/
                var window = angular.element($window);
                window.bind('resize', function () {
                    $scope.$apply();
                });

                $scope.$watch(() => $window.innerHeight, function (value) {
                    if (value === screen.height) {
                        $scope.model.isFullscreen = true;
                        $scope.initFullScreen();
                    } else {
                        $scope.model.isFullscreen = false;
                        $scope.initFullScreen();
                    }
                }, true);

                $scope.initFullScreen = function () {
                    $interval.cancel(refilterInterval);
                    if ($scope.model.isFullscreen) {
                        document.documentElement.requestFullscreen().catch(() => {
                        });
                        angular.element(".side-menu").get(0).style.display = "none";
                        let refreshTime = $scope.configurations.refreshTime * 1000;
                        refilterInterval = $interval(paginate, refreshTime);
                    } else {
                        document.exitFullscreen().catch(() => {
                        });
                        angular.element(".side-menu").get(0).style.display = "block";
                    }
                };
                $scope.$on("$destroy", function () {
                    $interval.cancel(refilterInterval);
                });

                $scope.changeIndicatorDefinition = function () {
                    if ($scope.configurations.indicatorDefinition) {
                        $scope.tableOptions.indicatorDefinition = $scope.configurations.indicatorDefinition;
                        $scope.indicatorDefinition = getLastWordEnumLower($scope.configurations.indicatorDefinition);
                    }
                };

                function getLastWordEnumLower(value) {
                    return getLastWordEnum(value).toLowerCase();
                }

                function getLastWordEnum(value) {
                    return value.substr(value.lastIndexOf(".") + 1);
                }

                $scope.getValueOrHifen = function (value) {
                    if (value) {
                        return $filter("number")(value);
                    }
                    return "-";
                };

                $scope.getPresentationValueByDefinition = function (quantities, prefix) {
                    let value = $scope.getValueByDefinition(quantities, prefix);
                    return $scope.getValueOrHifen(value);
                };

                $scope.getValueByDefinition = function (quantities, prefix) {
                    if (!quantities) {
                        return 0;
                    }
                    if (prefix && quantities[prefix + $scope.indicatorDefinition.capitalize()]) {
                        return quantities[prefix + $scope.indicatorDefinition.capitalize()]
                    }
                    if (quantities[$scope.indicatorDefinition]) {
                        return quantities[$scope.indicatorDefinition];
                    }
                    return 0;
                };

                $scope.getEffectivenessDeliveredValue = function (record) {
                    let deliveredInvoiceValue = $scope.getValueByDefinition(record.NOT_ACCOMPLISHED) - $scope.getValueByDefinition(record.NOT_ACCOMPLISHED, "notDelivered");
                    let deliveredInvoiceConfigured = $scope.getValueByDefinition(record.CONFIGURED) - $scope.getValueByDefinition(record.CONFIGURED, "notDelivered");
                    return $scope.getValueByDefinition(record.FINALIZED) + deliveredInvoiceValue + deliveredInvoiceConfigured;
                }

                $scope.getPresentationEffectivenessDelivered = function (record) {
                    let deliveredValue = $scope.getEffectivenessDeliveredValue(record);
                    return $scope.getValueOrHifen(deliveredValue);
                }


                $scope.calculatePercentage = function (value, total) {
                    if (!value || !value === "-" || !total) {
                        return "-";
                    }
                    let percentage = (value / total) * 100;
                    if (percentage) {
                        return $filter("percentage")(percentage, 2);
                    }
                    return "-";
                };

                $scope.getSummaryEffectivenessDeliveredValue = function () {
                    let deliveredInvoiceValue = $scope.getSummaryByDefinition("failure") - $scope.getSummaryByDefinition("notDelivered");
                    let deliveredInvoiceConfigured = $scope.getSummaryByDefinition("configured") - $scope.getSummaryByDefinition("configuredNotDelivered");
                    return $scope.getSummaryByDefinition("finalized") + deliveredInvoiceValue + deliveredInvoiceConfigured;
                }

                $scope.getSummaryByDefinition = function (field) {
                    let indicatorDefinitionCaptalize = $scope.indicatorDefinition.capitalize();
                    let property = field + indicatorDefinitionCaptalize;
                    return _.get($scope.summary, property, 0);
                };

                String.prototype.capitalize = function () {
                    return this.charAt(0).toUpperCase() + this.slice(1);
                };


                $scope.getPresentationSensorPercentage = function (sensorConfiguration) {
                    let sensorUsagePercentage = calculateTimeSensor(sensorConfiguration);
                    if (sensorUsagePercentage) {
                        return $filter("percentage")(sensorUsagePercentage, 2);
                    }
                    return "-";
                };

                $scope.getSensorIcon = function (sensorConfiguration) {
                    let timeOnSensor = calculateTimeSensor(sensorConfiguration);
                    if (timeOnSensor === 0) {
                        return no_data;
                    }
                    if (timeOnSensor <= 60) {
                        return delayed;
                    }
                    if (timeOnSensor <= 80) {
                        return warning;
                    }
                    return intime;
                };

                function calculateTimeSensor(sensorConfiguration) {
                    if (!sensorConfiguration[0]) {
                        return 0;
                    }
                    let sensor = sensorConfiguration[0];
                    let centerRange = sensor.timeOnCenterRange;
                    let criticalRange = sensor.timeOnLowerCriticalRange + sensor.timeOnTopCriticaRange;
                    let warningRange = sensor.timeOnLowerWarningRange + sensor.timeOnTopWarningRange;
                    return (centerRange / (centerRange + criticalRange + warningRange)) * 100;
                }

                $scope.getMonitorableSemaphore = function (record) {
                    if (!record.startTimestamp || !record.expectedTimestamp) {
                        return no_data;
                    }
                    if (record.startTimestamp <= record.expectedTimestamp) {
                        return intime;
                    }
                    return delayed;
                };

                $scope.calculateTotalPercentageSensor = function () {
                    let totalSensorOccurrences = $scope.tableOptions.records.filter(record => record.sensorsUsage[0]).length;
                    let totalAverageTemperature = $scope.tableOptions.records.reduce((sum, record) => sum + calculateTimeSensor(record.sensorsUsage), 0) / totalSensorOccurrences;
                    if (!totalAverageTemperature) {
                        return 0;
                    }
                    return totalAverageTemperature;
                };

                function paginate() {
                    if (!$scope.tableOptions.records.length) {
                        return;
                    }
                    var maxPageSize = $scope.tableOptions.pagination.maxPageSize;
                    var countRecords = $scope.tableOptions.pagination.count;
                    var numPages = Math.ceil(countRecords / maxPageSize);
                    if (numPages <= page) {
                        page = 1;
                        autoRefresh();
                    } else {
                        page++;
                        autoRefresh();
                        if (!$scope.tableOptions.records.length) {
                            page = 1;
                            autoRefresh();
                        }
                    }
                }

                function autoRefresh() {
                    $scope.search(template);
                }

                $scope.tableOptions = {
                    indicatorDefinition: $scope.configurations.indicatorDefinition,
                    records: [],
                    pagination: {
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            page = $scope.tableOptions.pagination.currentPage;
                            return loadingService(monitoringService.filterMonitoringFollowup(template, firstResult, maxResults))
                                .then(function (response) {
                                    // Por ser um objeto composto, sempre vem um resultado apenas com resultados internos e os resultados precisam ser tratados.
                                    response.result = fillResultsAndSummary(response.result[0]);
                                    return response;
                                }).catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                        paginationApiDefer.resolve();
                    }
                };

                function fillResultsAndSummary(response) {
                    $scope.summary = response.summary;
                    var result = response.result;
                    result.forEach(function (tuple) {
                        tuple.summaries.forEach(function (summary) {
                            if (!summary.key.transitionFailureReason) {
                                tuple[getLastWordEnum(summary.key.transitionStatus)] = summary.value;
                            } else if (isConfiguredFailureReason(summary.key.transitionFailureReason)) {
                                tuple["CONFIGURED"] = summary.value;
                            }

                        })
                    });

                    result.forEach(function (tuple) {
                        subtractConfiguredFailureOfGeneralFailures(tuple);
                    });

                    subtractConfiguredFailureOfGeneralFailuresSummary();


                    return result;
                }

                function subtractConfiguredFailureOfGeneralFailures(tuple) {
                    if (!tuple["CONFIGURED"] || !tuple["NOT_ACCOMPLISHED"]) {
                        return;
                    }
                    tuple["NOT_ACCOMPLISHED"].distance -= tuple["CONFIGURED"].distance;
                    tuple["NOT_ACCOMPLISHED"].quantity -= tuple["CONFIGURED"].quantity;
                    tuple["NOT_ACCOMPLISHED"].value -= tuple["CONFIGURED"].value;
                    tuple["NOT_ACCOMPLISHED"].volume -= tuple["CONFIGURED"].volume;
                    tuple["NOT_ACCOMPLISHED"].weight -= tuple["CONFIGURED"].weight;
                    tuple["NOT_ACCOMPLISHED"].notDeliveredWeight -= tuple["CONFIGURED"].notDeliveredWeight;
                    tuple["NOT_ACCOMPLISHED"].notDeliveredVolume -= tuple["CONFIGURED"].notDeliveredVolume;
                }

                function subtractConfiguredFailureOfGeneralFailuresSummary() {
                    $scope.summary.failureQuantity -= $scope.summary.configuredQuantity;
                    $scope.summary.failureVolume -= $scope.summary.configuredVolume;
                    $scope.summary.failureWeight -= $scope.summary.configuredWeight;
                    $scope.summary.notDeliveredVolume -= $scope.summary.configuredNotDeliveredVolume;
                    $scope.summary.notDeliveredWeight -= $scope.summary.configuredNotDeliveredWeight;
                }

                function isConfiguredFailureReason(failureReason) {
                    if (!failureReason) {
                        return false;
                    }
                    return failureReason.sourceId === $scope.configurations.transitionFailureReason.sourceId;
                }

                function getAvailableIndicatorDefinition() {
                    (function () {
                        monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.kpiconfigurations.KPIConfigurationIndicatorDefinition")
                            .then(function (values) {
                                $scope.availableIndicatorMeasurement = values;
                            });
                    })();
                }
            }
        ]);
    });
