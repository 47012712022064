import analysisMapModule from "./analysisMapModule"
import server from "../server"

analysisMapModule.service("analysisMapPreViolationService", [
    "$http", "remoteExceptionHandler", "preViolationModal", "loadingService", "$q",
    ($http, remoteExceptionHandler, preViolationModal, loadingService, $q) => {

        const optimizationPreValidationServiceUrl = server.getServiceUrl("analysisMapOptimizationPreValidation")

        return {
            getViolationsForOptimization: getViolations.bind(null, optimizationPreValidationServiceUrl)
        }

        function getViolations(service, deliveryUnitsIds){
            return loadingService($http.post(service, deliveryUnitsIds))
            .catch(function (reason) {
                remoteExceptionHandler(reason)
                return $q.reject(reason)
            }).then( function (response){
                const validationResult = response.data
                if (!validationResult.hardViolations.length && !validationResult.softViolations.length) {
                    return
                } else {
                    return preViolationModal.open(validationResult, {}, {
                        trips: [],
                        deliveryUnits: deliveryUnitsIds
                    }).catch(function onDismiss(reason) {
                        return $q.reject(reason)
                    })
                }
            })
        }
}])
