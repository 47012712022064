define([
    "angular",
    "text!./nlgTagInputPopUpTemplate.html",
    "text!./nlgTagInputPopUpTemplateIcon.html"
], function (angular, popupTemplate, popupTemplateIcon) {
    "use strict";

    return angular.module("nlgTagInputModule", [])
        .run(["$templateCache", function ($templateCache) {
            $templateCache.put("nlgTagInputPopUpTemplate.html", popupTemplate);
            $templateCache.put("nlgTagInputPopUpTemplateIcon.html", popupTemplateIcon);
        }]);
});