define([
        "../occurrencesModule",
        "text!./monitorablesAndTransitionsDialog.html"],
    function (occurrencesModule, template) {
        "use strict";

        return occurrencesModule.service("monitorablesAndTransitionsDialog", [
            "$http",
            "$modal",
            "$state",
            "$window",
            function ($http, $modal, $state, $window) {
                return function (monitorables) {
                    return $modal.open({
                        template: template,
                        size: "xl",
                        controller: ["$scope", function ($scope) {
                            var filterPatch = null;
                            var paginationApi;

                            monitorables.forEach(function (monitorable) {
                                monitorable.locations = monitorable.transitionGroups.flatMap(function (transition) {
                                    return transition.location.locality.name + " - " + transition.location.locality.city;
                                });
                            });

                            $scope.tableOptions = {
                                records: monitorables,
                                columns: [{
                                    header: "monitorable",
                                    cellName: "monitorable",
                                    width: "10%",
                                    cell: {
                                        template: "<a name='record.sourceId' ng-click='goToMonitorable(record.id)'><span>{{record.sourceId}}</span></a>"
                                    }
                                }, {
                                    header: "Localidades de grupos de transição",
                                    cellName: "Localidades de grupos de transição",
                                    width: "10%",
                                    cell: {
                                        type: "default",
                                        name: "{{displayArray(record.locations)}}"
                                    }
                                }]
                            };

                            $scope.displayArray = function (data) {
                                return data.join(",");
                            };

                            $scope.splitViolationMessage = function (invalidData) {
                                if (invalidData) {
                                    return invalidData.substring(1, invalidData.length - 2).split("] [");
                                }
                                return "";
                            };

                            $scope.search = function (patch) {
                                filterPatch = patch;
                                return paginationApi.seekPage(1);
                            };

                            $scope.goToMonitorable = function (id) {
                                $scope.$close();
                                var url = $state.href("monitoring.monitorableEdition", {
                                    monitorableId: id
                                });
                                return $window.open(url, '_blank');
                            };
                        }]
                    });
                };
            }]);
    });
