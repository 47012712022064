import authenticationModule from "./authenticationModule"
import authenticationView from "./authenticationView.html"

authenticationModule.directive("nlgAuthentication", [() =>{
    return {
        restrict: "E",
        controller: "authenticationController",
        template: authenticationView,
        scope:{
            loginState: "@loginState"
        }
    }
}])