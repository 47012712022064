define(["./mapsModule", "text!./nlgRoute.html"], function (mapsModule, nlgRouteTemplate) {
    "use strict";

    return mapsModule.directive("nlgRoute", [function () {
        return {
            restrict: "E",
            require: "?^mapControl",
            scope: {
                routes: "=description",
                openFirstRoute: "@openFirstRoute",
                markers: "=?",
                nonClusteredMarkers: "=?",
                hideTripTable: "@?",
                hideMonitorableTable: "@?",
                hideMonitorableTableButton: "=?",
                showLabelButton: "@?",
                hideTripTableButton: "=?",
                hidePolygonCarrierZoneButton: "@?",
                carrierZoneGrouperToDraw: "@?",
                carrierZoneGrouperToDrawSpecifyBundleConfirm: "@?",
                mapHeight: "<?",
                brazil: "@?",
                canProgram: "<?",
                canProgramInMonitoring: "<?",
                registerRoutesApi: "&?",
                autoRefreshCallback: "&?",
                hasFullscreenControl: "@?",
                stopMarker: "@?",
                markerControl: "<?",
                showLocalityIcon: "<?",
                polygons: "<?",
                selectedMapMarkers: "<selectedMapMarkers",
                canShowStopPopUp: "<?"
            },
            template: nlgRouteTemplate,
            link: function (scope, element, attrs, mapControlController) {
                if (mapControlController) {
                    mapControlController.registerMapControl(scope.mapControl);
                }
            },
            controller: "nlgRouteController"
        };
    }]);
});