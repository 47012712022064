define(["./nlgTableDynamicColumnsModule", "text!./nlgTableHeaders.html"], function (nlgTableDynamicColumnsModule, tableHeadersTemplate) {
    "use strict";

    /**
     * @ngdoc directive
     * @name nlgTableDynamicColumns.directive:nlgTableHeaders
     * @restrict A
     * @element tr
     * @requires directives.directive:nlgTable
     * @description
     * Exibe os headers das colunas definidas na tableOptions.columns da diretiva
     * {@link directives.directive:nlgTable nlgTable}.
     *
     * Esta diretiva costuma ser utilizada em conjunto com a {@link nlgTableDynamicColumns.directive:nlgTableRows nlgTableRows}.
     *
     * É possível extender as funcionalidades fornecidas para cada coluna, utilizando as funcionalidades expostas pelo
     * controller {@link nlgTableDynamicColumns.controller:nlgTableHeadersController nlgTableHeadersController}.
     *
     * @example
     * <example module="FrontEndWeb">
          <file name="index.html">
             <div ng-controller="test">
                 <table nlg-table="tableOptions">
                     <thead>
                         <tr nlg-table-headers></tr>
                     </thead>
                 </table>
             </div>
          </file>
          <file name="index.js">
             angular
                .module("FrontEndWeb")
                .controller("test", function ($scope) {
                   $scope.tableOptions = {
                       records: [
                           {id: 10, desc: '10'},
                           {id: 20, desc: '20'},
                           {id: 30, desc: '30'}
                       ],
                       columns: [
                           {header: "Identificador"},
                           {header: "Descrição"}
                       ]
                   };
                })
          </file>
      </example>
     */
    return nlgTableDynamicColumnsModule.directive("nlgTableHeaders", ["templateFactory", function (templateFactory) {
        return {
            restrict: "A",
            require: "^nlgTable",
            template: tableHeadersTemplate,
            scope: true,

            /**
             * @ngdoc controller
             * @name nlgTableDynamicColumns.controller:nlgTableHeadersController
             **/
            controller: ["$scope", function ($scope) {
                var doVisitsArray = [];

                /**
                 * @ngdoc method
                 * @name nlgTableDynamicColumns.controller:nlgTableHeadersController#addVisitor
                 * @methodOf nlgTableDynamicColumns.controller:nlgTableHeadersController
                 * @description
                 * Adiciona uma função que será executada ao fim da criação do template
                 * de cada célula do header.
                 * A função recebe o elemento criado, e as configurações definidas para esta coluna.
                 *
                 * @param {function} doVisit Função que será executada sobre o template.
                 * */
                this.addVisitor = function (doVisit) {
                    doVisitsArray.push(doVisit);
                };

                function doVisits(element, column) {
                    doVisitsArray.forEach( function (doVisit) {
                        doVisit(element, column);
                    });
                }

                $scope.createTemplate = function (column) {
                    var element = templateFactory(column.header);
                    doVisits(element, column);
                    return element[0].outerHTML;
                };
            }],
            link: function (scope, element, attrs, nlgTableController) {
                scope.tableOptions = nlgTableController.tableOptions;
            }
        };
    }]);
});