define(["./commonsCancellationModule"], function (commonsCancellationModule) {
    "use strict";

    return commonsCancellationModule.service("cancellationServiceInvoker", [function () {
        var cancellationService;
        return {
            cancel: function (tripNodeIdentifiers, context) {
                if (!cancellationService) {
                    throw new Error("Service for cancellation was not injected.");
                }
                return cancellationService.cancelTrips(tripNodeIdentifiers, context);
            },
            setService: function (service) {
                cancellationService = service;
            }
        };
    }]);
});