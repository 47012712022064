define([
    "./nlgHealthCheckerModule",
    "text!./nlgHealthCheckerConfiguration.html"
], function (nlgHealthCheckerModule, template) {
    "use strict";

    return nlgHealthCheckerModule.directive("nlgHealthCheckerConfiguration", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                instance: "<",
                checker: "<?",
                model: "="
            },
            controller: ["$scope", "nlgHealthCheckerService", "loadingService", "remoteExceptionHandler", function ($scope, nlgHealthCheckerService, loadingService, remoteExceptionHandler) {
                $scope.model = {};
                init();

                function init() {
                    return loadingService(nlgHealthCheckerService.getConfigurations($scope.instance, $scope.checker && [$scope.checker]))
                        .catch(remoteExceptionHandler())
                        .then(function (configurations) {
                            $scope.configurations = configurations;

                            $scope.configurations.forEach(function (configuration) {
                                $scope.model[configuration.name] = configuration.defaultValue;
                            });
                        });
                }
            }]
        };
    }]);
});
