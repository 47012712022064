define([
    "./monitoringModule",
    "json!./monitoring.json",
    "front-end-web-commons/app/nlgSaveAndContinue/nlgSaveAndContinueModule"
], function (monitoringModule, monitoringServer) {
    "use strict";

    monitoringModule.config(["dashboardDomainProvider", function (dashboardDomainProvider) {
        dashboardDomainProvider.registerDomain("monitoring", monitoringServer.baseUrl);
        dashboardDomainProvider.setDashboardPaths("monitoring", {
            infoUrl: monitoringServer.services.dashboards.info,
            queryUrl: monitoringServer.services.dashboards.query,
            exportChartUrl: monitoringServer.services.dashboards.exportChartUrl,
            countExportUrl: monitoringServer.services.dashboards.countExportUrl
        });
        dashboardDomainProvider.registerDomain("tripChartOverview", monitoringServer.baseUrl);
        dashboardDomainProvider.setDashboardPaths("tripChartOverview", {
            infoUrl: monitoringServer.services.tripChartOverview.info,
            queryUrl: monitoringServer.services.tripChartOverview.query,
            exportChartUrl: monitoringServer.services.tripChartOverview.exportChartUrl,
            countExportUrl: monitoringServer.services.tripChartOverview.countExportUrl
        });
        dashboardDomainProvider.registerDomain("tripStartChartOverview", monitoringServer.baseUrl);
        dashboardDomainProvider.setDashboardPaths("tripStartChartOverview", {
            infoUrl: monitoringServer.services.tripStartChartOverview.info,
            queryUrl: monitoringServer.services.tripStartChartOverview.query,
            exportChartUrl: monitoringServer.services.tripStartChartOverview.exportChartUrl,
            countExportUrl: monitoringServer.services.tripStartChartOverview.countExportUrl
        });

        dashboardDomainProvider.registerDomain("monitorableTemperatureChartOverview", monitoringServer.baseUrl);
        dashboardDomainProvider.setDashboardPaths("monitorableTemperatureChartOverview", {
            infoUrl: monitoringServer.services.monitorableTemperatureChartOverview.info,
            queryUrl: monitoringServer.services.monitorableTemperatureChartOverview.query,
            exportChartUrl: monitoringServer.services.monitorableTemperatureChartOverview.exportChartUrl,
            countExportUrl: monitoringServer.services.monitorableTemperatureChartOverview.countExportUrl
        });

        dashboardDomainProvider.registerDomain("monitorableFollowupChartOverview", monitoringServer.baseUrl);
        dashboardDomainProvider.setDashboardPaths("monitorableFollowupChartOverview", {
            infoUrl: monitoringServer.services.monitorableFollowupChartOverview.info,
            queryUrl: monitoringServer.services.monitorableFollowupChartOverview.query,
            exportChartUrl: monitoringServer.services.monitorableFollowupChartOverview.exportChartUrl,
            countExportUrl: monitoringServer.services.monitorableFollowupChartOverview.countExportUrl
        });

        dashboardDomainProvider.registerDomain("occurrenceChartOverview", monitoringServer.baseUrl);
        dashboardDomainProvider.setDashboardPaths("occurrenceChartOverview", {
            infoUrl: monitoringServer.services.occurrenceChartOverview.info,
            queryUrl: monitoringServer.services.occurrenceChartOverview.query,
            exportChartUrl: monitoringServer.services.occurrenceChartOverview.exportChartUrl,
            countExportUrl: monitoringServer.services.occurrenceChartOverview.countExportUrl
        });
        dashboardDomainProvider.registerDomain("carrierTripChartOverview", monitoringServer.baseUrl);
        dashboardDomainProvider.setDashboardPaths("carrierTripChartOverview", {
            infoUrl: monitoringServer.services.carrierTripChartOverview.info,
            queryUrl: monitoringServer.services.carrierTripChartOverview.query,
            exportChartUrl: monitoringServer.services.carrierTripChartOverview.exportChartUrl,
            countExportUrl: monitoringServer.services.carrierTripChartOverview.countExportUrl
        });
        dashboardDomainProvider.registerDomain("invoiceDeliveryEffectivenessChartOverview", monitoringServer.baseUrl);
        dashboardDomainProvider.setDashboardPaths("invoiceDeliveryEffectivenessChartOverview", {
            infoUrl: monitoringServer.services.invoiceDeliveryEffectivenessChartOverview.info,
            queryUrl: monitoringServer.services.invoiceDeliveryEffectivenessChartOverview.query,
            exportChartUrl: monitoringServer.services.invoiceDeliveryEffectivenessChartOverview.exportChartUrl,
            countExportUrl: monitoringServer.services.invoiceDeliveryEffectivenessChartOverview.countExportUrl
        });
    }]).run(["spreadsheetServiceInvoker", "xlsService", function (spreadsheetServiceInvoker, xlsService) {
        spreadsheetServiceInvoker.setService({
            exportData: xlsService
        });
    }]);

    monitoringModule.service("monitoringBasketService", ["nlgBasketServiceFactory", function (nlgBasketServiceFactory) {
        return nlgBasketServiceFactory("presentation-tree", monitoringServer.baseUrl);
    }]);
});
