define([
    "angular",
    "angular-translate",
    "angular-bootstrap",
    "../remote/remote",
    "../filter/filter",
    "../nlgPagination/nlgPagination",
], function (angular) {
    "use strict";

    return angular.module("genericParamsModule", ["ui.bootstrap", "pascalprecht.translate", "remoteModule", "filterModule", "nlgPaginationModule"]);
});