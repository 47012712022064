define([
    "../occurrenceCauseModule",
    "img!./WARNING.png",
    "img!./DELAY.png",
    "img!./FOOD.png",
    "img!./MECHANICAL.png",
    "img!./OVERNIGHT_STAY.png",
    "img!./SPENT.png",
    "img!./CLOSED_ESTABLISHMENT.png",
], function (module, defaultIcon, delayIcon, foodIcon, mechanicalIcon, overnightStayIcon, spentIcon, closedEstablishmentIcon) {
    "use strict";

    return module.constant("OccurrenceCauseIcon", {
        "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.WARNING": defaultIcon,
        "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.DELAY": delayIcon,
        "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.FOOD": foodIcon,
        "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.MECHANICAL": mechanicalIcon,
        "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.OVERNIGHT_STAY": overnightStayIcon,
        "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.SPENT": spentIcon,
        "br.com.neolog.monitoring.monitorable.model.rest.occurrence.OccurrenceCauseIcon.CLOSED_ESTABLISHMENT": closedEstablishmentIcon
    });
});