import analysisMapModule from "../analysisMapModule"
import manualOperationOptionsTemplate from "./manualOperationOptionsModal.html"

analysisMapModule.service("manualOperationOptionsModal", ["$modal", function ($modal) {
    return function () {
        return $modal.open({
            template: manualOperationOptionsTemplate,
            controller: ["$scope", "$modalInstance", function ($scope, $modalInstance) {
                $scope.model = {
                    chosenOperation: "DEFAULT"
                }

                $scope.cancel = function () {
                    $modalInstance.dismiss()
                }

                $scope.toBasket = function () {
                    $modalInstance.close($scope.model)
                }

                $scope.toTrip = function () {
                    $scope.model.chosenOperation = "TRIP"
                    $modalInstance.close($scope.model)
                }
            }]
        })
    }
}])