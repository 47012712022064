define(["./spreadsheetExportModule", "xlsx-js-style"], function (spreadsheetExportModule, xlsx) {
    "use strict";

    return spreadsheetExportModule.service("spreadsheetExportService", ["$translate", "$q", function ($translate, $q) {
        return {
            exportSpreadsheet: exportSpreadsheet
        };

        function exportSpreadsheet(spreadsheetName, fileName, headers, values, descriptions) {
            var workbook = xlsx.utils.book_new();
            var ws = xlsx.utils.aoa_to_sheet([headers]);
            xlsx.utils.sheet_add_aoa(formatCells(ws, values, descriptions), values, {origin: "A2"});
            xlsx.utils.book_append_sheet(workbook, ws, spreadsheetName);

            var defer = $q.defer();
            setTimeout(function () {
                // Executa fora do ciclo de digest
                xlsx.writeFile(workbook, fileName + ".xlsx");
                defer.resolve();
            });
            return defer.promise;
        }

        function formatCells(ws, datas, descriptions) {
            ws["!cols"] = adaptCellsWidth(ws, datas);
            var cellNumber = 0;
            for (var i in ws) {
                if (typeof (ws[i]) !== "object") continue;
                ws[i].s = addStyle();

                if (descriptions) {
                    ws[i].c = [];
                    ws[i].c.hidden = true;
                    ws[i].c.push({a: "SheetJS", t: $translate.instant(descriptions[cellNumber++])});
                }
            }
            return ws;
        }

        function adaptCellsWidth(ws, datas) {
            var keys = Object.keys(datas[0]).map(function (k) {
                return k.toString().length;
            });
            var wscols = [];
            for (var i = 0; i < keys.length; i++) {
                wscols.push({wch: keys[i] + 30});
            }
            return wscols;
        }

        function addStyle() {
            return {
                font: {
                    name: "Calibri", sz: 11, color: {rgb: "FFFFFF"}, bold: true
                }, fill: {
                    patternType: "solid", fgColor: {rgb: "1CBBB2"}, bgColor: {rgb: "1CBBB2"}
                }
            };
        }
    }]);
});
