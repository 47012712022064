define(["./monitoringModule"], function (monitoringModule) {
    "use strict";

    return monitoringModule.filter("phonenumber", [function () {
        return function (number) {
            if (!number) {
                return "";
            }
            
            if (!number) { return ""; }
            
            // Will return formattedNumber. 
            // If phonenumber isn"t longer than an area code, just show number
            var formattedNumber = number;
            
            if ( number.substring(0,2) === "55" ) {
            	var blah = Math.ceil( ( number.length - 4 ) / 2 );
            	formattedNumber = "+" + number.substring(0,2) + " (" + number.substring(2,4) + ") " +
            		number.substring(4, 4 + blah) + "-" + number.substring(4 + blah);
            }
            
            if ( number[0] === "1" ) {
            	// if the first character is "1", strip it out and add it back
            	var c = "1 ";
            	number = number[0] === "1" ? number.slice(1) : number;
            	
            	// # (###) ###-#### as c (area) front-end
            	var area = number.substring(0,3);
            	var front = number.substring(3, 6);
            	var end = number.substring(6, 10);
            	
            	if (front) {
            		formattedNumber = (c + "(" + area + ") " + front);	
            	}
            	if (end) {
            		formattedNumber += ("-" + end);
            	}
            }
 
			return formattedNumber;
        };
    }]);
});