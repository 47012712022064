define(["../templateFactoryModule", "angular"], function (templateFactoryModule, angular) {
    "use strict";

    /**
     * @ngdoc object
     * @name templateFactoryDateTimeTemplate.class:TemplateFactoryConfiguration
     * @property {*} model
     * Model que será afetado quando houverem alterações no `datetimepicker` associado
     **/

    /**
     * @ngdoc function
     * @name templateFactoryDateTimeTemplate.templateFactoryDateTimeTemplate
     * @description
     * Template utilizado para criar um `input` do tipo `datetimepicker`.
     *
     * @param {TemplateFactoryConfiguration} config Configuração para preencher o template.
     * @returns {jquery} Template preenchido.
     **/
    return templateFactoryModule.service("templateFactoryDateTimeTemplate", [function () {
        return function (config) {
            var element = angular.element("<datetimepicker>");
            if (config.model) {
                element.attr("ng-model", config.model);
            }
            if (config.ngDisabled) {
                element.attr("ng-disabled", config.ngDisabled);
            }
            return element;
        };
    }]);
});