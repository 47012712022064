import loadUnloadDurationModule from "./loadUnloadDurationModule"
import server from "../server"

loadUnloadDurationModule.service("loadUnloadDurationService", ["$http", function($http) {

    return {
        getLoadUnloadDurationConfig: getLoadUnloadDurationConfig,
        createOrUpdateConfig: createOrUpdateConfig
    }

    function getLoadUnloadDurationConfig() {
        return $http.get(server.getServiceUrl("load-unload-duration"))
            .then((response) => response.data)
    }

    function createOrUpdateConfig (loadUnloadDurationConfig)  {
        return $http.post(server.getServiceUrl("load-unload-duration"), loadUnloadDurationConfig)
    }
}])