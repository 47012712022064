define(["./mapsModule"], function (mapsModule) {
    "use strict";

    return mapsModule.directive("mapControl", ["$q", function ($q) {
        return {
            restrict: "A",
            controller: [function () {
                var mapControl = {};
                this.registerMapControl = function (mapControlRegistry) {
                    mapControl = mapControlRegistry;
                };

                this.getMapControl = function () {
                    return mapControl.initialized.then(function () {
                        return $q.resolve(mapControl);
                    });
                };
            }]
        };
    }]);
});