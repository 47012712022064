define(["./threeModule", "three", "../arrays/arrays",], function(threeModule, THREE, arrays){
    "use strict";

    var DEGRES_90 = Math.PI/2;

    return threeModule.service("threeAxleMeshFactory", ["Object3DInfo", function(Object3DInfo){
        var services = {};

        services.drawAxles = function(vehicle, object){
            var width = object.dimensions.width;
            var height = object.dimensions.height;
            var depth = object.dimensions.length;

            var axleMaterial = new THREE.MeshLambertMaterial({
                color: 0x000,
                side: THREE.DoubleSide
            });

            var axlesDistances = object.extraInfo[Object3DInfo.AXLE_DISTANCES];
            arrays.each(axlesDistances, drawTire.bind(null, -1));
            arrays.each(axlesDistances, drawTire.bind(null, 1));

            return vehicle;

            function drawTire(side, distance) {
                var axleRadius = 600,
                    axleDepth = 500;

                var axle = new THREE.Mesh(new THREE.CylinderGeometry(axleRadius, axleRadius, axleDepth, 32, 20), axleMaterial);
                vehicle.add(axle);
                axle.position.x = -side * (width - axleDepth) / 2;
                axle.position.y = -axleRadius - 50 - height / 2;
                axle.position.z = distance - depth / 2;
                axle.rotation.z = DEGRES_90;
            }
        };

        return services;
    }]);
});