import reportModule from "./reportModule"
import queryReportView from "./queryReportView.html"

reportModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.report", {
        url: "/report/query",
        template: queryReportView,
        controller: "queryReportController",
        data: {
            pageTitle: "{{'title.report' | translate}}"
        }
    })
}])
