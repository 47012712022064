define([
    "./restrictionConfigurationModule",
    "json!../tdnByEntity.json"
], function (module, tdnByEntity) {
    "use strict";
    return module.controller("restrictionConfigurationController", [
        "$scope",
        "$state",
        "transitionService",
        "restrictionConfigurationService",
        "restriction",
        "tdnHelp",
        function ($scope, $state, transitionService, restrictionConfigurationService, restriction, tdnHelp) {
            $scope.entity = restriction;
            $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["restriction-configuration"];
            $scope.getMonitorableTypes = () => [
                "br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType.TRIP",
                "br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType.INVOICE",
                "br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType.DOCUMENT",
                "br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType.LOAD"];

            $scope.clearTransitions = function (record) {
                record.transitionName = undefined
            }

            $scope.isValidPayload = function () {
                return $scope.entity.transitionsRestrictionConfiguration.every((config) => {
                    return config.monitorableType && config.transitionName;
                })
            };

            $scope.getTransitions = function (monitorableType) {
                var type = monitorableType.replace("br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType.", "");
                return transitionService.getTransitionsByMonitorableType(type);
            }

            $scope.addConfig = function () {
                $scope.entity.transitionsRestrictionConfiguration.push({
                    monitorableType: null,
                    transitionName: "",
                    attachmentMandatoryToFinalize: false,
                    autoEnableCamOnFinalize: false,
                    automaticComment: "",
                });
            };

            $scope.removeConfigAt = function (index) {
                $scope.entity.transitionsRestrictionConfiguration.splice(index, 1);
            };

            $scope.save = function () {
                return restrictionConfigurationService.save($scope.entity);
            };
        }
    ]);
});
