import {configParamsModule} from "./configParamsModule";
import {IHttpService, IPromise} from "angular";
import {PersistentProxyConfiguration} from "./model/persistentProxyConfiguration";
import {RemoteServicesConfigParams} from "./model/remoteServicesConfigParams";
import {MutableConfigParams} from "./model/mutableConfigParams";
import {ConfigFunctionalities} from "./model/configFunctionalities";
import {integration, server} from "../server";
import {userManagementServer} from "../userManagementServer";
import {ExceptionMessage, PatchInfo} from "../../bower_components/front-end-web-commons";
import {IntegrationValidatorResult} from "./model/integrationValidatorResult";
import {PermissionGroupCreateParams} from "./permissionGroup/PermissionGroupCreateParams";
import {
    InstanceTypeWithRemoteServicesConfigParams
} from "./remoteRouting";

export class ConfigParamsService {
    constructor(private $http: IHttpService) {
    }

    getConfigParams(): IPromise<MutableConfigParams> {
        return this.$http.get<MutableConfigParams>(server.getServiceUrl("config-params")).then(response => response.data);
    }

    getRemoteServicesConfigParams(): IPromise<RemoteServicesConfigParams[]> {
        return this.$http.get<RemoteServicesConfigParams[]>(server.getServiceUrl("config-params.remote-config-params")).then(response => response.data);
    }

    updateRemoteServicesConfigParams(remoteServicesConfigParams: RemoteServicesConfigParams[]): IPromise<void> {
        return this.$http.post<void>(server.getServiceUrl("config-params.remote-config-params"), remoteServicesConfigParams).then(response => response.data);
    }

    getDefaultProxyConfig() {
        return this.$http.get<PersistentProxyConfiguration>(server.getServiceUrl("config-params.default-proxy")).then(response => response.data);
    }

    getDefaultRemoteServer() {
        return this.$http.get<RemoteServicesConfigParams>(server.getServiceUrl("config-params.default-remote-server")).then(response => response.data);
    }

    clearServerCache() {
        return this.$http.delete<void>(server.getServiceUrl("config-params.cache")).then(response => response.data);
    }

    clearIntegrationCache() {
        return this.$http.delete<void>(integration.getServiceUrl("cache")).then(response => response.data);
    }

    validateRemoteServicesConfigParams(remoteServices: RemoteServicesConfigParams[]) {
        return this.$http.post<void>(server.getServiceUrl("config-params.validate-remote-services"), remoteServices).then(response => response.data);
    }

    checkForScript(numberOfRow: number): IPromise<void> {
        return this.$http.post<void>(server.getServiceUrl("config-params.check-for-script"), numberOfRow).then(response => response.data);
    }

    updateConfigParams(patches: PatchInfo[], version: number) {
        return this.$http.post<void>(server.getServiceUrl("config-params.update"), patches, {params: {version}}).then(response => response.data);
    }

    getBiConfigurations() {
        return this.$http.get<any>(server.getServiceUrl("microstrategy-configuration")).then(response => response.data);
    }

    saveBiConfigurations(configurations) {
        return this.$http.post<void>(server.getServiceUrl("microstrategy-configuration"), configurations).then(response => response.data);
    }

    isBiAllowed() {
        return this.$http.get<boolean>(server.getServiceUrl("microstrategy-is-allowed")).then(response => response.data);

    }

    getUsers() {
        return this.$http.post<any>(server.getServiceUrl("r2-all-users"), null).then(response => response.data);
    }

    updateIgnoringWarnings(patches: PatchInfo[], version: number) {
        return this.$http.post<void>(server.getServiceUrl("config-params.update-ignoring-warnings"), patches, {params: {version}}).then(response => response.data);
    }

    testRoutingConnection(remoteServicesSet: RemoteServicesConfigParams[]) {
        return this.$http.post<ExceptionMessage>(server.getServiceUrl("config-params.test-routing-connection"), remoteServicesSet).then(response => response.data);
    }

    testRoutingParallelism(instaceTypeWithUrl: InstanceTypeWithRemoteServicesConfigParams) {
        return this.$http.post<ExceptionMessage>(server.getServiceUrl("orchestrator.test-routing-parallelism"), instaceTypeWithUrl).then(response => response.data);
    }

    getIntegrationDataSources() {
        return this.$http.get<string[]>(integration.getServiceUrl("validator.dataSources")).then(response => response.data);
    }

    getAvailableInterfaces() {
        return this.$http.get<string[]>(integration.getServiceUrl("validator.interfaces")).then(response => response.data);
    }

    testPublish(interfaceName: string, integrationDataSourceId: string) {
        return this.$http.post<IntegrationValidatorResult>(integration.getServiceUrl("validator"), null, {
            params: {
                interface: interfaceName,
                integrationDataSourceId
            }
        }).then(response => response.data);
    }

    findPermissionGroupsBySourceId(params: string) {
        return this.$http.post<any[]>(userManagementServer.getServiceUrl("permission-group.find"), {
            params: {
                string: params
            }
        }).then(response => response.data)
    }

    createPermissionGroup(params: PermissionGroupCreateParams) {
        return this.$http.post<any[]>(userManagementServer.getServiceUrl("permission-group"), params)
            .then(response => response.data)
    }

    calculateCode(params: any) {
        return this.$http.post<number>(userManagementServer.getServiceUrl("permission-group-calculate-code"),
            params
        ).then(response => response.data)
    }

    purgeProgrammingDatabase() {
        const data = {};
        return this.$http.post<void>(server.getServiceUrl("purge-programming-database"), data)
            .then(response => response.data)
    }

    purgeExecutionDatabase() {
        const data = {};
        return this.$http.post<void>(server.getServiceUrl("purge-execution-database"), data)
            .then(response => response.data)
    }

    findConfigFunctionalities() {
        return this.$http.get<ConfigFunctionalities>(server.getServiceUrl("config-functionalities-find"), {})
            .then(response => response.data)
    }

    saveConfigFunctionalities(configFunctionalities: ConfigFunctionalities) {
        return this.$http.post<void>(server.getServiceUrl("config-functionalities-save"), configFunctionalities)
            .then(response => response.data)
    }
}

configParamsModule.service("configParamsService", ConfigParamsService)