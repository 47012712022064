/**
 * Métodos utilitários para manipular arrays.
 * @module infra/arrays
 */
define(["jquery", "angular"], function arrays($, angular) {
    "use strict";

    var isArray = angular.isArray,
        equals = angular.equals,
        map = $.map;

    /**
     * @typedef {(Array|Object)} Iterable
     *
     * Iteráveis podem ser arrays, ou objetos.
     * Quando Arrays, a iteração ocorre sobre as propriedades numeradas do array, pelo número de iterações da propriedade length,
     * em order crescente.
     *
     * Quando Objects, a iteração ocorre sobre qualquer propriedade do objeto (com exceção de propriedades herdadas),
     * sem ordem determinística.
     */

    /**
     * @callback Iterator
     * @param {*} value Valor da iteração atual, ou valor da propriedade atual.
     * @param {int|String} index Índice da iteração atual, ou nome da propriedade atual.
     * @param {Iterable} iterable Iterable que está sendo percorrido.
     * @this value É o valor da iteração atual. Atenção: este valor sofre auto-box caso seja uma propriedade primitiva.
     *
     * Um Iterator é uma função que é executada à cada iteração de um laço executado em um iterable.
     * */

    /**
     * Executa o iterator para cada elemento do iterable.
     *
     * É possível parar a execução do loop retornando {@link each.BREAK} no iterator.
     * Também é possível pular para a próxima iteração retornando {@link each.CONTINUE} no iterator.
     *
     * @param {Iterable} iterable que será percorrido.
     * @param {Iterator} iterator à ser executado
     */
    function each(iterable, iterator) {
        $.each(iterable, iteratorFunction);

        // inverte os parametros para seguir o padrão do Iterator
        function iteratorFunction(index, value) {
            return iterator.call(value, value, index, iterable);
        }
    }

    /**
     * Valor que deve ser retornado pela callback executada pelo método "each" para que o loop seja interrompido.
     * @type {boolean}
     */
    each.BREAK = false;

    /**
     * Valor que deve ser retornado pela callback executada pelo método "each" para que pular para a próxima iteração do loop.
     * @type {boolean}
     */
    each.CONTINUE = !each.BREAK;

    /**
     * Remove todos os elementos de um array.
     *
     * @param {Array} array
     */
    function clear(array) {
        array.length = 0;
    }

    /**
     * Verifica se um array contém um objeto qualquer, ou se um object possui uma propriedade com um valor qualquer.
     *
     * @param {Iterable} iterable à ser percorrido em busca do valor.
     * @param {*} value valor buscado.
     * @returns {boolean} true se o valor existe no iterable e false caso contrário.
     */
    function contains(iterable, value) {
        var result = false;
        each(iterable, function (val) {
            return !(result = equals(val, value));
        });
        return result;
    }


    /**
     * Filtra os elementos de um array, ou propriedades de um objeto.
     *
     * Sempre retorna um array ou objeto novo, e não muda o objeto ou array fornecido.
     *
     * @param {Iterable} iterable
     * @param {Iterator} predicate Função que retorna um valor truthy para os valores que devem ser mantidos no retorno da função.
     * @returns {*} Novo array ou object com as propriedades filtradas.
     */
    function filter(iterable, predicate) {
        return isArray(iterable) ? $.grep(iterable, predicateFunction) :
            filterObject(iterable);

        function filterObject(object) {
            var ret = {};
            each(object, function (value, key) {
                if (predicateFunction(value, key)) {
                    ret[key] = value;
                }
            });
            return ret;
        }

        // faz seguir o padrão iterator, passando value como this da chamada
        function predicateFunction(value, idx) {
            return predicate.call(value, value, idx, iterable);
        }
    }

    /**
     * @function split retorna um array de 2 dimensões, de maneira que o tamanho da segunda dimensão não exceda o limite
     * especificado. Não modifica o array original.
     *
     * @param {Array} array array a ser dividido
     * @param {Number} size tamanho máximo da segunda dimensão
     * @returns {Array} Novo array com 2 dimensões
     */
    function split(array, size) {
        if (!angular.isArray(array)) {
            throw new Error("First argument must be array.");
        }
        if (!angular.isNumber(size) || size <= 0) {
            throw new Error("Second argument must be a positive integer.");
        }
        if (size >= array.length) {
            return [array];
        }

        var result = [];
        var elements = angular.copy(array);
        var remaining;
        while (elements.length > size) {
            remaining = elements.splice(size);
            result.push(elements);
            elements = remaining;
        }
        if (remaining.length > 0) {
            result.push(remaining);
        }
        return result;
    }

    /**
     * @function
     * Troca duas casas de um array.
     * @param {object[]} array Array para trocar as posições.
     * @param {number} oldIndex Índice que será trocado.
     * @param {number} newIndex Índice que será trocado.
     * */
    function swap(array, oldIndex, newIndex) {
        if (isIndexOutOfBounds(array, oldIndex) || isIndexOutOfBounds(array, newIndex)) {
            throw new Error("Indexes " + oldIndex + " or " + newIndex + " is out of bounds for array [" + array.join(", ") + "]");
        }

        var temp = array[newIndex];
        array[newIndex] = array[oldIndex];
        array[oldIndex] = temp;
    }

    /**
     * @function
     * Insere um valor num array, em uma posição fixa.
     * @param {object[]} array Array para inserir o valor.
     * @param {number} index Índice onde o valor deve ser inserido.
     * @param {*} value Valor a ser inserido.
     * */
    function insertAt(array, index, value) {
        if (isIndexOutOfBounds(array, index)) {
            throw new Error("Indexes " + index + " is out of bounds for array [" + array.join(", ") + "]");
        }

        array.splice(index, 0, value);
    }

    /**
     * @function
     * Remove um elemento de um array, fazendo uma busca pelo índice com indexOf.
     * @param {object[]} array Array para remover o índice.
     * @param {object} object Objeto que será removido.
     * @returns {*} O elemento que estava na posição removida.
     * */
    function remove(array, object) {
        var index = indexOf(array, object);
        if (index === -1) {
            throw new Error("Object " + object + " not found on array [" + array.join(", ") + "]");
        }

        return removeAt(array, index);
    }


    function indexOf(array, object) {
        var idx = -1;
        each(array, function (el, index) {
            if (equals(el, object)) {
                idx = index;
                return each.BREAK;
            }
        });
        return idx;
    }

    /**
     * @function
     * Remove um elemento de um array em um índice específico
     * @param {object[]} array Array para remover o índice.
     * @param {number} index Índice que será removido.
     * @returns {*} O elemento que estava na posição removida.
     * */
    function removeAt(array, index) {
        if (isIndexOutOfBounds(array, index)) {
            throw new Error("Indexes " + index + " is out of bounds for array [" + array.join(", ") + "]");
        }

        return array.splice(index, 1)[0];
    }

    /**
     * @function
     * Retorna um  novo array que contém os itens do primeiro
     * array que não estão presentes no segundo array.
     * @param {object[]} first Primeiro array de itens.
     * @param {object[]} second Segundo array de itens.
     * @returns {object[]} Array que possui os itens do primeiro array, que não estão no segundo array.
     * */
    function minus(first, second) {
        return filter(first, function (option) {
            return !contains(second, option);
        });
    }

    /**
     * @function
     * Retorna uma  cópia de um array.
     * @param {object[]} array Array a ser copiado.
     * @returns {object[]} Novo array.
     * */
    function copy(array) {
        return Array.prototype.slice.call(array);
    }

    /**
     * @function
     * @param {object[]} array Array à ser checado.
     * @param {number} index Indíce à ser checado.
     * @returns {boolean} true caso o índice esteja fora array e false caso contrário.
     * */
    function isIndexOutOfBounds(array, index) {
        return index < 0 || index >= array.length;
    }

    /**
     * @function
     * @description
     * Quebra um array em partições, sendo que o primeiro elemento da partição N + 1
     * é o último elemento da partição N.
     *
     * @param {object[]} array Array à ser quebrado em partições.
     * @param {number} partitionSize Tamanho máximo das partições.
     * @returns {object[][]} Array com as partições
     * */
    function continuousPartition(array, partitionSize) {
        if (partitionSize < 2) {
            throw new Error("partitionSize of " + partitionSize + " must be greather than 1");
        }
        var originArray = copy(array);
        if (originArray.length < 2) {
            return [originArray];
        }
        var chunks = [];
        while (originArray.length > 1) {
            var items = originArray.splice(0, partitionSize);
            chunks.push(items);
            originArray.unshift(items[items.length - 1]);
        }
        return chunks;
    }

    /**
     * @function
     * @description
     * Itera sobre os dois arrays especificados, aplicando a função da callback para cada elemento de ambos os arrays.
     * A função de callback especificada deverá possuir dois argumentos,
     * sendo o primeiro argumento que representará o elemento de índice X do primeiro array e o segundo argumento o elemento de índice X do segundo array.
     * Ambos os arrays devem ter o mesmo tamanho para utilização desta função.
     * @param {object[]} firstArray Primeiro array.
     * @param {object[]} firstArray Segundo array.
     * @param {function(object, object} callback Função de callback que será aplicada a ambos os elementos dentro de uma iteração.
     * */
    function biIterate(firstArray, secondArray, callback) {
        if (firstArray.length !== secondArray.length) {
            throw new Error("Both arrays must be the same length to biIterate");
        }
        each(firstArray, function (element, index) {
            return callback(element, secondArray[index]);
        });
    }

    /**
     * @function
     * Versão in-place de concat. Adiciona todos elements no final do array informado.
     * @param {object[]} array Array de referência, a ter os elementos adicionados.
     * @returns {object[]} elements Array de elementos a serem adicionados no final de 'array'.
     * */
    function addAll(array, elements) {
        return Array.prototype.push.apply(array, elements);
    }

    /**
     * @function
     * @description
     * Verifica se dois arrays são disjuntos, isto é,
     * se todos os elementos de um array não estão contidos
     * no outro.
     *
     * @param {*[]} c1 primeiro conjunto.
     * @param {*[]} c2 segundo conjunto.
     * @returns {boolean} true if arrays are disjoin.
     */
    function disjoint(c1, c2) {
        var smallerCollection = c2;
        var iterate = c1;

        var c1size = c1.length;
        var c2size = c2.length;
        if (c1size === 0 || c2size === 0) {
            return true;
        }

        if (c1size > c2size) {
            iterate = c2;
            smallerCollection = c1;
        }

        for (var i = 0; i < iterate.length; i++) {
            if (contains(smallerCollection, iterate[i])) {
                return false;
            }
        }
        return true;
    }

    return {
        "each": each,
        "clear": clear,
        "filter": filter,
        "isArray": isArray,
        "contains": contains,
        "split": split,
        "map": map,
        "swap": swap,
        "indexOf": indexOf,
        "insertAt": insertAt,
        "remove": remove,
        "removeAt": removeAt,
        "minus": minus,
        "copy": copy,
        "isIndexOutOfBounds": isIndexOutOfBounds,
        "continuousPartition": continuousPartition,
        "biIterate": biIterate,
        "disjoint": disjoint,
        "addAll": addAll
    };

});
