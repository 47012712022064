define([
    "./secondaryDevicesModal/secondaryDevicesModal",
    "./monitorableModule",
    "./monitorableRouter",
    "./monitorableService",
    "./MonitorableSearchController",
    "./MonitorableEditionController",
    "./trackMonitorableModal",
    "./monitorableItemExtensionDialog",
    "./tpf/tpfModal"
], function () {
});