import unblockTripMessagesModule from "./unblockTripMessagesModule"
import server from "../server"

unblockTripMessagesModule.service("unblockTripMessagesService", ["$http", function searchService($http) {

    this.filter = (entityType, template, firstResult, maxResults) => {
        return $http.post(server.getServiceUrl("search." + entityType) + "presentation-tree", template, {
            params: {
                firstResult,
                maxResults
            }
        }).then((response) => response.data)
    }

    this.find = (recordId) => {
        return $http.get(server.getServiceUrl("unblockTripMessages") + recordId)
            .then((response) => response.data)
    }

    this.forceProcessMessage = (recordId) => {
        return $http.get(server.getServiceUrl("unblockTripMessages-forceProcess") + recordId)
            .then((response) => response.data)
    }

    this.resendMessage = (recordId) => {
        return $http.get(server.getServiceUrl("unblockTripMessages-resendMessage") + recordId)
            .then((response) => response.data)
    }
}])