define([
    "./genericParamsModule",
    "../arrays/arrays",
    "../objects/objects",
    "angular",
    "text!./viewTypeChangeConfirm.html",
], function (crudModule, arrays, objects, angular, template) {
    "use strict";

    return crudModule.controller("GenericParamDataController", [
        "$scope",
        "genericParamMetadataService",
        "messagesModal",
        "loadingService",
        "remoteExceptionHandler",
        "genericParamDataService",
        "$modal",
        "genericParamsUrls",
        "genericParamAutocompleteService",
        "autocompleteFactory",
        "filterServiceFactory",
        "filterDomain",
        function ($scope, genericParamMetadataService, messagesModal, loadingService, remoteExceptionHandler, genericParamDataService, $modal, genericParamsUrls, genericParamAutocompleteService, autocompleteFactory, filterServiceFactory, filterDomain) {
            $scope.hideButtonsColumn = $scope.hideButtonsColumn ? $scope.hideButtonsColumn : false;

            $scope.components = null;
            $scope.patchInfo = [];

            var filterService = $scope.filterService || filterServiceFactory($scope.domain || filterDomain.getDefaultDomain());

            var originalDatasById = null,
                editionData = null,
                metadataComponentMapping = null;

            clearEdition();

            var filterPatch = [];
            var paginationApi = null;

            var transferableGenericParamApi = null;

            $scope.layoutControl = {
                tableViewActive: false,
                transferViewActive: false,
                useTableView: function () {
                    var self = this;
                    if (transferableGenericParamApi && !transferableGenericParamApi.isEditing()) {
                        self.tableViewActive = true;
                        self.transferViewActive = false;
                    } else {
                        $modal.open({
                            template: template
                        }).result.then(function () {
                            self.tableViewActive = true;
                            self.transferViewActive = false;
                        });
                    }
                },
                useTransferView: function () {
                    this.transferViewActive = true;
                    this.tableViewActive = false;
                }
            };

            $scope.onRegisterTransferableGenericParamApi = function (api) {
                transferableGenericParamApi = api;
            };

            $scope.tableOptions = {
                records: [],
                columns: [],
                pagination: {
                    onPaginate: function (firstResult, maxResults) {
                        return loadingService(filterService.doFilter($scope.filterServiceUrl, filterPatch, {
                            firstResult: firstResult,
                            maxResults: maxResults
                        }).catch(remoteExceptionHandler()))
                            .then(function (resp) {
                                var data = resp.data;
                                data.result = editionData.toCreate.concat(data.result);
                                data.result = arrays.map(data.result, function (data) {
                                    if (editionData.toUpdate[data.id]) {
                                        return editionData.toUpdate[data.id];
                                    }
                                    // a deep copy é necessária para corretude do dirty checking
                                    // na função `genericParamDataHasChanged`
                                    originalDatasById[data.id] = angular.copy(data);
                                    return (editionData.toUpdate[data.id] = data);
                                });
                                data.result = arrays.filter(data.result, function (data) {
                                    return editionData.toDelete.indexOf(data.id) === -1;
                                });
                                $scope.patchInfo = filterPatch;
                                return data;
                            });
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                }
            };

            $scope.transfer = {
                metaInf: null,
                isTransferable: false
            };

            $scope.hasDefinition = false;
            $scope.$watch("definition", function (definition) {
                $scope.hasDefinition = !!definition;
                if (!$scope.hasDefinition) {
                    return;
                }

                metadataComponentMapping = {};
                clearEdition();
                paginationApi.clearResults();
                $scope.tableOptions.columns = [];

                $scope.filterServiceUrl = genericParamsUrls.genericParamDataUrl + "/" + definition.id;
                genericParamDataService.getComponents(definition)
                    .then(function (req) {
                        $scope.components = req.components;
                        var columns = arrays.map(req.components, function (component) {
                            var componentMetadata = objects.enrich(component.metadata, {
                                additionalInfo: {}
                            });
                            var escapedComponentName = genericParamMetadataService.getEscapedComponentName(component);
                            metadataComponentMapping[escapedComponentName] = componentMetadata;

                            return {
                                header: "generic.param.component." + escapedComponentName,
                                cell: genericParamMetadataService.createCellConfig(component),
                                cellName: component.name,
                                width: "230px"
                            };
                        });

                        if (!$scope.hideButtonsColumn) {
                            columns.unshift({
                                header: {
                                    template: "<a class='btn btn-xs green' name='addButton' style='margin-left: 5px;' ng-click='addGenericParamData()'><span class='fa fa-plus'></span></a>",
                                    disabled: "::ngDisabled"
                                },
                                cell: {
                                    template: "<a class='btn btn-xs red' name='removeButton' style='margin-left: 5px; margin-top: 4px;' ng-click='removeGenericParamData(record)' nlg-permission= 'group.manager.generic.param.definition.delete'><span class='fa fa-trash'></span></a>",
                                    disabled: "::!record.editable"
                                },
                                width: "30px"
                            });
                        }

                        $scope.tableOptions.columns = columns;
                    });

                genericParamDataService.getTransferMetaInf(definition)
                    .then(function (metaInf) {
                        if (!metaInf) {
                            $scope.layoutControl.tableViewActive = true;
                            return;
                        }
                        $scope.layoutControl.transferViewActive = true;
                        $scope.transfer.metaInf = metaInf;
                        $scope.transfer.isTransferable = true;
                    });
            });

            /**
             * Identificador negativo a ser utilizado em 'track by' de genericParamDatas.
             * Será removido ao salvar os dados do parâmetro.
             */
            var newRecordIndex = -1;
            $scope.addGenericParamData = function () {
                var newGenericParamData = {
                    editable: true,
                    id: newRecordIndex--,
                    properties: {}
                };
                if ($scope.tableOptions.records.length === 0) {
                    $scope.tableOptions.records.push(newGenericParamData);
                } else {
                    arrays.insertAt($scope.tableOptions.records, 0, newGenericParamData);
                }
                editionData.toCreate.push(newGenericParamData);
            };

            $scope.removeGenericParamData = function (genericParamData) {
                arrays.removeAt($scope.tableOptions.records, $scope.tableOptions.records.indexOf(genericParamData));
                if (editionData.toCreate.indexOf(genericParamData) !== -1) {
                    arrays.remove(editionData.toCreate, genericParamData);
                }
                /**
                 * Identificador negativo é utilizado no track by de novas tuplas.
                 */
                if (angular.isDefined(genericParamData.id) && genericParamData.id >= 0) {
                    editionData.toDelete.push(genericParamData.id);
                }
            };

            $scope.getEnums = function (enumClass) {
                return autocompleteFactory.forEnum(enumClass)();
            };

            $scope.getEntities = function (record, viewValue, escapedComponentName) {
                var metadata = metadataComponentMapping[escapedComponentName];
                return genericParamAutocompleteService(metadata, viewValue);
            };

            $scope.updateDependencies = function (record, componentName) {
                var newValue = record.properties[componentName];
                if (angular.isUndefined(newValue) || newValue === null) {
                    arrays.each(metadataComponentMapping, function (metadata, name) {
                        var dependencyInfo = metadata.dependencyInfo;
                        if (dependencyInfo && genericParamMetadataService.escape(dependencyInfo) === componentName) {
                            record.properties[name] = null;
                        }
                    });
                }
            };

            $scope.search = function (patch) {
                filterPatch = patch;
                clearEdition();
                return paginationApi.seekPage(1);
            };

            var getDatasToSave = function () {
                var toSave = {toUpdate: []};
                toSave.toCreate = angular.copy(editionData.toCreate);
                /**
                 * Esvazia os índices negativos utilizados para novos genericParamDatas.
                 */
                toSave.toCreate.forEach(function (record) {
                    delete record.id;
                });
                toSave.toDelete = editionData.toDelete;
                arrays.each(editionData.toUpdate, function (record) {
                    if (genericParamDataHasChanged(record) && toSave.toDelete.indexOf(record.id) === -1) {
                        toSave.toUpdate.push(record);
                    }
                });
                return toSave;
            };

            if ($scope.registerApi) {
                $scope.registerApi({api: getDatasToSave});
            }

            $scope.save = function () {
                var toSave = getDatasToSave();

                if (!hasChanges()) {
                    messagesModal("dialog.warning", [{
                        keyBundle: "entity.validator.noModification",
                        parameters: []
                    }]);
                    return;
                }
                loadingService(genericParamDataService.save($scope.definition, toSave))
                    .then(function () {
                        messagesModal("dialog.success", [{
                            keyBundle: "edition.genericParameters.success.save",
                            parameters: [changesQuantity(), $scope.definition.sourceId]
                        }]).finally(reload);
                    }).catch(remoteExceptionHandler());

                function changesQuantity() {
                    return toSave.toCreate.length +
                        toSave.toUpdate.length +
                        toSave.toDelete.length;
                }

                function hasChanges() {
                    return changesQuantity() > 0;
                }
            };

            function reload() {
                return $scope.search(filterPatch);
            }

            function clearEdition() {
                originalDatasById = {};
                editionData = {
                    toCreate: [],
                    toUpdate: {},
                    toDelete: []
                };
            }

            function genericParamDataHasChanged(genericParamData) {
                var originalData = originalDatasById[genericParamData.id];
                return !!originalData && !angular.equals(originalData, genericParamData);
            }
        }]);
});