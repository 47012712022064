import availabilityModule from "./availabilityModule"
import modalView from "./availabilityModalView.html"

availabilityModule.service("availabilityModalService", [
    "$modal",
    "autocompleteFactory",
    "loadingService",
    "remoteExceptionHandler",
    "$http",
    "messagesModal",
    "availabilityService",
    ($modal, autocompleteFactory, loadingService, remoteExceptionHandler, $http, messagesModal, availabilityService) => {

    const show = (vehicle, origin) => {
        return $modal.open({
            template: modalView,
            size: "lg",
            backdrop: "static",
            controller: ["$scope", "$q", ($scope, $q) => {
                $scope.vehicleId = vehicle.id
                $scope.availability = getVehicleAvailabilities(vehicle, origin)
                $scope.getCountries = autocompleteFactory.lazyLoader("country")
                $scope.getStates = autocompleteFactory.lazyLoader("state")
                $scope.getCities = autocompleteFactory.lazyLoader("city")

                $scope.onCountryChanged = (plate) => {
                    plate.state = null
                    plate.city = null
                }
                $scope.onStateChanged = (plate) => {
                    plate.city = null
                }
                $scope.createNewPlate = () => {
                    return {
                        city: null,
                        plate: null
                    }
                }

                $scope.removePlate = (plate, indexOf) => {
                    if(indexOf !== -1) {
                        $scope.tableOptions.records.splice(indexOf, 1)
                        const trucks = $scope.availability.availabilityPlates
                        const index = trucks.indexOf(plate)
                        if(index !== -1) {
                            trucks.splice(index, 1)
                        }
                    }
                }

                $scope.save = (availability) => {
                    const trucks = $scope.availability.availabilityPlates

                    availability.forEach(newAvailability => {
                        if (!trucks.includes(newAvailability)) {
                            trucks.push(newAvailability)
                        }
                    })

                    const fieldValidation=validateThatFieldsAreNotEmpty(trucks)
                    if(fieldValidation===true){
                        return messagesModal("dialog.warning", [{
                            keyBundle: "vehicle.edition.AvaliabilityFieldsEmpty",
                            parameters: []
                        }])
                    }
                    const plate = onlyUniquePlates(trucks)
                    if (plate !== true) {
                        return messagesModal("dialog.warning", [{
                            keyBundle: "vehicle.edition.TruckPlateAlreadyExists",
                            parameters: [plate]
                        }])
                    }
                    const vehicleAvailability = {
                        vehicleId: $scope.vehicleId,
                        availability: $scope.availability
                    }
                    availabilityService.saveVehicleAvailability(vehicleAvailability).then(() => {
                        messagesModal("dialog.success", [{
                            keyBundle: "entity.validator.successMessage",
                            parameters: []
                        }]).finally(() => {
                            $scope.$close()
                        })
                    })
                }

                $scope.platesQuantity = $scope.availability.availabilityPlates.length
                $scope.tableOptions = {
                    allRecords: $scope.availability.availabilityPlates,
                    records: [],
                    pagination: {
                        maxPageSizes: [10, 25, 50, 100],
                        maxPageSize: 10,
                        onPaginate: function (firstResult, maxResults) {
                            return $q.when($scope.tableOptions.allRecords.slice(firstResult, firstResult + maxResults))
                                .then(function (data) {
                                    return {
                                        firstResult: firstResult,
                                        maxResults: maxResults,
                                        count: $scope.platesQuantity,
                                        result: data
                                    }
                                })
                        }
                    },
                    onRegisterPagination: function (api) {
                        api.seekPage(1)
                    }
                }

                function getVehicleAvailabilities(vehicle, origin) {
                    const vehicleAvailabilities = vehicle.vehicleAvailabilities
                    if (vehicleAvailabilities && vehicleAvailabilities.length === 1) {
                        return vehicleAvailabilities[0]
                    }
                    vehicle.vehicleAvailabilities = {
                        "origin": origin,
                        "additionalAvailability": 0,
                        "availabilityPlates": []
                    }
                    return vehicle.vehicleAvailabilities
                }

                function onlyUniquePlates(trucks) {
                    const set = new Set()
                    for(let i = 0; i < trucks.length; i++) {
                        const plate = trucks[i].plate
                        if(set.has(plate)) {
                            return plate
                        } else {
                            set.add(plate)
                        }
                    }
                    return true
                }

                function validateThatFieldsAreNotEmpty(trucks){
                    let fieldEmpty=false
                    for(let i=0; i< trucks.length; i++){
                        fieldEmpty=trucks[i].plate===null || trucks[i].country===null
                            || trucks[i].state===null || trucks[i].city===null
                        if(fieldEmpty){
                           return fieldEmpty
                        }
                    }
                    return fieldEmpty
                }

            }]
        }).result
    }

    return {
        show: show
    }
}])