define(["./nodeCommandsModule"], function (nodeCommandsModule) {
    "use strict";

    return nodeCommandsModule.service("nodeCommandCalculateToll", [
        "tollService",
        "remoteExceptionHandler",
        function (tollService, remoteExceptionHandler) {
            return function (selection, context) {
                if (selection && selection.length > 0) {
                    return tollService.calculateTollFromNodeIdentifiers(selection, context)
                        .catch(remoteExceptionHandler())
                        .catch(function () {
                            context.refilter();
                        });
                }
            };
        }
    ]);
});