define(["./commonsOperationModule",
    "text!./preViolationModal.html",
    "../objects/objects",
    "../arrays/arrays",
    "lodash",
    "angular"
], function (commonsOperationModule, preViolationModalTemplate, objects, arrays, _, angular) {
    "use strict";

    /**
     * @ngdoc service
     * @name operationModule.violationModal
     * @description
     * Serviço que exibe para o usuário as violações de uma operação manual e permite
     * que o usuário continue com o fluxo caso possível.
     *
     * @param {manualResult} manualResult Resultado de uma operação manual.
     * @return {Promise} promessa que é resolvida com o próprio `manualResult` fornecido.
     * */
    return commonsOperationModule.service("preViolationModal", [
        "$modal",
        "$http",
        "Set",
        "loadingService",
        "remoteExceptionHandler",
        "pathService",
        function ($modal, $http, Set, loadingService, remoteExceptionHandler, pathService) {
            var open = function(validationMessages, configs, entitiesToOperate) {
                return $modal.open({
                    size: "lg",
                    template: preViolationModalTemplate,
                    controller: ["$scope", function ($scope) {
                        $scope.hardViolations = validationMessages.hardViolations;
                        $scope.softViolations = validationMessages.softViolations;
                        $scope.hardOrderViolations = validationMessages.hardOrderAdjustments;
                        $scope.softOrderViolations = validationMessages.softOrderAdjustments;
                        var additionalDeliveryUnits = [];

                        $scope.selectedLocalities = new Set();

                        $scope.getLocalityUrl = getLocalityUrl;

                        $scope.hasOptimizables = function () {
                            var hasDeliveryUnits = !!entitiesToOperate.deliveryUnits.length;
                            var hasTrips = !!entitiesToOperate.trips.length;
                            return hasTrips || hasDeliveryUnits;
                        };

                        $scope.reload = function () {
                            $scope.$dismiss({
                                trips: entitiesToOperate.trips,
                                deliveryUnits: entitiesToOperate.deliveryUnits,
                                additionalDeliveryUnits: additionalDeliveryUnits,
                                status: "reload"
                            });
                        };

                        var adjustableViolations = validationMessages.hardLocalityAdjustments.concat(validationMessages.softLocalityAdjustments);

                        $scope.autoAdjust = function () {
                            loadingService($http.post(pathService.getPath("geocodeAndStamp"), $scope.selectedLocalities.toArray())
                                .then($scope.reload)
                                .catch(remoteExceptionHandler()));
                        };

                        $scope.removeDeliveryUnits = function () {
                            $scope.selectedLocalities.toArray().forEach(function (selectedLocality) {
                                $scope.tableOptions.records.forEach(function (adjustableViolation) {
                                    if (adjustableViolation.locality.id === selectedLocality.id) {
                                        entitiesToOperate.deliveryUnits = _.difference(entitiesToOperate.deliveryUnits, adjustableViolation.affectedDeliveryUnitsIds);
                                        arrays.remove($scope.tableOptions.records, adjustableViolation);

                                        $scope.tableOptions.records.forEach(function (record) {
                                            record.affectedDeliveryUnitsIds = _.difference(record.affectedDeliveryUnitsIds, adjustableViolation.affectedDeliveryUnitsIds);
                                            if (!record.affectedDeliveryUnitsIds.length) {
                                                arrays.remove($scope.tableOptions.records, record);
                                            }
                                        });
                                    }

                                    $scope.hardViolations.forEach(clearViolationsForAffectedLocalities.bind(null, $scope.hardViolations));
                                    $scope.hardViolations.forEach(removeAffectedDeliveryUnits.bind(null, adjustableViolation.affectedDeliveryUnitsIds));
                                    $scope.softViolations.forEach(clearViolationsForAffectedLocalities.bind(null, $scope.softViolations));

                                    function removeAffectedDeliveryUnits(affectedDeliveryUnitsIds, violation) {
                                        violation.affectedDeliveryUnitsIds = _.difference(violation.affectedDeliveryUnitsIds, affectedDeliveryUnitsIds);
                                    }

                                    function clearViolationsForAffectedLocalities(violationsCollection, violation) {
                                        var index = arrays.indexOf(violation.affectedLocalities, selectedLocality);
                                        if (index >= 0) {
                                            arrays.removeAt(violation.affectedLocalities, index);

                                            if (!violation.affectedLocalities.length) {
                                                arrays.remove(violationsCollection, violation);
                                            }
                                        }
                                    }
                                });
                            });
                            $scope.selectedLocalities.clear();
                            $scope.reload();
                        };

                        var selectAllAdjustments = false;
                        $scope.selectAll = function () {
                            selectAllAdjustments = !selectAllAdjustments;
                            $scope.tableOptions.records.forEach(function (adjustment) {
                                adjustment.selected = selectAllAdjustments;
                                $scope.toggleSelection(adjustment);
                            });
                        };

                        $scope.isAllSelected = function () {
                            var result = true;
                            arrays.each($scope.tableOptions.records, function (adjustment) {
                                result = result && adjustment.selected;
                            });
                            return result;
                        };

                        $scope.toggleSelection = function (adjustment) {
                            if (adjustment.selected) {
                                $scope.selectedLocalities.add(adjustment.locality);
                            } else {
                                $scope.selectedLocalities.remove(adjustment.locality);
                            }
                        };

                        $scope.hasGeographicInfoViolation = function (adjustment) {
                            var violationTypesNames = _.map(adjustment.violationTypes, objects.getEnumName);
                            return arrays.contains(violationTypesNames, "GEOGRAPHIC_INFO");
                        };

                        $scope.hasLocalityWithoutCarrierZoneViolation = function (adjustment) {
                            var violationTypesNames = _.map(adjustment.violationTypes, objects.getEnumName);
                            return arrays.contains(violationTypesNames, "LOCALITY_WITHOUT_CARRIER_ZONE");
                        };

                        $scope.buildResult = function () {
                            return {
                                routingConfig: configs,
                                entitiesToOperate: entitiesToOperate,
                                additionalDeliveryUnits: additionalDeliveryUnits
                            };
                        };

                        $scope.selectRemainingAndClose = function () {
                            var allOrderAdjustments = validationMessages.softOrderAdjustments.concat(validationMessages.hardOrderAdjustments);
                            var deliveryUnitIds = new Set();
                            allOrderAdjustments.forEach(function (orderAdjustment) {
                                deliveryUnitIds.addAll(orderAdjustment.remainingDeliveryUnits.map(function (deliveryUnit) {
                                    return deliveryUnit.id;
                                }));
                                orderAdjustment.deliveryUnitNodeIdentifiers.forEach(function (hierarchyDuNodeIdentifier){
                                    var du = hierarchyDuNodeIdentifier.identifier;
                                    du.$parent = hierarchyDuNodeIdentifier.parent.identifier;
                                    additionalDeliveryUnits.push(du);
                                });
                            });
                            arrays.addAll(entitiesToOperate.deliveryUnits, deliveryUnitIds.toArray());
                            $scope.$close($scope.buildResult());
                        };

                        $scope.tableOptions = {
                            records: adjustableViolations,
                            columns: [{
                                width: 20,
                                header: {
                                    template: "<input class='checkbox' type='checkbox' ng-click='selectAll()' ng-checked='isAllSelected()'>"
                                },
                                cellName: "select",
                                cell: {
                                    type: "checkbox",
                                    click: "toggleSelection(record)",
                                    model: "record.selected"
                                }
                            }, {
                                width: 20,
                                header: {
                                    template: "<div class='text-center'><i class='fa fa-map-marker text-center'></i></div>"
                                },
                                cellName: "geographicInfoViolation",
                                cell: {
                                    template: "<div class='text-center'><i class='fa fa-check-circle' ng-if='!hasGeographicInfoViolation(record)'></i><i class='fa fa-warning' ng-if='hasGeographicInfoViolation(record)'></i></div>"
                                }
                            }, {
                                width: 20,
                                header: {
                                    template: "<div class='text-center'><i class='fa fa-map'></i></div>"
                                },
                                cellName: "localityWithoutCarrierZone",
                                cell: {
                                    template: "<div class='text-center'><i class='fa fa-check-circle' ng-if='!hasLocalityWithoutCarrierZoneViolation(record)'></i><i class='fa fa-warning' ng-if='hasLocalityWithoutCarrierZoneViolation(record)'></i></div>"
                                }
                            }, {
                                width: 20,
                                header: "",
                                cellName: "edit",
                                cell: {
                                    template: "<div class='text-center'><a ng-href='{{getLocalityUrl(record.locality.id, record.locality.version)}}' target='_blank'><i class='fa fa-pencil'></i></a></div>"
                                }
                            }, {
                                header: "entity.property.name",
                                cellName: "text",
                                cell: {
                                    template: "<span>{{ record.locality.name }}</span>"
                                }
                            }, {
                                header: "entity.property.description",
                                cellName: "text",
                                cell: {
                                    template: "<span>{{ record.locality.description }}</span>"
                                }
                            }]
                        };
                    }]
                }).result;
            };

            var getLocalityUrl = null;
            var setLocalityEditionCallback = function(getLocalityUrlCallback){
                if(!angular.isFunction(getLocalityUrlCallback)){
                    throw new Error("Locality url getter must be a function.");
                }
                getLocalityUrl = getLocalityUrlCallback;
            };
            return {
                open: open,
                setLocalityEditionCallback: setLocalityEditionCallback
            };
        }]);
});