define(["angular",
    "./nlgHealthCheckerModule"
], function (angular, nlgHealthCheckerModule) {
    "use strict";

    nlgHealthCheckerModule.service("nlgHealthCheckerService", [
        "$http",
        "$q",
        "monitorUrlService",
        "loadingService",
        "remoteExceptionHandler",
        function ($http, $q, monitorUrlService, loadingService, remoteExceptionHandler) {
            return {
                getSummaryInfos: function (instance, checkerNames, checkerTypes, configurations) {
                    return loadingService($http.post(monitorUrlService.getUrl(instance, "healthcheck"), {
                        checkerNames: checkerNames,
                        checkerTypes: checkerTypes,
                        detailed: false,
                        configurations: configurations
                    })
                        .catch(remoteExceptionHandler())
                        .then(getData));
                },
                getCheckerNames: function (instance) {
                    return $http.get(monitorUrlService.getUrl(instance, "healthcheck", "all"))
                        .catch(remoteExceptionHandler())
                        .then(getData);
                },
                getCheckerNamesByType: function (instance, type) {
                    return $http.get(monitorUrlService.getUrl(instance, "healthcheck", "names-by-type"), {
                        params: {
                            type: type
                        }
                    })
                        .catch(remoteExceptionHandler())
                        .then(getData);
                },
                getCheckerTypes: function (instance) {
                    return $http.get(monitorUrlService.getUrl(instance, "healthcheck", "types"))
                        .catch(remoteExceptionHandler())
                        .then(getData);
                },
                getStatus: function (instance) {
                    return $http.get(monitorUrlService.getUrl(instance, "healthcheck", "status"))
                        .catch(remoteExceptionHandler())
                        .then(getData);
                },
                getHealthInfos: function (instance, checkerName, configurations) {
                    return loadingService($http.post(monitorUrlService.getUrl(instance, "healthcheck"), {
                        checkerNames: [checkerName],
                        detailed: true,
                        configurations: configurations
                    })
                        .catch(remoteExceptionHandler())
                        .then(getData));
                },
                getConfigurations: function (instance, checkerNames, configurations) {
                    return $http.post(monitorUrlService.getUrl(instance, "healthcheck", "configurations"), {
                        checkerNames: checkerNames,
                        detailed: true,
                        configurations: configurations
                    }).then(getData);
                },
                saveConfigurations: function (instance, configurations) {
                    return $http.post(monitorUrlService.getUrl(instance, "healthcheck", "configuration-persistence"), configurations)
                        .catch(function (err) {
                            if (err && err.status && err.status === 404) {
                                return $q.reject({
                                    status: 400,
                                    data: {messages: ["healthcheck.configurations.persistenceDisabled"]}
                                });
                            }
                            return $q.reject(err);
                        })
                        .then(getData);
                },
                getFixActions: function (instance, checkerNames) {
                    return $http.post(monitorUrlService.getUrl(instance, "healthcheck", "fix-actions"), {
                        checkerNames: checkerNames
                    }).then(getData);
                },
                fix: function (instance, checkerNames, action) {
                    return $http.post(monitorUrlService.getUrl(instance, "healthcheck", "fix"), {
                        checkerNames: checkerNames
                    }, {
                        params: {
                            action: action
                        }
                    }).then(getData);
                },
                getHistoryByCheckerName: function (instance, indicatorName) {
                    return $http.get(monitorUrlService.getUrl(instance, "healthcheck", "history", "indicator"), {
                        params: {
                            name: indicatorName
                        }
                    }).then(getData);
                }
            };

            function getData(response) {
                return response.data;
            }
        }]);
});