import * as angular from "angular"
import translateModule from "./r2TranslateModule"
import locales from "./languages"

translateModule.config(["$translateProvider", "tmhDynamicLocaleProvider", function ($translateProvider, tmhDynamicLocaleProvider) {
    $translateProvider.registerAvailableLanguageKeys(["pt", "en", "es"], {
        "pt*": "pt",
        "en*": "en",
        "es*": "es"
    })
    tmhDynamicLocaleProvider.localeLocationPattern(locales.getLocalePattern())
    const urls = {}
    locales.getAllLanguages().forEach(language => urls[language.getLanguageCode()] = language.getBundleUrl())
    $translateProvider.useLoader("translateUrlMapLoader", {urls})
    $translateProvider.fallbackLanguage(["pt"])
    $translateProvider.determinePreferredLanguage(function () {
        return "pt-br"
    })
    $translateProvider.useSanitizeValueStrategy("escape")
}]).factory("translateUrlMapLoader", function ($q, $http) {
    // implementação semelhante à $translateStaticFilesLoader, mas com url fixa por linguagem
    return function (options) {
        const deferred = $q.defer()
        $http(angular.extend({
            url: options.urls[options.key],
            method: "GET",
            params: ""
        }, options.$http)).success(function (data) {
            deferred.resolve(data)
        }).error(function () {
            deferred.reject(options.key)
        })
        deferred.promise.batata = 1
        return deferred.promise
    }
}).config(["$provide", function ($provide) {
    $provide.decorator("$translate", ["$delegate", "tmhDynamicLocale", "$document", function changeLocale($delegate, tmhDynamicLocale, $document) {
        var original = $delegate.use
        $delegate.use = function (value) {
            if (angular.isString(value)) {
                var localeId = locales.get(value).getFullCode()
                tmhDynamicLocale.set(localeId)
                $document.find("html").attr("lang", localeId)
            }
            return original.apply($delegate, arguments)
        }
        return $delegate
    }])
}]).run(["$translate", "$window", function ($translate, $window) {
    $translate.use($window.navigator.language || $window.navigator.userLanguage)
}])