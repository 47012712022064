define([
    "./completionModule"
], function (completionModule) {
    "use strict";

    return completionModule.service("completionService", ["CompletionStatus", function (CompletionStatus) {
        var self = this;
        self.isActive = function (status) {
            return status === CompletionStatus.CREATED || status === CompletionStatus.IN_EXECUTION
        };
    }]);
});