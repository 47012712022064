define([
    "./emailModule",
    "text!./template/emailTemplateView.html",
], function (module, templateView) {
    "use strict";
    module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state("monitoring.emailTemplate", {
            url: "/emailTemplate?reload",
            controller: "emailTemplateController",
            template: templateView,
            resolve: {
                reload: ["$stateParams", function ($stateParams) {
                    return $stateParams.reload === "true";
                }],
                template: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                    return monitoringService.getEmailTemplate();
                }]
            },
            data: {
                group: "group.monitoring.email.template"
            }
        })
    }]);
});
