define([
    "./monitorModule",
    "text!./nlgMonitorMapping.html",
    "lodash"
], function (module, template, _) {
    "use strict";

    return module.directive("nlgMonitorMapping", [function () {
        return {
            scope: {
                instance: "<"
            },
            template: template,
            controller: [
                "$scope",
                "monitorService",
                "loadingService",
                "remoteExceptionHandler",
                "monitorMappingParser",
                function ($scope, monitorService, loadingService, remoteExceptionHandler, monitorMappingParser) {
                    $scope.mappings = [];
                    $scope.request = {
                        method: "GET",
                        url: "/",
                        headers: [],
                        body: null
                    };
                    $scope.response = null;

                    loadMappings();

                    function loadMappings() {
                        return monitorService.findEndpoint($scope.instance, "mappings")
                            .catch(remoteExceptionHandler())
                            .then(monitorMappingParser)
                            .then(function (data) {
                                $scope.mappings = _.sortBy(data, "route");
                            });
                    }

                    $scope.mappingSelected = function (mapping) {
                        $scope.request.url = mapping.route;
                        $scope.request.method = (mapping.extraInfo.methods || [])[0] || "GET";
                        $scope.request.body = null;
                        $scope.request.headers = [{
                            name: "content-type",
                            value: (mapping.extraInfo.consumes || [])[0] || "application/json"
                        }];
                        $scope.request.extraInfo = Object.assign({
                            bean: mapping.bean,
                            method: mapping.method
                        }, mapping.extraInfo);
                    };

                    $scope.executeRequest = function () {
                        $scope.response = null;

                        return loadingService(monitorService.request($scope.instance, {
                            url: $scope.request.url,
                            method: $scope.request.method,
                            headers: convertToHeaderObject($scope.request.headers),
                            body: $scope.request.body
                        }))
                            .then(function (data) {
                                $scope.response = data;
                            })
                            .catch(function (error) {
                                $scope.response = error;
                            });

                        function convertToHeaderObject(headersArray) {
                            return _.chain(headersArray)
                                .keyBy("name")
                                .mapValues("value")
                                .value();
                        }
                    };

                    $scope.addHeader = function () {
                        $scope.request.headers.push({name: "", value: ""});
                    };

                    $scope.removeHeader = function (index) {
                        $scope.request.headers.splice(index, 1);
                    };
                }]
        };
    }]);
});