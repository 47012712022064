define([
    "./monitoringQueryModule"
], function (module) {
    "use strict";
    return module.controller("monitoringQueryListController", [
        "$scope",
        "monitoringFilterService",
        function ($scope, monitoringFilterService) {
            $scope.filterServiceUrl = "transactional/report/query/";
            $scope.baseUrl = "../cockpit-gateway/monitoring";
            $scope.filterService = monitoringFilterService;
        }]);
});