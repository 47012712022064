define(["angular",
    "./nlgAnalysisTreeModule",
    "../arrays/arrays",
    "text!./nlgAnalysisTreeDirective.html",
    "lodash"
], function (angular, nlgAnalysisTreeModule, arrays, nlgAnalysisTreeTemplate, _) {
    "use strict";

    return nlgAnalysisTreeModule.directive("nlgAnalysisTree", ["$templateCache", "$timeout", "$translate", "$document", "$location", "$window", "$anchorScroll", function ($templateCache, $timeout, $translate, $document, $location, $window, $anchorScroll) {
        $templateCache.put("nlg-analysis-tree-children", nlgAnalysisTreeTemplate);
        var baseComponentClass = "nlgAnalysisTree";
        var dropTargetClass = "nlgAnalysisTreeDropTarget";
        var TIMEOUT_TO_OPEN_ON_HOVER = 700;

        var blankImage = document.createElement("img");
        blankImage.src = document.createElement("canvas").toDataURL();

        var dragAndDropOverlay = angular.element("<div>").addClass("dragAndDropOverlay").css("position", "absolute")
            .append(angular.element("<span>").addClass("dragAndDropOverlayText"))
            .append(angular.element("<span>").addClass("dragAndDropOverlayBadge"));
        var $body = angular.element($document).find("body");

        var hoveredNode = null;
        var froms = null;
        return {
            restrict: "E",
            scope: {
                node: "=root",
                dragEnter: "&?",
                dragLeave: "&?",
                drop: "=?",
                // por questões de desempenho, esta propriedade é readonly
                selection: "=?selection",
                nodeCommandsContext: "=",
                service: "=service",
                allowMoveTo: "&?",
                allowEditTrip: "@?",
                registerTreeApi: "&?",
                basketService: "=?"
            },
            template: nlgAnalysisTreeTemplate,
            // node command provider
            controller: ["$scope", "rootNodeCommandExecutorSupplier", "nlgBasketService", "remoteExceptionHandler", "$q", "loadingService",
                function ($scope, rootNodeCommandExecutorSupplier, nlgBasketService, remoteExceptionHandler, $q, loadingService) {
                    var rootNodeCommandExecutor = rootNodeCommandExecutorSupplier.getRoot();
                    $scope.selection = $scope.selection || {};
                    $scope.canEdit = $scope.allowEditTrip !== "false";
                    $scope.$deliveryUnitNodeType = "DeliveryUnit";

                    var volumeSum = {
                        messageKey: "presentationnode.nodeproperty.volume.selected",
                        value: 0,
                        nodePropertyKey: "presentationnode.nodeproperty.deliveryunit.volume.total"
                    };

                    var weightSum = {
                        messageKey: "presentationnode.nodeproperty.weight.selected",
                        value: 0,
                        nodePropertyKey: "presentationnode.nodeproperty.deliveryunit.weight.total"
                    };

                    $scope.model = {
                        summary: [
                            volumeSum,
                            weightSum
                        ]
                    };

                    $scope.expandOrCollapse = function (node, expand) {
                        node.$opened = expand === undefined ? !node.$opened : expand;
                        // se for um nó com sub-árvore lazy, e esta não tiver sido carregada
                        if (node.hasLazyChildren && !node.children.length) {
                            node.loadingChildren = true;
                            return basketService().doLoadLazyChildren($scope.service, node.id, node.lazyChildrenParams)
                                .then(function (response) {
                                    var lazyChildren = response.data;
                                    node.children = lazyChildren;
                                    doDefineParent(node);
                                })
                                .catch(remoteExceptionHandler())
                                .finally(function () {
                                    node.loadingChildren = false;
                                });
                        }
                        return $q.resolve();
                    };

                    function basketService() {
                        return $scope.basketService || nlgBasketService;
                    }

                    $scope.expandOrCollapseAllNodes = function (node, expand) {
                        loadingService(collapseCurrentNode(node, expand).then(function () {
                            return expandOrCollapseAllNodes(node, expand);
                        })).catch(remoteExceptionHandler());

                        function collapseCurrentNode(node, expand) {
                            if (!expand && $scope.isRoot(node)) {
                                return $q.resolve();
                            }
                            return $scope.expandOrCollapse(node, expand);
                        }

                        function expandOrCollapseAllNodes(node, expand) {
                            return (function asyncLoop(index) {
                                if (index >= node.children.length) {
                                    return $q.resolve();
                                }
                                var child = node.children[index];
                                return $scope.expandOrCollapse(child, expand)
                                    .then(expandOrCollapseAllNodes.bind($scope, child, expand))
                                    .then(asyncLoop.bind(null, index + 1));
                            })(0);
                        }
                    };

                    $scope.expandOrCollapseNextLevel = function (root, expand) {
                        if (!root.children.length) {
                            return $q.resolve();
                        }
                        return loadingService((function () {
                            var promises = [];
                            (function expandAll(rootNode) {
                                arrays.each(rootNode.children, function (child) {
                                    if (child.$opened !== expand) {
                                        promises.push($scope.expandOrCollapse(child, expand));
                                    } else {
                                        expandAll(child);
                                    }
                                });
                            })(root);
                            return $q.all(promises);
                        })()).catch(remoteExceptionHandler());
                    };

                    $scope.selectOrUnselectAllInternalItems = function (node, isSelect) {
                        return node.children.forEach(function (node) {
                            if (isSelect) {
                                select(node);
                            } else {
                                unselect(node);
                            }
                            if (node.children) {
                                $scope.selectOrUnselectAllInternalItems(node, isSelect);
                            }
                        });
                    };

                    $scope.expandOrCollapseAllDetails = function (node, expand) {
                        return $scope.expandOrCollapse(node, !(!node.$opened && !expand))
                            .then(function () {
                                node.children.forEach(function (node) {
                                    node.bodyVisible = expand;
                                });
                            });
                    };

                    $scope.onHeaderClick = function ($event, node) {
                        var which = $event.which;
                        if (which === 1 && !$scope.isRoot(node) && (node.children.length > 0 || node.hasLazyChildren)) {
                            $scope.expandOrCollapse(node);
                        } else if (which === 2 && node.properties.length) {
                            node.bodyVisible = !node.bodyVisible;
                            $event.preventDefault();
                        }
                    };

                    $scope.canMove = function (toNode) {
                        if (_.isNil($scope.allowMoveTo)) {
                            return true;
                        }
                        return $scope.allowMoveTo({node: toNode});
                    };

                    $scope.isGearEnabled = function (node) {
                        if ($scope.isRoot(node) || $scope.isTripNode(node) || $scope.isDriverNode(node) || $scope.isStopNode(node)) {
                            return true;
                        }
                        if (node.commands && node.commands.length) {
                            return true;
                        }
                        return $scope.canMove(node);
                    };

                    $scope.isTripNode = function (node) {
                        return node.type.name === "Trip";
                    };

                    $scope.isDriverNode = function (node) {
                        if (node.type) {
                            return node.type.name === "Driver";
                        }
                    };

                    $scope.isStopNode = function (node) {
                        if (node.type) {
                            return node.type.name === "STOP";
                        }
                    };

                    function doDefineParent(node) {
                        arrays.each(node.children, defineParent.bind(null, node));
                    }

                    $scope.$watch("node", prepare);

                    function prepare() {
                        defineParent(null, $scope.node);
                        $scope.node.$opened = true;
                        $scope.selection = {};
                        $scope.isRootSelected = false;
                    }

                    function defineParent(parent, node) {
                        node.$parent = parent;
                        if (node.children) {
                            doDefineParent(node);
                        }
                    }

                    $scope.isRoot = function (node) {
                        if (!node) {
                            return true;
                        }

                        return !node.$parent;
                    };

                    $scope.hideCheckbox = function (node) {
                        if (node.type && node.type.name) {
                            return (node.type.name === "Destination" || node.type.name === "TripOfferBid");
                        }
                        return false;
                    };

                    $scope.toggleSelection = function (node) {
                        var selection = $scope.selection[node.type.name] || [];
                        var nodeIndex = selection.indexOf(node);
                        var nodeSelected = nodeIndex !== -1;
                        if (!nodeSelected) {
                            select(node);
                        } else {
                            unselect(node);
                        }
                        nodeSelected = !nodeSelected;

                        selectChildren(node);

                        function selectChildren(node) {
                            if (node.selectChildrenOnSelection) {
                                arrays.each(node.children, function (childNode) {
                                    if (nodeSelected) {
                                        select(childNode);
                                    } else {
                                        unselect(childNode);
                                    }
                                    selectChildren(childNode);
                                });
                            }
                        }

                        selectParent(node);

                        function selectParent(node) {
                            if (node.$parent && node.$parent.selectChildrenOnSelection) {
                                if ($scope.allChildSelected(node.$parent)) {
                                    select(node.$parent);
                                } else {
                                    unselect(node.$parent);
                                }
                                selectParent(node.$parent);
                            }
                        }
                        $scope.emitOnNodeSelected(node);
                        updateSelectionSummary();
                        $scope.setRootNode(node);
                    };

                    $scope.emitOnNodeSelected = function (node){
                        $scope.$emit("OnNodeSelected", {
                            id: node.id,
                            type: {
                                name: node.type.name
                            }
                        });
                    };

                    $scope.setRootNode = function (node) {
                        if (!$scope.isTripBasket(node.$parent) && $scope.allChildSelected(node.$parent)) {
                            return $scope.selectRootNode();
                        }
                        return $scope.deselectRootNode();
                    };

                    $scope.selectChildrenByRoot = function (node, nodeSelected) {
                        arrays.each(node.children, function (childNode) {
                            if (nodeSelected) {
                                select(childNode);
                            } else {
                                unselect(childNode);
                            }
                            $scope.emitOnNodeSelected(childNode);
                        });
                    };

                    $scope.rootSelection = function (node) {
                        $scope.isRootSelected = !$scope.isRootSelected;
                        $scope.selectChildrenByRoot(node,$scope.isRootSelected);
                    };

                    $scope.isTripBasket = function (node) {
                        if (node.type && node.type.name) {
                            return node.type.name !== "TRIP_BASKET";
                        }
                        return true;
                    };

                    function updateSelectionSummary() {
                        $scope.model.summary = angular.copy($scope.model.summary);
                        var deliveryUnitSelection = $scope.selection[$scope.$deliveryUnitNodeType] || [];
                        var selectedPresentationIds = deliveryUnitSelection.map(function (selectionNode) {
                            return selectionNode.presentationId;
                        });
                        $scope.model.summary.forEach(function (summaryProperty) {
                            summaryProperty.value = deliveryUnitSelection.filter(function (selectedNode, index) {
                                return selectedPresentationIds.indexOf(selectedNode.presentationId) === index;
                            }).map(function (distinctSelectedNode) {
                                return distinctSelectedNode.properties;
                            }).map(function (properties) {
                                return properties.find(function (property) {
                                    return property.messageKey === summaryProperty.nodePropertyKey;
                                }) || {value: 0};
                            }).map(function (found) {
                                return found.value;
                            }).reduce(function (previousValue, currentValue) {
                                return previousValue + currentValue;
                            }, 0);
                        });
                    }

                    var treeApi = {};
                    treeApi.toggleSelection = function (nodeId, nodeType) {
                        //Busca só no primeiro nível.
                        var nodeToSelect = _.find($scope.node.children, function (node) {
                            return node.id === nodeId && node.type.name === nodeType;
                        });
                        if (nodeToSelect) {
                            $scope.$evalAsync(function () {
                                $scope.toggleSelection(nodeToSelect);
                            });
                        }
                    };
                    var presentationIconColors = [];
                    treeApi.changePresentationIconColor = function (colorChangeData) {
                        presentationIconColors[colorChangeData.presentationId] = colorChangeData.color;
                    };
                    if ($scope.registerTreeApi) {
                        $scope.registerTreeApi({api: treeApi});
                    }

                    $scope.getPresentationIconColor = function (node) {
                        return {
                            color: presentationIconColors[node.presentationId] || "#000"
                        };
                    };

                    function select(node) {
                        setSelected(node, true);
                        var selection = $scope.selection[node.type.name] || [];
                        if (selection.indexOf(node) === -1) {
                            selection.push(node);
                        }
                        $scope.selection[node.type.name] = selection;
                    }

                    function unselect(node) {
                        setSelected(node, false);
                        var selection = $scope.selection[node.type.name] || [];
                        var nodeIndex = selection.indexOf(node);
                        if (nodeIndex !== -1) {
                            arrays.removeAt(selection, nodeIndex);
                        }
                        if (selection.length === 0) {
                            delete $scope.selection[node.type.name];
                        }
                    }

                    function setSelected(node, value) {
                        node.$selected = value;
                        var parent = node.$parent;
                        if (parent) {
                            parent.$childSelected = 0;
                            arrays.each(parent.children, function (node) {
                                if (node.$selected) {
                                    parent.$childSelected++;
                                }
                            });
                        }
                    }

                    $scope.allChildSelected = function (node) {
                        return node.$childSelected === node.children.length;
                    };

                    $scope.isAtLeastOneButNoAllChildSelected = function (node) {
                        return node.$childSelected > 0 && node.$childSelected < node.children.length;
                    };

                    $scope.isNodeSelected = function (node) {
                        return node.type && node.$selected;
                    };

                    $scope.selectRootNode = function () {
                        $scope.isRootSelected = true;
                    };

                    $scope.deselectRootNode = function () {
                        $scope.isRootSelected = false;
                    };

                    $scope.getParents = function (node) {
                        if (!node) {
                            return [];
                        }

                        if (node.$parents) {
                            return node.$parents;
                        }

                        var result = [];
                        var parent = node.$parent;
                        while (!$scope.isRoot(parent)) {
                            result.push(parent);
                            parent = parent.$parent;
                        }
                        result = result.reverse();
                        node.$parents = result;
                        return result;
                    };

                    $scope.nodeIdentifier = function (node) {
                        if (node && !$scope.isRoot(node)) {
                            return node.type.name + "_" + node.id;
                        }
                        return "";
                    };

                    $scope.scrollTo = function (node) {
                        $location.hash($scope.nodeIdentifier(node));
                        $anchorScroll();

                        // header
                        $timeout(function () {
                            $window.scrollBy(0, -55);
                        });
                    };

                    $scope.executeNodeCommand = function (qualifier, selectedNode) {
                        rootNodeCommandExecutor.execute(qualifier, [selectedNode], angular.merge({
                            expandOrCollapse: $scope.expandOrCollapse,
                            toggleSelection: $scope.toggleSelection,
                            isNodeSelected: $scope.isNodeSelected,
                            deselectRootNode: $scope.deselectRootNode,
                            selectRootNode: $scope.selectRootNode
                        }, $scope.nodeCommandsContext));
                    };

                    $scope.disableWhenDontHaveChildren = function (node) {
                        if (node.hasOwnProperty("children")) {
                            return !node.hasLazyChildren && !node.children.length;
                        }
                        return false;
                    };
                }],
            link: function ($scope, $element) {
                var enteredOnNode = false;
                var openOnHoverTimeout = null;

                $element.addClass(baseComponentClass);

                $element.on("dragstart", ".nlgAnalysisTreeDragHandler", setDragData);
                $element.on("dragover dragenter", ".nlgAnalysisTreeNode", allowDrop);
                $element.on("dragenter", ".nlgAnalysisTreeNode", handleEnterEvent);
                $element.on("dragleave drop", ".nlgAnalysisTreeNode", handleLeaveEvent);
                $element.on("drop", ".nlgAnalysisTreeNode", function (event) {
                    if (!canDrop(event)) {
                        return;
                    }
                    event.preventDefault();
                    var toNode = getNodeFor(event);
                    doDrop(froms, toNode);
                });
                $element.on("dragend", function clearState() {
                    arrays.each(froms, function (from) {
                        delete from.$isFrom;
                    });
                    hoveredNode = null;
                    froms = null;
                    $scope.$digest();
                });

                $scope.doDrop = function (toNode) {
                    doDrop(arrays.map($scope.selection, function (selection) {
                        return selection;
                    }), toNode);
                };

                function doDrop(froms, toNode) {
                    notify($scope.drop, {fromNode: froms, toNode: toNode});
                }

                function enterNode(node) {
                    if (node.$hover === true) {
                        return;
                    }
                    node.$hover = true;
                    notify($scope.dragEnter, {node: node});
                    $scope.$digest();
                }

                function leaveNode(node) {
                    if (node.$hover === false) {
                        return;
                    }
                    node.$hover = false;
                    $timeout.cancel(openOnHoverTimeout);
                    notify($scope.dragLeave, {node: node});
                    $scope.$digest();
                }

                $scope.$watch(function () {
                    return froms !== null;
                }, function (isDragging) {
                    $element.toggleClass(dropTargetClass, isDragging);
                });

                function handleEnterEvent(event) {
                    var targetNode = getNodeFor(event);
                    enteredOnNode = true;
                    if (hoveredNode === targetNode) {
                        return;
                    }

                    if (hoveredNode) {
                        leaveNode(hoveredNode);
                    }
                    hoveredNode = targetNode;
                    openOnHoverTimeout = $timeout(function () {
                        $scope.expandOrCollapse(targetNode, true);
                    }, TIMEOUT_TO_OPEN_ON_HOVER);
                    if (!canDrop(event)) {
                        return;
                    }
                    enterNode(targetNode);
                }

                function handleLeaveEvent(event) {
                    var leavingNode = getNodeFor(event);
                    if (!enteredOnNode) {
                        leaveNode(leavingNode);
                        hoveredNode = null;
                    }
                    enteredOnNode = false;
                }

                function setDragData(event) {
                    var node = getNodeFor(event);
                    if (!$scope.isNodeSelected(node)) {
                        $scope.toggleSelection(node);
                    }
                    froms = arrays.map($scope.selection, function (selection) {
                        return selection;
                    });
                    arrays.each(froms, function (from) {
                        from.$isFrom = true;
                    });

                    $body.append(dragAndDropOverlay);
                    var dragOverlayText = $translate.instant(node.type.messageKey) + " " + node.id;
                    dragAndDropOverlay.find(".dragAndDropOverlayText").text(dragOverlayText);
                    dragAndDropOverlay.find(".dragAndDropOverlayBadge").text(froms.length).attr("data-number", froms.length);

                    $body.on("dragover", updatePosition);
                    $body.one("dragend", function () {
                        $body.off("dragover", updatePosition);
                        dragAndDropOverlay.remove();
                    });

                    // remove imagem padrão do browser
                    var dataTransfer = (event.originalEvent || {}).dataTransfer || {};
                    if (dataTransfer.setDragImage) {
                        dataTransfer.setDragImage(blankImage, 0, 0);
                    }

                    // ativa efeito do drag
                    if (dataTransfer.setData) {
                        dataTransfer.setData("Text", dragOverlayText + " (+" + froms.length + ")");
                    }

                    var lastPosition = {x: 0, y: 0};
                    var dragAndDropOverlayDom = dragAndDropOverlay[0];

                    $scope.$digest();

                    function updatePosition(event) {
                        var ev = event.originalEvent;
                        var pageY = ev.pageY;
                        var pageX = ev.pageX;
                        if (pageY !== lastPosition.y) {
                            dragAndDropOverlayDom.style.top = pageY + "px";
                            lastPosition.y = pageY;
                        }
                        if (pageX !== lastPosition.x) {
                            dragAndDropOverlayDom.style.left = pageX + "px";
                            lastPosition.x = pageX;
                        }
                    }
                }

                function allowDrop(event) {
                    if (canDrop(event)) {
                        event.preventDefault();
                    }
                }

                function canDrop(event) {
                    var to = getNodeFor(event);
                    if (!to.type) {
                        return false;
                    }
                    return canDropToNode(to);
                }

                function getNodeFor(event) {
                    return angular.element(event.currentTarget).scope().node;
                }

                function canDropToNode(to) {
                    for (var i = 0; i < froms.length; i++) {
                        if (arrays.map(froms[i].possibleToTypes, nodeTypeToName).indexOf(to.type.name) === -1) {
                            dragAndDropOverlay.addClass("cantDrop");
                            return false;
                        }
                    }
                    dragAndDropOverlay.removeClass("cantDrop");
                    return true;
                }

                $scope.canDropToNode = canDropToNode;

                function nodeTypeToName(nodeType) {
                    return nodeType.name;
                }

                function notify(fn, args) {
                    (fn || angular.noop)(args);
                }
            }
        };
    }]);
});