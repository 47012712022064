import {integrationDocumentationModule} from "./integrationDocumentationModule";
import {IntegrationDocumentationService, WsdlIdentifier} from "./integrationDocumentationService";
import {IntegrationDocumentationParser, WsdlDefinition, WsdlType} from "./integrationDocumentationParser";
import {IAnchorScrollService, ILocationService, IScope} from "angular";
import {LoadingService, RemoteExceptionHandler} from "../../../bower_components/front-end-web-commons";

interface NlgIntegrationDocumentationDetailsScope extends IScope {
    wsdlIdentifier: WsdlIdentifier
}

class NlgIntegrationDocumentationDetails {
    definition?: WsdlDefinition

    constructor(private integrationDocumentationService: IntegrationDocumentationService,
                private integrationDocumentationParser: IntegrationDocumentationParser,
                private $scope: NlgIntegrationDocumentationDetailsScope,
                private $location: ILocationService,
                private $anchorScroll: IAnchorScrollService,
                loadingService: LoadingService,
                remoteExceptionHandler: RemoteExceptionHandler) {
        loadingService(integrationDocumentationService.getWsdlContent($scope.wsdlIdentifier))
            .then(content => integrationDocumentationService.parseWsdlDefinition($scope.wsdlIdentifier, content))
            .then(content => this.definition = content)
            .then(() => $anchorScroll())
            .catch(remoteExceptionHandler())
    }

    focusAnchor(anchorName: string) {
        this.$location.hash(anchorName)
        this.$anchorScroll()
    }

    isMandatory(element: WsdlType["elements"][0]) {
        return element.minOccurs !== 0
    }

    isCollection(element: WsdlType["elements"][0]) {
        return element.maxOccurs === "unbounded" || element.maxOccurs > 1
    }

    getWsdlLink() {
        return this.integrationDocumentationService.getWsdlContentLink(this.$scope.wsdlIdentifier)
    }
}

integrationDocumentationModule.directive("nlgIntegrationDocumentationDetails", function () {
    return {
        // @ts-ignore
        templateUrl: require("link!./nlgIntegrationDocumentationDetails.html"),
        controller: NlgIntegrationDocumentationDetails,
        controllerAs: "ctrl",
        scope: {
            wsdlIdentifier: "="
        }
    }
})