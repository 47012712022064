define([
    "./trackingLGPDModule",
    "json!../tracking.json",
], function (trackingLGPDModule, trackingServer) {
    "use strict";
    trackingLGPDModule.service("trackingLgpdApi", function ($http) {
            var self = this;

            self.obfuscateAllNames = function () {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.obfuscate.allNames)
                    .then(function (response) {
                        return response;
                    })
            };

            self.obfuscateEntitiesByName = function (args) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.obfuscate.entitiesByName, args)
                    .then(function (response) {
                        return response;
                    })
            };

            self.obfuscateFind = function (args, params) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.obfuscate.find, args, params)
                    .then(function (response) {
                        return response;
                    })
            };

            self.obfuscateSave = function (args) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.obfuscate.save, args)
                    .then(function (response) {
                        return response;
                    })
            };

            self.logsAllNames = function () {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.logs.allNames)
                    .then(function (response) {
                        return response;
                    });
            };

            self.logsEntitiesByName = function (args) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.logs.entitiesByName, args)
                    .then(function (response) {
                        return response;
                    });
            };

            self.logsFind = function (args, params) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.logs.find, args, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.logExportDefinitions = function () {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.logs.exportDefinitions)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityGetAllOwners = function () {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.entity.edition)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entitySave = function (definition) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.entity.edition, definition)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityGetByOwner = function (owner) {
                const params = {params: {entityName: owner.entityName}};
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.entity.byOwner, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityFilter = function (template, params) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.entity.filter, template, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityGetDefinitionById = function (id) {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.entity.edition + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityDelete = function (id) {
                return $http.delete(trackingServer.baseUrl + trackingServer.services.lgpd.entity.edition + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityCopyGetDefinitionById = function (id) {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.entity.copy + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityLogVersionOf = function (id) {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.entity.versions + "/" + id)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityFindVersion = function (id, version) {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.entity.edition + "/" + id + "/version/" + version)
                    .then(function (response) {
                        return response;
                    });
            };

            self.entityGetDefinitions = function () {
                return $http.get(trackingServer.baseUrl + trackingServer.services.lgpd.entity.all)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeGetAllDefinitionsNames = function () {
                return [$http.get(trackingServer.baseUrl + trackingServer.services.lgpd.entity.allNames).then(function (response) {
                    return {
                        key: {
                            artifactName: "tracking",
                            path: "",
                            name: "entity"
                        },
                        value: response.data
                    };
                }).catch(function () {
                    return {
                        key: {
                            artifactName: "",
                            path: "",
                            name: ""
                        },
                        value: []
                    }
                })];
            };

            self.anonymizeGetEntitiesByName = function (entityName) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.entity.anonymize + "/entities-by-name", entityName)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeGetFieldTemplate = function (field) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.entity.anonymize + "/find-template", field)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeFindFieldTemplate = function (body, params) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.entity.anonymize + "/find-field-template", body, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymize = function (definition, params) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.entity.anonymize + "/anonymize", definition, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeAttachment = function (definition, params) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.entity.anonymize + "/attachment-anonymize", definition, params)
                    .then(function (response) {
                        return response;
                    });
            };

            self.anonymizeGetAnonymizableEntities = function (template, params) {
                return $http.post(trackingServer.baseUrl + trackingServer.services.lgpd.entity.anonymize + "/find", template, params)
                    .then(function (response) {
                        return response;
                    });
            };

            return self;
        }
    );
});
