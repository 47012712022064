import {configParamsModule} from "../configParamsModule";
import {IModalService} from "angular-ui-bootstrap";

export class ExceptionModal {
    constructor(private $modal: IModalService) {
    }

    open(exception: string) {
        return this.$modal.open({
            size: "lg",
            templateUrl: require("link!./exceptionModal.html"),
            controller: function ($scope: any) {
                "ngInject"
                $scope.exception = exception
            }
        }).result
    }
}

configParamsModule.service("exceptionModal", ExceptionModal)