define(["../nlgTableModule",
        "angular",
        "text!./nlgTableAddButton.html",
        "text!./nlgTableRemoveButton.html",
        "text!./nlgTableToggleButton.html"],
    function (nlgTableModule, angular, addButtonTemplate, removeButtonTemplate, toggleButtonTemplate) {
        "use strict";

        /**
         * @ngdoc directive
         * @name directives.directive:nlgTableAddButton
         * @description
         * Botão para adição de novos itens em uma coleção.
         *
         * @param {obejct[]} collection Coleção que terá itens adicionados.
         * @param {Expression=} createElement Expressão que deve retornar um objeto à ser adicionado na coleção.
         * Se não for definido, um objeto vazio será usado.
         *
         * @example
         * <example module="FrontEndWeb">
         <file name="index.html">
         <div ng-init="colecao = []">
         <nlg-table-add-button collection="colecao"></nlg-table-add-button>
         <nlg-table-add-button collection="colecao" create-element="{teste:true}"></nlg-table-add-button>
         <br>
         <ul><li ng-repeat="item in colecao">{{ item | json }}</li></ul>
         </div>
         </file>
         </example>
         */
        nlgTableModule.directive("nlgTableAddButton", [function () {
            return {
                template: addButtonTemplate,
                restrict: "E",
                replace: true,
                scope: {
                    collection: "=collection",
                    createElement: "&?createElement",
                    index: "=?index"
                },
                controller: ["$scope", "$attrs", "$q", function ($scope, $attrs, $q) {
                    $scope.addElement = function () {
                        $attrs.$set("disabled", "disabled");
                        $q.when(createElement()).then(function (element) {
                            var index = $scope.index;
                            if (index === undefined) {
                                index = $scope.collection.length;
                            }
                            $scope.collection.splice(index, 0, element);
                            $attrs.$set("disabled", undefined);
                        });
                    };

                    function createElement() {
                        if (!$attrs.createElement) {
                            return {};
                        }
                        return $scope.createElement();
                    }
                }]
            };
        }]);

        /**
         * @ngdoc directive
         * @name directives.directive:nlgTableRemoveButton
         * @description
         * Botão para remoção de itens em uma coleção.
         *
         * @param {obejct[]} collection Coleção que terá itens removidos.
         * @param {object=} elementToRemove Elemento à ser removido da coleção.
         * @param {number=} index Índice do elemento à ser removido da coleção.
         *
         * @example
         * <example module="FrontEndWeb">
         *     <file name="index.html">
         *         <div ng-init="colecao = [1,2,3,4,5,6,7,8,9,10]">
         *             <ul>
         *                 <li ng-repeat="item in colecao">
         *                    <nlg-table-remove-button collection="colecao" element-to-remove="item"></nlg-table-remove-button> {{item}}
         *                 </li>
         *             </ul>
         *         </div>
         *     </file>
         * </example>
         */
        nlgTableModule.directive("nlgTableRemoveButton", [function () {
            return {
                template: removeButtonTemplate,
                restrict: "E",
                replace: true,
                scope: {
                    collection: "=collection",
                    elementToRemove: "=?elementToRemove",
                    index: "=?"
                },
                controller: ["$scope", function ($scope) {
                    $scope.removeElement = function () {
                        var indexOf = $scope.index;
                        if (!angular.isNumber(indexOf)) {
                            indexOf = $scope.collection.indexOf($scope.elementToRemove);
                        }
                        if (indexOf === -1) {
                            throw new Error("element '" + $scope.elementToRemove + "' not found on collection [" + $scope.collection + "]");
                        }
                        $scope.collection.splice(indexOf, 1);
                    };
                }]
            };
        }]);

        /**
         * @ngdoc directive
         * @name directives.directive:nlgTableToggleButton
         * @description
         * Botão que esconde/mostra colunas dentro de uma coluna superior com colspan maior que 1 da tabela
         * @param {action} Function que realiza a mudança dos valores de colspan e altera o estado esconde/mostra
         *
         *@example
         * <th name="header"
         *    colspan="{{colspan}}">
         *    <nlg-table-toggle-button action="toggleColumns()"></nlg-table-toggle-button>
         *</th>
         */
        nlgTableModule.directive("nlgTableToggleButton", [function () {
            return {
                template: toggleButtonTemplate,
                restrict: "E",
                replace: true,
                scope: {
                    action: "&"
                }
            };
        }]);
    });