import analysisModule from "../analysisModule"
import arrays from "../../../bower_components/front-end-web-commons/app/arrays/arrays"

analysisModule.service("nodeCommandUnselectNodes", [function () {
    return (select, context, nodes) => {
        const basketSelection = context.getSelection()
        arrays.each(nodes, function (node) {
            if(node.type.name === "TRIP_BASKET"){
                context.deselectRootNode()
            }
            arrays.each(node.children, function (child) {
                if (arrays.contains(basketSelection[child.type.name] || [], child)) {
                    context.toggleSelection(child)
                }
            })
        })
    }
}])

