import headerModule from "./headerModule"
import headerView from "./header.html"

headerModule.directive("nlgHeader",[()=>{
    return {
        restrict: "E",
        template: headerView,
        controller: "headerController",
        scope: {
            menuCollapsed: "=?"
        }
    }
}])