define([
    "./genericParamsModule"
], function (entityModule) {

    "use strict";

    entityModule.controller("GenericParamDefinitionGroupEditionController", ["$scope", "loadingService",  "genericParamDataEditionModal", function ($scope, loadingService, genericParamDataEditionModal) {
        
        $scope.genericParametersTableOptions = {
            records: $scope.entityDTO.genericParamDefinitionGroups,
            columns: [{
                "cell": {
                    template: "<a name='editButton' class='btn mini btn-default' ng-click='editGenericParamDefinitionGroup(record)' style='margin-top:3px'> <span class='fa fa-pencil-square-o'></span> </a>"
                },
                width: "28px"
            }, {
                header: "entity.property.sourceId",
                cell: "{{ record.sourceId }}",
                sortBy: "sourceId",
                cellName:"genericParameterGroupSourceId"
            }, {
                header: "entity.property.name",
                cell: "{{ record.name }}",
                sortBy: "name",
                cellName:"genericParameterGroupName"
            }, {
                header: "entity.property.description",
                cell: "{{ record.description }}",
                sortBy: "description",
                cellName:"genericParameterGroupDescription"
            }]
        };
        
        $scope.editGenericParamDefinitionGroup = function(definitionGroup){
            genericParamDataEditionModal.fromDefinitionGroup(definitionGroup, $scope.entityDTO.dto);
        };
    }]);

});