define([
    "./SensorConfigurationModule",
    "text!./sensorConfigurationByDeviceModal.html",
    "lodash"
], function (module, template, _) {
    "use strict";

    return module.service("sensorConfigurationByDeviceModal", [
        "$modal",
        "monitoringService",
        "mbrService",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        "$state",
        "$window",
        "$q",
        function ($modal, monitoringService, mbrService, loadingService, remoteExceptionHandler, messagesModal, $state, $window, $q) {
            return function (devicesByMonitorable) {
                return $modal.open({
                    size: "lg",
                    template: template,
                    controller: ["$scope", function ($scope) {
                        $scope.devicesByMonitorable = devicesByMonitorable;
                        $scope.sensorConfiguration = [];
                        (function loadSensorConfigurationColumns() {
                            const getSensorConfiguration = (deviceId, providerId) => {
                                return monitoringService.findSensorsByMonitorableIdAndDeviceIdAndProviderId(
                                        devicesByMonitorable.monitorable.id,
                                        deviceId,
                                        providerId)
                                    .then(function(values) {
                                        return values.map((value) => {
                                            return {...value, monitorableId: devicesByMonitorable.monitorable.id, deviceId: deviceId};
                                        })
                                    });
                            };

                            $scope.truckDevices = _.union([devicesByMonitorable.truckDevice], devicesByMonitorable.secondaryDevices);

                            let promises = $scope.truckDevices.map(({deviceId, providerId}) => getSensorConfiguration(deviceId, providerId));

                            $q.all(promises).then(function(results) {
                                for (let result of results) {
                                    $scope.sensorConfiguration.push(result)
                                }
                            });
                        })();

                        $scope.temperatureRangeConfigs = function (viewValue) {
                            return monitoringService.getTemperatureRangeConfigs(viewValue);
                        };

                        $scope.configTemperature = function() {
                            const href = $state.href("monitoring.temperatureRangeConfigEdition", {});
                            $window.open(href,'_blank');
                        };

                        $scope.save = function() {
                            const id = devicesByMonitorable.monitorable.id;
                            const configs = Object.values($scope.sensorConfiguration)
                                .filter(config => config.length)
                                .reduce((accumulator, currentValue) => accumulator.concat(currentValue));
                            return loadingService(mbrService.createSpecificConfigurationForMonitorable(id, configs))
                                .catch(remoteExceptionHandler())
                                .then(function () {
                                    messagesModal("dialog.success", ["sensor.configuration.save.success"])
                                }).then(function () {
                                    return $scope.$close();
                                });
                        }
                    }]
                }).result;
            };
        }]);
});
