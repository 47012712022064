define([
    "./nlgLGPDModule"
], function (nlgLGPDModule) {
    "use strict";

    nlgLGPDModule.provider("lgpdApiByArtifact", [function () {
        var self = this;
        var apiByArtifact = {};

        /**
         * Adiciona API de acesso ao LGPD de um artefato
         * @param {string} artifactName nome do artefato (usermanagement, gateway, monitoring...)
         * @param {string} api nome do serviço de API do artefato
         * */
        self.addArtifactApi = function (artifactName, api) {
            apiByArtifact[artifactName] = api;
        };

        self.$get = function ($injector) {
            return {
                getApiOfArtifact: function (artifactName) {
                    return $injector.get(apiByArtifact[artifactName]);
                }
            };
        };

        return this;
    }]);
});
