define([
    "angular",
    "angular-ui-router",
    "angular-bootstrap",
    "front-end-web-commons",
    "front-end-web-commons/app/nlgSaveAndContinue/nlgSaveAndContinue",
    "front-end-web-commons/app/nlgLGPD/nlgLGPD",
    "front-end-web-commons/app/tdnHelp/tdnHelp"
], function (angular) {
    "use strict";

    /**
     * @ngdoc overview
     * @name userManagementModule
     * @description
     *
     * Módulo com funcionalidades de admin de usuários.
     * */
    return angular.module("userManagementModule", [
        "ui.router",
        "ui.bootstrap",
        "filterModule",
        "remoteModule",
        "entityDTOModule",
        "mapsModule",
        "nlgTableModule",
        "nlgPortletModule",
        "nlgTableDynamicColumnsModule",
        "nlgSaveAndContinueModule",
        "nlgLGPDModule",
        "userManagementLGPDModule",
        "tdnHelpModule"
    ]);
});