define([
    "angular",
    "front-end-web-commons"
], function (angular) {
    "use strict";

    return angular.module("driverMapModule", [
        "nlgPaginationModule",
        "remoteModule",
        "mapsModule",
        "nlgPortletModule",
        "filterModule"
    ]);
});