define(["./timeModule", "angular", "moment"], function (timeModule, angular, moment) {
    "use strict";

    return timeModule.filter("dateTimeZoneFormat", ["dateFilter", function (dateFilter) {
        return function (date) {
            return formatDate(date);
        };

        function formatDate(date) {
            if (date === null || angular.isUndefined(date)) {
                return "";
            }
            var formattedDate = moment(date, "DD/MM/YYYY hh:mmZZ").format();
            return dateFilter(new Date(formattedDate), "short");
        }
    }]);
});
