define([
    "./emailModule"
], function (emailModule) {
    "use strict";

    emailModule.service("emailService", [
        "monitoringService",
        function (monitoringService) {
            var self = this;

            self.getMonitorableEmailTemplate = function () {
                return monitoringService.getEmailTemplate();
            };

            self.saveTemplate = function (template) {
                return monitoringService.saveEmailTemplate(template);
            };

            self.configParams = () => {
                return monitoringService.getEmailConfig();
            };

            self.createOrUpdateConfig = (configurations) => {
                return monitoringService.saveEmailConfig(configurations);
            };

            self.testConfigurations = (configurations) => {
                return monitoringService.testConfigurations(configurations);
            };

            self.isValidResult = function (elements) {
                var messages = [];
                elements.forEach((function (element) {
                    if (element.result === false) {
                        messages.push(element.description)
                    }
                }));
                return messages
            };

            return self;
        }]);
});