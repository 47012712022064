define(["./nlgPortletModule",
    "text!./portletTitle.html"
], function (nlgPortletModule, portletTitleTemplate) {
    "use strict";

    return nlgPortletModule.directive("nlgPortletTitle", function () {
        return {
            require: ["^nlgPortlet", "?^nlgPortletGroup"],
            restrict: "A",
            scope: true,
            template: portletTitleTemplate,
            replace: true,
            transclude: true,
            link: function (scope, elem, attrs, controllers) {
                var portletController = controllers[0], portletGroupController = controllers[1];
                scope.isCollapsed = portletController.isCollapsed;
                scope.toggleCollapse = portletController.toggleCollapse;
                scope.setCollapsed = portletController.setCollapsed;
                if (portletGroupController) {
                    scope.canCollapse = true;
                    portletGroupController.register(scope);
                    scope.onPortletGroup = true;
                    scope.focusPortlet = portletGroupController.focusPortlet.bind(null, scope);
                } else {
                    scope.canCollapse = attrs.canCollapse === "true";
                }
                scope.onTitleDoubleClick = function () {
                    if (scope.onPortletGroup) {
                        scope.focusPortlet();
                    }
                };
            }
        };
    });
});

