define([
    "./dashboardModule",
    "angular",
    "text!./dashboardContent.html",
    "../arrays/arrays"
], function (dashboardModule, angular, template, arrays) {
    "use strict";

    dashboardModule.directive("dashboardContent", ["chartTemplateFactories", "templateFactory", "dashboardExport", "$translate", function (chartTemplateFactories, templateFactory, dashboardExport, $translate) {
        var progressBarApi = null;
        return {
            restrict: "E",
            require: "^dashboard",
            template: template,
            controller: ["$scope", function ($scope) {
                $scope.registerApi = function (api) {
                    progressBarApi = api;
                };
            }],
            link: function (scope, element, attrs, dashboardController) {
                scope.chartInfos = [];
                scope.chartInfosBySourceId = {};
                scope.contentOptions = {};
                scope.dashboardConfig = {};
                scope.choosablesByOption = {};
                scope.choosable = {
                    selected: null
                };

                var choosableBundle = "dashboard.choosable.option.";

                scope.exportSpreadsheet = function (chartInfo) {
                    scope.currentExportingChart = chartInfo;
                    var dashboardSourceId = scope.config.dashboardSourceId;
                    var chartSourceId = chartInfo.chartViewConfig.chartSourceId;
                    var maxResults = 10000;

                    dashboardController.countExport(dashboardSourceId, chartSourceId)
                        .then(function (result) {
                            var exportCallback = dashboardController.export.bind(null, dashboardSourceId, chartSourceId, maxResults);
                            exportAllPages(exportCallback, result.count, maxResults, chartInfo);
                        });
                };

                function exportAllPages(exportCallback, totalCount, maxResults, chartInfo) {
                    resetProgressBar(totalCount);
                    showExportingBar();
                    loadPage(0);

                    var fileQuantity = 0;

                    function loadPage(firstResult) {
                        var tuples = [];
                        return exportCallback(firstResult)
                            .then(function (result) {
                                if (cancelRequested) {
                                    cancelRequested = false;
                                    hideExportingBar();
                                    return;
                                }
                                arrays.addAll(tuples, result.results);
                                progressBarApi.setText(generateProgressDescription(++fileQuantity));

                                var sheetName = chartInfo.chartViewConfig.chartTitle;
                                dashboardExport(tuples, sheetName);
                                if (tuples.length === maxResults) {
                                    var currentResult = firstResult + maxResults;
                                    loadingBar();
                                    return loadPage(currentResult);
                                }
                                hideExportingBar();
                            });
                    }

                    function resetProgressBar() {
                        progressBarApi.setText(generateProgressDescription(0));
                        progressBarApi.animate(0);
                    }

                    var percentForAnimate = 0;

                    function loadingBar() {
                        percentForAnimate = ((100 / (totalCount / maxResults)) * 0.01) + percentForAnimate;
                        progressBarApi.animate(percentForAnimate);
                    }

                    function generateProgressDescription(pageIndex) {
                        return pageIndex + " " + $translate.instant("charting.of") + " " + Math.ceil(totalCount / maxResults);
                    }
                }

                scope.isExporting = false;
                var cancelRequested = false;

                function showExportingBar() {
                    scope.isExporting = true;
                }

                function hideExportingBar() {
                    scope.isExporting = false;
                }

                scope.cancelExport = function () {
                    cancelRequested = true;
                };


                this.getTemplateFactory = function () {
                    return templateFactory;
                };

                scope.$on("$dashboardConfigUpdate", function (event, dashboardConfig) {
                    scope.dashboardConfig = dashboardConfig;
                    if (dashboardConfig.presentation) {
                        scope.presentationConfiguration.presentation = dashboardConfig.presentation;
                    }
                    scope.chartInfosBySourceId = {};
                    scope.chartInfos = [];
                    dashboardConfig.chartInfoResult.forEach(function (chartInfo) {
                        scope.chartInfos.push(chartInfo);
                        var chartViewConfig = chartInfo.chartViewConfig;
                        scope.chartInfosBySourceId[chartViewConfig.chartSourceId] = chartInfo;
                        scope.contentOptions[chartViewConfig.chartSourceId] = chartTemplateFactories[chartViewConfig.chartType].getOptions(chartInfo);
                    });
                    dashboardConfig.chartChoosablesInfo.forEach(function (choosable) {
                        choosable.optionAliases.forEach(function (option) {
                            var alias = scope.choosablesByOption[option];
                            if (angular.isUndefined(alias)) {
                                scope.choosablesByOption[option] = [choosable.alias];
                            } else if (!arrays.contains(alias, choosable.alias)) {
                                alias.push(choosable.alias);
                            }
                        });
                    });
                });

                scope.$on("$dashboardDataUpdate", function (event, dataByChart) {
                    Object.keys(dataByChart).forEach(function (chartSourceId) {
                        scope.contentOptions[chartSourceId].data = dataByChart[chartSourceId];
                    });
                });

                scope.hasData = function (chartInfo) {
                    var chartData = scope.contentOptions[chartInfo.chartViewConfig.chartSourceId].data;
                    return chartData && chartData.length;
                };

                scope.isExportable = function (chartInfo) {
                    return chartInfo.chartViewConfig.isExportable;
                };

                scope.getBorder = function (chartInfo) {
                    return chartInfo.chartViewConfig.borderStyle;
                };

                scope.getStyle = function (chartInfo) {
                    var border = scope.getBorder(chartInfo);
                    if (border && border !== "NONE") {
                        return {border: border};
                    }
                    return {};
                };

                scope.createContentTemplate = function (chartInfo) {
                    if (!chartInfo) {
                        throw new Error("Cannot create template without chartInfo.");
                    }
                    var templateFactory = dashboardController.getTemplateFactory();
                    var templateConfig = chartTemplateFactories[chartInfo.chartViewConfig.chartType].getTemplateConfig(chartInfo);
                    angular.extend(templateConfig, {
                        context: "contentOptions[\"" + chartInfo.chartViewConfig.chartSourceId + "\"]"
                    });
                    var template = templateFactory(templateConfig);
                    return template[0].outerHTML;
                };

                scope.layoutApi = {
                    createContentTemplate: scope.createContentTemplate,
                    cancelExport: scope.cancelExport,
                    exportSpreadsheet: scope.exportSpreadsheet,
                    hasData: scope.hasData,
                    isExportable: scope.isExportable,
                    getStyle: scope.getStyle,
                    chartInfo: function (sourceId) {
                        return scope.chartInfosBySourceId[sourceId];
                    },
                    currentExportingChart: function () {
                        return scope.currentExportingChart;
                    },
                    isExporting: function () {
                        return scope.isExporting;
                    }
                };

                function hasChoosablesInfo() {
                    return scope.dashboardConfig && scope.dashboardConfig.chartChoosablesInfo && scope.dashboardConfig.chartChoosablesInfo.length > 0;
                }

                scope.showChoosables = function () {
                    return scope.appliedFilter && hasChoosablesInfo();
                };

                scope.getChoosables = function () {
                    return Object.keys(scope.choosablesByOption);
                };
                scope.formatChoosableTag = function (tag) {
                    if (tag) {
                        return $translate.instant(choosableBundle + tag);
                    }
                };

                scope.choosableSelected = function () {
                    var choosableSelected = scope.choosable.selected;
                    if (choosableSelected) {
                        var options = scope.choosablesByOption[choosableSelected];
                        var choosables = {};
                        options.forEach(function (option) {
                            choosables[option] = choosableSelected;
                        });
                        dashboardController.filter(scope.config.dashboardSourceId, scope.appliedFilter, choosables);
                    }
                };

                scope.$on("$filterRefresh", function () {
                    if (hasChoosablesInfo()) {
                        arrays.each(scope.dashboardConfig.chartChoosablesInfo, function (choosable) {
                            if (choosable.defaultOptionAlias) {
                                scope.choosable.selected = choosable.defaultOptionAlias;
                                return arrays.each.BREAK;
                            }
                        });

                    }
                });

            }
        };
    }]);
});
