import analysisModule from "./analysisModule"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"
import server from "../server"

analysisModule.controller("analysisController", [
    "$scope",
    "nodeIdentifiers",
    "nodeCommandViewRoute",
    "nodeCommandOptimize",
    "nodeCommandInvoiceView",
    "analysisService",
    "loadingService",
    "messagesModal",
    "preValidationService",
    "OptimizationSource",
    "organizeService",
    "$http",
    "FlowType",
    "integrationService",
    "stopFitModal",
    "tripEditionService",
    "orderService",
    "remoteExceptionHandler",
    "$modal",
    "spreadsheetModal",
    "nodeCommandStartMonitorableConfiguration",
    "nodeCommandExportAllTripPickingList",
    ($scope,
     nodeIdentifiers,
     nodeCommandViewRoute,
     nodeCommandOptimize,
     nodeCommandInvoiceView,
     analysisService,
     loadingService,
     messagesModal,
     preValidationService,
     OptimizationSource,
     organizeService,
     $http,
     FlowType,
     integrationService,
     stopFitModal,
     tripEditionService,
     orderService,
     remoteExceptionHandler,
     $modal,
     spreadsheetModal,
     nodeCommandStartMonitorableConfiguration,
     nodeCommandExportAllTripPickingList) => {

        const entityType = "trip"

        let baskets = {
            orders: null,
            trips: null
        }

        $scope.baskets = baskets
        $scope.routingConfigId = null

        $scope.selection = {
            deliveryUnits: {},
            tripBasket: {}
        }

        $scope.refilterBaskets = () => {
            baskets.orders.refreshFilter()
            baskets.trips.refreshFilter()
        }

        $scope.registerBasketApi = function (basketApiName, api) {
            baskets[basketApiName] = api
        }

        $scope.viewRoute = () => {
            nodeCommandViewRoute($scope.getSelectedTrips(), {refilter: baskets.trips.refreshFilter})
        }

        $scope.optimize = () => {
            doOptimize($scope.getSelectedTrips(), getSelectedDeliveryUnits())
        }

        function doOptimize(tripsToOptimize, dusToOptimize) {
            return preValidationService.getViolationsForOptimization(OptimizationSource.OPTIMIZATION, tripsToOptimize, dusToOptimize, false, FlowType.ANALYSIS)
                .then(function (result) {
                    var tripsNodeIdentifiers = tripsToOptimize.filter(function (tripIdentifier) {
                        return arrays.contains(result.entitiesToOperate.trips, tripIdentifier.id)
                    })
                    var dusNodeIdentifiers = dusToOptimize.filter(function (duIdentifier) {
                        return arrays.contains(result.entitiesToOperate.deliveryUnits, duIdentifier.id)
                    }).concat(result.additionalDeliveryUnits || [])
                    return nodeCommandOptimize(tripsNodeIdentifiers, dusNodeIdentifiers, result.routingConfig)
                        .then(() => {
                            return $scope.openSuccessMessageModal({
                                keyBundle: "programming.optimization.successful.submit"
                            })
                        })
                        .finally(() => {
                            baskets.orders.refreshFilter()
                            baskets.trips.refreshFilter()
                        })
                })
        }

        $scope.canOptimizeAllPages = function () {
            return isBasketNonEmpty(baskets.orders)
        }


        $scope.optimizeAllPages = function () {
            var patches = baskets.orders.getLastAppliedFilterPatches()
            $http.post(server.getServiceUrl("orderBasketUrl"), patches)
                .then(function (response) {
                    doOptimize([], response.data.result)
                })
        }
        $scope.hasSelectedDeliveryUnits = () => {
            return !!getSelectedDeliveryUnits().length
        }

        $scope.getSelectedTrips = () => {
            return nodeIdentifiers.toNodeIdentifiers($scope.selection.tripBasket.Trip || [])
        }

        $scope.getSelectedDeliveryUnitsFromAllBaskets = () => {
            var orders = (($scope.selection.tripBasket.Trip || []))
                .concat($scope.selection.deliveryUnits.DeliveryUnit || [])
            return nodeIdentifiers.toNodeIdentifiers(orders)
        }

        $scope.invoiceView = () => {
            nodeCommandInvoiceView($scope.getSelectedDeliveryUnitsFromAllBaskets())
        }

        $scope.startMonitorableView = () => {
            nodeCommandStartMonitorableConfiguration($scope.getSelectedTrips())
        }

        $scope.exportAllTripPickingList = () => {
            return nodeCommandExportAllTripPickingList($scope.getSelectedTrips())
        }

        $scope.stopFitModalOpen = () => {

            tripEditionService.getTripStatus($scope.getSelectedTrips()[0].id).then(function (status) {
                if (status !== "br.com.neolog.model.trip.TripStatus.SERVED") {
                    return messagesModal("dialog.warning", [{
                        keyBundle: "stopfit.only.served.trips.allowed",
                        parameters: []
                    }])
                }
                loadingService(
                    preValidationService.getViolationsForManualOperation(OptimizationSource.MANUAL_OPERATION, $scope.getSelectedTrips(), getSelectedDeliveryUnits(), false, FlowType.ANALYSIS).then(function (response) {
                        $scope.routingConfigId = response.routingConfig.routingConfig.id
                        loadingService($http.post(server.getServiceUrl("getFittingInformationsByTripAndOrderId") + $scope.getSelectedTrips()[0].id + "/" + getSelectedOrders()[0].id)
                            .then(function (response) {
                                orderService.getOrder(getSelectedOrders()[0].id).then(function (order) {
                                    return stopFitModal(response.data,
                                        order,
                                        $scope.routingConfigId,
                                        baskets.orders.refreshFilter,
                                        baskets.trips.refreshFilter)
                                })
                            }).catch(remoteExceptionHandler()))
                    })
                )
            })
        }

        $scope.onDropOnBasket = function (dropInfo) {
            organizeService.organize($scope.selection, dropInfo.toNode).then($scope.refilterBaskets)
        }
        $scope.openSuccessMessageModal = function (message) {
            return messagesModal("dialog.success", [message])
        }

        $scope.hasSelectedTripsAndOrders = () => {
            return (getSelectedOrders().length === 1 && $scope.getSelectedTrips().length === 1)
        }

        $scope.deliveryUnitActions = [{
            name: "basketFilterSummmary.title",
            icon: "fa-ellipsis-h",
            execute: function (service, filterState) {
                var summaryUrl = server.getBaseUrl() + "/presentation/filterResultSummary"
                loadingService($http.post(summaryUrl, filterState.patch)).then(function (result) {
                    return $modal.open({
                        templateUrl: require("link!./basketFilterSummary.html"),
                        size: "lg",
                        controller: ["$scope", function ($scope) {
                            $scope.summary = result.data
                        }]
                    }).result
                })
            }
        }]

        let getSelectedDeliveryUnits = () => {
            return nodeIdentifiers.toNodeIdentifiers($scope.selection.deliveryUnits.DeliveryUnit || [])
        }

        let getSelectedOrders = () => {
            return nodeIdentifiers.toNodeIdentifiers($scope.selection.deliveryUnits.Order || [])
        }

        function isBasketNonEmpty(basket) {
            if (!basket) {
                return false
            }
            var data = basket.getData()
            return data && data.children && !!data.children.length
        }

        function isAllowedAdditionalStopFit() {
            integrationService.getIntegrationConfig().then(function (result) {
                $scope.allowAdditionalStopFit = result.allowAdditionalStopFit
            })
        }

        (function () {
            isAllowedAdditionalStopFit()
        }())

        $scope.spreadsheetModal = () => {
            spreadsheetModal.show(entityType)
        }

    }
])