import analysisMapModule from "./analysisMapModule"
import server from "../server"

analysisMapModule.service("analysisMapService", [
    "$http",
    ($http) => {

        const markersServiceUrl = server.getServiceUrl("analysisMapMarkers")
        const optimizeServiceUrl = server.getServiceUrl("analysisMapOptimize")
        const ordersDetailsServiceUrl = server.getServiceUrl("analysisMapOrdersDetails")

        return {
            doOptimize: doOptimize,
            findMarkers: findMarkers,
            getOrdersDetails: getOrdersDetails
        }

        function doOptimize(deliveryUnitsIds){
            return $http.post(optimizeServiceUrl, deliveryUnitsIds)
        }

        function findMarkers(ordersSourceIds){
            return $http.post(markersServiceUrl, ordersSourceIds).then((response) => response.data)
        }

        function getOrdersDetails(ordersSourceIds){
            return $http.post(ordersDetailsServiceUrl, ordersSourceIds).then((response) => response.data)
        }
}])
