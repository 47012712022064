define([
    "./occurrenceStatusModule",
    "text!./search/OccurrenceStatusSearchView.html",
    "text!./edition/OccurrenceStatusEditionView.html"
], function (module, occurrenceStatusSearchTemplate, occurrenceStatusEditionTemplate) {
    "use strict";

    module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("monitoring.newOccurrenceStatus", {
                url: "/newOcccurrenceStatus",
                controller: "OccurrenceStatusEditionController",
                template: occurrenceStatusEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.status",
                    pageTitle: "{{'title.monitoring.newOccurrenceStatus' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceStatus: ["monitoringService", function (monitoringService) {
                        return monitoringService.getNewInstanceOccurrenceStatus();
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.OccurrenceStatusSearch", {
                url: "/occurrenceStatus?reload",
                controller: "OccurrenceStatusSearchController",
                template: occurrenceStatusSearchTemplate,
                resolve: {
                    reload: ["$stateParams", function ($stateParams) {
                        return $stateParams.reload === "true";
                    }]
                },
                data: {
                    group: "group.monitoring.occurrence.status",
                    pageTitle: "{{'title.monitoringOccurrenceStatusSearch' | translate:$stateParams}}"
                }
            })
            .state("monitoring.OccurrenceStatusEdition", {
                url: "/occurrenceStatus/:occurrenceStatusId",
                controller: "OccurrenceStatusEditionController",
                template: occurrenceStatusEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.status",
                    pageTitle: "{{'title.monitoring.occurrenceStatusEdition' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceStatus: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.findOccurrenceStatusById($stateParams.occurrenceStatusId);
                    }]
                },
                params: {
                    reload: "true"
                }
            })
            .state("monitoring.OccurrenceStatusCopy", {
                url: "/occurrenceStatus/copy/:occurrenceStatusId",
                controller: "OccurrenceStatusEditionController",
                template: occurrenceStatusEditionTemplate,
                data: {
                    group: "group.monitoring.occurrence.status",
                    pageTitle: "{{'title.monitoring.occurrenceStatusCopy' | translate:$stateParams}}"
                },
                resolve: {
                    occurrenceStatus: ["$stateParams", "monitoringService", function ($stateParams, monitoringService) {
                        return monitoringService.copyOccurrenceStatus($stateParams.occurrenceStatusId);
                    }]
                },
                params: {
                    reload: "true"
                }
            });
    }]);
});
