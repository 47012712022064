define([
    "./threeModule",
    "angular"
], function (threeModule, angular) {
    "use strict";

    return threeModule.service("ThreeRenderLoop", [function () {
        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderLoop
         * @description
         * Serviço usado para fazer um loop de renderização, para criar animações.
         *
         * @param {threeModule.ThreeRenderer} renderer Renderizador.
         * */
        function ThreeRenderLoop(renderer) {
            this.renderer = renderer;
            this.camera = null;
            this.scene = null;
            this._started = false;
        }

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderLoop#setCamera
         * @methodOf threeModule.ThreeRenderLoop
         * @description
         * Define qual camera será usada na renderização.
         * @param {THREE.Camera} camera Camera.
         * @returns {threeModule.ThreeRenderLoop} Loop de renderização
         * */
        ThreeRenderLoop.prototype.setCamera = function (camera) {
            this.camera = camera;
            return this;
        };

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderLoop#setScene
         * @methodOf threeModule.ThreeRenderLoop
         * @description
         * Define qual cena será renderizada.
         * @param {THREE.Scene} scene Cena.
         * @returns {threeModule.ThreeRenderLoop} Loop de renderização
         * */
        ThreeRenderLoop.prototype.setScene = function (scene) {
            this.scene = scene;
            return this;
        };

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderLoop#start
         * @methodOf threeModule.ThreeRenderLoop
         * @description
         * Inicia o loop de renderização com o renderer, camera e scene definidos.
         * @param {function=} callback Callback que será executada a cada frame.
         * @returns {threeModule.ThreeRenderLoop} Loop de renderização
         * */
        ThreeRenderLoop.prototype.start = function (callback) {
            this._started = true;
            render(this, callback || angular.noop());
            return this;
        };

        /**
         * @ngdoc service
         * @name threeModule.ThreeRenderLoop#stop
         * @methodOf threeModule.ThreeRenderLoop
         * @description
         * Encerra o loop de renderização.
         * @returns {threeModule.ThreeRenderLoop} Loop de renderização
         * */
        ThreeRenderLoop.prototype.stop = function () {
            this._started = false;
            return this;
        };

        function render(renderLoop, callback) {
            if (!renderLoop._started) {
                return;
            }

            if (renderLoop.scene === null || renderLoop.camera === null) {
                renderLoop.stop();
                throw new Error("camera or scene not defined for " + renderLoop);
            }

            requestAnimationFrame(render.bind(null, renderLoop, callback));
            callback();
            renderLoop.renderer.render(
                renderLoop.scene,
                renderLoop.camera);
        }

        return ThreeRenderLoop;
    }]);
});