define(["./commentModule", "angular", "text!./newComment.html"], function (module, angular, template) {
    "use strict";

    return module.directive("newComment", ["monitoringService", function () {
        return {
            restrict: "E",
            scope: {
            	value: "=",
                hideStatusChange: "<?",
                saveComment: "&?",
                findPossibleOccurrenceStatus: "&?"
            },
            template: template,
            controller: ["$scope", "$state", "$q", function ($scope, $state, $q) {
                $scope.occurrenceId = $state.params.occurrenceId;
                $scope.submitComment = function () {
                    return $q.when($scope.saveComment({comment: $scope.value}))
                        .then(function () {
                            $scope.value = getEmptyComment();
                        });
                };
            }],
            link: function(scope, element, attrs) {
            	scope.value = scope.value || getEmptyComment();
            	scope.hideTitle = angular.isDefined( attrs.hideTitle );
            }
        };

        function getEmptyComment() {
            return {
                status: null,
                files: [],
                content: null
            };
        }
    }]);
});