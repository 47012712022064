define([
    "angular",
    "./monitoringCommonsModule",
    "lodash"
], function (angular, monitoringCommonsModule, _) {
    "use strict";

    monitoringCommonsModule.provider("monitoringCommonsServiceUrls", [function () {
        var filterTemplateUrl;
        var filterUrl;

        this.$get = [function () {
            return {
                filterTemplateUrl: filterTemplateUrl,
                filterUrl: filterUrl
            };
        }];

        this.setUrl = function (url) {
            filterTemplateUrl = validateUrl(url.filterTemplateUrl, "filterTemplateUrl");
            filterUrl = validateUrl(url.filterUrl, "filterUrl");
        };

        function validateUrl(url, urlType) {
            if (!_.isString(url) || !url.trim()) {
                throw new Error("monitoringCommonsServiceUrls: " + urlType + " must be defined. got: " + url);
            }
            return url;
        }
    }]);
});
