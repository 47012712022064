define([
    "./monitoringLocalityModule"
], function (monitoringLocalityModule) {
    "use strict";

    monitoringLocalityModule.service("monitoringLocalityService", [
        "$http",
        "loadingService",
        "remoteExceptionHandler",
        "messagesModal",
        "remoteMessageModal",
        "monitoringService",
        function ($http, loadingService, remoteExceptionHandler, messagesModal, remoteMessageModal, monitoringService) {
            var self = this;

            self.save = function (locality) {
                return $http.post("/cockpit-gateway/monitoring/monitorable/locality/save", locality)
                    .then(getData)
                    .then(function (data) {
                        return remoteMessageModal(data);
                    });
            };

            self.delete = function (id) {
                return messagesModal.cancellable("dialog.warning", ["entityFilter.delete.confirm"])
                    .then(function () {
                        return loadingService(monitoringService.deleteLocality(id))
                            .catch(remoteExceptionHandler());
                    }).then(function () {
                        return messagesModal("dialog.success", {
                            keyBundle: "entityFilter.delete.ok"
                        });
                    });
            };

            function getData(response) {
                return response.data;
            }

            self.getLocalityTypes = function () {
                return monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.LocalityType")
                    .then(function (values) {
                        return values;
                    });
            };

            self.getLocalityIcons = function () {
                return monitoringService.getEnum("br.com.neolog.monitoring.monitorable.model.api.LocalityIcon")
                    .then(function (values) {
                        return values;
                    });
            };

            return self;
        }]);
});