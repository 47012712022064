import _ from "lodash"
import bundlePT from "!!file-loader!./bundle-pt.json"
import bundleEN from "!!file-loader!./bundle-en.json"
import bundleES from "!!file-loader!./bundle-es.json"
import pt_br from "./images/br.svg"
import en_us from "./images/us.svg"
import es_es from "./images/es.svg"
import es_mx from "./images/mx.svg"
import localePattern from "file-loader?name=i18n/[name].[ext]!../../node_modules/angular-i18n/angular-locale_pt-br"
import "file-loader?name=i18n/[name].[ext]!../../node_modules/angular-i18n/angular-locale_en-us"
import "file-loader?name=i18n/[name].[ext]!../../node_modules/angular-i18n/angular-locale_es-es"
import "file-loader?name=i18n/[name].[ext]!../../node_modules/angular-i18n/angular-locale_es-mx"

class Language {
    constructor(languageCode, bundleUrl, ...locales) {
        this.code = languageCode
        this.localesByCode = {}
        locales.forEach((locale) => {
            this.localesByCode[locale.code] = locale
            locale.language = this
        })
        this.locales = locales
        this.defaultLocale = locales[0]
        this.bundleUrl = bundleUrl
    }

    getLanguageCode() {
        return this.code
    }

    getLocale(localeCode) {
        return this.localesByCode[localeCode] || this.defaultLocale
    }

    getBundleUrl(){
        return this.bundleUrl
    }
}

class Locale {
    constructor(code, img) {
        this.code = code
        this.img = img
    }
    getFullCode() {
        return this.language.code + "-" + this.code
    }
    getLanguage(){
        return this.language
    }
}

var allLanguages = [
    new Language("pt", bundlePT, new Locale("br", pt_br)),
    new Language("en", bundleEN, new Locale("us", en_us)),
    new Language("es", bundleES, new Locale("es", es_es), new Locale("mx", es_mx))
]
var allLocales = _.flatten(allLanguages.map((language) => {
    return language.locales
}))
var allLocaleCodes = _.flatten(allLocales.map((locale)=>{
    return locale.getFullCode()
}))
var languagesByCode = _.keyBy(allLanguages, (lang)=> lang.getLanguageCode())

export default {
    get(languageAndLocale) {
        languageAndLocale = (languageAndLocale || "").toLowerCase()
        var language = languagesByCode[languageAndLocale]
        if (language) {
            return language.defaultLocale
        }
        var [lang, locale] = languageAndLocale.split("-")
        language = languagesByCode[lang]
        if (!language) {
            return undefined
        }
        return language.getLocale(locale)
    },
    getAllLocales(){
        return allLocales
    },
    getAllLocaleCodes(){
        return allLocaleCodes
    },
    getAllLanguages(){
        return allLanguages
    },
    getLocalePattern(){
        return localePattern.replace("pt-br", "{{locale}}")
    }
}