import angular from "angular"
import analysisModule from "../../analysisModule"
import secondaryDevicesModal from "../secondaryDevicesModal/startMonitorableConfigurationSecondaryDevicesModal.html"
import arrays from "../../../../bower_components/front-end-web-commons/app/arrays/arrays"

analysisModule.service("startMonitorableConfigurationSecondaryDevicesModal", [
    "$modal",
    "monitoringService",
    "trackingService",
    function ($modal, monitoringService, trackingService) {
        return function (deviceByMonitorable) {
            return $modal.open({
                template: secondaryDevicesModal,
                size: "lg",
                backdrop: "static",
                keyboard: false,
                controller: ["$scope",
                    function ($scope) {

                        const secundaryTruckDevices = !deviceByMonitorable.secundaryTruckDevices ? [] : angular.copy(deviceByMonitorable.secundaryTruckDevices)
                        $scope.records = secundaryTruckDevices.map(function(device){
                            return {
                                device: device
                            }
                        })

                        $scope.truckDeviceNumbers = function (viewValue) {
                            return trackingService.findByDeviceIdLike(viewValue).then(function (truckDevices) {
                                return filterSecondaryDevicesMonitorables(truckDevices)
                            })
                        }

                        $scope.secondaryTruckDevices = function (viewValue) {
                            return trackingService.findByDescriptionLike(viewValue).then(function (truckDevices) {
                                return filterSecondaryDevicesMonitorables(truckDevices)
                            })
                        }

                        function filterSecondaryDevicesMonitorables(truckDevices) {
                            if( deviceByMonitorable.primeryTruckDevice ){
                                if( arrays.contains(truckDevices, deviceByMonitorable.primeryTruckDevice) ){
                                    arrays.remove(truckDevices, deviceByMonitorable.primeryTruckDevice)
                                }
                            }
                            return arrays.minus(truckDevices, $scope.records.map(record => record.device))
                        }

                        $scope.formatTag = function (tag) {
                            if (tag) {
                                return tag.deviceId
                            }
                        }

                        $scope.assign = function () {
                            deviceByMonitorable.secundaryTruckDevices = $scope.records.map(record => record.device)
                            $scope.$close()
                        }

                        $scope.disabledOKButton = function () {
                            return $scope.records.some( record => !record.device )
                        }
                    }]
            })
        }
    }
])
