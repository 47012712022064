define([
    "../trackingMobileModule",
    "text!./monitoringMobileUrlModal.html"
], function (module, monitoringMobileUrlModalTemplate) {
    "use strict";

    return module.service("deviceConfigurationService", [
        "messagesModal",
        "loadingService",
        "trackingMobileService",
        "$modal",
        "countryLanguages",
        "remoteExceptionHandler",
        function (messagesModal,
                  loadingService,
                  trackingMobileService,
                  $modal,
                  countryLanguages,
                  remoteExceptionHandler) {
            var self = this;

            self.sendConfigurationMessage = function (device) {
                loadingService(trackingMobileService.sendConfigurationMessage(device))
                    .catch(remoteExceptionHandler())
                    .then(function () {
                        return messagesModal("dialog.success", ["trackingMobile.driver.smsSent"]);
                    });
            };

            self.openMobileQRCodeModal = function (device) {
                return loadingService(trackingMobileService.getQRCodeConfigurationUrl(device))
                    .catch(remoteExceptionHandler()).then(function (configurationUri) {
                        return $modal.open({
                            template: monitoringMobileUrlModalTemplate,
                            controller: ["$scope", "$location", function ($scope) {
                                $scope.getTrackingUrl = function () {
                                    return configurationUri;
                                };
                            }]
                        }).result;
                    });
            };

            return self;
        }])
});
