define([
    "./SensorConfigurationModule",
    "text!./SensorConfigurationEditionView.html"
], function (module, sensorConfigurationEditionView) {
    "use strict";

    return module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("monitoring.sensorConfigurationEdition", {
                url: "/sensor",
                controller: "SensorConfigurationEditionController",
                template: sensorConfigurationEditionView,
                data: {
                    group: "group.monitoring",
                    pageTitle: "{{'title.monitoring.sensorConfigurationEdition' | translate:$stateParams}}"
                },
                params: {
                    reload: "false"
                }
            });
    }]);
});