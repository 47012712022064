/**
 * Module nlgTagInputController
 *
 * Provê implementações de controllers para seleção única/múltipla de nlg(Single)TagInput.
 */
define(["angular",
    "../arrays/arrays"
], function nlgTagInputController(angular, arrays) {
    "use strict";
    var filter = arrays.filter,
        contains = arrays.contains,
        isUndefined = angular.isUndefined,
        equals = angular.equals;
    return {
        singleValueController: ["$scope", function ($scope) {
            return controller($scope, new SingleValueModelHandler($scope));
        }],
        multiValueController: ["$scope", function ($scope) {
            return controller($scope, new MultiValueModelHandler($scope));
        }]
    };

    /**
     * Factory de controllers.
     * @param $scope
     * @param modelHandler
     */
    function controller($scope, modelHandler) {
        // Se os resultados já estiverem presentes, não é necessário atrasar a consulta,
        // tampouco limitar o mínimo de caracteres
        if ($scope.source) {
            $scope.typeAheadMinLength = -1;
            $scope.typeAheadWaitMs = 0;
        } else {
            $scope.source = [];
            $scope.typeAheadMinLength = $scope.typeAheadMinLength || 3;
            $scope.typeAheadWaitMs = $scope.typeAheadWaitMs || 200;
        }

        // Inicializa modelo
        modelHandler.initModel();
        // Delega métodos de edição
        $scope.addTagString = modelHandler.addTagString;
        $scope.addTagObject = modelHandler.addTagObject;
        $scope.removeTag = modelHandler.removeTag;
        $scope.getModel = modelHandler.getModel;

        $scope.doGetTags = $scope.getTags;
        $scope.doFormat = $scope.formatTag;
        $scope.template = $scope.showIcon ? "nlgTagInputPopUpTemplateIcon.html" : "nlgTagInputPopUpTemplate.html";

        $scope.isComplete = function () {
            return $scope.disabled || modelHandler.achievedMax();
        };

        $scope.$watch("model", function (newValue) {
            if (angular.isUndefined(newValue)) {
                $scope.model = null;
            }
        });
    }

    /**
     * Implementação de controller que suporta múltipla seleção de valores.
     * @param $scope
     * @returns {MultiValueModelHandler}
     * @constructor
     */
    function MultiValueModelHandler($scope) {
        var scope = $scope;
        this.initModel = function () {
            scope.model = getModel();
        };

        this.addTagString = function (viewData) {
            var isUsingTypeAhead = !!((scope.source && scope.source.length) || scope.getTagsInUse);
            if (viewData.length > 0) {
                if (!isUsingTypeAhead) {
                    scope.addTagObject(viewData);
                } else {
                    viewData.split(",").forEach(function (item) {
                        scope.doGetTags({viewValue: item}).then(function (results) {
                            scope.addTagObject(results[0]);
                        });
                    });
                }
            }
        };

        this.addTagObject = function (viewData) {
            if (viewData && !contains(getModel(), viewData)) {
                scope.updateModel(getModel().concat([viewData]));
                scope.viewData = undefined;
            }
        };

        this.removeTag = function (tag) {
            if (isUndefined(tag)) {
                scope.updateModel(getModel().slice(0, -1));
                return;
            }
            scope.updateModel(filter(getModel(), function (el) {
                return !equals(tag, el);
            }));
        };

        this.getModel = getModel;

        function getModel() {
            return scope.model || [];
        }

        this.achievedMax = function () {
            return false;
        };
    }

    /**
     * Implementação de controller para seleção de apenas um valor.
     * @param $scope
     * @returns {SingleValueModelHandler}
     * @constructor
     */
    function SingleValueModelHandler($scope) {
        var scope = $scope;
        var delegate = new MultiValueModelHandler(scope);

        this.addTagString = delegate.addTagString;

        this.initModel = function () {
            scope.model = scope.model || null;
        };

        this.addTagObject = function (viewData) {
            if (scope.model === null && viewData) {
                scope.updateModel(viewData);
            }
            scope.viewData = null;
        };

        this.removeTag = function () {
            scope.updateModel(null);
        };

        this.getModel = function () {
            if (scope.model === null) {
                return [];
            }
            return [scope.model];
        };

        this.achievedMax = function () {
            return scope.model;
        };
    }
});