import sessionRecordsModule from "./sessionRecordsModule"
import server from "../server"

sessionRecordsModule.service("sessionRecordsService", ["$http", function searchService($http) {

    this.filter = (entityType, template, firstResult, maxResults) => {
        return $http.post(server.getServiceUrl("search." + entityType) + "presentation-tree", template, {
            params: {
                firstResult,
                maxResults
            }
        }).then((response) => response.data)
    }

    this.find = (recordId) => {
        return $http.get(server.getServiceUrl("session-record") + recordId)
            .then((response) => response.data)
    }
}])
