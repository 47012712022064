define(["../occurrencesModule", "json!../../tdnByEntity.json"],
    function (occurrencesModule, tdnByEntity) {
        "use strict";

        return occurrencesModule.controller("MonitoringOccurrenceSearchController", [
            "$scope",
            "$state",
            "$q",
            "$translate",
            "monitoringService",
            "messagesModal",
            "reload",
            "loadingService",
            "remoteExceptionHandler",
            "occurrenceService",
            "monitorablesAndTransitionsDialog",
            "tdnHelp",
            "spreadsheetExportService",
            "nlgTableDynamicManagerModal",
            "tableDynamicService",
            "tableDynamicDefinition",
            "filterState",
            function ($scope, $state, $q, $translate, monitoringService, messagesModal, reload, loadingService, remoteExceptionHandler, occurrenceService, monitorablesAndTransitionsDialog, tdnHelp, spreadsheetExportService, nlgTableDynamicManagerModal, tableDynamicService, tableDynamicDefinition, filterState) {
                $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["occurrence-create"];
                $scope.serviceName = occurrenceService.getFilterServiceUrlWithMonitorable();
                var template = [], paginationApi, paginationApiDefer = $q.defer();
                var filterApi, filterApiDefer = $q.defer();

                $scope.search = function (modifiedTemplate) {
                    template = modifiedTemplate;
                    paginationApi.seekPage(1);
                };

                $scope.onRegisterFilterApi = function (api) {
                    filterApi = api;
                    filterApiDefer.resolve();
                };

                $scope.newOccurrence = function () {
                    return occurrenceService.createNewOccurrence();
                };

                monitoringService.getContentExclusion().then(function (response) {
                    $scope.contentExclusion = response;
                });

                $q.all([paginationApiDefer.promise, filterApiDefer.promise]).then(function () {
                    filterApi.onChangeService(function (service) {
                        if (service === $scope.serviceName) {
                            if (reload) {
                                filterApi.reload();
                            } else {
                                filterApi.reset();
                            }
                        }
                    });
                });

                $scope.tableOptions = {
                    records: [],
                    columns: [
                        {
                            cellName: "actions",
                            actions: true,
                            cell: {
                                template: "<a name='editButton' class='btn mini btn-default' tooltip=\"{{'searchView.edit' | translate }}\" ui-sref='monitoring.monitoringOccurrenceEdition({ occurrenceId: record.restOccurrence.id })' style='margin-top:3px'> <span class='fa fa-pencil-square-o'></span> </a>"
                            }
                        },
                        {
                            cellName: "id",
                            header: "monitoring.occurrence.search.id",
                            cell: "{{ record.restOccurrence.id }}",
                            sortBy: "value"
                        },
                        {
                            cellName: "sourceId",
                            header: "monitoring.occurrence.monitorable.sourceId",
                            cell: "{{ record.partialMonitorables[0].monitorableSourceId}}",
                            sortBy: "value"
                        },
                        {
                            cellName: "driver",
                            header: "monitoring.occurrence.monitorable.driver",
                            cell: "{{ record.partialMonitorables[0].restDriver.name | entityFormat  }}",
                            sortBy: "value"
                        },
                        {
                            cellName: "truck",
                            header: "monitoring.occurrence.monitorable.truck",
                            cell: "{{ record.partialMonitorables[0].restTruck.name | entityFormat}}",
                            sortBy: "value"
                        },
                        {
                            cellName: "openTimeDuration",
                            header: "monitoring.occurrence.search.duration",
                            cell: "{{openTimeDuration(record.restOccurrence)}}",
                            sortBy: "value"
                        },
                        {
                            cellName: "durationInStatus",
                            header: "monitoring.occurrence.search.status.duration",
                            cell: {
                                type: "default",
                                name: "{{durationInStatus(record.restOccurrence)}}"
                            },
                            sortBy: "value"
                        },
                        {
                            cellName: "status",
                            header: "monitoring.occurrence.search.status",
                            cell: "{{ record.restOccurrence.status | entityFormat }}",
                            sortBy: "value"
                        },
                        {
                            cellName: "indicator",
                            header: "monitoring.occurrence.search.indicator",
                            textAlign: "center",
                            cell: {
                                template: "<img ng-src='{{getIndicator(record.restOccurrence)}}'/>"
                            },
                            sortBy: "value"
                        },
                        {
                            cellName: "category",
                            header: "monitoring.occurrence.search.category",
                            cell: "{{ record.restOccurrence.category | entityFormat}}",
                            sortBy: "value"
                        },
                        {
                            cellName: "cause",
                            header: "monitoring.occurrence.search.cause",
                            cell: "{{ record.restOccurrence.cause | entityFormat }}",
                            sortBy: "value"
                        },
                        {
                            cellName: "lastCommentTimestamp",
                            header: "monitoring.occurrence.search.lastCommentTimestamp",
                            cell: "{{ record.restOccurrence.lastCommentTimestamp | date: 'short' }}",
                            sortBy: "value"
                        },
                        {
                            cellName: "lastCommentUsername",
                            header: "monitoring.occurrence.search.lastCommentUsername",
                            cell: "{{ record.restOccurrence.lastCommentUsername}}",
                            sortBy: "value"
                        },

                        {
                            cellName: "operationCarrierZone",
                            header: "monitoring.occurrence.search.operationCarrierZone",
                            cell: "{{ record.partialMonitorables[0].operationCarrierZone}}",
                            sortBy: "value"
                        },
                        {
                            cellName: "monitorablesAndLocalities",
                            actions: true,
                            header: "monitoring.occurrence.search.monitorables.and.localities",
                            textAlign: "center",
                            cell: {
                                template: "<a name='inspect' class='btn btn-xs blue' ng-click='inspectRecord(record)'><span class='fa fa-search'></span></a>"
                            }
                        }
                    ],
                    pagination: {
                        onPaginate: function (firstResult, maxResults) {
                            return loadingService(occurrenceService.filterWithMonitorable(template, firstResult, maxResults))
                                .catch(remoteExceptionHandler());
                        }
                    },
                    onRegisterPagination: function (api) {
                        paginationApi = api;
                        paginationApiDefer.resolve();
                    }
                };

                $scope.inspectRecord = function (record) {
                    loadingService(occurrenceService.getMonitorables(record.restOccurrence.id))
                        .then(function (response) {
                            monitorablesAndTransitionsDialog(response);
                        }).catch(remoteExceptionHandler());
                };

                $scope.openTimeDuration = function (occurrence) {
                    return getOpenDuration(occurrence);
                };

                function getOpenDuration(occurrence) {
                    return occurrenceService.openTimeDuration(occurrence);
                };

                $scope.durationInStatus = function (occurrence) {
                    return getStatusDuration(occurrence);
                };

                function getStatusDuration(occurrence) {
                    return occurrenceService.durationInStatus(occurrence);
                };

                $scope.getIndicator = function (occurrence) {
                    return occurrenceService.getIndicator(occurrence);
                };

                $scope.exportOccurrenceSpreadsheet = function () {
                    const headers = createHeaders();
                    const dtos = createDTOs($scope.tableOptions.records);
                    const spreadsheetName = $translate.instant("occurrence.export.spreadsheet");

                    return spreadsheetExportService.exportSpreadsheet(spreadsheetName, spreadsheetName, headers, dtos)
                };

                function createHeaders() {
                    return $scope.tableOptions.columns.filter((column) => !column.actions && !column.hide)
                        .map((column) => $translate.instant(column.header));
                }


                function createDTOs(occurrenceData) {
                    return occurrenceData.reduce((rows, record) => {
                        let filteredData = [
                            {hide: isHideRecord("id"), value: record.restOccurrence?.id},
                            {hide: isHideRecord("sourceId"), value: record.partialMonitorables[0]?.monitorableSourceId},
                            {hide: isHideRecord("driver"), value: record.partialMonitorables[0].restDriver?.name},
                            {hide: isHideRecord("truck"), value: record.partialMonitorables[0].restTruck?.name},
                            {hide: isHideRecord("openTimeDuration"), value: getOpenDuration(record.restOccurrence)},
                            {hide: isHideRecord("durationInStatus"), value: getStatusDuration(record.restOccurrence)},
                            {hide: isHideRecord("status"), value: record.restOccurrence?.status.name},
                            {
                                hide: isHideRecord("indicator"),
                                value: $translate.instant(getIndicator(record.restOccurrence))
                            },
                            {hide: isHideRecord("category"), value: record.restOccurrence?.category.name},
                            {hide: isHideRecord("cause"), value: record.restOccurrence?.cause.name},
                            {
                                hide: isHideRecord("lastCommentTimestamp"),
                                value: new Date(record.restOccurrence?.lastCommentTimestamp).toLocaleString()
                            },
                            {
                                hide: isHideRecord("lastCommentUsername"),
                                value: record.restOccurrence?.lastCommentUsername
                            },
                            {
                                hide: isHideRecord("operationCarrierZone"),
                                value: record.partialMonitorables[0]?.operationCarrierZone
                            }
                        ].reduce((records, record) => {
                            if (!record.hide) {
                                records.push(record.value)
                            }
                            return records;
                        }, [])
                        rows.push(filteredData);
                        return rows;
                    }, [])
                }

                function isHideRecord(name) {
                    return tableDynamicDefinition.hiddenColumnIdentifiers.includes(name);
                }

                function getIndicator(occurrence) {
                    let indicator = occurrenceService.getIndicator(occurrence);
                    if (!indicator) {
                        return;
                    }
                    if (indicator.includes("red")) {
                        return "br.com.neolog.monitoring.monitorable.model.api.StatusSLAIndicator.RED";
                    }
                    if (indicator.includes("yellow")) {
                        return "br.com.neolog.monitoring.monitorable.model.api.StatusSLAIndicator.YELLOW";
                    }
                    return "br.com.neolog.monitoring.monitorable.model.api.StatusSLAIndicator.GREEN";
                }

                $scope.tableOptions.columns = tableDynamicService.getDynamicColumns(tableDynamicDefinition.hiddenColumnIdentifiers, $scope.tableOptions);
                $scope.openModal = function () {
                    var columnsDefinition = tableDynamicService.getColumnsDefinition($scope.tableOptions);
                    return nlgTableDynamicManagerModal(
                        {
                            identifier: tableDynamicDefinition.identifier,
                            definitions: columnsDefinition
                        }
                    ).then(function (tableDynamicDefinitions) {
                        tableDynamicDefinition = tableDynamicDefinitions
                        return messagesModal("dialog.success", {
                            keyBundle: "table.dynamic.save.success"
                        }).then(() => {
                            let occurrenceFilterState = filterState.for("/occurrences-with-monitorable")
                            if (occurrenceFilterState && occurrenceFilterState.patch) {
                                occurrenceFilterState.patch = []
                            }
                            $state.reload()
                        });
                    });
                };
            }
        ]);
    });