define([
    "./extensionModule",
    "json!../monitoring.json"
], function (extensionModule, monitoring) {
        "use strict";

        extensionModule.service("extensionService", [
            "$http",
            function ($http) {
                return {
                    newExtensions: newExtensions,
                    getRequiredTransitionExtensions: getRequiredTransitionExtensions
                };

                function getData(response) {
                    return response.data;
                }

                function transitionExtensionServicePath() {
                    return monitoring.baseUrl + monitoring.services.transitionExtensions;
                }

                function newExtensions(extensionConfigurations) {
                    return extensionConfigurations.map(function(configuration) {
                        if (configuration.dataType === "boolean") {
                            return {
                                "key": configuration.extensionKey,
                                "textValue": null,
                                "floatValue": null,
                                "integerValue": null,
                                "instantValue": null,
                                "booleanValue": false
                            }
                        }
                        return {
                            "key": configuration.extensionKey,
                            "textValue": null,
                            "floatValue": null,
                            "integerValue": null,
                            "instantValue": null,
                            "booleanValue": null
                        };
                    });
                }

                function getRequiredTransitionExtensions(transitionId) {
                    return $http.post(transitionExtensionServicePath(), transitionId).then(getData);
                }
            }]);
    });