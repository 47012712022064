import {queryModule} from "./queryModule"

import server from "../server"

export class QueryReportController {
    private filterServiceUrl: string;
    private baseUrl: string;
    private filterService: any;
    private domain: string;

    constructor(filterServiceFactory: any, filterDomain: any) {
        this.filterServiceUrl = "transactional/report/query/";
        this.baseUrl = server.getBaseUrl();
        this.domain = filterDomain.getDefaultDomain();
        this.filterService = filterServiceFactory(this.domain);
    }
}

export const queryReportController = queryModule.controller("QueryReportController", QueryReportController)