import analysisModule from "../analysisModule"
import startMonitorableView from "./startMonitorableConfigurationView.html"
import server from "../../server"
import arrays from "../../../bower_components/front-end-web-commons/app/arrays/arrays"
import angular from "angular"

analysisModule.service("startMonitorableConfigurationView", [
    "$q",
    "$http",
    "$modal",
    "monitoringService",
    "trackingService",
    "loadingService",
    "remoteExceptionHandler",
    "messagesModal",
    "startMonitorableConfigurationSecondaryDevicesModal",
    function ($q, $http, $modal, monitoringService, trackingService, loadingService, remoteExceptionHandler, messagesModal, startMonitorableConfigurationSecondaryDevicesModal) {
        return function (startMonitorableConfigurations) {
            return $modal.open({
                template: startMonitorableView,
                size: "lg",
                controller: ["$q", "$scope",
                    function ($q, $scope) {
                        $scope.startMonitorableConfigurations = startMonitorableConfigurations
                        $scope.onlyFreeDrivers = false

                        $scope.drivers = function (viewValue) {
                            if ($scope.onlyFreeDrivers) {
                                return monitoringService.findFreeDrivers(viewValue)
                            }
                            return monitoringService.findDrivers(viewValue)
                        }

                        $scope.filterByDescription = function (viewValue, deviceByMonitorable) {
                            deviceByMonitorable.secundaryTruckDevices = !deviceByMonitorable.secundaryTruckDevices ? [] : angular.copy(deviceByMonitorable.secundaryTruckDevices)
                            if ($scope.onlyFreeDrivers) {
                                return trackingService.findByDescriptionLikeWithoutMonitorable(viewValue).then(function (truckDevices) {
                                    return arrays.minus(truckDevices, deviceByMonitorable.secundaryTruckDevices)
                                })
                            }
                            return trackingService.findByDescriptionLike(viewValue).then(function (truckDevices) {
                                return arrays.minus(truckDevices, deviceByMonitorable.secundaryTruckDevices)
                            })
                        }

                        $scope.filterByDeviceId = function (viewValue, deviceByMonitorable) {
                            deviceByMonitorable.secundaryTruckDevices = !deviceByMonitorable.secundaryTruckDevices ? [] : angular.copy(deviceByMonitorable.secundaryTruckDevices)
                            if($scope.onlyFreeDrivers){
                                return trackingService.findByDeviceIdLikeWithoutMonitorable(viewValue).then(function (truckDevices) {
                                    return arrays.minus(truckDevices, deviceByMonitorable.secundaryTruckDevices)
                                })
                            }
                            return trackingService.findByDeviceIdLike(viewValue).then(function (truckDevices) {
                                return arrays.minus(truckDevices, deviceByMonitorable.secundaryTruckDevices)
                            })
                        }

                        $scope.formatTag = function (tag) {
                            if (tag) {
                                return tag.deviceId
                            }
                        }

                        $scope.openSecondaryDevicesModalConfiguration = function (deviceByMonitorable) {
                            return startMonitorableConfigurationSecondaryDevicesModal(deviceByMonitorable)
                        }

                        $scope.submit = function () {
                            loadingService($http.post(server.getServiceUrl("saveStartMonitorableConfiguration"), $scope.startMonitorableConfigurations)
                                .then(function () {
                                    $scope.$close(messagesModal("dialog.success", ["start.configuration.save.successfully"]))
                                })).catch(remoteExceptionHandler())
                        }

                        $scope.isValid = function () {
                            return $scope.startMonitorableConfigurations.every(function (configuration) {
                                return !!configuration.driver && !!configuration.primeryTruckDevice
                            })
                        }

                        $scope.delete = function (configuration) {
                            loadingService($http.post(server.getServiceUrl("deleteStartMonitorableConfiguration"), configuration)
                                .then(function () {
                                    var index = arrays.indexOf($scope.startMonitorableConfigurations, configuration)
                                    var currentConfiguration = $scope.startMonitorableConfigurations[index]
                                    currentConfiguration.driver = null
                                    currentConfiguration.primeryTruckDevice = null
                                    currentConfiguration.secundaryTruckDevices = []
                                })).catch(remoteExceptionHandler())
                        }
                    }]
            })
        }
    }
])