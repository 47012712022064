import spreadsheetModule from "./spreadsheetModule"
import spreadsheetModal from "./spreadsheetModal.html"

spreadsheetModule.service("spreadsheetModal", ["oldSpreadsheetService", "$modal", "loadingService", "messagesModal", "remoteExceptionHandler", function (oldSpreadsheetService, $modal, loadingService, messagesModal, remoteExceptionHandler) {
    this.show = (entityType) => {
        return $modal.open({
            template: spreadsheetModal,
            controller: ["$scope", ($scope) => {
                $scope.entityType = entityType
                $scope.importSpreadsheet = () => {
                    $scope.$close()
                    loadingService(oldSpreadsheetService.importSpreadsheet(entityType, $scope.model))
                        .catch(remoteExceptionHandler())
                        .then($scope.$close)
                        .then(() => messagesModal("dialog.success", "entity.validator.successMessage"))
                }
                $scope.exportTemplate = () => {
                    oldSpreadsheetService.exportTemplate(entityType)
                }
            }]
        }).result
    }
}])