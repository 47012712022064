define([
    "./temperatureModule",
    "text!./MonitoringTemperature.html"
], function (module, monitoringTemperature) {
    "use strict";

    return module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("monitoring.monitoringTemperature", {
                url: "/temperature/:monitorableId",
                controller: "MonitoringTemperatureController",
                template: monitoringTemperature,
                data: {
                    group: "group.monitoring.trip",
                    pageTitle: "{{'title.monitoring.temperature' | translate:$stateParams}}"
                }
            });
    }]);
});
