import truckAllocationView from "./truckAllocationView.html"
import truckAllocationModule from "./truckAllocationModule"

truckAllocationModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.truckallocation", {
        url: "/programming/truckallocation",
        template: truckAllocationView,
        data: {
            pageTitle: "{{'title.truckAllocation' | translate}}"
        }
    })
}])