define([
    "./occurrenceListenerModule",
    "text!./MBROccurrenceListenerView.html"
], function (module, mbrOccurrenceListenerTemplate) {
    "use strict";

    return module.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("monitoring.occurrenceListener", {
                url: "/listener",
                controller: "MBROccurrenceListenerController",
                template: mbrOccurrenceListenerTemplate,
                data: {
                    group: "group.monitoring.occurrence",
                    pageTitle: "{{'title.monitoring.mbr.occurrence.listener' | translate:$stateParams}}"
                }
            });
    }]);
});
