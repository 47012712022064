import messageConfigParamsModule from "../messageConfigParamsModule"
import template from "./emailMessageTestConfigurationsModal.html"

messageConfigParamsModule.service("emailMessageTestConfigurationsModal", [
    "$modal",
    "messageConfigParamsService",
    "loadingService",
    "messagesModal",
    "remoteExceptionHandler",
    function ( $modal, messageConfigParamsService, loadingService, messagesModal, remoteExceptionHandler) {
        return function (entity) {

            return $modal.open({
                template: template,
                size: "md",
                controller: ["$scope", function ($scope) {
                    $scope.entity = entity
                    $scope.emailTestAddress = ""
                    $scope.testConfigurations = function () {
                        loadingService(messageConfigParamsService.testConfigurations(entity.emailConfigParams,$scope.emailTestAddress))
                            .then(() => {
                                messagesModal("dialog.success", [{
                                    keyBundle: "email.test.validator.successMessage",
                                    parameters: []
                                }])
                            })
                            .catch(remoteExceptionHandler())
                        $scope.$close()
                    }
                }]
            })
        }
    }])