define([
    "angular",
], function (angular) {
    "use strict";

    return angular.module("nlgLGPDModule", [
        "nlgAuditModule",
        "nlgFilterFieldInputModule"
    ]);
});
