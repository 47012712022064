define([
    "./monitorModule",
    "lodash"
], function (module, _) {
    "use strict";

    return module.provider("monitorUrlService", [function () {
        var provider = this;
        var _gatewayUrl = null;
        provider.setGatewayUrl = function (gatewayUrl) {
            _gatewayUrl = trimBars(gatewayUrl);
        };

        provider.$get = [function () {
            var service = this;

            service.getUrl = function () {
                var args = _.map(arguments, trimBars);
                var instance = args.shift();
                var baseUrl = [_gatewayUrl, instance];
                if (instance === _gatewayUrl) {
                    baseUrl.pop();
                }
                if (instance === "r2-server") {
                    args.unshift("r2");
                }
                return "/" + _.filter(_.map(baseUrl.concat(args), trimBars), notEmpty)
                    .join("/");
            };

            return service;
        }];

        function trimBars(part) {
            return (part || "").replace(/^\/*/, "").replace(/\/*$/, "");
        }

        function notEmpty(value) {
            return typeof value !== "undefined" && value !== "";
        }

        return provider;
    }]);
});