define([
    "./genericParamsModule",
    "text!./nlgGenericParamDefinitionEdition.html"
], function (entityModule, template) {

    "use strict";

    entityModule.directive("nlgGenericParamDefinitionEdition", ["loadingService",  "genericParamDataEditionModal", function (loadingService, genericParamDataEditionModal) {

        return {
            template: template,
            scope: {
                genericParamDefinitions: "="
            },
            controller: ["$scope", function ($scope) {
                $scope.genericParametersTableOptions = {
                    columns: [{
                        "cell": {
                            template: "<a name='editButton' class='btn mini btn-default' ng-click='editGenericParamDefinition(record)' style='margin-top:3px'> <span class='fa fa-pencil-square-o'></span> </a>"
                        },
                        width: "28px"
                    }, {
                        header: "entity.property.sourceId",
                        cell: "{{ record.sourceId }}",
                        sortBy: "sourceId",
                        cellName:"sourceId"
                    }, {
                        header: "entity.property.name",
                        cell: "{{ record.name }}",
                        sortBy: "name",
                        cellName:"name"
                    }, {
                        header: "entity.property.description",
                        cell: "{{ record.description }}",
                        sortBy: "description",
                        cellName:"description"
                    }]
                };

                $scope.editGenericParamDefinition = function(definition){
                    genericParamDataEditionModal.fromDefinitions([definition]);
                };

                $scope.$watch("genericParamDefinitions", function (genericParamDefinitions) {
                    $scope.genericParametersTableOptions.records = genericParamDefinitions;
                });
            }]
        };
    }]);
});