import analysisModule from "../analysisModule"
import stopFitModal from "./stopFitModal.html"

analysisModule.service("stopFitModal", [
    "stopFitService",
    "$modal",
    "routingSelectionDialog",
    "messagesModal",
    function (stopFitService, $modal, routingSelectionDialog, messagesModal) {
        return function (stopFitDTO, order, routingConfigId, refreshOrderFilter, refreshTripFilter) {
            return $modal.open({
                template: stopFitModal,
                size: "lg",
                controller: ["$scope", function ($scope) {
                    $scope.stopFitDTO = stopFitDTO
                    $scope.stopFits = stopFitDTO.r2StopFits
                    $scope.order = order
                    $scope.contextId = routingConfigId
                    $scope.token = null
                    $scope.totalTripDistance = stopFitDTO.totalTripDistance
                    $scope.totalTripTime = stopFitDTO.totalTripTime
                    $scope.newTotalTripDistance = ""
                    $scope.newTotalTripTime = 0
                    $scope.orderToFit = {
                        "clientName": $scope.order.destination.description,
                        "type": "order",
                        "destinationId": $scope.order.destination.id
                    }
                    $scope.lastIndex = null
                    $scope.monitorableVersion = stopFitDTO.monitorableVersion
                    $scope.stopFits.forEach((stopFit) => stopFit.isNotOrderFit = true)

                    /**
                     * Bloqueia o click quando for um o índice de uma origem ou paradas anteriores da origem;
                     * Quando é uma parada que foi encaixada na mesma perna;
                     * Na próxima parada da ordem já inserida
                     * Ou quando é uma transição já executada.
                     **/
                    $scope.blockClick = function (stopFit, index) {
                        if (stopFit.originOrderLocality) {
                            return true
                        }
                        if ($scope.isUnFittable(stopFit.fittable, stopFit.index)) {
                            return true
                        }
                        const lastStop = $scope.stopFits[index - 1]
                        if (lastStop && !lastStop.isNotOrderFit && !isFitInLastStop(index)) {
                            return true
                        }
                        return stopFit.fitInLeg
                    }
                    /**
                     * Retorna true para a parada bloqueada e a próxima.
                     **/
                    $scope.isUnFittable = function (fittable, index) {
                        if (!fittable || fittable === false) {
                            return true
                        }
                        const stopFit = $scope.stopFits[index - 1]
                        if (stopFit && stopFit.fittable === false) {
                            return true
                        }
                    }
                    $scope.confirmStopFit = function () {
                        const data = {
                            "token": $scope.token,
                            "orderId": $scope.order.id,
                            "stopPosition": $scope.lastIndex,
                            "monitorableVersion": $scope.monitorableVersion,
                            "tripId": $scope.stopFitDTO.tripId
                        }
                        stopFitService.applyManualOperation(data).then((response) => {
                            if (response) {
                                messagesModal("dialog.success", [{
                                    keyBundle: "stopfit.modal.fitSuccess",
                                    parameters: []
                                }]).then(function(){
                                    refreshOrderFilter()
                                    refreshTripFilter()
                                })
                            }
                            $scope.$close()
                        })
                    }

                    $scope.selectedIndex = undefined
                    $scope.doPreManualOperation = function (stopIndex, tableIndex) {
                        let isInsertInLastStop = false
                        if (isFitInLastStop(stopIndex, tableIndex)) {
                            stopIndex++
                            isInsertInLastStop = true
                        }
                        $scope.lastIndex = stopIndex
                        stopFitService.manualOperation(stopFitDTO.tripId, getObjectToManualOperation(stopIndex))
                            .then((response) => {
                                if (response.success) {
                                    $scope.token = response.token
                                    const operationResult = response.stopFillOperationResultData[0]
                                    $scope.newTotalTripDistance = operationResult.totalDistance
                                    $scope.newTotalTripTime = operationResult.totalDuration

                                    separateOrders()
                                    insertTableRow(tableIndex)
                                    isFitBetweenLegs(tableIndex, isInsertInLastStop)
                                    setReestimatedTime(operationResult)
                                }
                            })
                    }

                    function separateOrders() {
                        $scope.stopFits.forEach((stopFit) => stopFit.fitInLeg = undefined)
                    }

                    function isOrderDestinationInTrip(destinationId) {
                        return $scope.stopFits.some((stop) => stop.type !== "order" && destinationId === stop.destinationId)
                    }

                    /**
                     * Caso o destino do pedido a ser encaixado estiver entre ou no mesmo index, não gera uma parada nova,
                     * será feito o encaixe na própria leg.
                     * Caso não exista o destino do pedido em nenhuma parada da viagem, não prossegue com o encaixe na leg.
                     **/
                    function isFitBetweenLegs(stopIndex, isInsertInLastStop) {
                        const localityId = $scope.orderToFit.destinationId
                        if (!isOrderDestinationInTrip(localityId)) {
                            return
                        }
                        if (isInsertInLastStop) {
                            stopIndex++
                            $scope.lastIndex = stopIndex
                        }

                        const nextStopFit = $scope.stopFits[stopIndex + 1]
                        if (nextStopFit && nextStopFit.destinationId === localityId) {
                            nextStopFit.fitInLeg = true
                            removeOldRow(stopIndex)
                            $scope.selectedIndex = undefined
                            return
                        }

                        const currentStopFit = $scope.stopFits[stopIndex]
                        if ($scope.selectedIndex < stopIndex && currentStopFit && currentStopFit.destinationId === localityId) {
                            currentStopFit.fitInLeg = true
                            removeOldRow(stopIndex - 1)
                            $scope.selectedIndex = undefined
                            $scope.lastIndex = stopIndex - 1
                            return
                        }

                        const lastStopFit = $scope.stopFits[stopIndex - 1]
                        if (lastStopFit && lastStopFit.destinationId === localityId) {
                            lastStopFit.fitInLeg = true
                            removeOldRow(stopIndex)
                            $scope.selectedIndex = undefined
                            $scope.lastIndex = stopIndex - 1
                        }
                    }

                    /**
                     * Insere uma linha na tabela, podendo remover o encaixe antigo que foi feito.
                     * **/
                    function insertTableRow(tableIndex) {
                        if ($scope.selectedIndex === undefined && $scope.stopFits.length - 1 === tableIndex) {
                            tableIndex++
                            addNewRow(tableIndex)
                            $scope.selectedIndex = tableIndex
                            return
                        }
                        if ($scope.selectedIndex === undefined) {
                            addNewRow(tableIndex)
                            $scope.selectedIndex = tableIndex
                            return
                        }
                        if (tableIndex < $scope.selectedIndex) {
                            addNewRow(tableIndex)
                            removeOldRow($scope.selectedIndex + 1)
                            $scope.selectedIndex = tableIndex
                            return
                        }
                        if (isFitInLastStop(tableIndex)) {
                            tableIndex++
                            addNewRow(tableIndex)
                            removeOldRow($scope.selectedIndex)
                            $scope.selectedIndex = tableIndex - 1
                            return
                        }
                        if (tableIndex > $scope.selectedIndex) {
                            addNewRow(tableIndex)
                            removeOldRow($scope.selectedIndex)
                            $scope.selectedIndex = tableIndex - 1
                        }
                    }

                    function removeOldRow(index) {
                        if (index !== undefined) {
                            $scope.stopFits.splice(index, 1)
                        }
                    }

                    function addNewRow(index) {
                        $scope.stopFits.splice(index, 0, $scope.orderToFit)
                    }

                    /**
                     * Quando efetua a operação manual, recebe as paradas na ordem já com o novo horário estimado.
                     * **/
                    function setReestimatedTime(operationResult) {
                        for (let i = 0; i < $scope.stopFits.length; i++) {
                            const stopFit = $scope.stopFits[i]
                            if (!$scope.isUnFittable(stopFit.fittable, i) || stopFit.type === "order") {
                                stopFit.newStop = i
                                stopFit.newTime = operationResult.arrivalTimeByStop[i]
                            } else {
                                stopFit.newStop = "-"
                                stopFit.newTime = "-"
                            }
                        }
                    }

                    function isFitInLastStop(stopIndex, tableIndex) {
                        if (tableIndex) {
                            return $scope.stopFits.length - 1 === tableIndex
                        }
                        return $scope.stopFits.length - 1 === stopIndex
                    }

                    function getObjectToManualOperation(index) {
                        return {
                            routingConfigId: $scope.contextId,
                            orderId: $scope.order.id,
                            destinationPosition: index
                        }
                    }
                }]
            }).result
        }
    }])