define(["./mapsModule", "../arrays/arrays"], function (mapsModule, arrays) {
    "use strict";

    /**
     * @ngdoc service
     * @name mapsModule.mapsLatLngApi
     * @description
     * Serviço que provê métodos para trabalhar com a API do google maps.
     * Este serviço retorna uma promessa que contém os métodos expostos.
     * */
    return mapsModule.factory("mapsLatLngApi", ["uiGmapGoogleMapApi", function (uiGmapGoogleMapApi) {
        return uiGmapGoogleMapApi.then(function (maps) {
            var self;
            return (self = {
                /**
                 * @ngdoc method
                 * @name mapsModule.mapsLatLngApi#toLatLng
                 * @methodOf mapsModule.mapsLatLngApi
                 * @description
                 * Converte um objeto que contém `latitude` e `longitude` para um objeto do tipo `LatLng`
                 * da API do google maps.
                 * @param {object} geoInfo Objeto com `latitude` e `longitude`.
                 * @returns {LatLng} Objeto do tipo LatLng do google.
                 * */
                toLatLng: function (geoInfo) {
                    return new maps.LatLng(geoInfo.latitude, geoInfo.longitude);
                },

                /**
                 * @ngdoc method
                 * @name mapsModule.mapsLatLngApi#fromLatLng
                 * @methodOf mapsModule.mapsLatLngApi
                 * @description
                 * Converte um objeto LatLng do google para outro, que contém `latitude` e `longitude`.
                 * @param {LatLng} latLng Objeto do tipo LatLng do google.
                 * @returns {object} Objeto com `latitude` e `longitude`.
                 * */
                fromLatLng: function (latLng) {
                    return {
                        latitude: latLng.lat(),
                        longitude: latLng.lng()
                    };
                },

                /**
                 * @ngdoc method
                 * @name mapsModule.mapsLatLngApi#toBounds
                 * @methodOf mapsModule.mapsLatLngApi
                 * @description
                 * Cria um objeto com os limites dos pontos geográficos fornecidos.
                 * @param {object[]} geoInfos Array de objetos com `latitude` e `longitude`.
                 * @returns {object} Objeto contendo um ponto no nordeste e um no sudoeste que contenham todos os
                 * pontos fornecidos no geoInfos.
                 * */
                toBounds: function (geoInfos) {
                    var bounds = new maps.LatLngBounds();
                    arrays.each(geoInfos, function (geoInfo) {
                        bounds.extend(self.toLatLng(geoInfo));
                    });
                    return {
                        northeast: self.fromLatLng(bounds.getNorthEast()),
                        southwest: self.fromLatLng(bounds.getSouthWest())
                    };
                }
            });
        });
    }]);
});