define([
    "angular",
    "./tollModule"
], function (angular, tollModule) {
    "use strict";
    tollModule.provider("tollServiceUrl", [function () {

        var calculateTollUrlForNodeIdentifier;

        this.$get = [function () {
            return {
                getCalculateTollUrlForNodeIdentifier: getCalculateTollUrlForNodeIdentifier
            };
        }];

        function getCalculateTollUrlForNodeIdentifier() {
            return calculateTollUrlForNodeIdentifier;
        }

        this.configure = function (server) {
            calculateTollUrlForNodeIdentifier = server.calculateTollUrlForNodeIdentifier;
        };
    }]);
});

