import gatewayServer from "./gatewayServer"

const trackingBaseUrl = gatewayServer.gatewayBaseUrl + "/tracking"
const services = {
    "trip": trackingBaseUrl + "/trip",
    "lastSignal": trackingBaseUrl + "/signal/trip"
}

export default {
    getBaseUrl() {
        return trackingBaseUrl
    },
    getServiceUrl(service){
        if (!services[service]) {
            return void 0
        }
        return services[service]
    }
}