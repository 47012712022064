define([
    "./transitionsModule",
    "./transitionsConfiguration",
    "./transitionService",
    "./TransitionStatus",
    "./transitionOverview/MonitoringTransitionOverviewController",
    "./nlgTransitionTimeline",
    "./transitionAccordion",
    "./nlgTransitions",
    "../transitionFailureReason/transitionFailureReasonModal"
], function () {
});