import spreadsheetModule from "./spreadsheetModule"
import server from "../server"

spreadsheetModule.service("oldSpreadsheetService", ["$translate", "uploadService", "downloadBinary", "xlsService", function ($translate, uploadService, downloadBinary, xlsService) {
    this.importSpreadsheet = (entityType, spreadsheetData) => {
        return uploadService.post(server.getServiceUrl("search." + entityType) + "spreadsheet", spreadsheetData)
    }

    this.exportTemplate = (entityType) => {
        downloadBinary.get(server.getServiceUrl(
            "search." + entityType) + "spreadsheet", $translate.instant("spreadsheet.template.filename." + entityType) + ".xls")
    }

    this.exportData = xlsService
}])