import {integrationDocumentationModule} from "./integrationDocumentationModule";
import {IntegrationDocumentationService, WsdlIdentifier} from "./integrationDocumentationService";
import {groupBy} from "lodash";
import {IntegrationDocumentationParser} from "./integrationDocumentationParser";
import {LoadingService, RemoteExceptionHandler} from "../../../bower_components/front-end-web-commons";

class NlgIntegrationDocumentation {
    wsdlsByService: Record<string, WsdlIdentifier[]> = {}

    constructor(private integrationDocumentationService: IntegrationDocumentationService,
                loadingService: LoadingService,
                remoteExceptionHandler: RemoteExceptionHandler) {
        loadingService(integrationDocumentationService.getWsdls())
            .then(wsdl => this.wsdlsByService = groupBy(wsdl, identifier => identifier.service))
            .catch(remoteExceptionHandler())
    }

    getWsdlContentLink(identifier: WsdlIdentifier) {
        return this.integrationDocumentationService.getWsdlContentLink(identifier)
    }
}

integrationDocumentationModule.directive("nlgIntegrationDocumentation", function () {
    return {
        // @ts-ignore
        templateUrl: require("link!./nlgIntegrationDocumentation.html"),
        controller: NlgIntegrationDocumentation,
        controllerAs: "ctrl"
    }
})