define([
    "./monitoringNodeCommandsModule",
    "./nodeCommandMonitorableDetails",
    "./nodeCommandMonitorableStart",
    "./nodeCommandMonitorableFinish",
    "./nodeCommandMonitorableCancel",
    "./nodeCommandTransitionFinalize",
    "./nodeCommandTransitionNotAccomplish",
    "./nodeCommandSelectAllTransitions",
    "./nodeCommandTemperatureGraph"
], function (module) {
    "use strict";

    return module.config(["$provide", function ($provide) {
        $provide.decorator("commonNodeCommandExecutor", [
            "$delegate",
            "NodeCommandExecutor",
            "nodeCommandMonitorableDetails",
            "nodeCommandMonitorableStart",
            "nodeCommandMonitorableFinish",
            "nodeCommandMonitorableCancel",
            "nodeCommandTransitionFinalize",
            "nodeCommandTransitionNotAccomplish",
            "nodeCommandSelectAllTransitions",
            "nodeCommandTemperatureGraph",
            function (
                $delegate,
                NodeCommandExecutor,
                nodeCommandMonitorableDetails,
                nodeCommandMonitorableStart,
                nodeCommandMonitorableFinish,
                nodeCommandMonitorableCancel,
                nodeCommandTransitionFinalize,
                nodeCommandTransitionNotAccomplish,
                nodeCommandSelectAllTransitions,
                nodeCommandTemperatureGraph) {

                return new NodeCommandExecutor({
                    "nodeCommandMonitorableDetails": nodeCommandMonitorableDetails.generalTab,
                    "nodeCommandMonitorableTransitions": nodeCommandMonitorableDetails.transitionsTab,
                    "nodeCommandMonitorableOccurrences": nodeCommandMonitorableDetails.occurrencesTab,
                    "nodeCommandMonitorableStart": nodeCommandMonitorableStart,
                    "nodeCommandMonitorableFinish": nodeCommandMonitorableFinish,
                    "nodeCommandMonitorableCancel": nodeCommandMonitorableCancel,
                    "nodeCommandTransitionFinalize": nodeCommandTransitionFinalize,
                    "nodeCommandTransitionNotAccomplish": nodeCommandTransitionNotAccomplish,
                    "nodeCommandSelectAllTransitions": nodeCommandSelectAllTransitions,
                    "nodeCommandTemperatureGraph": nodeCommandTemperatureGraph
                }, $delegate);
            }]);
    }]);
});