import vehicleModule from "./vehicleModule"
import {TollVehicleType} from "./TollVehicleType"

vehicleModule.controller("vehicleController", [
    "$scope",
    "sourceEntity",
    "entity",
    "entityEditionService",
    "productCategoryService",
    "messagesModal",
    ($scope, sourceEntity, entity, entityEditionService, productCategoryService, messagesModal) => {
        const hasOwnProperty = Object.hasOwnProperty
        const updateService = entityEditionService.crudHelper("vehicle", sourceEntity)
        $scope.entity = entity
        $scope.availability = undefined

        productCategoryService.findIncompatibleCandidates().then((categories) => {
            $scope.productCategories = categories
        })

        $scope.getVehicleTypes = () => {
            return Object.values(TollVehicleType)
        }

        $scope.isTruck = () => {
            return entity.vehicleType === TollVehicleType.TRUCK
        }

        $scope.resetAxlesNumber = () => {
            if ($scope.isTruck()) {
                entity.axles = 2
                entity.returnAxles = 2
                return
            }
            entity.axles = undefined
            entity.returnAxles = undefined
        }

        $scope.filterAvailability = (patch) => {
            let origin
            for (let i = 0; i < patch.length; i++) {
                const candidate = patch[i].value
                const isOriginPatch = candidate !== []
                    && hasOwnProperty.call(candidate, "entityClass")
                    && candidate.entityClass === "br.com.neolog.model.Locality"
                if (isOriginPatch) {
                    origin = candidate.sourceId
                    break
                }
            }
            const availabilities = $scope.entity.vehicleAvailabilities
            for (let i = 0; i < availabilities.length; i++) {
                const availability = availabilities[i]
                const isValidAvailability = availability.origin.sourceId === origin
                    && !(availability.additionalAvailability === 0 && availability.availabilityPlates.length === 0)
                if (isValidAvailability) {
                    $scope.availability = availability
                    return
                }
            }
            messagesModal("dialog.warning", [{
                keyBundle: "vehicle.availability.invalidOrigin",
                parameters: [origin]
            }])
        }

        $scope.save = updateService.save.bind(updateService, entity)
        $scope.saveAndContinue = updateService.save.bind(updateService, entity, true)
    }])