import authenticationModule from "./authenticationModule"

authenticationModule.controller("authenticationController", ["$scope", "authenticationService", "$state", ($scope, authenticationService, $state) => {
    $scope.credentials = {}
    $scope.hasToShow = false

    function followToUrl() {
        var targetUrl = authenticationService.getTargetState()
        if (targetUrl) {
            $state.go(targetUrl, authenticationService.getTargetStateParams())
        } else {
            $state.go($scope.loginState)
        }
    }

    if (authenticationService.isAuthenticated()) {
        authenticationService.validateToken()
            .then(followToUrl)
    }

    $scope.authenticate = function () {
        authenticationService.authenticate($scope.credentials.username, $scope.credentials.password)
            .then(followToUrl)
            .catch((response) => {
                var errors = response.data
                if (response.status === 504) {
                    return $scope.errorMessages = [{keyBundle: "messages.authentication.connectionError"}]
                }
                if (response.status === 403) {
                    return $scope.errorMessages = [{keyBundle: "messages.authentication.accessDenied"}]
                }
                if (Array.isArray(errors)) {
                    $scope.errorMessages = errors
                } else {
                    $scope.errorMessages = [{keyBundle: response.data.message ? response.data.message : response.data}]
                }
            })
    }

    $scope.hidePassword = () => {
        $scope.hasToShow = !$scope.hasToShow
    }

    $scope.getInputType = () => {
        if ($scope.hasToShow) {
            return "text"
        }
        return "password"
    }

    $scope.getEyeClass = () => {
        if ($scope.hasToShow) {
            return "fa fa-eye"
        }
        return "fa fa-eye-slash"
    }


}])