define(["./monitoringDeliveryOverviewModule"], function (module) {
    "use strict";

    return module.controller("monitoringDeliveryOverviewController", [
        "$scope",
        "$q",
        "monitoringService",
        "loadingService",
        "remoteExceptionHandler",
        "modalSubtitles",
        "$filter",
        "$translate",
        function ($scope, $q, monitoringService, loadingService, remoteExceptionHandler, modalSubtitles, $filter, $translate) {
            $scope.serviceName = monitoringService.getMonitoringDeliveryOverviewFilterUrl();
            $scope.isActiveSummaryPerPage = true;

            let page = 1;
            let firstPage = 1
            let template = [], paginationApi, paginationApiDefer = $q.defer();
            let filterApi, filterApiDefer = $q.defer();

            $scope.openModalSubtitles = () => {
                modalSubtitles()
            }

            $scope.search = function (modifiedTemplate) {
                template = modifiedTemplate;
                paginationApi.seekPage(firstPage);
            };

            $scope.onRegisterFilterApi = function (api) {
                filterApi = api;
                filterApiDefer.resolve();
            };
            $scope.summary = {
                total: 0,
                delivered: 0,
                notAccomplished: 0,
                pending: 0,
                occurrences: 0
            };

            $scope.tableOptions = {
                records: [],
                pagination: {
                    maxPageSize: 10,
                    onPaginate: function (firstResult, maxResults) {
                        page = $scope.tableOptions.pagination.currentPage;
                        return loadingService(monitoringService.filterMonitoringDelivery(template, firstResult, maxResults))
                            .then(function (response) {
                                $scope.summary = response.result[0]["summary"]
                                $scope.totalSummary = response.result[0]["totalSummary"]
                                response.result = response.result[0]["results"];
                                $scope.isActiveSummaryPerPage = true;
                                return response;
                            }).catch(remoteExceptionHandler());
                    }
                },
                onRegisterPagination: function (api) {
                    paginationApi = api;
                    paginationApiDefer.resolve();
                }
            };

            $scope.getNumberClass = (number) => {
                if (number >= 100) {
                    return "more-than-hundred"
                }
            }


            $scope.getStatusClass = (stop) => {
                return getStatusType(stop).toLowerCase();
            }

            function getStatusType(stop) {
                let lastDotIndex = stop.statusType.lastIndexOf(".");
                return stop.statusType.substring(lastDotIndex + 1);
            }

            $scope.getTooltipMessage = (stop) => {
                let statusType = getStatusType(stop);
                if (statusType === "ORIGIN") {
                    return $translate.instant("monitoring.delivery.overview.message.origin.estimated.timestamp")
                        + getDateOrNA(stop.currentEstimatedTimestamp);
                }
                if (statusType === "ORIGIN_FINALIZED") {
                    return $translate.instant("monitoring.delivery.overview.message.origin.finalized.timestamp")
                        + getDateOrNA(stop.lastFinalizedTimestamp);
                }

                if (stop.lastFinalizedTimestamp) {
                    return $translate.instant("monitoring.delivery.overview.message.finalized.timestamp")
                        + getDateOrNA(stop.lastFinalizedTimestamp);
                }
            }

            function getDateOrNA(dateTime) {
                if (!dateTime) {
                    return "N/A"
                }
                let dateFilter = $filter("date");
                return dateFilter(dateTime, "short")
            }

        }]);
});
