import panelModule from "./r2PanelModule"
import panelBodyView from "./panelBodyView.html"

panelModule.directive("nlgPanelBody", [()=>{
    return {
        restrict: "E",
        require: "^nlgPanel",
        template: panelBodyView,
        transclude: true
    }
}])