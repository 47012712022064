import transitionsConfigModule from "./transitionsConfigModule"

transitionsConfigModule.controller("transitionsConfigController", ["$scope", "transitionsConfigService", "loadingService", "messagesModal", "remoteExceptionHandler",
    function ($scope, transitionsConfigService, loadingService, messagesModal, remoteExceptionHandler) {

        $scope.entity = {}
        $scope.possibleLoadTransitions = ["Chegada", "Início de Carregamento", "Fim de Carregamento", "Saída"]
        $scope.possibleUnloadTransitions = ["Chegada", "Início de Descarregamento", "Fim de Descarregamento", "Saída"]

        function getTransitionsConfig() {
            return loadingService(transitionsConfigService.getTransitionsConfig()).then((transitionsConfig) => {
                $scope.entity = transitionsConfig
            })
        }

        getTransitionsConfig()

        $scope.save = function () {
            loadingService(transitionsConfigService.save($scope.entity))
                .catch(remoteExceptionHandler())
                .then(() => getTransitionsConfig())
                .then(() => {
                    messagesModal("dialog.success", [{
                        keyBundle: "entity.validator.successMessage",
                        parameters: []
                    }])
                })
        }
    }])