import loadUnloadDurationModule from "./loadUnloadDurationModule"

loadUnloadDurationModule.controller("loadUnloadDurationController", ["$scope", "loadUnloadDurationService", "loadingService", "messagesModal", "remoteExceptionHandler",
    function ($scope, loadUnloadDurationService, loadingService, messagesModal, remoteExceptionHandler) {

        $scope.entity = {}

        function getConfig() {
            return loadingService(loadUnloadDurationService.getLoadUnloadDurationConfig()).then((config) => {
                $scope.entity = config
            })
        }

        getConfig()

        $scope.save = function () {
            loadingService(loadUnloadDurationService.createOrUpdateConfig($scope.entity))
                .catch(remoteExceptionHandler())
                .then(() => getConfig())
                .then(() => {
                    messagesModal("dialog.success", [{
                        keyBundle: "entity.validator.successMessage",
                        parameters: []
                    }])
                })
        }

    }])