import jobModule from "./jobModule"
import server from "../server"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"
import confirmationModalView from "./../edition/confirmationModalView.html"

jobModule.controller("jobController", ["$scope", "$q", "jobService", "messagesModal", "remoteExceptionHandler", "violationModal", "$modal", "loadingService", ($scope, $q, jobService, messagesModal, remoteExceptionHandler, violationModal, $modal, loadingService) => {

    const paginationApi = $q.defer()
    let filterPatch = []

    $scope.filterServiceUrl = server.getRelativeUrl("search.jobs")

    $scope.tableOptions = {
        records: [],
        pagination: {
            onPaginate: function (firstResult, maxResults) {
                return jobService.filter("jobs", filterPatch, firstResult, maxResults)
                    .then((response) => {
                        response.result = response.result[0].children
                        mapChildrenProperties(response.result)
                        return response
                    })
            }
        },
        onRegisterPagination: function (api) {
            paginationApi.resolve(api)
        }
    }

    function mapChildrenProperties(children) {
        arrays.each(children, function (child) {
            var map = {}
            arrays.each(child.properties, function (property) {
                map[property.messageKey] = property
            })
            child.mappedProperties = map
        })
    }

    $scope.search = (patch) => {
        filterPatch = patch
        return paginationApi.promise.then((api) => api.seekPage(1))
    }

    $scope.cancel = (record) => {
        $scope.openWarningModal(record.id).then(() => {
            loadingService(jobService.cancelJob(record.id)).then(() => {
                messagesModal("dialog.success", "programming.optimization.cancel.success")
                    .finally(() => {
                        paginationApi.promise.then((api) => api.seekPage(1))
                    })
            }).catch(remoteExceptionHandler())
        })
    }

    $scope.openWarningModal = (jobId) => {
        return $modal.open({
            controller: ["$scope", ($scope) => {
                $scope.header = "dialog.warning"
                $scope.messageList = [{
                    keyBundle: "programming.optimization.cancel.ask",
                    parameters: [jobId]
                }]
            }],
            template: confirmationModalView
        }).result
    }

    $scope.violations = (record) => {
        loadingService(jobService.showViolations(record.id))
            .then((response) => {
                if (!response) {
                    return messagesModal("dialog.warning", "programming.optimization.violations.none")
                }
                var result = {
                    "summary": response
                }
                violationModal(result)
            })
    }

    $scope.downloadJob = (record) => {
        let jobId = record.id
        loadingService(jobService.canDownloadJob(jobId))
            .then((response) => {
                if (!response) {
                    return messagesModal("dialog.warning", "presentationnode.nodeproperty.job.without.snapshot")
                        .catch(() => {
                            // ignore backdrop click
                        })
                }
                jobService.download(jobId)
            }).catch(remoteExceptionHandler())
    }

    $scope.shouldDisable = (record) => {
        return record.mappedProperties["presentationnode.job.canCancel"].value
            || record.mappedProperties["presentationnode.job.status"].value === "br.com.neolog.model.job.JobStatus.ABORTED"
    }
}])
