define(["./trackingModule",
    "text!./TrackingView.html",
    "text!./truckDevice/TruckDeviceSearchView.html",
    "text!./truckDevice/TruckDeviceEditionView.html"],
    function (trackingModule, trackingView, truckDeviceSearchTemplate, truckDeviceEditionTemplate) {
    "use strict";

    trackingModule.config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state("tracking.overview", {
                url: "/overview",
                controller: "TrackingController",
                template: trackingView,
                data: {
                    group: "group.monitoring.tracking",
                    pageTitle: "{{'title.tracking' | translate:$stateParams}}"
                }
            })
            .state("tracking.truckDeviceSearch", {
                url: "/truck-device",
                controller: "TruckDeviceSearchController",
                template: truckDeviceSearchTemplate,
                data: {
                    group: "group.monitoring.tracking.truck.device",
                    pageTitle: "{{'title.tracking.truckDeviceSearch' | translate:$stateParams}}"
                },
                params: {
                    reload: "true"
                }
            })
            .state("tracking.truckDeviceEdition", {
                url: "/truck-device/:truckDeviceId",
                controller: "TruckDeviceEditionController",
                template: truckDeviceEditionTemplate,
                data: {
                    group: "group.monitoring.tracking.truck.device",
                    pageTitle: "{{'title.tracking.truckDeviceEdition' | translate:$stateParams}}"
                },
                resolve: {
                    truckDevice: ["$stateParams", "trackingService", function ($stateParams, trackingService) {
                        return $stateParams.truckDeviceId ? trackingService.findDevice($stateParams.truckDeviceId) : null;
                    }]
                },
                params: {
                    reload: "true"
                }
            });
    }]);
});
