import angular from "angular"
import "../edition/edition"
import "front-end-web-commons"

export default angular.module("orderModule", [
    "editionModule",
    "nlgTableModule",
    "timeModule",
    "attributableModule",
    "classifiableModule"
])