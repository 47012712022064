define([
    "./SensorConfigurationModule",
    "angular",
    "front-end-web-commons/app/arrays/arrays",
    "lodash",
    "json!../tdnByEntity.json"
], function (sensorConfigurationModule, angular, arrays, _, tdnByEntity) {
    "use strict";

    return sensorConfigurationModule.controller("SensorConfigurationEditionController", [
        "$scope",
        "$state",
        "messagesModal",
        "loadingService",
        "remoteExceptionHandler",
        "monitoringService",
        "mbrService",
        "tdnHelp",
        function ($scope, $state, messagesModal, loadingService, remoteExceptionHandler, monitoringService, mbrService, tdnHelp) {
            $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["sensor-configuration"];
            const TEMPERATURE = "br.com.neolog.tracking.model.api.SensorDataType.TEMPERATURE";
            const DOOR = "br.com.neolog.tracking.model.api.SensorDataType.CONTAINER_DOOR";
            $scope.inputSensors = [];
            $scope.emptySensor = {
                id: "",
                identifier: "",
                providerId: "",
                sensorType: "",
                temperatureRangeConfig: "",
                monitorableId: ""
            };
            init();

            function init() {
                return loadingService(monitoringService.findAllSensors())
                    .catch(remoteExceptionHandler())
                    .then(function (sensors) {
                        $scope.inputSensors = [];
                        if (sensors.length > 0) {
                            sensors.forEach(sensor => $scope.addSensor(sensor))
                        } else {
                            $scope.addSensor($scope.emptySensor);
                        }
                        ;
                    });
            }

            $scope.addSensor = function (sensor) {
                $scope.inputSensors.push({
                    id: sensor.id,
                    identifier: sensor.identifier,
                    providerId: sensor.providerId,
                    sensorType: sensor.sensorType,
                    temperatureRangeConfig: sensor.temperatureRangeConfig,
                    monitorableId: sensor.monitorableId
                });
            };

            $scope.providers = function () {
                return monitoringService.listProviders()
                    .catch(remoteExceptionHandler())
                    .then(function (providers) {
                        return _.map(providers, "id");
                    });
            };
            $scope.sensorType = function () {
                return monitoringService.getEnum("br.com.neolog.tracking.model.api.SensorDataType")
                    .then(function (values) {
                        return values;
                    });
            };
            $scope.temperatureRangeConfigs = function () {
                return monitoringService.getTemperatureRangeConfigs()
                    .catch(remoteExceptionHandler())
                    .then(function (configuration) {
                        return configuration;
                    });
            };

            $scope.isValid = function () {
                for (let i = 0; i < $scope.inputSensors.length; i++) {
                    if (!validSensor($scope.inputSensors[i])) {
                        return false;
                    }
                }
                return true;
            };

            function validSensor(sensor) {
                if ($scope.isTemperature(sensor.sensorType)) {
                    return sensor.providerId && sensor.temperatureRangeConfig;
                }
                return sensor.providerId && sensor.sensorType;
            };

            $scope.isTemperature = function (sensorType) {
                return sensorType == TEMPERATURE;
            };

            $scope.moveParamUp = function (index) {
                var oldValue = $scope.inputSensors[index - 1];
                $scope.inputSensors[index - 1] = $scope.inputSensors[index];
                $scope.inputSensors[index] = oldValue;
            };
            $scope.moveParamDown = function (index) {
                var oldValue = $scope.inputSensors[index + 1];
                $scope.inputSensors[index + 1] = $scope.inputSensors[index];
                $scope.inputSensors[index] = oldValue;
            };
            $scope.removeItem = function (index, item) {
                arrays.removeAt(item, index);
            };
            $scope.isFirst = function (index) {
                return index === 0;
            };

            $scope.isLastParam = function (index) {
                return index === $scope.inputSensors.length - 1;
            };

            $scope.saveEntity = function () {
                function updateSensorIdentifier(providersMap) {
                    Object.keys(providersMap).forEach(providerKey => {
                        const typeMap = providersMap[providerKey];
                        Object.keys(typeMap).forEach(typeKey => {
                            const configs = typeMap[typeKey];
                            configs.forEach((config, index) => {
                                config.identifier = index + 1;
                            })
                        })
                    })
                }

                const providersMap = _.groupBy($scope.inputSensors, 'providerId');
                _.forEach(providersMap, (value, key) => {
                    providersMap[key] = _.groupBy(providersMap[key], config => {
                        return config.sensorType;
                    })
                });

                updateSensorIdentifier(providersMap);

                return loadingService(mbrService.createSensorConfiguration($scope.inputSensors)
                    .catch(remoteExceptionHandler())
                    .then(function () {
                        messagesModal("dialog.success", ["sensor.configuration.save.success"]);
                    })
                    .finally(function () {
                        init();
                    })
                );

            };

            $scope.updateProvider = function (index) {
                if (!!$scope.inputSensors[index].sensorType) {
                    checkSensorTypeDoorProviderConstraint(index);
                }
            };

            $scope.updateType = function (index) {
                checkEmptiness(index);
                if ($scope.inputSensors[index].sensorType === DOOR) {
                    checkSensorTypeDoorProviderConstraint(index);
                }
            };

            function checkEmptiness(index) {
                if (!$scope.inputSensors[index].sensorType) {
                    $scope.inputSensors[index].temperatureRangeConfig = null;
                }
            }

            function checkSensorTypeDoorProviderConstraint(index) {
                for (let i = 0; i < $scope.inputSensors.length; i++) {
                    let sensor = $scope.inputSensors[i];
                    if (i === index) {
                        continue;
                    }
                    if (sensor.sensorType === DOOR && sensor.providerId === $scope.inputSensors[index].providerId) {
                        $scope.inputSensors[index].sensorType = TEMPERATURE;
                        messagesModal("dialog.error", ["sensor.configuration.type.provider.constraint.violation"]);
                    }
                }
            }
        }
    ]);
});