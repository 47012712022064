import spreadsheetModule from "./spreadsheetModule"

spreadsheetModule.service("newSpreadsheetConverter", [
    function () {

        function convertPayloadToEntity(entityName, payload) {
            switch (entityName) {
                case "DELETE_ORDER": {
                    return {
                        sourceId: payload.sourceId
                    }
                }
                case "DELETE_LOCALITY": {
                    return {
                        sourceId: payload.sourceId
                    }
                }
                case "VEHICLE_AVAILABILITY": {
                    return {
                        originSourceId: payload.originSourceId,
                        vehicleSourceId: payload.vehicleSourceId,
                        additionalAvailability: payload.additionalAvailability,
                        plate: payload.plate,
                        countryAcronym: payload.countryAcronym,
                        state: payload.state,
                        city: payload.city
                    }
                }
                case "PRODUCT":
                    return {
                        sourceId: payload.sourceId,
                        name: payload.name,
                        categorySourceId: payload.categorySourceId,
                        factor: payload.factor,
                        classifications: createClassifications(payload),
                        attributes: createAttributes(payload)
                    }
                case "PRODUCT_CATEGORY":
                    return {
                        sourceId: payload.sourceId,
                        name: payload.name,
                        factor: payload.factor,
                        classifications: createClassifications(payload),
                        attributes: createAttributes(payload)
                    }
                case "ORDER":
                    return {
                        sourceId: payload.orderSourceId,
                        originSourceId: payload.originSourceId,
                        destinationSourceId: payload.destinationSourceId,
                        observation: payload.observation,
                        pickUpWindowStart: payload.pickUpWindowStart,
                        pickUpWindowEnd: payload.pickUpWindowEnd,
                        deliveryWindowStart: payload.deliveryWindowStart,
                        deliveryWindowEnd: payload.deliveryWindowEnd,
                        orderTypeSourceId: payload.orderTypeSourceId,
                        classifications: createClassifications(payload),
                        attributes: createAttributes(payload),
                        orderItem: {
                            sourceId: payload.orderItemSourceId,
                            quantityOfShipmentUnits: payload.quantityOfShipmentUnits,
                            value: payload.value,
                            volume: payload.volume,
                            weight: payload.weight,
                            productSourceId: payload.productSourceId,
                            classifications: {
                                sourceIdClassificationType1: payload.orderItemSourceIdClassificationType1,
                                sourceIdClassification1: payload.orderItemSourceIdClassification1,
                                sourceIdClassificationType2: payload.orderItemSourceIdClassificationType2,
                                sourceIdClassification2: payload.orderItemSourceIdClassification2,
                                sourceIdClassificationType3: payload.orderItemSourceIdClassificationType3,
                                sourceIdClassification3: payload.orderItemSourceIdClassification3,
                                sourceIdClassificationType4: payload.orderItemSourceIdClassificationType4,
                                sourceIdClassification4: payload.orderItemSourceIdClassification4,
                                sourceIdClassificationType5: payload.orderItemSourceIdClassificationType5,
                                sourceIdClassification5: payload.orderItemSourceIdClassification5
                            },
                            attributes: {
                                sourceIdAttributeType1: payload.orderItemSourceIdAttributeType1,
                                sourceIdAttribute1: payload.orderItemSourceIdAttribute1,
                                sourceIdAttributeType2: payload.orderItemSourceIdAttributeType2,
                                sourceIdAttribute2: payload.orderItemSourceIdAttribute2,
                                sourceIdAttributeType3: payload.orderItemSourceIdAttributeType3,
                                sourceIdAttribute3: payload.orderItemSourceIdAttribute3,
                                sourceIdAttributeType4: payload.orderItemSourceIdAttributeType4,
                                sourceIdAttribute4: payload.orderItemSourceIdAttribute4,
                                sourceIdAttributeType5: payload.orderItemSourceIdAttributeType5,
                                sourceIdAttribute5: payload.orderItemSourceIdAttribute5
                            }
                        }
                    }
                case "VEHICLE":
                    return {
                        sourceId: payload.sourceId,
                        name: payload.name,
                        minVolume: payload.minVolume,
                        maxVolume: payload.maxVolume,
                        minWeight: payload.minWeight,
                        maxWeight: payload.maxWeight,
                        minValue: payload.minValue,
                        maxValue: payload.maxValue,
                        maxStops: payload.maxStops,
                        tollVehicleType: payload.tollVehicleType,
                        tollAxles: payload.tollAxles,
                        tollReturnAxles: payload.tollReturnAxles,
                        defaultSpeed: payload.defaultSpeed,
                        classifications: createClassifications(payload),
                        attributes: createAttributes(payload)
                    }
                case "PERMITTED_PLATES":
                    return {
                        localitySourceId: payload.localitySourceId,
                        permittedPlate: payload.permittedPlate
                    }
                case "FORBIDDEN_VEHICLES":
                    return {
                        localitySourceId: payload.localitySourceId,
                        restrictedVehicleSourceId: payload.restrictedVehicleSourceId
                    }
                case "SERVICE_TIME":
                    return {
                        localitySourceId: payload.localitySourceId,
                        loadFixedDuration: payload.loadFixedDuration,
                        unloadFixedDuration: payload.unloadFixedDuration,
                        loadWeightDuration: payload.loadWeightDuration,
                        unloadWeightDuration: payload.unloadWeightDuration,
                        loadQuantityDuration: payload.loadQuantityDuration,
                        unloadQuantityDuration: payload.unloadQuantityDuration
                    }
                case "LOCALITY":
                    return {
                        sourceId: payload.sourceId,
                        name: payload.name,
                        logisticRole: payload.logisticRole,
                        phone: payload.phone,
                        deliveryRestriction: payload.deliveryRestriction,
                        addressCity: payload.addressCity,
                        addressStateAcronym: payload.addressStateAcronym,
                        addressCountryAcronym: payload.addressCountryAcronym,
                        street: payload.street,
                        number: payload.number,
                        zipCode: payload.zipCode,
                        complement: payload.complement,
                        district: payload.district,
                        latitude: payload.latitude,
                        longitude: payload.longitude,
                        contactName: payload.contactName,
                        contactEmail: payload.contactEmail,
                        contactPhone: payload.contactPhone,
                        sundayStartTime: payload.sundayStartTime,
                        sundayEndTime: payload.sundayEndTime,
                        mondayStartTime: payload.mondayStartTime,
                        mondayEndTime: payload.mondayEndTime,
                        tuesdayStartTime: payload.tuesdayStartTime,
                        tuesdayEndTime: payload.tuesdayEndTime,
                        wednesdayStartTime: payload.wednesdayStartTime,
                        wednesdayEndTime: payload.wednesdayEndTime,
                        thursdayStartTime: payload.thursdayStartTime,
                        thursdayEndTime: payload.thursdayEndTime,
                        fridayStartTime: payload.fridayStartTime,
                        fridayEndTime: payload.fridayEndTime,
                        saturdayStartTime: payload.saturdayStartTime,
                        saturdayEndTime: payload.saturdayEndTime,
                        classifications: createClassifications(payload),
                        attributes: createAttributes(payload)
                    }
            }
        }
        const createClassifications = function (payload) {
            return {
                sourceIdClassificationType1: payload.sourceIdClassificationType1,
                sourceIdClassification1: payload.sourceIdClassification1,
                sourceIdClassificationType2: payload.sourceIdClassificationType2,
                sourceIdClassification2: payload.sourceIdClassification2,
                sourceIdClassificationType3: payload.sourceIdClassificationType3,
                sourceIdClassification3: payload.sourceIdClassification3,
                sourceIdClassificationType4: payload.sourceIdClassificationType4,
                sourceIdClassification4: payload.sourceIdClassification4,
                sourceIdClassificationType5: payload.sourceIdClassificationType5,
                sourceIdClassification5: payload.sourceIdClassification5
            }
        }

        const createAttributes = function (payload) {
            return {
                sourceIdAttributeType1: payload.sourceIdAttributeType1,
                sourceIdAttribute1: payload.sourceIdAttribute1,
                sourceIdAttributeType2: payload.sourceIdAttributeType2,
                sourceIdAttribute2: payload.sourceIdAttribute2,
                sourceIdAttributeType3: payload.sourceIdAttributeType3,
                sourceIdAttribute3: payload.sourceIdAttribute3,
                sourceIdAttributeType4: payload.sourceIdAttributeType4,
                sourceIdAttribute4: payload.sourceIdAttribute4,
                sourceIdAttributeType5: payload.sourceIdAttributeType5,
                sourceIdAttribute5: payload.sourceIdAttribute5
            }
        }

        // Somos incompetentes e não sabemos fazer melhor que isso para a entidade pedido.
        function convertEntityToPayload(payload, entityName) {
            if (entityName === "ORDER") {
                payload.orderSourceId = payload.sourceId
                const orderItem = payload.orderItem
                const classifications = orderItem.classifications
                const attributes = orderItem.attributes
                payload.orderItemSourceId = orderItem.sourceId
                payload.quantityOfShipmentUnits = orderItem.quantityOfShipmentUnits
                payload.value = orderItem.value
                payload.volume = orderItem.volume
                payload.weight = orderItem.weight
                payload.productSourceId = orderItem.productSourceId
                payload.orderItemSourceIdClassificationType1 = classifications.sourceIdClassificationType1
                payload.orderItemSourceIdClassification1 = classifications.sourceIdClassification1
                payload.orderItemSourceIdClassificationType2 = classifications.sourceIdClassificationType2
                payload.orderItemSourceIdClassification2 = classifications.sourceIdClassification2
                payload.orderItemSourceIdClassificationType3 = classifications.sourceIdClassificationType3
                payload.orderItemSourceIdClassification3 = classifications.sourceIdClassification3
                payload.orderItemSourceIdClassificationType4 = classifications.sourceIdClassificationType4
                payload.orderItemSourceIdClassification4 = classifications.sourceIdClassification4
                payload.orderItemSourceIdClassificationType5 = classifications.sourceIdClassificationType5
                payload.orderItemSourceIdClassification5 = classifications.sourceIdClassification5
                payload.orderItemSourceIdAttributeType1 = attributes.sourceIdAttributeType1
                payload.orderItemSourceIdAttribute1 = attributes.sourceIdAttribute1
                payload.orderItemSourceIdAttributeType2 = attributes.sourceIdAttributeType2
                payload.orderItemSourceIdAttribute2 = attributes.sourceIdAttribute2
                payload.orderItemSourceIdAttributeType3 = attributes.sourceIdAttributeType3
                payload.orderItemSourceIdAttribute3 = attributes.sourceIdAttribute3
                payload.orderItemSourceIdAttributeType4 = attributes.sourceIdAttributeType4
                payload.orderItemSourceIdAttribute4 = attributes.sourceIdAttribute4
                payload.orderItemSourceIdAttributeType5 = attributes.sourceIdAttributeType5
                payload.orderItemSourceIdAttribute5 = attributes.sourceIdAttribute5
            }
        }

        return {
            convertPayloadToEntity: convertPayloadToEntity,
            convertEntityToPayload: convertEntityToPayload
        }
    }])
