define(["./monitoringDriverModule", "text!./driverSelectionModal.html"], function (module, template) {
    "use strict";

    return module.service("monitoringDriverSelectionModal", ["$modal", "monitoringService", function ($modal) {
        return function(currentDriver, drivers){
          return $modal.open({
              size: "sm",
              template: template,
              controller: ["$scope", function ($scope) {
                  $scope.model = {
                      selectedDriver: null
                  };

                  if(currentDriver){
                      $scope.model.selectedDriver = currentDriver;
                  }
                  $scope.getTags = function() {
                      return drivers;
                  }

              }]
          }).result;
        };
    }]);
});