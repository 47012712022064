define([
    "./monitorModule",
    "file-saver",
    "text!./nlgMonitorLogSearch.html"
], function (monitorModule, fileSaver, template) {
    "use strict";

    var save = fileSaver.saveAs || fileSaver;

    return monitorModule.directive("nlgMonitorLogSearch", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                instance: "="
            },
            controllerAs: "ctrl",
            controller: [
                "$scope",
                "$q",
                "monitorService",
                "loadingService",
                "remoteExceptionHandler",
                function ($scope, $q, monitorService, loadingService, remoteExceptionHandler) {
                    var ctrl = this;
                    ctrl.searchResults = [];
                    ctrl.model = {
                        pattern: "",
                        options: {
                            fileGlob: "**/*.log"
                        }
                    };

                    ctrl.paginationOptions = {
                        records: [],
                        pagination: {
                            maxPageSize: 100,
                            maxPageSizes: [100, 250, 500],
                            onPaginate: function (firstResult, maxResults) {
                                return $q.resolve({
                                    firstResult: firstResult,
                                    maxResults: maxResults,
                                    count: ctrl.searchResults.length,
                                    result: ctrl.searchResults.slice(firstResult, firstResult + maxResults)
                                });
                            }
                        },
                        onRegisterPagination: function (paginationApi) {
                            ctrl.paginationApi = paginationApi;
                        }
                    };

                    ctrl.search = function () {
                        return loadingService(monitorService.searchLogFiles($scope.instance, ctrl.model.pattern, ctrl.model.options))
                            .catch(remoteExceptionHandler())
                            .then(function (result) {
                                ctrl.searchResults = result;
                                ctrl.paginationApi.seekPage(1);
                            });
                    };

                    ctrl.downloadResults = function () {
                        var searchResultsString = ctrl.searchResults.map(function (searchResult) {
                            return searchResult.file.padEnd(60).substr(0, 60)
                                + " | " + (searchResult.result.lineNumber + "").padStart(10)
                                + " | " + searchResult.result.line;
                        }).join("\n");
                        save(new Blob([searchResultsString]), "search-" + $scope.instance);
                    };
                }]
        };
    }]);
});