define([
    "../trackingMobileModule"
], function (trackingMobileModule) {
    "use strict";

    return trackingMobileModule.controller("LegacyDriverEditionController", [
        "$scope",
        "driver",
        "device",
        "$state",
        "messagesModal",
        "loadingService",
        "remoteExceptionHandler",
        "trackingMobileService",
        "countryLanguages",
        "$translate",
        function ($scope, driver, device, $state, messagesModal, loadingService, remoteExceptionHandler, trackingMobileService, countryLanguages, $translate) {
            $scope.entity = driver ? {
                id: driver.id,
                name: driver.name,
                phoneNumber: device ? device.phoneNumber : null
            } : {
                id: null,
                name: null,
                phoneNumber: {
                    number: null,
                    country: null
                }
            };

            $scope.back = function () {
                return $state.go("trackingMobile.legacyDriverSearch");
            };

            $scope.model = {
                countryData: null
            };
            $scope.defaultCountry = countryLanguages.findByLanguage($translate.use()).iso2;

            $scope.isValid = function () {
                return $scope.entity.name & $scope.entity.phoneNumber.number;
            };

            $scope.save = function () {
                $scope.entity.phoneNumber.country = $scope.model.countryData.iso2;
                loadingService(trackingMobileService.saveOrUpdateLegacyDriver($scope.entity))
                    .then(function () {
                        messagesModal("dialog.success", ["entity.validator.successMessage"])
                            .finally(function () {
                                $scope.back();
                            });
                    }).catch(remoteExceptionHandler());
            };
        }
    ]);
});